import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
// import UserLoader from '../../assets/images/user-loader.gif';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
// import TableView from '../TableView';
import Select from 'react-select';
import moment from 'moment';
import { toster_sucess } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';

import './index.css'
// import { async } from 'file-upload-with-preview';

class Hide24hours extends Component {
    _unmounted = false
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: { value: 4352, label: 'Social Influencer' },
            mainCate: [],
            isHideToggle: 0,
            isLoader: false,
            agentList: [],
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            statusSelectedOption: { value: 1, label: 'SHOW ALL' },
        }
    }

    componentDidMount() {
        this._unmounted = true
        this.getMainCate();
        this.getVisibility(this.state.selectedOption, '', this.state.statusSelectedOption);
    }

    componentWillUnmount() {
        this._unmounted = false
    }
    getMainCate = async () => {
        try {

            if (this._unmounted) {
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //"Access-Control-Allow-Origin": '*'
                }

                const response = await apiHalper('getMainServices', 'GET', null, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    var option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
                    option = [{ value: 0, label: 'Show All by Category' }, ...option]
                    console.log(option)
                    this.setState({
                        mainCate: option,
                    })
                } else {
                    this.setState({
                        mainCate: []
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }
    
    getVisibility = async (selectedOption, search, statusSelectedOption) => {
        try {
            this.setState({
                isLoader: true,
            });
            
            const { page, sizePerPage } = this.state;

            this.getAgentList(page, search, sizePerPage, selectedOption, statusSelectedOption);
            
        } catch (err) {
            console.log(err.message);
        }
    }
    
    getAgentList = async (page, search, sizePerPage, selectedOption, statusSelectedOption) => {
        try {
            this.setState({
                isLoader: true,
                agentList: []
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('page_no', page)
            formData.append('search_by', search)
            formData.append('limit', sizePerPage)
            formData.append('serviceCategoryID', 4352)
            formData.append('filter_by', statusSelectedOption.value)
            const response = await apiHalper('getAgentListsForAvailableJobs', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(response.data.data);
                this.setState({
                    isLoader: false,
                    agentList: data.agent_data,
                    totalRecord: data.user_count,
                });
            }
            else {
                this.setState({
                    isLoader: false,
                    agentList: []
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'job_count':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'count_rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'count_reports':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'total_amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'profile_status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            this.getAgentList(page, searchText, sizePerPage, this.state.selectedOption, this.state.statusSelectedOption);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getAgentList(page, searchText, sizePerPage, this.state.selectedOption, this.state.statusSelectedOption);
        }        
    }
    
    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }

    userName = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.photo}`
        return <div className="tb_user_wrap user-prof">
            <div className="useimg_block online_icon">
                <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }

    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    commonField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }

    actionField = (cell, row) => {
        return <Link to={`/usersDetails/${row.id}?type=agents`} className="btn-view margin_0auto">View</Link>
    }

    handlehide24hrs = async (e, id) => {
        try {
            this.setState({
                isLoader: true,
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('serviceCategoryID', this.state.selectedOption.value)
            formData.append('jobVisibility', 2)
            let user_list = []
            user_list.push(id)
            formData.append('userList', JSON.stringify(user_list))

            const response = await apiHalper('setVisibilityByCategory', 'POST', formData, headers)
            const res = response.data;
            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                this.getVisibility(this.state.selectedOption, '', this.state.statusSelectedOption);
                toster_sucess("Updated Successfully");
            }
            else {
                this.setState({
                    isLoader: false,
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    hide24hrsField = (cell, row) => {
        return <span className='text-center'>
                    <label className="switch switch-green">
                    <input type="checkbox" className="switch-input" onChange={(e) => this.handlehide24hrs(e, row.id)}  checked={row.is_selected === '0'} />
                    <span className="switch-label" data-on="On" data-off="Off"></span>
                    <span className="switch-handle"></span>
                    </label>
                </span>
    }

    statusField = (cell) => {
        switch (cell) {
            case 0:
                return <button type="button" className="btn-status orange margin_0auto" disabled>{'Signed up'}</button>;
            case 1:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Unverified'}</button>;
            case 2:
                return <button type="button" className="btn-status blue margin_0auto" disabled>{'Verified'}</button>;
            case 3:
                return <button type="button" className="btn-status green margin_0auto" disabled>{'Active'}</button>;
            case 4:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Inactive'}</button>;
            case 5:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Inactive'}</button>;
            case 6:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Deleted'}</button>;
            case 7:
                return <button type="button" className="btn-status orange margin_0auto" disabled>{'On Hold'}</button>;
            default:
                break
        }
    }

    handleChange = (selectedOption) => {
        this.setState({
            selectedOption: selectedOption ,
            search: '',
        })
        this.getVisibility(selectedOption, '', this.state.statusSelectedOption);
    }

    user_options = [
        {value: 1, label: "SHOW ALL" },
        {value: 2, label: "NON VIP" },
        {value: 3, label: "VIP" },
    ]

    handleUserOption = (option) => {
        this.setState({
            statusSelectedOption: option
        });
        const { page, sizePerPage, search } = this.state;
        this.getAgentList(page, search, sizePerPage, this.state.selectedOption, option);
    }

    render() {
        const { selectedOption, isClearable, mainCate, isHideToggle, statusSelectedOption } = this.state

        const columns = [
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter: this.userName },
            { dataField: 'signup_date', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'job_count', text: 'No of jobs', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
            { dataField: 'starpoints', text: 'Rating', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
            { dataField: 'count_reports', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
            { dataField: 'profile_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'total_amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField },
            { dataField: 'hide24hrs', text: 'Hide 24hrs', sort: false, headerFormatter: this.headerSpan, formatter: this.hide24hrsField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.agentList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            totalRecord: this.state.totalRecord,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className={isHideToggle === 0 ? "balance_wrap settings hide-column" : "balance_wrap settings"}>
                        {/* <div className="select_wrap main-service">
                            <Select value={selectedOption} isClearable={isClearable} placeholder={"Select Category"} onChange={(option)=> this.handleChange(option)} options={mainCate} />
                        </div> */}
                        <div className="status-div select_wrap">
                            <Select value={statusSelectedOption} isClearable={isClearable} placeholder={"Select Status"} onChange={(option) => this.handleUserOption(option)} options={this.user_options} />
                        </div>
                        
                        <DataTable TableData={tableData} />
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(Hide24hours))
