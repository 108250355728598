import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import LoaderImg from '../../assets/images/user-loader.gif'; 
import { useState } from "react";
import { apiHalper } from "../../helpers/ApiHelper";
import {toster_sucess, toster_error} from '../../helpers/toster_helper';

const DeleteCModal = ({ isModal, id, handleClose , getList ,pageNumber}) => {

const [isLoader , setIsLoader] = useState(false)

const deleteCoupon = async (e) =>{
   setIsLoader(true)
    let formData = new FormData();
    formData.append('id', id);
    const headers = {
        'Authorization':localStorage.getItem('token')
    }
    const res1 = await apiHalper('deleteCompany', 'POST', formData, headers);
    if(res1.data.status === true){
      getList(pageNumber) 
        setIsLoader(false)
        toster_sucess("Delete Comapny Successfully..");
        handleClose();
    }else{
        setIsLoader(false)
        toast.error("Delete Company Failed");
    }
}


  return (
    <>
      <Modal
        show={isModal}
        onHide={() => handleClose()}
        className="modal_design"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="body_modals body_modals2">
              <div >
                <div className="form_body2">
                  <p className="delete_text">
                    Delete Record
                    <span>Are you sure you want to delete this record?</span>
                  </p>
                </div>
                <div className="bg_submit2 mr-top">
                  <button
                    type="submit"
                    className="btn-submit"
                    disabled={isLoader}
                    onClick={()=>deleteCoupon()}
                  >
                    {isLoader ? (
                      <img
                        src={LoaderImg}
                        className="small-loader"
                        alt="loader"
                      />
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => handleClose()}
                    >
                      No
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
    </>
  );
};

export default DeleteCModal;
