import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { apiHalper } from '../../helpers/ApiHelper';
import * as ACTION_TYPES from '../../redux/actions/types';
import { getCoupList } from '../../redux/actions/offersActions';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
import LoaderImg from '../../assets/images/user-loader.gif';
import { ToastContainer } from 'react-toastify';

class CouponModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            totalRecord: 0,
            search: '',
            isSLoader: false,
            name: '',
            code: '',
            startDate: null,
            endDate: null,
            selectedType: { value: 1, label: 'Percentage' },
            discount: '',
            numUsers: '',
            perUser: '',
            cUrl: '',
            minOrdAmount: '',
            selectedFor: null,
            couForValid: false,
            selectedStatus: null,
            statusValid: false,
            discountError: '',
            discountValid: false,
            apiError: '',
            formError: {
                name: '',
                code: '',
                numUsers: '',
                perUser: '',
                cUrl: '',
                minOrdAmount: ''
            },
            formValid: {
                name: false,
                code: false,
                numUsers: false,
                perUser: false,
                cUrl: false,
                minOrdAmount: false
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { isCouponM, editData } = this.props;
        if (isCouponM !== prevProps.isCouponM) {

            if (editData !== null) {
                // console.log(editData.start_date);
                if (editData.type === 1) {
                    this.setState({ selectedType: { value: 1, label: 'Percentage' } });
                } else {
                    this.setState({ selectedType: { value: 2, label: 'Fixed' }, minOrdAmount: editData.minOrdAmount });
                }

                if (editData.status === 1) {
                    this.setState({ selectedStatus: { value: 1, label: 'Active' } });
                } else {
                    this.setState({ selectedStatus: { value: 0, label: 'Inactive' } });
                }

                switch (editData.coupon_for) {
                    case 1:
                        this.setState({ selectedFor: { value: 1, label: 'Bronze' } })
                        break;
                    case 2:
                        this.setState({ selectedFor: { value: 2, label: 'Silver' } })
                        break;
                    case 3:
                        this.setState({ selectedFor: { value: 3, label: 'Gold' } })
                        break;
                    case 4:
                        this.setState({ selectedFor: { value: 4, label: 'VIP' } })
                        break;
                    case 5:
                        this.setState({ selectedFor: { value: 5, label: 'New User' } })
                        break;
                    case 6:
                        this.setState({ selectedFor: { value: 6, label: 'Old User' } })
                        break;
                    case 7:
                        this.setState({ selectedFor: { value: 7, label: 'Both User' } })
                        break;
                    case 8:
                        this.setState({ selectedFor: { value: 8, label: 'AI User' } })
                        break;
                    default:
                        this.setState({ selectedFor: null })
                        break;
                }
                // console.log(editData);
                this.setState({
                    name: editData.coupon_name,
                    code: editData.coupon_code,
                    startDate: editData.start_date,
                    endDate: editData.end_date,
                    discount: editData.discount,
                    numUsers: editData.num_of_users,
                    perUser: editData.max_usage_per_user,
                    cUrl: editData.coupon_url,
                    couForValid: true,
                    statusValid: true,
                    discountError: '',
                    discountValid: true,
                    formError: {
                        name: '',
                        code: '',
                        numUsers: '',
                        perUser: '',
                        cUrl: '',
                        minOrdAmount: ''
                    },
                    formValid: {
                        name: true,
                        code: true,
                        numUsers: true,
                        perUser: true,
                        cUrl: true,
                        minOrdAmount: true
                    }
                });
            } else {
                this.setState({
                    name: '',
                    code: '',
                    startDate: new Date(),
                    endDate: new Date(),
                    discount: '',
                    numUsers: '',
                    perUser: '',
                    cUrl: '',
                    discountError: '',
                    discountValid: true,
                    selectedFor: null,
                    selectedType: { value: 1, label: 'Percentage' },
                    selectedStatus: { value: 1, label: 'Active' },
                    couForValid: false,
                    statusValid: true,
                    formError: {
                        name: '',
                        code: '',
                        numUsers: '',
                        perUser: '',
                        cUrl: '',
                        minOrdAmount: ''
                    },
                    formValid: {
                        name: false,
                        code: false,
                        numUsers: false,
                        perUser: false,
                        cUrl: false,
                        minOrdAmount: false
                    }
                })
            }
        }

    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        let discountValid = this.state.discountValid;
        let discountError = this.state.discountError;

        const reg = new RegExp(/^\d+$/);
        const flotReg = new RegExp(/(\d+(\.\d+)?)/);
        const expression = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        const urlreg = new RegExp(expression);

        switch (name) {
            case 'name':
                valid.name = value.length > 0;
                error.name = valid.name ? '' : 'Coupon Name must required!';
                break;
            case 'code':
                valid.code = value.length > 0;
                error.code = valid.code ? '' : 'Coupon Code must required!';
                break;
            case 'minOrdAmount':
                if (flotReg.test(value)) {
                    if (value > 0) {
                        valid.minOrdAmount = true;
                        error.minOrdAmount = '';
                    } else {
                        valid.minOrdAmount = false;
                        error.minOrdAmount = 'Order value upto 0';
                    }
                } else {
                    valid.minOrdAmount = false;
                    error.minOrdAmount = 'Min Order only numerical';
                }
                break;
            case 'discount':
                if (this.state.selectedType.value === 2) {
                    if (flotReg.test(value)) {
                        discountValid = true;
                        discountError = '';
                    } else {
                        discountValid = false;
                        discountError = discountValid ? '' : 'Discount only numerical';
                    }
                } else {
                    if (value.length > 0) {
                        if (flotReg.test(value)) {
                            if (value <= 100) {
                                discountValid = true;
                                discountError = '';
                            } else {
                                discountValid = false;
                                discountError = 'Discount maximum 100%';
                            }
                        } else {
                            discountValid = false;
                            discountError = discountValid ? '' : 'Discount only numerical required!';
                        }
                    } else {
                        discountValid = false;
                        discountError = 'Discount must required!';
                    }
                }
                break;
            case 'numUsers':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.numUsers = true;
                        error.numUsers = '';
                    } else {
                        valid.numUsers = false;
                        error.numUsers = 'Number of users only numerical required!';
                    }
                } else {
                    valid.numUsers = false;
                    error.numUsers = 'Number of users must required!';
                }
                break;
            case 'perUser':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.perUser = true;
                        error.perUser = '';
                    } else {
                        valid.perUser = false;
                        error.perUser = 'Number of Usage per User only numerical required!';
                    }
                } else {
                    valid.perUser = false;
                    error.perUser = 'Number of Usage per User must required!';
                }
                break;
            case 'cUrl':
                if (value.length > 0) {
                    if (value.match(urlreg)) {
                        valid.cUrl = true;
                        error.cUrl = '';
                    } else {
                        valid.cUrl = false;
                        error.cUrl = 'Enter a valid url!';
                    }
                } else {
                    valid.cUrl = false;
                    error.cUrl = 'Coupon url must required!';
                }
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid,
            discountError: discountError,
            discountValid: discountValid
        })
    }

    handleStartdate = (date) => {

        this.setState({
            startDate: date
        });
    }


    handleEnddate = (date) => {
        this.setState({
            endDate: date
        });
    }

    handleChange = selectedOption => {
        this.setState({
            selectedType: selectedOption,
            minOrdAmount: '',
            formError: {
                ...this.state.formError,
                minOrdAmount: ''
            },
            formValid: {
                ...this.state.formValid,
                minOrdAmount: false
            },
            discount: '',
            discountError: '',
            discountValid: false
        });
    };

    handleForChange = selectedOption => {
        this.setState({
            selectedFor: selectedOption,
            couForValid: true
        });
    };

    handleStatusChange = selectedOption => {
        this.setState({
            selectedStatus: selectedOption,
            statusValid: true
        });
    };

    submitCoupon = async (e) => {
        e.preventDefault();
        this.setState({
            isSLoader: true
        })
        // console.log('add coupon...');
        const { name, code, startDate, endDate, selectedType, discount, minOrdAmount, numUsers, perUser, selectedFor, selectedStatus, cUrl } = this.state;

        let formData = new FormData();
        formData.append('coupon_name', name);
        formData.append('coupon_code', code);
        formData.append('start_date', moment(new Date(startDate)).format('YYYY-MM-DD'));
        formData.append('end_date', moment(new Date(endDate)).format('YYYY-MM-DD'));
        formData.append('type', selectedType.value);
        formData.append('discount', discount);
        formData.append('num_of_users', numUsers);
        formData.append('max_usage_per_user', perUser);
        formData.append('coupon_for', selectedFor.value);
        formData.append('coupon_url', cUrl);
        formData.append('coupon_status', selectedStatus.value);
        if (selectedType.value === 2) {
            formData.append('minOrdAmount', minOrdAmount);
        }

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const res = await apiHalper('addCoupon', 'POST', formData, headers);
        if (res.data.status === true) {
            this.setState({
                apiError: '',
                isSLoader: false
            });
            toster_sucess("Coupon added Successfully..");
            this.props.handleClose(true, null);
            const res1 = await getCoupList(this.state.page, this.state.search);
            if (res1.data.status === true) {
                const data = res1.data.data;
                this.props.addCouponList(data.coupon_data);
            }
        } else {
            this.setState({
                apiError: res.data.message,
                isSLoader: false
            });
            toster_error("Coupon Failed");
        }

    }

    updateCoupon = async (e) => {
        e.preventDefault();
        // console.log('update coupon...');
        this.setState({
            isSLoader: true
        })
        const { editData } = this.props;
        const { name, code, startDate, endDate, selectedType, discount, minOrdAmount, numUsers, perUser, selectedFor, selectedStatus, cUrl } = this.state;

        let formData = new FormData();
        formData.append('coupon_id', editData.id);
        formData.append('coupon_name', name);
        formData.append('coupon_code', code);
        formData.append('start_date', moment(new Date(startDate)).format('YYYY-MM-DD'));
        formData.append('end_date', moment(new Date(endDate)).format('YYYY-MM-DD'));
        formData.append('type', selectedType.value);
        if (selectedType.value === 2) {
            formData.append('minOrdAmount', minOrdAmount);
        }
        formData.append('discount', discount);
        formData.append('num_of_users', numUsers);
        formData.append('max_usage_per_user', perUser);
        formData.append('coupon_for', selectedFor.value);
        formData.append('coupon_url', cUrl);
        formData.append('status', selectedStatus.value);

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const res = await apiHalper('editCoupon', 'POST', formData, headers);
        // console.log(res);
        if (res.data.status === true) {
            this.setState({
                apiError: '',
                isSLoader: false
            });
            toster_sucess("Coupon updated Successfully..");
            this.props.handleClose(true, null);
            const res1 = await getCoupList(this.state.page, this.state.search);
            if (res1.data.status === true) {
                const data = res1.data.data;
                this.props.addCouponList(data.coupon_data);
            }
        } else {
            this.setState({
                apiError: res.data.message,
                isSLoader: false
            });
            toster_error("Coupon Failed");
        }
    }

    render() {
        const { isCouponM, handleClose, isAdd } = this.props;
        const { isSLoader, name, code, startDate, endDate, selectedType, minOrdAmount, discount, numUsers, perUser, cUrl, selectedFor, couForValid, selectedStatus, statusValid, discountError, discountValid, formError, formValid, apiError } = this.state;

        const typeOptions = [
            { value: 1, label: 'Percentage' },
            { value: 2, label: 'Fixed' }
        ];

        const forOptions = [
            { value: 1, label: 'Bronze' },
            { value: 2, label: 'Silver' },
            { value: 3, label: 'Gold' },
            { value: 4, label: 'VIP' },
            { value: 5, label: 'New User' },
            { value: 6, label: 'Old User' },
            { value: 7, label: 'Both User' },
            { value: 8, label: 'AI User' }
        ];

        const statusOptions = [
            { value: 1, label: 'Active' },
            { value: 2, label: 'Inactive' }
        ];

        const disabled = selectedType === 2 ?
            !formValid.name || !formValid.code || !discountValid || !formValid.numUsers || !formValid.perUser || !formValid.cUrl || !couForValid || !statusValid || !formValid.minOrdAmount
            :
            !formValid.name || !formValid.code || !discountValid || !formValid.numUsers || !formValid.perUser || !formValid.cUrl || !couForValid || !statusValid;
        return (
            <Modal show={isCouponM} onHide={() => handleClose(true, null)} className="modal_design" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">{isAdd ? "Add" : "Edit"} Coupon details <button type="button" onClick={() => handleClose(true, null)}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals body_modals2">
                            <form method="POST" action="#" onSubmit={isAdd ? this.submitCoupon : this.updateCoupon}>
                                <div className="form_body">
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>Coupon Name</label>
                                            <input type="text" name="name" className={formError.name !== '' ? "form_control2 error_border" : "form_control2"} value={name} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.name !== '' ? <p className="error_text">{formError.name}</p> : null}
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>Coupon code</label>
                                            <input type="text" name="code" className={formError.code !== '' ? "form_control2 error_border" : "form_control2"} value={code} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.code !== '' ? <p className="error_text">{formError.code}</p> : null}
                                        </div>
                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>Start Date</label>
                                            <DatePicker selected={new Date(startDate)} onChange={this.handleStartdate} dateFormat="MMM dd, yyyy" className="form_control2" />
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>End Date</label>
                                            <DatePicker selected={new Date(endDate)} onChange={this.handleEnddate} dateFormat="MMM dd, yyyy" className="form_control2" />
                                        </div>
                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>Type</label>
                                            <div className="custom_sele">
                                                <Select value={selectedType} placeholder="Select" onChange={this.handleChange} options={typeOptions} isDisabled={isAdd ? false : true} />
                                            </div>
                                        </div>
                                        {selectedType.value === 2 ?
                                            <div className="colum_2_wrap left_both">
                                                <div className="colum_2_wrap colum_2_wrap2">
                                                    <label>Min Order Amount</label>
                                                    <input type="text" name="minOrdAmount" className={formError.minOrdAmount !== '' ? "form_control2 error_border" : "form_control2"} disabled={selectedType === null} value={minOrdAmount} onChange={this.inputHandler} autoComplete="off" />
                                                    {formError.minOrdAmount !== '' ? <p className="error_text">{formError.minOrdAmount}</p> : null}
                                                </div>
                                                <div className={"colum_2_wrap colum_2_wrap2"}>
                                                    <label>Discount</label>
                                                    <input type="text" name="discount" className={discountError !== '' ? "form_control2 error_border" : "form_control2"} disabled={selectedType === null} value={discount} onChange={this.inputHandler} autoComplete="off" />
                                                    {discountError !== '' ? <p className="error_text">{discountError}</p> : null}
                                                </div>
                                            </div>
                                            :
                                            <div className={"colum_2_wrap"}>
                                                <label>Discount</label>
                                                <input type="text" name="discount" className={discountError !== '' ? "form_control2 error_border" : "form_control2"} disabled={selectedType === null} value={discount} onChange={this.inputHandler} autoComplete="off" />
                                                {discountError !== '' ? <p className="error_text">{discountError}</p> : null}
                                            </div>
                                        }

                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>Number of Users</label>
                                            <input type="text" name="numUsers" className={formError.numUsers !== '' ? "form_control2 error_border" : "form_control2"} value={numUsers} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.numUsers !== '' ? <p className="error_text">{formError.numUsers}</p> : null}
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>Number of Usage per User</label>
                                            <input type="text" name="perUser" className={formError.perUser !== '' ? "form_control2 error_border" : "form_control2"} value={perUser} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.perUser !== '' ? <p className="error_text">{formError.perUser}</p> : null}
                                        </div>
                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>Coupon For</label>
                                            <div className="custom_sele">
                                                <Select value={selectedFor} placeholder="Select" onChange={this.handleForChange} options={forOptions} />
                                            </div>
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>Status</label>
                                            <div className="custom_sele">
                                                <Select value={selectedStatus} onChange={this.handleStatusChange} options={statusOptions} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_group2">
                                        <label>Coupon Url</label>
                                        <input type="url" name="cUrl" className={formError.cUrl !== '' ? "form_control2 error_border" : "form_control2"} value={cUrl} onChange={this.inputHandler} autoComplete="off" />
                                        {formError.cUrl !== '' ? <p className="error_text">{formError.cUrl}</p> : null}
                                    </div>
                                </div>
                                {apiError !== '' ? <p className="error_text">{apiError}</p> : null}
                                <div className="bg_submit2 mr-top">
                                    {isAdd ?
                                        <button type="submit" className="btn-submit" disabled={disabled || isSLoader}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save"}</button>
                                        :
                                        <button type="submit" className="btn-submit" disabled={disabled || isSLoader}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Update"}</button>
                                    }
                                    <p>Or <button type="button" className="btn_cancel2" onClick={() => handleClose(true, null)}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ToastContainer />
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    isCouponM: state.offers.isCouponM,
    isAdd: state.offers.isAdd,
    editData: state.offers.editData
});

const mapDispatchToProps = dispatch => ({
    handleClose: (isAdd, da) => dispatch({ type: ACTION_TYPES.CLOSE_COUPON_MODAL, payload: { isAdd, data: da } }),
    addCouponList: (data) => dispatch({ type: ACTION_TYPES.GET_COUPON_LIST, payload: data })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponModal));
