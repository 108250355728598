import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
// import TableView from '../TableView';
// import userImg from '../../assets/images/user_img.svg';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';
import DataTable from '../JqueryDataTable';

class Clients extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            istester: false,
            selectedOption:null,
            isClearable:true,
            page:1,
            search:'',
            clientData:[],
            isLoader:false,
            messageApi:'',
            totalRecord:0,
            clientCount:0,
            agentCount:0,
            adminCount:0
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page, istester, search } = this.state;
        this.getTotalUsersApi(istester);
        this.getClientApi(page, search, istester);        
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getTotalUsersApi = async (istester)=>{
        if(this._ismounted){
            const headers = {
                'Authorization':localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('show_tester', istester ? 1 : 0)
            
            const res = await apiHalper(`getTotalUser`, 'POST', formData, headers);
            const { addCounts } = this.props;
            if(res.data.status === true){
                const data = jwtDecode(res.data.data);
                this.setState({
                    clientCount:data.client_count,
                    agentCount:data.agent_count,
                    adminCount:data.admin_count
                })
                addCounts(data.client_count, data.agent_count, data.admin_count);
            }else{
                this.setState({
                    clientCount:0,
                    agentCount:0,
                    adminCount:0
                })
                addCounts(0, 0, 0);
            }
        }
        
    }

    getClientApi = async (page, search, istester) =>{
        if(this._ismounted){

            try{
                this.setState({
                    isLoader:true
                })

                const headers = {
                    'Authorization':localStorage.getItem('token')
                }
                const { selectedOption } = this.state;
                let formData = new FormData();
                if(selectedOption && selectedOption !== null){
                    formData.append('sort_by', selectedOption.value)
                }

                if(search && search !== ''){
                    formData.append('search_by', search)
                }

                formData.append('page_no', page)
                formData.append('show_tester', istester ? 1 : 0)

                const res = await apiHalper(`getClientLists`, 'POST', formData, headers);
                if(res.data.status === true){
                    const data  = jwtDecode(res.data.data);
                    console.log(data,'clientdata')
                    this.setState({
                        clientCount:data.user_count,
                        clientData:data.client_data,
                        totalRecord:data.user_count,
                        isLoader:false,
                        messageApi:'',
                    })
                }else{
                    this.setState({
                        clientData:[],
                        isLoader:false,
                        messageApi:'',
                    }) 
                }
            }catch(err){
                this.setState({
                    clientData:[],
                    isLoader:false,
                    messageApi:'Network Error'
                })
            }
        }

    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getClientApi(this.state.page, this.state.search);
    };

    toggleClearable = () =>{
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption:null }));
        this.getClientApi(this.state.page, this.state.search);
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'username':
                return <span className={column.sort?"header_span sort pd_left":"header_span pd_left"}>{column.text}</span>;
            case 'count_jobs':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'chat':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'keyword':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'count_reports':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'project_amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'profile_status':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
    }


    centerField = (cell, row)=>{
        return <span className="text-center margin_0auto">0</span>;
    }
    centerJobCountField = (cell, row)=>{
        return <span className="text-center margin_0auto">{row.count_jobs}</span>;
    }
    centerReportsCountField = (cell, row)=>{
        return <span className="text-center margin_0auto">{row.count_reports}</span>;
    }

    actionField = (cell, row) =>{
        return <Link to={`/usersDetails/${row.id}?type=clients`} className="btn-view margin_0auto">View</Link>;
    }
    amountField = (cell, row) =>{
        return <span className="text-center margin_0auto">{row.totalAmt}</span>;
    }
    statusField = (cell) =>{

        if(cell === 1){
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        }else{
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }
        
    }

    imgNotFound = (e)=>{
        console.log(e);
    }

    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.client_img}${row.photo}`

        if (row.flag === 1) {
            return <div className="tb_user_wrap flag_icon">
                <div className="useimg_block online_icon">
                    <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
                </div>
                <p>{cell} {this.checkRatings(Math.round(row.starpoints))}</p>
            </div>
        } else {
            return <div className="tb_user_wrap">
                <div className="useimg_block online_icon">
                    <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
                </div>
                <p>{cell} {this.checkRatings(Math.round(row.average_rate))}</p>
            </div>
        }
    }

    timeSet = (cell) =>{
        return moment(cell).format('MMM DD, YYYY');
    }

    checkRatings = (rating) =>{
        switch (rating) {
            case 1:
                return <span className="rating_wrap rating1"></span>;
            case 2:
                return <span className="rating_wrap rating2"></span>;
            case 3:
                return <span className="rating_wrap rating3"></span>;
            case 4:
                return <span className="rating_wrap rating4"></span>;
            case 5:
                return <span className="rating_wrap rating5"></span>;
            default:
                return <span className="rating_wrap rating0"></span>;
        }
    }

    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage,
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }

        this.getClientApi(page, searchText, this.state.istester);
    }

    isTester = (row) =>{
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    testerHandler = (e) => {
        // const { checked } = e.target;
    
        this.setState({
            istester: !this.state.istester,
            page: 1,
        
            
        });

        const { page, search } = this.state;
        this.getClientApi(1, search, !this.state.istester);
        // this.getTotalUsersApi(!this.state.istester);
    }

    render() {
        const options = [
            { value: 'join', label: 'Join' },
            { value: 'kw', label: 'Keyword' },
            { value: 'chat', label: 'Chat' },
            { value: 'flag', label: 'Flag' },
            { value: 'paid', label: 'Paid' },
            { value: 'jobs', label: 'Jobs' },
            { value: 'report', label: 'Report' }
        ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter:this.userField }, 
            { dataField: 'signup_date', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.timeSet },
            { dataField: 'count_jobs', text: 'No of Campaign', sort: true, headerFormatter: this.headerSpan, formatter:this.centerJobCountField },
            { dataField: 'chat', text: 'No of chat', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            // { dataField: 'keyword', text: 'Keyword', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            { dataField: 'count_reports', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter:this.centerReportsCountField },
            { dataField: 'profile_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'project_amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // const data = [
        //     { id:'1', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'2', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'3', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:1 },
        //     { id:'4', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'5', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'6', username:'john Doe', star:0, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'7', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
        //     { id:'8', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'9', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'10', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'11', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'12', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
        //     { id:'13', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'14', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'15', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'16', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 }
        // ]

        const tableData = {
            columns:columns,
            data:this.state.clientData,
            keyId:'id',
            loading:this.state.isLoader,
            message:this.state.messageApi !== ''?this.state.messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.clientCount,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { selectedOption, isClearable } = this.state;
        const { addUser } = this.props; 

        return (
            <div className="tab_container">
                <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button>
                <div className="tester_view user_table_view">
                    <p>Tester Record</p>
                    <button type="button" className={this.state.istester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                        <span className="label_text"></span>
                    </button>
                </div>
                <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div>
                {/* <TableView TableData={tableData} />   */}
                <DataTable TableData = {tableData} />
            </div>
        )
    }
}

const mapStateToProps = state =>({
    clientCount:state.users.client
})

const mapDispatchToProps = (dispatch) =>({
    addUser:()=>dispatch({type:ACTION_TYPES.TOGGLE_USER_MODAL}),
    addCounts:(client, agent, admin)=>dispatch({type:ACTION_TYPES.TOTAL_USERS_COUNT,payload:{client:client, agent:agent, admin:admin}})
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
