import React, { Component } from 'react';
import Select from 'react-select';
import { Link, withRouter } from 'react-router-dom'
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import DataTable from '../../components/JqueryDataTable';
import NoDataIndication from '../../components/TableLoader';
import './index.css'
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../redux/actions/types';

class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editData: null,
            pageNo: 1,
            selectPartnerAs: 0,
            recordCount: 0,
            listData: [],
            isLoader: false,
            isSelectLoader: false,
            isMainLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            selectedOption: [],
            isClearable: null
        }
    }

    componentDidMount() {
        const { pageNo, selectPartnerAs, search } = this.state
        this.getPartnerList(pageNo, search, selectPartnerAs);
    }
    actionField = (cell, row) =>{
        const { addEditData } = this.props;
        return <Link to={`/partner-detail?id=${row.id}`} className="btn-view margin_0auto" onClick={()=>addEditData(row)}>View</Link>;
    }
    headerSpan = (column) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'partnerAs':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'currentPosition':
                    return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'companyWebsite':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'country':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'age':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    imgNotFound = (e)=>{
        e.target.src = DummyProfile;
    }
    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.photo}`
        return <div className="tb_user_wrap">
            <div className="useimg_block online_icon">
                <img src={row.photo !== null? imgPath: DummyProfile} onError={(e)=>this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }
    partnerOptField = (row) => {
        return <div className="text-center">{row}</div>
    }

    companyPositionField = (row) => {
        return <div className="text-center">{row}</div>
    }
    companyWebField = (row) => {
        return <div className="text-center">{row}</div>
    }
    countryField = (row) => {
        return <div className="text-center">{row}</div>
    }
    ageField = (row) => {
        return <div className="text-center">{row}</div>
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        const { selectPartnerAs } = this.state
        if(type === 'pagination') {            
            this.setState({
                pageNo: page,
                sizePerPage,
            });
            this.getPartnerList(page, searchText, selectPartnerAs);
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
            this.getPartnerList(page, searchText, selectPartnerAs);
        }
    }
    getPartnerList = async (pageNo, search, selectPartnerAs) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('page_no', pageNo )
            formData.append('search_by', search)
            
            const { selectedOption } = this.state;
            if(!selectedOption){
                formData.append('partnerAs', selectedOption.value)
            }
            else {
                formData.append('partnerAs', selectPartnerAs)
            }          
            
            const response = await apiHalper('getUserPartnershipRequestList', 'POST', formData, headers);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);

                this.setState({
                    isLoader: false,
                    listData: decode.data,
                    recordCount: decode.count,                    
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    listData: [],
                    recordCount: 0,
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }
    }

    handleChange = selectedOption => {
        const { page, search } = this.state;
        this.setState({ selectedOption });
        this.getPartnerList(page, search, selectedOption.value);
    };

    render() {
        const { listData, isLoader, pageNo, messageApi, selectedOption, isClearable } = this.state;

        const options = [
            { value: '1', label: 'Become an owner operator' },
            { value: '2', label: 'Invest with us' },
            { value: '3', label: 'Franchise with us' },
            { value: '4', label: 'Work with us' },
            { value: '5', label: 'Other' }            
        ];
        
        const columns = [
            { dataField: 'username', text: 'Partner', sort: true, headerFormatter: this.headerSpan, formatter:this.userField },
            { dataField: 'partnerAs', text: 'Partner with us', sort: true, headerFormatter: this.headerSpan, formatter:this.partnerOptField },
            { dataField: 'currentPosition', text: 'Current Company Position', sort: true, headerFormatter: this.headerSpan, formatter:this.companyPositionField },
            { dataField: 'companyWebsite', text: 'Current Company Website', sort: true, headerFormatter: this.headerSpan, formatter:this.companyWebField },
            { dataField: 'country', text: 'Country', sort: true, headerFormatter: this.headerSpan, formatter:this.countryField },
            { dataField: 'age', text: 'Age', sort: true, headerFormatter: this.headerSpan, formatter:this.ageField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: listData,
            keyId: 'username',
            loading: isLoader,
            message: messageApi !== '' ? messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: pageNo,
            totalRecord: this.state.recordCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange
        };
        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap partner-list">
                        <div className="select_wrap users_filters">
                            <Select value={selectedOption} isClearable={isClearable} placeholder={"Search Partner with us"} onChange={this.handleChange} options={options} />
                        </div>
                        <DataTable TableData={tableData} />
                    </div>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch =>({
    addEditData:(data)=>dispatch({type:ACTION_TYPES.PATNER_SINGLE_DATA,payload:data})
})

export default withRouter(connect(null, mapDispatchToProps)(Partner));