import React, { Component } from 'react';
import Select from 'react-select';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';

class ChatHistory extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            
        }
    }

    render() {
        const options = [
            { value: 'join', label: 'Join' },
            { value: 'kw', label: 'Keyword' },
            { value: 'chat', label: 'Chat' },
            { value: 'flag', label: 'Flag' },
            { value: 'paid', label: 'Paid' },
            { value: 'jobs', label: 'Jobs' },
            { value: 'report', label: 'Report' }
        ];

        const columns = [ 
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter:this.userField }, 
            { dataField: 'signup_date', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.timeSet },
            { dataField: 'count_jobs', text: 'No of Campaign', sort: true, headerFormatter: this.headerSpan, formatter:this.centerJobCountField },
            { dataField: 'chat', text: 'No of chat', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            { dataField: 'count_reports', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter:this.centerReportsCountField },
            { dataField: 'profile_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'project_amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns:columns,
            data:[],
            keyId:'id',
            loading:this.state.isLoader,
            message:this.state.messageApi !== ''?this.state.messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.clientCount,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            // rowClass: this.isTester
        };

        const { selectedOption, isClearable } = this.state;
        const { addUser } = this.props; 

        return (
            <div className="tab_container">
                <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button>
                <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div>
                {/* <TableView TableData={tableData} />   */}
                <DataTable TableData = {tableData} />
            </div>
        )
    }
}


export default ChatHistory;

