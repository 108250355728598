import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import SearchIcon from '../../../assets/images/search_icon.svg';
import * as ACTION_TYPES from '../../../redux/actions/types';
import NoDataIndication from '../../TableLoader';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper';
import { toster_error, toster_sucess } from '../../../helpers/toster_helper';
import './index.css';

class addSubcategory extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            insertedId: null,
            editData: null,
            mainCate: [],
            listData: [],
            isLoader: false,
            isSelectLoader: false,
            isMainLoader: false,
            displayWebsite: '',
           // isFeatured: 1,
            messageApi: '',
            page: 1,
            search: '',
            selectedOption: { value: 1, label: 'Customer Service' },
            isClearable: null,
            addModal: false,
            multiSelect: [{ categoryOption: null, isClearable: true, options: [] }],
            cateText: '',
            appName: '',
            fpName: '',
            pfName: '',
            description: '',
            formError: {
                cateText: '',
                appName: '',
                fpName: '',
                description: '',
                pfName: ''
            },
            formValid: {
                cateText: false,
                appName: false,
                fpName: false,
                description: false,
                pfName: false
            },
            apiError: '',
            isSLoader: false,
            isDLoader: false,
            isDelModal: false,
            step: 1,
            simFilterVal: 1,
            isCLoader: false,
            similarSctArr: [],
            SubCateList: [],
            searchSkillArr: [], 
            searchVal: '',
            simArray: [],
            renderList: [],
            checked: false,
            isEdit: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        //const { selectedOption } = this.state;
        //this.getSubCateList(selectedOption.value);
        this.getMainCate();
        // this.getSubListC(this.state.simFilterVal);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMainCate = async () => {
        try {
            if (this._unmounted) {
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //"Access-Control-Allow-Origin": '*'
                }
                const is_main = new URLSearchParams(this.props.location.search).get('is_main');
                const response = await apiHalper(is_main === '1'? 'getMainServices' : 'getMainServicesByCategory' , 'GET', null, headers);
                
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    let option;
                    is_main === '1'? 
                        option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : []
                        : 
                        option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : []
                    this.setState({
                        mainCate: option,
                        multiSelect: [{ categoryOption: null, isClearable: true, options: option }]
                    })
                } else {
                    this.setState({
                        mainCate: []
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    getSubCateList = async (id) => {
        try {

            if (this._unmounted) {

                this.setState({
                    isLoader: true,
                    isSelectLoader: true,
                    isMainLoader: true,
                    listData: []
                });

                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //"Access-Control-Allow-Origin": '*'
                }
                const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
                let formData = new FormData();
                // formData.append('page_no', page)
                // formData.append('search_no', search)
                formData.append('parent_service_category_id', id)
                formData.append('pageType', parentPath)
                const response = await apiHalper('getSubServices', 'POST', formData, headers);
                // console.log(response);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    const newArray = data.length > 0 ? data.map((el, i) => Object.assign({ num: (i + 1) }, el)): [];
                    console.log(newArray);
                    this.setState({
                        isLoader: false,
                        isSelectLoader: false,
                        isMainLoader: false,
                        listData: newArray,
                    })
                } else {
                    this.setState({
                        isLoader: false,
                        isMainLoader: false,
                        listData: [],
                    })
                }
            }

        } catch (err) {
            console.log(err);
            this.setState({
                isLoader: false,
                isSelectLoader: false,
                isMainLoader: false,
                messageApi: 'Network Error'
            })
        }
    }

    displaySiteChecked = (e) => {
        let checked = e.target.checked
        let value = 0;
        if (checked) {
            value = 1
        }
        this.setState({
            displayWebsite: value
        })
    }
    // isFeaturedChecked = (e) => {
    //     let checked = e.target.checked
    //     let value = 0;
    //     if (checked) {
    //         value = 1
    //     }
    //     this.setState({
    //         isFeatured: value
    //     })
    // }
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        //this.getSubCateList(selectedOption.value);
        // this.getClientApi(this.state.page, this.state.search);
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: null }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'num':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'name':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'is_content':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'pageFileName':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            // case 'username':
            //     return <span className={column.sort?"header_span sort pd_left":"header_span pd_left"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    autoNumber = (cell) => {
        return <span className="number_td"></span>
    }

    checkNotNull = (cell) => {
        return cell !== null ? cell : '--';
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">0</span>;
    }

    actionField = (cell, row) => {
        return <div className="action_group text-center">

            {row.is_content === 1 ?
                <button type="button" className={"btn_content edit_content"} onClick={() => this.redirectPage(row)}></button>
                :
                row.pageFileName !== null ?
                    <button type="button" className={"btn_content add_content"} onClick={() => this.redirectPage(row)}></button>
                    :
                    <button type="button" className={"btn_content add_content"} onClick={() => this.pageCreateMessage(row)}></button>
            }

            <button type="button" className="btn_edit2" onClick={() => this.editModal(row)}></button>
            <button type="button" className="btn_delete" onClick={() => this.openDeleModal(row)}></button>
        </div>
    }

    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }

    contentField = (cell, row) => {
        return row.is_content === 1 ? <span className="text_green text-center margin_0auto">Available</span> : <span className="text_red text-center margin_0auto">Unavailable</span>;
    }

    statusField = (cell) => {

        if (cell === '1') {
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }

    }

    pageCreateMessage = (row) => {
        const { editDataPage, history } = this.props;
        editDataPage(null);
        history.push(`/seo/content-details?id=${row.id}`);
        toster_error(`${row.name} page is not Found.`);
    }

    redirectPage = async (data) => {
        try {
            const { history } = this.props;
            history.push(`/seo/content-details?id=${data.pageId}`);

        } catch (err) {
            console.log(err.message);
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText }) => {
        // console.log(type);
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage,
            });
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
        }

        // this.getClientApi(page, searchText);
    }

    toggleModalAdd = (data) => {
        const { editDataPage } = this.props;
        if (this.state.addModal) {
            this.setState({
                selectedOption: { value: 1, label: 'Customer Service' },
                isClearable: null,
                addModal: false,
                multiSelect: [{ categoryOption: null, isClearable: true, options: this.state.mainCate }],
                cateText: '',
                appName: '',
                fpName: '',
                pfName: '',
                description: '',
                formError: {
                    cateText: '',
                    appName: '',
                    fpName: '',
                    description: '',
                    pfName: ''
                },
                formValid: {
                    cateText: false,
                    appName: false,
                    fpName: false,
                    description: false,
                    pfName: false
                },
                apiError: '',
                isSLoader: false,
                isDLoader: false,
                isDelModal: false,
                step: 1
            });

            // editDataPage(null);
        }
        this.setState({
            addModal: !this.state.addModal,
            // editData: data
        });
        editDataPage(null);
    }

    editModal = async (data) => {
        // console.log(data);
        const { id } = data;
        this.setState({
            isEdit: true,
            editData: data,
            addModal: true,
            similarSctArr: [],
            simArray: [],
            appName: data.name_app,
            pfName: data.pageFileName,
            displayWebsite: data.isDisplay
        });

        try {

            const headers = {
                'Authorization': localStorage.getItem('token'),
               // "Access-Control-Allow-Origin": '*'
            }

            let formData = new FormData();
            formData.append('service_category_id', id);

            const response = await apiHalper('getAllMainServices', 'POST', formData, headers);
            // console.log(response);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    appName: data.name_app,
                    pfName: data.pageFileName,
                    formValid: {
                        cateText: true,
                        appName: true,
                        fpName: true,
                        pfName: true,
                        description: true
                    }
                });
                if (Array.isArray(data) && data.length > 0) {
                    const last_index = data.length - 1;
                    for (var i = 0; i < data.length; i++) {
                        if (i < last_index) {
                            if (i > 0) {
                                // console.log(data[i]);
                                try {
                                    const headers = {
                                        'Authorization': localStorage.getItem('token')
                                    }


                                    let formData = new FormData();
                                    formData.append('parent_service_category_id', data[i].id)
                                    const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
                                    formData.append('pageType', parentPath)
                                    const response = await apiHalper('getSubServices', 'POST', formData, headers);
                                    // console.log(response);
                                    const res = response.data;
                                    if (res.status) {
                                        const data = jwtDecode(res.data);
                                        if (Array.isArray(data)) {
                                            const selectVal1 = { label: data[i].name, value: data[i].id }
                                            const update = data.map(({ id, name }) => ({ value: id, label: name }));
                                            this.addMoreSelect(update, selectVal1);
                                        }
                                    }
                                } catch (err) {
                                    console.log(err.message);
                                }
                            } else {
                                const selectVal = { label: data[i].name, value: data[i].id }
                                this.setState({
                                    multiSelect: [
                                        { categoryOption: selectVal, isClearable: true, options: this.state.mainCate }
                                    ]
                                });
                                // this.addMoreSelect(this.state.mainCate, selectVal);
                            }
                        } else {
                            this.setState({
                                cateText: data[i].name,

                            })
                        }
                    }
                }

                // get similar services

                const respons = await apiHalper('getSimilarCategoryByServiceId', 'POST', formData, headers);
                const ress = respons.data;

                if (ress.status) {
                    const simiData = jwtDecode(ress.data);
                    let newArr = [];
                    for (let v = 0; v < simiData.length; v++) {
                        newArr.push(simiData[v].similarServiceCategoryID)
                    }
                    this.setState({
                        similarSctArr: simiData,
                        simArray: newArr,
                    })
                }
            }
        } catch (err) {
            console.log(err.message);
        }

    }

    openDeleModal = (data) => {
        this.setState({
            isDelModal: true,
            editData: data
        })
    }

    addMoreSelect = (options, selectVal) => {
        this.setState({
            multiSelect: [...this.state.multiSelect, { categoryOption: selectVal, isClearable: true, options: options }]
        })
    }

    RemoveClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: true }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    cateHandler = async (selectedOption, index) => {
        const { multiSelect } = this.state;
        const list = [...multiSelect];        
        list[index].categoryOption = selectedOption;
        this.setState({
            multiSelect: list
        });
    }

    updateSubC = async (selectedOption, index, selectVal) => {
        try {
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            if (index === 0) {
                this.setState({
                    multiSelect: [{ categoryOption: selectedOption, isClearable: true, options: this.state.mainCate }]
                })
            }

            let formData = new FormData();
            const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
            formData.append('parent_service_category_id', selectedOption.value)
            formData.append('pageType', parentPath)
            const response = await apiHalper('getSubServices', 'POST', formData, headers);
            // console.log(response);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (Array.isArray(data)) {
                    const update = data.map(({ id, name }) => ({ value: id, label: name }));
                    this.addMoreSelect(update, selectVal);
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    handleRemoveClick = (index) => {

        if (index > 0) {
            const list = [...this.state.multiSelect];
            list.splice(index, 1);
            // console.log(list);
            this.setState({ multiSelect: list });
        } else {
            this.setState({ multiSelect: [{ categoryOption: null, isClearable: true, options: this.state.mainCate }] })
        }
    };

    getCate = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const numberReg = new RegExp(/^[A-Z0-9!@#$%^&*()_+\\/=\\[\]{};':"\\|,.<>\\/?]*$/);
        // const numberReg = new RegExp(/^[-+]?\d+\.[0-9]+$/);
        switch (name) {
            case 'cateText':
                valid.cateText = value.length > 0;
                error.cateText = valid.cateText ? '' : 'Category is required'
                break;
            case 'appName':
                valid.appName = value.length > 0;
                error.appName = valid.appName ? '' : 'App Name is required'
                break;
            case 'fpName':
                valid.fpName = value.length > 0;
                error.fpName = valid.fpName ? '' : 'FastPaper App Name is required'
                break;
            case 'pfName':
                // console.log(numberReg.test(value));
                if (value.length > 0) {
                    if (!numberReg.test(value)) {
                        valid.pfName = true;
                        error.pfName = '';
                    } else {
                        valid.pfName = false;
                        error.pfName = 'Page File name must be string';
                    }
                } else {
                    valid.pfName = false
                    error.pfName = 'Page File Name is required';
                }
                break;
            case 'description':
                valid.description = value.length > 0;
                error.description = valid.description ? '' : 'Description is required'
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    submitSubCategory = async (e) => {
        e.preventDefault();
        try {            
            const { multiSelect, cateText, appName, displayWebsite, selectedOption } = this.state;
            this.setState({
                isSLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            const totalIndex = multiSelect.length;
            if (multiSelect[0].categoryOption !== null) {
                if (totalIndex > 1) {
                    if (multiSelect[totalIndex - 1].categoryOption !== null) {
                        formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                    } else {
                        formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                    }
                } else {
                    formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                }
                //formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
            } else {
                //formData.append('parent_service_category_id', 0)
                
            }
            
            if (totalIndex > 2) {
                if (multiSelect[totalIndex - 1].categoryOption !== null) {
                    formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                } else {
                    formData.append('parent_service_category_id', multiSelect[totalIndex - 2].categoryOption.value)
                }
            }
            
            const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
            formData.append('name', cateText)
            formData.append('name_app', appName)
            formData.append('isDisplay', displayWebsite ? 1 : 0)
            formData.append('pageType', parentPath)
           // formData.append('isFeatured', isFeatured)
            const response = await apiHalper('addServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data)
                this.setState({
                    apiError: '',
                    isSLoader: false,
                    addModal: false,
                    step: 2,
                    insertedId: decode.insertedId
                });
                this.getSubListC(selectedOption.value);
                toster_sucess(res.message);
            }
            else { 
                toster_error(res.message);
                this.setState({
                    isSLoader: false
                })
            }
        }
        catch(err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isLoader: false
            })
        }
    }

    submitCategory = async (e) => {
        e.preventDefault();
        const isUrl = new URLSearchParams(this.props.location.search).get('value');
        try {
            const { similarSctArr, simArray, insertedId, multiSelect } = this.state;
            if (similarSctArr.length > 0) {
                if (similarSctArr.length > 5) {
                    this.setState({
                        isSLoader: true
                    })
                    const headers = {
                        'Authorization': localStorage.getItem('token')
                    }
                    const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
                    let formData = new FormData();
                    formData.append('serviceCategoryID', insertedId)
                    formData.append('similarCategoryID', JSON.stringify(simArray))
                    formData.append('parentPath', parentPath)
                    const response = await apiHalper('addSimilarCategory', 'POST', formData, headers);
                    //const response = await apiHalper('addServices', 'POST', formData, headers);
                    const res = response.data;
                    // console.log(res)
                    if (res.status) {
                        this.setState({
                            apiError: '',
                            isSLoader: false,
                            addModal: false,
                            step: 2,
                            simArray: [],
                            similarSctArr: [],
                            simFilterVal: 1                            
                        });
                        const { history } = this.props;
                        const to = new URLSearchParams(this.props.location.search).get('to');
                        history.push(`/seo/add-pagecontent?id=${insertedId}&parentPath=${parentPath}&to=${to}`)
                        toster_sucess(res.message);
                    } else {
                        this.setState({
                            apiError: res.message,
                            isSLoader: false,
                            addModal: true
                        })
                    }
                } else {
                    this.setState({
                        apiError: 'Please Select Minimum 6 skills',
                        isSLoader: false
                    })
                }
            } else {
                this.setState({
                    apiError: 'Similar Skill is required.',
                    isSLoader: false
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isSLoader: false
            })
        }
    }

    modalDelClose = () => {
        this.setState({
            isDelModal: false
        })
    }

    deleteFunc = (e) => {
        e.preventDefault();
        // console.log('working...');
        try {

            const { editData } = this.state;

            this.setState({
                isDLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('service_category_id', editData.id);

            const response = apiHalper('deleteServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isDelModal: false,
                    editData: null,
                    isDLoader: false
                });
            }

        } catch (err) {
            console.log(err);
        }

    }

    stepHandler = () => {
        // console.log('if');
        const { formValid, multiSelect } = this.state;
        let disabled;
        if (multiSelect[0].categoryOption) {
            // || formValid.fpName 
            disabled = formValid.cateText || formValid.appName;
        } else {
            // || formValid.fpName
            disabled = formValid.cateText || formValid.appName;
        }
        // console.log(disabled);
        // const disabled = multiSelect[0].categoryOption !== null?formError.cateText !== '': formError.cateText !== '' || formError.appName !== '' || formError.fpName !== '' || formError.description !== '';
        if (disabled) {
            this.setState({
                step: 2,
                apiError: '',
                simArray: [],
                SubCateList: [],
                searchSkillArr: [],
                similarSctArr: []
            });
            this.getSubListC(this.state.simFilterVal);
        } else {
            // console.log('else');
            this.setState({
                step: 1,
                apiError: 'Please fill the form'
            })
        }
    }

    getSubListC = async (value) => {
        if (this._unmounted) {

            this.setState({
                isCLoader: true,
                isMainLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
            let formData = new FormData();
            formData.append('parent_service_category_id', value)
            formData.append('pageType', parentPath)
            const response = await apiHalper('getSubServices', 'POST', formData, headers);
            //console.log(response);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (Array.isArray(data) && data.length > 0) {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        SubCateList: data,
                        searchSkillArr: data,
                        subMessage: ''
                    })
                } else {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        SubCateList: [],
                        searchSkillArr: [],
                        subMessage: 'No Record Found'
                    })
                }
            } else {
                this.setState({
                    isMainLoader: false,
                    isCLoader: false,
                    SubCateList: [],
                    searchSkillArr: [],
                    subMessage: res.message
                })
            }
        }
    }

    searchSkill = async (e) => {
        e.preventDefault();
        const { searchSkillArr, searchVal } = this.state;

        const search = searchSkillArr.filter(info => info.name.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchSkillArr: search
        })
    }
    
    skillSearchInput = (e) => {
        e.preventDefault();
        const { value } = e.target;
        const { SubCateList } = this.state;

        if (value.length > 0) {
            const search = SubCateList.filter(info => info.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchSkillArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchSkillArr: SubCateList,
                searchVal: value
            })
        }
    }

    prevSubcategory = () => {
        this.setState({
            step: 1
        });
    }

    radioHandler = (e) => {
        const { value } = e.target;
        // console.log(value);
        this.setState({
            simFilterVal: value
        });

        this.getSubListC(value);
    }

    removeSelect = (data) => {
        let nameArr = this.state.similarSctArr;
        let simArray = this.state.simArray;

        for (let i = 0; i < nameArr.length; i++) {
            if (nameArr[i].id === data.id) {
                // console.log(nameArr[i]);
                nameArr.splice(i, 1);
                simArray.splice(i, 1);
                i--;
            }
        }
        this.setState({
            similarSctArr: nameArr,
            simArray: simArray
        })
    }

    toggleCli = (data) => {
        // console.log(data);
        let newArray = this.state.similarSctArr;
        let simArray = this.state.simArray;
        document.getElementById('allSkil' + data.id).setAttribute('checked', true);
        document.getElementById('allSkil' + data.id).setAttribute('class', 'active');

        newArray.push({ id: data.id, name: data.name });
        simArray.push(data.id);


        for (let h = 0; h < newArray.length; h++) {
            let curItem = newArray[h];
            let foundCount = 0;
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id === newArray[h].id) {
                    foundCount++;
                };
            }
            if (foundCount > 1) {
                for (let j = 0; j < newArray.length; j++) {
                    if (newArray[j].id === curItem.id) {
                        newArray.splice(j, 1);
                        simArray.splice(j, 1);
                        j--;
                    }
                }
            }
        }

        if (newArray.length > 0) {
            return this.setState({
                similarSctArr: newArray,
                simArray: simArray
            })
        } else {
            return this.setState({
                similarSctArr: [],
                simArray: []
            })
        }
    }

    similarSelectedRender = (data, i) => {

        return <li key={i}>
            <label htmlFor={"web" + i}>
                <input type="checkbox" id={"web" + i} value={data.id} name="selectSimilar" defaultChecked onClick={() => this.removeSelect(data)} className="active" />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    allSubListRender = (data, i) => {
        const { simArray } = this.state;
        let activeClass = '';
        let checked1 = false;
        // console.log(simArray.includes(data.id));
        if (simArray.includes(data.id)) {
            // console.log('if');
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
            <label htmlFor={"allSkil" + data.id}>
                <input type="checkbox" id={"allSkil" + data.id} value={data.id} name="allSkil" onClick={() => this.toggleCli(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    render() {

        const { mainCate } = this.state;

        const { multiSelect, cateText, apiError, isSLoader, formError, formValid, similarSctArr, isMainLoader, displayWebsite } = this.state;
        // || !formValid.appName || !formValid.fpName || !formValid.fpName
        const disabled = !formValid.cateText || !formValid.appName || !multiSelect[0].categoryOption;
        // console.log(this.state.SubCateList);
        const disabled2 = similarSctArr.length > 5;
        let h2Select = this.state.cateText;
        const { location } = this.props;
        const isUrl = new URLSearchParams(location.search).get('value');

        return (
            <section className="bg-balance">
                <div className="page-subcategory">
                    <div className="modal_header">
                        <h4 className="model_title">{this.state.step === 1 ? isUrl === 'sub-services' ? "Add Sub Services" : "Add Sub Category" : "Similar Services"}
                            {isUrl === 'sub-services' ? 
                                this.state.step === 1 ? <Link className="btn-back" to="/seo/services-skills">Back</Link> : null
                            :
                                this.state.step === 1 ? <Link className="btn-back" to="/seo/services">Back</Link> : null
                            }
                            
                        </h4>
                    </div>
                    {this.state.step === 1 ?
                        <form action="/" method="POST" onSubmit={this.submitSubCategory}>
                            {multiSelect.map((x, i) =>
                                <div className="form_group" key={i}>
                                    <Select className="select_out" value={x.categoryOption} isSearchable={true} placeholder={"Select"} onChange={(e) => this.cateHandler(e, i)} options={x.options} />
                                    {this.state.isSelectLoader ? <span className="select-loader"><img src={LoaderImg} className="small-loader" alt="loader" /></span> : null}
                                </div>
                            )}
                            <div className="form_group">
                                <input type="text" name="cateText" className={formError.cateText !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Skill Name" value={cateText} onChange={this.getCate} autoComplete="off" />
                            </div>

                            <div className="form_group">
                                <input type="text" name="appName" className={formError.appName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="App Name" value={this.state.appName} onChange={this.getCate} autoComplete="off" />
                            </div>
                            <div className="form_group" style={{display:'flex', gap:'10px'}}>
                                <div className="checkbox-group">
                                    <input type="checkbox" value={displayWebsite} name="displayWebsite" id="mainmenu" defaultChecked={1} onChange={(e) => this.displaySiteChecked(e)} className="styled-checkbox" />
                                    <label htmlFor="mainmenu">Display on website</label>
                                </div>
                                {/* <div className="checkbox-group">
                                    <input type="checkbox" value={isFeatured} name="isFeatured" id="isFeatured" defaultChecked={1} onChange={(e) => this.isFeaturedChecked(e)} className="styled-checkbox" />
                                    <label htmlFor="isFeatured">IsFeatured</label>
                                </div> */}
                            </div>
                            {apiError !== '' ? <p className="error_text">{apiError}</p> : null}
                            {this.state.apiError !== '' ? <p className="error_text">{this.state.apiError}</p> : null}
                            <div className="bg_submit2 right-mx120">
                                <button type="submit" className="btn-submit" disabled={disabled}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save & Next"}</button>
                            </div>
                        </form>
                        :
                        <form action="/" method="POST" onSubmit={this.submitCategory}>
                            <div className="main_cat">
                                {multiSelect[0].categoryOption ?
                                    <h2 className="text_sel">Selected:
                                        <span>{h2Select}</span>
                                    </h2>
                                    :
                                    null
                                }
                                <ul className="radioBt">
                                    {Array.isArray(mainCate) ?
                                        mainCate.map((info, i) => {
                                            return <li key={i}>
                                                <label>
                                                    <input type="radio" name="mainCate" defaultChecked={info.value === this.state.simFilterVal} value={info.value} onChange={this.radioHandler} />
                                                    <span className="label_text">{info.label}</span>
                                                </label>
                                            </li>
                                        })
                                        :
                                        null
                                    }
                                </ul>
                                <div className="conte_bloc">
                                    <div className="select_divd">
                                        <ul className={this.state.similarSctArr.length > 0 ? '' : 'no-result'}>
                                            {Array.isArray(this.state.similarSctArr) && this.state.similarSctArr.length > 0 ?
                                                this.state.similarSctArr.map((data, i) => this.similarSelectedRender(data, i))
                                                :
                                                <li>No Data Found</li>
                                            }
                                        </ul>
                                    </div>

                                    <div className="select_divd">
                                        <div className="search-form">
                                            <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search Skill" onChange={this.skillSearchInput} />
                                            <button type="button"><img src={SearchIcon} alt="Search" /></button>
                                        </div>
                                        
                                            <ul className={this.state.searchSkillArr.length > 0 ? '' : 'no-result'}>
                                                {isMainLoader ? <NoDataIndication /> : Array.isArray(this.state.searchSkillArr) && this.state.searchSkillArr.length > 0 ?
                                                        this.state.searchSkillArr.map((data, i) => this.allSubListRender(data, i)) : <li>No Data Found</li> 
                                                }
                                            </ul>
                                        
                                    </div>
                                </div>
                                <span className="small-caps">Please Select Minimum 6 Skills</span>
                            </div>
                            {this.state.apiError !== '' ? <p className="error_text">{this.state.apiError}</p> : null}
                            <div className="bg_submit2 right-mx120 two-button">
                                {this.state.step === 1 ? <button type="button" className="btn-submit" disabled={disabled} onClick={this.stepHandler}>{"Save & Next"}</button> : null}
                                {this.state.step === 2 ? <><button type="Submit" className="btn-submit" onClick={this.prevSubcategory}>Prev</button> <button type={"Submit"} className="btn-submit" disabled={!disabled2 || isSLoader}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save & Next"}</button> </> : null}
                            </div>
                        </form>
                    }
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    editDataPage: (data) => dispatch({ type: ACTION_TYPES.EDIT_DATA_STORE, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(addSubcategory));