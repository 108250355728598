import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
import VerifyModal from './VerifyModal';
import Modal from 'react-bootstrap/Modal';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';
import DatePicker from 'react-datepicker'
import Select from 'react-select';

import './index.css'

class UnverifiedUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            isLoader: false,
            unverifiedUData: [],
            imageVerify: '',
            isModal: false,
            editData: null,
            isVerifyModal: false,
            verifyMessage: '',
            isDLoader: false,
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            startDate: '',
            endDate: '',
            selectedUser: 0,
        }
    }

    componentDidMount() {
        const { page, search, sizePerPage, startDate, endDate, selectedUser } = this.state;
        this.getUnverifiedUser(page, search, sizePerPage, startDate, endDate, selectedUser);
    }

    getUnverifiedUser = async (page, search, sizePerPage, startDate, endDate, selectedUser) => {
        try {
            this.setState({
                isLoader: true,
                unverifiedUData: []
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('page_no', page)
            formData.append('search_by', search)
            formData.append('limit', sizePerPage)
            if (startDate === '' || startDate === null) {
                formData.append('start_date', '')
            }
            else {
                formData.append('start_date', moment(startDate).format('YYYY-MM-DD'))
            }
            if (endDate === '' || endDate === null) {
                formData.append('end_date', '')
            }
            else {
                formData.append('end_date', moment(endDate).format('YYYY-MM-DD'))
            }
            formData.append('profile_type', selectedUser.value ? selectedUser.value : 0)

            const response = await apiHalper('getUnverifiedUsers', 'POST', formData, headers)
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(response.data.data);
                console.log('data', data);
                this.setState({
                    isLoader: false,
                    unverifiedUData: data.userData,
                    totalRecord: data.count
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'verifications_file':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            this.getUnverifiedUser(page, searchText, sizePerPage, this.state.startDate, this.state.endDate, this.state.selectedUser);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getUnverifiedUser(page, searchText, sizePerPage, this.state.startDate, this.state.endDate, this.state.selectedUser);
        }
    }
    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }
    userName = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = row.profile_type_id == 1 ? `${decodePath.img}${row.img}` : `${decodePath.client_img}${row.img}`
        return <a className="tb_user_wrap user-prof" href={`/usersDetails/${row.id}?type=${row.profile_type_id == 1 ? "agents": "clients"}`}>
            <div className="useimg_block online_icon">
                <img src={row.img !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </a>
    }
    phoneNumber = (cell, row) => {
        console.log("row", row);
        return <div className="tb_user_wrap user-prof">
            <p>{row.contact_no}</p>
        </div>
    }
    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    userType = (cell, row) => {
        console.log("row", row);
        return <div className="tb_user_wrap user-prof">
            <p>{row.profile_type_id == 1 ? "Agent" : row.profile_type_id == 2 ? "Clinet" : null}</p>
            {/* <p>Agent</p> */}
        </div>
    }
    reference = (cell, row) => {
        return <div className="tb_user_wrap user-prof">
            {row.profile_type_id == 1 ? <p>
                {row.is_mawthooq_number == 1 ? <a target='_blank' href={'https://elaam.gamr.gov.sa/gcam-licenses/gcam-celebrity-check/' + row.mawthooq_id}>{row.mawthooq_id}</a> : null}
            </p> : row.profile_type_id == 2 ? <p>{row.cr_number}</p> : null}

        </div>
    }
    mawthooq = (cell, row) => {
        console.log("row", row);
        return <button type="button" className="btn-status blue margin_0auto">View</button>;
    }
    verification = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>
    }
    typeFormat = (cell) => {
        return cell
    }
    verificationFile = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        // const imgPath = `${decodePath.img_id}${row.verifications_file}`
        const mawthooqImgPath = `https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/agentApi/webroot/mawthooq_id/${row.mawthooq_id}`
        const crImgPath = `https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/clientApi/webroot/commercial_registration/${row.cr_file}`
        return <div className="tb_user_wrap verification-prof">
             {row.profile_type_id == 1 ? <p>
                {row.is_mawthooq_number == 0 && row.mawthooq_id ? <a target='_blank' href={mawthooqImgPath}><button type="button" className="btn-show-icon"></button></a> : null}
            </p> : row.profile_type_id == 2 && row.cr_file ? <a target='_blank' href={crImgPath}><button type="button" className="btn-show-icon"></button></a> : null}
            {/* <a href=''></a> */}
                
        </div>

    }
    statusField = (cell, row) => {
        if (row.status === '2') {
            return <button type="button" className="btn-status green margin_0auto" disabled>Verified</button>;
        } else {
            return <button type="button" className="btn-status blue margin_0auto" onClick={() => this.openVerifyModal(row)}>Verify</button>;
        }
    }
    openImageModal = (data) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/agentApi/webroot/mawthooq_id/${data.mawthooq_id}`
        this.setState({
            isModal: true,
            imageVerify: imgPath !== null ? imgPath : DummyProfile
        })

        console.log(imgPath)
    }
    closeImageModal = () => {
        this.setState({
            isModal: false
        })
    }

    openVerifyModal = (data) => {
        this.setState({
            isVerifyModal: true,
            editData: data
        })
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }
    applyDates = () => {
        this.getUnverifiedUser(this.state.page, this.state.search, this.state.sizePerPage, this.state.startDate, this.state.endDate, this.state.selectedUser);
    }
    modalVerifyClose = () => {
        this.setState({
            isVerifyModal: false,
            editData: null
        })
    }
    handleUserChange = selectedUser => {
        this.setState({ selectedUser });
        const { page, search, sizePerPage, startDate, endDate } = this.state;
        this.getUnverifiedUser(page, search, sizePerPage, startDate, endDate, selectedUser);
    };
    verifyFunc = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                isDLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('profile_id', this.state.editData.id)
            formData.append('profile_type_id', this.state.editData.profile_type_id)

            const response = await apiHalper('verifyProfile', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isVerifyModal: false,
                    editData: null,
                    verifyMessage: '',
                    isDLoader: false
                });

                const { page, search, sizePerPage, startDate, endDate, selectedUser } = this.state;
                this.getUnverifiedUser(page, search, sizePerPage, startDate, endDate, selectedUser);
                toster_sucess("Verified Successfully..");
            } else {
                this.setState({
                    isVerifyModal: false,
                    editData: null,
                    verifyMessage: res.message,
                    isDLoader: false
                });
                toster_error(res.message);
            }

        } catch (err) {
            // console.log(err);
            this.setState({
                verifyMessage: 'Network Error',
                isDLoader: false
            });
        }
    }

    render() {
        const columns = [
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter: this.userName },
            { dataField: 'user_type', text: 'User Type', sort: true, headerFormatter: this.headerSpan, formatter: this.userType },
            { dataField: 'phone_number', text: 'Phone Number', sort: true, headerFormatter: this.headerSpan, formatter: this.phoneNumber },
            { dataField: 'signup_date', text: 'Register Date', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'reference', text: 'Reference', sort: true, headerFormatter: this.headerSpan, formatter: this.reference },
            { dataField: 'verifications_file', text: 'Verification File', sort: true, headerFormatter: this.headerSpan, formatter: this.verificationFile },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.unverifiedUData,
            keyId: 'profile_verifications_id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            isCsv: false,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '250', value: 250 },
                { text: '500', value: 500 }
            ]
        };

        const userOptions = [
            { value: 0, label: 'All User' },
            { value: 1, label: 'Stars' },
            { value: 2, label: 'Brands' }
        ];
        const verifyModal = {
            handleClose: this.modalVerifyClose,
            isModal: this.state.isVerifyModal,
            verifyFunc: this.verifyFunc,
            isLoader: this.state.isDLoader
        }

        return (
            //     <div className="tab_container">
            <div className="tab_container push-offer-pg">
                
                <div className="location_select_wrap export-data-modal">
                    <DatePicker placeholderText="Select Start Date" selected={this.state.startDate} minDate={new Date("2018-01-01")} maxDate={new Date()} onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />
                    <DatePicker placeholderText="Select End Date" selected={this.state.endDate} onChange={date => this.setEndDate(date)} minDate={this.state.startDate} maxDate={new Date()} endDate={this.state.endDate} dateFormat="yyyy-MM-dd" />
                    <button type="button" onClick={this.applyDates} className="btn btn-primary">Apply</button>
                </div>
                <div className="filter_btnGroup flex-filter">
                    <Select value={this.state.selectedUser} onChange={this.handleUserChange} options={userOptions} placeholder="User Type" />
                </div>
                {/* <section className="bg-balance"> */}

                {/* <div className="container-fluid">

                        <div className="balance_wrap settings"> */}

                <DataTable TableData={tableData} />
                <VerifyModal data={verifyModal} />
                <Modal show={this.state.isModal} onHide={() => this.closeImageModal()} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view image-view">
                            <div className="body_modals body_modals2">
                                <img src={this.state.imageVerify} alt="" />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* </div>
                    </div> */}
                {/* </section> */}
            </div>
        )
    }
}

export default withRouter(connect(null)(UnverifiedUser))