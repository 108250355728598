import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
// import TableView from '../TableView';
import NoDataIndication from '../TableLoader';
import { apiDeviceHelper } from '../../helpers/ApiHelper';
import moment from 'moment';
import DataTable from '../JqueryDataTable';

class Profit extends Component {

    constructor(props){
        super(props);
        this.state={
            selectedOption:null,
            isClearable:true,
            isLoader:false,
            profitData:[],
            page:1,
            search:'',
            aBalance:null,
            pBalance:null,
            tBalance:null
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page, selectedOption } = this.state;
        this.getProfitApi(page, selectedOption);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getProfitApi = async (page, selected) =>{

        if(this._ismounted){
            this.setState({
                isLoader:true
            });

            // const { selectedOption, page } = this.state;

            let formData = new FormData();
            formData.append('sort_by', selected!==null?selected.value:'')
            formData.append('page_no', page)
            const response = await apiDeviceHelper('Withdrawals/getProfits.json','POST',formData, null);
            if(response.data.flag === 1){
                const data = response.data.data;
                const total_balance = (data.totals.total_deposit + data.totals.total_paid);
                this.setState({
                    isLoader:false,
                    profitData:data.profits,
                    aBalance:data.totals.total_deposit,
                    pBalance:data.totals.total_paid,
                    tBalance:total_balance.toFixed(2)
                });
            }else{
                this.setState({
                    isLoader:false,
                    profitData:[],
                    aBalance:0,
                    pBalance:0,
                    tBalance:0
                });
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getProfitApi(this.state.page, selectedOption)
    };

    toggleClearable = () =>{
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'timestamp':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
        // return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
    }

    statusField = (cell, row) =>{
        
        return <div className="group_btn text-center">
                {row.user_type === 'Agent'?
                    <button type="button" className="btn-status light_gray">Agent</button>
                        :
                    <button type="button" className="btn-status dark_gray">Client</button>
                }
                {row.trans_type === 'Paid'?
                    <button type="button" className="btn-status green">Paid</button>
                    :
                    <button type="button" className="btn-status blue">Deposit</button>
                }
                        
        </div>
        
    }

    dateField = (cell, row) =>{
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    amountField = (cell, row) =>{
        return <span className="text-center margin_0auto"><strong>${cell}</strong> <b className={row.pstatus === 0?"text-blue":"text-green" }>${cell}</b></span>;
    }

    jobId = (cell)=>{
        return `Paid for job ID ${cell}`
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
            this.getIncomeApi(page, searchText);
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
            this.getIncomeApi(page, searchText);
        }
    }

    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        const options = [
            { value: 1, label: 'Client' },
            { value: 2, label: 'Agent' },
            { value: 1, label: 'Deposite' },
            { value: 2, label: 'Paid' }
        ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'job_post_id', text: 'Job Details', sort: true, headerFormatter: this.headerSpan, formatter:this.jobId }, 
            { dataField: 'timestamp', text: 'Date', sort: true, headerFormatter: this.headerSpan, formatter:this.dateField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField }
        ];

        // const data = [
        //     { id:'1', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'2', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'3', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:1, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'4', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:1, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'5', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'6', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'7', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'8', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:1, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'9', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'10', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'11', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'12', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'13', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:1, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'14', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:0, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'15', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        //     { id:'16', jobId:'Paid for job ID 789546O', time:'Oct 10, 2020', pstatus:0, status:1, amount:'+$21.30', profit:'+$1.00' },
        // ]

        const tableData = {
            columns:columns,
            data:this.state.profitData,
            keyId:'id',
            loading:this.state.isLoader,
            message:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.props.incomeCount,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { selectedOption, isClearable, aBalance, pBalance, tBalance } = this.state;

        return (
            <Fragment>
                <div className="tab_container">
                    <ul className="wiget_wrap">
                        <li>
                            <div>
                                <h5>{aBalance} USD</h5>
                                <p>Availabel Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{pBalance} USD</h5>
                                <p>Pending Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{tBalance} USD</h5>
                                <p>Total Balance</p>
                            </div>
                        </li>
                    </ul>
                    <div className="total_balance">${tBalance}</div>
                    <div className="select_wrap">
                        <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                    </div>
                    <DataTable TableData={tableData} />           
                </div>
            </Fragment>
        )
    }
}
export default withRouter(connect(null)(Profit));
