import axios from 'axios';
import axiosInstance from '../axiosConfig'

let nft_url = "https://fs6jtboen7.execute-api.us-east-2.amazonaws.com/dev/";

export const apiHalper = (api, method, data) => {
    const apiUrl = api; 
    return new Promise((resolve, reject) => {
        axiosInstance({
            method: method,
            url: apiUrl,
            data: data
        })
            .then(res => resolve(res))
            .catch(error => reject(error));
    });
}

export const apiHelperNFT = (api, method, data, headers) => {
    const apiUrl = nft_url + api;
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            data: data,
            url: apiUrl,
            headers: headers
        })
            .then(res => resolve(res))
            .catch(error => reject(error));
    });
}

export const apiDeviceHelper = (api, method, data, headers) => {
    const apiUrl2 = 'https://adminapi.24task.com/' + api;
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: apiUrl2,
            data: data,
            headers: headers
        })
            .then(res => resolve(res))
            .catch(error => reject(error));
    });
}
