import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../assets/images/user-loader.gif';
import './index.css'

class VerifyModal extends Component {
    render() {
        const { isLoader, isModal, handleClose, verifyFunc } = this.props.data;
        return (
            <Modal show={isModal} onHide={()=>handleClose()} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">                      
                        <div className="body_modals body_modals2">
                            <form method="POST" action="#" onSubmit={(e)=>verifyFunc(e)}>
                                <div className="form_body2">
                                    <p className="delete_text">Verify Record<span>Are you sure you want to verify?</span></p>
                                </div>
                                <div className="bg_submit2 mr-top flex-center">
                                    <button type="submit" className="btn-submit" disabled={isLoader} >{isLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Yes"}</button>
                                    <span>Or</span>
                                    <button type="button" className="btn_cancel2" onClick={()=>handleClose()}>No</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(VerifyModal));