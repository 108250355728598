import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import * as ACTION_TYPES from '../../../redux/actions/types';
import NoDataIndication from '../../TableLoader';
// import Modal from 'react-bootstrap/Modal';
// import LoaderImg from '../../../assets/images/user-loader.gif';
import DataTable from '../../JqueryDataTable';
import DeleteModal from '../DeleteModal';
import { apiHelperNFT } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper';
import { toster_sucess } from '../../../helpers/toster_helper';
import moment from 'moment';

class NFTPageContent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            listContent: [],
            listCount: 0,
            editData: null,
            isLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            selectedLanguage: { value: 1, label: 'English' },
            isClearable: null,
            addModal: false,
            isSLoader: false,
            isDLoader: false,
            isDelModal: false,
            deleteMessage: ''
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getListPage(this.state.page, this.state.search);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getListPage = async (page, search) => {
        try {
            if (this._unmounted) {

                this.setState({
                    isLoader: true,
                    listContent: []
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('page_no', page)
                formData.append('search_by', search)
                formData.append('languageID', this.state.selectedLanguage ? this.state.selectedLanguage.value : 1)

                const response = await apiHelperNFT('getPages', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    this.setState({
                        isLoader: false,
                        listContent: data.page_date,
                        listCount: data.count
                    })
                } else {
                    this.setState({
                        isLoader: false,
                        listContent: [],
                        listCount: 0
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    handleChange = selectedLanguage => {
        this.setState({
            selectedLanguage
        }, () => {
            this.getListPage(this.state.page, this.state.search);
        });

    };

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            // pd_left
            case 'pageName':
                return <span className={column.sort ? "header_span sort " : "header_span"}>{column.text}</span>;
            // case 'pageName':
            //     return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'pageContent':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'keyword':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'count_reports':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'project_amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    headingField = (cell, row) => {
        return <span className="text_limit">{cell}</span>;
    }

    contentField = (cell, row) => {
        return cell !== null && cell !== '' ? <span className="text_green text-center margin_0auto">Available</span> : <span className="text_red text-center margin_0auto">Unavailable</span>;
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">0</span>;
    }

    actionField = (cell, row) => {
        return <div className="action_group text-center">
            {/* <button type="button" className="btn_edit2" onClick={() => this.toggleModalAdd(row)}></button> */}

            <button type="button" className="btn_edit2" onClick={() => this.toggleModalAdd(row)}></button>
            {/* <button type="button" className="btn_delete" onClick={() => this.openDeleModal(row)}></button> */}
        </div>
    }
    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }
    statusField = (cell) => {

        if (cell === '1') {
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }

    }

    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage,
            });
            this.getListPage(page, searchText);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getListPage(page, searchText);
        }

        // this.getClientApi(page, searchText);
    }

    toggleModalAdd = async (data) => {
        try {
            const { history } = this.props;
            if (data.languageID === 1) {
                history.push(`/seo/nft-content-details?id=${data.id}&page=NFTcontent`);
            }
            else {
                history.push(`/seo/nft-content-details?id=${data.id}&page=NFTcontentArabic`);
            }

        } catch (err) {
            console.log(err.message);
        }
    }

    openDeleModal = (data) => {
        this.setState({
            isDelModal: true,
            editData: data
        })
    }

    modalDelClose = () => {
        this.setState({
            isDelModal: false,
            editData: null
        })
    }

    deleteFunc = async (e) => {
        e.preventDefault();
        try {
            if (this._unmounted) {

                this.setState({
                    isDLoader: true
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('pageID', this.state.editData.id)

                const response = await apiHelperNFT('deletePages', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    this.setState({
                        isDelModal: false,
                        editData: null,
                        deleteMessage: '',
                        isDLoader: false
                    });
                    this.getListPage(this.state.page, this.state.search);
                    toster_sucess("Delete Successfully..");
                } else {
                    this.setState({
                        isDelModal: true,
                        editData: null,
                        deleteMessage: res.message,
                        isDLoader: false
                    });
                }
            }

        } catch (err) {
            // console.log(err);
            this.setState({
                deleteMessage: 'Network Error',
                isDLoader: false
            });
        }
    }

    render() {

        const options = [
            { value: 1, label: 'English' },
            { value: 2, label: 'Arabic' }
        ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'pageName', text: 'Page Name', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'updatedAt', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'pageHeading', text: 'Page Heading', sort: true, headerFormatter: this.headerSpan, formatter: this.headingField },
            { dataField: 'pageContent', text: 'Page Content', sort: true, headerFormatter: this.headerSpan, formatter: this.contentField },
            // { dataField: 'keyword', text: 'Keyword', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            // { dataField: 'count_reports', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.listContent,
            keyId: 'id',
            loading: this.state.isLoader,
            message: this.state.messageApi !== '' ? this.state.messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.listCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        const deleteModal = {
            handleClose: this.modalDelClose,
            isModal: this.state.isDelModal,
            deleteFunc: this.deleteFunc,
            isLoader: this.state.isDLoader
        }

        const { selectedLanguage, isClearable } = this.state;

        return (
            <section className="bg-balance page-content-detail">
                <div className="container-fluid">
                    <div className="balance_wrap">
                        <div className="select_wrap users_filters lang_opt">
                            <label>Select Language</label>
                            <Select value={selectedLanguage} isClearable={isClearable} placeholder={"Select Language"} onChange={this.handleChange} options={options} />
                        </div>
                        {/* <TableView TableData={tableData} />   */}
                        <DataTable TableData={tableData} />
                    </div>
                </div>

                <DeleteModal data={deleteModal} />
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    editStore: (data) => dispatch({ type: ACTION_TYPES.EDIT_DATA_STORE, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(NFTPageContent));