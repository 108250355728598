import { useEffect, useState } from "react";
import { jwtDecode } from "../../helpers/jwt_helper";
import { getAllCompany } from "../Companies/CompaniApiHelper";
import { apiHalper } from "../../helpers/ApiHelper";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal";
import SocialPlatformModal from "./SocialPlatformModal";
import SCDataTable from "./SCDataTable";

const SocialPlatform = () => {
  const [dataList, setDataList] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [socialPlatform, setSocialPlatform] = useState();
  const [approveData, setApproveData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isUserProModal, setIsUserProModal] = useState(false);
  const [rejectData, setRejectData] = useState();
  const closeModal = () => {
    setIsRequestModal(false);
  };

  const getPage = async (page, search) => {
    setIsLoader(true);
    const res = await getAllCompany(
      `getSocial?page_no=${page}&search=${search}`,
      "GET"
    );
    if (res.data.status === true) {
      setIsLoader(false);
      const data = jwtDecode(res.data.data);
      setDataList(data.data);
      setTotalRecord(data.count);
    }
  };

  const getData = async () => {
    const res = await getAllCompany(`getSocialPlatformsTypes`, "GET");
    if (res.data.status === true) {
      const data = jwtDecode(res.data.data);
      setSocialPlatform(data);
      // let array = [];
      // const MainData = data?.map((data) => {
      //   return array.push({ value: data.id, label: data.name });
      // });

      // setDataList(data.data);
      // setTotalRecord(data.count);
    }
  };
  const headerSpan = (column, colIndex) => {
    switch (column.dataField) {
      default:
        return (
          <span className={column.sort ? "header_span sort" : "header_span"}>
            {column.text}
          </span>
        );
    }
  };

  const statusField = (cell, row) => {
    if (cell === "1") {
      return (
        <button
          type="button"
          className={"btn-status green margin_0auto"}
          disabled
        >
          <span className="text-white">{"Approved"}</span>
        </button>
      );
    }
    if (cell === "3") {
      return (
        <button
          type="button"
          className={"btn-status orange  margin_0auto"}
          disabled
        >
          {"Pending"}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={"btn-status red  margin_0auto"}
          disabled
        >
          {"Rejected"}
        </button>
      );
    }
  };

  const RejectRequest = async () => {
    const formData = new FormData();
    formData.append("id", rejectData.id);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    const res1 = await apiHalper("deleteSocial", "POST", formData, headers);
    if (res1.data.status === true) {
      getPage(1, "");
      toast.success("Reject Request Successfully!");
      setIsUserProModal(false);
    } else {
      toast.error("Reject Request  Failed");
      setIsUserProModal(false);
    }
  };

  const ActionFiled = (cell, row) => {
    // if (cell === 3) {
    return (
      <>
        <div className="action_group ">
          {/* <button className="btn-view-icon" onClick={() => this.viewCouponCode(row)}></button> */}
          <button
            type="button"
            className="btn_edit2"
            onClick={() => {
              // setUpdateData(row);
              // setIsCompanyModal(!isCompanyModal);
              // setIsAdd(false);
              setApproveData(row);
              setIsRequestModal(!isRequestModal);
            }}
          ></button>
          <button
            type="button"
            className="btn_delete"
            onClick={() => {
              setIsUserProModal(true);
              setRejectData(row);
            }}
          ></button>
        </div>
        {/* <div className="d-flex">
            <button type="button" className={"btn-status blue margin_0auto"} onClick={()=>{setApproveData(row);setIsRequestModal(true)}}>
              {"Approve"}
            </button>
            <button type="button" className={"btn-status red margin_0auto"} onClick={()=>{setIsUserProModal(true);setRejectData(row)}}>
              {"Reject"}
            </button>
          </div> */}
      </>
    );
    // }
    // else{
    //   return(
    //     <div className="text-center">
    //       {"-"}
    //     </div>
    //   )
    // }
  };

  const imageField = (cell, row) => {
    const url = jwtDecode(localStorage.getItem("filePath"));
   
    return (
      <div
        className="action_group text-center"
        style={{ height: "75px", width: "75px" }}
      >
        <img src={`${row.filename}`} className="w-100 h-100" />
      </div>
    );
  };

  const imagegaryField = (cell, row) => {
    const url = jwtDecode(localStorage.getItem("filePath"));
    return (
      <div
        className="action_group text-center"
        style={{ height: "75px", width: "75px" }}
      >
        <img
          src={`${row.filename_gray}`}
          className="w-100 h-100"
        />
      </div>
    );
  };
  const socialField = (cell, row) => {

    const matchedPlatform = socialPlatform?.find(
      (platform) => platform.id === cell
    );

    return <div>{matchedPlatform?.name}</div>;
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: headerSpan,
    },
    {
      dataField: "name_ar",
      text: "Name Ar",
      sort: true,
      headerFormatter: headerSpan,
    },
    {
      dataField: "fiilename",
      text: "Image",
      sort: true,
      headerFormatter: headerSpan,
      formatter: imageField,
    },
    {
      dataField: "filename_gray",
      text: "Image Gray",
      sort: true,
      headerFormatter: headerSpan,
      formatter: imagegaryField,
    },
    {
      dataField: "web_url",
      text: "Link",
      sort: true,
      headerFormatter: headerSpan,
    },
    {
      dataField: "social_platform_type_id",
      text: "Social Platform",
      sort: true,
      headerFormatter: headerSpan,
      formatter: socialField,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: headerSpan,
      formatter: statusField,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerFormatter: headerSpan,
      formatter: ActionFiled,
    },

    // {
    //   dataField: "link",
    //   text: "Link",
    //   sort: true,
    //   headerFormatter: headerSpan,
    // },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   headerFormatter: headerSpan,
    // },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   headerFormatter: headerSpan,

    // },
  ];

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder, data }
  ) => {
    let result;
    if (type === "search") {
      if (!searchText) {
        // getList();
        getPage(1, "");
      } else {
        getPage(1, searchText);
        // const getdata = async () => {
        //   const res = await getAllCompany(
        //     `getCompany/${page}/${searchText}`,
        //     "GET"
        //   );
        //   if (res.data.status === true) {
        //     setIsLoader(false);
        //     const data = jwtDecode(res.data.data);
        //     setTotalRecord(data.data.length);
        //     setCompanyList(data.data);
        //   }
        // };
        // getdata();
      }
    }
    if (sortOrder === "asc") {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setDataList(result);
    // this.setState(() => ({
    //     data: result
    // }))
    if (type === "pagination") {
      setPageNumber(page);
      getPage(page, "");
      // this.setState(
      //   {
      //     page,
      //     sizePerPage,
      //   },
      //   () => {
      //     getCoupList(page, searchText);
      //     this.getAllCoupon();
      //   }
      // );
    }
  };

  const tableData = {
    columns: columns,
    data: dataList,
    keyId: "id",
    loading: isLoader,
    searching: false,
    // message: messageApi !== '' ? messageApi : 'Table is Empty',
    // noDataIndication: <NoDataIndication />,
    page: pageNumber,
    totalRecord: totalRecord,
    sizePerPage: 10,
    handleTableChange: handleTableChange,
    // overlay:overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } })
  };

  useEffect(() => {
    getData();
    // getList();
    getPage(1, "");
  }, []);

  const modalCloseConfirm = () => {
    setIsUserProModal(false);
  };

  const confirmModalData = {
    isOpen: isUserProModal,
    modalClose: modalCloseConfirm,
    text: "Delete user Request?",
    confirmHandler: RejectRequest,
  };

  return (
    <>
      <div className="tab_container">
        <div className="btn_groupsB">
          {dataList && (
            <button
              type="button"
              className="btn_addUsers"
              onClick={() => {
                // setIsCompanyModal(!isCompanyModal);
                setIsRequestModal(!isRequestModal);
                setIsAdd(true);
              }}
            >
              Add User Data
            </button>
          )}
        </div>
        {/* <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button> */}
        {/* <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div> */}
        {/* <TableView TableData={tableData} />   */}
        {/* <DataTable TableData={tableData} />*/}
        {dataList && <SCDataTable TableData={tableData} />}
        <SocialPlatformModal
          isRequestModal={isRequestModal}
          closeModal={closeModal}
          approveData={approveData}
          getPage={getPage}
          isAdd={isAdd}
        />
        <ConfirmModal modalData={confirmModalData} />
      </div>
    </>
  );
};

export default SocialPlatform;
