import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
// import TableView from '../TableView';
import DataTable from '../JqueryDataTable';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';


class IncomeDetails extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            selectedOption:null,
            isClearable:true,
            isLoader:false,
            incomeData:[],
            page:1,
            search:'',
            aBalance:null,
            pBalance:null,
            tBalance:null,
            totalRecord:0,
            messageApi:''
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page, search, selectedOption } = this.state;
        this.getIncomeApi(page, search, selectedOption);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getIncomeApi = async (page, search, selected) =>{

        if(this._ismounted){
            try{
                this.setState({
                    isLoader:true
                });

                // const { selectedOption } = this.state;

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                const { uid, type } = this.props;

                let formData = new FormData();
                formData.append('profile_id', uid)
                formData.append('sort_by', selected!==null?selected.value:'')
                formData.append('page_no', page)
                formData.append('search_by', search)
                // if(type === 'agent'){
                    const response = await apiHalper('getAgentIncome','POST',formData, headers);
                    // console.log(response);
                    if(response.data.status === true){
                        const data = jwtDecode(response.data.data);
                        // const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].refunded_amount;
                        if(Array.isArray(data.income) && data.income.length){
                            this.setState({
                                isLoader:false,
                                incomeData:data.income,
                                aBalance:data.available_balance,
                                pBalance:data.pending_balance,
                                tBalance:data.total_balance,
                                totalRecord:data.record_count
                            });
                        }else{
                            this.setState({
                                isLoader:false,
                                incomeData:[],
                                aBalance:0,
                                pBalance:0,
                                tBalance:0
                            });
                        }
                        
                    }else{
                        this.setState({
                            isLoader:false,
                            incomeData:[],
                            aBalance:0,
                            pBalance:0,
                            tBalance:0
                        });
                    }
                // }

                if(type === 'client'){
                    this.setState({
                        isLoader:false,
                        incomeData:[],
                        aBalance:0,
                        pBalance:0,
                        tBalance:0
                    })
                }
            }catch(err){
                this.setState({
                    isLoader:false,
                    incomeData:[],
                    aBalance:0,
                    pBalance:0,
                    tBalance:0,
                    messageApi:'Network Error'
                })
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        const { page, search } = this.state;
        this.getIncomeApi(page, search, selectedOption);
    };

    toggleClearable = () =>{
        this.setState(state => ({ isClearable: !state.isClearable }));
        const { page, search, selectedOption } = this.state;
        this.getIncomeApi(page, search, selectedOption);
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'status':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
    }

    statusField = (cell) =>{
        
        switch(cell){
            case 0:
                return <button type="button" className="btn-status blue margin_0auto" disabled>Pending</button>;
            case 1:
                return <button type="button" className="btn-status green margin_0auto" disabled>Paid</button>;
            case 2:
                return <button type="button" className="btn-status red margin_0auto" disabled>Cancel</button>;
            case 3:
                return <button type="button" className="btn-status orange margin_0auto" disabled>Refund</button>;
            default:
                return <button type="button" className="btn-status margin_0auto" disabled>--</button>;
        }
        
    }

    dateField = (cell, row) =>{
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    actionField = (cell, row) =>{
        return <Link to={`/jobsDetails/${row.profile_id}?type=balance`} className="btn-view margin_0auto">View</Link>;
    }

    amountField = (cell, row) =>{
        return <span className="text-center margin_0auto">{`$${cell}`}</span>;
    }

    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        // console.log(type);
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }
        const { selectedOption } = this.state;
        this.getIncomeApi(page, searchText, selectedOption);
    }

    jobId = (cell)=>{
        return `Paid for job ID ${cell}`
    }

    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Paid' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Refund' }
        ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'job_post_id', text: 'Job Details', sort: true, headerFormatter: this.headerSpan, formatter:this.jobId }, 
            { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.dateField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text:"Action", sort:false, headerFormatter:this.headerSpan, formatter:this.actionField}
        ];

        const tableData = {
            columns:columns,
            data:this.state.incomeData,
            keyId:'id',
            loading:this.state.isLoader,
            message:this.state.messageApi !== ''?this.state.messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.totalRecord,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { selectedOption, isClearable, aBalance, pBalance, tBalance } = this.state;

        return (
            <Fragment>
                <div className="tab_container">
                    <ul className="wiget_wrap">
                        <li>
                            <div>
                                <h5>{aBalance?.toFixed(2)} USD</h5>
                                <p>Available Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{pBalance?.toFixed(2)} USD</h5>
                                <p>Pending Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{tBalance?.toFixed(2)} USD</h5>
                                <p>Total Balance</p>
                            </div>
                        </li>
                    </ul>
                    <div className="total_balance">${tBalance?.toFixed(2)}</div>
                    <div className="select_wrap">
                        <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                    </div>
                    <DataTable TableData={tableData} />           
                </div>
            </Fragment>
        )
    }
}
export default withRouter(connect(null)(IncomeDetails));
