import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ListViews from './ListViews';
import { apiHalper } from '../../helpers/ApiHelper';
import { toster_sucess } from '../../helpers/toster_helper';
import { jwtDecode } from '../../helpers/jwt_helper';
import LoaderImg from '../../assets/images/user-loader.gif';

class ApprovedList extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isALoader: false,
            isARSLoader: false,
            isAsLoader: false,
            isADisabled: true,
            approvData: [],
            appSelect: [],
            apage: 1,
            messageApi: '',
            search: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({
            isALoader: true
        });
        this.getApprovedviewProfileData(this.state.apage, this.state.search)
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getApprovedviewProfileData = async (page, search) => {
        if (this._ismounted) {
            try {
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('page_no', page)
                formData.append('search_by', search)

                const res = await apiHalper(`getApproveProfilePicture`, 'POST', formData, headers);
                // console.log(res);
                if (res.data.status === true) {
                    let data = jwtDecode(res.data.data);
                    // console.log(data);
                    if (data.length > 0) {
                        this.setState({
                            isAsLoader: true,
                            isALoader: false,
                            approvData: [...this.state.approvData, ...data[0].data],
                            messageApi: ''
                        })
                    } else {
                        this.setState({
                            isALoader: false,
                            isAsLoader: false,
                            messageApi: '',
                        })
                    }
                } else {
                    this.setState({
                        isALoader: false,
                        isAsLoader: false,
                        approvData: [],
                        messageApi: 'No Record Found'
                    })
                }
            } catch (err) {
                this.setState({
                    isALoader: false,
                    isAsLoader: false,
                    approvData: [],
                    messageApi: 'Network Error'
                })
            }
        }
    }

    nextpageApprovedprofile = () => {
        const inc = this.state.apage + 1;
        if (this.state.isAsLoader) {
            this.setState({
                apage: inc
            })
            this.getApprovedviewProfileData(inc, this.state.search)
        } else {
            this.setState({
                isAsLoader: false
            })
        }

    }

    approveHander = (e) => {
        const { value } = e.target;
        let selected = this.state.appSelect;
        if (!selected.includes(value)) {
            selected.push(value);
        } else {
            selected.splice(selected.indexOf(value), 1);
        }

        if (Array.isArray(selected) && selected.length) {
            this.setState({
                appSelect: selected,
                isADisabled: false
            })
        } else {
            this.setState({
                appSelect: selected,
                isADisabled: true
            })
        }
    }

    aRejectApi = async () => {

        const { appSelect } = this.state;
        if (Array.isArray(appSelect) && appSelect.length) {

            this.setState({
                isARSLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('photo_ids', appSelect.join())
            const response = await apiHalper('rejectProfilePicture', 'POST', formData, headers);
            if (response.data.status === true) {
                // const { appSelect, approvData } = this.state;
                // let newArray = approvData;
                // for(let i = appSelect.length -1; i >= 0; i--){
                //     newArray.splice(appSelect[i], i);
                // }

                this.setState({
                    isARSLoader: false,
                    approvData: [],
                    appSelect: [],
                    apage: 1
                });
                this.getApprovedviewProfileData(1, this.state.search);
                toster_sucess("Profile Rejected Successfully...");
            }
        }
    }

    searchHandler = (e) => {
        const { value } = e.target;
        this.setState({
            search: value
        })
    }

    searchSubmit = (e) => {
        e.preventDefault();
        const { search } = this.state;
        this.setState({
            approvData: [],
            appSelect: []
        })
        // if(search !== ""){
        this.getApprovedviewProfileData(1, search);
        // }
    }

    render() {

        const { isADisabled, isARSLoader, search } = this.state;

        const approv = {
            isLoader: this.state.isALoader,
            name: 'approved',
            data: this.state.approvData,
            inputHandler: this.approveHander,
            // isUnderR:false,
            nextData: this.nextpageApprovedprofile,
            isSLoader: this.state.isAsLoader,
            // ApproveApi:null,
            // RejectApi:this.aRejectApi,
            // isDisabled:null,
            // isRDisabled:this.state.isADisabled,
            // isASLoader:false,
            // isRSLoader:this.state.isARSLoader,
            messageApi: this.state.messageApi
        }

        return <Fragment>
            <div className="search_bar">
                <form action="/" method="POST" onSubmit={this.searchSubmit}>
                    <input type="text" name="search" value={search} onChange={this.searchHandler} autoComplete="off" placeholder="Search" />
                    <button type="submit" className="btn_searchIc"></button>
                </form>
            </div>
            <ListViews propsData={approv} />
            <div className="group_btns">
                <button type="button" className="btn_same btn_reject" onClick={this.aRejectApi} disabled={isADisabled || isARSLoader}>{isARSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Reject"}</button>
            </div>

        </Fragment>
    }
}

export default withRouter(connect(null)(ApprovedList));
