import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoaderImg from '../../../assets/images/user-loader.gif';
import SearchIcon from '../../../assets/images/search_icon.svg';
import NoDataIndication from '../../TableLoader';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper'
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
 
import './index.css';

class TopSkill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            isCLoader: false,
            isMainLoader: false,
            SubCateList: [],
            searchSkillArr: [],
            searchVal: '',
            selectedSkillArray: [],
            selectedConID: [],
            checked: false,
            parentID: 1,
            mainCate: [],
            simFilterVal: 1
        }
    }
    componentDidMount() {
        this.getMainCate();
        this.getSubService(this.state.parentID);
        this.getSelectedSkill();
    } 
    getMainCate = async () => {       
        try {
            this.setState({
                isMServiceload: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token'),
                //"Access-Control-Allow-Origin": '*'
            }

            const response = await apiHalper('getMainServices', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                const option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
                this.setState({
                    mainCate: option,
                    parentID: data.id,
                    isMServiceload: false
                })
            } else {
                this.setState({
                    mainCate: [],
                    isMServiceload: false
                })
            }            
        } catch (err) {
            console.log(err);
        }
    }
    radioHandler = (e) => {
        const { value } = e.target;
        console.log(value)
        this.setState({
            simFilterVal: value
        });
        this.getSubService(value);
    }
    getSubService = async (value) => {
        try {
            this.setState({
                isCLoader: true,
                isSelectLoader: true,
                isMainLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('parent_service_category_id', value)
            formData.append('pageType', '1')
            
            const response = await apiHalper('getSubServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                // console.log(newArray);
                if (Array.isArray(data) && data.length > 0) {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        SubCateList: data,
                        searchSkillArr: data,
                        subMessage: ''
                    })
                } else {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        SubCateList: [],
                        searchSkillArr: [],
                        subMessage: 'No Record Found'
                    })
                }
            } else {
                this.setState({
                    isCLoader: false,
                    isMainLoader: false,
                    SubCateList: [],
                    searchSkillArr: [],
                    subMessage: res.message
                })
            }
        }
        catch (err) {
            console.error(err.message)
        }
    }

    searchTopSkill = async (e) => {
        e.preventDefault();
        const { searchSkillArr, searchVal } = this.state;

        const search = searchSkillArr.filter(info => info.name.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchSkillArr: search
        })
    }
    topSkillSearchInput = (e) => {
        e.preventDefault();
        const { value } = e.target;
        const { SubCateList } = this.state;

        if (value.length > 0) {
            const search = SubCateList.filter(info => info.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchSkillArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchSkillArr: SubCateList,
                searchVal: value
            })
        }

    }
    
    addSkillBind = (data) => {
        let newArray = this.state.selectedSkillArray;
        let ConID = this.state.selectedConID;
        if (newArray.some(info => info.id === data.id)) {

            document.getElementById('topskillID' + data.id).removeAttribute('checked', true);
            document.getElementById('topskillID' + data.id).removeAttribute('class', 'active');

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }
        else {
            document.getElementById('topskillID' + data.id).setAttribute('checked', true);
            document.getElementById('topskillID' + data.id).setAttribute('class', 'active');
            newArray.push({ id: data.id, name: data.name });
            ConID.push(data.id);
        }

        if (newArray.length > 0) {
            this.setState({
                selectedSkillArray: newArray,
                selectedConID: ConID
            })
        }
    }

    SkillRender = (data, i) => {
        const { selectedConID } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedConID.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
            <label htmlFor={"topskillID" + data.id}>
                <input type="checkbox" id={"topskillID" + data.id} value={data.id} name="topskillID" onClick={() => this.addSkillBind(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    SelectedSkillRender = (data, i) => {
        return <li key={i}>
            <label htmlFor={"selectCon" + i}>
                <input type="checkbox" id={"selectCon" + i} value={data.id} name="selectedCon" defaultChecked onClick={() => this.removeSelectedSkill(data)} className="active" />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    submitSkillList = async () => {
        const { selectedConID } = this.state
        try {
            if(selectedConID.length > 0) {
                this.setState({
                    isLoader: true,
                    apiError: ''
                })
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                
                let formData = new FormData()

                formData.append('serviceCategoryID', JSON.stringify(selectedConID))
                const response = await apiHalper('addTopSkills', 'POST', formData, headers)
                const res = response.data;

                if (!res.status) {
                    this.setState({
                        apiErr: res.message,
                        isLoader: false
                    })
                    toster_error(res.message);
                    return true
                }
                toster_sucess(res.message);
                this.setState({
                    isLoader: false
                })
            }        
            else {
                this.setState({
                    apiError: 'Please Select at least one Skill',
                    isLoader: false
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    getSelectedSkill = async () => {
        try {
            this.setState({
                isMainLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getTopSkills`, 'GET', null, headers)
            const res = response.data;
            if (!res.status) {
                this.setState({
                    apiErr: res.message,
                    isMainLoader: false
                })
                return true
            }
            const decode = jwtDecode(res.data)
            let conId = []
            for (let cid = 0; cid < decode.length; cid++ ) {
                conId.push(decode[cid].id)
            }
            this.setState({
                selectedSkillArray: decode,
                selectedConID: conId,
                isMainLoader: false
            })
            toster_sucess(res.message);
        }
        catch (error) {
            console.log(error.message)
        }
    }

    removeSelectedSkill = (data) => {
        let newArray = this.state.selectedSkillArray;
        let ConID = this.state.selectedConID;
        if (newArray.some(info => info.id === data.id)) {

            if(document.getElementById('topskillID' + data.id)) {
                document.getElementById('topskillID' + data.id).removeAttribute('checked', true);
                document.getElementById('topskillID' + data.id).removeAttribute('class', 'active');
            }

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }

        this.setState({
            selectedSkillArray: newArray,
            selectedConID: ConID
        })
    }

    render() {
        const { isMServiceload, isLoader, mainCate, isMainLoader, apiError } = this.state;
        return (
            <div className="sidebar-skill">
                <h4 className="model_title">Top Skill</h4>                
                <ul className="radioBt">
                {isMServiceload ? <div className="box-loader"><img src={LoaderImg} alt="loader" /> </div>: 
                    Array.isArray(mainCate) ?
                        mainCate.map((info, i) => {
                            return <li key={i}>
                                <label>
                                    <input type="radio" name="mainCate" defaultChecked={info.value === this.state.simFilterVal} value={info.value} onChange={this.radioHandler} />
                                    <span className="label_text">{info.label}</span>
                                </label>
                            </li>
                        }) : null 
                    }                    
                </ul>                
                <div className="top-list">
                    {isMainLoader ? <NoDataIndication /> : 
                        <div className={this.state.selectedSkillArray.length > 0 ? 'list' : 'list no-result'}>
                            <ul>
                                {this.state.selectedSkillArray.length > 0 ?
                                    this.state.selectedSkillArray.map((data, i) => this.SelectedSkillRender(data, i)) : <li>No Skill Available</li>}
                            </ul>
                        </div>
                    }   
                    {isMainLoader ? <NoDataIndication /> : 
                        <div className={this.state.searchSkillArr.length > 0 ? 'list' : 'list no-result'}>
                            <form className="search-form" action="/" method="POST" onSubmit={this.searchTopSkill}>
                                <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search Skill" onChange={this.topSkillSearchInput} />
                                <button type="submit"><img src={SearchIcon} alt="Search" /></button>
                            </form>
                            <form action="/" method="POST">
                                <ul>{this.state.searchSkillArr.length > 0 ? this.state.searchSkillArr.map((data, i) => this.SkillRender(data, i)) : <li>No Skill Available</li>}</ul>
                            </form>
                        </div>
                    }
                </div>           
                <div className="bg_submit2 right-mx120">
                    {apiError !== '' ? <span className="skill-error">{apiError}</span> : null }
                    <button type="submit" className="btn-submit" onClick={this.submitSkillList}>{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                </div>                
            </div>
        )
    }
}
export default withRouter(connect(null)(TopSkill));