import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ListViews from './ListViews';
import { apiHalper } from '../../helpers/ApiHelper';
import { toster_sucess } from '../../helpers/toster_helper';
import { jwtDecode } from '../../helpers/jwt_helper';
import LoaderImg from '../../assets/images/user-loader.gif';

class UnderReview extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isULoader: false,
            isURSLoader: false,
            isUASLoader: false,
            isUsLoader: false,
            isUADisabled: true,
            isURDisabled: true,
            reviewData: [],
            undRSelect: [],
            upage: 1,
            search: '',
            messageApi: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({
            isULoader: true
        });
        this.getunderreviewProfileData(this.state.upage, this.state.search)
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getunderreviewProfileData = async (page, search) => {
        if (this._ismounted) {
            try {
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('page_no', page)
                formData.append('search_by', search)

                const res = await apiHalper(`getUnderReviewProfilePicture`, 'POST', formData, headers);
                if (res.data.status === true) {
                    let data = jwtDecode(res.data.data);
                    // console.log(data);
                    if (data.length > 0 && data) {
                        if (search !== '') {
                            this.setState({
                                isULoader: false,
                                isUsLoader: true,
                                reviewData: data[0].data,
                                messageApi: ''
                            })
                        } else {
                            this.setState({
                                isULoader: false,
                                isUsLoader: true,
                                reviewData: [...this.state.reviewData, ...data[0].data],
                                messageApi: ''
                            })
                        }
                    } else {
                        this.setState({
                            isULoader: false,
                            isUsLoader: false,
                            messageApi: 'No Record Found.'
                        })
                    }
                } else {
                    this.setState({
                        isULoader: false,
                        isUsLoader: false,
                        // reviewData:[],
                        // messageApi:'No Record Found',
                    })
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    isULoader: false,
                    isUsLoader: false,
                    reviewData: [],
                    messageApi: 'Network Error'
                })
            }
        }
    }

    nextpageunderreviewprofile = () => {
        const inc = this.state.upage + 1

        if (this.state.isUsLoader) {
            this.setState({
                upage: inc
            })
            this.getunderreviewProfileData(inc, this.state.search)
        } else {
            this.setState({
                isUsLoader: false
            })
        }

    }

    underRHander = (e) => {
        const { value } = e.target;
        let selected = this.state.undRSelect;
        if (!selected.includes(value)) {
            selected.push(value);
        } else {
            selected.splice(selected.indexOf(value), 1);
        }

        if (Array.isArray(selected) && selected.length) {
            this.setState({
                undRSelect: selected,
                isUADisabled: false,
                isURDisabled: false
            })
        } else {
            this.setState({
                undRSelect: selected,
                isUADisabled: true,
                isURDisabled: true
            })
        }
    }

    uRApproveApi = async () => {

        const { undRSelect } = this.state;
        if (Array.isArray(undRSelect) && undRSelect.length) {

            this.setState({
                isUASLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('photo_ids', undRSelect.join())
            const response = await apiHalper('approveProfilePicture', 'POST', formData, headers);
            if (response.data.status === true) {
                // const { undRSelect, reviewData } = this.state;
                // let newArray = reviewData;
                // for(let i = undRSelect.length -1; i >= 0; i--){
                //     newArray.splice(undRSelect[i], i);
                // }

                this.setState({
                    undRSelect: [],
                    isUASLoader: false,
                    reviewData: [],
                    upage: 1
                });
                this.getunderreviewProfileData(1, this.state.search);
                toster_sucess("Profile Approved Successfully...");
            } else {
                this.setState({
                    isUASLoader: false,
                });
            }
        }
    }

    uRRejectApi = async () => {

        const { undRSelect } = this.state;
        if (Array.isArray(undRSelect) && undRSelect.length) {

            this.setState({
                isURSLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('photo_ids', undRSelect.join())

            const response = await apiHalper('rejectProfilePicture', 'POST', formData, headers);
            if (response.data.status === true) {
                // const { undRSelect, reviewData } = this.state;
                // let newArray = reviewData;
                // for(let i = undRSelect.length -1; i >= 0; i--){
                //     newArray.splice(undRSelect[i], i);
                // }
                // console.log(newArray);
                this.setState({
                    isURSLoader: false,
                    reviewData: [],
                    undRSelect: [],
                    upage: 1
                });
                this.getunderreviewProfileData(1, this.state.search);
                toster_sucess("Profile Rejected Successfully...");
            } else {
                this.setState({
                    isURSLoader: false,
                });
            }
        }
    }

    searchHandler = (e) => {
        const { value } = e.target;
        this.setState({
            search: value
        })
    }

    searchSubmit = (e) => {
        e.preventDefault();
        const { search } = this.state;
        this.setState({
            reviewData: []
        })
        // if(search !== ""){
        this.getunderreviewProfileData(1, search);
        // }
    }

    render() {

        const { isUADisabled, isUASLoader, isURDisabled, isURSLoader, search } = this.state;

        const underR = {
            isLoader: this.state.isULoader,
            name: 'underReview',
            data: this.state.reviewData,
            inputHandler: this.underRHander,
            nextData: this.nextpageunderreviewprofile,
            isSLoader: this.state.isUsLoader,
            messageApi: this.state.messageApi,
            // ApproveApi:this.uRApproveApi,
            // RejectApi:this.uRRejectApi,
            // isUnderR:true,
            // isDisabled:this.state.isUADisabled,
            // isRDisabled:this.state.isURDisabled,
            // isASLoader:this.state.isUASLoader,
            // isRSLoader:this.state.isURSLoader,

        }

        return (
            <Fragment>
                <div className="search_bar">
                    <form action="/" method="POST" onSubmit={this.searchSubmit}>
                        <input type="text" name="search" value={search} onChange={this.searchHandler} autoComplete="off" placeholder="Search" />
                        <button type="submit" className="btn_searchIc"></button>
                    </form>
                </div>
                <ListViews propsData={underR} />
                <div className="group_btns">
                    {/* {isUnderR? */}
                    <button type="button" className="btn_same btn_apply" onClick={this.uRApproveApi} disabled={isUADisabled || isUASLoader}>{isUASLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Approve"}</button>
                    {/* :
                        null
                    } */}

                    <button type="button" className="btn_same btn_reject" onClick={this.uRRejectApi} disabled={isURDisabled || isURSLoader}>{isURSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Reject"}</button>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(connect(null)(UnderReview));
