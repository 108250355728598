import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
// import TableView from '../TableView';
import WithdrawModal from './WithdrawModal';
import ImageViewer from '../ImageViewer';
import AddAcountModal from './AddAcountModal';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';

class Withdraw extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            isClearable: true,
            isShow: false,
            isModal: false,
            isImageView: false,
            isLoader: false,
            withdrawData: [],
            startDate: '',
            endDate: '',
            page: 1,
            search: '',
            dBalance: null,
            pBalance: null,
            rBalance: null,
            cBalance: null,
            tBalance: null,
            totalRecord: 0,
            sizePerPage: 10,
            timeConverter: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page, search, selectedOption, startDate, endDate, sizePerPage } = this.state;
        this.getWithdrawApi(page, sizePerPage, search, selectedOption, startDate, endDate);
    }
    componentDidUpdate(prevProps) {
        const { show } = this.props
        if(prevProps.show !== show) {            
            if(!show){
                const { page, search, selectedOption, startDate, endDate, sizePerPage } = this.state;
                this.getWithdrawApi(page, sizePerPage, search, selectedOption, startDate, endDate);
            }
        }
    }
    componentWillUnmount() {
        this._ismounted = false;
    }
    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    applyDates = () => {
        this.getWithdrawApi(this.state.page, this.state.sizePerPage, this.state.search, this.state.selectedOption, this.state.startDate, this.state.endDate );
    }

    getWithdrawApi = async (page, sizePerPage, search, selected, startDate, endDate) => {

        if (this._ismounted) {
            this.setState({
                isLoader: true,
                withdrawData: []
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            // const { selectedOption, page } = this.state;

            let formData = new FormData();
            formData.append('sort_by', selected !== null ? selected.value : '')
            formData.append('page_no', page)
            formData.append('search_by', search)
            formData.append('limit', sizePerPage)
            if(startDate === '' || startDate === null ){
                formData.append('start_date', '')
            }
            else {
                formData.append('start_date', moment(startDate).format('YYYY-MM-DD'))
            }
            if(endDate === '' || endDate === null){
                formData.append('end_date', '')
            }
            else {
                formData.append('end_date', moment(endDate).format('YYYY-MM-DD'))
            }
            formData.append('influencer_bird', 1)
            const response = await apiHalper('getWithdrawals', 'POST', formData, headers);
            if (response.data.status === true) {
                const data = jwtDecode(response.data.data);
                const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].reject_amount;
                this.setState({
                    isLoader: false,
                    withdrawData: data.withdrawals,
                    dBalance: data.total[0].done_amount,
                    pBalance: data.total[0].pending_amount,
                    rBalance: data.total[0].reject_amount,
                    cBalance: data.total[0].cancel_amount,
                    tBalance: total_balance.toFixed(2),
                    totalRecord: data.record_count
                });
            } else {
                this.setState({
                    isLoader: false,
                    withdrawData: [],
                    dBalance: 0,
                    pBalance: 0,
                    tBalance: 0
                });
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getWithdrawApi(this.state.page, this.state.sizePerPage, this.state.search, selectedOption, this.state.startDate, this.state.endDate);
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
        // return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
    }

    modalWithdraw = (data) => {
        const { toggleModal, withdrawModal } = this.props;
        if(withdrawModal) {
            toggleModal(null)
        }
        else {
            toggleModal(data)
        }
        
    }

    statusField = (cell, row) => {

        switch (cell) {
            case 0:
                return <button type="button" className="btn-status blue margin_0auto" onClick={() => this.modalWithdraw(row)}>Pending</button>;
            case 1:
                return <button type="button" className="btn-status green margin_0auto" disabled>Done</button>;
            case 2:
                return <button type="button" className="btn-status red margin_0auto" disabled>Cancel</button>;
            case 3:
                return <button type="button" className="btn-status red margin_0auto" disabled>Rejected</button>;
            default:
                return <button type="button" className="btn-status margin_0auto" disabled>--</button>;
        }

    }

    dateField = (cell, row) => {
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY HH:MM')}</span>;
        // return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">${cell}</span>;
    }

    handleClose = () => {
        this.setState({ isShow: false })
    }

    toggleModal = () => {
        this.setState({ 
            isShow: !this.state.isShow,
        });
    }

    toggleViewModal = () => {
        this.setState({ isImageView: !this.state.isImageView });
    }
    jobId = (cell) => {
        return cell
    }
    paymentAc = (cell) => {
        return cell
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            this.getWithdrawApi(page, sizePerPage, searchText, this.state.selectedOption, this.state.startDate, this.state.endDate);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getWithdrawApi(page, sizePerPage, searchText, this.state.selectedOption, this.state.startDate, this.state.endDate);
        }
    }

    isTester = (row) => {
        // console.log(row)
        let classs = null;
        if (row.is_tester === 1) {
            classs = 'tester_tr'
        } else {
            classs = null;
        }

        return classs;
    }
    statusFormat = (cell) => {
        switch (cell) {
            case 0:
                return "Pending"
            case 1:
                return "Done"
            case 2:
                return "Cancel"
            case 3:
                return "Rejected"
            default:
                return null
        }
    }

    exportData = () => {
        console.log("btn click")
    }

    render() {

        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Done' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Rejected' }
        ];

        const columns = [
            { dataField: 'id', text: 'Withdrawal Id', sort: true, headerFormatter: this.headerSpan, hidden: true },
            { dataField: 'profile_id', text: 'Profile Id', sort: false, headerFormatter: this.headerSpan, hidden: true },
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter: this.jobId },
            { dataField: 'account', text: 'Payment Account', sort: true, headerFormatter: this.headerSpan, formatter: this.paymentAc },
            { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.dateField, csvFormatter: (cell, row, rowIndex) => moment(cell).format('MMM DD, YYYY HH:MM') },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'transactionid', text: 'Transaction Id', sort: true, headerFormatter: this.headerSpan, hidden: true },
            { dataField: 'date_paid', text: 'Date Paid', sort: true, headerFormatter: this.headerSpan, hidden: true, formatter: this.dateField, csvFormatter: (cell, row, rowIndex) => moment(cell).format('MMM DD, YYYY HH:MM') },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField, csvFormatter: this.statusFormat }
        ];

        const tableData = {
            columns: columns,
            data: this.state.withdrawData,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: true,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '250', value: 250 },
                { text: '500', value: 500 }
            ]
        };

        const { selectedOption, isClearable, isShow, pBalance, dBalance, rBalance, cBalance, tBalance, startDate, endDate } = this.state;
        const modalData = {
            show: isShow,
            handleClose: this.handleClose
        }

        return (
            <Fragment>                
                <div className="tab_container">
                    <ul className="wiget_wrap wiget_wrap5">
                        <li>
                            <div>
                                <h5>{pBalance} USD</h5>
                                <p>Pending withdrawal</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{dBalance} USD</h5>
                                <p>Done withdraw</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{cBalance} USD</h5>
                                <p>Cancel withdraw</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{rBalance} USD</h5>
                                <p>Reject withdraw</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{tBalance} USD</h5>
                                <p>Total withdraws</p>
                            </div>
                        </li>
                    </ul>
                    {/* <div className="total_balance">${tBalance}</div> */}
                    <div className="export-data-modal">
                        <DatePicker placeholderText="Select Start Date" selected={startDate} minDate={new Date("2018-01-01")} maxDate={new Date()} onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />
                        <DatePicker placeholderText="Select End Date" selected={endDate} onChange={date => this.setEndDate(date)} minDate={startDate} maxDate={new Date()} endDate={endDate} dateFormat="yyyy-MM-dd" />
                        <button type="button" onClick={this.applyDates} className="btn btn-primary">Apply</button>
                    </div>

                    <div className="select_wrap select-status">
                        <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                    </div>
                    <DataTable TableData={tableData} /> 
                    <WithdrawModal data={modalData} />
                    <ImageViewer />
                    <AddAcountModal />
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    show: state.balance.withdrawModal,
    withdrawModal: state.balance.withdrawModal
})

const mapDispatchToProps = dispatch => ({
    toggleModal: (data) => dispatch({ type: ACTION_TYPES.TOGGLE_WITHDRAW_MODAL, payload: data })
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Withdraw));
