import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import DataTable from '../JqueryDataTable';
import WithdrawModal from '../Balance/WithdrawModal';
import ImageViewer from '../ImageViewer';
// import AddAcountModal from './AddAcountModal';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';

class WithdrawDetails extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            selectedOption:null,
            isClearable:true,
            isShow:false,
            isImageView:false,
            isLoader:false,
            withdrawData:[],
            page:1,
            search:'',
            dBalance:null,
            pBalance:null,
            tBalance:null,
            totalRecord:0
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page, search, selectedOption } = this.state;
        this.getWithdrawApi(page, search, selectedOption);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getWithdrawApi = async (page, search, selected) =>{

        if(this._ismounted){
            this.setState({
                isLoader:true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const { uid } = this.props;

            let formData = new FormData();
            formData.append('profile_id', uid)
            formData.append('sort_by', selected!==null?selected.value:'')
            formData.append('page_no', page)
            formData.append('search_by', search)
            const response = await apiHalper('getAgentWithdrawal','POST',formData, headers);
            // console.log(response);
            if(response.data.status === true){
                const data = jwtDecode(response.data.data);
                const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].reject_amount;
                if(Array.isArray(data.withdrawals) && data.withdrawals.length > 0){
                    this.setState({
                        isLoader:false,
                        withdrawData:data.withdrawals,
                        dBalance:data.total[0].done_amount,
                        pBalance:data.total[0].pending_amount,
                        tBalance:total_balance.toFixed(2),
                        totalRecord:data.record_count
                    });
                }else{
                    this.setState({
                        isLoader:false,
                        withdrawData:[],
                        // dBalance:data.total[0].done_amount,
                        // pBalance:data.total[0].pending_amount,
                        // tBalance:total_balance.toFixed(2),
                        // totalRecord:data.record_count
                    });
                }
                
            }else{
                this.setState({
                    isLoader:false,
                    withdrawData:[],
                    dBalance:0,
                    pBalance:0,
                    tBalance:0
                });
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getWithdrawApi(this.state.page, this.state.search, selectedOption);
    };

    toggleClearable = () =>{
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'status':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
        // return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
    }

    statusField = (cell) =>{
        const { toggleModal } = this.props;
        
        switch(cell){
            case 0:
                return <button type="button" className="btn-status blue margin_0auto" onClick={()=>toggleModal()}>Pending</button>;
            case 1:
                return <button type="button" className="btn-status green margin_0auto" disabled>Done</button>;
            case 2:
                return <button type="button" className="btn-status red margin_0auto" disabled>Cancel</button>;
            case 3:
                return <button type="button" className="btn-status red margin_0auto" disabled>Rejected</button>;
            default:
                return <button type="button" className="btn-status margin_0auto" disabled>--</button>;
        }
        
    }

    dateField = (cell, row) =>{
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    amountField = (cell, row) =>{
        return <span className="text-center margin_0auto">${cell}</span>;
    }

    handleClose = () =>{
        this.setState({isShow:false})
    }

    toggleModal = () =>{
        this.setState({isShow:!this.state.isShow});
    }

    toggleViewModal = () =>{
        this.setState({isImageView:!this.state.isImageView});
    }
    jobId = (cell)=>{
        return `Paid for job ID ${cell}`
    }
    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        // console.log(type);
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }
        this.getWithdrawApi(page, searchText, this.state.selectedOption);
    }

    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Done' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Rejected' }
        ];

        const columns = [
            { dataField: 'payment_account_id', text: 'Job Details', sort: true, headerFormatter: this.headerSpan, formatter:this.jobId }, 
            { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.dateField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField }
        ];

        const tableData = {
            columns:columns,
            data:this.state.withdrawData,
            keyId:'id',
            loading:this.state.isLoader,
            message:this.state.messageApi !== ''?this.state.messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.totalRecord,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { selectedOption, isClearable, isShow, dBalance, tBalance, pBalance } = this.state;

        const modalData = {
            show:isShow,
            handleClose:this.handleClose
        }

        return (
            <Fragment>
                <div className="tab_container">
                    <ul className="wiget_wrap">
                        <li>
                            <div>
                                <h5>{pBalance} USD</h5>
                                <p>Pending withdrawal</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{dBalance} USD</h5>
                                <p>Done withdraw</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{tBalance} USD</h5>
                                <p>Total withdraws</p>
                            </div>
                        </li>
                    </ul>
                    <div className="total_balance">$110657.66</div>
                    <div className="select_wrap">
                        <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                    </div>
                    <DataTable TableData={tableData} />    
                    <WithdrawModal data={modalData} />
                    <ImageViewer />  
                    {/* <AddAcountModal />      */}
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch =>({
    toggleModal:()=>dispatch({type:ACTION_TYPES.TOGGLE_WITHDRAW_MODAL})
})
export default withRouter(connect(null, mapDispatchToProps)(WithdrawDetails));
