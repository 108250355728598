import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './ConfirmModal.css';

class ConfirmModal extends Component {

    render() {

        const { isOpen, text, modalClose, confirmHandler } = this.props.modalData;

        return (
            <Modal show={isOpen} onHide={() => modalClose()} className="confirm-moda" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="confirm-mod">
                    <p>Are you sure you want to <span>{text}</span></p>
                    <div className="confirm-blo">
                        <button type="button" aria-label="Cancel" className="btn-cancel" onClick={() => modalClose()}>Cancel</button>
                        <button type="button" aria-label="Confirm" className="btn-confirm" onClick={confirmHandler}>Confirm</button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(ConfirmModal));