import React, { Component } from 'react'
import {Link} from 'react-router-dom'
export default class addSimilarService extends Component {
    render() {
        return (
            <div className="page-subcategory">
                <h4 className="model_title">Add Sub Category <Link to="/seo/add-subcategory" className="btn-back">Back</Link></h4>
                <div className="main_cat">
                    <h2 className="text_sel">Selected:<span>Social Media Optimization</span></h2>
                    <ul className="radioBt">
                        <li><label><input type="radio" name="mainCate" value="1" /><span className="label_text">Customer Service</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="2" /><span className="label_text">Marketing</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="3" /><span className="label_text">Accounting</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="4" /><span className="label_text">Admin Support</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="5" /><span className="label_text">Writing</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="6" /><span className="label_text">Video &amp; Animation</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="7" /><span className="label_text">Website Dev</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="8" /><span className="label_text">Mobile Dev</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="9" /><span className="label_text">Design</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="10" /><span className="label_text">Other</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="16" /><span className="label_text">Local Jobs</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="17" /><span className="label_text">Legal and Lawyers</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="289" /><span className="label_text">Engineering and Architecture</span></label></li>
                        <li><label><input type="radio" name="mainCate" value="290" /><span className="label_text">Data Science and Analytics</span></label></li>
                    </ul>
                    <div className="conte_bloc">
                        <div className="select_divd">
                            <ul>
                                <li>
                                    <label htmlFor="allSkill0">
                                        <input type="checkbox" id="allSkill0" name="allSkil" className="" value="1" />
                                        <p><span>Marketing Experts</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="allSkill1">
                                        <input type="checkbox" id="allSkill1" name="allSkil" className="" value="1" />
                                        <p><span>Social Media Optimization</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="select_divd">
                            <ul>
                                <li>
                                    <label htmlFor="allSkill">
                                        <input type="checkbox" id="allSkill" name="allSkil" className="" value="1" />
                                        <p><span>Marketing Experts</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="allSkill2">
                                        <input type="checkbox" id="allSkill1" name="allSkil" className="" value="1" />
                                        <p><span>Social Media Optimization</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="allSkill3">
                                        <input type="checkbox" id="allSkill" name="allSkil" className="" value="1" />
                                        <p><span>Marketing Experts</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="allSkill4">
                                        <input type="checkbox" id="allSkill1" name="allSkil" className="" value="1" />
                                        <p><span>Social Media Optimization</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="add-icon" transform="translate(-13604 1017)"><g id="Ellipse_20" class="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" stroke-width="1"><circle cx="10" cy="10" r="10" stroke="none"></circle><circle cx="10" cy="10" r="9.5" fill="none"></circle></g><path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff"></path></g></svg></p>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                <div className="bg_submit2 right-mx120">                    
                    <Link to="/seo/add-pagecontent" className="btn-submit">Next</Link>
                </div>
            </div>
        )
    }
}
