import { combineReducers } from 'redux';
import headerReducers from './headerReducers';
import balanceReducers from './balanceReducers';
import usersReducers from './usersReducers';
import reportReducers from './reportReducers';
import notificationReducers from './notificationReducers';
import allJobsReducers from './allJobsReducers';
import offersReducers from './offersReducers';
import couReportReducers from './couReportReducers';
import pageContentReducers from './pageContentReducers';
import partnerReducers from './partnerReducers';
import jobViewDetailReducers from './jobViewDetailReducers'

const rootReducers = combineReducers({
    header: headerReducers,
    allJobs: allJobsReducers,
    balance: balanceReducers,
    users: usersReducers,
    reports: reportReducers,
    notification: notificationReducers,
    offers: offersReducers,
    couponReport: couReportReducers,
    pageContent: pageContentReducers,
    partner: partnerReducers,
    jobDetail: jobViewDetailReducers
});

export default rootReducers;