import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NoDataIndication from '../../TableLoader';
import TableView from '../../TableView';
import DeleteModal from '../DeleteModal';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper';
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
import ConfirmModal from '../ConfirmModal';
import LoaderImg from '../../../assets/images/user-loader.gif';

export default class MenuComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editData: null,
            mainMenu: [],
            isLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            isModalOpen: false,
            editService: null,
            mainService: '',
            appName: '',
            categoryStatus: null,
            showMenu: '',
            showOnWeb: '',
            formError: {
                mainService: '',
                appName: ''
            },
            formValid: {
                mainService: false,
                appName: false
            },
            selectedOption: null,
            isClearable: null,
            isSLoader: false,
            isDLoader: false,
            isDelModal: false,
            MeuMainCount: 0,
            status: null,
            isChecked: null,
            isConFModal: false,
            updateType: null,
            title: '',
            text: ''
        }
        //this.showModal = this.showModal.bind(this);
        //this.hideModal = this.hideModal.bind(this);
    }

    showModal = (row) => {
        this.setState({
            isModalOpen: true,
            editService: row,
            mainService: row.name,
            appName: row.name_app,
            categoryStatus: row.status,
            showMenu: Number(row.isMainMenu),
            showOnWeb: Number(row.isDisplay)
        });
    };

    hideModal = () => {
        this.setState({ isModalOpen: false });
    };

    componentDidMount() {
        this.getListApi();
    }

    getListApi = async () => {
        try {

            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const response = await apiHalper('getMainServices', 'GET', null, headers);
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);

                let count = 0;
                const newArray = data.map((el, i) => Object.assign({ num: (i + 1) }, el));
                for (var i = 0; i < data.length; i++) {
                    if (data[i].isMainMenu === 1) {
                        count++
                    }
                }

                this.setState({
                    isLoader: false,
                    mainMenu: newArray,
                    MeuMainCount: count,
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    mainMenu: [],
                    MeuMainCount: 0,
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }
    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch (name) {
            case 'mainService':
                valid.mainService = value.length > 0;
                error.mainService = valid.mainService ? '' : 'Main service is required.';
                break;
            case 'appName':
                valid.appName = value.length > 0;
                error.appName = valid.appName ? '' : 'App Name is required.';
                break;
            default:
                break;
        }
        this.setState({
            formError: error,
            formValid: valid
        })
    }

    categoryChange = (val) => {
        this.setState({
            categoryStatus: val
        })
    }

    menuChecked = (e) => {
        let checked = e.target.checked
        let value = 0;
        if (checked) {
            value = 1
        }
        this.setState({
            showMenu: value
        })
    }
    displayChecked = (e) => {
        let checked = e.target.checked
        let value = 0;
        if (checked) {
            value = 1
        }
        console.log(value)
        this.setState({
            showOnWeb: value
        })
    }

    submitContent = async (e) => {
        e.preventDefault();
        const { formError, mainService, appName } = this.state;

        if (mainService === '' && appName === '') {
            this.setState({
                formError: {
                    ...formError,
                    mainService: 'Main service is required',
                    appName: 'App name is required',
                }
            })
        }
        else if (mainService === '') {
            this.setState({
                formError: {
                    ...formError,
                    mainService: 'Main service is required'
                }
            })
        }
        else if (appName === '') {
            this.setState({
                formError: {
                    ...formError,
                    appName: 'App name is required',
                }
            })
        }
        else {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const { editService, mainService, appName, categoryStatus, showMenu, showOnWeb } = this.state

            let formData = new FormData()
            formData.append('serviceCategoryID', editService.id)
            formData.append('name', mainService)
            formData.append('name_app', appName)
            formData.append('status', categoryStatus)
            formData.append('isMainMenu', showMenu)
            formData.append('pageType', 1)
            formData.append('isDisplay', showOnWeb)
            
            const response = await apiHalper('editMainServices', 'POST', formData, headers)
            const res = response.data;
            if (!res.status) {
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                toster_error(res.message);
                return true
            }

            //const { history } = this.props;
            //history.push(`/seo/menu`)
            //const decode = jwtDecode(res.data)
            this.getListApi();
            toster_sucess(res.message);
            //history.push(`/seo/add-pagecontent?id=${decode.insertedId}`)
            this.setState({
                isModalOpen: false,
                formError: {
                    ...formError,
                    mainService: '',
                    appName: '',
                    apiErr: '',
                    isLoader: false,
                }
            })
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        // this.getClientApi(this.state.page, this.state.search);
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: null }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'name':
                // pd_left
                return <span className={column.sort ? "header_span sort " : "header_span"}>{column.text}</span>;
            case 'count_jobs':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'noOfSkill':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'isMainMenu':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'isDisplay':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case 'count_reports':
            //     return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            // case 'time':
            //     return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    actionField = (cell, row) => {
        // return <Link to={`/seo/edit-pagecontent`} className="btn-view margin_0auto">View</Link>;        
        return <div className="action_group text-center">
            {/* <button type="button" className="btn_content add_content" ></button> */}
            {row.pageId === 0 ?
                <Link to={`/seo/add-pagecontent?id=${row.id}&parentPath=1&prev=menu`} className="btn_content add_content"></Link>
                :
                <Link to={`/seo/edit-pagecontent?id=${row.id}&parentPath=1&prev=menu`} className="btn_content add_content"></Link>
            }

            <button type="button" className="btn_edit2" onClick={() => this.showModal(row)}></button>
            <button type="button" className="btn_delete" onClick={() => this.deleteConfirmation(0, row)}></button>
        </div>
    }
    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }
    statusField = (cell, row) => {
        // console.log(row);
        if (cell === '1') {
            return <button type="button" className="btn-status green margin_0auto">Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" >Inactive</button>;
        }
    }

    checkBoxField = (cell, row) => {
        return <div className="text-center">
            <button type="button" className={cell === '1' ? "btn_checkbox active" : "btn_checkbox"}></button>
            {/* <label className="container-checkbox">
                    <input type="checkbox" defaultChecked={cell} onChange={(e)=>this.menuHandler(e, row)} />
                    <span className="checkmark"></span>
                </label> */}
        </div>
    }

    menuHandler = async (e, row) => {
        let val = 0;
        if (row.isMainMenu === 0) {
            val = 1;
            this.setState({
                title: 'Active Menu',
                text: 'Are you sure you want to Active this Menu?'
            })
        } else {
            val = 0;
            this.setState({
                title: 'Inactive Menu',
                text: 'Are you sure you want to Inactive this Menu?'
            });
        }
        // console.log(val);
        this.setState({
            isChecked: val,
            updateType: 'menu',
            editData: row
        });
        this.openConfirmModal();
        // this.updateMenuStatus(val, row, row)
    }

    deleteConfirmation = (val, row) => {
        this.setState({
            updateType: 'status',
            status: val,
            editService: row,
            title: 'Delete Record',
            text: 'Are you sure you want to delete this record?'
        });
        this.openConfirmModal();
    }

    openConfirmModal = () => {
        this.setState({
            isConFModal: true
        })
    }

    closeCofiClose = () => {
        this.setState({
            editData: null,
            isConFModal: false,
            isChecked: 0,
            updateType: null,
            title: '',
            text: ''
        });
        this.getListApi();
    }

    // updateMenuStatus = async (e) =>{
    //     e.preventDefault();
    //     try{

    //         const headers = {
    //             'Authorization': localStorage.getItem('token')
    //         }

    //         const { updateType, editData, isChecked, status } = this.state;

    //         let formData = new FormData();
    //         // console.log(isChecked);
    //         if(updateType === 'menu'){
    //             formData.append('isMainMenu', isChecked)
    //             formData.append('status', editData.status)
    //         }

    //         if(updateType === 'status'){
    //             formData.append('isMainMenu', editData.isMainMenu)
    //             formData.append('status', status)
    //         }

    //         formData.append('service_category_id', editData.id)
    //         const response = await apiHalper('updateServicesStatus', 'POST', formData, headers);
    //         const res = response.data;
    //         // console.log(res);
    //         if(res.status){
    //             this.setState({
    //                 isConFModal:false
    //             })
    //             this.getListApi();
    //             toster_sucess(res.message);
    //         }else{
    //             toster_error(res.message);
    //             this.setState({
    //                 isConFModal:false
    //             })
    //             this.getListApi();
    //         }

    //     }catch(err){
    //         console.log(err.message);
    //     }
    // }

    handleTableChange = (type, { page, sizePerPage, searchText }) => {
        // console.log(type);
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage,
            });
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
        }

        // this.getClientApi(page, searchText);
    }

    openDeleModal = (data) => {
        this.setState({
            isDelModal: true,
            editData: data
        })
    }

    modalDelClose = () => {
        this.setState({
            isDelModal: false,
            editData: null
        })
    }
    deleteFunc = async (e) => {
    }

    updateMenuStatus = async (e) => {
        try {
            e.preventDefault();

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const { editService } = this.state
            
            let formData = new FormData()
            formData.append('serviceCategoryID', editService.id)
            formData.append('pageType', 1)

            const response = await apiHalper('deleteServices', 'POST', formData, headers)
            const res = response.data;

            if (!res.status) {
                this.setState({
                    apiErr: res.message
                })
                toster_error(res.message);
                return true
            }
            this.getListApi();
            toster_sucess(res.message);

            this.setState({
                isConFModal: false,
                editData: null
            })
            this.getListApi();
        }
        catch (err) {
            console.log(err.message);
        }
    }

    render() {

        const columns = [
            { dataField: 'num', text: 'No', sort: false, headerFormatter: this.headerSpan },
            { dataField: 'name', text: 'Main Services', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'noOfSkill', text: 'No of Skills', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'count_jobs', text: 'Added By', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'isMainMenu', text: 'Main Menu', sort: true, headerFormatter: this.headerSpan, formatter: this.checkBoxField },
            { dataField: 'isDisplay', text: 'Display on Website', sort: true, headerFormatter: this.headerSpan, formatter: this.checkBoxField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.mainMenu,
            keyId: 'id',
            loading: this.state.isLoader,
            message: this.state.messageApi !== '' ? this.state.messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: 2,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        const deleteModal = {
            handleClose: this.modalDelClose,
            isModal: this.state.isDelModal,
            deleteFunc: this.deleteFunc,
            isLoader: this.state.isDLoader
        }

        const confirmModal = {
            title: this.state.title,
            text: this.state.text,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: this.updateMenuStatus,
            isLoader: this.state.isDLoader
        }

        const { mainService, appName, categoryStatus, showMenu, showOnWeb, formError, isLoader } = this.state;

        return (
            <section className="bg-balance">
                <div className="container-fluid">

                    <div className="balance_wrap">
                        {/* <button type="button" className=""></button> */}
                        <Link to="/seo/add-main-services?to=menu&page_type=1" className="btn_addUsers">Add Main Services</Link>
                        {/* <h1>Category</h1> */}
                        {/* <button type="button" className="btn_addUsers" onClick={()=>this.toggleModalAdd(null)}>Add</button>
                        <div className="select_wrap users_filters">
                            <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                        </div> */}
                        {/* <TableView TableData={tableData} />   */}
                        <TableView TableData={tableData} />
                    </div>
                </div>

                <DeleteModal data={deleteModal} />
                <ConfirmModal data={confirmModal} />

                {/* Edit service popup */}
                <Modal show={this.state.isModalOpen} onHide={this.hideModal} centered className="modal_design main-service" size="lg">
                    <form action="/" method="POST" onSubmit={this.submitContent}>
                        <div className="modal_header">
                            <h4 className="model_title">Edit Service <button type="button" onClick={this.hideModal}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Main Service</label>
                                    <input type="text" name="mainService" value={mainService} className={formError.mainService !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Main Service name" onChange={this.inputHandler} autoComplete="off" />
                                    {formError.mainService !== '' ? <p className="error_text pos_a">{formError.mainService}</p> : null}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>App Name</label>
                                    <input type="text" name="appName" value={appName} className={formError.appName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="App name" onChange={this.inputHandler} autoComplete="off" />
                                    {formError.appName !== '' ? <p className="error_text pos_a">{formError.appName}</p> : null}
                                </div>
                                <div className="form-group col-md-12 radio-group">
                                    <label>Category Status</label>
                                    <p><input type="radio" id="categoryStatus" name="radioGroup" defaultChecked onChange={() => this.categoryChange(1)} value={categoryStatus} /><label htmlFor="categoryStatus">Active</label></p>
                                    <p><input type="radio" id="categoryStatus2" name="radioGroup" onChange={() => this.categoryChange(0)} value={categoryStatus} /><label htmlFor="categoryStatus2">Unactive</label></p>
                                </div>
                                <div className="form-group col-md-12" style={{display:'flex', gap:'10px'}}>
                                    <div className="checkbox-group">
                                        <input type="checkbox" value={showMenu} name="showMenu" id="mainmenu" defaultChecked={showMenu === 1} onChange={(e) => this.menuChecked(e)} className="styled-checkbox" />
                                        <label htmlFor="mainmenu">Show in Main Menu</label>
                                    </div>
                                    <div className="checkbox-group">
                                        <input type="checkbox" value={showOnWeb} name="showOnWebsite" id="isDisplay" defaultChecked={showOnWeb === 1} onChange={(e) => this.displayChecked(e)} className="styled-checkbox" />
                                        <label htmlFor="isDisplay">Display on Website</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button variant="primary" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save"}</button>
                        </div>
                    </form>
                </Modal>
            </section>
        )
    }
}
