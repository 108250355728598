import React, { Component } from 'react';
import { connect } from 'react-redux';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import parse from 'html-react-parser';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import ProgressCircleBar from '../../helpers/ProgressCircleBar';
// import * as TimeHelper from '../../helpers/TimeDiff';
import ImageViewer from '../ImageViewer';
import Select from 'react-select';
import ConfirmModal from './../ConfirmModal';

const options = [
    { value: 1, label: 'Approve' },
    { value: 3, label: 'Reject' },
];

class Details extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isEdit: true,
            profileId: null,
            gigID: null,
            agentId: null,
            agentDetail: '',
            clientDetail: '',
            agentProfileID: null,
            clientProfileID: null,
            gigStateID: null,
            timer: null,
            isAprrovalModal: false,
            userName: '',
            profilePic: '',
            location: '',
            gig_title: '',
            budget: '-',
            service: '',
            admin_notes:'',
            looking_for: '',
            deadline: '',
            details: '',
            gigData:'',
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            jobFilePath: [],
            viewDownloadPath: '',
            agent_submitted_files: [],
            client_contract_attachment: [],
            file_attributes: [],
            viewImgPath: '',
            submittedPath: '',
            attachment_path: '',
            depositBal: 0,
            releaseBal: 0,
            totalBal: 0,
            paymentdetail: '',
            decodePath:{},
            selectedPaymentOption: '',
            payableAmount:0,
            formError: {
                gig_title: '',
                budget: '',
                service: '',
                looking_for: '',
                deadline: '',
                details: ''
            },
            formValid: {
                gig_title: false,
                budget: false,
                service: false,
                looking_for: false,
                deadline: false,
                details: false
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.interValSet();
        setInterval(() => this.interValSet(), 1000);

        const { jobDetail, history } = this.props
        const queryString = this.props.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type');
        const { cid } = this.props.match.params;
        this.getPaymentDetails(cid);
        if(jobDetail){
            if (type === 'agents') {
                this.getGigContractDetail(jobDetail.id)
            }else if (type === 'balance_agents') {
                this.getGigContractDetail(jobDetail.gigContractID)
            }  
        }else {
            if (type === 'agents') {
                history.push('/all-gigs');
            }
        }

        this.setState({
            current: 2,
        })
    }
    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(() => this.interValSet());
    }

    getGigContractDetail = async (gigID) => {
        try {
            this.setState({
                isLoader: true,
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const response = await apiHalper(`viewContractGigDetails/${gigID}`, 'GET', null, headers);

            if (response.data.status) {
                const data = jwtDecode(response.data.data);
                console.log(data)
                const imgfilePath = localStorage.getItem('filePath')
                const decodePath = jwtDecode(imgfilePath);
                const imgPath = `${decodePath.img}${data.agent_details ? data.agent_details.photo : ''}`
                var jobFPath1 = encodeURIComponent(data.attachments ? data.attachments[0].filename : '');
                const jobFPath = `${decodePath.client_attachments}${jobFPath1}`

                switch (data.job_post_state_id) {
                    case 2:
                        this.setState({
                            depositBal: data.fixed_price,
                            totalBal: data.fixed_price
                        })
                        break;
                    case 4:
                        this.setState({
                            releaseBal: data.fixed_price,
                            totalBal: data.fixed_price
                        })
                        break;
                    case 8:
                        this.setState({
                            depositBal: data.fixed_price,
                            totalBal: data.fixed_price
                        })
                        break;
                    default:
                        break;
                }

                if (data.client_rate_id === -1) {
                    this.setState({
                        budget: '-'
                    })
                }
                else if (data.client_rate_id === 0) {
                    this.setState({
                        budget: data.job_post_budget ? data.job_post_budget.budget : '-',
                    })
                }
                else if (data.client_rate_id >= 1) {
                    this.setState({
                        budget: "$" + data.range_from + " - $" + data.range_to
                    })
                }

                this.setState({
                    decodePath: decodePath,
                    isLoader: false,
                    profileId: data.profile_id,
                    gigID: data.id,
                    agentId: data.agent_profile_id ? data.agent_profile_id : null,
                    agentDetail: data.agent_details,
                    clientDetail: data.client_details,
                    clientProfileID: data.clientProfileID,
                    agentProfileID : data.agentProfileID,
                    gigStateID: data.gigStateID,
                    timer: data.timer ? data.timer : null,
                    userName: data.agent_details ? data.agent_details.username : '',
                    clientProfilePic: data.client_details ? data.client_details.photo != ''? data.client_profile_path+data.client_details.photo:DummyProfile:DummyProfile,
                    agentProfilePic: data.agent_details ? data.agent_details.photo != ''? data.agent_profile_path+data.agent_details.photo:DummyProfile:DummyProfile,
                    location: data.agent_details ? data.agent_details.address.country : '',
                    // totalDays: data.timer.isDue ? 0 : 1,
                    days: data.timer ? data.timer.isDue ? 0 : data.timer.days : null,
                    hours: data.timer ? data.timer.isDue ? 0 : data.timer.hours : null,
                    minutes: data.timer ? data.timer.isDue ? 0 : data.timer.minutes : null,
                    seconds: data.timer ? data.timer.isDue ? 0 : data.timer.seconds : null,
                    gig_title: data.GigTitle,
                    service: data.name_app,
                    looking_for: data.name,
                    deadline: data.deadline,
                    details: data.Details,
                    jobFilePath: data.attachments ? data.attachments : [],
                    viewDownloadPath: jobFPath,
                    agent_submitted_files: data.agent_submitted_files ? data.agent_submitted_files : [],
                    client_contract_attachment: data.client_contract_attachment ? data.client_contract_attachment : [],
                    file_attributes: data.file_attributes,
                    payableAmount: data.payableAmount,
                    attachment_path: data.attachment_path,
                    submittedPath: data.submitted_path,
                    formError: {
                        gig_title: '',
                        budget: '',
                        service: '',
                        looking_for: '',
                        deadline: '',
                        details: ''
                    },
                    formValid: {
                        gig_title: true,
                        budget: true,
                        service: true,
                        looking_for: true,
                        deadline: true,
                        details: true
                    }
                });
            } else {
                this.setState({
                    isLoader: false,
                    profileId: null,
                    gigID: null,
                    agentDetail: '',
                    clientDetail: '',
                    timer: null,
                    userName: '',
                    profilePic: '',
                    location: '',
                    gig_title: '',
                    budget: '-',
                    service: '',
                    looking_for: '',
                    deadline: '',
                    details: '',
                    viewDownloadPath: '',
                    jobFilePath: [],
                    submitFilesPath: [],
                    viewImgPath: '',
                    submittedPath: '',
                    attachment_path: '',
                    agent_submitted_files: [],
                    client_contract_attachment: [],
                    payableAmount: '',
                    depositBal: 0,
                    releaseBal: 0,
                    totalBal: 40,
                    formError: {
                        gig_title: '',
                        budget: '',
                        service: '',
                        looking_for: '',
                        deadline: '',
                        details: ''
                    },
                    formValid: {
                        gig_title: false,
                        budget: false,
                        service: false,
                        looking_for: false,
                        deadline: false,
                        details: false
                    }
                });
            }
        }
        catch (err) {
            console.log(err.message)
        }

    }

    getPaymentDetails = async (cid) =>{
        
        try{
            this.setState({
                isLoader:true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const response = await apiHalper(`getGigsBankTransferRequest/${cid}`, 'GET', null, headers);
            const res = response.data;
                if(response.data.status){
                    const data = jwtDecode(res.data);
                        this.setState({
                            isLoader:false,   
                            paymentdetail:data[0],
                            selectedPaymentOption: data[0].status !== 2 ? options.filter(item => item.value === data[0].status)[0] : ''
                        });
                }else{
                    this.setState({
                        isLoader:false,
                        paymentdetail:'',                                      
                    });
                }
        }catch(err){
            this.setState({
                isLoader:false,  
                paymentdetail:'',   
                messageApi:'Network Error',
            })
        }
}

    toggleForm = () => {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }

    checkValidation = (name, value) => {
        const { formError, formValid } = this.state;
        let error = formError;
        let valid = formValid;

        switch (name) {
            case 'gig_title':
                valid.gig_title = value.length > 0;
                error.gig_title = valid.gig_title ? '' : 'Job Title Must Required';
                break;
            case 'budget':
                let isnum = /^\d+(\.\d{1,2})?$/.test(value);
                if (value.length > 0) {
                    if (isnum) {
                        valid.budget = true;
                        error.budget = '';
                    }
                    else {
                        valid.budget = false;
                        error.budget = 'Budget Must in number';
                    }
                }
                else {
                    valid.budget = false;
                    error.budget = 'Budget Must Required';
                }
                break;
            case 'service':
                valid.service = value.length > 0;
                error.service = valid.service ? '' : 'Service Must Required';
                break
            case 'looking_for':
                valid.looking_for = value.length > 0;
                error.looking_for = valid.looking_for ? '' : 'Looking For Must Required';
                break;
            case 'deadline':
                valid.deadline = value.length > 0;
                error.deadline = valid.deadline ? '' : 'Deadline Must Required';
                break;
            case 'details':
                valid.details = value.length > 0;
                error.details = valid.details ? '' : 'Details Must Required';
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        });
    }

    detailsSubmit = (e) => {
        e.preventDefault();
    }

    interValSet = () => {
        //const diffDate = TimeHelper.TimeRemaining('2020-1-1', '2020-12-31');
        const { totalDays, days, hours, minutes, seconds } = this.state;
        if (this._isMounted) {
            this.setState({
                // totalDays: diffDate.totalDays,
                // days: diffDate.days,
                // hours: diffDate.hours,
                // minutes: diffDate.minutes,
                // seconds: diffDate.seconds,
                totalDays: totalDays,
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            });
        }

    }

    statusField = (gigStateID) => {

        switch (gigStateID) {
            case 1:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Waiting for deposit'}</button>;
            case 2:
                return <button type="button" className="btn-status btn-status2 minWd_220 blue margin_0auto" disabled>{'In Progress'}</button>;
            case 3:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Bidding'}</button>;
            case 4:
                return <button type="button" className="btn-status btn-status2 minWd_220 green margin_0auto" disabled>{'Completed'}</button>;
            case 5:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Cancelled'}</button>;
            case 6:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Waiting for my acceptance'}</button>;
            case 7:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Waiting for agent acceptance'}</button>;
            case 8:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Submit-Waiting for Release'}</button>;
            case 9:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Refunded'}</button>;
            case 10:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Auto Rejected Bid'}</button>;
            case 11:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Removed'}</button>;
            case 12:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Under review'}</button>;
            case 13:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Rejected Job'}</button>;
            case 14:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Blocked/Reported'}</button>;
            default:
                return <button type="button" className="btn-status btn-status2 minWd_220 margin_0auto" disabled>--</button>;
        }
    }

    renderStep2 = (step, key) => {
        const { current } = this.state;
        const done = key < current;
        const currentStep = key === current;
        const currentClassName = currentStep ? ' stepper__step--current' : '';
        const doneClassName = done ? ' stepper__step--done' : '';
        const className = `stepper__step${currentClassName}${doneClassName}`;
        return (
            <li className={className} key={key}>
                <div className="step">
                    <div className="round1">{parse(step.svgIcon)}</div>
                </div>
                <p className="step_title">{step.title}</p>
            </li>
        )
    }

    timeLeftRender = ({ label, num }, i) => {
        return <li key={i}>
            <div className="round_wrap">
                <div className="round2_type">
                    {/* <ProgressBar now={num} > */}
                    <div className="round3_wrap">
                        <p>{num}<span>{label}</span></p>
                    </div>
                    {/* </ProgressBar> */}
                </div>
            </div>
        </li>
    }

    renderSec = () => {
        const { seconds } = this.state;
        return <div className="round3_wrap">
            <p>{seconds}<span>Sec</span></p>
        </div>
    }

    viewImage = (info) => {
        const submitImgPath = `${this.state.submittedPath}${info.fileName}`;
        var extension = info.fileName.split('.').pop();
        if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
            const { toggleViewImage } = this.props
            toggleViewImage(submitImgPath)
        }
        else {
            console.log("other file")
        }
    }

    handleChangePaymentStatus = (selected) => {
        console.log({ selected })
        this.setState({
            selectedPaymentOption: selected ? selected : ''
        })
    }
    handleAdminNotesForPaymnet = (event) => {
        this.setState({
            admin_notes: event.target.value
        })
    }

    handleSave = async (e) => {
        e.preventDefault();
        try {
            if (this._unmounted) {
                this.setState({
                    isAprrovalModal: false,
                })
            }
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
          
          const { selectedPaymentOption, admin_notes,paymentdetail } = this.state;
          console.log('paymentdetail',paymentdetail)
          console.log('selectedPaymentOption pavan',selectedPaymentOption.value)
            
            let formData = new FormData();
            formData.append('bank_request_id', paymentdetail.id);
            formData.append('profile_id', paymentdetail.profile_id);
            formData.append('contractID', paymentdetail.contractID);
            formData.append('approval_status', selectedPaymentOption.value);
            formData.append('admin_notes', admin_notes);
      
          const response = await apiHalper('updateGigsBankTransferRequest','POST',formData , headers);
             console.log('hello',response.data);
             if(response.status === 200){
                const data = jwtDecode(response.data);
                const { cid } = this.props.match.params;
                this.getPaymentDetails(cid)
                this.setState({
                    isAprrovalModal: false, 
                });             
            }  
            this.setState({
                selectedPaymentOption:'',
                admin_notes:'',
            });   
        } catch (error) {
          console.error('Error during updateClientBalance request:', error);
        }
      };
      modalCloseConfirm = () => {
        this.setState({
            isAprrovalModal: false
        })
    }
    modalOpenConfirm = () => {
        const { selectedPaymentOption } = this.state;
        if(!selectedPaymentOption){
           alert('please elect')
        }else{
            this.setState({
                isAprrovalModal: true
            })
        }
       
    }
      
      handleCancel = () => {
       
        this.setState({
            isAprrovalModal: false,
        });
      };

    render() {

        const {isAprrovalModal,admin_notes,payableAmount, agent_submitted_files, client_contract_attachment, paymentdetail, selectedPaymentOption, decodePath, file_attributes,  isLoader, isEdit, gig_title, depositBal, agentId, gigStateID, agentProfileID, clientProfileID, releaseBal, totalBal, agentDetail, clientDetail, timer, gigID, userName, viewDownloadPath, jobFilePath, submitFilesPath, clientProfilePic, agentProfilePic, location, budget, service, looking_for, deadline, details, formError, days, hours, minutes, seconds, totalDays } = this.state;
        // const saveBtnDisabled = !formValid.gig_title || !formValid.budget || !formValid.service || !formValid.looking_for || !formValid.deadline || !formValid.details;

        const steps = [
            { title: 'Hired', svgIcon: `<svg width="32.571" height="27.143" viewBox="0 0 32.571 27.143"><path fill="#050507" d="M20.75,15.5h7.464v2.036H20.75Z" transform="translate(-0.732 -5.321)"/><path fill="#050507" d="M20.75,19.5h7.464v2.036H20.75Z" transform="translate(-0.732 -3.893)"/><path fill="#050507" d="M20.75,23.5h7.464v2.036H20.75Z" transform="translate(-0.732 -2.464)"/><path fill="#050507" d="M34.839,34.429H9.732A3.735,3.735,0,0,1,6,30.7V13.732A3.735,3.735,0,0,1,9.732,10h8.143v2.036H9.732a1.7,1.7,0,0,0-1.7,1.7V30.7a1.7,1.7,0,0,0,1.7,1.7H34.839a1.7,1.7,0,0,0,1.7-1.7V13.732a1.7,1.7,0,0,0-1.7-1.7H26.7V10h8.143a3.735,3.735,0,0,1,3.732,3.732V30.7A3.735,3.735,0,0,1,34.839,34.429Z" transform="translate(-6 -7.286)"/><path fill="#050507" d="M22.482,15.464H16.375A2.378,2.378,0,0,1,14,13.089V10.375A2.378,2.378,0,0,1,16.375,8h6.107a2.378,2.378,0,0,1,2.375,2.375v2.714A2.378,2.378,0,0,1,22.482,15.464Zm-6.107-5.429a.339.339,0,0,0-.339.339v2.714a.339.339,0,0,0,.339.339h6.107a.339.339,0,0,0,.339-.339V10.375a.339.339,0,0,0-.339-.339Z" transform="translate(-3.143 -8)"/><path fill="#050507" d="M14.393,21.786a3.393,3.393,0,1,1,3.393-3.393A3.4,3.4,0,0,1,14.393,21.786Zm0-4.75a1.357,1.357,0,1,0,1.357,1.357A1.359,1.359,0,0,0,14.393,17.036Z" transform="translate(-4.214 -5.5)"/><path fill="#050507" d="M21.214,25.411H19.179v-.679a1.7,1.7,0,0,0-1.7-1.7h-4.75a1.7,1.7,0,0,0-1.7,1.7v.679H9v-.679A3.735,3.735,0,0,1,12.732,21h4.75a3.735,3.735,0,0,1,3.732,3.732Z" transform="translate(-4.929 -3.357)"/><path fill="#050507" d="M20.75,15.5h7.464v2.036H20.75Z" transform="translate(-0.732 -5.321)"/><path fill="#050507" d="M20.75,19.5h7.464v2.036H20.75Z" transform="translate(-0.732 -3.893)"/><path fill="#050507" d="M20.75,23.5h7.464v2.036H20.75Z" transform="translate(-0.732 -2.464)"/></svg>` },
            // { title: 'Deposit', svgIcon: `<svg width="30.857" height="30.857" viewBox="0 0 30.857 30.857"><path fill="#fff" d="M35.679,14.464v1.929H6.75V14.464L21.214,6.75Z" transform="translate(-5.786 -5.786)"/><path fill="#fff" d="M9.643,15.429,23.565,8,21.214,6.75,6.75,14.464v1.929H9.643Z" transform="translate(-5.786 -5.786)"/><path fill="#050507" d="M35.893,17.571H6.964A.965.965,0,0,1,6,16.607V14.679a.964.964,0,0,1,.51-.851L20.975,6.113a.967.967,0,0,1,.906,0l14.464,7.714a.963.963,0,0,1,.512.851v1.929A.965.965,0,0,1,35.893,17.571ZM7.929,15.643h27v-.386l-13.5-7.2-13.5,7.2Z" transform="translate(-6 -6)"/><path fill="#050507" d="M6.75,28.5H35.679v1.929H6.75Z" transform="translate(-5.786 0.429)"/><path fill="#050507" d="M19.2,26.5H16.464V24.572H19.2a.8.8,0,0,0,0-1.607h-.977a2.732,2.732,0,0,1,0-5.464h2.739v1.929H18.226a.8.8,0,0,0,0,1.607H19.2a2.732,2.732,0,0,1,0,5.464Z" transform="translate(-3.286 -2.714)"/><path fill="#050507" d="M17.25,16.75h1.929v1.929H17.25Z" transform="translate(-2.786 -2.929)"/><path fill="#050507" d="M17.25,23.75h1.929v1.929H17.25Z" transform="translate(-2.786 -0.929)"/><path fill="#050507" d="M9,14.25h1.929V33.536H9Z" transform="translate(-5.143 -3.643)"/><path fill="#050507" d="M25.5,14.25h1.929V33.536H25.5Z" transform="translate(-0.428 -3.643)"/></svg>` },
            {
                title: 'Deposit', svgIcon: `<svg id="deposit" xmlns="http://www.w3.org/2000/svg" width="30.857" height="30.855" viewBox="0 0 30.857 30.855">
                    <path id="Path_499" data-name="Path 499" d="M35.679,14.464v1.929H6.75V14.464L21.214,6.75Z" transform="translate(-5.786 -5.786)" fill="none"/>
                    <path id="Path_501" data-name="Path 501" d="M9.75,14.25H30.965V33.536H9.75Z" transform="translate(-4.929 -3.643)" fill="none"/>
                    <path id="Path_502" data-name="Path 502" d="M9.75,14.25h2.893V33.536H9.75Z" transform="translate(-4.929 -3.643)" fill="none"/>
                    <path id="Path_507" data-name="Path 507" d="M35.893,17.571H6.964A.965.965,0,0,1,6,16.607V14.679a.964.964,0,0,1,.51-.851L20.975,6.113a.967.967,0,0,1,.906,0l14.464,7.714a.963.963,0,0,1,.512.851v1.929A.965.965,0,0,1,35.893,17.571ZM7.929,15.643h27v-.386l-13.5-7.2-13.5,7.2Z" transform="translate(-6 -6)" class="fillPath" />
                    <path id="Path_508" data-name="Path 508" d="M6.75,28.5H35.679v1.929H6.75Z" transform="translate(-5.786 0.429)" class="fillPath" />
                    <path id="Path_509" data-name="Path 509" d="M19.2,26.5H16.464V24.572H19.2a.8.8,0,0,0,0-1.607h-.977a2.732,2.732,0,0,1,0-5.464h2.739v1.929H18.226a.8.8,0,0,0,0,1.607H19.2a2.732,2.732,0,0,1,0,5.464Z" transform="translate(-3.286 -2.714)" class="fillPath" />
                    <path id="Path_510" data-name="Path 510" d="M17.25,16.75h1.929v1.929H17.25Z" transform="translate(-2.786 -2.929)" class="fillPath" />
                    <path id="Path_511" data-name="Path 511" d="M17.25,23.75h1.929v1.929H17.25Z" transform="translate(-2.786 -0.929)" class="fillPath" />
                    <path id="Path_512" data-name="Path 512" d="M9,14.25h1.929V33.536H9Z" transform="translate(-5.143 -3.643)" class="fillPath" />
                    <path id="Path_513" data-name="Path 513" d="M25.5,14.25h1.929V33.536H25.5Z" transform="translate(-0.428 -3.643)" class="fillPath" />
                </svg>` 
            },

            { title: 'Submit', svgIcon: `<svg width="34.686" height="26.114" viewBox="0 0 34.686 26.114"><g transform="translate(0.2 0.2)"><path stroke="#fff" stroke-width="0.4px" d="M17.25,11.75h1.607V24.071H17.25Z" transform="translate(-0.911 -5.894)"/><path stroke="#fff" stroke-width="0.4px" d="M21.184,13.854,18.27,10.94l-2.914,2.914L14.22,12.718,17.7,9.236a.8.8,0,0,1,1.137,0l3.482,3.482Z" transform="translate(-1.127 -5.018)"/><path stroke="#fff" stroke-width="0.4px" d="M24.125,27.786H12.875A1.877,1.877,0,0,1,11,25.911V17.875A1.877,1.877,0,0,1,12.875,16h2.679v1.607H12.875a.268.268,0,0,0-.268.268v8.036a.268.268,0,0,0,.268.268h11.25a.268.268,0,0,0,.268-.268V17.875a.268.268,0,0,0-.268-.268H21.446V16h2.679A1.877,1.877,0,0,1,26,17.875v8.036A1.877,1.877,0,0,1,24.125,27.786Z" transform="translate(-1.357 -5.59)"/><path stroke="#fff" stroke-width="0.4px" d="M28.768,31.714H8.946A2.949,2.949,0,0,1,6,28.768V8.946A2.949,2.949,0,0,1,8.946,6H28.768a2.949,2.949,0,0,1,2.946,2.946V28.768A2.949,2.949,0,0,1,28.768,31.714ZM8.946,7.607A1.341,1.341,0,0,0,7.607,8.946V28.768a1.341,1.341,0,0,0,1.339,1.339H28.768a1.341,1.341,0,0,0,1.339-1.339V8.946a1.341,1.341,0,0,0-1.339-1.339Z" transform="translate(-1.714 -6)"/><path stroke="#fff" stroke-width="0.4px" d="M2,28.5H36.286v1.607H2Z" transform="translate(-2 -4.393)"/></g></svg>` },
            { title: 'Paid', svgIcon: `<svg width="26.832" height="25.714" viewBox="0 0 26.832 25.714"><path fill="#989898" d="M9.863,27.064l-.6-.243.575-.589v-.855l.6.243-.575.59Z" transform="translate(-5.615 -4.831)"/><path fill="#989898" d="M21.75,25.088v-.011h1.677Z" transform="translate(-4.141 -4.867)"/><path fill="#989898" d="M21.736,14.472a2.236,2.236,0,1,1,2.236-2.236A2.238,2.238,0,0,1,21.736,14.472Zm0-2.8a.559.559,0,1,0,.559.559A.56.56,0,0,0,21.736,11.677Z" transform="translate(-4.407 -6.646)"/><path fill="#989898" d="M16.5,11.25h1.4v1.677H16.5Z" transform="translate(-4.761 -6.498)"/><path fill="#989898" d="M25.25,11.25h1.4v1.677h-1.4Z" transform="translate(-3.728 -6.498)"/><path fill="#989898" d="M28.814,18.18H15.957A1.959,1.959,0,0,1,14,16.224V8.957A1.959,1.959,0,0,1,15.957,7H28.814A1.959,1.959,0,0,1,30.77,8.957v7.267A1.959,1.959,0,0,1,28.814,18.18Zm-12.857-9.5a.28.28,0,0,0-.28.28v7.267a.28.28,0,0,0,.28.28H28.814a.28.28,0,0,0,.28-.28V8.957a.28.28,0,0,0-.28-.28Z" transform="translate(-5.056 -7)"/><path fill="#989898" d="M9.913,29.18H6.839A.839.839,0,0,1,6,28.342v-9.5A.839.839,0,0,1,6.839,18c3.919,0,5.744,1.361,5.819,1.419a.838.838,0,0,1,.3.885l-2.236,8.258A.839.839,0,0,1,9.913,29.18ZM7.677,27.5H9.271l1.9-7.011a9.506,9.506,0,0,0-3.494-.79v7.8Z" transform="translate(-6 -5.702)"/><path fill="#989898" d="M20.126,30.8c-2.693,0-7.838-2.236-10.666-3.568l.716-1.517c4.6,2.167,8.222,3.409,9.95,3.409,2.267,0,7.415-2.448,10.111-3.862a2.867,2.867,0,0,0-1.447-.331,11.848,11.848,0,0,0-3.369.8,12.969,12.969,0,0,1-3.9.881,20.1,20.1,0,0,1-5.6-1.168L16.5,23.86a18.7,18.7,0,0,0,5.024,1.068,11.848,11.848,0,0,0,3.369-.8,12.969,12.969,0,0,1,3.9-.881c2.772,0,3.542,1.846,3.573,1.925a.837.837,0,0,1-.373,1.045C31.653,26.407,23.655,30.8,20.126,30.8Z" transform="translate(-5.592 -5.082)"/><path fill="#989898" d="M24.681,25.694H23v-.5a1.4,1.4,0,0,0-1.118-1.37l-10.766-2.2.337-1.642,10.766,2.2a3.084,3.084,0,0,1,2.46,3.012v.5Z" transform="translate(-5.396 -5.469)"/></svg>` }
        ];

        const secondData = {
            title: 'Sec',
            classes: 'round_wrap',
            sqSize: 80,
            strokeWidth: 6,
            percentage: seconds,
            isdays: false,
            totalDays: totalDays
        }
        const minData = {
            title: 'Mins',
            classes: 'round_wrap',
            sqSize: 80,
            strokeWidth: 6,
            percentage: minutes,
            isdays: false,
            totalDays: totalDays
        }
        const hoursData = {
            title: 'Hours',
            classes: 'round_wrap',
            sqSize: 80,
            strokeWidth: 6,
            percentage: hours,
            isdays: false,
            totalDays: totalDays
        }
        const daysData = {
            title: 'Days',
            classes: 'round_wrap',
            sqSize: 80,
            strokeWidth: 6,
            percentage: days,
            isdays: true,
            totalDays: totalDays
        }

        const confirmModalData = {
            isOpen: this.state.isAprrovalModal,
            modalClose: this.modalCloseConfirm,
            text: 'Approval?',
            confirmHandler: this.handleSave
        }


        return (
            <div className="job-details">
                { isLoader ? <NoDataIndication /> : <>
                    <div className="use_wrap">
                        <div className="wrap_divFlex">
                        <div className="divs_wrap">
                                {clientDetail ?
                                    <div className="user_profile r-border">
                                        <div className="profile-img"><img src={clientProfilePic} alt="user" /></div>
                                        <div className="user_names">
                                            <h6>{clientDetail.username}</h6>
                                            {/* <p>{location}</p> */}
                                            <p>Client</p>
                                        </div>
                                        <Link type="button" to={`/usersDetails/${clientProfileID}?type=clients`} className="btn btn-green">View Profile</Link>
                                    </div>
                                
                                : null}   
                                {agentDetail ?
                                <div className="user_profile">
                                    <div className="profile-img"><img src={agentProfilePic} alt="user" /></div>
                                    <div className="user_names">
                                        <h6>{agentDetail.username}</h6>
                                        {/* <p>{location}</p> */}
                                        <p>Agent</p>
                                    </div>
                                    <Link type="button" to={`/usersDetails/${agentProfileID}?type=agents`} className="btn btn-green">View Profile</Link>
                                </div>
                                :null}
                            </div>
                        </div>
                    </div>
                    <div className="tab_container detail-div">
                        <div className="use_wrap">
                            <div className="wrap_div">
                                <form action="#" method="POST">
                                    <div className="form_bg">
                                        <h5 className="title_block title_block1">
                                            <span className="text_title text_title1">Gig Details</span>
                                            <span className="bg_id">Gig ID: <strong>{gigID}</strong></span>
                                        </h5>
                                        <div className="flex_grid">
                                            <div>
                                                <label>Gig Title</label>
                                                <div className={formError.gig_title !== "" ? "error_border" : ""}>
                                                    <input type="text" name="gig_title" value={gig_title} onChange={this.inputHandler} placeholder="Gig Title" disabled={isEdit} autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="flex_grid">
                                            <div className="form-group">
                                                <label>Budget(USD)</label>
                                                <div className={formError.budget !== "" ? "error_border" : ""}>
                                                    <input type="text" name="budget" value={budget} onChange={this.inputHandler} placeholder="Budget" disabled={isEdit} autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Service</label>
                                                <div className={formError.service !== "" ? "error_border" : ""}>
                                                    <input type="text" name="service" value={service} onChange={this.inputHandler} placeholder="Service" disabled={isEdit} autoComplete="off" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="flex_grid">
                                            {/* <div className="form-group">
                                                <label>Looking For</label>
                                                <div className={formError.looking_for !== "" ? "error_border" : ""}>
                                                    <input type="text" name="looking_for" value={looking_for} onChange={this.inputHandler} placeholder="Looking For" disabled={isEdit} autoComplete="off" />
                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <label>Deadline</label>
                                                <div className={formError.deadline !== "" ? "error_border" : ""}>
                                                    <input type="text" name="deadline" value={moment(deadline).format('LLLL')} onChange={this.inputHandler} placeholder="Deadline" disabled={isEdit} autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex_grid">
                                            <div>
                                                <label>Details</label>
                                                <div className={formError.details !== "" ? "textarea_bg error_border" : "textarea_bg"}>
                                                    <textarea name="details" onChange={this.inputHandler} disabled={isEdit} value={details ? details : ''} placeholder="Details"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        {jobFilePath.length > 0 ?
                                            <div className="file_render">
                                                <label>Job Files</label>
                                                <ul className={isEdit ? "file-list" : "file-list active"}>
                                                    {jobFilePath.map((info, i) =>
                                                        <li key={i}>
                                                            <span className="attchment-icon">
                                                                <svg width="11.246" height="20.4" viewBox="0 0 11.246 20.4"><path fill="#fff" stroke="#fff" strokeWidth="0.4px" d="M118.563,0a5.429,5.429,0,0,0-5.423,5.423V15.69a.663.663,0,1,0,1.326,0V5.423a4.1,4.1,0,0,1,8.194,0V16.008a2.669,2.669,0,0,1-2.666,2.665c-.011,0-.021.006-.032.006s-.021-.006-.032-.006a2.669,2.669,0,0,1-2.666-2.665V9.659a1.3,1.3,0,1,1,2.6,0V15.69a.663.663,0,1,0,1.326,0V9.659a2.624,2.624,0,1,0-5.248,0v6.35A4,4,0,0,0,119.931,20c.012,0,.021-.006.032-.007s.021.007.032.007a4,4,0,0,0,3.992-3.992V5.423A5.429,5.429,0,0,0,118.563,0Z" transform="translate(-112.94 0.2)" /></svg>
                                                            </span>
                                                            <p>{info.filename}</p>
                                                            <button aria-label="Button Delete" type="button" className={isEdit ? "btn-delete" : "btn-delete active"}>
                                                                <svg width="10.856" height="10.856" viewBox="0 0 10.856 10.856"><g transform="translate(0.4 0.4)"><g transform="translate(0 0)"><path fill="#ff7878" stroke="#ff7878" strokeWidth="0.8px" d="M5.744,5,9.846.9A.526.526,0,0,0,9.1.155L5,4.257.9.155A.526.526,0,0,0,.154.9L4.256,5,.154,9.1A.526.526,0,0,0,.9,9.847L5,5.745l4.1,4.1A.526.526,0,0,0,9.846,9.1Z" transform="translate(0 -0.001)"></path></g></g></svg>
                                                            </button>
                                                            <div className="btn-div">
                                                                <a rel="noreferrer" href={viewDownloadPath} target="_blank" className="btn-download">
                                                                    <svg width="36" height="36" viewBox="0 0 36 36"><g transform="translate(0 0.09)"><rect fill="#262a31" width="36" height="36" rx="3" transform="translate(0 -0.09)" /><g transform="translate(8 12)"><path fill="#fff" d="M10.066,98.725c-3.846,0-7.334,2.1-9.908,5.522a.8.8,0,0,0,0,.951c2.574,3.422,6.062,5.527,9.908,5.527s7.334-2.1,9.908-5.522a.8.8,0,0,0,0-.951C17.4,100.829,13.912,98.725,10.066,98.725Zm.276,10.225A4.234,4.234,0,1,1,14.291,105,4.237,4.237,0,0,1,10.341,108.95ZM10.214,107a2.28,2.28,0,1,1,2.129-2.129A2.276,2.276,0,0,1,10.214,107Z" transform="translate(0 -98.725)" /></g></g></svg>
                                                                </a>
                                                                <a rel="noreferrer" href={viewDownloadPath} download target="_blank" className="btn-download"><svg width="36" height="36" viewBox="0 0 36 36"><rect fill="#262a31" width="36" height="36" rx="3" /><g transform="translate(10 10)"><path fill="#fff" d="M85.7,12.571l5.143-5.143L89.129,5.714,86.844,8V0H84.558V8L82.272,5.714,80.558,7.429Z" transform="translate(-77.701)" /><path fill="#fff" d="M0,386.667H16v2.286H0Z" transform="translate(0 -372.953)" /></g></svg></a>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            : null}
                                        {/* {!isEdit ?
                                            <div className="btn-flex">
                                                <button type="button" className="btn_save" onClick={this.detailsSubmit} disabled={saveBtnDisabled}>Submit</button>
                                                <button type="button" className="btn_cancel" onClick={this.toggleForm}>Cancel</button>
                                            </div>
                                            :
                                            <div className="btn-flex">
                                                <button type="button" className="btn_edit" onClick={this.toggleForm}>Edit Job Detail</button>
                                            </div>
                                        } */}
                                    </div>
                                </form>
                                {/* Timer */}
                                {timer ?
                                    <div className="divs_wrap time_block">
                                        <h5>Time Left</h5>
                                        <ul className="time_wrap">
                                            <li><ProgressCircleBar data={daysData} /></li>
                                            <li><ProgressCircleBar data={hoursData} /></li>
                                            <li><ProgressCircleBar data={minData} /></li>
                                            <li><ProgressCircleBar data={secondData} /></li>
                                        </ul>
                                    </div>

                                    : null}

                                {/* Job Status */}
                                <div className="jstatus_wrap">
                                    <h5>Gig Status</h5>
                                    <div className="step_wrap">
                                        {/* <ul className="steps">
                                            {steps.map((step, i) => this.renderStep2(step, i))}
                                        </ul> */}
                                        {this.statusField(gigStateID)}
                                    </div>
                                </div>
                            </div>
                            <div className="wrap_div">
                                <div className="file_render">
                                    <h5 className="title_block">Agent Submitted Files</h5>
                                    <ul className="flie_list flie_list2">
                                        {agent_submitted_files && agent_submitted_files.length > 0 ? agent_submitted_files.map((info, i) =>
                                            <li key={i}>
                                                <div className="flex-box">
                                                    <h6>{info.title_data.description}</h6>
                                                    <span>{moment(info.title_data.createdAt).format('MMMM Do YYYY, h:mm')}</span>
                                                </div>
                                                    <div className="flie_block" key={i}>
                                                        <div className="content_wrap">
                                                            <div className="img_url"><svg width="30.591" height="24" viewBox="0 0 30.591 24"><path fill="#444a56" d="M1.929,50.447H9.245a1.339,1.339,0,0,1,1.161.671l1.2,1.645a1.34,1.34,0,0,0,1.161.671h17.82v18.78A1.786,1.786,0,0,1,28.8,74H1.786A1.786,1.786,0,0,1,0,72.215v-16.7a13.4,13.4,0,0,1,.632-4.068h0A1.339,1.339,0,0,1,1.929,50.447Z" transform="translate(0 -50.447)" /><rect fill="#959595" width="23.514" height="9.933" transform="translate(2.784 2.68)" /><path fill="#787d88" d="M29.251,64.117H21.879a1.339,1.339,0,0,0-1.218.783L19.4,67.661a1.34,1.34,0,0,1-1.218.783H0V85.438a1.786,1.786,0,0,0,1.786,1.786H28.8a1.786,1.786,0,0,0,1.786-1.786V65.456A1.34,1.34,0,0,0,29.251,64.117Z" transform="translate(0 -63.224)" /></svg></div>
                                                            <p>{info.title_data.fileName} ({info.title_data.size}KB)</p>
                                                        </div>
                                                        <div className="btn-div">
                                                            {/* <button type="button" className="btn-fileview" onClick={() => this.viewImage(info.title_data)}>
                                                                <svg width="36" height="36" viewBox="0 0 36 36"><g transform="translate(0 0.09)"><rect fill="#262a31" width="36" height="36" rx="3" transform="translate(0 -0.09)" /><g transform="translate(8 12)"><path fill="#fff" d="M10.066,98.725c-3.846,0-7.334,2.1-9.908,5.522a.8.8,0,0,0,0,.951c2.574,3.422,6.062,5.527,9.908,5.527s7.334-2.1,9.908-5.522a.8.8,0,0,0,0-.951C17.4,100.829,13.912,98.725,10.066,98.725Zm.276,10.225A4.234,4.234,0,1,1,14.291,105,4.237,4.237,0,0,1,10.341,108.95ZM10.214,107a2.28,2.28,0,1,1,2.129-2.129A2.276,2.276,0,0,1,10.214,107Z" transform="translate(0 -98.725)" /></g></g></svg>
                                                            </button> */}
                                                            <a rel="noreferrer" href={`${this.state.submittedPath}${info.title_data.fileName}`} download target="_blank" className="btn-download"><svg width="36" height="36" viewBox="0 0 36 36"><rect fill="#262a31" width="36" height="36" rx="3" /><g transform="translate(10 10)"><path fill="#fff" d="M85.7,12.571l5.143-5.143L89.129,5.714,86.844,8V0H84.558V8L82.272,5.714,80.558,7.429Z" transform="translate(-77.701)" /><path fill="#fff" d="M0,386.667H16v2.286H0Z" transform="translate(0 -372.953)" /></g></svg></a>
                                                        </div>
                                                    </div>
                                            </li>
                                        ) :
                                            <li className="nofile">
                                                <div className="nofile_block"><p> No Files<span>All attached files with this gig will be displayed here</span></p></div>
                                            </li>}
                                    </ul>
                                </div>
                                <div className="file_render">
                                    <h5 className="title_block">Client Contract Attachments</h5>
                                    <ul className="flie_list flie_list2">
                                        {client_contract_attachment && client_contract_attachment.length > 0 ? client_contract_attachment.map((info, i) =>
                                            <li key={i}>
                                                <div className="flex-box">
                                                    <h6>{info.title_data.description}</h6>
                                                    <span>{moment(info.title_data.createdAt).format('MMMM Do YYYY, h:mm')}</span>
                                                </div>
                                                    <div className="flie_block">
                                                        <div className="content_wrap">
                                                            <div className="img_url"><svg width="30.591" height="24" viewBox="0 0 30.591 24"><path fill="#444a56" d="M1.929,50.447H9.245a1.339,1.339,0,0,1,1.161.671l1.2,1.645a1.34,1.34,0,0,0,1.161.671h17.82v18.78A1.786,1.786,0,0,1,28.8,74H1.786A1.786,1.786,0,0,1,0,72.215v-16.7a13.4,13.4,0,0,1,.632-4.068h0A1.339,1.339,0,0,1,1.929,50.447Z" transform="translate(0 -50.447)" /><rect fill="#959595" width="23.514" height="9.933" transform="translate(2.784 2.68)" /><path fill="#787d88" d="M29.251,64.117H21.879a1.339,1.339,0,0,0-1.218.783L19.4,67.661a1.34,1.34,0,0,1-1.218.783H0V85.438a1.786,1.786,0,0,0,1.786,1.786H28.8a1.786,1.786,0,0,0,1.786-1.786V65.456A1.34,1.34,0,0,0,29.251,64.117Z" transform="translate(0 -63.224)" /></svg></div>
                                                            <p>{info.title_data.fileName}</p>
                                                        </div>
                                                        <div className="btn-div">
                                                            {/* <button type="button" className="btn-fileview" onClick={() => this.viewImage(info.title_data)}>
                                                                <svg width="36" height="36" viewBox="0 0 36 36"><g transform="translate(0 0.09)"><rect fill="#262a31" width="36" height="36" rx="3" transform="translate(0 -0.09)" /><g transform="translate(8 12)"><path fill="#fff" d="M10.066,98.725c-3.846,0-7.334,2.1-9.908,5.522a.8.8,0,0,0,0,.951c2.574,3.422,6.062,5.527,9.908,5.527s7.334-2.1,9.908-5.522a.8.8,0,0,0,0-.951C17.4,100.829,13.912,98.725,10.066,98.725Zm.276,10.225A4.234,4.234,0,1,1,14.291,105,4.237,4.237,0,0,1,10.341,108.95ZM10.214,107a2.28,2.28,0,1,1,2.129-2.129A2.276,2.276,0,0,1,10.214,107Z" transform="translate(0 -98.725)" /></g></g></svg>
                                                            </button> */}
                                                            <a rel="noreferrer" href={`${this.state.submittedPath}${info.title_data.fileName}`} download target="_blank" className="btn-download"><svg width="36" height="36" viewBox="0 0 36 36"><rect fill="#262a31" width="36" height="36" rx="3" /><g transform="translate(10 10)"><path fill="#fff" d="M85.7,12.571l5.143-5.143L89.129,5.714,86.844,8V0H84.558V8L82.272,5.714,80.558,7.429Z" transform="translate(-77.701)" /><path fill="#fff" d="M0,386.667H16v2.286H0Z" transform="translate(0 -372.953)" /></g></svg></a>
                                                        </div>
                                                    </div>
                                            
                                            </li>
                                        ) :
                                            <li className="nofile">
                                                <div className="nofile_block"><p> No Files<span>All attached files with this gig will be displayed here</span></p></div>
                                            </li>}
                                    </ul>
                                </div>
                                <div className="wrap_payments">
                                    <h5 className="title_block">Payment Details</h5>
                                    {paymentdetail && <div class="payment_details_block d-flex">
                                    <div className='paymentsubdetails'>
                                            <p>Sender Name: {paymentdetail?.sender_name}</p>
                                            <p>Bank Name: {paymentdetail?.bank_name}</p>
                                            <p>Account Number: {paymentdetail?.account_number}</p>
                                            <p>Amount: {paymentdetail?.amount}</p>
                                            <p>Notes: {paymentdetail?.notes}</p>
                                            {paymentdetail?.attechment && <div >
                                                <a target='_blank' href={`${decodePath.bank_transfer_request}${paymentdetail.attechment}`}><button type="button" className="btn-show-icon"></button></a>
                                            </div>}
                                            </div>
                                            {console.log({ status: paymentdetail.status})}
                                            {paymentdetail.status === 2 ?
                                                <div className='selectbox'>
                                                    <Select value={selectedPaymentOption} isClearable={true} onChange={this.handleChangePaymentStatus} options={options} disabled={paymentdetail.status === 1 || paymentdetail.status === 3}/>
                                                    {console.log({ selectedPaymentOption })}
                                                    {selectedPaymentOption.value === 3 && <input type="text" name="admin_notes" value={admin_notes} className='form_control mt-2' placeholder='Add notes' onChange={this.handleAdminNotesForPaymnet}/>}
                                                    <div className='selectbtn'>
                                                    {isAprrovalModal && (
                                                            <ConfirmModal modalData={confirmModalData} />
                                                        )}
                                                    <button type="button" className="btn_save" onClick={this.modalOpenConfirm}>
                                                            <button type='button' className="btn btn-green btnsave">Save</button>
                                                    </button>
                                                        <button type='button' className="btncancel" onClick={this.handleCancel}>Cancel</button>
                                                    </div>
                                                </div>
                                                : 
                                                paymentdetail.status === 1 ? <button type="button" className="btn-status btn-status2 minWd_220 blue margin_0auto" disabled>{'Approved'}</button>
                                                : 
                                                <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Rejected'}</button>
                                            }
                                        </div>}
                                    <ul className="payments_wrap">
                                        <li>
                                            <div className="price_block">
                                                <span className="icon_active"></span>
                                                <p>Payable Amount <span>${payableAmount}.00</span></p>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="price_block">
                                                <span className="icon_active"></span>
                                                <p>Released <span>${releaseBal}.00</span></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="price_block fixed">
                                                <span className="top-text">(Fixed Price)</span>
                                                <p>Total <span>${totalBal}.00</span></p>
                                                <span className="info_te">The employer already released deposit, you can withdraw money from <u>Balance</u> page.</span>
                                            </div>
                                        </li> */}
                                    </ul>
                                    <div className="wrap_info">
                                        <h6 className="title_block">View Billing Info</h6>
                                        <p className="list_icon">Deposit is securely held by <a href="https://24task.com/" rel="noreferrer" target="_blank"><u>24Task.com</u></a></p>
                                        <p className="list_icon">Once you submit your job, employer will relesing deposit.</p>
                                        <span>* All Deposits and fees are refundable subject to our <a href="https://24task.com/terms-of-use" rel="noreferrer" target="_blank"><u>Tearms and Conditions</u></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ImageViewer />
                    </div>
                </>}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    toggleViewImage: (url) => dispatch({ type: ACTION_TYPES.TOGGLE_IMAGE_VIEWER, payload: url }),
})

const mapStateToProps = (state) => ({
    jobDetail: state.jobDetail.jobDetail
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
