import * as ACTION_TYPES from '../actions/types';

const intiState ={
    editData:null
} 

const partnerReducers = (state = intiState, action)=>{
    switch(action.type){
        case ACTION_TYPES.PATNER_SINGLE_DATA:
            return{
                ...state,
                editData:action.payload
            }
        default:
            return state;
    }
}

export default partnerReducers;