import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import * as ACTION_TYPES from '../../redux/actions/types';
import LoaderImg from '../../assets/images/user-loader.gif';
// import { apiDeviceHelper } from '../../helpers/ApiHelper';


class AdminModal extends Component {

    constructor(props){
        super(props);
        this.state={
            isLoader:false,
            editData:[],
            firstname:'',
            lastname:'',
            username:'',
            email:'',
            password:'',
            countryCode:'',
            phone:'',
            phoneError:'',
            phoneValid:false,
            formError:{
                firstname:'',
                lastname:'',
                username:'',
                email:'',
                password:''
            },
            formValid:{
                firstname:false,
                lastname:false,
                username:false,
                email:false,
                password:false
            }
        }
    }

    componentDidUpdate(prevProps){
        const { adminModal, editAdminData } = this.props;
        if(adminModal !== prevProps.adminModal){
                // console.log('working' + (1));
            if(editAdminData !== null){
                const contact = editAdminData.contact_no.split('.');
                // console.log(contact.join(' '));
                // const numb = `${contact[0]} ${contact[1]}`
                this.setState({
                    editData:editAdminData,
                    firstname:editAdminData.first_name,
                    lastname:editAdminData.last_name,
                    email:editAdminData.email,
                    username:editAdminData.username,
                    phone:contact[1],
                    countryCode:contact[0],
                    phoneError:'',
                    phoneValid:true,
                    formError:{
                        firstname:'',
                        lastname:'',
                        username:'',
                        email:'',
                        password:''
                    },
                    formValid:{
                        firstname:true,
                        lastname:true,
                        username:true,
                        email:true,
                        password:true
                    }
                })
            }
        }
    }

    // static getDerivedStateFromProps = async (props, state) =>{
    //     const { adminId } = props;
    //     if(adminId !== null){
    //         // if(adminId !== nextProps.adminId){
    //             const response = await apiDeviceHelper(`Profiles/viewAdminProfile/${adminId}.json`,'GET', null, null);
    //             if(response.data.flag === 1){
    //                 state.isLoader = false;
    //                 const data = response.data.data;
    //                 state.editData = data;
    //                 const { contact_no, email, first_name, last_name, username } = data;
    //                 const contact = contact_no.split('.');
    //                 state.firstname = first_name;
    //                 state.lastname = last_name;
    //                 state.email = email;
    //                 state.countryCode = contact[0];
    //                 state.phone = contact[1];
    //                 state.username = username;
    //                 state.phoneError = '';
    //                 state.phoneValid = false;
    //                 // this.setState({
    //                 //     isLoader:false,
    //                 // });
    //                 // toster_sucess("User added Successfully...");
    //                 // this.props.handleClose();
    //             }else{
    //                 state.isLoader = false; 
    //                 // this.setState({
    //                 //     isLoader:false,
    //                 // });
    //                 // toster_error(response.data.msg);
    //             }
    //         // }
    //     }
    // }

    radioChange = (val) =>{
        this.setState({
            radioStatus:val
        });
    }

    adminRadioChange = (val) =>{
        this.setState({
            adminstatus:val
        });
    }

    inputHandler = e =>{
        const { name, value } = e.target;
        this.setState({
            [name]:value
        }, ()=>this.validationChecked(name, value));
    }

    validationChecked = (name, value) =>{
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch(name){
            case 'firstname':
                valid.firstname = value.length > 0;
                error.firstname = valid.firstname?'':'Firstname must required!';
                break;
            case 'lastname':
                valid.lastname = value.length > 0;
                error.lastname = valid.lastname?'':'Lastname must required!';
                break;
            case 'username':
                valid.username = value.length > 0;
                error.username = valid.username?'':'Username must required!';
                break;
            case 'email':
                if(value.length > 0){
                    if(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
                        valid.email = true;
                        error.email = '';
                    }else{
                        valid.email = false;
                        error.email = 'Enter a valid email!';
                    }
                }else{
                    valid.email = false;
                    error.email = 'Email must required!';
                }
                break;
            case 'password':
                if(value.length > 0){
                    if(value.length >= 8){
                        valid.password = true;
                        error.password = '';
                    }else{
                        valid.password = false;
                        error.password = 'Password must be at least 8 characters long';
                    }
                }else{
                    valid.password = false;
                    error.password = 'Password must required!';
                }
                break;
            default:
                break;
        };

        this.setState({
            formError:error,
            formValid:valid
        });
    }

    handlePhone = (isvalid, val) =>{
        if(!isvalid){
            this.setState({
                phone:val,
                phoneError:'Please Enter Valid Phone Number',
                phoneValid:false
            });
        }else{
            this.setState({
                phone:val,
                phoneError:'',
                phoneValid:true
            })
        }
    }

    getCountry = (e, country)=>{
        this.setState({
            countryCode:country.dialCode,
            phone:'',
            phoneError:'Phone Number is must required!',
            phoneValid:false
        })
    }

    submitUser = async (e)=>{
        e.preventDefault();
        console.log('form submit...');
    }

    render() {

        const { adminModal, handleClose } = this.props;
        // phone, countryCode,
        const { firstname, lastname, username, email, formError, formValid, phoneError, phoneValid, isLoader, editData } = this.state;
        const disabled = !formValid.firstname || !formValid.lastname || !formValid.username || !formValid.email || !phoneValid;

        // const phoneNo = countryCode.concat(phone);
        // console.log(phoneNo);

        return (
            <Modal show={adminModal} onHide={()=>handleClose(null)} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Admin Details <button type="button" onClick={()=>handleClose(null)}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#" onSubmit={this.submitUser}>
                                <div className="form_body">
                                    <div className="users_wrap">
                                        <div className="img_dev">
                                            <img src={editData.photo !== null? editData.photo:DummyProfile} alt={editData.photo !== null?editData.username:"Profile"} />
                                        </div>
                                        <h5>{editData.first_name + ' ' + editData.last_name}</h5>
                                        <p>{editData.email}<span>ID - {editData.id}</span></p>
                                        <button type="button" className="btn-tester">Add As Tester</button>
                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>First Name</label>
                                            <input type="text" name="firstname" className={formError.firstname !== ''?"form_control2 error_border":"form_control2"} value={firstname} onChange={this.inputHandler} placeholder="Enter First Name" autoComplete="off"/>
                                            {formError.firstname !== ''?<p className="error_text">{formError.firstname}</p>:null}
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>Last Name</label>
                                            <input type="text" name="lastname" className={formError.lastname !== ''?"form_control2 error_border":"form_control2"} value={lastname} onChange={this.inputHandler} placeholder="EnterLast Name" autoComplete="off"/>
                                            {formError.lastname !== ''?<p className="error_text">{formError.lastname}</p>:null}
                                        </div>
                                    </div>
                                    <div className="form_group3">
                                        <div className="colum_2_wrap">
                                            <label>User Name</label>
                                            <input type="text" name="username" className={formError.username !== ''?"form_control2 error_border":"form_control2"} value={username} onChange={this.inputHandler} placeholder="Enter User Name" autoComplete="off"/>
                                            {formError.username !== ''?<p className="error_text">{formError.username}</p>:null}
                                        </div>
                                        <div className="colum_2_wrap">
                                            <label>Email</label>
                                            <input type="email" name="email" className={formError.email !== ''?"form_control2 error_border":"form_control2"} value={email} onChange={this.inputHandler} placeholder="Enter Email Address" autoComplete="off"/>
                                            {formError.email !== ''?<p className="error_text">{formError.email}</p>:null}
                                        </div>
                                    </div>
                                    <div className="form_group3">
                                        {/* <div className="colum_2_wrap">
                                            <label>Password</label>
                                            <input type="password" name="password" className={formError.password !== ''?"form_control2 error_border":"form_control2"} value={password} onChange={this.inputHandler} placeholder="Enter Password" autoComplete="off"/>
                                            {formError.password !== ''?<p className="error_text">{formError.password}</p>:null}
                                        </div> */}
                                        <div className="colum_2_wrap">
                                            <label>Phone Number</label>
                                            <IntlTelInput onPhoneNumberChange={this.handlePhone} onSelectFlag={this.getCountry} value={'+91 1234567891'} inputClassName={phoneError !== ''?"form_control2 error_border":"form_control2"}  />
                                            {phoneError !== ''?<p className="error_text">{phoneError}</p>:null}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg_submit2 mr-top">
                                    <button type="submit" className="btn-submit" disabled={disabled || isLoader}>{isLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Save"}</button>
                                    <p>Or <button type="button" className="btn_cancel2" onClick={()=>handleClose(null)}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state =>({
    adminModal:state.users.adminModal,
    editAdminData:state.users.editAdminData
});

const mapDispatchToProps = dispatch =>({
    handleClose:(data)=>dispatch({type:ACTION_TYPES.TOGGLE_ADMIN_MODAL, payload:data})
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminModal));
