import React, { Component } from 'react'
import { connect } from 'react-redux';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { withRouter } from 'react-router-dom';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import NoDataIndication from '../../components/TableLoader';
import Moment from 'moment';

import './index.css'

class PartnerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            partnerDetails: '',
            partnerID: null,
            pageNo: 1,
            selectPartnerAs: 0,
            search: '',
            userData: [],
            listData: [],
            profileStatus: [],
            trustRateStatus: [],
            signUpDate: '',
            messageApi: ''
        }
    }
    componentDidMount () {
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.getPartnerList(myParam);
    }
    getPartnerList = async (id) => {
        try {
                    
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('profile_id', id )
            
            const response = await apiHalper('getUserPartnershipRequestDetail', 'POST', formData, headers);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);
                    this.setState({
                        isLoader: false,
                        userData: decode.userDetail,
                        listData: decode.data,
                        profileStatus: JSON.parse(decode.userDetail.profile_status),
                        trustRateStatus: JSON.parse(decode.userDetail.trust_rate_status),
                        signUpDate: Moment(decode.userDetail.signup_date).format('MMMM DD, YYYY'),
                        messageApi: ''
                    });
                
            } else {
                this.setState({
                    isLoader: false,
                    listData: [],
                    userData: [],
                    profileStatus: [],
                    signUpDate: [],
                    trustRateStatus: [],
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }
    }

    imgNotFound = (e)=>{
        e.target.src = DummyProfile;
    }
    
    render() {        

        const { listData, userData, profileStatus, signUpDate, trustRateStatus, isLoader } = this.state;

        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${userData.photo}`
        
        return (
            <section className="bg_userDetails partner-detail">
                <div className="container-fluid">
                    {isLoader ? <NoDataIndication /> :
                        <div className="balance_wrap flex_wrap">                        
                            <div className="leftpart_1">                                
                                <div className="user_wrrapper">
                                    <div className="img_dev">
                                        <img src={userData.photo !== null? imgPath: DummyProfile} onError={(e)=>this.imgNotFound(e)} alt="Partner" />
                                    </div>
                                    <div className="user_content_wrap">
                                        <h5> { userData.first_name !== null?userData.first_name: ''} {userData.last_name!== null?userData.last_name:''}</h5>
                                        <span>@{userData.username !== null? userData.username:'--'}</span>
                                        <span>{userData.email!== null?userData.email: '--'}</span>
                                        <span>ID - {userData.id!== null?userData.id: '-'}</span>
                                    </div>
                                    <div className="partner-detail-review">
                                        <div className="rating">
                                            <p className="rating-badge">{!userData.rate ? '0' : Math.round((userData.rate + Number.EPSILON) * 100) / 100}</p>
                                            
                                            <i className={userData.rate > 0.85 || userData.rate === 1 ? 'star full': 'star'}></i>
                                            <i className={userData.rate > 1.85 || userData.rate === 2 ? 'star full': 'star'}></i>
                                            <i className={userData.rate > 2.85 || userData.rate === 3 ? 'star full': 'star'}></i>
                                            <i className={userData.rate > 3.85 || userData.rate === 4 ? 'star full': 'star'}></i>
                                            <i className={userData.rate > 4.85 || userData.rate === 5 ? 'star full': 'star'}></i>
                                            {/* <span className="count">(128 reviews)</span> */}
                                        </div>
                                        <p className="location">{userData.countryName}</p>
                                        <ul>
                                            <li className={trustRateStatus && trustRateStatus.email && trustRateStatus.email === 1 ? 'active' : ""}>
                                                <div className="icon">
                                                    <svg id="email" xmlns="http://www.w3.org/2000/svg" width="17.684" height="12" viewBox="0 0 17.684 12.6">
                                                    <path id="Path_49" data-name="Path 49" d="M165.313,48.866c0-.02.013-.04.013-.06l-5.4,5.2,5.39,5.034c0-.036-.006-.072-.006-.109Z" transform="translate(-147.642 -47.598)" fill="#fff"/>
                                                    <path id="Path_50" data-name="Path 50" d="M21.628,126.9l-2.2,2.12a.577.577,0,0,1-.792.006l-2.2-2.047-5.425,5.229a1.254,1.254,0,0,0,.422.084H26.618a1.248,1.248,0,0,0,.606-.167Z" transform="translate(-10.164 -119.693)" fill="#fff"/>
                                                    <path id="Path_51" data-name="Path 51" d="M14.514,41.2l8.236-7.934a1.248,1.248,0,0,0-.636-.185H6.927a1.252,1.252,0,0,0-.8.3Z" transform="translate(-5.659 -33.085)" fill="#fff"/>
                                                    <path id="Path_52" data-name="Path 52" d="M0,52.1v9.871a1.254,1.254,0,0,0,.054.327l5.358-5.16Z" transform="translate(0 -50.639)" fill="#fff"/>
                                                    </svg>
                                                </div>
                                                <span>{!userData.email ? 'Email': userData.email}</span>
                                            </li>
                                            <li className={trustRateStatus && trustRateStatus.facebook && trustRateStatus.facebook === 1 ? 'active':""}>
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.841" height="13.68" viewBox="0 0 6.841 13.68">
                                                    <path id="facebook" d="M11.779,2.271h1.249V.1A16.127,16.127,0,0,0,11.208,0,2.888,2.888,0,0,0,8.174,3.214V5.13H6.187V7.562H8.174V13.68H10.61V7.562h1.907l.3-2.432H10.61V3.455c0-.7.19-1.184,1.169-1.184Z" transform="translate(-6.187)" fill="#fff"/>
                                                    </svg>

                                                </div>
                                                <span>Facebook</span>
                                            </li>
                                            <li className={trustRateStatus && trustRateStatus.Verify_id && trustRateStatus.Verify_id === 1 ? 'active':""}>
                                                <div className="icon">
                                                    <svg id="Group_14" data-name="Group 14" xmlns="http://www.w3.org/2000/svg" width="16.654" height="12.6" viewBox="0 0 16.654 12.6">
                                                    <g id="user">
                                                        <g id="Group_11" data-name="Group 11" transform="translate(2.215)">
                                                        <g id="Group_10" data-name="Group 10">
                                                            <path id="Path_53" data-name="Path 53" d="M124.322,0a3.322,3.322,0,1,0,3.322,3.322A3.326,3.326,0,0,0,124.322,0Z" transform="translate(-121)" fill="#fff"></path>
                                                        </g>
                                                        </g>
                                                        <g id="Group_13" data-name="Group 13" transform="translate(0 7.383)">
                                                        <g id="Group_12" data-name="Group 12">
                                                            <path id="Path_54" data-name="Path 54" d="M40.671,301.432a4.737,4.737,0,0,0-3.4-1.432H35.8a4.737,4.737,0,0,0-3.4,1.432,4.841,4.841,0,0,0-1.4,3.416.369.369,0,0,0,.369.369H41.705a.369.369,0,0,0,.369-.369A4.841,4.841,0,0,0,40.671,301.432Z" transform="translate(-31 -300)" fill="#fff"></path>
                                                        </g>
                                                        </g>
                                                    </g>
                                                    <path id="star" d="M6.557,2.873a.349.349,0,0,0-.3-.24l-1.9-.172L3.608.7a.349.349,0,0,0-.643,0L2.215,2.46l-1.9.172a.35.35,0,0,0-.2.611L1.554,4.5,1.131,6.365a.349.349,0,0,0,.52.377l1.637-.978,1.636.978a.349.349,0,0,0,.52-.377L5.02,4.5,6.454,3.244A.35.35,0,0,0,6.557,2.873Zm0,0" transform="translate(10.08 -0.492)" fill="#fff"></path>
                                                    </svg>
                                                </div>
                                                <span>Government Identity</span>
                                            </li>
                                            <li className={trustRateStatus && trustRateStatus.payment && trustRateStatus.payment === 1 ? 'active':""}>
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.944" height="13.68" viewBox="0 0 10.944 13.68">
                                                    <g id="Group_15" data-name="Group 15" transform="translate(-0.4 -0.2)">
                                                        <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
                                                        <path id="Path_60" data-name="Path 60" d="M266.53,34.115l-4.788-2.052a.687.687,0,0,0-.541,0l-4.788,2.052a.682.682,0,0,0-.413.628v2.736c0,3.763,1.391,5.963,5.132,8.118a.688.688,0,0,0,.681,0c3.74-2.149,5.131-4.349,5.131-8.118V34.743A.682.682,0,0,0,266.53,34.115Z" transform="translate(-255.6 -31.808)" fill="#fff"/>
                                                        <text id="_" data-name="$" transform="translate(5.4 10.2)" fill="#c1c1c1" fontSize="9" fontFamily="'SF Pro Text', sans-serif" fontWeight="700"><tspan x="-2.871" y="0">$</tspan></text>
                                                        </g>
                                                    </g>
                                                    </svg>
                                                </div>
                                                <span>Payment</span>
                                            </li>
                                            <li className={trustRateStatus && trustRateStatus.phone_number && trustRateStatus.phone_number === 1 ? 'active':""}>
                                                <div className="icon">
                                                    <svg id="phone-call" xmlns="http://www.w3.org/2000/svg" width="12.574" height="12.6" viewBox="0 0 12.574 12.6">
                                                        <g id="Group_16" data-name="Group 16">
                                                            <path id="Path_61" data-name="Path 61" d="M12.783,9.247,11.025,7.489a1.17,1.17,0,0,0-1.947.44A1.2,1.2,0,0,1,7.7,8.682,5.422,5.422,0,0,1,4.431,5.416a1.138,1.138,0,0,1,.754-1.382,1.17,1.17,0,0,0,.44-1.947L3.866.33a1.254,1.254,0,0,0-1.7,0L.977,1.523C-.216,2.779,1.1,6.107,4.054,9.059s6.28,4.333,7.536,3.077l1.193-1.193A1.254,1.254,0,0,0,12.783,9.247Z" transform="translate(-0.539 0)" fill="#fff"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <span>{!userData.contact_no ? 'Mobile Number': userData.contact_no}</span>
                                            </li>
                                        </ul>
                                        <div className="date-time">
                                            {/* <p className="time">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                    <path id="clock" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm4.756,15.172a.833.833,0,0,1-1.178,0L9.411,11.006a.83.83,0,0,1-.244-.589V5a.833.833,0,0,1,1.667,0v5.072l3.923,3.922A.832.832,0,0,1,14.756,15.172Zm0,0" fill="#fff"/>
                                                </svg>
                                                It’s Currently 2:00 PM Here</p> */}
                                            <p className="date">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.667" height="20" viewBox="0 0 16.667 20" >
                                                    <g id="badge" transform="translate(-47.662 0)">
                                                        <g id="Group_38" data-name="Group 38" transform="translate(42.662 0)">
                                                        <path id="Path_251" data-name="Path 251" d="M58.5,5a5.433,5.433,0,0,1,.747-3.078.417.417,0,0,0-.035-.55L57.957.122a.417.417,0,0,0-.494-.071,7.385,7.385,0,0,1-3.134.782A7.432,7.432,0,0,1,51.194.051a.419.419,0,0,0-.4,0,7.385,7.385,0,0,1-3.134.782A7.432,7.432,0,0,1,44.528.051a.417.417,0,0,0-.493.072l-1.25,1.25a.42.42,0,0,0-.037.547A5.444,5.444,0,0,1,43.5,5a9.33,9.33,0,0,1-.327,2.34,16.608,16.608,0,0,0-.506,4.327c0,3.958,3.078,5.772,8.148,8.29A.435.435,0,0,0,51,20a.424.424,0,0,0,.185-.043c5.07-2.517,8.148-4.332,8.148-8.29a16.641,16.641,0,0,0-.506-4.327A9.358,9.358,0,0,1,58.5,5ZM55.457,8.629,53.54,10.545l.749,3a.412.412,0,0,1,.056.208.433.433,0,0,1-.425.417h-.008a.417.417,0,0,1-.207-.055L51,12.564l-2.71,1.548a.417.417,0,0,1-.611-.463l.776-3.1L46.534,8.627a.417.417,0,0,1,.235-.707l2.685-.383,1.158-2.7a.434.434,0,0,1,.766,0l1.158,2.7,2.685.383a.414.414,0,0,1,.336.281A.42.42,0,0,1,55.457,8.629Z" transform="translate(-37.662 0)" fill="#fff"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                                Joined {signUpDate}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-part">
                                <div className="parts part2">
                                    <div>
                                        <h3>Application</h3>                                
                                        {/* {editData.answers.map((info,i) => info.page === 1?<div className="form-group" key={i}> <span>{info.question}</span> <p>{info.answer}</p> </div>:null )} */}
                                        {listData.map((info,i) => info.page === 1? <div className="form-group" key={i}>  <span>{info.question}</span>  <p>{info.answer}</p> </div> : null )}
                                    </div>
                                </div>
                                <div className="parts part3">
                                    <div>
                                        <h3>About me</h3>
                                        {/* {editData.answers.map((info,i) => info.page === 2?<div className="form-group" key={i}> <span>{info.question}</span> <p>{info.answer}</p> </div>:null )} */}
                                        {listData.map((info,i) => info.page === 2?<div className="form-group" key={i}> <span>{info.question}</span> <p className={info.id === 7 ? 'textarea':''}>{info.answer}</p>  </div>:null )}
                                    </div>
                                    <div>
                                        <h3>My Profile</h3>
                                        <span>Profile Status</span>
                                        <div className="form-group flex">                                        
                                            <p>This Profile is {profileStatus.total_percentage}% Completed<span className="profile-status">Your Profile is <b>{profileStatus.total_percentage}%</b></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     }
                </div>
            </section>
        )
    }
}


const mapStateToProps = state=>({
    editData:state.partner.editData
})

export default withRouter(connect(mapStateToProps)(PartnerDetail));