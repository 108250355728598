import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import NoDataIndication from '../../TableLoader';
import SearchIcon from '../../../assets/images/search_icon.svg';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper'
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
// import * as ACTION_TYPES from '../../../redux/actions/types';

class TopSkillStates extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoader: false,
            isSLoader: false,
            isSelectLoader: false,
            isMainLoader: false,
            pageID: null,
            stateArr: [],
            searchStateArr: [],
            selectedStateArray: [],
            selectedStateID: [],
            searchVal: '',
            checked: false,
            selectedOption: [],
            options: []
        }
    }
    componentDidMount() {
        this.getAllCountry();
        this.getAllState();
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            pageID: myParam
        })
        this.getSelectedState(myParam);
    }

    getAllCountry = async () => {
        try {
            this.setState({
                isSelectLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }          
            const response = await apiHalper('getCountries', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    options: [],
                    isSelectLoader: false
                })
            }
            const selectedConList = jwtDecode(res.data)

            const sortList = selectedConList.sort((a, b) => {
                if (a.countryName > b.countryName) return 1
                if (a.countryName < b.countryName) return -1
                return 0;
            });
            this.setState({                
                options: sortList.map(info => ({ value: info.id, label: info.countryName })),
                isSelectLoader: false,
                selectedOption: { value: sortList[0].id, label: sortList[0].countryName }
            })
            this.getAllState(sortList[0].id);
        }
        catch (err) {
            console.error(err.message)
        }
    }

    getAllState = async (countryID) => {
        try {
            this.setState({
                isMainLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getStates/${countryID}`, 'GET', null, headers);
            const res = response.data;
            
            if (!res.status) {
                this.setState({
                    stateArr: [],
                    searchStateArr: [],
                    isMainLoader: false
                })
            }
            const decode = jwtDecode(res.data)
            const sortList = decode.sort((a, b) => {
                if (a.stateName > b.stateName) return 1
                if (a.stateName < b.stateName) return -1
                return 0;
            });

            this.setState({
                stateArr: sortList,
                searchStateArr: sortList,
                isMainLoader: false
            })

        }
        catch (err) {
            console.error(err.message)
        }
    }
    StateRender = (data, i) => {
        const { selectedStateID } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedStateID.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }
        return <li key={i}>
            <label htmlFor={"stateID" + data.id}>
                <input type="checkbox" id={"stateID" + data.id} value={data.id} name="stateID" onClick={() => this.addStateBind(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.stateName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    addStateBind = (data) => {
        let newArray = this.state.selectedStateArray;
        let ConID = this.state.selectedStateID;
        if (newArray.some(info => info.id === data.id)) {

            document.getElementById('stateID' + data.id).removeAttribute('checked', true);
            document.getElementById('stateID' + data.id).removeAttribute('class', 'active');

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }
        else {
            document.getElementById('stateID' + data.id).setAttribute('checked', true);
            document.getElementById('stateID' + data.id).setAttribute('class', 'active');
            newArray.push({ id: data.id, stateName: data.stateName });
            ConID.push(data.id);
        }

        if (newArray.length > 0) {
            this.setState({
                selectedStateArray: newArray,
                selectedStateID: ConID
            })
        }
    }
    SelectedStateRender = (data, i) => {
        return <li key={i}>
            <label htmlFor={"selectState" + i}>
                <input type="checkbox" id={"selectState" + i} value={data.id} name="selectedCon" defaultChecked onClick={() => this.removeSelectedState(data)} className="active" />
                <p><span>{data.stateName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    removeSelectedState = (data) => {
        let newArray = this.state.selectedStateArray;
        let ConID = this.state.selectedStateID;
        if (newArray.some(info => info.id === data.id)) {

            if(document.getElementById('stateID' + data.id)) {
                document.getElementById('stateID' + data.id).removeAttribute('checked', true);
                document.getElementById('stateID' + data.id).removeAttribute('class', 'active');
            }

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }

        this.setState({
            selectedStateArray: newArray,
            selectedStateID: ConID
        })
    }
   
    getSelectedState = async (id) => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getTopStatesSkills/${id}`, 'GET', null, headers)
            const res = response.data;
            if (!res.status) {
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                return true
            }
            const decode = jwtDecode(res.data)
            let statId = []
            for (let sid = 0; sid < decode.length; sid++ ) {
                statId.push(decode[sid].id)
            }
            
            this.setState({
                selectedStateArray: decode,
                selectedStateID: statId
            })
            this.setState({
                isLoader: false
            })
        }
        catch (error) {
            console.log(error.message)
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getAllState(selectedOption.value);
    };
      
    searchState = async (e) => {
        e.preventDefault();
        const { searchStateArr, searchVal } = this.state;

        const search = searchStateArr.filter(info => info.stateName.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchStateArr: search
        })
    }
    stateSearchInput = (e) => {
        const { value } = e.target;
        const { stateArr } = this.state;

        if (value.length > 0) {
            const search = stateArr.filter(info => info.stateName.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchStateArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchStateArr: stateArr,
                searchVal: value
            })
        }

    }
    StateRender = (data, i) => {
        const { selectedStateID } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedStateID.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
        <label htmlFor={"stateID" + data.id}>
            <input type="checkbox" id={"stateID" + data.id} value={data.id} name="stateID" onClick={() => this.addStateBind(data)} defaultChecked={checked1} className={activeClass} />
            <p><span>{data.stateName}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="add-icon" transform="translate(-13604 1017)">
                        <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                        </g>
                        <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                    </g>
                </svg>
            </p>
        </label>
    </li>
    }
    submitStateList = async () => {
        const { selectedStateID, pageID  } = this.state
        try {
            if(selectedStateID.length > 0) {
                this.setState({
                    isSLoader: true,
                    apiError: ''
                })
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData()

                formData.append('serviceCategoryID', pageID)
                formData.append('stateID', JSON.stringify(selectedStateID))
                const response = await apiHalper('addTopStatesSkills', 'POST', formData, headers)

                const res = response.data;
                console.log(res)
                if (!res.status) {
                    this.setState({
                        apiErr: res.message,
                        isSLoader: false
                    })
                    toster_error(res.message);
                    return true
                }
                toster_sucess(res.message);
                this.setState({
                    isSLoader: false
                })
            }
            else {
                this.setState({
                    apiError: 'Please Select at least one State',
                    isSLoader: false
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }
    render() {
        const {  isSLoader, selectedOption, options, isMainLoader, isSelectLoader, apiError } = this.state
        
        return (
            <>            
                <div className="country-dropdown"><label>Select Country: </label><Select value={selectedOption} onChange={this.handleChange} options={options} /></div>
                {isSelectLoader ? <span className="select-loader"><img src={LoaderImg} className="small-loader" alt="loader" /></span> : null}
                <div className="top-list">
                    {isMainLoader ? <NoDataIndication /> :
                        <div className={this.state.selectedStateArray.length > 0 ? 'list state' : 'list state no-result'}>
                            <form action="/" method="POST">
                                <ul>
                                    {this.state.selectedStateArray.length > 0 ?
                                        this.state.selectedStateArray.map((data, i) => this.SelectedStateRender(data, i)) : <li>No State Available</li>}
                                </ul>
                            </form>
                        </div>
                    }
                    {isMainLoader ? <NoDataIndication /> : 
                        <div className={this.state.searchStateArr.length > 0 ? 'list state' : 'list state no-result'}>                            
                            <form className="search-form" action="/" method="POST" onSubmit={this.searchState}>
                                <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search State" onChange={this.stateSearchInput} />
                                <button type="submit"><img src={SearchIcon} alt="Search" /></button>
                            </form>
                            <form action="/" method="POST">
                                <ul>
                                    { isMainLoader ? <NoDataIndication /> : this.state.searchStateArr.length > 0 ? this.state.searchStateArr.map((data, i) => this.StateRender(data, i)) : <li>No State Available</li> }
                                </ul>
                            </form>
                        </div>
                    }
                </div>
                <div className="bg_submit2 right-mx120">
                    {apiError !== '' ? <span className="skill-error">{apiError}</span> : null }
                    <button type="submit" className="btn-submit" onClick={this.submitStateList}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                </div>                
            </>
        )
    }
}
export default withRouter(connect(null)(TopSkillStates));