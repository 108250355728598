import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import moment from 'moment/moment';
import InfiniteScroll from "react-infinite-scroll-component";
import UserLoader from '../../assets/images/user-loader.gif';
import ConfirmModal from '../ConfirmModal';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';

class ReassignJobModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            search_by: '',
            page_no: 1,
            limit: 10,
            agents: [],
            path: null,
            hasMore: true,
            totalCount: null,
            modalState: false,
            modalMesage: '',
            job_post_bid_id: null, 
            current_agent: null, 
            new_agent: null,
        }
    }

    componentDidMount() {
        const { search_by, page_no, limit } = this.state
        this.getVIP(search_by, page_no, limit)
    }

    getVIP = async (search_by, page_no, limit) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('search_by', search_by)
            formData.append('page_no', page_no)
            formData.append('limit', limit)
            const response = await apiHalper('getVIP', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                this.setState({
                    agents: search_by === '' ? [...this.state.agents, ...decode.VipUsers] : decode.VipUsers,
                    path: decode.path,
                    totalCount: decode.Count[0].count
                })
                console.log(this.state.agents)
            } else {
                this.setState({
                    isLoader: false,
                });
            }

        } catch (err) {
            console.log(err);
        }
    }

    fetchMoreData = () => {
        console.log("LOG")
        if (this.state.agents.length >= this.state.totalCount) {
            this.setState({ hasMore: false });
            return;
        }
        this.setState({
            page_no: this.state.page_no + 1
        })
        const { search_by, page_no, limit } = this.state
        this.getVIP(search_by, page_no, limit)
    };

    handleSearch = async () => {
        this.setState({
            page_no: 1
        })
        const { search_by, page_no, limit } = this.state

        this.getVIP(search_by, page_no, limit)
    }
    inputHandler = (e) => {
        this.setState({
            search_by: e.target.value
        })
    }
    reassignJob = async (e, profileID) => {
        e.preventDefault()
        this.setState({
            modalState: true,
            modalMesage: "reassign this job?",
            current_agent: this.props.modalData.agentID,
            new_agent: profileID,
            job_post_bid_id: this.props.modalData.bidID
        })
    }
    
    modalCloseConfirm = () =>{
        this.setState({
            modalState: false,
            modalMesage: ""
        })
    }

    handleSubmitReassignJob = async () => {
        const { job_post_bid_id, current_agent, new_agent } = this.state;
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('job_post_bid_id', job_post_bid_id)
            formData.append('current_agent', current_agent)
            formData.append('new_agent', new_agent)
            const response = await apiHalper('reAssignJob', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                toster_sucess(res.message)
                this.modalCloseConfirm()
                this.props.modalData.modalClose()
                const { history } = this.props
                history.push('/allJobs');
            } else {
                toster_error(res.message)
                this.modalCloseConfirm()
                this.props.modalData.modalClose()
                this.setState({
                    isLoader: false,
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { isOpen, text, modalClose, confirmHandler } = this.props.modalData;
        const { agents, path, search_by, modalState } = this.state

        const confirmModalData = {
            isOpen: this.state.modalState,
            modalClose: this.modalCloseConfirm,
            text: this.state.modalMesage,
            confirmHandler: this.handleSubmitReassignJob
        }

        return (
            <>
            <Modal show={isOpen} onHide={() => modalClose()} className="confirm-moda" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="reassign-job-modal">
                    <div className='reassign-job-header'>
                        <h1>Reassign Job</h1>
                    </div>
                    <div className="reassign-job-body">
                        <div className='search-wrapper'>
                            <input type="text" name='search' value={search_by} onChange={this.inputHandler} placeholder='Search' className='form-control' />
                            <button className='btn btn-primary' onClick={this.handleSearch}>Search</button>
                        </div>
                        <div className='agents-list-wrapper'>
                            {this.state.agents.length > 0 ?
                            <InfiniteScroll
                                dataLength={this.state.agents.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.hasMore}
                                loader={<div className='loader'><img src={UserLoader} width={40} height={40} /></div>}
                                height={450}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                {agents.map(item => (
                                    <a href='#' key={item.profileID} onClick={(e) => (this.reassignJob(e, item.profileID))}>
                                        <div className='agent-box'>
                                            <div><img src={item.img ? path + item.img : DummyProfile} alt="Profile Picture" /></div>
                                            <div style={{ width: "100%" }}>
                                                <p>{item.username}</p>
                                                <div className='details'>
                                                    <div>ID: {item.profileID}</div>
                                                    <div>Joined: {moment(item.createdAt).format('LL')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </InfiniteScroll>
                            :<div className='loader'><p>No Data Found</p></div>}
                        </div>
                    </div>
                    {/* <button type="button" aria-label="Cancel" className="btn-cancel" onClick={() => modalClose()}>Cancel</button>
                        <button type="button" aria-label="Confirm" className="btn-confirm" onClick={confirmHandler}>Confirm</button> */}
                </div>
            </Modal>
            {modalState?
                <ConfirmModal modalData={confirmModalData}/>
            :null}
            </>
        )
    }
}

export default withRouter(connect(null)(ReassignJobModal));