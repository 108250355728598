import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import Select from 'react-select';
import DataTable from '../JqueryDataTable';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import { apiDeviceHelper } from '../../helpers/ApiHelper';
// import { jwtDecode } from '../../helpers/jwt_helper';

class Admin extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            selectedOption:null,
            isClearable:true,
            adminData:[],
            page:1,
            isLoader:false,
            search:''
        }
    }

    componentDidMount(){
        this._ismounted = true;
        this.getAdminApi(this.state.page, this.state.search);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getAdminApi = async (page, search) =>{
        if(this._ismounted){
            this.setState({
                isLoader:true
            })

            // const headers = {
            //     'Authorization':localStorage.getItem('token')
            // }

            let formData = new FormData();
            if(search !== ''){
                formData.append('search', search)
            }

            const res = await apiDeviceHelper(`Profiles/getAdminProfile/${page}.json`, 'POST', formData, null);
            // console.log(res);
            if(res.data.flag === 1){
                // const data  = jwtDecode(res.data.data);
                const data = res.data.data;
                
                // let adminData = this.state.adminData;
                // adminData = data.user_list;

                const { addCounts } = this.props;
                addCounts(data.user_count.client, data.user_count.agent, data.user_count.admin);

                this.setState({
                    adminData:data.user_list,
                    isLoader:false
                })
            }else{
                this.setState({
                    adminData:[],
                    isLoader:false
                })
            }
        }

    }

    headerSpan = ( column, colIndex )=>{
        if(column.text === 'Users'){
            return <span className={column.sort?"header_span pd_left sort":"header_span pd_left"}>{column.text}</span>;
        }else if(column.text === 'Action'){
            return <span className={column.sort?"header_span text-center sort":"header_span text-center"}>{column.text}</span>;
        }
        else if(column.text === 'Admin ID'){
            return <span className={column.sort?"header_span text-center sort":"header_span text-center"}>{column.text}</span>;
        }
        else if(column.text === 'Status'){
            return <span className={column.sort?"header_span text-center sort":"header_span text-center"}>{column.text}</span>;
        }
        else{
            return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
    }

    openEditModal = async (id) =>{
        const response = await apiDeviceHelper(`Profiles/viewAdminProfile/${id}.json`,'GET', null, null);
        if(response.data.flag === 1){
            const data = response.data.data;
            const { editAdmin } = this.props;
            editAdmin(data);
        }
    }

    actionField = (cell, row) =>{
        
        return <button type="button" className="btn-view margin_0auto" onClick={()=>this.openEditModal(row.id)}>Edit</button>;
    }
    amountField = (cell, row) =>{
        return <strong className="text-center margin_0auto">{cell}</strong>;
    }
    statusField = (cell) =>{

        if(cell === 1){
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        }else{
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }
        
    }

    imgNotFound = (e)=>{
        console.log(e);
    }

    userField = (cell, row) => {
        if(row.flag === 1){
            return <div className="tb_user_wrap flag_icon">
                <div className="useimg_block online_icon">
                    {/* row.photo !== null?row.photo: */}
                    <img src={row.photo !== null?row.photo.img:DummyProfile} onError={(e)=>this.imgNotFound(e)} alt="profile" />
                </div>
                <p>{cell}</p>
            </div>
        }else{
            return <div className="tb_user_wrap">
                <div className="useimg_block online_icon">
                    <img src={row.photo !== null?row.photo.img:DummyProfile} alt="profile" />
                </div>
                <p>{cell}</p>
            </div>
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
            this.getClientApi(page, searchText);
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
            this.getClientApi(page, searchText);
        }
    }

    
    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        // const options = [
        //     { value: 0, label: 'Join' },
        //     { value: 1, label: 'Keyword' },
        //     { value: 2, label: 'Chat' },
        //     { value: 3, label: 'Flag' },
        //     { value: 4, label: 'Paid' },
        //     { value: 5, label: 'Jobs' },
        //     { value: 6, label: 'Report' }
        // ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter:this.userField },
            { dataField: 'id', text: 'Admin ID', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // const data = [
        //     { id:'1', username:'john Doe', adminId:57874, status:0, amount:'+$21.30', flag:0 },
        //     { id:'2', username:'Steve Smith', adminId:44093, status:1, amount:'+$21.30', flag:0 },
        //     { id:'3', username:'john Doe', adminId:57874, status:1, amount:'+$21.30', flag:1 },
        //     { id:'4', username:'Steve Smith', adminId:44093, status:0, amount:'+$21.30', flag:0 },
        //     { id:'5', username:'john Doe', adminId:57874, status:1, amount:'+$21.30', flag:0 },
        //     { id:'6', username:'Steve Smith', adminId:44093, status:1, amount:'+$21.30', flag:0 },
        //     { id:'7', username:'john Doe', adminId:57874, status:0, amount:'+$21.30', flag:1 },
        //     { id:'8', username:'Steve Smith', adminId:44093, status:0, amount:'+$21.30', flag:0 },
        //     { id:'9', username:'john Doe', adminId:57874, status:0, amount:'+$21.30', flag:0 },
        //     { id:'10', username:'Steve Smith', adminId:44093, status:1, amount:'+$21.30', flag:0 },
        //     { id:'11', username:'john Doe', adminId:57874, status:1, amount:'+$21.30', flag:0 },
        //     { id:'12', username:'Steve Smith', adminId:44093, status:0, amount:'+$21.30', flag:1 },
        //     { id:'13', username:'john Doe', adminId:57874, status:1, amount:'+$21.30', flag:0 },
        //     { id:'14', username:'Steve Smith', adminId:44093, status:0, amount:'+$21.30', flag:0 },
        //     { id:'15', username:'john Doe', adminId:44093, status:1, amount:'+$21.30', flag:0 },
        //     { id:'16', username:'Steve Smith', adminId:57874, status:1, amount:'+$21.30', flag:0 }
        // ]

        const tableData = {
            columns:columns,
            data:this.state.adminData,
            keyId:'id',
            loading:this.state.isLoader,
            message:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.props.adminCount,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        // const { selectedOption, isClearable } = this.state;

        const { addUser } = this.props;

        return (
            <div className="tab_container">
                <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button>
                {/* <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                </div> */}
                <DataTable TableData={tableData} />           
            </div>
        )
    }
}

const mapStateToProps = state =>({
    adminCount:state.users.admin
})

const mapDispatchToProps = (dispatch) =>({
    addUser:()=>dispatch({type:ACTION_TYPES.TOGGLE_USER_MODAL}),
    editAdmin:(data)=>dispatch({type:ACTION_TYPES.TOGGLE_ADMIN_MODAL, payload:data}),
    addCounts:(client, agent, admin)=>dispatch({type:ACTION_TYPES.TOTAL_USERS_COUNT,payload:{client, agent, admin}})
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
