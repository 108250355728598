import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
// import TableView from '../TableView';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import DataTable from '../JqueryDataTable';
import moment from 'moment';
import { jwtDecode } from '../../helpers/jwt_helper';

class Agents extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            istester: false,
            selectedOption: null,
            isClearable: true,
            agentsData: [],
            page: 1,
            search: '',
            isLoader: false,
            clientCount: 0,
            agentCount: 0,
            adminCount: 0,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page, istester, search } = this.state;
        this.getTotalUsersApi(istester);
        this.getAgentApi(page, search, istester);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getTotalUsersApi = async (istester) => {
        if (this._ismounted) {
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('show_tester', istester ? 1 : 0)

            const res = await apiHalper(`getTotalUser`, 'POST', formData, headers);
            const { addCounts } = this.props;
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.setState({
                    clientCount: data.client_count,
                    agentCount: data.agent_count,
                    adminCount: data.admin_count
                })
                addCounts(data.client_count, data.agent_count, data.admin_count);
            } else {
                this.setState({
                    clientCount: 0,
                    agentCount: 0,
                    adminCount: 0
                })
                addCounts(0, 0, 0);
            }
        }

    }

    getAgentApi = async (page, search, istester) => {
        if (this._ismounted) {
            this.setState({
                isLoader: true
            })

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const { selectedOption } = this.state;
            let formData = new FormData();
            if (selectedOption !== null) {
                formData.append('sort_by', selectedOption.value)
            }
            if (search !== '') {
                formData.append('search_by', search)
            }

            formData.append('page_no', page)
            formData.append('influencer_bird', 1)
            formData.append('show_tester', istester ? 1 : 0)

            const res = await apiHalper(`getAgentLists`, 'POST', formData, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);

                this.setState({
                    agentCount: data.user_count,
                    agentsData: data.agent_data,
                    totalRecord: data.user_count,
                    isLoader: false
                })
            } else {
                this.setState({
                    agentsData: [],
                    isLoader: false
                })
            }
        }

    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getAgentApi(this.state.page, this.state.search)
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort pd_left" : "header_span pd_left"}>{column.text}</span>;
            case 'job_count':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'chat':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'keyword':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'report_count':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'project_amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'profile_status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">0</span>;
    }
    centerJobField = (cell, row) => {
        return <span className="text-center margin_0auto">{row.job_count}</span>;
    }
    centerReportsField = (cell, row) => {
        return <span className="text-center margin_0auto">{row.count_reports}</span>;
    }

    actionField = (cell, row) => {

        return <Link to={`/usersDetails/${row.id}?type=agents`} className="btn-view margin_0auto">View</Link>;
    }
    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{row.total_amount}</span>;
    }
    statusField = (cell) => {

        if (cell === 1) {
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }

    }

    imgNotFound = (e) => {
        console.log(e);
    }

    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.photo}`

        if (row.flag === 1) {
            return <div className="tb_user_wrap flag_icon">
                <div className="useimg_block online_icon">
                    <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
                </div>
                <p>{cell} {this.checkRatings(Math.round(row.starpoints))}</p>
            </div>
        } else {
            return <div className="tb_user_wrap">
                <div className="useimg_block online_icon">
                    <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
                </div>
                <p>{cell} {this.checkRatings(Math.round(row.average_rate))}</p>
            </div>
        }
    }

    checkRatings = (rating) => {
        switch (rating) {
            case 1:
                return <span className="rating_wrap rating1"></span>;
            case 2:
                return <span className="rating_wrap rating2"></span>;
            case 3:
                return <span className="rating_wrap rating3"></span>;
            case 4:
                return <span className="rating_wrap rating4"></span>;
            case 5:
                return <span className="rating_wrap rating5"></span>;
            default:
                return <span className="rating_wrap rating0"></span>;
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            this.getAgentApi(page, searchText, this.state.istester);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getAgentApi(page, searchText, this.state.istester);
        }
    }

    isTester = (row) => {
        let classs = null;
        if (row.is_tester === 1) {
            classs = 'tester_tr'
        } else {
            classs = null;
        }

        return classs;
    }

    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    testerHandler = (e) => {
        // const { checked } = e.target;
        this.setState({
            istester: !this.state.istester,
            page: 1,
            // search: ''
        });

        const { page, search } = this.state;
        this.getAgentApi(1, search, !this.state.istester);
        this.getTotalUsersApi(!this.state.istester);
    }

    render() {
        const options = [
            { value: 0, label: 'Join' },
            { value: 1, label: 'Keyword' },
            { value: 2, label: 'Chat' },
            { value: 3, label: 'Flag' },
            { value: 4, label: 'Paid' },
            { value: 5, label: 'Jobs' },
            { value: 6, label: 'Report' }
        ];

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter: this.userField },
            { dataField: 'signup_date', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'job_count', text: 'No of Campaign', sort: true, headerFormatter: this.headerSpan, formatter: this.centerJobField },
            // { dataField: 'chat', text: 'No of chat', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'keyword', text: 'Keyword', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'report_count', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter: this.centerReportsField },
            { dataField: 'profile_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'project_amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // const data = [
        //     { id:'1', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'2', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'3', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:1 },
        //     { id:'4', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'5', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'6', username:'john Doe', star:0, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'7', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
        //     { id:'8', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'9', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'10', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'11', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'12', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
        //     { id:'13', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'14', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
        //     { id:'15', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
        //     { id:'16', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 }
        // ]

        console.log({ agentsData: this.state.agentsData })

        const tableData = {
            columns: columns,
            data: this.state.agentsData,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.agentCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { selectedOption, isClearable } = this.state;

        const { addUser } = this.props;

        return (
            <div className="tab_container">
                <div className='d-flex'>
                <button type="button" className="btn_addUsers" onClick={() => addUser()}>Add</button>
                <div className="tester_view user_table_view">
                    <p>Tester Record</p>
                    <button type="button" className={this.state.istester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                        <span className="label_text"></span>
                    </button>
                </div>
                </div>
                <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div>
                <DataTable TableData={tableData} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    agentCount: state.users.agent
})

const mapDispatchToProps = (dispatch) => ({
    addUser: () => dispatch({ type: ACTION_TYPES.TOGGLE_USER_MODAL }),
    addCounts: (client, agent, admin) => dispatch({ type: ACTION_TYPES.TOTAL_USERS_COUNT, payload: { client, agent, admin } })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agents));
