import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import { apiHalper } from "../../helpers/ApiHelper";
import { jwtDecode } from "../../helpers/jwt_helper";
import moment from "moment";
import "./index.css";
import * as ACTION_TYPES from "../../redux/actions/types";
import FilterModal from "./FilterModal";
import * as NumHelper from "../../helpers/NumberFormate";

class GigReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRevenue: true,
            isGigs: true,
            filter_date_start: moment().subtract(1, "days").format("YYYY-MM-DD"),
            filter_date_end: moment().format("YYYY-MM-DD"),
            total_gigs0: 0,
            total_gigs1: 0,
            total_gigs2: 0,
            gigs_revenue0: {},
            gigs_revenue1: {},
            gigs_revenue2: {},
            gigs0: {
                gigs_completed: 0,
                gigs_in_progress: 0,
                gigs_waiting: 0,
                gigs_submit: 0,
                gigs_canceled: 0,
                gigs_refunded: 0,
            },
            gigs1: {
                gigs_completed: 0,
                gigs_in_progress: 0,
                gigs_waiting: 0,
                gigs_submit: 0,
                gigs_canceled: 0,
                gigs_refunded: 0,
            },
            gigs2: {
                gigs_completed: 0,
                gigs_in_progress: 0,
                gigs_waiting: 0,
                gigs_submit: 0,
                gigs_canceled: 0,
                gigs_refunded: 0,
            },
        };
    }

    componentDidMount() {
        this.getGigsReport("day", 0);
        this.getGigsReport("month", 1);
        this.getGigsReport("year", 2);
    }

    numberFormate = (val) => {
        return NumHelper.numberWithCommas(val);
    };

    componentDidUpdate(prevProps) {
        const { filterSelect, typeVal, isApply, applyFilterFun } = this.props;
        if (typeVal !== prevProps.typeVal) {
            if (isApply !== prevProps.isApply) {
                switch (filterSelect) {
                    case 0:
                        this.getGigsReport(typeVal, 0);
                        break;
                    case 1:
                        this.getGigsReport(typeVal, 1);
                        break;
                    case 2:
                        this.getGigsReport(typeVal, 2);
                        break;
                    default:
                        break;
                }
                applyFilterFun(false);
            }
        }
    }

    getGigsReport = async (type, row) => {
        const { dayValue, isDdate, d_start_date, d_end_date } = this.props;
        const { monthValue, isMdate, m_start_date, m_end_date } = this.props;
        const { yearValue, isYdate, y_start_date, y_end_date } = this.props;

        switch (row) {
            case 0:
                if (!isDdate) {
                    let formData = new FormData();
                    formData.append("type", type);
                    formData.append("value", dayValue);
                    formData.append("influencer_bird", 1);

                    const headers = {
                        Authorization: localStorage.getItem("token"),
                    };
                    const res = await apiHalper("getGigReports", "POST", formData, headers);
                    if (res.data.status === true) {
                        const data = jwtDecode(res.data.data);
                        this.loadData(data, row);
                    }
                } else {
                    let formData2 = new FormData();
                    formData2.append("start_date", moment(d_start_date).format("YYYY-MM-DD"));
                    formData2.append("end_date", moment(d_end_date).format("YYYY-MM-DD"));

                    const headers = {
                        Authorization: localStorage.getItem("token"),
                    };

                    const res = await apiHalper("getGigReportByDateRange", "POST", formData2, headers);
                    if (res.data.status === true) {
                        const data = jwtDecode(res.data.data);
                        this.loadData(data, row);
                    }
                }
                break;
            case 1:
                if (!isMdate) {
                    let formData = new FormData();
                    formData.append("type", type);
                    formData.append("value", monthValue);
                    formData.append("influencer_bird", 1);
                    const headers = {
                        Authorization: localStorage.getItem("token"),
                    };

                    const res = await apiHalper("getGigReports", "POST", formData, headers);
                    if (res.data.status === true) {
                        const data = jwtDecode(res.data.data);
                        this.loadData(data, row);
                    }
                } else {
                    let formData2 = new FormData();
                    formData2.append("start_date", moment(m_start_date).format("YYYY-MM-DD"));
                    formData2.append("end_date", moment(m_end_date).format("YYYY-MM-DD"));

                    const headers = {
                        Authorization: localStorage.getItem("token"),
                    };

                    const res = await apiHalper("getGigReportByDateRange", "POST", formData2, headers);
                    if (res.data.status === true) {
                        const data = jwtDecode(res.data.data);
                        this.loadData(data, row);
                    }
                }
                break;
            case 2:
                if (!isYdate) {
                    let formData = new FormData();
                    formData.append("type", type);
                    formData.append("value", yearValue);
                    formData.append("influencer_bird", 1);
                    const headers = {
                        Authorization: localStorage.getItem("token"),
                    };

                    const res = await apiHalper("getGigReports", "POST", formData, headers);
                    if (res.data.status === true) {
                        const data = jwtDecode(res.data.data);
                        this.loadData(data, row);
                    }
                } else {
                  let formData2 = new FormData();
                  formData2.append('start_date', moment(y_start_date).format('YYYY-MM-DD'));
                  formData2.append('end_date', moment(y_end_date).format('YYYY-MM-DD'));
      
                  const headers = {
                      'Authorization': localStorage.getItem('token')
                  }
      
                  const res = await apiHalper('getGigReportByDateRange', 'POST', formData2, headers);
                  if (res.data.status === true) {
                      const data = jwtDecode(res.data.data);
                      this.loadData(data, row);
                  }
                }
                break;
        }
    };

    loadGigsData = (data) => {
        let gigs_in_progress = 0,
            gigs_completed = 0,
            gigs_canceled = 0,
            gigs_waiting = 0,
            gigs_submit = 0,
            gigs_refunded = 0;
        data.gigs.map((item) => {
            switch (item.gigStateID) {
                case 2:
                    gigs_in_progress = item.count;
                    break;
                case 4:
                    gigs_completed = item.count;
                    break;
                case 5:
                    gigs_canceled = item.count;
                    break;
                case 7:
                    gigs_waiting = item.count;
                    break;
                case 8:
                    gigs_submit = item.count;
                    break;
                case 9:
                    gigs_refunded = item.count;
                    break;
            }
        });
        return { gigs_in_progress, gigs_completed, gigs_canceled, gigs_waiting, gigs_submit, gigs_refunded };
    };

    loadData = (data, row) => {
        switch (row) {
            case 0:
                const _gigs = this.loadGigsData(data);
                this.setState({
                    gigs_revenue0: data.general,
                    total_gigs0: data.total.shift().total_count,
                    gigs0: {
                        gigs_completed: _gigs.gigs_completed,
                        gigs_in_progress: _gigs.gigs_in_progress,
                        gigs_waiting: _gigs.gigs_waiting,
                        gigs_submit: _gigs.gigs_submit,
                        gigs_canceled: _gigs.gigs_canceled,
                        gigs_refunded: _gigs.gigs_refunded,
                    },
                });
                break;
            case 1:
                const _gigs1 = this.loadGigsData(data);
                this.setState({
                    gigs_revenue1: data.general,
                    total_gigs1: data.total.shift().total_count,
                    gigs1: {
                        gigs_completed: _gigs1.gigs_completed,
                        gigs_in_progress: _gigs1.gigs_in_progress,
                        gigs_waiting: _gigs1.gigs_waiting,
                        gigs_submit: _gigs1.gigs_submit,
                        gigs_canceled: _gigs1.gigs_canceled,
                        gigs_refunded: _gigs1.gigs_refunded,
                    },
                });
                break;
            case 2:
                const _gigs2 = this.loadGigsData(data);
                this.setState({
                    gigs_revenue2: data.general,
                    total_gigs2: data.total.shift().total_count,
                    gigs2: {
                        gigs_completed: _gigs2.gigs_completed,
                        gigs_in_progress: _gigs2.gigs_in_progress,
                        gigs_waiting: _gigs2.gigs_waiting,
                        gigs_submit: _gigs2.gigs_submit,
                        gigs_canceled: _gigs2.gigs_canceled,
                        gigs_refunded: _gigs2.gigs_refunded,
                    },
                });
                break;
        }
    };

    collapseHandler = (name) => {
        switch (name) {
            case "revenue":
                this.setState({
                    isRevenue: !this.state.isRevenue,
                });
                break;
            case "gigs":
                this.setState({
                    isGigs: !this.state.isGigs,
                });
                break;
            default:
                this.setState({
                    isRevenue: true,
                    isGigs: false,
                });
                break;
        }
    };

    openFilterModal = (val) => {
        const { openFModal } = this.props;
        openFModal(val);
    };

    render() {
        const { isRevenue, isGigs, gigs0, gigs1, gigs2, total_gigs0, total_gigs1, total_gigs2, gigs_revenue0, gigs_revenue1, gigs_revenue2 } = this.state;
        const { isDdate, isMdate, isYdate, d_start_date, d_end_date, m_start_date, m_end_date, y_start_date, y_end_date, FilterD, FilterM, FilterY } =
            this.props;

        return (
            <section className="bg-balance">
                <div className="balance_wrap">
                    <div className="accroding_wrap">
                        <div className="filter_wrap">
                            <p>Date</p>
                            <div className="filter_button_wrap">
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(0)}>
                                    {isDdate ? (
                                        <span className="date_font">
                                            {moment(d_start_date).format("MMM DD, YYYY") + " - " + moment(d_end_date).format("MMM DD, YYYY")}
                                        </span>
                                    ) : (
                                        FilterD
                                    )}
                                </button>
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(1)}>
                                    {isMdate ? (
                                        <span className="date_font">
                                            {moment(m_start_date).format("MMM DD, YYYY") + " - " + moment(m_end_date).format("MMM DD, YYYY")}
                                        </span>
                                    ) : (
                                        FilterM
                                    )}
                                </button>
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(2)}>
                                    {isYdate ? (
                                        <span className="date_font">
                                            {moment(y_start_date).format("MMM DD, YYYY") + " - " + moment(y_end_date).format("MMM DD, YYYY")}
                                        </span>
                                    ) : (
                                        FilterY
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler("revenue")} aria-controls="revenue" aria-expanded={isRevenue}>
                                Revenue <span className="icon_wrap_collapse"></span>{" "}
                            </button>
                            <Collapse in={isRevenue}>
                                <div id="revenue" className="repo_warp">
                                    <ul className="ul_jobs">
                                        <li className="green">
                                            <p>
                                                <b>Gigs Gross Income</b>{" "}
                                                <strong>
                                                    <span>${gigs_revenue0.total_income_2}</span>
                                                    <span>${gigs_revenue1.total_income_2}</span>
                                                    <span>${gigs_revenue2.total_income_2}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className="green">
                                            <p>
                                                <b>Gigs Cost</b>{" "}
                                                <strong>
                                                    <span>${gigs_revenue0.total_cost}</span>
                                                    <span>${gigs_revenue1.total_cost}</span>
                                                    <span>${gigs_revenue2.total_cost}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className="green">
                                            <p>
                                                <b>Gigs Refund</b>{" "}
                                                <strong>
                                                    <span>${gigs_revenue0.total_refund}</span>
                                                    <span>${gigs_revenue1.total_refund}</span>
                                                    <span>${gigs_revenue2.total_refund}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className="blue">
                                            <p>
                                                <b>Net Profit</b>{" "}
                                                <strong>
                                                    <span>${gigs_revenue0.total_profit}</span>
                                                    <span>${gigs_revenue1.total_profit}</span>
                                                    <span>${gigs_revenue2.total_profit}</span>
                                                </strong>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler("gigs")} aria-controls="totaluser" aria-expanded={isGigs}>
                                Gigs <span className="icon_wrap_collapse"></span>{" "}
                            </button>
                            <Collapse in={isGigs}>
                                <div id="totaluser" className="repo_warp">
                                    <ul className="ul_jobs">
                                        <li className={"green"}>
                                            <p>
                                                <b>Completed</b>
                                                <strong>
                                                    <span>{gigs0.gigs_completed}</span>
                                                    <span>{gigs1.gigs_completed}</span>
                                                    <span>{gigs2.gigs_completed}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className={"blue"}>
                                            <p>
                                                <b>In-Progress</b>
                                                <strong>
                                                    <span>{gigs0.gigs_in_progress}</span>
                                                    <span>{gigs1.gigs_in_progress}</span>
                                                    <span>{gigs2.gigs_in_progress}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className={""}>
                                            <p>
                                                <b>Waiting for agent acceptance</b>
                                                <strong>
                                                    <span>{gigs0.gigs_waiting}</span>
                                                    <span>{gigs1.gigs_waiting}</span>
                                                    <span>{gigs2.gigs_waiting}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className={""}>
                                            <p>
                                                <b>Submit - Waiting for Release</b>
                                                <strong>
                                                    <span>{gigs0.gigs_submit}</span>
                                                    <span>{gigs1.gigs_submit}</span>
                                                    <span>{gigs2.gigs_submit}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className={"red"}>
                                            <p>
                                                <b>Cancelled</b>
                                                <strong>
                                                    <span>{gigs0.gigs_canceled}</span>
                                                    <span>{gigs1.gigs_canceled}</span>
                                                    <span>{gigs2.gigs_canceled}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li className={"red"}>
                                            <p>
                                                <b>Refunded</b>
                                                <strong>
                                                    <span>{gigs0.gigs_refunded}</span>
                                                    <span>{gigs1.gigs_refunded}</span>
                                                    <span>{gigs2.gigs_refunded}</span>
                                                </strong>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>All Gigs</b>
                                                <strong>
                                                    <span>{total_gigs0}</span>
                                                    <span>{total_gigs1}</span>
                                                    <span>{total_gigs2}</span>
                                                </strong>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <FilterModal />
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    FilterD: state.reports.filterD,
    FilterM: state.reports.filterM,
    FilterY: state.reports.filterY,
    typeVal: state.reports.typeVal,
    dayValue: state.reports.dayValue,
    monthValue: state.reports.monthValue,
    yearValue: state.reports.yearValue,
    filterSelect: state.reports.filterSelect,
    isDdate: state.reports.isDdate,
    isMdate: state.reports.isMdate,
    isYdate: state.reports.isYdate,
    d_start_date: state.reports.d_start_date,
    d_end_date: state.reports.d_end_date,
    m_start_date: state.reports.m_start_date,
    m_end_date: state.reports.m_end_date,
    y_start_date: state.reports.y_start_date,
    y_end_date: state.reports.y_end_date,
    isApply: state.reports.isApply,
});

const mapDispatchToProps = (dispatch) => ({
    openFModal: (val) => dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_REPORT_MODAL, payload: val }),
    applyFilterFun: (val) => dispatch({ type: ACTION_TYPES.IS_APPLY_FILTER, payload: val }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GigReports));
