import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { jwtDecode } from '../../helpers/jwt_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import LoaderImg from '../../assets/images/user-loader.gif';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
import './index.css';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            email: '',
            username: '',
            password: '',
            formError: {
                username: '',
                email: '',
                password: ''
            },
            formValid: {
                username: false,
                email: false,
                password: false
            },
            apiError: ''
        }
    }

    inputhendler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }

    checkValidation = (name, value) => {
        const { formError, formValid } = this.state;
        const error = formError;
        const valid = formValid;

        switch (name) {
            case 'username':
                valid.username = value.length > 0;
                error.username = valid.username ? '' : 'Username must Required';
                break;
            case 'email':
                if (value.length > 0) {
                    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        valid.email = true;
                        error.email = ''
                    } else {
                        valid.email = false;
                        error.email = 'Enter valid Email';
                    }
                } else {
                    valid.email = false;
                    error.email = 'Email must required';
                }
                break;
            case 'password':
                valid.password = value.length > 0;
                error.password = valid.password ? '' : 'Password must Required';
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        });
    }

    submitLogin = async (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        try {

            this.setState({
                isLoader: true
            });

            let formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const headers = {
                'Content-Type': 'application/json'
            }

            const res = await apiHalper('login', 'POST', formData, headers);
            // console.log(res);
            if (res.data.status === true) {
                // sessionStorage.setItem('token', res.data.data);
                // sessionStorage.setItem('filePath', res.data.filepaths);
                localStorage.setItem('token', res.data.data);
                localStorage.setItem('filePath', res.data.filepaths);
                this.setState({
                    isLoader: false
                });
                toster_sucess("Login Successfully..");
                // const { history } = this.props;
                // history.push("/allJobs");
                // setTimeout(()=> {this.historyFun()}, 5000);
                // history.push("/");
                // window.location.href="http://adminweb.24task.com/alljobs";
            } else {
                this.setState({
                    isLoader: false,
                    apiError: res.data.message
                });
                toster_error("Authentication Failed");

            }
        } catch (err) {
            console.log(err);
            this.setState({
                isLoader: false,
                apiError: err.message
            })
        }
    }

    historyFun = () => {
        alert('ff')
        const { history } = this.props;
       // history.push("/allJobs");
       history.push("/all-gigs");
    }

    render() {

        const { formError, formValid, username, password, apiError, isLoader } = this.state;
        const disabled = !formValid.username && !formValid.password;

        const user = localStorage.getItem('token') && jwtDecode(localStorage.getItem('token'));
        var profileType = user && user.profile_type_id

        return (
            <section className="body_wrap">
                {user ? (profileType === 6 ? <Redirect to="/reports" /> : <Redirect to="/allJobs" />) : null}
                <div className="bg_login">
                    <div className="container">
                        <div className="login_wrap">
                            <div className="logo_block">
                                <h1>Influence Bird</h1>
                            </div>
                            <div className="form_wrap">
                                <h2>Login</h2>
                                <h3>Access to our dashboard</h3>

                                <form action="#" method="post" onSubmit={this.submitLogin}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control className={formError.username.length > 0 ? "form_control error_border" : "form_control"} name="username" type="text" placeholder="Enter username" value={username} onChange={this.inputhendler} autoComplete="off" />
                                        {formError.username.length > 0 ? <Form.Text className="error_text">{formError.username}</Form.Text> : null}
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control className={formError.password.length > 0 ? "form_control error_border" : "form_control"} name="password" type="password" placeholder="Enter password" value={password} onChange={this.inputhendler} />
                                        {formError.password.length > 0 ? <Form.Text className="error_text">{formError.password}</Form.Text> : null}
                                    </Form.Group>
                                    {formError.apiError !== '' ? <Form.Text className="error_text">{apiError}</Form.Text> : null}
                                    <Form.Group>
                                        <button type="submit" className="btn-login" disabled={disabled || isLoader}>{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Login"}</button>
                                    </Form.Group>
                                </form>
                                {/* <ToastContainer /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(props => <Login {...props} />);
