import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollLoader from '../ScrollLoader';
// import LoaderImg from '../../assets/images/user-loader.gif';
import LoaderComponent from '../LoaderComponent';

class ListViews extends Component {
    
    renderList = (name, data, i, inputHandler) =>{
        // console.log(data);
        return <Col xs={12} sm={6} md={4} lg={3} key={i} className="for-addDiv">
            <input type="checkbox" name={name + i} id={name + i} value={data.id} onChange={inputHandler}/>
            <label htmlFor={name + i} className="customChek">
                <div className="content_user">
                    <div className="img_bl">
                        <img src={data.img} alt={"profile"} />
                    </div>
                    <div className="content_user2">
                        <h6>{data.first_name + ' ' + data.last_name}</h6>
                        <p>{data.email}</p>
                    </div>
                </div>
                <div className="time_footer">
                        <p>{"--"}</p>
                    </div>
            </label>
        </Col>
    }

    render() {
        // isUnderR, ApproveApi, RejectApi, isDisabled, isRDisabled, isASLoader, isRSLoader,
        const { isLoader, name, data, inputHandler, nextData, isSLoader, messageApi } = this.props.propsData;
        return (
            !isLoader?
                <div className="tab_container">
                    <div className="profile_list" id="scrollableDivProfile">
                        <Row>
                            
                                {data.length > 0?
                                    <InfiniteScroll className="width-forscrool" dataLength={data.length} next={nextData} hasMore={isSLoader} loader={<ScrollLoader />} scrollableTarget="scrollableDivProfile">
                                        {data.map((info, i)=>this.renderList(name, info, i, inputHandler))}
                                    </InfiniteScroll>
                                :
                                    <Col>
                                        <div className="noRecord">
                                            <p>{messageApi}</p>
                                        </div>
                                    </Col>
                                }
                        </Row>
                    </div>
                    
                    {/* <div className="group_btns">
                        {isUnderR?
                            <button type="button" className="btn_same btn_apply" onClick={ApproveApi} disabled={isDisabled || isASLoader}>{isASLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Approve"}</button>
                            :
                            null
                        }
                            
                        <button type="button" className="btn_same btn_reject" onClick={RejectApi} disabled={isRDisabled || isRSLoader}>{isRSLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Reject"}</button>      
                    </div> */}
                </div>
            :
                <LoaderComponent />
        )
    }
}

export default withRouter(connect(null)(ListViews))
