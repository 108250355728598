import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as ACTION_TYPES from '../../redux/actions/types';

class AddAcountModal extends Component{

    constructor(props){
        super(props);
        this.state={
            email:'',
            error:{
                email:''
            },
            valid:{
                email:false
            }
        }
    }

    inputHandler = (e) =>{
        const { value } = e.target;
        if(value.length > 0){
            if(value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
                this.setState({
                    email:value,
                    error:{
                        email:''
                    },
                    valid:{
                        email:true
                    }
                })
            }else{
                this.setState({
                    email:value,
                    error:{
                        email:'Please enter a valid email address'
                    },
                    valid:{
                        email:false
                    }
                })
            }
        }else{
            this.setState({
                email:value,
                error:{
                    email:'Email Must Required!'
                },
                valid:{
                    email:false
                }
            })
        }

    }

    addAcount = (e) =>{
        e.preventDefault();
        console.log('working');
    }

    render(){

        const { accountModal, handleClose } = this.props;

        const { error, valid } = this.state;

        const disabled = !valid.email;

        return(
            <Modal show={accountModal} onHide={handleClose} className="modal_design" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Add Paypal <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#" onSubmit={this.addAcount}>
                                <div className="field_bg">
                                    <div className="form_group2">
                                        <label htmlFor="accountEmail">Enter Your Paypal Account Email</label>
                                        <input type="text" name="email" id="accountEmail" placeholder="name@example.com" onChange={this.inputHandler} autoComplete={"off"}/>
                                    </div>
                                </div>
                                {error.email !== ''?<p className="error_text">{error.email}</p>:null}
                                <div className="bg_submit">
                                    <p>We’ll send an varification Email</p>
                                    <button type="submit" className="btn-submit" disabled={disabled}>Add Paypal Account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state =>({
    accountModal:state.balance.accountModal
});

const mapDispatchToProps = dispatch=>({
    handleClose: ()=>dispatch({type:ACTION_TYPES.TOGGLE_ADD_ACCOUNT_MODAL})
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAcountModal));