
import { apiHalper } from '../../helpers/ApiHelper';
//import { jwtDecode } from '../../helpers/jwt_helper';

export const getCoupList = async (page, search) => {

    const headers = {
        'Authorization': localStorage.getItem('token'),
        //'Access-Control-Allow-Origin': '*'
    }
    let formData = new FormData();
    formData.append('limit', 10);
    formData.append('page_no', page);
    formData.append('search_by', search);
    const res = await apiHalper('getCouponList', 'POST', formData, headers);
    //console.log(res.data);
    return res;
}