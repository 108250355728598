import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse'
import * as ACTION_TYPES from '../../redux/actions/types';
import FilterModal from './FilterModal';
import { apiHalper } from '../../helpers/ApiHelper';
import * as NumHelper from '../../helpers/NumberFormate';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';
// import FilterIcon from '../../assets/images/filter_icon.svg'; 
import './index.css';

class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRevenue: true,
            isUsers: false,
            isNumJobs: false,
            isIncomeJobs: false,
            revenue: {
                incomeD: 0,
                incomeM: 0,
                incomeY: 0,
                incomeD2: 0,
                incomeM2: 0,
                incomeY2: 0,
                balanceD: 0,
                balanceM: 0,
                balanceY: 0,
                costD: 0,
                costM: 0,
                costY: 0,
                refundsD: 0,
                refundsM: 0,
                refundsY: 0,
                profitD: 0,
                profitM: 0,
                profitY: 0
            },
            totalUser: {
                allUsersD: 0,
                allUsersM: 0,
                allUsersY: 0,
                allClientD: 0,
                allClientM: 0,
                allClientY: 0,
                allAgentD: 0,
                allAgentM: 0,
                allAgentY: 0,
                allBuyersD: 0,
                allBuyersM: 0,
                allBuyersY: 0
            },
            allJobsNumF: 0,
            allJobsNumS: 0,
            allJobsNumT: 0,
            completedNumF: 0,
            completedNumS: 0,
            completedNumT: 0,
            submitNumF: 0,
            submitNumS: 0,
            submitNumT: 0,
            progressNumF: 0,
            progressNumS: 0,
            progressNumT: 0,
            wDepositNumF: 0,
            wDepositNumS: 0,
            wDepositNumT: 0,
            bidNumF: 0,
            bidNumS: 0,
            bidNumT: 0,
            canceledNumF: 0,
            canceledNumS: 0,
            canceledNumT: 0,
            refundNumF: 0,
            refundNumS: 0,
            refundNumT: 0,
            noBidNumF: 0,
            noBidNumS: 0,
            noBidNumT: 0,
            allJobsIncF: 0,
            allJobsIncS: 0,
            allJobsIncT: 0,
            completedIncF: 0,
            completedIncS: 0,
            completedIncT: 0,
            progressIncF: 0,
            progressIncS: 0,
            progressIncT: 0,
            wDepositIncF: 0,
            wDepositIncS: 0,
            wDepositIncT: 0,
            refundIncF: 0,
            refundIncS: 0,
            refundIncT: 0
            // numJobs:{
            //     allJobsD:0,
            //     allJobsM:0,
            //     allJobsY:0,
            //     completedD:0,
            //     completedM:0,
            //     completedY:0,
            //     submitD:0,
            //     submitM:0,
            //     submitY:0,
            //     progressD:0,
            //     progressM:0,
            //     progressY:0,
            //     wDepositD:0,
            //     wDepositM:0,
            //     wDepositY:0,
            //     bidD:0,
            //     bidM:0,
            //     bidY:0,
            //     canceledD:0,
            //     canceledM:0,
            //     canceledY:0,
            //     refundD:0,
            //     refundM:0,
            //     refundY:0,
            //     noBidD:0,
            //     noBidM:0,
            //     noBidY:0
            // },
            // incomeJobs:{
            // allJobsD:0,
            // allJobsM:0,
            // allJobsY:0,
            // completedD:0,
            // completedM:0,
            // completedY:0,
            // submitD:0,
            // submitM:0,
            // submitY:0,
            // progressD:0,
            // progressM:0,
            // progressY:0,
            // wDepositD:0,
            // wDepositM:0,
            // wDepositY:0,
            // bidD:0,
            // bidM:0,
            // bidY:0,
            // canceledD:0,
            // canceledM:0,
            // canceledY:0,
            // refundD:0,
            // refundM:0,
            // refundY:0
            // noBidD:0,
            // noBidM:0,
            // noBidY:0
            // }
        }
    }

    componentDidMount() {
        this.firstRowApi('day');
        this.secondRowApi('month');
        this.thirdRowApi('year');
    }

    numberFormate = (val) => {
        return NumHelper.numberWithCommas(val);
    }

    componentDidUpdate(prevProps) {
        const { filterSelect, typeVal, isApply, applyFilterFun } = this.props;
        if (typeVal !== prevProps.typeVal) {
            if (isApply !== prevProps.isApply) {
                switch (filterSelect) {
                    case 0:
                        this.firstRowApi(typeVal);
                        break;
                    case 1:
                        this.secondRowApi(typeVal);
                        break;
                    case 2:
                        this.thirdRowApi(typeVal);
                        break;
                    default:
                        break;
                }
                applyFilterFun(false);
            }
        }
    }

    firstRowApi = async (type) => {
        const { dayValue, isDdate, d_start_date, d_end_date } = this.props;
        if (!isDdate) {
            let formData = new FormData();
            formData.append('type', type);
            formData.append('value', dayValue);

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReports', 'POST', formData, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateFirstRow(data);
            }
        } else {
            let formData2 = new FormData();
            formData2.append('start_date', moment(d_start_date).format('YYYY-MM-DD'));
            formData2.append('end_date', moment(d_end_date).format('YYYY-MM-DD'));

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReportByDateRange', 'POST', formData2, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateFirstRow(data);
            }
        }
    }

    updateFirstRow = (data) => {
        const { general, users, jobs, incomes } = data;
        const { revenue, totalUser } = this.state;
        this.setState({
            revenue: {
                incomeD: general.total_income_1,
                costD: general.total_cost,
                profitD: general.total_profit,
                incomeM: revenue.incomeM,
                costM: revenue.costM,
                profitM: revenue.profitM,
                incomeY: revenue.incomeY,
                costY: revenue.costY,
                profitY: revenue.profitY,
                refundsD: general.total_refund,
                refundsM: revenue.refundsM,
                refundsY: revenue.refundsY,
                incomeD2: general.total_income_2,
                incomeM2: revenue.incomeM2,
                incomeY2: revenue.incomeY2,
                balanceD: general.total_balance,
                balanceM: revenue.balanceM,
                balanceY: revenue.balanceY
            },
            totalUser: {
                allUsersD: users.total_users,
                allClientD: users.client_users,
                allAgentD: users.agent_users,
                allUsersM: totalUser.allUsersM,
                allClientM: totalUser.allClientM,
                allAgentM: totalUser.allAgentM,
                allUsersY: totalUser.allUsersY,
                allClientY: totalUser.allClientY,
                allAgentY: totalUser.allAgentY,
                allBuyersD: users.buyer_users,
                allBuyersM: totalUser.allBuyersM,
                allBuyersY: totalUser.allBuyersY
            }
        });
        for (let i = 0; i < jobs.length; i++) {

            if (jobs[i].state === 'Waiting for deposit') {
                this.setState({
                    wDepositNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'In-Progress') {
                this.setState({
                    progressNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'Bidding') {
                this.setState({
                    bidNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'Completed') {

                this.setState({
                    completedNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'Cancelled') {
                this.setState({
                    canceledNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    submitNumF: jobs[i].count
                });
            }

            if (jobs[i].state === 'Refunded') {
                this.setState({
                    refundNumF: jobs[i].count
                });
            }
            if (jobs[i].state === 'No bid') {
                this.setState({
                    noBidNumF: jobs[i].count
                });
            }
            if (jobs[i].state === 'All Jobs') {
                this.setState({
                    allJobsNumF: jobs[i].count
                });
            }
        }
        for (let i = 0; i < incomes.length; i++) {

            if (incomes[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    wDepositIncF: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'In-Progress') {
                this.setState({
                    progressIncF: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Completed') {
                this.setState({
                    completedIncF: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Refunded') {
                this.setState({
                    refundIncF: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'All Jobs') {
                this.setState({
                    allJobsIncF: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                });
            }
        }
    }

    secondRowApi = async (type) => {
        const { monthValue, isMdate, m_start_date, m_end_date } = this.props;
        if (!isMdate) {
            let formData = new FormData();
            formData.append('type', type);
            formData.append('value', monthValue)

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReports', 'POST', formData, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateSecondRow(data);
            }
        } else {
            let formData2 = new FormData();
            formData2.append('start_date', moment(m_start_date).format('YYYY-MM-DD'));
            formData2.append('end_date', moment(m_end_date).format('YYYY-MM-DD'));

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReportByDateRange', 'POST', formData2, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateSecondRow(data);
            }
        }
    }

    updateSecondRow = (data) => {
        const { general, users, jobs, incomes } = data;
        const { revenue, totalUser } = this.state;
        this.setState({
            revenue: {
                incomeM: general.total_income_1,
                costM: general.total_cost,
                profitM: general.total_profit,
                incomeD: revenue.incomeD,
                costD: revenue.costD,
                profitD: revenue.profitD,
                incomeY: revenue.incomeY,
                costY: revenue.costY,
                profitY: revenue.profitY,
                refundsD: revenue.refundsD,
                refundsM: general.total_refund,
                refundsY: revenue.refundsY,
                incomeD2: revenue.incomeD2,
                incomeM2: general.total_income_2,
                incomeY2: revenue.incomeY2,
                balanceD: revenue.balanceD,
                balanceM: general.total_balance,
                balanceY: revenue.balanceY
            },
            totalUser: {
                allUsersM: users.total_users,
                allClientM: users.client_users,
                allAgentM: users.agent_users,
                allUsersD: totalUser.allUsersD,
                allClientD: totalUser.allClientD,
                allAgentD: totalUser.allAgentD,
                allUsersY: totalUser.allUsersY,
                allClientY: totalUser.allClientY,
                allAgentY: totalUser.allAgentY,
                allBuyersD: totalUser.allBuyersD,
                allBuyersM: users.buyer_users,
                allBuyersY: totalUser.allBuyersY
            }
        });
        for (let i = 0; i < jobs.length; i++) {

            if (jobs[i].state === 'Waiting for deposit') {
                this.setState({
                    wDepositNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'In-Progress') {
                this.setState({
                    progressNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'Bidding') {
                this.setState({
                    bidNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'Completed') {

                this.setState({
                    completedNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'Cancelled') {
                this.setState({
                    canceledNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    submitNumS: jobs[i].count
                });
            }

            if (jobs[i].state === 'Refunded') {
                this.setState({
                    refundNumS: jobs[i].count
                });
            }
            if (jobs[i].state === 'No bid') {
                this.setState({
                    noBidNumS: jobs[i].count
                });
            }
            if (jobs[i].state === 'All Jobs') {
                this.setState({
                    allJobsNumS: jobs[i].count
                });
            }
        }
        for (let i = 0; i < incomes.length; i++) {

            if (incomes[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    wDepositIncS: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'In-Progress') {
                this.setState({
                    progressIncS: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Completed') {
                this.setState({
                    completedIncS: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Refunded') {
                this.setState({
                    refundIncS: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'All Jobs') {
                this.setState({
                    allJobsIncS: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                });
            }
        }
    }

    thirdRowApi = async (type) => {
        const { yearValue, isYdate, y_start_date, y_end_date } = this.props;
        if (!isYdate) {
            let formData = new FormData();
            formData.append('type', type);
            formData.append('value', yearValue)

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReports', 'POST', formData, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateThirdRow(data);
            }
        } else {
            let formData2 = new FormData();
            formData2.append('start_date', moment(y_start_date).format('YYYY-MM-DD'));
            formData2.append('end_date', moment(y_end_date).format('YYYY-MM-DD'));

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const res = await apiHalper('getReportByDateRange', 'POST', formData2, headers);
            if (res.data.status === true) {
                const data = jwtDecode(res.data.data);
                this.updateThirdRow(data);
            }
        }
    }

    updateThirdRow = (data) => {
        const { revenue, totalUser } = this.state;
        const { general, users, jobs, incomes } = data;
        this.setState({
            revenue: {
                incomeY: general.total_income_1,
                costY: general.total_cost,
                profitY: general.total_profit,
                incomeD: revenue.incomeD,
                costD: revenue.costD,
                profitD: revenue.profitD,
                incomeM: revenue.incomeM,
                costM: revenue.costM,
                profitM: revenue.profitM,
                refundsD: revenue.refundsD,
                refundsM: revenue.refundsM,
                refundsY: general.total_refund,
                incomeD2: revenue.incomeD2,
                incomeM2: revenue.incomeM2,
                incomeY2: general.total_income_2,
                balanceD: revenue.balanceD,
                balanceM: revenue.balanceM,
                balanceY: general.total_balance
            },
            totalUser: {
                allUsersY: users.total_users,
                allClientY: users.client_users,
                allAgentY: users.agent_users,
                allUsersD: totalUser.allUsersD,
                allClientD: totalUser.allClientD,
                allAgentD: totalUser.allAgentD,
                allUsersM: totalUser.allUsersM,
                allClientM: totalUser.allClientM,
                allAgentM: totalUser.allAgentM,
                allBuyersD: totalUser.allBuyersD,
                allBuyersM: totalUser.allBuyersM,
                allBuyersY: users.buyer_users
            }
        });
        for (let i = 0; i < jobs.length; i++) {

            if (jobs[i].state === 'Waiting for deposit') {
                this.setState({
                    wDepositNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'In-Progress') {
                this.setState({
                    progressNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'Bidding') {
                this.setState({
                    bidNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'Completed') {

                this.setState({
                    completedNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'Cancelled') {
                this.setState({
                    canceledNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    submitNumT: jobs[i].count
                });
            }

            if (jobs[i].state === 'Refunded') {
                this.setState({
                    refundNumT: jobs[i].count
                });
            }
            if (jobs[i].state === 'No bid') {
                this.setState({
                    noBidNumT: jobs[i].count
                });
            }
            if (jobs[i].state === 'All Jobs') {
                this.setState({
                    allJobsNumT: jobs[i].count
                });
            }
        }
        for (let i = 0; i < incomes.length; i++) {

            if (incomes[i].state === 'Submit - Waiting for Release') {
                this.setState({
                    wDepositIncT: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'In-Progress') {
                this.setState({
                    progressIncT: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Completed') {
                this.setState({
                    completedIncT: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'Refunded') {
                this.setState({
                    refundIncT: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                })
            }
            if (incomes[i].state === 'All Jobs') {
                this.setState({
                    allJobsIncT: incomes[i].sum_incomes !== null ? incomes[i].sum_incomes : 0
                });
            }
        }
    }

    collapseHandler = (name) => {
        switch (name) {
            case 'revenue':
                this.setState({
                    isRevenue: !this.state.isRevenue
                });
                break;
            case 'users':
                this.setState({
                    isUsers: !this.state.isUsers
                });
                break;
            case 'numJobs':
                this.setState({
                    isNumJobs: !this.state.isNumJobs
                });
                break;
            case 'incomeJobs':
                this.setState({
                    isIncomeJobs: !this.state.isIncomeJobs
                });
                break;
            default:
                this.setState({
                    isRevenue: true,
                    isUsers: false,
                    isNumJobs: false,
                    isIncomeJobs: false
                });
                break;
        }
    }

    openFilterModal = (val) => {
        const { openFModal } = this.props;
        openFModal(val);
    }

    render() {

        const { isRevenue, isUsers, isNumJobs, isIncomeJobs, revenue, totalUser } = this.state;
        const { isDdate, isMdate, isYdate, d_start_date, d_end_date, m_start_date, m_end_date, y_start_date, y_end_date, FilterD, FilterM, FilterY } = this.props;
        return (
            <section className="bg-balance">
                <div className="balance_wrap">
                    <div className="accroding_wrap">
                        <div className="filter_wrap">
                            <p>Date</p>
                            <div className="filter_button_wrap">
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(0)}>
                                    {
                                        isDdate ?
                                            <span className="date_font">{moment(d_start_date).format('MMM DD, YYYY') + ' - ' + moment(d_end_date).format('MMM DD, YYYY')}</span>
                                            :
                                            FilterD
                                    }
                                </button>
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(1)}>
                                    {
                                        isMdate ?
                                            <span className="date_font">{moment(m_start_date).format('MMM DD, YYYY') + ' - ' + moment(m_end_date).format('MMM DD, YYYY')}</span>
                                            :
                                            FilterM
                                    }
                                </button>
                                <button type="button" className="btn_filter" onClick={() => this.openFilterModal(2)}>
                                    {
                                        isYdate ?
                                            <span className="date_font">{moment(y_start_date).format('MMM DD, YYYY') + ' - ' + moment(y_end_date).format('MMM DD, YYYY')}</span>
                                            :
                                            FilterY
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler('revenue')} aria-controls="revenue" aria-expanded={isRevenue} >Revenue <span className="icon_wrap_collapse"></span> </button>
                            <Collapse in={isRevenue}>
                                <div id="revenue" className="repo_warp">
                                    <ul className="ul_jobs">
                                        <li>
                                            <p><b>Clients Income</b> <strong><span>${revenue.incomeD}</span> <span>${revenue.incomeM}</span> <span>${revenue.incomeY}</span></strong></p>
                                        </li>
                                        <li>
                                            <p><b>Jobs Income</b> <strong><span>${revenue.incomeD2}</span> <span>${revenue.incomeM2}</span> <span>${revenue.incomeY2}</span></strong></p>
                                        </li>
                                        {/* <li>
                                            <p><b>Client Balance</b> <strong><span>${revenue.balanceD}</span> <span>${revenue.balanceM}</span> <span>${revenue.balanceY}</span></strong></p>
                                        </li> */}
                                        <li className="green">
                                            <p><b>Job Cost</b> <strong><span>${revenue.costD}</span> <span>${revenue.costM}</span> <span>${revenue.costY}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>Job Refunds</b> <strong><span>${revenue.refundsD}</span> <span>${revenue.refundsM}</span> <span>${revenue.refundsY}</span></strong></p>
                                        </li>
                                        <li className="blue">
                                            <p><b>Net Profit</b> <strong><span>${revenue.profitD}</span> <span>${revenue.profitM}</span> <span>${revenue.profitY}</span></strong></p>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler('users')} aria-controls="totaluser" aria-expanded={isUsers} >Total Users <span className="icon_wrap_collapse"></span> </button>
                            <Collapse in={isUsers}>
                                <div id="totaluser" className="repo_warp">
                                    <ul className="ul_jobs">
                                        <li>
                                            <p><b>All Users</b> <strong><span>{totalUser.allUsersD}</span> <span>{totalUser.allUsersM}</span> <span>{totalUser.allUsersY}</span> </strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>All Client</b> <strong><span>{totalUser.allClientD}</span> <span>{totalUser.allClientM}</span> <span>{totalUser.allClientY}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>All Buyers</b> <strong><span>{totalUser.allBuyersD}</span> <span>{totalUser.allBuyersM}</span> <span>{totalUser.allBuyersY}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>All Agent</b> <strong><span>{totalUser.allAgentD}</span> <span>{totalUser.allAgentM}</span> <span>{totalUser.allAgentY}</span></strong></p>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler('numJobs')} aria-controls="numJobs" aria-expanded={isNumJobs} >Total number of jobs <span className="icon_wrap_collapse"></span> </button>
                            <Collapse in={isNumJobs}>
                                <div id="numJobs" className="repo_warp">
                                    <ul className="ul_jobs ul_jobs2">
                                        <li>
                                            <p><b>All Jobs</b> <strong><span>{this.state.allJobsNumF}</span> <span>{this.state.allJobsNumS}</span> <span>{this.state.allJobsNumT}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>Completed</b> <strong><span>{this.state.completedNumF}</span> <span>{this.state.completedNumS}</span> <span>{this.state.completedNumT}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>Submit</b> <strong><span>{this.state.submitNumF}</span> <span>{this.state.submitNumS}</span> <span>{this.state.submitNumT}</span></strong></p>
                                        </li>
                                        <li className="blue">
                                            <p><b>Progress</b> <strong><span>{this.state.progressNumF}</span> <span>{this.state.progressNumS}</span> <span>{this.state.progressNumT}</span></strong></p>
                                        </li>
                                        <li className="coffee">
                                            <p><b>W Deposit</b> <strong><span>{this.state.wDepositNumF}</span> <span>{this.state.wDepositNumS}</span> <span>{this.state.wDepositNumT}</span></strong></p>
                                        </li>
                                        <li className="orange">
                                            <p><b>Bid</b> <strong><span>{this.state.bidNumF}</span> <span>{this.state.bidNumS}</span> <span>{this.state.bidNumT}</span></strong></p>
                                        </li>
                                        <li className="red">
                                            <p><b>Canceled</b> <strong><span>{this.state.canceledNumF}</span> <span>{this.state.canceledNumS}</span> <span>{this.state.canceledNumT}</span></strong></p>
                                        </li>
                                        <li className="red">
                                            <p><b>Refund</b> <strong><span>{this.state.refundNumF}</span> <span>{this.state.refundNumS}</span> <span>{this.state.refundNumT}</span></strong></p>
                                        </li>
                                        <li className="red">
                                            <p><b>No-Bid</b> <strong><span>{this.state.noBidNumF}</span> <span>{this.state.noBidNumS}</span> <span>{this.state.noBidNumT}</span></strong></p>
                                        </li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="collapse_list">
                            <button type="button" onClick={() => this.collapseHandler('incomeJobs')} aria-controls="incomeJobs" aria-expanded={isIncomeJobs} >Total income of jobs <span className="icon_wrap_collapse"></span> </button>
                            <Collapse in={isIncomeJobs}>
                                <div id="incomeJobs" className="repo_warp">
                                    <ul className="ul_jobs ul_jobs2">
                                        <li>
                                            <p><b>All Jobs</b> <strong><span>${this.state.allJobsIncF}</span> <span>${this.state.allJobsIncS}</span> <span>${this.state.allJobsIncT}</span></strong></p>
                                        </li>
                                        <li className="green">
                                            <p><b>Completed</b> <strong><span>${this.state.completedIncF}</span> <span>${this.state.completedIncS}</span> <span>${this.state.completedIncT}</span></strong></p>
                                        </li>
                                        {/* <li className="green">
                                            <p><b>Submit</b> <strong><span>${incomeJobs.submitD}</span> <span>${incomeJobs.submitM}</span> <span>${incomeJobs.submitY}</span></strong></p>
                                        </li> */}
                                        <li className="blue">
                                            <p><b>Progress</b> <strong><span>${this.state.progressIncF}</span> <span>${this.state.progressIncS}</span> <span>${this.state.progressIncT}</span></strong></p>
                                        </li>
                                        <li className="coffee">
                                            <p><b>W Deposit</b> <strong><span>${this.state.wDepositIncF}</span> <span>${this.state.wDepositIncS}</span> <span>${this.state.wDepositIncT}</span></strong></p>
                                        </li>
                                        {/* <li className="orange">
                                            <p><b>Bid</b> <strong><span>${incomeJobs.bidD}</span> <span>${incomeJobs.bidM}</span> <span>${incomeJobs.bidY}</span></strong></p>
                                        </li> */}
                                        {/* <li className="red">
                                            <p><b>Canceled</b> <strong><span>${incomeJobs.canceledD}</span> <span>${incomeJobs.canceledM}</span> <span>${incomeJobs.canceledY}</span></strong></p>
                                        </li> */}
                                        <li className="red">
                                            <p><b>Refund</b> <strong><span>${this.state.refundIncF}</span> <span>${this.state.refundIncS}</span> <span>${this.state.refundIncT}</span></strong></p>
                                        </li>
                                        {/* <li className="red">
                                            <p><b>No-Bid</b> <strong><span>${incomeJobs.noBidD}</span> <span>${incomeJobs.noBidM}</span> <span>${incomeJobs.noBidY}</span></strong></p>
                                        </li> */}
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <FilterModal />
            </section>
        )
    }
}

const mapStateToProps = state => ({
    FilterD: state.reports.filterD,
    FilterM: state.reports.filterM,
    FilterY: state.reports.filterY,
    typeVal: state.reports.typeVal,
    dayValue: state.reports.dayValue,
    monthValue: state.reports.monthValue,
    yearValue: state.reports.yearValue,
    filterSelect: state.reports.filterSelect,
    isDdate: state.reports.isDdate,
    isMdate: state.reports.isMdate,
    isYdate: state.reports.isYdate,
    d_start_date: state.reports.d_start_date,
    d_end_date: state.reports.d_end_date,
    m_start_date: state.reports.m_start_date,
    m_end_date: state.reports.m_end_date,
    y_start_date: state.reports.y_start_date,
    y_end_date: state.reports.y_end_date,
    isApply: state.reports.isApply
})

const mapDispatchToProps = (dispatch) => ({
    openFModal: (val) => dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_REPORT_MODAL, payload: val }),
    applyFilterFun: (val) => dispatch({ type: ACTION_TYPES.IS_APPLY_FILTER, payload: val })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
