import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import './index.css';

const customTotal = (from, to, size) => {
    return <span className="custom_record react-bootstrap-table-pagination-total">
        {from} to {to} of Total {size}
    </span>
}

const MySearch = (props) => {
    // console.log(props);
    let input;
    const handleClick = (e) => {
        e.preventDefault();
        props.onSearch(input.value);
    };
    return (
        <form method="POST" action="#" onSubmit={handleClick}>
            <div className="search_bloc float_right">
                <input className="form-Scontrol" ref={n => input = n} type="text" placeholder="Search" id="tableSearch" autoComplete="off" />
                <button type="submit" className="btn-search">Search</button>
            </div>
        </form>
    );
};

class SCDataTable extends Component {

    render() {

        const { keyId, columns, data, page, sizePerPage, handleTableChange, totalRecord, loading, message, noDataIndication, rowClass, dataLimit, isCsv, isHideToggle, isSelectRow, handleOnSelect, handleOnSelectAll, selectedRow } = this.props.TableData;
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: isHideToggle > 0,
            selected: selectedRow,
            onSelect: handleOnSelect,
            onSelectAll: handleOnSelectAll,
            selectionHeaderRenderer: ({ mode, ...rest }) => (
                <label className="custom-checkbox table-header-checkbox"><input type={mode} {...rest} readOnly /><span class="checkmark"></span>All </label>
            ),
            selectionRenderer: ({ mode, ...rest }) => (
                <label className={isHideToggle === 1 ? "custom-checkbox disabled" : "custom-checkbox"}><input type={mode} {...rest} readOnly disabled={isHideToggle === 1} /><span class="checkmark"></span> </label>
            )
        };
        const option = {
            //custom: true,
            page,
            sizePerPage,
            totalSize: totalRecord,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: dataLimit ? dataLimit : []
        }

        // const { SearchBar } = Search;
        const MyExportCSV = (props) => {
            const handleClick = () => {
                props.onExport();
            };
            return (
                <div>
                    <button className="btn btn-primary export-csv" onClick={handleClick}>Export to CSV</button>
                </div>
            );
        };
        return (
            <div className="table_wrap">
            
                <PaginationProvider pagination={paginationFactory(option)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <ToolkitProvider keyField={keyId} data={data} columns={columns} search totalSize={totalRecord} exportCSV >
                                {
                                    props => (
                                        <div>
                                            {/* <SearchBar {...props.searchProps} className="search-table" placeholder="Search" /> */}
                                            {isCsv && <MyExportCSV {...props.csvProps} />}
                                            <MySearch {...props.searchProps} />
                                            {/* <hr /> */}
                                            {isSelectRow ? <BootstrapTable remote striped hover {...props.baseProps} rowClasses={rowClass} noDataIndication={!loading ? message : noDataIndication} classes="table_design" {...paginationTableProps} onTableChange={handleTableChange} selectRow={selectRow} /> : <BootstrapTable remote striped hover {...props.baseProps} rowClasses={rowClass} noDataIndication={!loading ? message : noDataIndication} classes="table_design" {...paginationTableProps} onTableChange={handleTableChange} />}
                                            {/* <SizePerPageDropdownStandalone {...paginationProps} />
                                            <PaginationListStandalone {...paginationProps} /> */}
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        )
                    }
                </PaginationProvider>
            </div>
        )
    }
}

export default withRouter(connect(null)(SCDataTable));