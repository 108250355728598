
import * as ACTIVE_TYPES from '../actions/types';

const initialState = {
    jobDetail:null
}

const jobViewDetailReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTIVE_TYPES.JOB_VIEW_DETAIL:
            return{
                ...state,
                jobDetail: action.payload
            }
        default:
            return state;
    }
}

export default jobViewDetailReducers;