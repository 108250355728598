import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import ReactStars from "react-rating-stars-component";
import LoaderImg from '../../assets/images/user-loader.gif';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

class AddMoreModal extends Component {

    constructor(props){
        super(props);
        this.state={
            singleData:null,
            img:null,
            u_fname:'',
            u_lname:'',
        }
    }

    componentDidUpdate(prevProps){
        const { singleData, addMModal } = this.props.modalData;

        if(addMModal !== prevProps.modalData.addMModal){
            // if(singleData !== prevProps.singleData){
                const { img, first_name, last_name } = singleData;
                this.setState({
                    singleData,
                    img:img,
                    u_fname:first_name,
                    u_lname:last_name
                })
            // }
        }
    }

    errorImg = (e) =>{
        e.target.src = DummyProfile
    }

    render() {

        const { addMModal, handleClose, title, description, clarity, formError, formValid, ratingValid, messageAApi, issLoader, startDate, inputHandler, handleStartdate, addMReview, ratinghandler, noReviews, reviewType, reviewTypeHandler } = this.props.modalData;
        // console.log(singleData);
        const { img, u_lname, u_fname, singleData } = this.state;

        const secondExample = {
            classNames:'custom_rating',
            a11y: false,
            isHalf: true,
            emptyIcon: <i className="star_empty" />,
            halfIcon: <i className="star_half" />,
            filledIcon: <i className="star_full" />,
        };

        const disabled = reviewType === 0?!formValid.title || !formValid.description || !ratingValid : !formValid.noReviews || !ratingValid;

        return (
            <Modal show={addMModal} onHide={handleClose} className="modal_design" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Add More Reviews <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <div className="user_viewB">
                                <div className="img_vB">
                                    <img src={img !== null?img:DummyProfile} onError={this.errorImg} alt={'Users'} />
                                </div>
                                <p className="user_nae">{`${u_fname} ${u_lname}`} <br /><span>Profile ID: {singleData !== null?singleData.id:'--'}</span></p>
                            </div>
                            <form method="POST" action="#" onSubmit={addMReview}>
                                <div className="form_group3">
                                    <label>Review Type</label>
                                    <div className="butn_g">
                                        <button type="button" className={reviewType === 0?"radio_button active":"radio_button"} onClick={()=>reviewTypeHandler(0)}><i></i><span>Single Review</span></button>
                                        <button type="button" className={reviewType === 1?"radio_button active":"radio_button"} onClick={()=>reviewTypeHandler(1)}><i></i><span>Multiple Reviews</span></button>
                                    </div>
                                </div>
                                {reviewType === 0?
                                    <>
                                        <div className="form_group3">
                                            <label>Title</label>
                                            <input type="text" name="title" className={formError.title !== ''?"form_control2 error_border":"form_control2"} value={title} onChange={inputHandler} placeholder="Enter title" autoComplete="off"/>
                                            {formError.title !== ''?<p className="error_text">{formError.title}</p>:null}
                                        </div>
                                        <div className="form_group3">
                                            <label>Description</label>
                                            <textarea name="description" className={formError.description !== ''?"form_control2 textarea error_border":"form_control2 textarea"} placeholder={'Enter description'} value={description} onChange={inputHandler}></textarea>
                                            {formError.description !== ''?<p className="error_text">{formError.description}</p>:null}
                                        </div>
                                    </>
                                    :
                                    <div className="form_group3">
                                        <label>No of Reviews</label>
                                        <input type="text" name="noReviews" className={formError.noReviews !== ''?"form_control2 error_border":"form_control2"} value={noReviews} onChange={inputHandler} placeholder="0" autoComplete="off"/>
                                        {formError.noReviews !== ''?<p className="error_text">{formError.noReviews}</p>:null}
                                    </div>
                                }   
                                <div className="form_group3 flex mar_t20">
                                    <label>Review Date</label>
                                    <DateRangePicker initialSettings={{singleDatePicker:true, locale:{format:'DD MMM, YYYY'}}} startDate={moment(startDate).format('DD MMM, YYYY')}  onApply={handleStartdate} ><input type="button"  className="dateR_btn"/></DateRangePicker>
                                </div>
                                <div className="form_group3 flex mar_t20">
                                    <label>Rating</label>
                                    <ReactStars {...secondExample} value={clarity} onChange={ratinghandler} />
                                </div>
                                {messageAApi !== ''?<p className="error_text">{messageAApi}</p>:null}
                                <div className="bg_submit2 mr-top">
                                    {/* <p>We’ll send an varification Email</p> */}
                                    <button type="submit" className="btn-submit" disabled={disabled || issLoader}>{issLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Add Review"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(AddMoreModal))