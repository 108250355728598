import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import { toster_sucess } from '../../helpers/toster_helper';

class WalletList extends Component {
  _ismounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      aBalance: null,
      pBalance: null,
      tBalance: null,
      totalRecord: 0,
      messageApi: '',
      isEditing: false, 
      editedBalance: null, 
    };
  }

  componentDidMount() {
    this._ismounted = true;
    this.getWallatapi();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getWallatapi = async () => {
    if (this._ismounted) {
      try {
        this.setState({
          isLoader: true,
        });

        const headers = {
          'Authorization': localStorage.getItem("token"),
        };

        const { id } = this.props.match.params;

        const response = await apiHalper(`getClientBalance?profile_id=${id}`, 'GET', null, headers);
        if (response.data.status === true) {
          const data = jwtDecode(response.data.data);
          const total_balance = data.available_balance + data.pending_balance;
          this.setState({
            isLoader: false,
            aBalance: data.available_balance,
            pBalance: data.pending_balance,
            tBalance: total_balance,
            editedBalance: data.available_balance,
          });
        } else {
          this.setState({
            isLoader: false,
            incomeData: [],
            aBalance: 0,
            pBalance: 0,
            tBalance: 0,
          });
        }
      } catch (err) {
        this.setState({
          isLoader: false,
          incomeData: [],
          aBalance: 0,
          pBalance: 0,
          tBalance: 0,
          messageApi: 'Network Error',
        });
      }
    }
  };

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };

//   handleSave = () => {
    
//     this.setState({
//       isEditing: false,
//       aBalance: this.state.editedBalance,
//     });
//   };
   handleSave = async (e) => {
    e.preventDefault();
    try {
      const { id } = this.props.match.params;
      const { editedBalance } = this.state;
  
      const headers = {
        'Authorization': localStorage.getItem("token"),
    }
        
        let formData = new FormData();
        formData.append('profile_id', id);
        formData.append('amount', editedBalance);
  
      const response = await apiHalper('updateClientBalance','POST',formData,headers);
         console.log(response)
         if(response.status === 200){
            // const data = jwtDecode(response.data.data);
            toster_sucess(response.data.message)
            // console.log('helooooooo',data)
            this.getWallatapi()
                this.setState({
                    isEditing: false,
                    // aBalance: data.amount,
                    editedBalance: null,                          
                });               
        }     
    } catch (error) {
      console.error('Error during updateClientBalance request:', error);
    }
  };
  

  handleCancel = () => {
   
    this.setState({
      isEditing: false,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      editedBalance: e.target.value,
    });
  };

  render() {
    const { aBalance, pBalance, tBalance, isEditing, editedBalance } = this.state;
    console.log('gfdsg',aBalance)

    return (
      <Fragment>
        <div className="tab_container">
          <ul className="wiget_wrap">
            <li>
              <div>
                <h5>{aBalance?.toFixed(2)} USD</h5>
                <p>Available Balance</p>
              </div>
            </li>
            <li>
              <div>
                <h5>{pBalance?.toFixed(2)} USD</h5>
                <p>Pending Balance</p>
              </div>
            </li>
            <li>
              <div>
                <h5>{tBalance?.toFixed(2)} USD</h5>
                <p>Total Balance</p>
              </div>
            </li>
          </ul>
          {<div className="form_wrap">
            <form method="POST" action="/" onSubmit={this.updateUserD}>
                <div className="form_group divd_wrap align-items-center d-flex">
                    <div className="wid_full">
                        <label className="label_form">Amount</label>
                        <input type="text" className='abalance' value={editedBalance} name="editedBalance" placeholder="Available balance" onChange={this.handleInputChange} autoComplete="off" disabled={!isEditing} />
                    </div>
                    <div className="form_btngroup mt-0 text-right">               
                        {!isEditing ? (
                        <button  type="button" onClick={this.handleEdit} className='btn_edit'>Edit</button>
                        ) : (
                        <>
                            <button type="button" className="btn_save" onClick={this.handleSave}>Save</button>
                            <button type="button" className="btn_cancel" onClick={this.handleCancel}>Cancel</button>
                        </>
                        )}
                    </div>                                
                </div>
            </form>
          </div>}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(null)(WalletList));
