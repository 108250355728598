import React, { Component } from 'react';
import { withRouter, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import KeywordLists from './KeywordLists';
import RedFlagLists from './RedFlagLists';
import AllLists from './AllLists';
import './index.css';
import FormControl from 'react-bootstrap/FormControl';
import AddModel from './AddModel';
import ChatTapsM from './ChatTapsM';
import FilterModel from './FilterModel';
import PrivateRoute from '../../Routes/PrivateRoutes';

class Chats extends Component {
    constructor(){
        super()
        this.state = {
            isAddModel:false,
            ischatTapsModel:false,
            isFilterModel:false,
            
        }
    }

    openisAddmodel = () => {
        this.setState({
            isAddModel : true,
            ischatTapsModel : false

        })
    }

    openischatTaps = () => {
        this.setState({
            ischatTapsModel : true
        })
    }

    closeisAddmodel = () => {
        this.setState({
            isAddModel : false,
            ischatTapsModel : true
        })
    }

    closeischatTaps = () => {
        this.setState({
            ischatTapsModel : false
        })
    }

    openisfilterModel = () => {
        this.setState({
            isFilterModel : true,
        })
    }
    closeisFiltermodel = () => {
        this.setState({
            isFilterModel : false
        })
    }

    render() {

        const { match } = this.props;

        const routUrl = [
            { name:'Keyword', url:`${match.url}`},
            { name:'Red Flag', url:`${match.url}/redFlag`},
            { name:'All', url:`${match.url}/all`}
        ]

        return (
            <section className="bg-balance mr_b0">
                <div className="container-fluid">
                    <div className="balance_wrap">
                            <ul className="nesting_links">
                                {routUrl.map((data,i)=><li key={i}><NavLink activeClassName="active" exact to={data.url} >{data.name}</NavLink></li>)}
                            </ul>
                            <div className="serch-div">
                                <FormControl type="text" placeholder="Search" className="mr-sm-2 input-color" />
                                <button type="button" className="add-button" onClick={this.openischatTaps} ></button>
                                <button type="button" className="btn_filterM" onClick={this.openisfilterModel} ></button>
                            </div>
                            <Switch>
                                <PrivateRoute exact path={`${match.path}`} component={KeywordLists} />
                                <PrivateRoute exact path={`${match.path}/redFlag`} component={RedFlagLists} />
                                <PrivateRoute exact path={`${match.path}/all`} component={AllLists} />
                            </Switch>
                        {/* <Tab.Container defaultActiveKey="keyword" className="tab_nav" id="chatsViews">
                            <Nav variant="pills" className="tab_nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="keyword">Keyword</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="redFlag">Red Flag</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="all">All</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            
                            <Tab.Content>
                                <Tab.Pane eventKey="keyword">
                                    <KeywordLists />
                                </Tab.Pane>
                                <Tab.Pane eventKey="redFlag">
                                    <RedFlagLists />
                                </Tab.Pane>
                                <Tab.Pane eventKey="all">
                                    <AllLists />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container> */}
                    </div>
                </div>
                <AddModel lgShow={this.state.isAddModel} handelClose={this.closeisAddmodel} />
                <ChatTapsM Show={this.state.ischatTapsModel} handelCloseM={this.closeischatTaps} othermodel={this.openisAddmodel}/>
                <FilterModel Show={this.state.isFilterModel} handelCloseFilter={this.closeisFiltermodel} />
            </section>
        )
    }
}
export default withRouter(connect(null)(Chats));
