import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import SearchIcon from '../../../assets/images/search_icon.svg';
import * as ACTION_TYPES from '../../../redux/actions/types';
import NoDataIndication from '../../TableLoader';
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
// import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { jwtDecode } from '../../../helpers/jwt_helper';
// import moment from 'moment';
import TableView from '../../TableView';
import DeleteModal from '../DeleteModal';

import { toster_sucess } from '../../../helpers/toster_helper';
import './index.css';

class ServiceSkillsComponent extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            insertedId: '',
            editData: null,
            mainCate: [],
            listData: [],
            isLoader: false,
            isSelectLoader: false,
            isMainLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            selectedOption: { value: 1, label: 'Customer Service' },
            isClearable: null,
            addModal: false,
            multiSelect: [{ categoryOption: null, isClearable: true, options: [] }],
            cateText: '',
            appName: '',
            fpName: '',
            pfName: '',
            displayWebsite: '',
            //isFeatured: '',
            description: '',
            formError: {
                cateText: '',
                appName: '',
                fpName: '',
                description: '',
                pfName: ''
            },
            formValid: {
                cateText: false,
                appName: false,
                fpName: false,
                description: false,
                pfName: false
            },
            apiError: '',
            isSLoader: false,
            isDLoader: false,
            isDelModal: false,
            step: 1,
            simFilterVal: 1,
            isCLoader: false,
            similerSctArr: [],
            SubCateList: [],
            searchSkillArr: [], 
            searchVal: '',
            simArray: [],
            checked: false,
            isEdit: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { selectedOption } = this.state;
        this.getSubCateList(selectedOption.value);
        this.getMainCate();
        // this.getSubListC(this.state.simFilterVal);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getMainCate = async () => {
        try {
            if (this._unmounted) {
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //"Access-Control-Allow-Origin": '*'
                }

                const response = await apiHalper('getMainServices', 'GET', null, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    const option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
                    // const 
                    this.setState({
                        mainCate: option,
                        multiSelect: [{ categoryOption: null, isClearable: true, options: option }]
                    })
                } else {
                    this.setState({
                        mainCate: []
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    getSubCateList = async (id) => {
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                    isSelectLoader: true,
                    isMainLoader: true,
                    listData: []
                });

                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //"Access-Control-Allow-Origin": '*'
                }

                let formData = new FormData();
                // formData.append('page_no', page)
                // formData.append('search_no', search)
                formData.append('parent_service_category_id', id)
                formData.append('pageType', 1)
                const response = await apiHalper('getSubServices', 'POST', formData, headers);
                // console.log(response);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    const newArray = data.map((el, i) => Object.assign({ num: (i + 1) }, el));
                    // console.log(newArray);
                    this.setState({
                        isLoader: false,
                        isSelectLoader: false,
                        isMainLoader: false,
                        listData: newArray,
                    })
                } else {
                    this.setState({
                        isLoader: false,
                        isSelectLoader: false,
                        isMainLoader: false,
                        listData: [],
                    })
                }
            }

        } catch (err) {
            console.log(err);
            this.setState({
                isLoader: false,
                isSelectLoader: false,
                isMainLoader: false,
                messageApi: 'Network Error'
            })
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getSubCateList(selectedOption.value);
        // this.getClientApi(this.state.page, this.state.search);
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: null }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'num':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'name':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'is_content':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'isDisplay':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case 'isFeatured':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case 'username':
            //     return <span className={column.sort?"header_span sort pd_left":"header_span pd_left"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    autoNumber = (cell) => {
        return <span className="number_td"></span>
    }

    checkNotNull = (cell) => {
        return cell !== null ? cell : '--';
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">0</span>;
    }

    displaySiteChecked = (e) => {
        let checked = e.target.checked
        let value = 0;
        if (checked) {
            value = 1
        }
        this.setState({
            displayWebsite: value
        })
    }
    // featuredChecked = (e) => {
    //     let checked = e.target.checked
    //     let value = 0;
    //     if (checked) {
    //         value = 1
    //     }
    //     this.setState({
    //         isFeatured: value
    //     })
    // }

    actionField = (cell, row) => {
        // return <Link to={`/`} className="btn-view margin_0auto">View</Link>;
        // onClick={() => this.toggleModalAdd(row)}
        // onClick={() => this.openDeleModal(row)}
        // console.log(row);
        return <div className="action_group text-center">
            {row.is_content === 1 ?
                <button type="button" className={"btn_content edit_content"} onClick={() => this.redirectPage(row)}></button>
                :
                row.pageFileName !== null ?
                    <button type="button" className={"btn_content add_content"} onClick={() => this.redirectPage(row)}></button>
                    :
                    <button type="button" className={"btn_content add_content"} onClick={() => this.pageCreateMessage(row)}></button>
            }

            <button type="button" className="btn_edit2" onClick={() => this.editModal(row)}></button>
            <button type="button" className="btn_delete" onClick={() => this.openDeleModal(row)}></button>
        </div>
    }

    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }

    contentField = (cell, row) => {
        return row.is_content === 1 ? <span className="text_green text-center margin_0auto">Available</span> : <span className="text_red text-center margin_0auto">Unavailable</span>;
    }

    statusField = (cell) => {

        if (cell === '1') {
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }

    }

    pageCreateMessage = (row) => {
        const { editDataPage, history, AddsubService } = this.props;
        // sessionStorage.setItem('subService', row.name);
        localStorage.setItem('subService', row.name);
        AddsubService(row.name)
        editDataPage(null);
        history.push(`/seo/add-pagecontent?id=${row.id}&parentPath=1&to=services&prev=services`);
    }

    redirectPage = async (data) => {
        try {
            const { history, AddsubService } = this.props;
            // sessionStorage.setItem('subService', data.name);
            localStorage.setItem('subService', data.name);
            AddsubService(data.name)
            if(data.is_content !== 0)
                history.push(`/seo/content-details?id=${data.id}&is_main=1&parentPath=1&prev=services`);
            else
                history.push(`/seo/add-pagecontent?id=${data.id}&parentPath=1&to=services&prev=services`)

        } catch (err) {
            console.log(err.message);
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage,
            });
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
        }

        // this.getClientApi(page, searchText);
    }

    toggleModalAdd = (data) => {
        const { editDataPage } = this.props;
        if (this.state.addModal) {
            this.setState({
                //selectedOption: { value: 1, label: 'Customer Service' },
                isClearable: null,
                addModal: false,
                multiSelect: [{ categoryOption: null, isClearable: true, options: this.state.mainCate }],
                cateText: '',
                appName: '',
                fpName: '',
                pfName: '',
                displayWebsite: '',
                description: '',
                formError: {
                    cateText: '',
                    appName: '',
                    fpName: '',
                    description: '',
                    pfName: ''
                },
                formValid: {
                    cateText: false,
                    appName: false,
                    fpName: false,
                    description: false,
                    pfName: false
                },
                apiError: '',
                isSLoader: false,
                isDLoader: false,
                isDelModal: false,
                step: 1
            });

            // editDataPage(null);
        }
        this.setState({
            addModal: !this.state.addModal,
            // editData: data
        });
        editDataPage(null);
    }

    editModal = async (data) => {
        // console.log(data);
        const { id } = data;
        this.setState({
            isEdit: true,
            editData: data,
            addModal: true,
            similerSctArr: [],
            simArray: [],
            appName: data.name_app !== null && data.name_app !== undefined ? data.name_app : data.name,
            pfName: data.pageFileName !== null && data.pageFileName !== undefined ? data.pageFileName : '',
            displayWebsite: Number(data.isDisplay),
           // isFeatured: Number(data.isFeatured),
            formValid: {
                cateText: true,
                appName: true,
                fpName: true,
                description: true
            }
        });
        this.getSimilarService(id);

        try {

            const headers = {
                'Authorization': localStorage.getItem('token'),
               // "Access-Control-Allow-Origin": '*'
            }

            let formData = new FormData();
            formData.append('service_category_id', id);
            formData.append('pageType', 1);
            const response = await apiHalper('getAllMainServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                const info = jwtDecode(res.data);
                if (Array.isArray(info) && info.length > 0) {
                    const last_index = info.length - 1;
                    for (var i = 0; i < info.length; i++) {
                        if (i < last_index) {
                            if (i > 0) {
                                // console.log(data[i]);
                                try {
                                    const headers = {
                                        'Authorization': localStorage.getItem('token')
                                    }

                                    let formData3 = new FormData();
                                    formData3.append('parent_service_category_id', data[i].id)
                                    formData.append('pageType', 1)
                                    const response = await apiHalper('getSubServices', 'POST', formData3, headers);
                                    // console.log(response);
                                    const res = response.data;
                                    if (res.status) {
                                        const data2 = jwtDecode(res.data);
                                        if (Array.isArray(data2)) {
                                            const selectVal1 = { label: data2[i].name, value: data2[i].id }
                                            const update = data2.map(({ id, name }) => ({ value: id, label: name }));
                                            this.addMoreSelect(update, selectVal1);
                                        }
                                    }
                                } catch (err) {
                                    console.log(err.message);
                                }
                            } else {
                                const selectVal = { label: info[i].name, value: info[i].id }
                                this.setState({
                                    multiSelect: [
                                        { categoryOption: selectVal, isClearable: true, options: this.state.mainCate }
                                    ]
                                });
                                // this.addMoreSelect(this.state.mainCate, selectVal);
                            }
                        } else {
                            this.setState({
                                cateText: info[i].name,

                            })
                        }
                    }
                }


            }
        } catch (err) {
            console.log(err.message);
        }

    }

    getSimilarService = async (id) => {
        console.log(id)
        try {
            const headers = {
                'Authorization': localStorage.getItem('token'),
                //"Access-Control-Allow-Origin": '*'
            }

            let formData2 = new FormData();
            formData2.append('serviceCategoryID', id)
            formData2.append('parentPath', 1)
            // get similer services
            const respons = await apiHalper('getSimilarCategoryByServiceId', 'POST', formData2, headers);
            const ress = respons.data;

            if (ress.status) {
                const simiData = jwtDecode(ress.data);
                console.log(simiData)
                let newArr = [];
                for (let v = 0; v < simiData.length; v++) {
                    newArr.push(simiData[v].id)
                }
                // console.log(newArr);
                // console.log(simiData);
                this.setState({
                    similerSctArr: simiData,
                    simArray: newArr
                })
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    openDeleModal = (data) => {
        this.setState({
            isDelModal: true,
            editData: data
        })
    }

    addMoreSelect = (options, selectVal) => {
        this.setState({
            multiSelect: [...this.state.multiSelect, { categoryOption: selectVal, isClearable: true, options: options }]
        })
    }

    RemoveClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: true }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    cateHandler = async (selectedOption, index) => {
        const { multiSelect } = this.state;
        const list = [...multiSelect];
        if (selectedOption !== null) {

            // console.log(selectedOption);
            list[index].categoryOption = selectedOption;
            this.setState({
                multiSelect: list
            });

            this.updateSubC(selectedOption, index, null);

        } else {
            this.handleRemoveClick(index);
        }

    }

    updateSubC = async (selectedOption, index, selectVal) => {
        try {
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            if (index === 0) {
                this.setState({
                    multiSelect: [{ categoryOption: selectedOption, isClearable: true, options: this.state.mainCate }]
                })
            }

            let formData = new FormData();
            // formData.append('page_no', page)
            // formData.append('search_no', search)
            formData.append('parent_service_category_id', selectedOption.value)
            formData.append('pageType', 1)
            const response = await apiHalper('getSubServices', 'POST', formData, headers);
            // console.log(response);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (Array.isArray(data)) {
                    const update = data.map(({ id, name }) => ({ value: id, label: name }));
                    this.addMoreSelect(update, selectVal);
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    handleRemoveClick = (index) => {
        if (index > 0) {
            const list = [...this.state.multiSelect];
            list.splice(index, 1);
            // console.log(list);
            this.setState({ multiSelect: list });
        } else {
            this.setState({ multiSelect: [{ categoryOption: null, isClearable: true, options: this.state.mainCate }] })
        }
    };

    getCate = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const numberReg = new RegExp(/^[A-Z0-9!@#$%^&*()_+\\/=\\[\]{};':"\\|,.<>\\/?]*$/);
        // const numberReg = new RegExp(/^[-+]?\d+\.[0-9]+$/);
        switch (name) {
            case 'cateText':
                valid.cateText = value.length > 0;
                error.cateText = valid.cateText ? '' : 'Category is required'
                break;
            case 'appName':
                valid.appName = value.length > 0;
                error.appName = valid.appName ? '' : 'App Name is required'
                break;
            case 'fpName':
                valid.fpName = value.length > 0;
                error.fpName = valid.fpName ? '' : 'FastPaper App Name is required'
                break;
            case 'pfName':
                // console.log(numberReg.test(value));
                if (value.length > 0) {
                    if (!numberReg.test(value)) {
                        valid.pfName = true;
                        error.pfName = '';
                    } else {
                        valid.pfName = false;
                        error.pfName = 'Page File name must be string';
                    }
                } else {
                    valid.pfName = false
                    error.pfName = 'Page File Name is required';
                }
                break;
            case 'description':
                valid.description = value.length > 0;
                error.description = valid.description ? '' : 'Description is required'
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    submitCategory = async (e) => {
        e.preventDefault();
        // console.log(simArray);
        try {
            const { similarSctArr, simArray, insertedId, multiSelect } = this.state;
            if (similarSctArr.length > 0) {
                if (similarSctArr.length > 5) {
                    this.setState({
                        isSLoader: true
                    })
                    const headers = {
                        'Authorization': localStorage.getItem('token')
                    }

                    let formData = new FormData();
                    formData.append('serviceCategoryID', multiSelect[0].categoryOption.value)
                    formData.append('similarCategoryID', JSON.stringify(simArray))
                    formData.append('parentPath', 1)

                    const response = await apiHalper('addSimilarCategory', 'POST', formData, headers);
                    //const response = await apiHalper('addServices', 'POST', formData, headers);
                    const res = response.data;
                    // console.log(res)
                    if (res.status) {
                        this.setState({
                            apiError: '',
                            isSLoader: false,
                            addModal: false,
                            step: 2,
                            simArray: [],
                            similarSctArr: [],
                            simFilterVal: 1,
                            multiSelect: [{ categoryOption: null, isClearable: true, options: [] }],
                            cateText: '',
                            appName: '',
                            fpName: '',
                            pfName: '',
                            displayWebsite: '',
                            description: '',
                            formError: {
                                cateText: '',
                                appName: '',
                                fpName: '',
                                description: '',
                                pfName: ''
                            },
                            formValid: {
                                cateText: false,
                                appName: false
                            }
                        });
                        const { history } = this.props;
                        history.push(`/seo/add-pagecontent?id=${insertedId}&value=sub`)
                        toster_sucess(res.message);
                    } else {
                        this.setState({
                            apiError: res.message,
                            isSLoader: false,
                            addModal: true
                        })
                    }
                } else {
                    this.setState({
                        apiError: 'Please Select Minimum 6 skills',
                        isSLoader: false
                    })
                }
            } else {
                this.setState({
                    apiError: 'Similar Skill is required.',
                    isSLoader: false
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isSLoader: false
            })
        }
    }

    prevSubcategory = () => {
        this.setState({
            step: 1
        });
    }
    updateCategoryStep1 = async (e) => {
        e.preventDefault();
        const { multiSelect, cateText, appName, editData, displayWebsite } = this.state;
        try {

            this.setState({
                isSLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            const totalIndex = multiSelect.length;
            if (totalIndex > 2) {
                // if(multiSelect[totalIndex - 1].categoryOption !== null){
                //     formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                // }else{
                //     formData.append('parent_service_category_id', multiSelect[totalIndex - 2].categoryOption.value)
                // }
                formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)

            } else {
                if (multiSelect[0].categoryOption !== null) {
                    if (totalIndex > 1) {
                        if (multiSelect[totalIndex - 1].categoryOption !== null) {
                            formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                        } else {
                            formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                        }
                    } else {
                        formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                    }
                } else {
                    formData.append('parent_service_category_id', 0)
                }
            }

            formData.append('name', cateText)
            formData.append('name_app', appName)
            formData.append('isDisplay', displayWebsite ? 1 : 0)    
            formData.append('service_category_id', editData.id)
            formData.append('pageType', 1)
           // formData.append('isFeatured', isFeatured)
            const response = await apiHalper('editServices', 'POST', formData, headers);
            const res = response.data;
            // console.log(res);
            if (res.status) {
                //const decode = jwtDecode(res.data)
                this.setState({
                    apiError: '',
                    isSLoader: false,
                    step: 2
                });
                const { selectedOption } = this.state;
                this.getSubListC(selectedOption.value);
                toster_sucess('Service successfully added.');
            } else {
                this.setState({
                    apiError: res.message,
                    isSLoader: false,
                    addModal: true
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isSLoader: false
            })
        }
    }
    submitCategoryStep1 = async (e) => {
        e.preventDefault();
        try {
            const { multiSelect, cateText, appName, displayWebsite, selectedOption } = this.state;
            this.setState({
                isSLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            const totalIndex = multiSelect.length;
            if (multiSelect[0].categoryOption !== null) {
                if (totalIndex > 1) {
                    if (multiSelect[totalIndex - 1].categoryOption !== null) {
                        formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                    } else {
                        formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                    }
                } else {
                    formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                }
                //formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
            } else {
                formData.append('parent_service_category_id', 0)
            }

            if (totalIndex > 2) {
                if (multiSelect[totalIndex - 1].categoryOption !== null) {
                    formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                } else {
                    formData.append('parent_service_category_id', multiSelect[totalIndex - 2].categoryOption.value)
                }
            }
            formData.append('name', cateText)
            formData.append('name_app', appName)
            formData.append('isDisplay', displayWebsite ? 1 : 0)
            formData.append('pageType', 1)
            const response = await apiHalper('addServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data)
                this.setState({
                    apiError: '',
                    isSLoader: false,
                    addModal: false,
                    step: 2,
                    insertedId: decode.insertedId
                });
                console.log(selectedOption.value)
                this.getSubListC(selectedOption.value);
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isSLoader: false
                })
            }
        }
        catch (err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isLoader: false
            })
        }
    }

    updateCategory = async (e) => {
        e.preventDefault();
        const { similerSctArr, simArray, editData } = this.state;
        try {
            if (similerSctArr.length > 0) {
                if (similerSctArr.length > 5) {
                    this.setState({
                        isSLoader: true
                    })
                    const headers = {
                        'Authorization': localStorage.getItem('token')
                    }

                    let formData = new FormData();
                    //const totalIndex = multiSelect.length;
                    // if (totalIndex > 2) {
                    //     if(multiSelect[totalIndex - 1].categoryOption !== null){
                    //         formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                    //     }else{
                    //         formData.append('parent_service_category_id', multiSelect[totalIndex - 2].categoryOption.value)
                    //     }

                    // }else{
                    //     if(multiSelect[0].categoryOption !== null){
                    //         if(totalIndex > 1){
                    //             if(multiSelect[totalIndex - 1].categoryOption !== null){
                    //                 formData.append('parent_service_category_id', multiSelect[totalIndex - 1].categoryOption.value)
                    //             }else{
                    //                 formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                    //             }
                    //         }else{
                    //             formData.append('parent_service_category_id', multiSelect[0].categoryOption.value)
                    //         }
                    //     }else{
                    //         formData.append('parent_service_category_id', 0)
                    //     }
                    // }


                    // formData.append('serviceCategoryID', multiSelect[0].categoryOption.value )
                    // formData.append('similarCategoryID', JSON.stringify(simArray))

                    formData.append('similarCategoryID', JSON.stringify(simArray))
                    formData.append('serviceCategoryID', editData.id)
                    formData.append('parentPath', 1)
                    const response = await apiHalper('addSimilarCategory', 'POST', formData, headers);
                    const res = response.data;
                    // console.log(response);
                    if (res.status) {
                        this.setState({
                            apiError: '',
                            isSLoader: false,
                            addModal: false,
                            step: 1,
                            simArray: [],
                            similerSctArr: [],
                            simFilterVal: 1
                        });
                        const { selectedOption } = this.state;
                        this.getSubCateList(selectedOption.value);
                        toster_sucess('Service successfully added.');
                    } else {
                        this.setState({
                            apiError: res.message,
                            isSLoader: false,
                            addModal: true
                        })
                    }
                } else {
                    this.setState({
                        apiError: 'Please Select Minimum 6 skills',
                        isSLoader: false
                    })
                }
            } else {
                this.setState({
                    apiError: 'Similer Skill is required.',
                    isSLoader: false
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                apiError: err.message,
                isSLoader: false
            })
        }
    }

    modalDelClose = () => {
        this.setState({
            isDelModal: false
        })
    }

    deleteFunc = async (e) => {
        e.preventDefault();
        // console.log('working...');
        try {

            const { editData } = this.state;

            this.setState({
                isDLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();     
            formData.append('serviceCategoryID', editData.id);
            formData.append('pageType', 1)
            const response = await apiHalper('deleteServices', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isDelModal: false,
                    editData: null,
                    SubCateList: [],
                    searchSkillArr: [],
                    isDLoader: false
                });
                this.getSubCateList(this.state.selectedOption.value);
            }

        } catch (err) {
            console.log(err);
        }
    }

    stepHandler = () => {
        // console.log('if');
        const { formValid, multiSelect } = this.state;
        let disabled;
        if (multiSelect[0].categoryOption) {
            // || formValid.fpName 
            disabled = formValid.cateText || formValid.appName
        } else {
            // || formValid.fpName
            disabled = formValid.cateText || formValid.appName;
        }
        // console.log(disabled);
        // const disabled = multiSelect[0].categoryOption !== null?formError.cateText !== '': formError.cateText !== '' || formError.appName !== '' || formError.fpName !== '' || formError.description !== '';
        if (disabled) {
            this.setState({
                step: 2,
                apiError: '',
                simFilterVal: multiSelect[0].categoryOption.value,
                // simArray:[],
                SubCateList: [],
                searchSkillArr: [],
                // similerSctArr:[]
            });
            this.getSubListC(multiSelect[0].categoryOption.value);
        } else {
            // console.log('else');
            this.setState({
                step: 1,
                apiError: 'Please fill the form'
            })
        }
    }

    getSubListC = async (value) => {
        if (this._unmounted) {

            this.setState({
                isCLoader: true,
                isSelectLoader: true,
                isMainLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            // formData.append('page_no', page)
            // formData.append('search_no', search)
            formData.append('parent_service_category_id', value)
            formData.append('pageType', 1)
            const response = await apiHalper('getSubServices', 'POST', formData, headers);
            // console.log(response);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                // console.log(data);
                if (Array.isArray(data) && data.length > 0) {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        isSelectLoader: false,
                        SubCateList: data,
                        searchSkillArr: data,
                        subMessage: ''
                    })
                } else {
                    this.setState({
                        isCLoader: false,
                        isMainLoader: false,
                        SubCateList: [],
                        searchSkillArr: [],
                        subMessage: 'No Record Found'
                    })
                }
            } else {
                this.setState({
                    isCLoader: false,
                    isMainLoader: false,
                    SubCateList: [],
                    searchSkillArr: [],
                    subMessage: res.message
                })
            }
        }
    }

    searchSkillSubmit = (e) => {
        e.preventDefault();
        console.log("working")
        const { searchSkillArr, searchVal } = this.state;

        const search = searchSkillArr.filter(info => info.name.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchSkillArr: search
        })
    }
    
    skillSearchInput = (e) => {
        e.preventDefault();
        const { value } = e.target;
        const { SubCateList } = this.state;

        if (value.length > 0) {
            const search = SubCateList.filter(info => info.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchSkillArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchSkillArr: SubCateList,
                searchVal: value
            })
        }
    }
    noCall = (e) => {
        e.preventDefault();
    }

    radioHandler = (e) => {
        const { value } = e.target;
        // console.log(value);
        this.setState({
            simFilterVal: value
        });

        this.getSubListC(value);
    }

    removeSelect = (data) => {
        // this.state.SubCateList.map((val) => {
        //     if (data.id === val.id) {
        //         document.getElementById('allSkil' + data.id).setAttribute('checked', false);
        //         document.getElementById('allSkil' + data.id).removeAttribute('class');
        //     }
        //     return this.setState({
        //         checked: false,
        //     })
        // })
        let nameArr = this.state.similerSctArr;
        let simArray = this.state.simArray;

        for (let i = 0; i < nameArr.length; i++) {
            if (nameArr[i].id === data.id) {
                nameArr.splice(i, 1);
                simArray.splice(i, 1);
                i--;
            }
        }
        if (nameArr.length > 0) {
            this.setState({
                similerSctArr: nameArr,
                simArray: simArray

            })
        } else {
            this.setState({
                similerSctArr: [],
                simArray: []
            })
        }
    }

    toggleCli = (data) => {
        // console.log(data);
        let newArray = this.state.similerSctArr;
        let simArray = this.state.simArray;
        document.getElementById('allSkil' + data.id).setAttribute('checked', true);
        document.getElementById('allSkil' + data.id).setAttribute('class', 'active');

        newArray.push({ id: data.id, name: data.name });
        simArray.push(data.id);


        for (let h = 0; h < newArray.length; h++) {
            let curItem = newArray[h];
            let foundCount = 0;
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id === newArray[h].id) {
                    foundCount++;
                };
            }
            if (foundCount > 1) {
                for (let j = 0; j < newArray.length; j++) {
                    if (newArray[j].id === curItem.id) {
                        newArray.splice(j, 1);
                        simArray.splice(j, 1);
                        j--;
                    }
                }
            }
        }

        if (newArray.length > 0) {
            return this.setState({
                similerSctArr: newArray,
                simArray: simArray
            })
        } else {
            return this.setState({
                similerSctArr: [],
                simArray: []
            })
        }
    }

    semilerSelectedRender = (data, i) => {
        // if(similerSctArr.includes(data.id)){
        //     activeClass="active";
        //     checked1 = true;
        // }
        return <li key={i}>
            <label htmlFor={"web" + i}>
                <input type="checkbox" id={"web" + i} value={data.id} name="selectSimiler" defaultChecked onClick={() => this.removeSelect(data)} className="active" />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    allSubListRender = (data, i) => {
        const { simArray } = this.state;
        let activeClass = '';
        let checked1 = false;
        //console.log(data);
        //console.log(simArray)
        if (simArray.includes(data.id)) {
            //console.log('if');
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
            <label htmlFor={"allSkil" + data.id}>
                <input type="checkbox" id={"allSkil" + data.id} value={data.id} name="allSkil" onClick={() => this.toggleCli(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    checkBoxField = (cell, row) => {
        return <div className="text-center">
            <button type="button" className={cell === '1' ? "btn_checkbox active" : "btn_checkbox"}></button>
        </div>
    }
    featuredcheckBoxField = (cell, row) => {
        return <div className="text-center">
            <button type="button" className={cell === 1 ? "btn_checkbox active" : "btn_checkbox"}></button>
        </div>
    }
    render() {

        const { mainCate, isMainLoader } = this.state;
        // const totalIndex = this.state.multiSelect.length;
        // console.log(this.state.multiSelect[totalIndex - 1]);

        const options = mainCate;
        // console.log(this.state.similerSctArr);

        const columns = [
            { dataField: 'num', text: '#', sort: false, headerFormatter: this.headerSpan },
            { dataField: 'name', text: 'Service', sort: true, headerFormatter: this.headerSpan },
            // { dataField: 'time', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.timeSet },
            { dataField: 'pageFileName', text: 'Page Link', sort: true, headerFormatter: this.headerSpan, formatter: this.checkNotNull },
            { dataField: 'is_content', text: 'Page Content', sort: true, headerFormatter: this.headerSpan, formatter: this.contentField },
            { dataField: 'isDisplay', text: 'Display in website', sort: true, headerFormatter: this.headerSpan, formatter: this.checkBoxField },
            // { dataField: 'isFeatured', text: 'Featured', sort: true, headerFormatter: this.headerSpan, formatter: this.featuredcheckBoxField },
            // { dataField: 'keyword', text: 'Keyword', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            // { dataField: 'count_reports', text: 'Report', sort: true, headerFormatter: this.headerSpan, formatter:this.centerField },
            // { dataField: 'profile_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
            // { dataField: 'project_amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];


        const tableData = {
            columns: columns,
            data: this.state.listData,
            keyId: 'num',
            loading: this.state.isLoader,
            message: this.state.messageApi !== '' ? this.state.messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: 0,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        const deleteModal = {
            handleClose: this.modalDelClose,
            isModal: this.state.isDelModal,
            deleteFunc: this.deleteFunc,
            isLoader: this.state.isDLoader
        }

        const { selectedOption, isClearable, addModal, multiSelect, cateText, apiError, isSLoader, editData, formError, formValid, similerSctArr, displayWebsite } = this.state;
        // || !formValid.appName || !formValid.fpName || !formValid.fpName
        const disabled = !formValid.cateText || !formValid.appName;
        // console.log(this.state.SubCateList);
        const disabled2 = similerSctArr.length > 5;
        let h2Select = this.state.cateText;
        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap">                        
                        <Link to="/seo/add-subcategory?parentPath=1&is_main=1&to=services" type="button" className="btn_addUsers">Add Sub Category</Link>
                        <div className="select_wrap users_filters">
                            <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                        </div>
                        <TableView TableData={tableData} />
                    </div>
                </div>
                <Modal show={addModal} onHide={() => this.toggleModalAdd(null)} className="modal_design" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">{this.state.step === 1 ? editData !== null ? "Update Services" : "Add Services" : "Similar Services"} <button type="button" onClick={() => this.toggleModalAdd(null)}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals">
                                {this.state.step === 1 ?
                                    <form action="/" method="POST" onSubmit={editData !== null ? this.updateCategoryStep1 : this.submitCategoryStep1}>
                                        {multiSelect.map((x, i) =>
                                            <div className="form_group" key={i}>
                                                <Select className="select_out" value={x.categoryOption} isDisabled={true} isSearchable={true} isClearable={x.isClearable} placeholder={"Select"} onChange={(e) => this.cateHandler(e, i)} options={x.options} />
                                                {this.state.isSelectLoader ? <span className="select-loader"><img src={LoaderImg} className="small-loader" alt="loader" /></span> : null}
                                            </div>
                                        )}
                                        <div className="form_group">
                                            <input type="text" name="cateText" className={formError.cateText !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Category" value={cateText} onChange={this.getCate} autoComplete="off" />
                                        </div>

                                        <div className="form_group">
                                            {this.state.appName === '' && editData !== null ?
                                                <input type="text" name="appName" className="form_control2" placeholder="App Name" value={this.state.cateText} onChange={this.getCate} autoComplete="off" />
                                                :
                                                <input type="text" name="appName" className="form_control2" placeholder="App Name" value={this.state.appName} onChange={this.getCate} autoComplete="off" />
                                            }
                                        </div>
                                        {/* <div className="form_group">
                                                <input type="text" name="fpName" className={formError.fpName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="FastPaper App Name" onChange={this.getCate} autoComplete="off" />
                                            </div> */}
                                        {/* <div className="form_group">
                                                <input type="text" name="pfName" className={formError.pfName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Page File Name" value={this.state.pfName} onChange={this.getCate} autoComplete="off" />
                                            </div> */}
                                        <div className="form_group" style={{display:'flex', gap:'10px'}}>
                                            <div className="checkbox-group">
                                                <input type="checkbox" value={displayWebsite} name="displayWebsite" id="mainmenu" defaultChecked={displayWebsite} onChange={(e) => this.displaySiteChecked(e)} className="styled-checkbox" />
                                                <label htmlFor="mainmenu">Display on website</label>
                                            </div>
                                            {/* <div className="checkbox-group">
                                                <input type="checkbox" value={isFeatured} name="isFeatured" id="isFeatured" defaultChecked={isFeatured} onChange={(e) => this.featuredChecked(e)} className="styled-checkbox" />
                                                <label htmlFor="isFeatured">isFeatured</label>
                                            </div> */}
                                        </div>
                                        
                                        {/* <div className="form_group">
                                                <textarea name="description" className={formError.description !== '' ? "form_control2 textarea error_border" : "form_control2 textarea"} placeholder="Description" onChange={this.getCate}></textarea>
                                            </div> */}
                                        {apiError !== '' ? <p className="error_text">{apiError}</p> : null}
                                        <div className="bg_submit2 right-mx120">
                                            <button type="submit" className="btn-submit" disabled={disabled}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Next"}</button>
                                        </div>
                                    </form>
                                    :
                                    <form action="/" method="POST" onSubmit={editData !== null ? this.updateCategory : this.submitCategory}>
                                        <div className="main_cat">
                                            {multiSelect[0].categoryOption ? <h2 className="text_sel">Selected: <span>{h2Select}</span> </h2> : null }
                                            <ul className="radioBt">
                                                {Array.isArray(mainCate) ?
                                                    mainCate.map((info, i) => {
                                                        return <li key={i}>
                                                            <label>
                                                                <input type="radio" name="mainCate" defaultChecked={info.value === this.state.simFilterVal} value={info.value} onChange={this.radioHandler} />
                                                                <span className="label_text">{info.label}</span>
                                                            </label>
                                                        </li>
                                                    }) : null
                                                }
                                            </ul>
                                            <div className="conte_bloc">
                                                <div className="select_divd">
                                                    <ul>{Array.isArray(this.state.similerSctArr) ? this.state.similerSctArr.map((data, i) => this.semilerSelectedRender(data, i)) : null } </ul>
                                                </div>
                                                <div className="select_divd">
                                                    <div className="search-form">
                                                        <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search Skill" onChange={this.skillSearchInput} />
                                                        <button type="button"><img src={SearchIcon} alt="Search" /></button>
                                                    </div>
                                                    <ul>{isMainLoader ? <NoDataIndication /> : Array.isArray(this.state.searchSkillArr) ? this.state.searchSkillArr.map((data, i) => this.allSubListRender(data, i)) : null } </ul>                                                    
                                                </div>
                                            </div>
                                            <span className="small-caps">Please Select Minimum 6 Skills</span>
                                        </div>
                                        <div className="bg_submit2 right-mx120 two-button">
                                            <button type="button" className="btn-submit" onClick={this.prevSubcategory}>Prev</button>
                                            <button type="submit" className="btn-submit" disabled={!disabled2 || isSLoader}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : editData !== null ? "Update" : "Add Services"}</button>
                                        </div>
                                    </form>
                                }

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <DeleteModal data={deleteModal} />
            </section>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    editDataPage: (data) => dispatch({ type: ACTION_TYPES.EDIT_DATA_STORE, payload: data }),
    AddsubService: (data) => dispatch ({ type: ACTION_TYPES.SERVICE_NAME, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(ServiceSkillsComponent));