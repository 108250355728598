import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderImg from "../../assets/images/user-loader.gif";
import { apiHalper } from "../../helpers/ApiHelper";

const TagsModal = ({
  isCategoryModal,
  closeModal,
  isAdd,
  getPage,
  setIsAdd,
  updateData,
  pageNumber,
  tagList,
  setTagsList,
  setUpdateData,
}) => {
  const [isSLoader, setIsSLoader] = useState(false);
  const [tagData, setTagsdata] = useState({
    name: "",
    name_ar: "",
    categoryId: "",
    status: 1,
  });
  useEffect(() => {
    setTagsdata({
      name: updateData?.name,
      name_ar: updateData?.name_ar,
      status: updateData?.status === "undefined" ? "" : updateData?.status,
      categoryId: updateData?.category_id,
    });
  }, [updateData]);

  const handleSubmit = async () => {
    setIsSLoader(true);
    const formData = new FormData();
    formData.append("name", tagData.name);
    formData.append("name_ar", tagData.name_ar);
    tagData.categoryId && formData.append("category_id", tagData.categoryId);
    tagData.status === 1 ||
      (tagData.status === 0 && formData.append("status", tagData.status));
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    try {
      const res1 = await apiHalper(
        "addServiceCategoryTag",
        "POST",
        formData,
        headers
      );
      if (res1.data.status === true) {
        setIsAdd(!isAdd);
        setTagsdata({
          name: "",
          name_ar: "",
          status: "",
          categoryId: "",
        });
        toast.success("Tag Add Successfully!");
        getPage(pageNumber);
        setUpdateData();
      } else {
        toast.error(res1.data.message);
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setIsSLoader(false);
      closeModal();
    }
  };

  const handleUpdate = async () => {
    try {
      setIsSLoader(true);
      const formData = new FormData();
      formData.append("name", tagData.name);
      formData.append("name_ar", tagData.name_ar);
      formData.append("id", updateData.id);
      tagData.categoryId && formData.append("category_id", tagData.categoryId);
      tagData.status === 1 ||
        (tagData.status === 0 && formData.append("status", tagData.status));

      const res1 = await apiHalper("editServiceCategoryTag", "PATCH", formData);
      if (res1.data.status === true) {
        const NewData = tagList.map((data) => {
          if (data.id === updateData.id) {
            data.name = tagData.name;
            data.name_ar = tagData.name_ar;
            data.category_id = tagData.categoryId;
          }
          return data;
        });
        setTagsList(NewData);
        toast.success("Tag Edit Successfully!");
        setTagsdata({
          name: "",
          name_ar: "",
          categoryId: "",
          status: "",
        });
      } else {
        toast.error("Tag Edit  Failed");
      }
    } catch (e) {
      toast.error(e || "Oops!");
    } finally {
      setIsSLoader(false);
      closeModal();
      setUpdateData();
    }
  };

  return (
    <>
      <Modal
        show={isCategoryModal}
        className="modal_design"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                Tags details{" "}
                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                    setTagsdata({
                      name: "",
                      name_ar: "",
                      status: "",
                    });
                    setUpdateData();
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals body_modals2">
              <form>
                <div className="form_body">
                  <div className="form_group2">
                    <label>Tag Name</label>
                    <input
                      type="text"
                      name="name"
                      className={"form_control2"}
                      value={tagData.name}
                      onChange={(e) => {
                        setTagsdata({
                          ...tagData,
                          name: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2">
                    <label>Tag Name Ar</label>
                    <input
                      type="text"
                      name="name_ar"
                      className={"form_control2"}
                      value={tagData.name_ar}
                      onChange={(e) => {
                        setTagsdata({
                          ...tagData,
                          name_ar: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="bg_submit2 mr-top">
                  {isAdd ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={tagData.name && tagData.name_ar ? false : true}
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleUpdate();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={isSLoader}
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => {
                        closeModal();
                        setTagsdata({
                          name: "",
                          name_ar: "",
                          status: "",
                        });
                        setUpdateData();
                      }}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TagsModal;
