import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

class RedFlagLists extends Component {
    render() {
        return (
            <div className="tab_container mr_t50">
                <div className="listView">
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Link to="chats/1">
                                <div className="chat_profile_Block">
                                    <div className="content_card">
                                        <h6 className="use_title">John Doe</h6>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                    </div>
                                    <div className="img_view">
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                        <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="user" />
                                    </div>
                                    <div className="card-footer2">
                                        <p>a moment ago</p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(RedFlagLists));