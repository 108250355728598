import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Details from './Details';
import * as ACTIVE_TYPES from '../../redux/actions/types';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import DataTable from '../JqueryDataTable';
import NoDataIndication from '../TableLoader';
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
// import SubmitDetails from './SubmitDetails';
// import Files from './Files';
// import PaymentDetails from './PaymentDetails';
import './index.css';

class IncrementDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cid: null,
            type: null,
            isViewProposal: false,

            pageNo: 1,
            recordCount: 0,
            listData: [],
            isLoader: false,            
            isMainLoader: false,
            messageApi: '',
            page: 1,
            search: ''            
        }
    }

    componentDidMount() {        
        const { cid } = this.props.match.params;
        const queryString = this.props.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type');

        this.setState({
            cid: cid,
            type: type
        })

        this.backTo(type, cid);
    }

    backBtn = () => {
        const { detailView, history } = this.props;
        detailView(null)
        let prevPath = localStorage.getItem('prevGigPath')
        if(prevPath){
            history.push(prevPath);
        }else{
            history.push('/all-gigs');
        }
        localStorage.removeItem('prevGigPath')
    }

    backTo = (type, id) => {
        const { history } = this.props;
        if (id === null) {
            if (type === 'jobs') {
                history.push('/allJobs');
            }
            if (type === 'balance') {
                history.push('/balance');
            }
            if (type === 'agents') {
                history.push('/users/agents');
            }
            if (type === 'offers') {
                history.push('/offers/usedcoupon');
            }
            if (type === 'users') {
                history.push('/users/agent');
            }
        }
    }
    viewProposal = () => {
        const { isViewProposal, page, cid } = this.state
        this.setState({
            isViewProposal: !isViewProposal
        })
        if(!isViewProposal) {
            this.getProposalList(cid, page)
        }
    }
    headerSpan = (column) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'is_hired':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'message':
                return <span className={column.sort ? "header_span sort" : "header_span text-center"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort" : "header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    imgNotFound = (e)=>{
        e.target.src = DummyProfile;
    }
    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.img}`
        return <div className="tb_user_wrap p-0">
            <div className="useimg_block online_icon">
                <img src={row.img !== null? imgPath: DummyProfile} onError={(e)=>this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }
    amountField = (cell, row) => {
        return <div className="text-center amount">{row.amount} {row.currency}</div>
    }

    descriptionField = (row) => {
        return row
    }
    statusField = (cell) => {
        switch (cell) {
            case 0:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Not Hired'}</button>;
            case 1:
                return <button type="button" className="btn-status blue margin_0auto" disabled>{'Hired'}</button>;
            default: 
                break
        }
    }
    
    actionField = (row) => {
        // to={`/partner-detail?id=${row.id}`}
        return <Link className="btn-view margin_0auto">View Chat</Link>
    }
    
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if(type === 'pagination') {            
            this.setState({
                pageNo: page,
                sizePerPage,
            });
            this.getProposalList(this.state.cid, page);
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
            this.getProposalList(this.state.cid, page);
        }

    }
    getProposalList = async (id, pageNo) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('job_post_id', id)
            formData.append('page_no', pageNo )
            
            const response = await apiHalper('viewProposals', 'POST', formData, headers);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    listData: decode.bid_list,
                    recordCount: decode.count,
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    listData: [],
                    recordCount: 0,
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                listData: [],
                messageApi: err.message
            })
        }
    }

    render() {
        // console.log(this.props.match.params);

        const { cid, type, isViewProposal, listData, isLoader, messageApi, pageNo } = this.state;
        const columns = [
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter:this.userField },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField },
            { dataField: 'is_hired', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'message', text: 'Description', sort: true, headerFormatter: this.headerSpan, formatter:this.descriptionField }, 
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: listData,
            keyId: 'username',
            loading: isLoader,
            message: messageApi !== '' ? messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: pageNo,
            totalRecord: this.state.recordCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange
        };
        return (
            <Fragment>
                <section className="bg-balance">
                    <div className="container-fluid">
                        <div className={!isViewProposal ? "balance_wrap set-back" : "balance_wrap"}>
                            {!isViewProposal ?
                                <div className="btns">
                                    {type === 'agents' ? <button onClick={this.backBtn} className="btn-back right_btn display_inlineBlock">Back</button> : null}
                                    {type === 'balance_agents' ? <Link to="/balance" onClick={this.backBtn} className="btn-back right_btn display_inlineBlock">Back</Link> : null}
                                    {/* {type === 'offers' ? <Link to="/offers/usedcoupon" onClick={this.backBtn} className="btn-back right_btn display_inlineBlock">Back</Link> : null}
                                    {type === 'balance' ? <Link to="/balance" onClick={this.backBtn} className="btn-back right_btn display_inlineBlock">Back</Link> : null} */}
                                    {/* <button className="btn btn-primary" onClick={this.viewProposal}>View All Proposal</button> */}
                                    {/* <button className="btn btn-primary"><svg width="19.999" height="20" fill="#fff" viewBox="0 0 19.999 20"><path d="M18.9,7.828l-1.567-.2a7.875,7.875,0,0,0-.474-1.145l.967-1.246a1.239,1.239,0,0,0-.1-1.649L16.415,2.277a1.242,1.242,0,0,0-1.654-.106l-1.244.967a7.847,7.847,0,0,0-1.146-.474l-.2-1.564A1.248,1.248,0,0,0,10.933,0H9.067A1.248,1.248,0,0,0,7.828,1.1l-.2,1.567a7.756,7.756,0,0,0-1.146.474L5.238,2.171a1.24,1.24,0,0,0-1.649.1L2.277,3.584a1.242,1.242,0,0,0-.106,1.655l.967,1.245a7.783,7.783,0,0,0-.474,1.145l-1.564.2A1.248,1.248,0,0,0,0,9.067v1.867a1.248,1.248,0,0,0,1.1,1.238l1.567.2a7.875,7.875,0,0,0,.474,1.145l-.967,1.246a1.239,1.239,0,0,0,.1,1.649l1.313,1.313a1.243,1.243,0,0,0,1.654.1l1.245-.968a7.693,7.693,0,0,0,1.145.474l.2,1.563A1.248,1.248,0,0,0,9.067,20h1.867a1.248,1.248,0,0,0,1.238-1.1l.2-1.567a7.875,7.875,0,0,0,1.145-.474l1.246.967a1.239,1.239,0,0,0,1.649-.1l1.313-1.312a1.241,1.241,0,0,0,.106-1.654l-.967-1.245a7.694,7.694,0,0,0,.474-1.145l1.563-.2A1.248,1.248,0,0,0,20,10.933V9.067a1.247,1.247,0,0,0-1.1-1.238ZM10,14.167A4.167,4.167,0,1,1,14.167,10,4.171,4.171,0,0,1,10,14.167Z" /></svg></button> */}
                                    <div className="onoffswitch d-none">
                                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                                        <label className="onoffswitch-label" for="myonoffswitch">
                                            <span className="onoffswitch-inner"></span>
                                            <span className="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
                                : null
                            }

                            {!isViewProposal ? 
                                <Details id={cid} /> :
                                <div className="proposal-list">
                                    <button className="proposal-title btn-back right_btn display_inlineBlock" onClick={this.viewProposal}>Back to Job</button>                                    
                                    <DataTable TableData={tableData} />
                                </div>                                    
                            }
                            {/* <Tab.Container defaultActiveKey="job" className="tab_nav" id="balanceViews">
                                <Nav variant="pills" className="tab_nav">
                                    <Nav.Item>
                                        <Nav.Link eventKey="job">Job</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="submit">Submit</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="details">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="files">Files</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="payment">Payment</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="job">
                                        <Job id={cid} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="submit">
                                        <SubmitDetails id={cid} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="details">
                                        <Details id={cid} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="files">
                                        <Files id={cid} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="payment">
                                        <PaymentDetails id={cid} />
                                    </Tab.Pane>                                    
                                </Tab.Content>                                
                            </Tab.Container> */}
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(IncrementDetails));
