import React from 'react';

const ActionField = ({ row, setUpdateData, setIsCategoryModal, setIsAdd, openDeleModal }) => {
  return (
    <div className="action_group">
      <button
        type="button"
        className="btn_edit2"
        onClick={() => {
          setUpdateData(row);
          setIsCategoryModal(prev => !prev);
          setIsAdd(false);
        }}
      ></button>
      <button
        type="button"
        className="btn_delete"
        onClick={() => openDeleModal(row.id)}
      ></button>
    </div>
  );
};

export default ActionField;
