import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { apiHalper } from "../../helpers/ApiHelper";
import { jwtDecode } from "../../helpers/jwt_helper";
import { toster_sucess, toster_error } from "../../helpers/toster_helper";
class StatusDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_id: "",
      chat_alert: 1,
      red_flag: 0,
      could_post: 0,
      could_chat: 0,
      Ban_user: 0,
      isNInfo: 1,
      // chatAlert:1,
      // redFlag:0,
      // posting:1,
      // chatVal:1,
      amountData: null,
      countData: null,
      reviewsData: null,
      statusData: [
        { title: "Completed" },
        { title: "Waiting for deposit" },
        { title: "In-Progress" },
        { title: "Bidding" },
        { title: "No-Bidding" },
        { title: "Canceled" },
        { title: "Refund" },
      ],
      // banUser:0,
      verified: 0,
      type: 0,
    };
  }

  componentDidMount() {
    this.getStatusApi();
  }

  componentDidUpdate(prevProps) {
    const { userDetails, type } = this.props;
    if (prevProps.type !== type) {
      this.setState({
        type: type,
      });
    }

    if (prevProps.userDetails !== userDetails) {
      this.setState({
        isNInfo: userDetails.status,
        verified:
          userDetails.is_verified === 0 || userDetails.is_verified === 2
            ? 0
            : 1,
      });
    }
  }

  getStatusApi = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const queryString = this.props.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const { id } = this.props.match.params;

      if (type === "agents") {
        let formData = new FormData();
        formData.append("profile_id", id);

        const res = await apiHalper(
          `getAgentStatus`,
          "POST",
          formData,
          headers
        );
        if (res.data.status) {
          const data = jwtDecode(res.data.data);
          console.log(data, "agetData");
          this.setState({
            profile_id: id,
            amountData: data.amount,
            countData: data.count,

            reviewsData: data.reviews,
            Ban_user: Number(data?.permissions[0]?.ban_user) || 0,
            chat_alert: Number(data?.permissions[0]?.chat_alert) || 0,
            could_post: Number(data?.permissions[0]?.could_post) || 0,
            could_chat: Number(data?.permissions[0]?.could_chat) || 0,
            red_flag: Number(data?.permissions[0]?.red_flag) || 0,
            isNInfo: Number(data?.permissions[0]?.status) || 4,
            // verified: Number(data?.permissions[0]?.red_flag) || 0,
          });
        } else {
          this.setState({
            amountData: null,
            countData: null,
            reviewsData: null,
            message: res.data.message,
          });
        }
      }

      if (type === "clients") {
        let formData = new FormData();
        formData.append("profile_id", id);
        const res = await apiHalper(
          `getAgentStatus`,
          "POST",
          formData,
          headers
        );
        if (res.data.status) {
          const data = jwtDecode(res.data.data);
          this.setState({
            profile_id: data?.permissions[0]?.profile_id,
            amountData: null,
            countData: null,
            reviewsData: null,
            Ban_user: Number(data?.permissions[0]?.ban_user) || 0,
            chat_alert: Number(data?.permissions[0]?.chat_alert) || 0,
            could_post: Number(data?.permissions[0]?.could_post) || 0,
            could_chat: Number(data?.permissions[0]?.could_chat) || 0,
            red_flag: Number(data?.permissions[0]?.red_flag) || 0,
            isNInfo: Number(data?.permissions[0]?.status) || 7,
            // verified: Number(data?.permissions[0]?.red_flag) || 0,
            // message:res.data.message
          });
        } else {
          this.setState({
            amountData: null,
            countData: null,
            reviewsData: null,
            // message:res.data.message
          });
        }

        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  banUserApi = async (val) => {
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const queryString = this.props.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const { id } = this.props.match.params;

      if (val == 0) {
        const res = await apiHalper(`unBanUser/${id}`, "GET", null, headers);

        if (res.data.status === true) {
          toster_sucess("Success on Unbanning user");
        } else {
          toster_error("Error on Unbanning user");
        }
      } else if (val == 1) {
        const res = await apiHalper(`banUser/${id}`, "GET", null, headers);

        if (res.data.status === true) {
          toster_sucess("Success on banning user");
        } else {
          toster_error("Error on banning user");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  userStatusHandlerApi = async (data) => {
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    let formData = new FormData();
    Object.entries(data).map(([key, value]) => {
      formData.append(key, value);
    });

    const response = await apiHalper(
      "togglePermissions",
      "POST",
      formData,
      headers
    );
    const res = response.data;
    if (res.status) {
      this.setState({ ...data });
      toster_sucess("Status Updated Successfully...");
    } else {
      toster_error(res.msg);
    }
  };

  verifyFunc = async (val) => {
    // e.preventDefault();
    try {
      const { type } = this.state;
      const { id } = this.props.match.params;
      this.setState({
        isDLoader: true,
      });

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("profile_type_id", type === "clients" ? 2 : 1);
      formData.append("is_verified", val);

      const response = await apiHalper(
        "verifyProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        this.setState({ verified: val });
        toster_sucess("Verified Successfully..");
      } else {
        toster_error(res.message);
      }
    } catch (err) {
      // console.log(err);
      this.setState({
        verifyMessage: "Network Error",
        isDLoader: false,
      });
    }
  };
  verifyAccount = async (val) => {
    try {
      const { id } = this.props.match.params;
      this.setState({
        isDLoader: true,
      });

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("account_status", val);

      const response = await apiHalper(
        "changeAccountStatus",
        "POST",
        formData,
        headers
      );
      const res = response.data;
      if (res.status) {
        this.setState({ isNInfo: val });
        toster_sucess("Account status updated Successfully..");
      } else {
        toster_error(res.message);
      }
    } catch (err) {
      // console.log(err);
      this.setState({
        verifyMessage: "Network Error",
        isDLoader: false,
      });
    }
  };
  radioHandle = (e, val) => {
    const { name } = e.target;

    const { id } = this.props.match.params;
    const {
      profile_id,
      chat_alert,
      red_flag,
      could_chat,
      could_post,
      Ban_user,
    } = this.state;
    switch (name) {
      case "noti_info":
        this.verifyAccount(val);
        break;
      case "chat-alert":
        this.userStatusHandlerApi({
          profile_id: id,
          chat_alert: val,
          red_flag: red_flag,
          could_post: could_post,
          could_chat: could_chat,
          Ban_user: Ban_user,
        });
        // this.setState({chat_alert:val});
        break;
      case "red_flag":
        this.userStatusHandlerApi({
          profile_id: id,
          chat_alert: chat_alert,
          red_flag: val,
          could_post: could_post,
          could_chat: could_chat,
          Ban_user: Ban_user,
        });
        // this.setState({red_flag:val});
        break;
      case "postings":
        this.userStatusHandlerApi({
          profile_id: id,
          chat_alert: chat_alert,
          red_flag: red_flag,
          could_post: val,
          could_chat: could_chat,
          Ban_user: Ban_user,
        });
        // this.setState({could_post:val});
        break;
      case "chat":
        this.userStatusHandlerApi({
          profile_id: id,
          chat_alert: chat_alert,
          red_flag: red_flag,
          could_post: could_post,
          could_chat: val,
          Ban_user: Ban_user,
        });
        // this.setState({could_chat:val});
        break;
      case "ban":
        this.userStatusHandlerApi({
          profile_id: id,
          chat_alert: chat_alert,
          red_flag: red_flag,
          could_post: could_post,
          could_chat: could_chat,
          Ban_user: val,
        });
        // this.setState({Ban_user:val});
        // this.banUserApi(val);
        break;
      case "verified":
        this.verifyFunc(val);
        // this.banUserApi(val);
        break;
      default:
        break;
    }
    // console.log(e.target.name);
    // this.setState({
    //     isNInfo:val
    // });
  };

  render() {
    const {
      isNInfo,
      chat_alert,
      red_flag,
      could_post,
      could_chat,
      amountData,
      countData,
      reviewsData,
      Ban_user,
      verified,
      statusData,
    } = this.state;

    return (
      <div className="tab_container">
        <div className="notifica_info">
          <ul className="noti_ul">
            <li className="">
              <div>
                <p>Account</p>
              </div>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="inactive"
                  name="noti_info"
                  checked={isNInfo === 4}
                  onChange={(e) => this.radioHandle(e, 4)}
                />
                <label htmlFor="inactive">Inactive</label>
                <input
                  type="radio"
                  id="active"
                  name="noti_info"
                  checked={isNInfo === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="active">Active</label>
              </div>
            </li>
            <li>
              <p>Verified Status</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="verified"
                  name="verified"
                  checked={verified === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="verified">No</label>
                <input
                  type="radio"
                  id="not_verified"
                  name="verified"
                  checked={verified === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="not_verified">Yes</label>
              </div>
            </li>
            <li>
              <p>Chatting Alert</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="chat_off"
                  name="chat-alert"
                  checked={chat_alert === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="chat_off">Off</label>
                <input
                  type="radio"
                  id="chat_on"
                  name="chat-alert"
                  checked={chat_alert === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="chat_on">On</label>
              </div>
            </li>
            <li>
              <p>Red Flag</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="rflag_off"
                  name="red_flag"
                  checked={red_flag === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="rflag_off">Off</label>
                <input
                  type="radio"
                  id="rflag_on"
                  name="red_flag"
                  checked={red_flag === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="rflag_on">On</label>
              </div>
            </li>
            <li>
              <p>Posting</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="posting_off"
                  name="postings"
                  checked={could_post === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="posting_off">Off</label>
                <input
                  type="radio"
                  id="posting_on"
                  name="postings"
                  checked={could_post === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="posting_on">On</label>
              </div>
            </li>
            <li>
              <p>Chat</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="chat_inactive"
                  name="chat"
                  checked={could_chat === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="chat_inactive">Inactive</label>
                <input
                  type="radio"
                  id="chat_active"
                  name="chat"
                  checked={could_chat === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="chat_active">Active</label>
              </div>
            </li>
            <li>
              <p>Ban User</p>
              <div className={"wrap_notialert"}>
                <input
                  type="radio"
                  id="banning_no"
                  name="ban"
                  checked={Ban_user === 0}
                  onChange={(e) => this.radioHandle(e, 0)}
                />
                <label htmlFor="banning_no">No</label>
                <input
                  type="radio"
                  id="banning_yes"
                  name="ban"
                  checked={Ban_user === 1}
                  onChange={(e) => this.radioHandle(e, 1)}
                />
                <label htmlFor="banning_yes">Yes</label>
              </div>
            </li>
          </ul>
          <ul className="noti_ul">
            <li>
              <p>Total Paid</p>
              <div className={"wrap_notialert min_width"}>
                <p>$2332/27</p>
              </div>
            </li>
            <li>
              <p>Total Chats</p>
              <div className={"wrap_notialert min_width"}>
                <p>13</p>
              </div>
            </li>
            <li>
              <p>Red Keywords</p>
              <div className={"wrap_notialert min_width"}>
                <p>14</p>
              </div>
            </li>
            <li>
              <p>Reports</p>
              <div className={"wrap_notialert min_width"}>
                <p>15</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="status_bWrap">
          <ul className="noti_ul noti_ul2">
            <li>
              <p>Total Jobs</p>
              <div className={"wrap_notialert min_width"}>
                {console.log(amountData, "amountData")}
                {console.log(countData, "amountData")}
                {/* <p>{amountData && countData ? `${amountData[0]?.all_count}/${countData[0]?.all_count}`:'$0/0'}</p> */}
                <p>{countData ? `${countData?.length}` : "0"}</p>
              </div>
            </li>
            {statusData?.map((item) => {
              const AllData = countData?.filter(
                (data) => data.name == item.title
              );
              return (
                <>
                  <li>
                    <p>{item.title}</p>
                    <div className={"wrap_notialert min_width"}>
                      {AllData?.length > 0 ? (
                        <p>{AllData[0]?.count}</p>
                      ) : (
                        <p>0</p>
                      )}
                    </div>
                  </li>
                </>
              );
            })}
            {/* <li>
              <p>Completed</p>
              <div className={"wrap_notialert min_width"}>
                <p>{amountData && countData? `${amountData[0].completed_count}/${countData[0].completed_count}`:'$0/0'}</p>
              </div>
            </li>
            <li>
              <p>In Progress</p>
              <div className={"wrap_notialert min_width"}>
                <p>{amountData && countData? `${amountData[0].inprogress_count}/${countData[0].inprogress_count}`:'$0/0'}</p>
              </div>
            </li>
            <li>
              <p>Waiting for deposit</p>
              <div className={"wrap_notialert min_width"}>
                <p>{amountData && countData? `${amountData[0].waiting_deposit_count}/${countData[0].waiting_deposit_count}`:'$0/0'}</p>
              </div>
            </li>
            <li>
              <p>Bidding</p>
              <div className={"wrap_notialert min_width"}>
                <p>{amountData && countData? `${amountData[0].bidding_count}/${countData[0].bidding_count}`:'$0/0'}</p>
              </div>
            </li> */}
            {/* <li>
              <p>No-Bidding</p>
              <div className={"wrap_notialert min_width"}>
                <p>$0/0</p>
              </div>
            </li> */}
            {/* <li>
              <p>Canceled</p>
              <div className={"wrap_notialert min_width"}>
                <p>
                  {countData
                    ? `$${amountData[0].cancel_count}/${countData[0].cancel_count}`
                    : "$0/0"}
                </p>
              </div>
            </li> */}
            {/* <li>
              <p>Refund</p>
              <div className={"wrap_notialert min_width"}>
                <p>
                  {amountData && countData
                    ? `$${amountData[0].refund_count}/${countData[0].refund_count}`
                    : "$0/0"}
                </p>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="status_bWrap">
          <ul className="noti_ul noti_ul2 mar_0">
            <li>
              <p>Rated me</p>
              <div className={"wrap_notialert min_width"}>
                <p>{reviewsData !== null ? reviewsData.rated_me : 0} Reviews</p>
              </div>
            </li>
            <li>
              <p>I Rated</p>
              <div className={"wrap_notialert min_width"}>
                <p>{reviewsData !== null ? reviewsData.i_rated : 0} Clients</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userDetails: state.users.userDetails,
});
const mapDispatchToProps = (dispatch) => ({
  // addDetails: (data) => dispatch({ type: ACTION_TYPES.USER_DETAILS_API, payload: data })
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatusDetails)
);
