import React, { Component } from 'react';
import LoaderGif from '../../assets/images/user-loader.gif';

export default class ScrollLoader extends Component {
    render() {
        return (
            <div className="Loader_scroll">
                <img src={LoaderGif} alt="Loader" />
            </div>
        )
    }
}
