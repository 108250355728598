import React, { Component } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import OffersLists from './OffersLists';
import PrivateRoute from '../../Routes/PrivateRoutes';
import CouponDetail from '../CouponDetail';
import UsedCoupon from '../UsedCoupon';
import CouponReport from '../CouponReport';
import CoupRepDetails from '../CoupRepDetails';
import './index.css';


class Offers extends Component {

    render() {

        const { match } = this.props;

        return (
            <Switch>
                <PrivateRoute exact path={`${match.path}`} component={OffersLists} />
                <PrivateRoute exact path={`${match.path}/coupon-detail`} component={CouponDetail} />
                <PrivateRoute exact path={`${match.path}/usedcoupon`} component={UsedCoupon} />
                <PrivateRoute exact path={`${match.path}/reportcoupon`} component={CouponReport} />
                <PrivateRoute exact path={`${match.path}/reportcoupon/:cid`} component={CoupRepDetails} />
            </Switch>
        )
    }
}



export default withRouter(connect(null)(Offers));
