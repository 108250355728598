import React, { Component } from 'react';
import { withRouter, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Clients from './Clients';
import Agents from './Agents';
import Admin from './Admin';
import './index.css';
import UserModal from './UserModal';
import AdminModal from './AdminModal';
import PrivateRoute from '../../Routes/PrivateRoutes';

class Users extends Component {

    render() {

        const { match, clientCount, agentCount, adminCount } = this.props;

        const routUrl = [
            { name:'Clients', count:clientCount, url:`${match.url}`},
            { name:'Agents', count:agentCount, url:`${match.url}/agents`},
            { name:'Admin', count:adminCount, url:`${match.url}/admin`}
        ]
        // console.log(this.props);

        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap">
                            <ul className="nesting_links">
                                {routUrl.map((data,i)=><li key={i}><NavLink activeClassName="active" exact to={data.url} >{data.name} <span>({data.count})</span></NavLink></li>)}
                            </ul>
                            <Switch>
                                <PrivateRoute exact path={`${match.path}`} component={Clients} />
                                <PrivateRoute exact path={`${match.path}/agents`} component={Agents} />
                                <PrivateRoute exact path={`${match.path}/admin`} component={Admin} />
                            </Switch>
                        {/* <Tab.Container defaultActiveKey="clients" className="tab_nav" id="usersViews">
                            <Nav variant="pills" className="tab_nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="clients">Clients <span>({clientCount})</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="agents">Agents <span>({agentCount})</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="admin">Admin <span>({adminCount})</span></Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="clients">
                                    <Clients />
                                </Tab.Pane>
                                <Tab.Pane eventKey="agents">
                                    <Agents />
                                </Tab.Pane>
                                <Tab.Pane eventKey="admin">
                                    <Admin />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container> */}
                    </div>
                </div>
                <UserModal />
                <AdminModal />
            </section>
        )
    }
}

const mapStateToProps = state =>({
    clientCount:state.users.client,
    agentCount: state.users.agent,
    adminCount: state.users.admin
});

export default withRouter(connect(mapStateToProps)(Users));
