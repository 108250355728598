import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import * as ACTION_TYPES from "../../redux/actions/types";
// import { apiDeviceHelper } from '../../helpers/ApiHelper';
import { toster_sucess, toster_error } from "../../helpers/toster_helper";
import LoaderImg from "../../assets/images/user-loader.gif";
import DummyProfile from "../../assets/images/dummy-profile.jpg";
import Select from "react-select";
import { apiHalper } from "../../helpers/ApiHelper";
import { jwtDecode } from "../../helpers/jwt_helper";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      radioStatus: 2,
      adminstatus: 3,
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      password: "",
      countryCode: "966",
      phone: "",
      phoneError: "",
      phoneValid: false,
      profilePic: DummyProfile,
      profileFile: [],
      mawthooq: [],
      mawthooqType: 1,
      mawthooq_id: "",
      categories: [],
      genderOptions: [
        { value: 2, label: "Male" },
        { value: 1, label: "Female" },
      ],
      gender: { value: 2, label: "Male" },
      profile_visiblity_options: [
        { value: 2, label: "invisible" },
        { value: 1, label: "visibale" },
      ],
      profile_visiblity : "",
      selectedCategory: "",
      selectedTags: [],
      tagsOption: [],
      companyName: "",
      brandName: "",
      vatType: 2,
      vatNumber: "",
      commercial: [],
      vat: [],
      cr_number: "",
      bussiness_email: "",
      bussiness_email_status: 1,
      formError: {
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        // gender: '',
        password: "",
        companyName: "",
        brandName: "",
        bussiness_email: "",
      },
      formValid: {
        firstname: false,
        lastname: false,    
        username: false,
        email: false,
        password: false,
        companyName: false,
        brandName: false,
        bussiness_email: false,
      },
    };
  }

  componentDidMount() {
    this._unmounted = true;
    this.getAllCategories();
    this.getAllTags();
  }

  componentWillUnmount() {
    this._unmounted = false;
  }
  radioChange = (val) => {
    this.setState({
      radioStatus: val,
    });
  };

  adminRadioChange = (val) => {
    this.setState({
      adminstatus: val,
    });
  };

  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.validationChecked(name, value)
    );
  };

  validationChecked = (name, value) => {
    let error = this.state.formError;
    let valid = this.state.formValid;

    switch (name) {
      case "bussiness_email":
        if (value.length > 0) {
          if (
            value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          ) {
            valid.bussiness_email = true;
            error.bussiness_email = "";
          } else {
            valid.bussiness_email = false;
            error.bussiness_email = "Enter a valid bussiness_email!";
          }
        } else {
          valid.bussiness_email = false;
          error.bussiness_email = "bussiness_email must required!";
        }
      case "firstname":
        console.log("call");
        valid.firstname = value.length > 0;
        error.firstname = valid.firstname ? "" : "Firstname must required!";
        break;
      case "lastname":
        valid.lastname = value.length > 0;
        error.lastname = valid.lastname ? "" : "Lastname must required!";
        break;
      case "gender":
        valid.gender = value.length > 0;
        error.gender = valid.gender ? "" : "gender must required!";
        break;
      case "username":
        valid.username = value.length > 0;
        error.username = valid.username ? "" : "Username must required!";
        break;
      case "email":
        if (value.length > 0) {
          if (
            value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          ) {
            valid.email = true;
            error.email = "";
          } else {
            valid.email = false;
            error.email = "Enter a valid email!";
          }
        } else {
          valid.email = false;
          error.email = "Email must required!";
        }
        break;
      case "password":
        if (value.length > 0) {
          if (value.length >= 8) {
            valid.password = true;
            error.password = "";
          } else {
            valid.password = false;
            error.password = "Password must be at least 8 characters long";
          }
        } else {
          valid.password = false;
          error.password = "Password must required!";
        }
        break;
      default:
        break;
    }

    this.setState({
      formError: error,
      formValid: valid,
    });
  };

  handlePhone = (isvalid, val) => {
    if (!isvalid) {
      this.setState({
        phone: val,
        phoneError: "Please Enter Valid Phone Number",
        phoneValid: false,
      });
    } else {
      this.setState({
        phone: val,
        phoneError: "",
        phoneValid: true,
      });
    }
  };

  getCountry = (e, country) => {
    console.log({ country });
    this.setState({
      countryCode: country.dialCode,
      phone: "",
      phoneError: "Phone Number is must required!",
      phoneValid: false,
    });
  };

  handleChangeProfile = (e) => {
    this.setState({
      profileFile: e.target.files[0],
      profilePic: URL.createObjectURL(e.target.files[0]),
    });
  };
  handleChangeMawthooq = (e) => {
    this.setState({
      mawthooq: e.target.files[0],
    });
  };
  handleChangeCR = (e) => {
    this.setState({
      commercial: e.target.files[0],
    });
  };
  handleChangeVat = (e) => {
    this.setState({
      vat: e.target.files[0],
    });
  };

  radioHandler = (mawthooqType) => {
    this.setState({ mawthooqType });
  };
  radioHandlerVat = (vatType) => {
    this.setState({ vatType });
  };
  radioHandlerVat = (vatType) => {
    this.setState({ vatType });
  };
  radioHandler = (mawthooqType) => {
    this.setState({ mawthooqType });
  };

  handleTagsChange = (selectedTags) => {
    this.setState({ selectedTags });
  };
  handleCategoryChange = (selectedCategory) => {
    this.setState({ selectedCategory });
  };
  handleGenderChange = (gender) => {
    this.setState({ gender: gender });
  };
  handleProfileVisibilityChange = (gender) => {
    this.setState({ profile_visiblity: gender });
  };

  getAllTags = async (e) => {
    try {
      this.setState({
        isLoader: true,
      });
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const response = await apiHalper("getTags", "GET", null, headers);
      const res = response.data;
      if (!res.status) {
        this.setState({
          tagsOption: [],
          isLoader: false,
        });
      }
      const selectedTagsList = jwtDecode(res.data);
      const sortList = selectedTagsList.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      this.setState({
        tagsOption: sortList.map((info) => ({
          value: info.id,
          label: info.name,
        })),
        isLoader: false,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
  getAllCategories = async (e) => {
    try {
      this.setState({
        isLoader: true,
      });
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const response = await apiHalper("getCategories", "GET", null, headers);
      const res = response.data;
      if (!res.status) {
        this.setState({
          categories: [],
          isLoader: false,
        });
      }
      const selectedCategoryList = jwtDecode(res.data);
      const sortList = selectedCategoryList.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      this.setState({
        categories: sortList.map((info) => ({
          value: info.id,
          label: info.name,
        })),
        isLoader: false,
      });
    } catch (err) {
      console.error(err.message);
    }
  };
  submitUser = async (e) => {
    e.preventDefault();
    // console.log('form submit...');
    const headers = {
      Authorization: localStorage.getItem("token"),
    };

    const {
      radioStatus,
      adminstatus,
      phone,
      username,
      firstname,
      lastname,
      email,
      password,
      gender,
      countryCode,
      formValid,
      phoneValid,
      selectedCategory,
      selectedTags,
      profileFile,
      mawthooq_id,
      mawthooq,
      companyName,
      brandName,
      vatNumber,
      cr_number,
      bussiness_email,
      commercial,
      vat,
      bussiness_email_status,
      profile_visiblity,
    } = this.state;
    if (
      formValid.username ||
      formValid.firstname ||
      formValid.lastname ||
      formValid.email ||
      !formValid.lastname ||
      phoneValid
    ) {
      this.setState({
        isLoader: true,
      });

      console.log({ phone, countryCode });
      let formData = new FormData();
      formData.append("contact", phone);
      formData.append("email", email);
      formData.append("first_name", firstname);
      formData.append("last_name", lastname);
      formData.append("mobile_prefix", countryCode);
      formData.append("password", password);
      formData.append(
        "profile_type_id",
        radioStatus === 3 ? adminstatus : radioStatus
      );
      formData.append("username", username);
      if (radioStatus == 1) {
        var option = Array.isArray(selectedTags)
          ? selectedTags.map((x) => x.value)
          : [];
        formData.append(
          "service_category_id",
          selectedCategory.value ? selectedCategory.value : 0
        );
        formData.append("tags", option);
        formData.append("mawthooq", mawthooq);
        formData.append("mawthooq_id", mawthooq_id);
        formData.append("gender", gender?.value);
        formData.append("bussiness_email", bussiness_email);
        formData.append("show_email", bussiness_email_status);
      }
      if (radioStatus == 2) {
        formData.append("company", companyName);
        formData.append("brand", brandName);
        formData.append("cr_number", cr_number);
        formData.append("vat_number", vatNumber);
        formData.append("commercial", commercial);
        formData.append("vat", vat);
      }
      formData.append("profile", profileFile);
      // return;
      const response = await apiHalper("addUser", "POST", formData, headers);
      const res = response.data;
      if (res.status) {
        this.setState({
          isLoader: false,
        });
        toster_sucess("User added Successfully...");
        this.props.handleClose();
      } else {
        this.setState({
          isLoader: false,
        });
        toster_error(res.message);
      }
    } else {
      toster_error("Please fill all field");
    }
  };

  render() {
    const { userModal, handleClose } = this.props;
    const {
      radioStatus,
      firstname,
      lastname,
      username,
      email,
      password,
      phone,
      gender,
      formError,
      formValid,
      phoneError,
      phoneValid,
      adminstatus,
      isLoader,
      profilePic,
      mawthooqType,
      mawthooq_id,
      categories,
      genderOptions,
      profile_visiblity_options,
      profile_visiblity,
      selectedTags,
      tagsOption,
      selectedCategory,
      companyName,
      brandName,
      vatType,
      vatNumber,
      cr_number,
      bussiness_email,
      bussiness_email_status,
    } = this.state;
    const disabled =
      !formValid.firstname ||
      !formValid.lastname ||
      !formValid.username ||
      !formValid.email ||
      !formValid.password ||
      !phoneValid;

    return (
      <Modal
        show={userModal}
        onHide={() => handleClose()}
        className="modal_design"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                Add User{" "}
                <button type="button" onClick={handleClose}>
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals">
              <form method="POST" action="#" onSubmit={this.submitUser}>
                <div className="form_body">
                  <div className="radio_wrap">
                    <input
                      type="radio"
                      name="user"
                      id="clientRadio"
                      checked={radioStatus === 2}
                      onChange={() => this.radioChange(2)}
                    />
                    <label htmlFor="clientRadio">Client</label>
                    <input
                      type="radio"
                      name="user"
                      id="agentRadio"
                      checked={radioStatus === 1}
                      onChange={() => this.radioChange(1)}
                    />
                    <label htmlFor="agentRadio">Agent</label>
                    <input
                      type="radio"
                      name="user"
                      id="adminRadio"
                      checked={radioStatus === 3}
                      onChange={() => this.radioChange(3)}
                    />
                    <label htmlFor="adminRadio">Admin</label>
                  </div>
                  {radioStatus === 1 || radioStatus === 2 ? (
                    <div className="form_group3">
                      <div className="colum_2_wrap">
                        <label>Upload logo</label>
                        <input
                          type="file"
                          onChange={this.handleChangeProfile}
                        />
                        <div className="tb_user_wrap">
                          <div className="useimg_block online_icon">
                            <img src={profilePic} width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="form_group3">
                    <div className="colum_2_wrap">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        className={
                          formError.firstname !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }
                        value={firstname}
                        onChange={this.inputHandler}
                        placeholder="Enter First Name"
                        autoComplete="off"
                      />
                      {formError.firstname !== "" ? (
                        <p className="error_text">{formError.firstname}</p>
                      ) : null}
                    </div>
                    <div className="colum_2_wrap">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        className={
                          formError.lastname !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }


                        
                        value={lastname}
                        onChange={this.inputHandler}
                        placeholder="EnterLast Name"
                        autoComplete="off"
                      />
                      {formError.lastname !== "" ? (
                        <p className="error_text">{formError.lastname}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form_group3">
                    <div className="colum_2_wrap">
                      <label>User Name</label>
                      <input
                        type="text"
                        name="username"
                        className={
                          formError.username !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }
                        value={username}
                        onChange={this.inputHandler}
                        placeholder="Enter User Name"
                        autoComplete="off"
                      />
                      {formError.username !== "" ? (
                        <p className="error_text">{formError.username}</p>
                      ) : null}
                    </div>
                    <div className="colum_2_wrap">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className={
                          formError.email !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }
                        value={email}
                        onChange={this.inputHandler}
                        placeholder="Enter Email Address"
                        autoComplete="off"
                      />
                      {formError.email !== "" ? (
                        <p className="error_text">{formError.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form_group3">
                    <div className="colum_2_wrap">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        className={
                          formError.password !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }
                        value={password}
                        onChange={this.inputHandler}
                        placeholder="Enter Password"
                        autoComplete="off"
                      />
                      {formError.password !== "" ? (
                        <p className="error_text">{formError.password}</p>
                      ) : null}
                    </div>
                    <div className="colum_2_wrap">
                      <label>Phone Number</label>
                      <IntlTelInput
                        onPhoneNumberChange={this.handlePhone}
                        defaultCountry="sa"
                        onSelectFlag={this.getCountry}
                        value={phone}
                        inputClassName={
                          phoneError !== ""
                            ? "form_control2 error_border"
                            : "form_control2"
                        }
                      />
                      {phoneError !== "" ? (
                        <p className="error_text">{phoneError}</p>
                      ) : null}
                    </div>
                  </div>
                  {radioStatus === 2 ? (
                    <>
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <label>Company Name</label>
                          <input
                            type="text"
                            name="companyName"
                            className={
                              formError.companyName !== ""
                                ? "form_control2 error_border"
                                : "form_control2"
                            }
                            value={companyName}
                            onChange={this.inputHandler}
                            placeholder="Enter Company Name"
                            autoComplete="off"
                          />
                          {formError.companyName !== "" ? (
                            <p className="error_text">
                              {formError.companyName}
                            </p>
                          ) : null}
                        </div>
                        <div className="colum_2_wrap">
                          <label>Brand Name</label>
                          <input
                            type="text"
                            name="brandName"
                            className={
                              formError.brandName !== ""
                                ? "form_control2 error_border"
                                : "form_control2"
                            }
                            value={brandName}
                            onChange={this.inputHandler}
                            placeholder="Enter Brand Name"
                            autoComplete="off"
                          />
                          {formError.brandName !== "" ? (
                            <p className="error_text">{formError.brandName}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <label>Commercial Registration Number</label>
                          <input
                            type="text"
                            name="cr_number"
                            className="form_control2"
                            value={cr_number}
                            onChange={this.inputHandler}
                            placeholder="Enter commercial registration number"
                            autoComplete="off"
                          />
                          {formError.cr_number !== "" ? (
                            <p className="error_text">{formError.cr_number}</p>
                          ) : null}
                        </div>
                        <div className="colum_2_wrap">
                          <label>Commercial Registration Document</label>
                          <input type="file" onChange={this.handleChangeCR} />
                        </div>
                      </div>
                      {/* --------------------profile-mobilevisibilty--------- */}
                      {/* <div className="form_group3">
                        <div className="colum_2_wrap">
                          <label>Profile Visibility</label>
                          {console.log(profile_visiblity,'profile_visiblity')}
                          <Select
                            value={profile_visiblity}
                            onChange={this.handleProfileVisibilityChange}
                            options={profile_visiblity_options}
                            placeholder="Profile Visibility"
                          />
                        </div>
                        <div className="colum_2_wrap mt-3">
                          <label>VAT Registration Query</label>
                          <input
                            type="radio"
                            name="release"
                            checked={vatType === 1}
                            onClick={(e) => this.radioHandlerVat(1)}
                          />{" "}
                          Yes
                          <input
                            type="radio"
                            name="release"
                            checked={vatType === 2}
                            onClick={(e) => this.radioHandlerVat(2)}
                          />{" "}
                          No
                        </div>
                      </div> */}
                      {vatType === 1 && (
                        <div className="form_group3">
                          <div className="colum_2_wrap">
                            <label>VAT Number</label>
                            <input
                              type="text"
                              name="vatNumber"
                              className="form_control2"
                              value={vatNumber}
                              onChange={this.inputHandler}
                              placeholder="Enter vat number"
                              autoComplete="off"
                            />
                            {formError.vatNumber !== "" ? (
                              <p className="error_text">
                                {formError.vatNumber}
                              </p>
                            ) : null}
                          </div>
                          <div className="colum_2_wrap">
                            <label>VAT Certificate Attachment</label>
                            <input
                              type="file"
                              onChange={this.handleChangeVat}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                  {radioStatus === 1 ? (
                    <>
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <label>Gender</label>
                          <Select
                            value={gender}
                            onChange={this.handleGenderChange}
                            options={genderOptions}
                            placeholder="Gender"
                          />
                          {/* <input type="text" name="gender" className={formError.gender !== '' ? "form_control2 error_border" : "form_control2"} value={gender} onChange={this.inputHandler} placeholder="Enter Gender" autoComplete="off" /> */}
                          {formError.gender !== "" ? (
                            <p className="error_text">{formError.gender}</p>
                          ) : null}
                        </div>
                        <div className="colum_2_wrap">
                          <label>Bussiness Email </label>
                          <input
                            type="text"
                            name="bussiness_email"
                            className="form_control2"
                            value={bussiness_email}
                            onChange={this.inputHandler}
                            placeholder="Enter Business Email"
                            autoComplete="off"
                          />
                          {/* <input type="text" name="gender" className={formError.gender !== '' ? "form_control2 error_border" : "form_control2"} value={gender} onChange={this.inputHandler} placeholder="Enter Gender" autoComplete="off" /> */}
                          {formError.bussiness_email !== "" ? (
                            <p className="error_text">
                              {formError.bussiness_email}
                            </p>
                          ) : null}
                        </div>
                        <div className="form_group3">
                          <div className="colum_2_wrap">
                            <label>Bussiness Email</label>
                            <input
                              type="radio"
                              name="bussiness_email_status"
                              checked={bussiness_email_status === 1}
                              onClick={(e) => {
                                this.setState({ bussiness_email_status: 1 });
                              }}
                            />{" "}
                            Public
                            <input
                              type="radio"
                              name="bussiness_email_status"
                              checked={bussiness_email_status === 2}
                              onClick={(e) => {
                                this.setState({ bussiness_email_status: 2 });
                              }}
                            />{" "}
                            Private
                          </div>
                        </div>
                      </div>

                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <input
                            type="radio"
                            name="release"
                            checked={mawthooqType === 1}
                            onClick={(e) => this.radioHandler(1)}
                          />{" "}
                          Upload Mawthooq Docs
                        </div>
                        <div className="colum_2_wrap">
                          <input
                            type="radio"
                            name="release"
                            checked={mawthooqType === 2}
                            onClick={(e) => this.radioHandler(2)}
                          />{" "}
                          Enter Mawthooq Number
                        </div>
                      </div>

                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          {mawthooqType === 1 && (
                            <input
                              type="file"
                              onChange={this.handleChangeMawthooq}
                            />
                          )}
                          {mawthooqType === 2 && (
                            <>
                              <input
                                type="text"
                                name="mawthooq_id"
                                className="form_control2"
                                value={mawthooq_id}
                                onChange={this.inputHandler}
                                placeholder="Enter mawthooq number"
                                autoComplete="off"
                              />
                              {formError.mawthooq_id !== "" ? (
                                <p className="error_text">
                                  {formError.mawthooq_id}
                                </p>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <Select
                            value={selectedCategory}
                            onChange={this.handleCategoryChange}
                            options={categories}
                            placeholder="Category"
                          />
                        </div>
                      </div>
                      <div className="form_group3">
                        <div className="colum_2_wrap">
                          <Select
                            isMulti={true}
                            value={selectedTags}
                            placeholder="Select Tags"
                            onChange={this.handleTagsChange}
                            options={tagsOption}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {radioStatus === 3 ? (
                    <div className="radio_wrap2">
                      <input
                        type="radio"
                        name="adminFunc"
                        id="allFunc"
                        checked={adminstatus === 3}
                        onChange={() => this.adminRadioChange(3)}
                      />
                      <label htmlFor="allFunc">All Functionality</label>
                      <input
                        type="radio"
                        name="adminFunc"
                        id="seo"
                        checked={adminstatus === 6}
                        onChange={() => this.adminRadioChange(6)}
                      />
                      <label htmlFor="seo">SEO</label>
                      <input
                        type="radio"
                        name="adminFunc"
                        id="support"
                        checked={adminstatus === 7}
                        onChange={() => this.adminRadioChange(7)}
                      />
                      <label htmlFor="support">Support</label>
                      <input
                        type="radio"
                        name="adminFunc"
                        id="userinfo"
                        checked={adminstatus === 4}
                        onChange={() => this.adminRadioChange(4)}
                      />
                      <label htmlFor="userinfo">No User Info</label>
                      <input
                        type="radio"
                        name="adminFunc"  
                        id="reviewOnly"
                        checked={adminstatus === 5}
                        onChange={() => this.adminRadioChange(5)}
                      />
                      <label htmlFor="reviewOnly">Reviews only</label>
                    </div>
                  ) : null}
                </div>
                <div className="bg_submit2">
                  <button
                    type="submit"
                    className="btn-submit"
                    disabled={disabled || isLoader}
                  >
                    {isLoader ? (
                      <img
                        src={LoaderImg}
                        className="small-loader"
                        alt="loader"
                      />
                    ) : (
                      "Add User"
                    )}
                  </button>
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userModal: state.users.userModal,
});

const mapDispatchToProps = (dispatch) => ({
  handleClose: () => dispatch({ type: ACTION_TYPES.TOGGLE_USER_MODAL }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserModal)
);
