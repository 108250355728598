import * as ACTION_TYPES from '../actions/types';

const initialState = {
    modalNoti:false,
    isSendAll:false
}

const notificationReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.TOGGLE_NOTIFICATION_MODAL:
            return{
                ...state,
                modalNoti:!state.modalNoti,
                isSendAll:action.payload,
            }
        case ACTION_TYPES.CLOSED_NOTIFICATION_MODAL:
            return{
                ...state,
                modalNoti:false
            }
        default:
            return state;
    }
}

export default notificationReducers;