import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderImg from "../../assets/images/user-loader.gif";
import { apiHalper } from "../../helpers/ApiHelper";

const CategoriModal = ({
  isCategoryModal,
  closeModal,
  isAdd,
  getPage,
  setIsAdd,
  updateData,
  pageNumber,
  categoryList,
  setCategoryList,
  setUpdateData
}) => {
  const [isSLoader, setIsSLoader] = useState(false);
  const [categoryData, setCategoryData] = useState({
    name: "",
    name_ar: "",
    status: 1,

  });

  useEffect(() => {
    setCategoryData({
      name: updateData?.name,
      name_ar: updateData?.name_ar,
      status: updateData?.status === "undefined" ? "" : updateData?.status,
    });
  }, [updateData]);

  const handleSubmit = async () => {
    setIsSLoader(true);
    const formData = new FormData();
    formData.append("name", categoryData.name);
    formData.append("name_ar", categoryData.name_ar);
    categoryData.status === 1 || categoryData.status === 0 && formData.append("status", categoryData.status);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    try {
      const res1 = await apiHalper("addServiceCategory", "POST", formData, headers);
      if (res1.data.status === true) {
        setIsSLoader(false);
        setIsAdd(!isAdd);
        setCategoryData({
          name: "",
          name_ar: "",
          status: "",
        });
        toast.success("Category Add Successfully!");
        getPage(pageNumber);
        closeModal();
        setUpdateData()
      } else {
        toast.error(res1.data.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const handleUpdate = async () => {
    setIsSLoader(true);
    const formData = new FormData();
    formData.append("name", categoryData.name);
    formData.append("name_ar", categoryData.name_ar);
    formData.append("id", updateData.id);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };

    const res1 = await apiHalper("editServiceCategory", "PATCH", { name: categoryData?.name, id: updateData?.id, name_ar: categoryData?.name_ar }, headers);
    if (res1.data.status === true) {
      const NewData = categoryList.map((data) => {
        if (data.id === updateData.id) {
          data.name = categoryData.name;
          data.name_ar = categoryData.name_ar;
        }
        return data;
      })
      setCategoryList(NewData)
      setIsSLoader(false);
      toast.success("Category Edit Successfully!");
      setCategoryData({
        name: "",
        name_ar: "",
        status: "",
      });
      closeModal();
      setUpdateData()
    } else {
      toast.error("Category Company  Failed");
    }
  };


  return (
    <>
      <Modal
        show={isCategoryModal}
        className="modal_design"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                Category details{" "}
                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                    setCategoryData({
                      name: "",
                      name_ar: "",
                      status: "",
                    })
                    setUpdateData()
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals body_modals2">
              <form>
                <div className="form_body">
                  <div className="form_group2">
                    <label>Category Name</label>
                    <input
                      type="text"
                      name="name"
                      className={"form_control2"}
                      value={categoryData.name}
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          name: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2">
                    <label>Category Name Ar</label>
                    <input
                      type="text"
                      name="name_ar"
                      className={"form_control2"}
                      value={categoryData.name_ar}
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          name_ar: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>




                </div>
                <div className="bg_submit2 mr-top">
                  {isAdd ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={
                        categoryData.name &&
                          categoryData.name_ar
                          ? false
                          : true
                      }
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleUpdate();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={isSLoader}
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => {
                        setCategoryData({
                          name: "",
                          name_ar: "",
                          status: "",
                        });
                        closeModal();
                        setUpdateData()
                      }}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CategoriModal;
