import * as ACTION_TYPES from '../actions/types';

const initialState = {
    isImageView:false,
    image_data:null,
    accountModal:false,
    withdrawModal:false,
    withdrawData: false
}

const balanceReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.TOGGLE_IMAGE_VIEWER:
            return{
                ...state,
                isImageView:!state.isImageView,
                image_data:action.payload
            }
        case ACTION_TYPES.TOGGLE_WITHDRAW_MODAL:
            return{
                ...state,
                withdrawModal:!state.withdrawModal,
                withdrawData: action.payload
            }
        case ACTION_TYPES.TOGGLE_ADD_ACCOUNT_MODAL:
            return{
                ...state,
                withdrawModal:!state.withdrawModal,
                accountModal:!state.accountModal
            }
        default:
            return state;
    }
}

export default balanceReducers;