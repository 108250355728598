import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as ACTION_TYPES from '../../redux/actions/types';
import * as TimeHelper from '../../helpers/TimeDiff';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class FilterModal2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            years: [],
            months: [],
            startDate: null,
            endDate: null,
            manFilterVal: null,
            FilterValue: null,
            type: null,
            isDdate: false,
            isMdate: false,
            isYdate: false
        }
    }

    componentDidMount() {
        this.getPrev3Years();
        this.getPrev3Months();
        this.setState({
            startDate: new Date(),
            endDate: new Date()
        });
        this.defaultLoad();
    }

    componentDidUpdate(prevProps) {
        const { isFilterModal } = this.props;
        if (isFilterModal !== prevProps.isFilterModal) {
            this.defaultLoad();
        }
    }

    defaultLoad = () => {
        const { isDdate, isMdate, isYdate, d_start_date, d_end_date, m_start_date, m_end_date, y_start_date, y_end_date, filterSelect, filterD, dayValue, filterM, monthValue, filterY, yearValue, firstFilterHandler, secFilterHandler, thirdFilterHandler } = this.props;
        switch (filterSelect) {
            case 0:
                if (!isDdate) {
                    this.setState({
                        manFilterVal: filterD,
                        FilterValue: dayValue,
                        type: null
                    });
                } else {
                    this.setState({
                        startDate: d_start_date,
                        endDate: d_end_date
                    })
                }
                firstFilterHandler(filterD, dayValue, this.state.type, this.state.isDdate, this.state.startDate, this.state.endDate);
                break;
            case 1:
                if (!isMdate) {
                    this.setState({
                        manFilterVal: filterM,
                        FilterValue: monthValue,
                        type: null
                    });
                } else {
                    this.setState({
                        startDate: m_start_date,
                        endDate: m_end_date
                    })
                }
                secFilterHandler(filterM, monthValue, this.state.type, this.state.isMdate, this.state.startDate, this.state.endDate);
                break;
            case 2:
                if (!isYdate) {
                    this.setState({
                        manFilterVal: filterY,
                        FilterValue: yearValue,
                        type: null
                    });
                } else {
                    this.setState({
                        startDate: y_start_date,
                        endDate: y_end_date
                    })
                }
                thirdFilterHandler(filterY, yearValue, this.state.type, this.state.isYdate, this.state.startDate, this.state.endDate);
                break;
            default:
                this.setState({ manFilterVal: null, FilterValue: null, type: null });
                break;
        }
    }

    handleFilManual = (data) => {
        const { filterSelect } = this.props;
        switch (filterSelect) {
            case 0:
                this.setState({ isDdate: false });
                break;
            case 1:
                this.setState({ isMdate: false });
                break;
            case 2:
                this.setState({ isYdate: false });
                break;
            default:
                break;
        }
        this.setState({
            manFilterVal: data.label ? data.label : data,
            FilterValue: data.value,
            type: data.type,
            startDate: new Date(),
            endDate: new Date()
        });
    }

    handleLifeTime = () => {        
        this.setState({
            manFilterVal: 'Life Time',
            FilterValue: 1,
            type: 'life'
        });
    }

    applyFilter = async () => {
        const { isDdate, isMdate, isYdate, manFilterVal, FilterValue, type, startDate, endDate } = this.state;

        const { filterSelect, firstFilterHandler, secFilterHandler, thirdFilterHandler, handleClose, applyFilterFun } = this.props;
        
        switch (filterSelect) {
            case 0:
                firstFilterHandler(manFilterVal, FilterValue, type, isDdate, startDate, endDate);
                break;
            case 1:
                secFilterHandler(manFilterVal, FilterValue, type, isMdate, startDate, endDate);
                break;
            case 2:
                thirdFilterHandler(manFilterVal, FilterValue, type, isYdate, startDate, endDate);
                break;
            default:
                this.setState({ manFilterVal: 'Life Time', FilterValue: 1, type: 'life', startDate: null, endDate: null });
                break;
        }
        applyFilterFun(true);

        // try {
        //     this.setState({
        //         isLoader: true,
        //     })
        //     const headers = {
        //         'Authorization': localStorage.getItem('token'),
        //         'Access-Control-Allow-Origin': '*'
        //     }

        //     let formData = new FormData();
        //     formData.append('type', type ? type : 'life')
        //     formData.append('value', FilterValue ? FilterValue : 1)

        //     const response = await apiHalper('getReports', 'POST', formData, headers);
        //     const res = response.data;
        //     console.log(res.status)
        //     if (res.status) {
        //         //const data = jwtDecode(res.data);

        //         this.setState({
        //             isLoader: false
        //         })
        //     }
        //     else {
        //         this.setState({
        //             isLoader: false
        //         });
        //     }
        // } catch (err) {
        //     console.log(err);
        //     this.setState({
        //         messageApi: err.message
        //     })
        // }

        handleClose();
    }

    getPrev3Months = () => {
        const current = new Date();
        const n = 3;
        let newArray = [];
        let d;
        // let month = '';
        for (let i = n; i >= 1; i--) {
            d = new Date(current.getFullYear(), current.getMonth() - i, 1);
            // month = TimeHelper.month_name(d.getMonth());
            newArray.push({
                label: TimeHelper.month_name(d.getMonth()),
                value: (d.getMonth() + 1),
                type: 'month'
            });
        }

        newArray.push({
            label: TimeHelper.month_name(current.getMonth()),
            value: current.getMonth() + 1,
            type: 'month'
        });
        this.setState({ months: newArray });
    }

    getPrev3Years = () => {
        const current = new Date();
        const n = 3;
        let newArray = [];
        for (let i = n; i >= 1; i--) {
            const year = current.getFullYear();
            newArray.push({
                label: (year - i),
                value: year - i,
                type: 'year'
            });
        }
        newArray.push({
            label: current.getFullYear(),
            value: current.getFullYear(),
            type: 'year'
        });

        this.setState({ years: newArray });
    }

    renderYears = (data, i) => {
        const { manFilterVal } = this.state;
        return <span key={i} className="divide_4">
            <input type="radio" name="filter_report" id={"filter" + data.label} checked={data.label === manFilterVal} value={data.value} onChange={() => this.handleFilManual(data)} />
            <label htmlFor={"filter" + data.label}>{data.label}</label>
        </span>
    }

    renderQuarter = (data, i) => {
        const { manFilterVal } = this.state;
        return <span key={i} className="divide_4">
            <input type="radio" name="filter_report" id={"quarter_" + data.label} checked={data.label === manFilterVal} onChange={() => this.handleFilManual(data)} />
            <label htmlFor={"quarter_" + data.label}>{data.label}</label>
        </span>
    }

    renderMonths = (data, i) => {
        const { manFilterVal } = this.state;
        return <span key={i} className="divide_4">
            <input type="radio" name="filter_report" id={"month_" + data.label} checked={data.label === manFilterVal} value={data.value} onChange={() => this.handleFilManual(data)} />
            <label htmlFor={"month_" + data.label}>{data.label}</label>
        </span>
    }

    renderDays = (data, i) => {
        const { manFilterVal } = this.state;
        return <span key={i} className="divide_4">
            <input type="radio" name="filter_report" id={"month_" + data.label} checked={data.label === manFilterVal} onChange={() => this.handleFilManual(data)} />
            <label htmlFor={"month_" + data.label}>{data.label}</label>
        </span>
    }

    handleStartdate = (date) => {
        const { filterSelect } = this.props;
        switch (filterSelect) {
            case 0:
                this.setState({ isDdate: true });
                break;
            case 1:
                this.setState({ isMdate: true });
                break;
            case 2:
                this.setState({ isYdate: true });
                break;
            default:
                break;
        }

        this.setState({
            startDate: date,
            manFilterVal: null,
            FilterValue: null,
            type: 'date'
        });

    }

    handleEnddate = (date) => {
        const { filterSelect } = this.props;
        switch (filterSelect) {
            case 0:
                this.setState({ isDdate: true });
                break;
            case 1:
                this.setState({ isMdate: true });
                break;
            case 2:
                this.setState({ isYdate: true });
                break;
            default:
                break;
        }
        this.setState({
            endDate: date,
            manFilterVal: null,
            FilterValue: null,
            type: 'date'
        });
    }

    render() {

        const quarter = [
            { label: 'Q1', value: 'Q1', type: 'quarter' },
            { label: 'Q2', value: 'Q2', type: 'quarter' },
            { label: 'Q3', value: 'Q3', type: 'quarter' },
            { label: 'Q4', value: 'Q4', type: 'quarter' }
        ]
        const day_array = [
            { label: '15 Days', value: 15, type: 'day' },
            { label: '7 Days', value: 7, type: 'day' },
            { label: 'YEST', value: 1, type: 'day' },
            { label: 'Today', value: 0, type: 'day' }
        ]

        const { isFilterModal, handleClose } = this.props;
        const { years, months, startDate, endDate, manFilterVal } = this.state;
        // centered
        return (
            <Modal show={isFilterModal} onHide={handleClose} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Date Range <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <div className="filter_block">
                                <div className="manual_range_wrap">
                                    <span className="fullWidth">
                                        <input type="radio" name="filter_report" id="lifeTime" checked={'Life Time' === manFilterVal} onChange={this.handleLifeTime} />
                                        <label htmlFor="lifeTime">Life Time</label>
                                    </span>
                                    {years.length > 0 ?
                                        years.map((label, i) => this.renderYears(label, i))
                                        :
                                        null
                                    }
                                    {quarter.length > 0 ?
                                        quarter.map((data, i) => this.renderQuarter(data, i))
                                        :
                                        null
                                    }
                                    {months.length > 0 ?
                                        months.map((data, i) => this.renderMonths(data, i))
                                        :
                                        null
                                    }
                                    {day_array.length > 0 ?
                                        day_array.map((data, i) => this.renderDays(data, i))
                                        :
                                        null
                                    }

                                </div>
                                <div className="date_range_wrap">
                                    <DatePicker selected={new Date(startDate)} selectsStart maxDate={new Date()} startDate={new Date(startDate)} endDate={new Date(endDate)} onChange={this.handleStartdate} dateFormat="MMM dd, yyyy" className="form_control2" />
                                    {/* <DateRangePicker initialSettings={{singleDatePicker:true, maxDate:new Date(), startDate:startDate}}  onApply={this.handleStartdate}><input type="button" disabled /></DateRangePicker> */}
                                    <span>To</span>
                                    <DatePicker selected={new Date(endDate)} selectsEnd minDate={new Date(startDate)} maxDate={new Date()} startDate={new Date(startDate)} endDate={new Date(endDate)} onChange={this.handleEnddate} dateFormat="MMM dd, yyyy" className="form_control2" />
                                    {/* <DateRangePicker initialSettings={{singleDatePicker:true, maxDate:new Date(), endDate:endDate}} onApply={this.handleEnddate}><input type="button" disabled /></DateRangePicker> */}
                                </div>
                                <div className="btn_groups">
                                    <button type="button" className="btn-cancel" onClick={handleClose}>Cancel</button>
                                    <button type="button" className="btn-apply" onClick={this.applyFilter}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    isFilterModal: state.reports.isFilterModal,
    filterSelect: state.reports.filterSelect,
    filterD: state.reports.filterD,
    filterM: state.reports.filterM,
    filterY: state.reports.filterY,
    dayValue: state.reports.dayValue,
    monthValue: state.reports.monthValue,
    isDate: state.reports.isDate,
    d_start_date: state.reports.d_start_date,
    d_end_date: state.reports.d_end_date,
    m_start_date: state.reports.m_start_date,
    m_end_date: state.reports.m_end_date,
    y_start_date: state.reports.y_start_date,
    y_end_date: state.reports.y_end_date,
});

const mapDispatchToProps = dispatch => ({
    handleClose: () => dispatch({ type: ACTION_TYPES.CLOSE_FILTER_REPORT_MODAL }),
    firstFilterHandler: (label, value, type, isDate, start, end) => dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_REPORT_FIRST_VAL, payload: { label, value, type, isDate, start, end } }),
    secFilterHandler: (label, value, type, isDate, start, end) => dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_REPORT_SECOND_VAL, payload: { label, value, type, isDate, start, end } }),
    thirdFilterHandler: (label, value, type, isDate, start, end) => dispatch({ type: ACTION_TYPES.TOGGLE_FILTER_REPORT_THIRD_VAL, payload: { label, value, type, isDate, start, end } }),
    applyFilterFun: (val) => dispatch({ type: ACTION_TYPES.IS_APPLY_FILTER, payload: val })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterModal2));
