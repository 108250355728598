import React, { Component } from 'react';
import CKEditor from 'ckeditor4-react';

class CkEditorComponent extends Component {

  render() {

    const { data, changeEvent, rtl, id, name } = this.props;
    return (
      rtl ?
        <CKEditor
          id={id}
          config={{
            extraPlugins: "colorbutton,font,justify,print,tableresize,liststyle",
            contentsLangDirection: 'rtl'
          }}
          data={data}
          onChange={changeEvent}
          name={name}
        />
        :
        <CKEditor
          id={id}
          config={{
            extraPlugins: "colorbutton,font,justify,print,tableresize,liststyle",
          }}
          data={data}
          onChange={changeEvent}
          name={name}
        />
    )

  }
}

export default CkEditorComponent;