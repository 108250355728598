import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderImg from "../../assets/images/user-loader.gif";
import { jwtDecode } from "../../helpers/jwt_helper";
import { apiHalper } from "../../helpers/ApiHelper";
import { getAllCompany } from "../Companies/CompaniApiHelper";
import Select from "react-select";
import ConfirmModal from "../ConfirmModal";

const RequestCompanyModal = ({
  isRequestModal,
  closeModal,
  approveData,
  getPage,
  //   getList,
  //   setIsAdd,
  //   updateData,
  //   pageNumber,
}) => {
  const [socialTypes, setSocialTypes] = useState();
  const [isSLoader, setIsSLoader] = useState(false);
  const [isUserProModal, setIsUserProModal] = useState(false);
  const [inputData, setInputData] = useState({
    social_platform_type_id: "",
    id: "",
    name: "",
    name_ar: "",
    filename: "",
    filename_pic: "",
    filename_gray: "",
    filename_gray_pic: "",
    web_url: "",
  });

  useEffect(() => {
    console.log(approveData)
    setInputData({ ...inputData,
       id: approveData?.id ,
       name: approveData?.name,
       name_ar: approveData?.name_ar,
       filename: approveData?.filename,
       filename_pic: "",
       filename_gray: approveData?.filename,
       web_url: approveData?.link,
      
      });
  }, [approveData]);

  const url = jwtDecode(localStorage.getItem("filePath"));


  const handleSubmit = async () => {
    console.log(inputData, "");

    const formData = new FormData();

    // formData.append(
    //   "social_platform_type_id",
    //   inputData.social_platform_type_id
    // );
    formData.append("name", inputData.name);
    formData.append("name_ar", inputData.name_ar);
    formData.append("image", inputData.filename ? inputData.filename : inputData.filename_gray);
    // formData.append("filename_gray", inputData.filename_gray);
    formData.append("link", inputData.web_url);
    formData.append("id", inputData.id);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    const res1 = await apiHalper(
      "approveCompanyRequest",
      "POST",
      formData,
      headers
    );
    if (res1.data.status === true) {
      setIsUserProModal(false)
      getPage(1);
      closeModal();
      toast.success("Approve Request Successfully!");
    } else {
      setIsUserProModal(false)
      toast.error("Approve Request  Failed");
    }
  };



  const modalCloseConfirm = () => {
    setIsUserProModal(false);
  };

    // const confirmModalData = {
    //   isOpen: isUserProModal,
    //   modalClose: modalCloseConfirm,
    //   text: "Approve user details?",
    //   confirmHandler: handleSubmit,
    // };


  return (
    <>
      <Modal
        show={isRequestModal}
        className="modal_design"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                Details{" "}
                <button
                  type="button"
                  onClick={() => { 
                    closeModal();
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals body_modals2">
              <div>
                <div className="form_body">
                  <div className="form_group2">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className={"form_control2"}
                      value={inputData.name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          name: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2">
                    <label>Name Ar</label>    
                    <input
                      type="text"
                      name="name_ar"
                      className={"form_control2"}
                      value={inputData.name_ar}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          name_ar: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  {/* <div className="form_group2">
                    <label>Social Type</label>
                    <Select
                      placeholder={"Select Social Type"}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          social_platform_type_id: e.value,
                        });
                      }}
                      options={socialTypes}
                    />
                  </div> */}
                  <div className="form_group2 " style={{ display: "grid" }}>
                    <label>Image</label>
                    <input
                      type="file"
                      name="file"
                      autoComplete="off"
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          filename_gray:"",
                          filename: e.target.files[0],
                          filename_pic: URL.createObjectURL(e.target.files[0]),
                        });
                      }}
                    />
                    {
                      inputData?.filename_gray ?

                    <img
                      src={`${url.company}${inputData?.filename_gray}`}
                      style={{ width: "60px" }}
                    />
                    :

                    <img
                      src={inputData?.filename_pic}
                      style={{ width: "50px" }}
                    />
                    }
                  </div>
                  {/* <div className="form_group2 " style={{ display: "grid" }}>
                    <label>Image Gray</label>
                    <input
                      type="file"
                      name="file"
                      autoComplete="off"
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          filename_gray: e.target.files[0],
                          filename_gray_pic: URL.createObjectURL(
                            e.target.files[0]
                          ),
                        });
                      }}
                    />
                    <img
                      src={inputData?.filename_gray_pic}
                      style={{ width: "50px" }}
                    />
                  </div> */}
                  <div className="form_group2">
                    <label>Link</label>
                    <input
                      type="text"
                      name="webUrl"
                      className={"form_control2"}
                      value={inputData.web_url}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          web_url: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="bg_submit2 mr-top">
                  <button
                    onClick={() => {
                      handleSubmit();
                      // setIsUserProModal(true)
                    }}
                    type="submit"
                    className="btn-submit"
                    disabled={
                      inputData.name &&
                      inputData.name_ar &&
                      inputData.filename &&
                      // inputData.filename_gray &&
                      // inputData.social_platform_type_id &&
                      inputData.web_url
                        ? false
                        : true
                    }
                  >
                    {isSLoader ? (
                      <img
                        src={LoaderImg}
                        className="small-loader"
                        alt="loader"
                      />
                    ) : (
                      "Save"
                    )}
                  </button>

                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>

      {/* <ConfirmModal modalData={confirmModalData} /> */}
    </>
  );
};

export default RequestCompanyModal;
