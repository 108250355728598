import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import CkEditorComponent from '../../CkEditorComponent';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper'
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
import * as ACTION_TYPES from '../../../redux/actions/types';

class EditPageContentMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insertID: null,
            isFaqsTab: false,
            selectedOption: { label: 'Default 0', value: 0 },
            isClearable: null,
            isLoader: false,
            pageName: '',
            paFileName: '',
            pageHeading: '',
            pageTitle: '',
            pageContent: '',
            pageShortDesc: '',
            metaTitle: '',
            metaKeyword: '',
            metaDescription: '',
            faqTitle: '',
            faqArray: [{ qa: '', ans: '', qaErr: '', ansErr: '' }],
            formError: {
                pageName: '',
                paFileName: '',
                pageHeading: '',
                faqTitle:'',
                pageTitle: '',
                pageContent: '',
                pageShortDesc: '',
                metaTitle: '',
                metaKeyword: '',
                metaDescription: '',
            },
            formValid: {
                pageName: false,
                paFileName: false,
                pageHeading: false,
                pageTitle: false,
                faqTitle:false,
                pageContent: false,
                pageShortDesc: false,
                metaTitle: false,
                metaKeyword: false,
                metaDescription: false,
            },
            isEdit: false
        }
    }

    getPageContent = async (id, value) => {
        const headers = {
            'Authorization': localStorage.getItem('token')
        }
        const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
        let formData = new FormData()
        formData.append('serviceCategoryID', id)
        formData.append('parentPath', parentPath)
        const response = await apiHalper('getPageContents', 'POST', formData, headers)

        const res = response.data;
        if (!res.status) {
            this.setState({
                apiErr: res.message,
                isLoader: false
            })
            toster_error(res.message);
            return true
        }        
        const decode = jwtDecode(res.data)
        const { page_data, faq_data } = decode;
       
        const { pageContent, pageFileName, pageHeading, pageShortDescription, pageName, faqTitle, pageTitle, pageMetaKeywords, pageMetaDescription } = page_data[0];
                
        if( page_data.length > 0 ) {
            this.setState({
                pageName: pageName,
                paFileName: pageFileName,
                pageHeading: pageHeading,
                pageShortDesc: pageShortDescription !== null ? pageShortDescription : "",
                metaTitle: pageTitle,
                metaKeyword: pageMetaKeywords,
                metaDescription: pageMetaDescription,
                formError: {
                    pageName: '',
                    paFileName: '',
                    pageHeading: '',
                    pageShortDesc: '',
                    faqTitle:'',
                    pageTitle: '',
                    metaTitle: '',
                    metaKeyword: '',
                    metaDescription: ''
                },
                formValid: {
                    pageName: true,
                    paFileName: true,
                    pageHeading: true,
                    pageTitle: true,
                    pageShortDesc: true,
                    faqTitle: true,
                    metaTitle: true,
                    metaKeyword: true,
                    metaDescription: true
                },
                pageContent: pageContent,
            })
        }
        if(faq_data.length > 0) {
            const faqList = faq_data.map(({question, answer})=> ({qa:question, ans:answer, qaErr:question !== ''?'':'Field is required', ansErr:answer !== ''?'':'Field is required'}));
            this.setState({            
                faqTitle: faqTitle,
                faqArray: faqList,
                formError: {
                    faqTitle: '',
                    pageName: '',
                    paFileName: '',
                    pageHeading: '',  
                    pageShortDesc: '',           
                    pageTitle: '',
                    metaTitle: '',
                    metaKeyword: '',
                    metaDescription: '',
                }
            })
        }
    }

    componentDidMount() {
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        const value = urlParams.get('value');
        this.setState({
            insertID: myParam
        })
        
        if(myParam === ''){
            this.props.history.push('/seo/menu')
            return true
        }
        this.getPageContent(myParam, value)
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        // this.getClientApi(this.state.page, this.state.search);
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable, selectedOption: null }));
        // this.getClientApi(this.state.page, this.state.search);
    }

    pageContenHandler = (event) => {
        const data = event.editor.getData();        
        const { formError } = this.state;        
        this.setState({
            pageContent:data,
            formError: {
                ...formError,
                pageContent: ''
            }
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;

        switch (name) {
            case 'pageName':
                valid.pageName = value.length > 0;
                error.pageName = valid.pageName ? '' : 'Page Name is required.';
                break;
            case 'paFileName':
                valid.paFileName = value.length > 0;
                error.paFileName = valid.paFileName ? '' : 'Page File Name is required.';
                break;
            case 'pageHeading':
                valid.pageHeading = value.length > 0;
                error.pageHeading = valid.pageHeading ? '' : 'Page Heading is required.';
                break;
            case 'pageShortDesc':
                valid.pageShortDesc = value.length > 0 && !/^\s*$/.test(value);
                error.pageShortDesc = valid.pageShortDesc ? '' : 'Page Short Description is required.';
                break;
            case 'pageTitle':
                valid.pageTitle = value.length > 0;
                error.pageTitle = valid.pageTitle ? '' : 'Page Title is required.';
                break;
            case 'pageContent':
                valid.pageContent = value.length > 0;
                error.pageContent = valid.pageContent?'':'Enter value in Editor.';
                break;
            case 'faqTitle':
                valid.faqTitle = value.length > 0;
                error.faqTitle = valid.faqTitle ? '' : 'FAQ Title is required.';
                break; 
            case 'metaTitle':
                valid.metaTitle = value.length > 0;
                error.metaTitle = valid.metaTitle ? '' : 'Meta Title is required';
                break;           
            case 'metaKeyword':
                valid.metaKeyword = value.length > 0;
                error.metaKeyword = valid.metaKeyword ? '' : 'Meta Keyword is required.';
                break;
            case 'metaDescription':
                valid.metaDescription = value.length > 0;
                error.metaDescription = valid.metaDescription ? '' : 'Meta Description is required.';
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    submitContent = async (e) => {
        e.preventDefault();
        
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const { insertID, pageName, paFileName,pageShortDesc, pageContent, pageHeading } = this.state;
            //const { editData } = this.props;

            let formData = new FormData();
            //formData.append('serviceCategoryID', editData.id);
            formData.append('serviceCategoryID',insertID )
            formData.append('pageName', pageName)
            formData.append('pageFileName', paFileName)   
            formData.append('pageContent', pageContent)
            formData.append('pageHeading', pageHeading)
            formData.append('pageShortDescription', pageShortDesc)
            const search = this.props.location.search
            const urlParams = new URLSearchParams(search);
            const parentPath = urlParams.get('parentPath');
            formData.append('parentPath', parentPath)
         
            const response = await apiHalper('editPages', 'POST', formData, headers)
            const res = response.data;
            
            if (res.status === true) {               
                this.setState({
                    isLoader: false,
                });
                e.preventDefault();
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isLoader: false,
                });
                toster_error(res.message);
            }
        } catch (err) {
            console.log(err);
        }
    }
    submitFAQContent = async (e) => {
        e.preventDefault();
        const { faqTitle, formError } = this.state;
        const list = [...this.state.faqArray];
        let index = null
        for (let i = 0; i < list.length; i++) {
            if (list[i].qa !== '' ) {
                list[i].qaErr = ''
            }
            else {
                list[i].qaErr = 'Question is Required'
                index= i
            }
            if (list[i].ans !== '' ) {
                list[i].ansErr = ''
            }
            else {
                list[i].ansErr = 'Answer is Required'
                index= i
            }
        }
        this.setState({
            faqArray: list
        });

        if (faqTitle === '' && index !== null) {            
            this.setState({
                faqArray: list,
                formError: {
                    ...formError,
                    faqTitle: 'Faq title is required'
                }
            })
        }
        else if (faqTitle === '') {
            this.setState({
                formError: {
                    ...formError,
                    faqTitle: 'Faq title is required'
                }
            })
        }
        else if ( index !== null){
            this.setState({
                faqArray: list
            })
        }
        else {
            try {
                this.setState({
                    isLoader: true
                });    
    
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
    
                const { insertID, faqTitle, faqArray } = this.state
                const faqArr = faqArray.map(info => { return { "question": info.qa, "answer": info.ans }})
                
                let formData = new FormData()
                formData.append('serviceCategoryID', insertID)
                formData.append('faqTitle', faqTitle)
                formData.append('faqContents', JSON.stringify(faqArr))
                const search = this.props.location.search
                const urlParams = new URLSearchParams(search);
                const parentPath = urlParams.get('parentPath');
                formData.append('parentPath', parentPath)
                const response = await apiHalper('addFAQs', 'POST', formData, headers)
    
                const res = response.data;
                if (!res.status) {
                    this.setState({
                        apiErr: res.message,
                        isLoader: false
                    })
                    toster_error(res.message);
                    return true
                }
                toster_sucess(res.message);
                this.setState({
                    isLoader: false,
                    formError: {
                        ...formError,
                        faqTitle: '',
                        faqArray: [{
                            qa: '',
                            ans: '',
                            qaErr: ''
                        }]
                    }
                })
                
            } catch (err) {
                console.log(err);
            }            
        }
    }
    submitMetaContent = async (e) => {
        e.preventDefault();
        const { metaTitle, metaKeyword, formError, metaDescription } = this.state;        
        if(metaTitle==='' && metaKeyword==='' && metaDescription==='' ) {
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: 'Meta Title is required',
                    metaKeyword: 'Meta Keyword is required',
                    metaDescription: 'Meta Description is required',
                }
            })
        }
        else if (metaTitle==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: 'Meta Title is required'
                }
            })
        }
        else if (metaKeyword==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaKeyword: 'Meta Keyword is required',
                }
            })
        }
        else if (metaDescription==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaDescription: 'Meta Description is required',
                }
            })
        }
        else {
            this.setState({
                isLoader: true
            })
            const headers = { 'Authorization': localStorage.getItem('token') }
            const { insertID, metaTitle, metaKeyword, metaDescription } = this.state;
            const search = this.props.location.search
            const urlParams = new URLSearchParams(search);
            const parentPath = urlParams.get('parentPath');
            const to = urlParams.get('to');
            let formData = new FormData()
                formData.append('serviceCategoryID', insertID)
                // formData.append('pageName', pageName )
                // formData.append('pageFileName',pageFileName )
                formData.append('pageTitle', metaTitle)
                formData.append('pageMetaKeywords', metaKeyword)
                formData.append('pageMetaDescription', metaDescription)
                formData.append('parentPath', parentPath)

            const response = await apiHalper('addMetaTags', 'POST', formData, headers)
            const res = response.data;

            if(!res.status){
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                toster_error(res.message)
                return true
            }

            const { history } = this.props;
            toster_sucess(res.message)
            history.push(`/seo/${to}`)
         
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: '',
                    metaKeyword: '',
                    metaDescription: '',
                }
            })
        }
    }

    editDataFun = () => {
        const { editStore } = this.props;
        editStore(null);
    }

    tabFaqToggle = () => {
        this.setState({
            isFaqsTab: !this.state.isFaqsTab
        });
    }

    faqHandler = (e, i) => {
        const { name, value } = e.target;
        const list = [...this.state.faqArray];
        list[i][name] = value;
        if (list[i].qa !== '') {
            list[i].qaErr = ''            
        }
        else {
            list[i].qaErr = 'Question is Required'
        }
        
        if (list[i].ans !== '' ) {
            list[i].ansErr = ''
        }
        else {
            list[i].ansErr = 'Answer is Required'
        }
        
        this.setState({
            faqArray: list            
        })
    }

    addMoreFAQ = () => {
        this.setState({
            faqArray: [...this.state.faqArray, { qa: '', ans: '' }]
        })
    }

    removeMoreFAQ = (index) => {
        const list = [...this.state.faqArray];
        list.splice(index, 1);
        this.setState({
            faqArray: list
        });
    }

    render() {
       
        const { isLoader, pageName, paFileName, metaTitle, metaKeyword, metaDescription, faqTitle, faqArray, formError, formValid, pageContent, pageShortDesc, pageHeading } = this.state;
        const disabled = !formValid.pageName || !formValid.paFileName || !formValid.pageHeading;
        const { location } = this.props;
        const prev = new URLSearchParams(location.search).get('prev');
        

        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap">
                        <div className="max-Block contents">
                            <div className="tabNav2">
                             <Link to={`/seo/${prev}`} className="btn-back" onClick={this.editDataFun}>Back</Link> 
                                {/* <button type="button" className={this.state.isFaqsTab?"tabBtn":"tabBtn active"} onClick={!this.state.isFaqsTab?null:this.tabFaqToggle}>Page Content</button>
                                <button type="button" className={this.state.isFaqsTab?"tabBtn active":"tabBtn"} onClick={!this.state.isFaqsTab?this.tabFaqToggle:null}>Page FAQ's</button> */}
                                <Tab.Container id="" defaultActiveKey="pgcontent">
                                    <Nav className="tabs">
                                        <Nav.Link eventKey="pgcontent">Page Content</Nav.Link>
                                        <Nav.Link eventKey="faq">Page FAQ's</Nav.Link>
                                        <Nav.Link eventKey="metatag">Meta Tag</Nav.Link>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="pgcontent">
                                            <form action="/" method="POST" onSubmit={this.submitContent}>
                                                <div className="tabContent">
                                                    <div className="div_3">                                                        
                                                        <div className="form_group">
                                                            <label>Page Name</label>
                                                            <input type="text" name="pageName" value={pageName} className={formError.pageName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Page Name" onChange={this.inputHandler} autoComplete="off" />
                                                            {formError.pageName !== '' ? <p className="error_text pos_a">{formError.pageName}</p> : null}
                                                        </div>
                                                        <div className="form_group">
                                                            <label>Page File Name</label>
                                                            <input type="text" name="paFileName" value={paFileName} className={formError.paFileName !== '' ? "form_control2 error_border" : "form_control2"} placeholder="File Name" onChange={this.inputHandler} autoComplete="off" />
                                                            {formError.paFileName !== '' ? <p className="error_text pos_a">{formError.paFileName}</p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="div_3">
                                                        <div className="form_group">
                                                            <label>Page Heading</label>
                                                            <input type="text" name="pageHeading" value={pageHeading} className={formError.pageHeading !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Page Heading" onChange={this.inputHandler} autoComplete="off" />
                                                            {formError.pageHeading !== '' ? <p className="error_text pos_a">{formError.pageHeading}</p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="div_3">
                                                            <div className="form_group">
                                                                <label>Page Short Description</label>
                                                                <input type="text" name="pageShortDesc" value={pageShortDesc} className={formError.pageShortDesc !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Page Short Description" onChange={this.inputHandler} autoComplete="off" />
                                                                {formError.pageShortDesc !== '' ? <p className="error_text pos_a">{formError.pageShortDesc}</p> : null}
                                                            </div>
                                                        </div>
                                                    <div className="div_3">
                                                        <div className="form_group">
                                                            <label>Page Content</label>
                                                            <CkEditorComponent data={pageContent} changeEvent={this.pageContenHandler} />
                                                            {formError.pageContent !== ''?<p className="error_text pos_a">{formError.pageContent}</p>:null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg_submit2 right-mx120">
                                                    <button type="submit" className="btn-submit" disabled={disabled || isLoader} >{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                                </div>
                                            </form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="faq">
                                            <form action="/" method="POST" onSubmit={this.submitFAQContent}>
                                                <div className="tabContent">
                                                    <div className="form_group">
                                                        <label>FAQ Title</label>
                                                        <input type="text" name="faqTitle" className={formError.faqTitle !== '' ? "form_control3 error_border" : "form_control3"} value={faqTitle} onChange={this.inputHandler} autoComplete="off" placeholder="Ex. Graphic Designers FAQ's" />
                                                        {formError.faqTitle !== '' ? <p className="error_text pos_a">{formError.faqTitle}</p> : null}
                                                    </div>
                                                    <div className={faqArray.length > 1 ? "more_wrap2 padR_15" : "more_wrap2"}>
                                                        {faqArray.map((info, i) =>
                                                            <div className={"more_block2"} key={i}>
                                                                <div className="form_group">
                                                                    <label>{i + 1}. Question</label>                                                                    
                                                                    <input type="text" name="qa" className={info.qaErr !== '' ? "form_control3 error_border" : "form_control3"} value={info.qa} onChange={(e) => this.faqHandler(e, i)} placeholder="Ex. What is Graphic Designers?" autoComplete="off" />
                                                                    {info.qaErr !== '' ? <p className="error_text pos_a">{info.qaErr}</p> : null}
                                                                </div>
                                                                <div className="form_group">
                                                                    <label>{i + 1}. Answer</label>
                                                                    <textarea className={info.ansErr !== '' ? "form_control3 textarea error_border" : "form_control3 textarea"} name="ans" value={info.ans} onChange={(e) => this.faqHandler(e, i)} placeholder="Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry."></textarea>
                                                                    {info.ansErr !== '' ? <p className="error_text pos_a">{info.ansErr}</p> : null}
                                                                </div>
                                                                {i > 0 ? <button type="button" className="btn_removeMore" onClick={() => this.removeMoreFAQ(i)}>Remove</button>: null }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button type="button" className="btn_addMore" onClick={this.addMoreFAQ}>Add More</button>
                                                </div>
                                                <div className="bg_submit2 right-mx120 two-button">
                                                    <Link to="/seo/add-metatag" onClick={this.SkipSubmit} className="btn-submit">Skip</Link>
                                                    <button type="submit" className="btn-submit" >{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                                </div>
                                            </form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="metatag">
                                            <form action="/" method="POST" onSubmit={this.submitMetaContent}>
                                                <div className="tabContent">
                                                    <div className="div_3">
                                                        <div className="form_group">
                                                            <label>Meta Title</label>
                                                            <input type="text" name="metaTitle" value={metaTitle} className={formError.metaTitle !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Meta title" onChange={this.inputHandler} autoComplete="off" />
                                                            {formError.metaTitle !== '' ? <p className="error_text pos_a">{formError.metaTitle}</p> : null}
                                                        </div>
                                                        <div className="form_group">
                                                            <label>Meta keyword</label>
                                                            <input type="text" name="metaKeyword" value={metaKeyword} className={formError.metaKeyword !== '' ? "form_control2 error_border" : "form_control2"} placeholder="Meta keyword" onChange={this.inputHandler} autoComplete="off" />
                                                            {formError.metaKeyword !== '' ? <p className="error_text pos_a">{formError.metaKeyword}</p> : null}
                                                        </div>
                                                    </div>
                                                    <div className="div_3">
                                                        <div className="form_group">
                                                            <label>Meta Description</label>
                                                            <textarea name={"metaDescription"} value={metaDescription} className="form_control3 textarea" onChange={this.inputHandler} placeholder="Meta Description">{this.state.metaDescription}</textarea>
                                                            {formError.metaDescription !== '' ? <p className="error_text pos_a">{formError.metaDescription}</p> : null}
                                                        </div>
                                                    </div>
                                                    {/* <div className="div_3">
                                                        <div className="form_group">
                                                            <label>Video embed</label>
                                                            <input type="file" id="img" name="img" accept="video/mp4,video/x-m4v,video/*" />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="bg_submit2 right-mx120">
                                                    <button type="submit" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                                </div>
                                            </form>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    editData: state.pageContent.editData
});

const mapDispatchToProps = dispatch => ({
    editStore: (data) => dispatch({ type: ACTION_TYPES.EDIT_DATA_STORE, payload: data })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPageContentMain));