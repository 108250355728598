import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';
import DataTable from '../JqueryDataTable';
import AddMoreModal from './AddMoreModal';
import { toster_sucess } from '../../helpers/toster_helper';
import './index.css';

class Reviews extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            reviewsData: [],
            isLoader: false,
            page: 1,
            search: '',
            count: 0,
            isImgError: false,
            isModal: false,
            singleData: null,
            messageApi: '',
            title: '',
            description: '',
            noReviews: '',
            clarity: 0,
            formError: {
                title: '',
                description: '',
                noReviews: ''
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            issLoader: false,
            startDate: new Date(),
            reviewType: 0,

        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({
            isLoader: true
        })
        this.getReviewApi(this.state.page, this.state.search);

    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getReviewApi = async (page, search) => {
        if (this._ismounted) {
            try {
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                let formData = new FormData();

                formData.append('search_by', search)
                formData.append('page_no', page)

                const res = await apiHalper(`getUsersReview`, 'POST', formData, headers);
                if (res.data.status === true) {
                    const data = jwtDecode(res.data.data);
                    // console.log(data);
                    this.setState({
                        reviewsData: data.review_data,
                        count: data.review_count,
                        isLoader: false,
                        messageApi: ''
                    })
                } else {
                    this.setState({
                        reviewsData: [],
                        isLoader: false,
                        messageApi: ''
                    })
                }
            } catch (err) {
                this.setState({
                    reviewsData: [],
                    isLoader: false,
                    messageApi: 'Network Error'
                })
            }
        }

    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'fake_count_review':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'real_count_review':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'total_count_review':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    totalField = (cell, row) => {
        const total = Number(row.fake_count_review) + Number(row.real_count_review)
        // console.log(row);
        return <span className="text-center margin_0auto">{total}</span>;
    }

    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    actionField = (cell, row) => {
        return <button type="button" className="btn-view max-90 margin_0auto" onClick={() => this.openModal(row)}>Add More</button>
        // return <Link to={`/usersDetails/${row.id}?type=clients`} className="btn-view margin_0auto">View</Link>;
       // return <Link to={} className="btn-view margin_0auto" onClick={() => this.viewDetail(row)}>View</Link>

    }
    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{0}</span>;
    }
    statusField = (cell) => {

        if (cell === 1) {
            return <button type="button" className="btn-status green margin_0auto" disabled>Active</button>;
        } else {
            return <button type="button" className="btn-status red margin_0auto" disabled>Inactive</button>;
        }

    }

    imgNotFound = (e) => {
        e.target.src = DummyProfile
    }

    userField = (cell, row) => {
        return <div className="tb_user_wrap no_pda">
            <div className="useimg_block online_icon">
                {/* row.photo !== null?row.photo: */}
                {row.img !== null ?
                    <img src={row.img} onError={(e) => this.imgNotFound(e)} alt="profile" />
                    :
                    <img src={DummyProfile} onError={(e) => this.imgNotFound(e)} alt="profile" />
                }
            </div>
            <p>{cell} {this.checkRatings(Math.round(row.rating))}</p>
        </div>
    }

    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    checkRatings = (rating) => {
        switch (rating) {
            case 1:
                return <span className="rating_wrap rating1"></span>;
            case 2:
                return <span className="rating_wrap rating2"></span>;
            case 3:
                return <span className="rating_wrap rating3"></span>;
            case 4:
                return <span className="rating_wrap rating4"></span>;
            case 5:
                return <span className="rating_wrap rating5"></span>;
            default:
                return <span className="rating_wrap rating0"></span>;
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            this.getReviewApi(page, searchText);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getReviewApi(page, searchText);
        }

    }

    isTester = (row) => {
        // console.log(row)
        let classs = null;
        if (row.is_tester === 1) {
            classs = 'tester_tr'
        } else {
            classs = null;
        }

        return classs;
    }

    addUser = () => {
        console.log('work...')
    }

    openModal = (data) => {
        this.setState({
            isModal: true,
            singleData: data,
            title: '',
            description: '',
            noReviews: '',
            clarity: 0,
            formError: {
                title: '',
                description: '',
                noReviews: '',
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            reviewType: 0
        })
    }

    closeModal = () => {
        this.setState({
            isModal: false
        })
    }

    // modal events
    ratinghandler = (rating) => {
        this.setState({
            clarity: rating,
            ratingValid: true
        });
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const reg = /^([0-9]+[\\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Title must required'
                break;
            case 'description':
                valid.description = value.length > 0;
                error.description = valid.description ? '' : 'Description must required'
                break;
            case 'noReviews':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.noReviews = true
                        error.noReviews = '';
                    } else {
                        valid.noReviews = false
                        error.noReviews = 'Enter only numberic value';
                    }
                } else {
                    valid.noReviews = false;
                    error.noReviews = 'No of reviews must required';
                }
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    addMReview = async (e) => {
        e.preventDefault();

        // try{

        this.setState({
            issLoader: true
        })

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const { singleData, title, description, clarity, startDate, reviewType, noReviews } = this.state;

        let formData = new FormData();
        if (singleData !== null) {
            formData.append('profile_id', singleData.id)
        }
        if (reviewType === 0) {
            formData.append('title', title)
            formData.append('comment', description)
        } else {
            formData.append('number_of_review', noReviews)
        }
        formData.append('review_type', reviewType)
        formData.append('rate', clarity)

        formData.append('timestamp', moment(startDate).format('YYYY-MM-DD'))

        const res = await apiHalper(`addAgentReviews`, 'POST', formData, headers);
        if (res.data.status === true) {
            this.setState({
                issLoader: false,
                messageAApi: ''
            });
            this.closeModal();
            toster_sucess('Review Added Successfully...');
            this.getReviewApi(this.state.page, this.state.search);
        } else {
            this.setState({
                issLoader: false,
                messageAApi: res.data.message
            });
        }


        // }catch(err){
        //     this.setState({
        //         issLoader:false,
        //         messageAApi:'Network Error'
        //     })
        // }
    }

    handleStartdate = (event, picker) => {
        const startDate = picker.startDate._d;
        // console.log(picker);
        this.setState({
            startDate: startDate
        });
    }

    reviewTypeHandler = (val) => {
        this.setState({
            reviewType: val
        })
    }

    // modal events over

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter: this.userField },
            { dataField: 'rating', text: 'Rating', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'signup_date', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter:this.timeSet },
            { dataField: 'fake_count_review', text: 'Fake Review', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'real_count_review', text: 'Real Review', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'total_count_review', text: 'Total Review', sort: true, headerFormatter: this.headerSpan, formatter: this.totalField },

            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];



        const tableData = {
            columns: columns,
            data: this.state.reviewsData,
            keyId: 'id',
            loading: this.state.isLoader,
            message: this.state.messageApi !== '' ? this.state.messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.count,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
        };

        const modalDa = {
            addMModal: this.state.isModal,
            handleClose: this.closeModal,
            singleData: this.state.singleData,
            title: this.state.title,
            description: this.state.description,
            clarity: this.state.clarity,
            formError: this.state.formError,
            formValid: this.state.formValid,
            ratingValid: this.state.ratingValid,
            messageAApi: this.state.messageAApi,
            issLoader: this.state.issLoader,
            startDate: this.state.startDate,
            inputHandler: this.inputHandler,
            handleStartdate: this.handleStartdate,
            addMReview: this.addMReview,
            ratinghandler: this.ratinghandler,
            reviewType: this.state.reviewType,
            reviewTypeHandler: this.reviewTypeHandler,
            noReviews: this.state.noReviews

        }

        // const { addUser } = this.props;

        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap">
                        <div className="tab_container">
                            <button type="button" className="btn_addUsers" onClick={() => this.addUser()}>Add</button>
                            <DataTable TableData={tableData} />
                        </div>
                        <AddMoreModal modalData={modalDa} />
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(Reviews));
