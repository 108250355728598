import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ACTIVE_TYPES from '../../redux/actions/types';
import * as ACTION_TYPES from '../../redux/actions/types';
import JobsFilterM from './JobsFilterM';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
import { apiHalper } from '../../helpers/ApiHelper';
import moment from 'moment';
import { jwtDecode } from '../../helpers/jwt_helper';
import DeleteModal from './DeleteModal';
class BidList extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            istester: false,
            page: 1,
            search: '',
            allJobs: [],
            totalRecord: 0,
            sortByVal: 1,
            rangeVal: 0,
            manuDate: null,
            startDate: null,
            endDate: null,
            cJobStatus: 0,
            iAdmin: 0,
            typeServices: 4352,
            selectedCountry: null,
            isClearable: true,
            apiMessage: '',
            mainServices: [],
            isDeleModal: false,
            deleteId: null,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({
            startDate: new Date(),
            endDate: new Date()
        });
        const { page, istester, search } = this.state;
        this.getAllJobsApi(page, istester, search);
        this.getMainServices();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getMainServices = async () => {
        try {

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const response = await apiHalper('getMainServices', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                const newArray = data.map(info => {
                    const inputName = info.name.split(" ").join('_');
                    return { id: info.id, name: info.name, inputID: inputName.toLowerCase() }
                });
                this.setState({
                    mainServices: newArray
                })
            } else {

            }

        } catch (err) {
            console.log(err.message);
        }
    }

    getAllJobsApi = async (page, istester, search) => {
        try {
            if (this._ismounted) {
                this.setState({
                    isLoader: true,
                    allJobs: []
                });


                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('show_tester', istester ? 1 : 0)
                formData.append('page_no', page)
                formData.append('job_type', 'BID')
                formData.append('sort_by', this.state.sortByVal)
                if (this.state.rangeVal === 1) {
                    // formData.append('month', this.state.manuDate)
                    // formData.append('year', this.state.manuDate)
                    formData.append('date_from', this.state.startDate)
                    formData.append('date_to', this.state.endDate)
                }

                formData.append('client_status', this.state.cJobStatus)
                // formData.append('client_country', null)
                formData.append('service_category_id', this.state.typeServices)
                formData.append('involved_admin', this.state.iAdmin)
                formData.append('search_by', search)

                const response = await apiHalper('getJobsTypeAll', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    // console.log(data);
                    this.setState({
                        isLoader: false,
                        allJobs: data.job_data,
                        totalRecord: data.count
                    });
                } else {
                    this.setState({
                        isLoader: false,
                        allJobs: [],
                        totalRecord: 0
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
                allJobs: [],
                totalRecord: 0
            });
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'bid_count':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'budget':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'job_post_state_id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'client_rate':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    statusField = (cell) => {

        switch (cell) {
            case 1:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Waiting for deposit'}</button>;
            case 2:
                return <button type="button" className="btn-status btn-status2 minWd_220 blue margin_0auto" disabled>{'In Progress'}</button>;
            case 3:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Bidding'}</button>;
            case 4:
                return <button type="button" className="btn-status btn-status2 minWd_220 green margin_0auto" disabled>{'Completed'}</button>;
            case 5:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Cancelled'}</button>;
            case 6:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Waiting for my acceptance'}</button>;
            case 7:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Waiting for agent acceptance'}</button>;
            case 8:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Submit-Waiting for Release'}</button>;
            case 9:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Refunded'}</button>;
            case 10:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Auto Rejected Bid'}</button>;
            case 11:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Removed'}</button>;
            case 12:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Under review'}</button>;
            case 13:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Rejected Job'}</button>;
            case 14:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Blocked/Reported'}</button>;
            default:
                return <button type="button" className="btn-status btn-status2 minWd_220 margin_0auto" disabled>--</button>;
        }

    }

    centerField = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    amountField = (cell, row) => {
        const { range_from, range_to } = row;
        // console.log(client_rate);
        return <span className="text-center margin_0auto">SAR {range_from} - SAR {range_to}</span>;
    }

    actionField = (cell, row) => {
        localStorage.setItem('jobID', row.id);
        localStorage.setItem('profileID', row.profile_id);
        return <Link to={`/jobsDetails/${row.id}?type=jobs`} className="btn-view margin_0auto" onClick={() => this.viewDetail(row)}>View</Link>
    }
    // actionField = (cell, row) => {
    //     localStorage.setItem('jobID', row.id);
    //     localStorage.setItem('profileID', row.profile_id);
    //     return <div className="action_group text-center">
    //         < Link to={`/jobsDetails/${row.id}?type=jobs`} className="btn-view-icon" onClick={() => this.viewDetail(row)}> </Link>
    //         <button type="button" className="btn_delete" onClick={() => this.openDeleModal(row.id)}></button>
    //     </div>
    // }
    openDeleModal = (id) => {
        this.setState({
            isDeleModal: true,
            deleteId: id
        })
    }
    cancelDelete = () => {
        this.setState({
            isDeleModal: false
        })
    }
    viewDetail = (data) => {
        const { detailView } = this.props
        detailView(data)
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        const { istester } = this.state;
        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            this.getAllJobsApi(page, istester, searchText);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getAllJobsApi(page, istester, searchText);
        }

    }

    isTester = (row) => {
        // console.log(row)
        let classs = null;
        if (row.is_tester) {
            classs = 'tester_tr'
        } else {
            classs = null;
        }

        return classs;
    }

    testerHandler = (e) => {
        // const { checked } = e.target;
        this.setState({
            istester: !this.state.istester,
        });

        const { page, search } = this.state;
        this.getAllJobsApi(page, !this.state.istester, search);
    }

    dateField = (cell, row) => {
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    // filter handler
    sortByHandler = (val) => {
        this.setState({
            sortByVal: val
        });
    }

    rangeHandler = val => {
        this.setState({
            rangeVal: val
        })
    }

    manuDateHandler = val => {
        this.setState({
            manuDate: val
        });
    }

    handleStartdate = (event, picker) => {
        const startDate = picker.startDate._d;
        this.setState({
            startDate: startDate,
            manFilterVal: null
        });
    }

    handleEnddate = (event, picker) => {
        const endDate = picker.endDate._d;
        this.setState({
            endDate: endDate,
            manFilterVal: null
        });
    }

    handleCountry = selectedOption => {
        this.setState({ selectedCountry: selectedOption });
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    jobStatusHandler = val => {
        this.setState({
            cJobStatus: val
        });
    }

    adminHandler = val => {
        this.setState({
            iAdmin: val
        })
    }

    serviceHandler = val => {
        this.setState({
            typeServices: val
        })
    }

    applyHandler = e => {
        e.preventDefault();
        const { openFilter } = this.props;
        const { page, istester, search } = this.state;
        this.getAllJobsApi(page, istester, search);
        openFilter();
    }

    // filter handler over

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan },
            { dataField: 'title', text: 'Title', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'id', text: 'Job ID', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.dateField },
            { dataField: 'job_post_state_id', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'bid_count', text: 'No of Bid', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'budget', text: 'Budget', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'client_rate', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.allJobs,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        const { openFilter, isBJFModal } = this.props;

        const clientStatusArray = [
            { status: 'Any Status', statusID: 0, inputID: 'allAnystatus' },
            { status: 'Waiting for deposit', statusID: 1, inputID: 'wfDStatus' },
            { status: 'In Progress', statusID: 2, inputID: 'inPrgStatus' },
            { status: 'Bidding', statusID: 3, inputID: 'bidStatus' },
            { status: 'Completed', statusID: 4, inputID: 'complStatus' },
            { status: 'Cancelled', statusID: 5, inputID: 'cancStatus' },
            { status: 'Waiting for my acceptance', statusID: 6, inputID: 'wfmaStatus' },
            { status: 'Waiting for agent acceptance', statusID: 7, inputID: 'wfaaStatus' },
            { status: 'Submit-Waiting for Release', statusID: 8, inputID: 'swfrStatus' },
            { status: 'Refunded', statusID: 9, inputID: 'refStatus' },
            { status: 'Auto Rejected Bid', statusID: 10, inputID: 'arbStatus' },
            { status: 'Removed', statusID: 11, inputID: 'remStatus' },
            { status: 'Under review', statusID: 12, inputID: 'urStatus' },
            { status: 'Rejected Job', statusID: 13, inputID: 'rejJobStatus' },
            { status: 'Blocked/Reported', statusID: 14, inputID: 'blkRepoStatus' }
        ]

        const fieldName = {
            sortBy: {
                name: 'bid_sortBy',
                newOld: 'bidNewOld',
                oldNew: 'bidOldNew'
            },
            dateRange: {
                name: 'bid_dateRange',
                date_range: 'bidDate_range',
                custom_range: 'bidCustom_range',
                custom_name: 'bid_customRange',
                month: 'bid',
                year: 'bidYear'
            },
            cjs: {
                name: 'bid_clientStatus',
                cateArray: clientStatusArray
            },
            iba: {
                name: 'bid_involvedAdmin',
                iAdmin1: 'bidiAdmin1',
                iAdmin2: 'bidiAdmin2',
                iAdmin3: 'bidiAdmin3',
                iAdmin4: 'bidiAdmin4',
                iAdmin5: 'bidiAdmin5'
            },
            toservices: {
                name: 'bid_typeServices',
                serviceArray: this.state.mainServices
            }
        }

        const modalData = {
            sortByVal: this.state.sortByVal,
            rangeVal: this.state.rangeVal,
            manuDate: this.state.manuDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            cJobStatus: this.state.cJobStatus,
            iAdmin: this.state.iAdmin,
            typeServices: this.state.typeServices,
            selectedCountry: this.state.selectedCountry,
            isClearable: this.state.isClearable,
            sortByHandler: this.sortByHandler,
            rangeHandler: this.rangeHandler,
            manuDateHandler: this.manuDateHandler,
            handleStartdate: this.handleStartdate,
            handleEnddate: this.handleEnddate,
            jobStatusHandler: this.jobStatusHandler,
            adminHandler: this.adminHandler,
            serviceHandler: this.serviceHandler,
            applyHandler: this.applyHandler,
            handleCountry: this.handleCountry,
            toggleClearable: this.toggleClearable
        }
        const { isDeleModal, deleteId } = this.state;
        return (
            <Fragment>
                <div className="tab_container">
                    <div className="tester_view">
                        <p>Tester Recorded</p>
                        <button type="button" className={this.state.istester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                            <span className="label_text"></span>
                        </button>
                    </div>
                    <div className="filter_btnGroup">
                        <button type="button" className="btn_filterM" onClick={openFilter}></button>
                    </div>
                    <DataTable TableData={tableData} />
                </div>
                <JobsFilterM field={fieldName} data={modalData} isModal={isBJFModal} closeEvent={openFilter} />
                <DeleteModal isModal={isDeleModal} id={deleteId} handleClose={this.cancelDelete} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isBJFModal: state.allJobs.isBJFModal
})

const mapDispatchToProps = dispatch => ({
    openFilter: () => dispatch({ type: ACTION_TYPES.TOGGLE_JOBS_BIDFILTER_MODAL }),
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BidList));
