export const TOGGLE_SIDEBAR_ACTIVE = 'TOGGLE_SIDEBAR_ACTIVE';
export const TOGGLE_IMAGE_VIEWER = 'TOGGLE_IMAGE_VIEWER';
export const TOGGLE_ADD_ACCOUNT_MODAL = 'TOGGLE_ADD_ACCOUNT_MODAL';
export const TOGGLE_WITHDRAW_MODAL = 'TOGGLE_WITHDRAW_MODAL';

// users
export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL';
export const TOGGLE_ADMIN_MODAL = 'TOGGLE_ADMIN_MODAL';
export const PAGE_NUMBER_PAGINATION = 'PAGE_NUMBER_PAGINATION';
export const CLIENT_DATA_ADD = 'CLIENT_DATA_ADD';
export const AGENT_DATA_ADD = 'AGENT_DATA_ADD';
export const ADMIN_DATA_ADD = 'ADMIN_DATA_ADD';
export const TOTAL_USERS_COUNT = 'TOTAL_USERS_COUNT';
export const USER_DETAILS_API = 'USER_DETAILS_API';

// report
export const TOGGLE_FILTER_REPORT_MODAL = 'TOGGLE_FILTER_REPORT_MODAL';
export const CLOSE_FILTER_REPORT_MODAL = 'CLOSE_FILTER_REPORT_MODAL';
export const TOGGLE_FILTER_REPORT_FIRST_VAL = 'TOGGLE_FILTER_REPORT_FIRST_VAL';
export const TOGGLE_FILTER_REPORT_SECOND_VAL = 'TOGGLE_FILTER_REPORT_SECOND_VAL';
export const TOGGLE_FILTER_REPORT_THIRD_VAL = 'TOGGLE_FILTER_REPORT_THIRD_VAL';
export const IS_APPLY_FILTER = 'IS_APPLY_FILTER';

// push notification
export const TOGGLE_NOTIFICATION_MODAL = 'TOGGLE_NOTIFICATION_MODAL';
export const CLOSED_NOTIFICATION_MODAL = 'CLOSED_NOTIFICATION_MODAL';

// all jobs
export const TOGGLE_JOBS_ALLFILTER_MODAL = 'TOGGLE_JOBS_ALLFILTER_MODAL';
export const TOGGLE_JOBS_POSTFILTER_MODAL = 'TOGGLE_JOBS_POSTFILTER_MODAL';
export const TOGGLE_JOBS_BIDFILTER_MODAL = 'TOGGLE_JOBS_BIDFILTER_MODAL';
export const TOGGLE_JOBS_HIREDFILTER_MODAL = 'TOGGLE_JOBS_HIREDFILTER_MODAL';
export const TOGGLE_JOBS_DEPOFILTER_MODAL = 'TOGGLE_JOBS_DEPOFILTER_MODAL';
export const TOGGLE_JOBS_RRQUESTFILTER_MODAL = 'TOGGLE_JOBS_RRQUESTFILTER_MODAL';
export const TOGGLE_JOBS_UREVIEWFILTER_MODAL = 'TOGGLE_JOBS_UREVIEWFILTER_MODAL';
export const TOGGLE_JOBS_REPORTFILTER_MODAL = 'TOGGLE_JOBS_REPORTFILTER_MODAL';

//job details
export const JOB_VIEW_DETAIL = 'JOB_VIEW_DETAIL';

// offers
export const OPEN_COUPON_MODAL = 'OPEN_COUPON_MODAL';
export const CLOSE_COUPON_MODAL = 'CLOSE_COUPON_MODAL';
export const GET_COUPON_LIST = 'GET_COUPON_LIST';

// coupon report
export const GET_COUPON_REPORT_DETAILS = 'GET_COUPON_REPORT_DETAILS';

// page content
export const EDIT_DATA_STORE = 'EDIT_DATA_STORE';
export const SERVICE_NAME = "SERVICE_NAME"

// partner component
export const PATNER_SINGLE_DATA = 'PATNER_SINGLE_DATA';