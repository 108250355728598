import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../assets/images/user-loader.gif';

export default class RankModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            general: '',
            category: '',
            rankError: '',
            formError: {
                general: '',
                category: '',
            },
            formValid: {
                general: false,
                category: false,
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { addMModal, data } = this.props.modalData
        if (prevProps.modalData.addMModal !== addMModal) {
            if (data) {
                this.setState({
                    general: data.generalRank,
                    category: data.categoryRank,
                    rankError: '',
                    formError: {
                        general: '',
                        category: '',
                    },
                    formValid: {
                        general: data.generalRank && data.generalRank !== '' ? true : false,
                        category: data.categoryRank && data.categoryRank !== '' ? true : false,
                    }
                })
            }
            else {
                this.setState({
                    general: '',
                    category: '',
                    rankError: '',
                    formError: {
                        general: '',
                        category: '',
                    },
                    formValid: {
                        general: false,
                        category: false,
                    }
                })
            }
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
        // , () => this.checkValidation(name, value));
    }

    // checkValidation = (name, value) => {
    //     const { formError, formValid } = this.state;
    //     let error = formError;
    //     let valid = formValid;

    //     switch (name) {
    //         case 'general':
    //             let isnum = /^[0-9]*$/.test(value);
    //             if (value.length > 0) {
    //                 if (isnum) {
    //                     valid.general = true;
    //                     error.general = '';
    //                 }
    //                 else {
    //                     valid.general = false;
    //                     error.general = 'Rank Must in number';
    //                 }
    //             }
    //             else {
    //                 valid.general = false;
    //                 error.general = 'Rank Must Required';
    //             }
    //             break;
    //         case 'category':
    //             let isnum1 = /^[0-9]*$/.test(value);
    //             if (value.length > 0) {
    //                 if (isnum1) {
    //                     valid.category = true;
    //                     error.category = '';
    //                 }
    //                 else {
    //                     valid.category = false;
    //                     error.category = 'Rank Must in number';
    //                 }
    //             }
    //             else {
    //                 valid.category = false;
    //                 error.category = 'Rank Must Required';
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    //     this.setState({
    //         formError: error,
    //         formValid: valid
    //     })
    // }

    addRank = async (e) => {
        e.preventDefault();
        const { general, category } = this.state;
        const { submitRank } = this.props.modalData

        if ((general === '' || general === null) && (category === '' || category === null)) {
            this.setState({
                rankError: 'Atleast One Rank is Required'
            })
        }
        else {
            submitRank(general, category)
        }
    }

    render() {
        const { addMModal, handleClose, issLoader, messageAApi } = this.props.modalData;
        const { general, category, formError, rankError } = this.state

        return (
            <Modal show={addMModal} onHide={handleClose} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view rank-modal">
                        <div className="modal_header">
                            <h4 className="model_title">Set Rank<button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#" className="rank-form" onSubmit={this.addRank} id="rankForm">
                                <div className="flex-box">
                                    <div className="form_group3">
                                        <label>General Rank</label>
                                        <input type="number" name="general" className={formError.general !== '' ? "form_control2 error_border" : "form_control2"} value={general} onChange={this.inputHandler} placeholder="# 3" autoComplete="off" />
                                        {formError.general !== '' ? <p className="error_text">{formError.general}</p> : null}
                                    </div>
                                    <div className="form_group3">
                                        <label>Category Rank</label>
                                        <input type="number" name="category" className={formError.category !== '' ? "form_control2 error_border" : "form_control2"} value={category} onChange={this.inputHandler} placeholder="# 5" autoComplete="off" />
                                        {formError.category !== '' ? <p className="error_text">{formError.category}</p> : null}
                                    </div>
                                </div>
                                {messageAApi !== '' ? <p className="error_text">{messageAApi}</p> : null}
                                {rankError !== '' ? <p className="error_text require-error">{rankError}</p> : null}
                                <div className="bg_submit2 mr-top">
                                    <button type="submit" className="btn-submit" >{issLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
