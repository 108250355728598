import * as ACTION_TYPES from '../actions/types';

const initialState = {
    userModal:false,
    adminModal:false,
    page:1,
    clientData:[],
    agentData:[],
    adminData:[],
    client:0,
    agent:0,
    admin:0,
    editAdminData:null,
    userDetails:null
}

const usersReducers = (state = initialState, action) =>{
    switch (action.type) {
        case ACTION_TYPES.TOGGLE_USER_MODAL:
            return{
                ...state,
                userModal:!state.userModal
            }
        case ACTION_TYPES.TOGGLE_ADMIN_MODAL:
            return{
                ...state,
                adminModal:!state.adminModal,
                editAdminData:action.payload
            }
        case ACTION_TYPES.PAGE_NUMBER_PAGINATION:
            return{
                ...state,
                page:action.payload
            }
        case ACTION_TYPES.CLIENT_DATA_ADD:
            let clientData = state.clientData;
            clientData += action.payload;
            return{
                ...state,
                clientData:clientData
            }
        case ACTION_TYPES.AGENT_DATA_ADD:
            let agentData = state.agentData;
            agentData += action.payload;
            return{
                ...state,
                agentData:agentData
            }
        case ACTION_TYPES.ADMIN_DATA_ADD:
            let adminData = state.adminData;
            adminData += action.payload;
            return{
                ...state,
                adminData:adminData
            }
        case ACTION_TYPES.TOTAL_USERS_COUNT:
            return{
                ...state,
                client:action.payload.client,
                agent:action.payload.agent,
                admin:action.payload.admin
            }
        case ACTION_TYPES.USER_DETAILS_API:
            return{
                ...state,
                userDetails:action.payload
            }
        default:
            return state;
    }
}

export default usersReducers;