import React from 'react';

const StatusField = ({ cell, row, handleToggle }) => {
  return (
    <div className="divd_wrap">
      <div className="wid_full">
        <div className="justify-content-between align-items-center">
          <div className="d-flex w-100 ms-auto gap-2 align-items-center">
            <div className={"wrap_notialert d-flex"}>
              <input
                type="radio"
                id={`bussiness_email_status_public_${row.id}`}
                name={`bussiness_email_status_public_${row.id}`}
                checked={cell === 1}
                onChange={() => {}}
              />
              <label
                htmlFor={`bussiness_email_status_public_${row.id}`}
                onClick={() => handleToggle(1, row.id)}
                style={{ background: cell === 1 ? "#1258E4" : 'transparent' }}
              >
                Active
              </label>
              <input
                type="radio"
                id={`bussiness_email_status_private_${row.id}`}
                name={`bussiness_email_status_private_${row.id}`}
                checked={cell === 0}
                onChange={() => {}}
              />
              <label
                htmlFor={`bussiness_email_status_private_${row.id}`}
                onClick={() => handleToggle(0, row.id)}
                style={{ background: cell === 0 ? '#d0555a' : "transparent" }}
              >
                InActive
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusField;
