import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import * as NUM_Helper from '../../helpers/NumberFormate';
import DataTable from '../JqueryDataTable';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import * as ACTION_TYPES from '../../redux/actions/types';

class CouponReport extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state = {
            cReportList:[],
            isLoader:false,
            page:1,
            search:'',
            count:0
        }
    }
    componentDidMount(){
        this._ismounted = true;
        const { page, search } = this.state;
        this.getCreportList(page, search);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getCreportList = async (page, search) =>{
        try{
            if(this._ismounted){
                this.setState({
                    isLoader:true
                })
                const headers = {
                    'Authorization':localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('page_no', page)
                formData.append('search_by', search)

                const res = await apiHalper('getCouponReports', 'POST', formData, headers);
                // console.log(res);
                if(res.data.status === true){
                    const data = jwtDecode(res.data.data);
                    // console.log(data);
                    this.setState({
                        isLoader:false,
                        cReportList:data.coupon_reports,
                        count:data.total_record
                    })
                }
            }
        }catch(err){
            if(err){
                this.setState({
                    isLoader:false,
                    cReportList:[]
                })
            }
        }
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'order_amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'paid_order_count':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'paid_discount_amount':
                return <span className={column.sort?"header_span sort  text-center imgRight":"header_span  text-center imgRight"}>{column.text}</span>;
            case 'paid_customer_count':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
    }

    numFormatter = (cell, row) =>{
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    amountField = (cell) =>{
        return <span className="text-center margin_0auto">${cell}</span>;
    }

    actionField = (cell, row) =>{
        return <button type="button" className="btn-view margin_0auto" onClick={()=>this.viewDetails(row)}>View</button>
    }

    viewDetails = (data) =>{
        const { addData } = this.props;
        addData(data);
        const { history } = this.props;
        history.push('/offers/reportcoupon/'+data.id);
    }
    usedCoupon = () =>{
        const { history } = this.props;
        history.push('/offers/usedcoupon');
    }

    backHandler = () =>{
        const { history } = this.props;
        history.push('/offers');
    }

    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        // console.log(type);
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }
        this.getCreportList(page, searchText);
    }

    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'coupon_code', text: 'Coupon Code', sort: true, headerFormatter: this.headerSpan }, 
            { dataField: 'order_amount', text: 'Order Amount', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField},
            { dataField: 'paid_order_count', text: 'Paid Order Count', sort: true, headerFormatter: this.headerSpan, formatter:this.numFormatter },
            { dataField: 'paid_customer_count', text: 'Paid Customer Count', sort: true, headerFormatter: this.headerSpan, formatter:this.numFormatter },
            { dataField: 'paid_discount_amount', text: 'Paid Discount Amount', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField },
            { dataField: 'action', text: 'Action', sort: true, headerFormatter: this.headerSpan, formatter:this.actionField }
        ];

       

        const tableDatas = {
            columns:columns,
            data:this.state.cReportList,
            keyId:'id',
            loading:this.state.isLoader,
            message:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.count,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };


        return (
            <section className="bg-balance">
                <div className="balance_wrap">
                    <div className="btn_groupsB">
                        <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                        <p className="subpage_title">Coupon Report</p>
                        {/* <button type="button" className="btn_coupon" onClick={this.usedCoupon}>All Coupon</button> */}
                    </div>
                    <DataTable TableData={tableDatas} />
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch =>({
    addData:(data)=>dispatch({type:ACTION_TYPES.GET_COUPON_REPORT_DETAILS, payload:data})
})

export default withRouter(connect(null, mapDispatchToProps)(CouponReport));
