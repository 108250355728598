import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NoteModal from './NoteModal';
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollLoader from '../ScrollLoader';
import LoaderComponent from '../LoaderComponent';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';

class NoteDetails extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            isModal:false,
            isAdd:false,
            noteData:[],
            page:1,
            isLoader:false,
            isSLoader:false,
            messageApi:'',
            singleData:null
        }
    }

    componentDidMount(){
        this._ismounted = true;
        this.getNoteListApi(this.state.page)
        
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getNoteListApi = async (page) =>{
        console.log(this.props.type)
        if(this._ismounted){
            try{
                const { id } = this.props.match.params;
                const headers = {
                    'Authorization':localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('profile_id', id)
                formData.append('page_no', page)
               
                if(this.props.type === 'agents' || this.props.type === 'clients'){
                    console.log("NOTE")
                    const res = await apiHalper('getAgentNote', 'POST', formData, headers);
                    // console.log(res);
                    if(res.data.status === true){
                        const data = jwtDecode(res.data.data);
                        if(data.length > 0){
                            this.setState({
                                isLoader:false,
                                isSLoader:true,
                                messageApi:'',
                                noteData:data
                            })
                        }else{
                            this.setState({
                                isLoader:false,
                                isSLoader:false,
                                messageApi:'No Record Found'
                            })
                        }
                    }else{
                        this.setState({
                            isLoader:false,
                            isSLoader:false,
                            messageApi:res.data.message,
                            noteData:[]
                        }) 
                    }
                }else{
                    this.setState({
                        isLoader:false,
                        isSLoader:false,
                        messageApi:'Record not found',
                        noteData:[]
                    })  
                }

            }catch(err){
                this.setState({
                    isLoader:false,
                    isSLoader:false,
                    messageApi:'Network Error'
                })
            }
        }
    }

    nextpageNote = () => {
        const inc = this.state.page + 1;
        if(this.state.isSLoader){
            this.setState({
                page : inc
            })
            this.getNoteListApi(inc)
        }else{
            this.setState({
                isSLoader:false
            })
        }
        
    }

    openModal = () =>{
        this.setState({
            isAdd:true,
            isModal:true
        });
    }

    editModal = (data) =>{
        this.setState({
            isAdd:false,
            isModal:true,
            singleData:data
        });
    }

    handlerClose = () =>{
        this.setState({
            isModal:false,
            singleData:null
        })
        this.getNoteListApi(this.state.page)
    }

    renderList = (data, i) =>{
        const { type } = this.props;
        return <li key={i}>
                <div className="note-wrapper">
                    <p className="details_text">{data.message}</p>
                    <p className="details_text">{moment(data.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>
                {/* {type==='agents'?<button type="button" className="btn-edit2" onClick={()=>this.editModal(data)}>Edit</button> :null} */}
                
        </li>
    }


    render() {
        
        const { isLoader, noteData, isSLoader, messageApi } = this.state;
        return (
            <div className="tab_container">
                <div className="review_wrap">
                    <h6 className="subpage_title">Note <button type="button" className="btn-addRev" onClick={this.openModal}>Add</button></h6>
                    <ul className="review_list" id="scrollableUl">
                        {!isLoader?
                            noteData.length > 0?
                                <InfiniteScroll className="review_list width-forscrool" dataLength={noteData.length} next={this.nextpageNote} hasMore={isSLoader} loader={<ScrollLoader />} scrollableTarget="scrollableUl">
                                    {noteData.map((info, i)=>this.renderList(info, i))}
                                </InfiniteScroll>
                            :
                            <li>
                                <div className="noRecord">
                                    <p>{messageApi}</p>
                                </div>
                            </li>
                        :
                            <LoaderComponent />
                        }
                        {/* <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null}
                            </div>
                            
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li>
                        <li>
                            <div className="review_block">
                                <div className="review_titleWrap">
                                    <p className="details_text">We are higly reccomend this freelancer for everyone! Skills,Speed and Communication is very good with him! Fast and perfect work!</p>
                                </div>
                                {type==='agents'?<button type="button" className="btn-edit2" onClick={this.editModal}>Edit</button> :null} 
                            </div>
                        </li> */}
                    </ul>
                </div>
                <NoteModal isModal={this.state.isModal} isAdd={this.state.isAdd} id={this.props.id} handlerClose={this.handlerClose} />
            </div>
        )
    }
}

export default withRouter(connect(null)(NoteDetails));
