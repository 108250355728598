import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiHalper } from '../../helpers/ApiHelper';
// import { toster_sucess } from '../../helpers/toster_helper';
import { jwtDecode } from '../../helpers/jwt_helper';
import './index.css'

class ReportedUser extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            userCount: null,
            userName: '',
            image: '',
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
        }
    }
    componentDidMount() {
        const { page, search, sizePerPage } = this.state;
        this.reportedUsers(page, search, sizePerPage)
    }
    reportedUsers = async (page, search, sizePerPage) => {
        try {
            this.setState({
                isLoader: true,
                userCount: null,
                userName: '',
                image: ''
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('page_no', page)
            formData.append('search_by', search)
            formData.append('limit', sizePerPage)            
            
            const response = await apiHalper('getReportedUsers', 'POST', formData, headers)
            const res = response.data;

            if(res.status) {
                const data = jwtDecode(res.data)

                this.setState({
                    isLoader: false,
                    userCount: data.userData[0].report_count,
                    userName: data.userData[0].username,
                    image: data.userData[0].img,
                    totalRecord: data.count
                });
            }
        }catch(err){
            console.log(err.message);
        }
    }
    render() {
        const { userName, userCount } = this.state
        return (
            <ul className="reported-user-list">
                <li>
                    <div className="box">
                        <div className="img-div">
                            <img alt="User" />
                        </div>
                        <div className="detail">
                            <span className="username">{userName}</span>
                            <span className="count">Reported UserCount: {userCount}</span>
                        </div>
                    </div>
                </li>                
                <li>
                    <div className="box">
                        <div className="img-div">
                            <img alt="User" />
                        </div>
                        <div className="detail">
                            <span className="username">Username</span>
                            <span className="count">Reported UserCount: 3</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="box">
                        <div className="img-div">
                            <img alt="User" />
                        </div>
                        <div className="detail">
                            <span className="username">Username</span>
                            <span className="count">Reported UserCount: 3</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="box">
                        <div className="img-div">
                            <img alt="User" />
                        </div>
                        <div className="detail">
                            <span className="username">Username</span>
                            <span className="count">Reported UserCount: 3</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="box">
                        <div className="img-div">
                            <img alt="User" />
                        </div>
                        <div className="detail">
                            <span className="username">Username</span>
                            <span className="count">Reported UserCount: 3</span>
                        </div>
                    </div>
                </li>
            </ul>
        )
    }
}

export default withRouter(connect(null)(ReportedUser))