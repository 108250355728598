import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import NoDataIndication from '../../TableLoader';
import SearchIcon from '../../../assets/images/search_icon.svg';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper'
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
// import * as ACTION_TYPES from '../../../redux/actions/types';

class TopSkillCities extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoader: false,
            isSelectLoader: false,
            isSLoader: false,
            isMainLoader: false,
            pageID: null,
            cityArr: [],
            searchCityArr: [],
            selectedCityArray: [],
            selectedCityID: [],
            searchVal: '',
            checked: false,
            selectedOption: null,
            selectedStateOption: null,
            options: [],
            stateOptions: []
        }
    }
    componentDidMount() {
        this.getAllCountry();
        this.getAllState();
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            pageID: myParam
        })
        this.getSelectedCity(myParam);
    }

    getAllCountry = async () => {
        try {
            this.setState({
                isLoader: true,
                isSelectLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }          
            const response = await apiHalper('getCountries', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    options: [],
                    isLoader: false,
                    isSelectLoader: false
                })
            }
            const selectedConList = jwtDecode(res.data)

            const sortList = selectedConList.sort((a, b) => {
                if (a.countryName > b.countryName) return 1
                if (a.countryName < b.countryName) return -1
                return 0;
            });            
            this.setState({                
                options: sortList.map(info => ({ value: info.id, label: info.countryName })),
                isLoader: false,
                selectedOption: { value: sortList[0].id, label: sortList[0].countryName }
            })
            this.getAllState(sortList[0].id);
        }
        catch (err) {
            console.error(err.message)
        }
    }

    getAllState = async (countryID) => {
        try {
            this.setState({
                isLoader: true,
                isMainLoader: true,
                isSelectLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getStates/${countryID}`, 'GET', null, headers);
            const res = response.data;
            
            if (!res.status) {
                this.setState({
                    stateArr: [],
                    searchStateArr: [],
                    isLoader: false,
                    isSelectLoader: false,
                    isMainLoader: false,
                })
            }
            const decode = jwtDecode(res.data)
            const sortList = decode.sort((a, b) => {
                if (a.stateName > b.stateName) return 1
                if (a.stateName < b.stateName) return -1
                return 0;
            });            
            
            this.setState({                
                stateOptions: sortList.map(info => ({ value: info.id, label: info.stateName })),
                isLoader: false,
                isSelectLoader: false,
                selectedStateOption: { value: sortList[0].id, label: sortList[0].stateName }
            })
            
            this.getAllCity(sortList[0].id);

        }
        catch (err) {
            console.error(err.message)
        }
    }

    getAllCity = async (stateID) => {
        try {
            this.setState({
                isLoader: true,
                isMainLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getCities/${stateID}`, 'GET', null, headers);
            const res = response.data;
            
            if (!res.status) {
                this.setState({
                    cityArr: [],
                    searchCityArr: [],
                    isLoader: false,
                    isMainLoader: false
                })
            }
            const decode = jwtDecode(res.data)
            const sortList = decode.sort((a, b) => {
                if (a.cityName > b.cityName) return 1
                if (a.cityName < b.cityName) return -1
                return 0;
            });

            this.setState({
                cityArr: sortList,
                searchCityArr: sortList,
                isLoader: false,
                isMainLoader: false
            })

        }
        catch (err) {
            console.error(err.message)
        }
    }
    CityRender = (data, i) => {
        const { selectedCityArray } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedCityArray.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }
        return <li key={i}>
            <label htmlFor={"cityID" + data.id}>
                <input type="checkbox" id={"cityID" + data.id} value={data.id} name="cityID" onClick={() => this.addCityBind(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.cityName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    addCityBind = (data) => {
        let newArray = this.state.selectedCityArray;
        let CityID = this.state.selectedCityID;
        if (newArray.some(info => info.id === data.id)) {

            document.getElementById('cityID' + data.id).removeAttribute('checked', true);
            document.getElementById('cityID' + data.id).removeAttribute('class', 'active');

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = CityID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            CityID.splice(couIndex, 1);
        }
        else {
            document.getElementById('cityID' + data.id).setAttribute('checked', true);
            document.getElementById('cityID' + data.id).setAttribute('class', 'active');
            newArray.push({ id: data.id, cityName: data.cityName });
            CityID.push(data.id);
        }

        if (newArray.length > 0) {
            this.setState({
                selectedCityArray: newArray,
                selectedCityID: CityID
            })
        }
    }
    SelectedCityRender = (data, i) => {
        return <li key={i}>
            <label htmlFor={"selectCity" + i}>
                <input type="checkbox" id={"selectCity" + i} value={data.id} name="selectedCon" defaultChecked onClick={() => this.removeSelectedCity(data)} className="active" />
                <p><span>{data.cityName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    removeSelectedCity = (data) => {
        let newArray = this.state.selectedCityArray;
        let CityID = this.state.selectedCityID;
        if (newArray.some(info => info.id === data.id)) {

            if(document.getElementById('cityID' + data.id)) {
                document.getElementById('cityID' + data.id).removeAttribute('checked', true);
                document.getElementById('cityID' + data.id).removeAttribute('class', 'active');
            }

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = CityID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            CityID.splice(couIndex, 1);
        }

        this.setState({
            selectedCityArray: newArray,
            selectedCityID: CityID
        })
    }
   
    getSelectedCity = async (id) => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper(`getTopCitiesSkills/${id}`, 'GET', null, headers)
            const res = response.data;
            if (!res.status) {
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                return true
            }
            const decode = jwtDecode(res.data)
            let cityId = []
            for (let cid = 0; cid < decode.length; cid++ ) {
                cityId.push(decode[cid].id)
            }
            
            this.setState({
                selectedCityArray: decode,
                selectedCityID: cityId
            })
            this.setState({
                isLoader: false
            })
        }
        catch (error) {
            console.log(error.message)
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.getAllState(selectedOption.value);
    };

    stateHandleChange = selectedStateOption => {
        this.setState({ selectedStateOption });
        this.getAllCity(selectedStateOption.value);
    };
      
    searchCity = (e) => {
        e.preventDefault();
    }
    searchCity = async (e) => {
        e.preventDefault();
        const { searchCityArr, searchVal } = this.state;

        const search = searchCityArr.filter(info => info.cityName.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchCityArr: search
        })
    }
    CitiesearchInput = (e) => {
        const { value } = e.target;
        const { cityArr } = this.state;

        if (value.length > 0) {
            const search = cityArr.filter(info => info.cityName.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchCityArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchCityArr: cityArr,
                searchVal: value
            })
        }

    }
    CityRender = (data, i) => {
        const { selectedCityID } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedCityID.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
        <label htmlFor={"cityID" + data.id}>
            <input type="checkbox" id={"cityID" + data.id} value={data.id} name="cityID" onClick={() => this.addCityBind(data)} defaultChecked={checked1} className={activeClass} />
            <p><span>{data.cityName}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g id="add-icon" transform="translate(-13604 1017)">
                        <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="9.5" fill="none" />
                        </g>
                        <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                    </g>
                </svg>
            </p>
        </label>
    </li>
    }
    submitCityList = async () => {
        const { selectedCityID, pageID  } = this.state
        try {
            if(selectedCityID.length > 0) {
                this.setState({
                    isSLoader: true,
                    apiError: ''
                })
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData()

                formData.append('serviceCategoryID', pageID)
                formData.append('cityID', JSON.stringify(selectedCityID))
                const response = await apiHalper('addTopCitiesSkills', 'POST', formData, headers)

                const res = response.data;
                console.log(res)
                if (!res.status) {
                    this.setState({
                        apiErr: res.message,
                        isSLoader: false
                    })
                    toster_error(res.message);
                    return true
                }
                toster_sucess(res.message);
                this.setState({
                    isSLoader: false
                })
            }
            else {
                this.setState({
                    apiError: 'Please Select at least one City',
                    isSLoader: false
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }
    render() {
        const { isSLoader, selectedOption, selectedStateOption, options, stateOptions, isMainLoader, apiError } = this.state
        
        return (
            <>            
                <div className="country-s-dropdown country-dropdown"><label>Select Country: </label><Select value={selectedOption} onChange={this.handleChange} options={options} /></div>
                {this.state.isSelectLoader ? <span className="select-loader select-state-loader"><img src={LoaderImg} className="small-loader" alt="loader" /></span> : null}
                <div className="country-dropdown state-dropdown"><label>Select State: </label><Select value={selectedStateOption} onChange={this.stateHandleChange} options={stateOptions} /></div>
                {this.state.isSelectLoader ? <span className="select-loader"><img src={LoaderImg} className="small-loader" alt="loader" /></span> : null}
                    <div className="top-list">
                        {isMainLoader ? <NoDataIndication /> : 
                            <div className={this.state.selectedCityArray.length > 0 ? 'list city' : 'list city no-result'}>
                                <form action="/" method="POST">
                                    <ul>
                                        {this.state.selectedCityArray.length > 0 ?
                                            this.state.selectedCityArray.map((data, i) => this.SelectedCityRender(data, i)) : <li>No City Available</li>}
                                    </ul>
                                </form>
                            </div>
                        }
                        {isMainLoader ? <NoDataIndication /> : 
                            <div className={this.state.searchCityArr.length > 0 ? 'list city' : 'list city no-result'}>
                                <form className="search-form" action="/" method="POST" onSubmit={this.searchCity}>
                                    <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search City" onChange={this.CitiesearchInput} />
                                    <button type="submit"><img src={SearchIcon} alt="Search" /></button>
                                </form>
                                <form action="/" method="POST">
                                    <ul>
                                        { this.state.isMainLoader ? <NoDataIndication /> : this.state.searchCityArr.length > 0 ? this.state.searchCityArr.map((data, i) => this.CityRender(data, i)) : <li>No City Available</li> }
                                    </ul>
                                </form>
                            </div>
                        }  
                    </div>
                <div className="bg_submit2 right-mx120">
                    {apiError !== '' ? <span className="skill-error">{apiError}</span> : null }
                    <button type="submit" className="btn-submit" onClick={this.submitCityList}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                </div>                
            
        </>
        )
    }
}
export default withRouter(connect(null)(TopSkillCities));