import React, { Component } from 'react';
import { apiHalper } from '../../../helpers/ApiHelper'
import { jwtDecode } from '../../../helpers/jwt_helper'
import { Link } from 'react-router-dom';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
import './index.css';

export default class AddAllServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            mainService:'',
            appName: '',            
            categoryStatus: 1,
            showMenu: 0,
            showTopOnWeb:0,
            apiErr:'',
            formError: {
                mainService:'',
                appName: '',
            },
            formValid: {
                mainService: false,
                appName: false,
            }
        }
    }
    
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]:value
        },()=>this.checkValidation(name, value));
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid =this.state.formValid;        
        switch(name){
            case 'mainService':
                valid.mainService = value.length > 0;
                error.mainService = valid.mainService?'':'Main service is required.';
                break;
            case 'appName':
                valid.appName = value.length > 0;
                error.appName = valid.appName?'':'App Name is required.';
                break;           
            default:
                break;
        }

        this.setState({
            formError:error,
            formValid:valid
        })
    }
    addMainServiceBtn = async (e, isServices) => {
        e.preventDefault();
        const { formError, mainService, appName } = this.state;

        if(mainService==='' && appName==='') {
            this.setState ({
                formError: {
                    ...formError,
                    mainService: 'Main service is required',
                    appName: 'App name is required',
                }
            })
        }
        else if (mainService === '' ) {
            this.setState ({                    
                formError: {
                    ...formError,
                    mainService: 'Main service is required'
                }
            })
        }
        else if (appName === '' ){
            this.setState ({
                formError: {
                    ...formError,
                    appName: 'App name is required',
                }
            })
        }
        else {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization':localStorage.getItem('token')
            }
            const { mainService, appName, categoryStatus, showMenu, showTopOnWeb } = this.state
            const to = new URLSearchParams(this.props.location.search).get('to');
            const page_type = new URLSearchParams(this.props.location.search).get('page_type');
            
            let formData = new FormData()
            formData.append('name',mainService)
            formData.append('name_app',appName)
            formData.append('status',categoryStatus)
            formData.append('isMainMenu',showMenu)
            formData.append('pageType', page_type)
            formData.append('isDisplay', showTopOnWeb )
            
            const response = await apiHalper('addMainServices', 'POST', formData, headers)
            const res = response.data;
            if(!res.status) {
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                toster_error(res.message);
                return true
            }
            const { history } = this.props;
            const decode = jwtDecode(res.data)
            toster_sucess(res.message);
            history.push(`/seo/add-pagecontent?id=${decode.insertedId}&parentPath=${page_type}&to=${to}`)
            
            this.setState ({
                formError: {
                    ...formError,
                    mainService: '',
                    appName: '',
                    apiErr: '',
                    isLoader: false,
                }
            })
            //toster_error(res.message);
        }
    }
    showMenuChecked = (e) => {
        const { checked } = e.target
        let checkVal = 0
        
        if ( checked === true ) {
            checkVal = 1
        }
        else {
            checkVal = 0
        }
        this.setState ({
            showMenu: checkVal
        })
    }
    showTopOnWebChecked = (e) => {
        const { checked } = e.target
        let checkVal = 0
        
        if ( checked === true ) {
            checkVal = 1
        }
        else {
            checkVal = 0
        }
        this.setState ({
            showTopOnWeb: checkVal
        })
    }
    categoryRadioChange = (val) => {
        this.setState({
            categoryStatus: val
        })
    }
    render() {
        const { mainService, appName, formError, categoryStatus, isLoader, showTopOnWeb } = this.state;
        const isUrl = new URLSearchParams(this.props.location.search).get('value');
        return (
            <div className="add-service main-service">
                <form action="/" method="POST"onSubmit={(e) => (
                    isUrl === 'services' ? this.addMainServiceBtn(e, true) : this.addMainServiceBtn(e, false) 
                    )}>
                    <div className="modal_header">
                        {isUrl === 'services'? 
                        <h4 className="model_title">Add Main Service <Link to="/seo/services-category" className="btn-back">Back</Link></h4>
                        :
                        <h4 className="model_title">Add Main Service <Link to="/seo/menu" className="btn-back">Back</Link></h4>
                        }
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label>Main Service</label>
                                <input type="text" name="mainService" value={mainService} className={formError.mainService !== ''?"form_control2 error_border":"form_control2"} placeholder="Main Service name" onChange={this.inputHandler} autoComplete="off" />
                                {formError.mainService !== ''?<p className="error_text pos_a">{formError.mainService}</p>:null}
                            </div>
                            <div className="col-md-12 form-group">
                                <label>App Name</label>
                                <input type="text" name="appName" value={appName} className={formError.appName !== ''?"form_control2 error_border":"form_control2"} placeholder="App name" onChange={this.inputHandler} autoComplete="off" />
                                {formError.appName !== ''?<p className="error_text pos_a">{formError.appName}</p>:null}
                            </div>                            
                            <div className="form-group col-md-12 radio-group">
                                <label>Category Status</label>
                                <p><input type="radio" id="categoryStatus" name="radioGroup" defaultChecked={categoryStatus === 1} onChange={()=> this.categoryRadioChange(1)} /><label htmlFor="categoryStatus">Active</label></p>
                                <p><input type="radio" id="categoryStatus2" name="radioGroup" defaultChecked={categoryStatus === 0}  onChange={()=> this.categoryRadioChange(0)} /><label htmlFor="categoryStatus2">Unactive</label></p>
                            </div>
                            <div className="form-group col-md-12" style={{display:'flex', gap:'10px'}}>                              
                                <div className="checkbox-group">
                                    <input type="checkbox" value="1" id="mainmenu" onChange={this.showMenuChecked} className="styled-checkbox" />
                                    <label htmlFor="mainmenu">Show in Main Menu</label>
                                </div>
                                <div className="checkbox-group">
                                        <input type="checkbox" value={showTopOnWeb} name="showOnWebsite" id="isDisplay" defaultChecked={showTopOnWeb === 1} onChange={(e) => this.showTopOnWebChecked(e)} className="styled-checkbox" />
                                        <label htmlFor="isDisplay">Display on Website</label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save & Next"}</button>
                    </div>
                </form>
            </div>
        )
    }
}
