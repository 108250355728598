import React, { Component } from 'react';
import { apiHalper } from '../../../helpers/ApiHelper';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
import { Link } from 'react-router-dom';

export default class pageFAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            pageID: null,
            faqTitle: '',
            faqArray: [{ qa: '', ans: '', qaErr: '', ansErr: '' }],
            formError: {
                faqTitle: ''
            },
            formValid: {
                faqTitle: false
            },
        }
    }

    componentDidMount() {
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            pageID: myParam
        })
        if (myParam === '') {
            this.props.history.push('/seo/add-pagecontent')
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value));
    }

    faqHandler = (e, i) => {
        const { name, value } = e.target;
        const list = [...this.state.faqArray];
        list[i][name] = value;
        if (list[i].qa !== '') {
            list[i].qaErr = ''            
        }
        else {
            list[i].qaErr = 'Question is Required'
        }
        
        if (list[i].ans !== '' ) {
            list[i].ansErr = ''
        }
        else {
            list[i].ansErr = 'Answer is Required'
        }
        
        this.setState({
            faqArray: list            
        })
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch (name) {
            case 'faqTitle':
                valid.faqTitle = value.length > 0;
                error.faqTitle = valid.faqTitle ? '' : 'FAQ Title is required.';
                break;           
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    addMoreFAQ = () => {
        this.setState({
            faqArray: [...this.state.faqArray, { qa: '', ans: '', qaErr: '', ansErr: '' }]
        })
    }

    removeMoreFAQ = (index) => {
        const list = [...this.state.faqArray];
        list.splice(index, 1);
        this.setState({
            faqArray: list
        });
    }
    SkipSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            isLoader: true
        });       
        const { history } = this.props;        
        const isUrl = new URLSearchParams(this.props.location.search).get('value');
            if(isUrl === 'services'){
                    history.push(`/seo/add-metatag?id=${this.state.pageID}&value=services`)
            }else{
                if(isUrl === "sub")
                    history.push(`/seo/add-metatag?id=${this.state.pageID}&value=sub`)
                else
                    history.push(`/seo/add-metatag?id=${this.state.pageID}`);
            }
    }
    submitContent = async (e) => {
        e.preventDefault();
        const { faqTitle, formError } = this.state;
        const list = [...this.state.faqArray];
        let index = null
        for (let i = 0; i < list.length; i++) {
            if (list[i].qa !== '' ) {
                list[i].qaErr = ''
            }
            else {
                list[i].qaErr = 'Question is Required'
                index= i
            }
            if (list[i].ans !== '' ) {
                list[i].ansErr = ''
            }
            else {
                list[i].ansErr = 'Answer is Required'
                index= i
            }
        }

        this.setState({
            faqArray: list
        });

        if (faqTitle === '' && index !== null) {            
            this.setState({
                faqArray: list,
                formError: {
                    ...formError,
                    faqTitle: 'Faq title is required'
                }
            })
        }
        else if (faqTitle === '') {
            this.setState({
                formError: {
                    ...formError,
                    faqTitle: 'Faq title is required'
                }
            })
        }
        else if ( index !== null){
            this.setState({
                faqArray: list
            })
        }
        else {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const { pageID, faqTitle, faqArray } = this.state
            const faqArr = faqArray.map(info => { return { "question": info.qa, "answer": info.ans }})
            const to = new URLSearchParams(this.props.location.search).get('to');
            const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
            let formData = new FormData()
            formData.append('serviceCategoryID', pageID)
            formData.append('faqTitle', faqTitle)
            formData.append('faqContents', JSON.stringify(faqArr)) 
            formData.append('parentPath', parentPath)
            
            const response = await apiHalper('addFAQs', 'POST', formData, headers)

            const res = response.data;
            if (!res.status) {
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                toster_error(res.message);
                return true
            }
            const { history } = this.props;
            toster_sucess(res.message);
            history.push(`/seo/add-metatag?id=${this.state.pageID}&parentPath=${parentPath}&to=${to}&prev=add-faqs`)
          
            this.setState({
                formError: {
                    ...formError,
                    faqTitle: '',
                    faqArray: [{
                        qa: '',
                        ans: '',
                        qaErr: ''
                    }]
                }
            })
        }
    }
    render() {
        const { faqArray, faqTitle, formError, isLoader, pageID } = this.state;        
        const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
        const to = new URLSearchParams(this.props.location.search).get('to');
        return (
            <div className="page-content">
                <h4 className="model_title">Page FAQ's 
                <Link to={`/seo/add-pagecontent?id=${pageID}&parentPath=${parentPath}`} className="btn-back">Back</Link>
                </h4>
                <form action="/" method="POST" onSubmit={this.submitContent}>
                    <div className="tabContent">
                        <div className="div_3">
                            <div className="form_group">
                                <label>FAQ Title</label>
                                <input type="text" name="faqTitle" className={formError.faqTitle !== '' ? "form_control3 error_border" : "form_control3"} value={faqTitle} onChange={this.inputHandler} autoComplete="off" placeholder="Ex. Graphic Designers FAQ's" />
                                {formError.faqTitle !== '' ? <p className="error_text pos_a">{formError.faqTitle}</p> : null}
                            </div>
                        </div>
                        <div className={faqArray.length > 1 ? "more_wrap2 padR_15" : "more_wrap2"}>
                            {faqArray.map((info, i) =>
                                <div className={"more_block2"} key={i}>
                                    <div className="form_group">
                                        <label>{i + 1}. Question</label>
                                        <input type="text" name="qa" className={info.qaErr !== '' ? "form_control3 error_border" : "form_control3"} value={info.qa} onChange={(e) => this.faqHandler(e, i)} placeholder="Ex. What is Graphic Designers?" autoComplete="off" />
                                        {info.qaErr !== '' ? <p className="error_text pos_a">{info.qaErr}</p> : null}
                                    </div>
                                    <div className="form_group">
                                        <label>{i + 1}. Answer</label>
                                        <textarea className={info.ansErr !== '' ? "form_control3 textarea error_border" : "form_control3 textarea"} name="ans" value={info.ans} onChange={(e) => this.faqHandler(e, i)} placeholder="Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry."></textarea>
                                        {info.ansErr !== '' ? <p className="error_text pos_a">{info.ansErr}</p> : null}
                                    </div>
                                    {i > 0 ? <button type="button" className="btn_removeMore" onClick={() => this.removeMoreFAQ(i)}>Remove</button> : null}
                                </div>
                            )}
                        </div>
                        <button type="button" className="btn_addMore" onClick={this.addMoreFAQ}>Add More</button>
                    </div>
                    <div className="bg_submit2 right-mx120 two-button">
                        <Link to={`/seo/add-metatag?id=${this.state.pageID}&parentPath=${parentPath}&to=${to}&prev=add-faqs`} className="btn-submit">Skip</Link>
                        <button type="submit" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save & Next"}</button>
                    </div>
                </form>
            </div>
        )
    }
}
