import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import NoDataIndication from '../TableLoader';
import dummy24Task from '../../assets/images/dummy24Task.png';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import Star from '../../assets/images/star.svg';
import RankModal from './RankModal';
import ConfirmModal from '../SEO/ConfirmModal';
import Modal from 'react-bootstrap/Modal';
import DataTable from '../JqueryDataTable';
import Select from 'react-select';
import LoaderImg from '../../assets/images/user-loader.gif';
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from 'react-responsive-carousel';

import InfiniteScroll from "react-infinite-scroll-component";
import ScrollLoader from '../ScrollLoader';
import LoaderComponent from '../LoaderComponent';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import { toster_error, toster_sucess } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';

import './index.css';
import { enable } from 'workbox-navigation-preload';

class BestSellers extends Component {
    _unmounted = false
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            issLoader: false,
            messageAApi: '',
            isTester: false,
            isClearable: true,
            isTopRate: false,
            viewBtn: 0,
            // orderBy: '',
            sortBy: 'DESC',
            selectedOption: null,
            orderBy: '',
            gigType: 0,
            mainCate: [],
            days: [],
            gigList: [],
            totalSeleRecord: 0,
            imageVerify: '',
            isModal: false,
            editModal: false,
            editData: null,
            addRModal: false,
            isConFModal: false,
            startDate: new Date(),
            reviewType: 0,
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            isSelectedLoader: false,
            hideConfirmation: false,
            hiddenField: '',
            gigId: null,
            gigTitle: '',
            gDescription: '',
            comment: '',
            noReviews: '',
            clarity: 0,
            ratingValid: false,
            reason: '',
            reviewGigData:[],
            rpage: 1,
            isLoader: false,
            isSGLoader: false,
            messageGigApi: '',
            profileId: null,
            rReviewModal: false,
            isAdd:false,
            gigContract: 0,
            review_id: 0,
            title:'',
            formError: {
                title:'',
                gigTitle: '',
                gDescription: '',
                comment: '',
                noReviews: '',
                reason: '',
            },
            formValid: {
                title:'',
                gigTitle: false,
                gDescription: false,
                comment: false,
                noReviews: false,
                reason: false
            },
        }
    }

    componentDidMount() {
        this._unmounted = true
        this.getMainCate();
        const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, rpage, profileId } = this.state;
        this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
     
    }

    componentWillUnmount() {
        this._unmounted = false
    }

    getMainCate = async () => {
        try {
            if (this._unmounted) {
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                   // "Access-Control-Allow-Origin": '*'
                }

                const response = await apiHalper('getMainServices', 'GET', null, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    const option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
                    this.setState({
                        mainCate: option,
                    })
                } else {
                    this.setState({
                        mainCate: []
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    getBestGigList = async (page, search, sizePerPage, selectedOption, isTester, orderBy, sort,rpage) => {
        try {
            this.setState({
                isLoader: true,
                gigList: [],
                viewBtn: 0,
                isTopRate: false,
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('gigType', 'ALL')
            formData.append('pageNo', page)
            formData.append('searchBy', search)
            // formData.append('dateFrom', '')
            // formData.append('dateTo', '')
            // formData.append('serviceCategoryID', selectedOption ? selectedOption.value : 0)
            formData.append('serviceCategoryID', 4352)
            formData.append('showTester', isTester ? 1 : 0)
            formData.append('limit', sizePerPage)
            formData.append('orderBy', orderBy ? orderBy.label : '')
            // formData.append('orderBy', type)
            formData.append('gigCreateType', this.state.gigType)
            formData.append('sortBy', sort)

            const response = await apiHalper('getGigLists', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    gigList: data.data,
                    totalRecord: data.count,
                    viewBtn: 0,
                    isTopRate: false
                });
            }
            else {
                this.setState({
                    isLoader: false,
                    gigList: [],
                    viewBtn: 0,
                    isTopRate: false
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    getGigReviewListApi = async (id,page, gigID) => {
        // if (this._ismounted) {
            try {
                // const id = this.state.profileId;
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                let formData = new FormData();
                formData.append('profile_id', id)
                formData.append('page_no', page)
                formData.append('gigID', gigID)

                const res = await apiHalper('getGigReview', 'POST', formData, headers);
                if (res.data.status === true) {
                    const data = jwtDecode(res.data.data);
                    if (data.length > 0) {
                        if(page > 1) {
                            this.setState({
                                isLoader: false,
                                isSGLoader: true,
                                messageGigApi: '',
                                reviewGigData: [...this.state.reviewGigData, ...data]
                            })
                        } else {
                            this.setState({
                                isLoader: false,
                                isSGLoader: true,
                                messageGigApi: '',
                                reviewGigData: data
                            })
                        }
                    } else {
                        this.setState({
                            isLoader: false,
                            isSGLoader: false,
                            messageGigApi: ''
                        })
                    }
                } else {
                    this.setState({
                        isLoader: false,
                        isSGLoader: false,
                        messageGigApi: res.data.message,
                        // reviewData: []
                    })
                }

            } catch (err) {
                this.setState({
                    isLoader: false,
                    isSGLoader: false,
                    messageGigApi: 'Network Error'
                })
            }
        // }
    }

    nextpageGigReviews = () => {
        const inc = this.state.rpage + 1;
        const {profileId, gigId}= this.state;
        if (this.state.isSGLoader) {
            this.setState({
                rpage: inc
            })
            this.getGigReviewListApi(profileId, inc, gigId)
        } else {
            this.setState({
                isSGLoader: false
            })
        }

    }
    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'gigTitle':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'count_rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            // case 'generalRank':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case 'categoryRank':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case '5D':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case '15D':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case '30D':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case '180D':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            // case 'total_contract':
            //     return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'price':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    hideGig = async (e) => {
        e.preventDefault();

        if (this.state.reason === '' && this.state.hiddenField === "0") {
            let error = this.state.formError;
            let valid = this.state.formValid;
            switch ('reason') {
                case 'reason':
                    valid.reason = (this.state.reason).length > 0;
                    error.reason = valid.reason ? '' : 'Reason must required!';
                    break;
                default:
                    break;
            };
            this.setState({
                formError: error,
                formValid: valid
            });
        }
        else {
            try {
                this.setState({
                    issLoader: true,
                    messageAApi: '',
                })
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();

                formData.append('gigID', this.state.gigId)
                formData.append('hidden', this.state.hiddenField === "1" ? "0" : "1")
                formData.append('reason', this.state.reason)

                const res = await apiHalper(`hideGig`, 'POST', formData, headers);
                if (res.data.status === true) {
                    this.setState({
                        issLoader: false,
                        messageAApi: ''
                    });
                    toster_sucess(res.data.message)
                } else {
                    this.setState({
                        issLoader: false,
                        messageAApi: res.data.message
                    });
                }
                this.closeModal();
                const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy } = this.state;
                this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)

            } catch (err) {
                this.setState({
                    issLoader: false,
                    messageAApi: 'Network Error'
                })
            }
        }
    }

    inputHandler = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        }, () => this.validationChecked(name, value));
    }
    validationChecked = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const reg = /^([0-9]+[\\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        switch (name) {
            case 'gigTitle':
                valid.gigTitle = value.length > 0;
                error.gigTitle = valid.gigTitle ? '' : 'Gig Title must required!';
                break;
            case 'gDescription':
                valid.gDescription = value.length > 0;
                error.gDescription = valid.gDescription ? '' : 'Gig Description must required!';
                break;
            case 'comment':
                valid.comment = value.length > 0;
                error.comment = valid.comment ? '' : 'Comment is required!';
                break;
            case 'noReviews':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        if (value.length > 150) {
                            valid.noReviews = false
                            error.noReviews = 'You can add max 150 reviews at that time.';
                        }
                        else {
                            valid.noReviews = true
                            error.noReviews = '';
                        }
                    } else {
                        valid.noReviews = false
                        error.noReviews = 'Enter only numberic value';
                    }
                } else {
                    valid.noReviews = false;
                    error.noReviews = 'No of reviews must required';
                }
                break;
            case 'reason':
                valid.reason = value.length > 0;
                error.reason = valid.reason ? '' : 'Reason must required!';
                break;
            default:
                break;
        };

        this.setState({
            formError: error,
            formValid: valid
        });
    }

    openHideConfirmation = (data) => {
        this.setState({
            gigId: data.gigID,
            hideConfirmation: true,
            hiddenField: data.hidden
        })
    }

    openEModal = (data) => {
        this.setState({
            gigId: data.gigID,
            gigTitle: data.gigTitle,
            gDescription: data.description,
            editModal: true,
            formError: {
                gigTitle: '',
                gDescription: '',
            }
        })
    }

    addReviewModal = (data) => {
        this.setState({
            addRModal: true,
            isAdd:true,
            gigId: data.gigID ? data.gigID : data,
            comment: '',
            noReviews: '',
            clarity: 0,
            formError: {
                comment: '',
                noReviews: '',
            },
            formValid: {
                comment: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            reviewType: 0,
            isLoader: false
        })
    }

    editReviewModal = (data) => {
        this.setState({
            addRModal: true,
            isAdd:false,
            review_id:data.review_id,
            gigId: data.gigID ? data.gigID : data,
            title: data.title,
            comment: data.comment,
            noReviews: data.rate,
            clarity: data.rate,
            formError: {
                title:'',
                comment: '',
                noReviews: '',
            },
            formValid: {
                title: false,
                comment: false,
                noReviews: false
            },
            ratingValid: true,
            messageAApi: '',
            reviewType: data.dummy_review,
            isLoader: false
        })
    }

    
    viewGigReview = (data) => {
        this.setState({
            gigId: data.gigID,
            profileId: data.profile_id,
            rReviewModal: true,
        })
        this.getGigReviewListApi(data.profile_id, 1, data.gigID);
        
    }

    handleStartdate = (event, picker) => {
        const startDate = picker.startDate._d;
        this.setState({
            startDate: startDate
        });
    }

    ratinghandler = (rating) => {
        this.setState({
            clarity: rating,
            ratingValid: true,
        });
    }

    reviewTypeHandler = (val) => {
        this.setState({
            reviewType: val
        })
    }

    addMReview = async (e) => {
        e.preventDefault();
        this.setState({
            issLoader: true
        })

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const { singleData, comment, clarity, startDate, reviewType, noReviews } = this.state;

        let formData = new FormData();
        if (singleData !== null) {
            formData.append('gigID', this.state.gigId)
        }
        if (reviewType === 0) {
            formData.append('comment', comment);
            formData.append('numberOfReview', 1)
        } else {
            formData.append('numberOfReview', noReviews)
        }
        formData.append('reviewType', reviewType)
        formData.append('rate', clarity)

        formData.append('reviewDate', moment(startDate).format('YYYY-MM-DD'))

        const res = await apiHalper(`addGigReviews`, 'POST', formData, headers);
        if (res.data.status === true) {
            this.setState({
                issLoader: false,
                messageAApi: ''
            });
            this.closeModal();
            toster_sucess('Review Added Successfully...');
            const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, isTopRate } = this.state;

            if (isTopRate) {
                this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
            }
            else {
                this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
            }

        } else {
            this.setState({
                issLoader: false,
                messageAApi: res.data.message
            });
        }

    }

    editMReview = async (e) => {
        e.preventDefault();
        this.setState({
            issLoader: true
        })

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const {  comment, startDate, reviewType, noReviews, review_id } = this.state;

        let formData = new FormData();
        formData.append('review_id', review_id)
        formData.append('comment', comment)
        formData.append('rate', noReviews)
        formData.append('reviewType', reviewType)
        formData.append('reviewDate', moment(startDate).format('YYYY-MM-DD'))

        const res = await apiHalper(`editGigReviews`, 'POST', formData, headers);
        if (res.data.status === true) {
            this.setState({
                issLoader: false,
                messageAApi: ''
            });
            this.closeEditModal();
            toster_sucess('Review Update Successfully...');
            const {gigId, profileId, page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, isTopRate } = this.state;

            if (isTopRate) {
                this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
            }
            else {
                this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
                this.getGigReviewListApi(profileId, page, gigId);
            }
            
        } else {
            this.setState({
                issLoader: false,
                messageAApi: res.data.message
            });
        }

    }

    openModal = (data) => {
        this.setState({
            isModal: true,
            singleData: data,
            title: '',
            description: '',
            noReviews: '',
            clarity: 0,
            formError: {
                title: '',
                description: '',
                noReviews: '',
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            reviewType: 0
        })
    }

    closeModal = () => {
        this.setState({
            isModal: false,
            editModal: false,
            addRModal: false,
            rReviewModal:false,
            hideConfirmation: false,
            reviewGigData:[],
            reason: '',
            formError: {
                gigTitle: '',
                gDescription: '',
                reason: '',
            }
        })
    }
    closeEditModal = () => {
        this.setState({
            isModal: false,
            editModal: false,
            addRModal: false,
            rReviewModal:true,
        })
    }
    editGig = async (e) => {
        e.preventDefault();

        try {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();

            formData.append('gigID', this.state.gigId)
            formData.append('gigTitle', this.state.gigTitle)
            formData.append('description', this.state.gDescription)

            const response = await apiHalper('editGig', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                toster_sucess(res.message);
                this.closeModal();
            }
            else {
                this.setState({
                    isLoader: false
                });
                return true
            }
            const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy } = this.state;
            this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
        }
        catch (err) {
            console.log(err.message);
        }
    }
    rankFormSubmit = async (general, category) => {
        const { page, search, sizePerPage, selectedOption, isTester, isTopRate, orderBy, sortBy } = this.state;
        try {
            this.setState({
                issLoader: true,
                messageAApi: ''
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();

            formData.append('gigID', this.state.singleData.gigID)
            formData.append('generalRank', general)
            formData.append('categoryRank', category)

            const res = await apiHalper(`setTopGig`, 'POST', formData, headers);
            if (res.data.status === true) {
                this.setState({
                    issLoader: false,
                    messageAApi: ''
                });
                this.closeModal();
                if (isTopRate) {
                    this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
                }
                else {
                    this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
                }
                toster_sucess('Rank Added Successfully...');

            } else {
                this.setState({
                    issLoader: false,
                    messageAApi: res.data.message
                });
            }

        } catch (err) {
            this.setState({
                issLoader: false,
                messageAApi: 'Network Error'
            })
        }
    }

    handleChange = (selectedOption) => {
        this.setState({
            selectedOption,
            search: '',
            rowSelected: [],
            showAll: true
        })
        document.getElementById('tableSearch').value = '';
        const { page, search, sizePerPage, isTester, isTopRate, orderBy, sortBy } = this.state;
        if (isTopRate) {
            this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
        }
        else {
            this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
        }
    }

    handleDayChange = (orderBy) => {
        this.setState({
            orderBy,
            search: '',
            rowSelected: [],
            showAll: true
        })
        document.getElementById('tableSearch').value = '';
        const { page, search, sizePerPage, isTester, selectedOption, isTopRate, sortBy } = this.state;
        if (isTopRate) {
            this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
        }
        else {
            this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
        }
    }

    handleTypeChange = (type) => {
        this.setState({
            gigType: type.value,
            search: '',
            rowSelected: [],
            showAll: true
        }, () => {

            const { page, search, sizePerPage, isTester, selectedOption, isTopRate, sortBy, orderBy } = this.state;
            if (isTopRate) {
                this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
            }
            else {
                this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
            }
        })
    }

    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }
    gigImgNotFound = (e) => {
        e.target.src = dummy24Task;
    }

    gigImages = (cell, row) => {
        var imageAarry = row.gig_images ? row.gig_images : [];
        let imgPath = row.gig_images_path;

        const images = [];
        if (imageAarry.length > 0) {
            imageAarry.map((img) => {
                return images.push({
                    original: imgPath + img.imageName,
                    thumbnail: imgPath + img.imageName,
                })
            })
        }
        else {
            images.push({
                original: dummy24Task,
                thumbnail: dummy24Task,
            })
        }

        //if (imageAarry.length > 1) {
        return <>
            <div className={row.hidden === "1" ? "gig-images hidden-field" : "gig-images"}>
                <Carousel images={images} hasIndexBoard={false} canAutoPlay={false} leftIcon={false} rightIcon={false} />
                {/* <Carousel showThumbs={false} infiniteLoop={true} showStatus={false} emulateTouch={true}>
                        { imageAarry.map((img) => {
                                return <div> <img src={imgPath + img.imageName} onError={(e) => this.gigImgNotFound(e)} alt="Gig Image" /></div>
                        }) }
                    </Carousel> */}
            </div>
        </>
        //}
        // else {
        //     let iPath = "";
        //     if (imageAarry.length === 0) { iPath = dummy24Task }
        //     else { iPath = imageAarry[0].imageName; }
        //     return <div className={row.hidden === "1" ? "gig-images hidden-field" : "gig-images"}>
        //         <img src={imgPath + iPath} onError={(e) => this.gigImgNotFound(e)} alt="Gig Image" />
        //     </div>
        // }
    }

    userName = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.img}`
        return <div className="flex-box">
            <div className={row.hidden === "1" ? "rating hidden-field" : "rating"}>
                <img src={Star} alt="Star" /> {row.starpoints === null ? <span>(0)</span> : <> {row.starpoints} <span>({row.count_rating})</span></>}
            </div>
            <div className={row.hidden === "1" ? "tb_user_wrap hidden-field" : "tb_user_wrap"}>
                <div className="useimg_block online_icon">
                    <img src={row.img !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
                </div>
                <p>{row.username}</p>
            </div>
        </div>;
    }

    leftField = (cell, row) => {
        // Tooltip display div---->
        // return <div className={row.hidden === "1" ? "c-tooltip hidden-field" : "c-tooltip"}><div className="margin_0auto minWd_220 one-line-detail">{cell === null ? "-" : cell}</div><span className="tooltiptext">{cell}</span></div>;
        return <div className={row.hidden === "1" ? "hidden-field" : ""}><div className="margin_0auto minWd_220 one-line-detail">{cell === null ? "-" : cell}</div></div>;
    }
    commonField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell === null ? "-" : cell}</span>;
    }
    daysField = (cell, row) => {
        return <div className={row.hidden === "1" ? "days hidden-field" : "days"}>
            <p>5 D: <span>{row['5D']}</span></p>
            <p>15 D: <span>{row['15D']} </span></p>
            <p>30 D: <span>{row['30D']}</span></p>
            <p>180 D: <span>{row['180D']} </span></p>
            <p>All: <span>{row.total_contract} </span></p>
        </div>;
    }
    centerField = (cell, row) => {
        return <span>{cell === null ? "-" : cell}</span>;
    }
    actionField = (cell, row) => {
        return <div className="flex-box">
            <p className="flex-p">
                <button type="button" title="View Review" className= {row.hidden === "1" ? "btn_edit2 hidden-field" : "btn-star-icon"} onClick={() => this.viewGigReview(row)}></button>
                {/* <button type="button" title="Add Review" className={row.hidden === "1" ? "btn_add hidden-field" : "btn_add"} onClick={() => this.addReviewModal(row)}></button> */}
                <button type="button" title={row.hidden === "0" ? "Hide Gig" : "Show Gig"} className={row.hidden === "0" ? "btn-show-icon" : "btn-hide-icon"} onClick={() => this.openHideConfirmation(row)}></button>
                <button type="button" title="Edit Gig" className={row.hidden === "1" ? "btn_edit2 hidden-field" : "btn_edit2"} onClick={() => this.openEModal(row)}></button>
                <button type="button" title="Delete Gig" className={row.hidden === "1" ? "btn_delete hidden-field" : "btn_delete"} onClick={() => this.deleteConfirmation(row)}></button>
                <button type="button" className={row.hidden === "1" ? "btn-view hidden-field" : "btn-view"} onClick={() => this.openModal(row)}>Set Rank</button>
            </p>
            <p className={row.hidden === "1" ? "price hidden-field" : "price"}>{row.price === 0 || row.price === null ? "Free" : "Starting at $" + row.price}</p>
        </div>;
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder }) => {
        const { selectedOption, isTester, isTopRate, sortBy } = this.state

        if (sortOrder === 'asc') {
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'ASC')
            }
            else {
                this.getBestGigList(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'ASC');
            }
        } else {
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'DESC')
            }
            else {
                this.getBestGigList(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'DESC');
            }
        }

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy)
            }
            else {
                this.getBestGigList(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy);
            }
        }
        if (type === 'search') {
            this.setState({
                search: searchText
            });
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy)
            }
            else {
                this.getBestGigList(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy);
            }
        }
    }

    viewTopRated = async (page, search, sizePerPage, selectedOption, isTester, type, sort) => {
        try {
            this.setState({
                isLoader: true,
                gigList: [],
                viewBtn: 1,
                isTopRate: true,
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('pageNo', page)
            formData.append('searchBy', search)
            formData.append('limit', sizePerPage)
            formData.append('serviceCategoryID', selectedOption ? selectedOption.value : 0)
            formData.append('showTester', isTester ? 1 : 0)
            formData.append('orderBy', type)
            formData.append('sortBy', sort)

            const response = await apiHalper('getTopGigLists', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    gigList: data.data,
                    totalRecord: data.count,
                    viewBtn: 1,
                    isTopRate: true,
                });
            }
            else {
                this.setState({
                    isLoader: false,
                    gigList: [],
                    viewBtn: 1,
                    isTopRate: true,
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    testerHandler = (e) => {

        let testerStatus = this.state.isTester
        if (testerStatus) {
            testerStatus = false
        }
        else {
            testerStatus = true
        }
        this.setState({
            isTester: testerStatus,
        });

        const { page, search, sizePerPage, selectedOption, isTopRate, orderBy, sortBy } = this.state;

        if (isTopRate) {
            this.viewTopRated(page, search, sizePerPage, selectedOption, testerStatus, orderBy, sortBy)
        }
        else {
            this.getBestGigList(page, search, sizePerPage, selectedOption, testerStatus, orderBy, sortBy);
        }
    }

    deleteGig = async (id) => {
        try {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();

            formData.append('gigID', id)

            const response = await apiHalper('deleteGig', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                toster_sucess(res.message);
                this.closeCofiClose();
            }
            else {
                this.setState({
                    isLoader: false
                });
                toster_error(res.message);
                return true
            }
            const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy } = this.state;
            this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)
        }
        catch (err) {
            console.log(err.message);
        }
    }

    deleteGigReview = async (gigContract, review_id, reviewType) => {
        try {
            this.setState({
                isLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('gigContract', gigContract)
            formData.append('review_id', review_id)
            formData.append('reviewType', reviewType)

            const response = await apiHalper('deleteGigReview', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                toster_sucess(res.message);
                this.closeCofiClose();
            }
            else {
                this.setState({
                    isLoader: false
                });
                toster_error(res.message);
                return true
            }
            const { page,  gigId, profileId} = this.state;
            this.getGigReviewListApi(profileId, page, gigId)
        }
        catch (err) {
            console.log(err.message);
        }
    }

    deleteConfirmation = (row) => {
        this.setState({
            gigId: row.gigID,
            updateType: 'status',
            editService: row,
            title: 'Delete Record',
            text: 'Are you sure you want to delete this record?',
        });
        this.openConfirmModal();
    }

    deleteGigReviewConfirmation = (row) => {
        this.setState({
            gigContract: row.gigContract,
            reviewType: row.dummy_review,
            review_id: row.review_id,
            title: 'Delete Record',
            text: 'Are you sure you want to delete this record?',
        });
        this.openConfirmModal();
    }

    openConfirmModal = () => {
        this.setState({
            isConFModal: true
        })
    }

    closeCofiClose = () => {
        this.setState({
            editData: null,
            isConFModal: false,
            updateType: null,
            title: '',
            text: ''
        });
        // const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy } = this.state;
        // this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy);
    }

    renderList = (data, i) => {
        return <li key={i}>
            <div className="review_block">
                <div className="review_titleWrap">
                    <h6>{data.title}</h6>
                    <p>{this.checkRatingStar(data.rate)} {data.rate} {data.dummy_review == 1 ? ' Dummy' : null} <span className="date_text">{moment(data.timestamp).format('MMM YYYY')}</span> </p>
                
                </div>
                <button type="button" className="btn_delete" onClick={() => this.deleteGigReviewConfirmation(data)}></button>
                <button type="button" className="btn_edit2" onClick={() => this.editReviewModal(data)}></button>

            </div>
            <p className="details_text">{data.comment}</p>
        </li>
    }

    checkRatingStar = (rating) => {

        const ar = String(rating).split('.');
        if (ar.length > 1) {
            return <span className={`star_icon star-${ar[0]}-${ar[1]}`}></span>
        } else {
            // console.log(ar);
            return <span className={`star_icon star-${ar[0]}-0`}></span>
        }
    }
    openDeleModal = (data) => {
        this.setState({
            isDelModal: true,
            reviewData: data,
        })
    }
    
    render() {

        const { title,isAdd, gigContract, review_id, gigId, gigType, startDate, reviewType, page, search, sizePerPage, selectedOption, isTester, isClearable, mainCate, viewBtn, orderBy, sortBy, editModal, hiddenField, hideConfirmation, issLoader, reason, gigTitle, gDescription, comment, noReviews, clarity, messageAApi, formError, formValid, ratingValid, isLoader, isSGLoader, reviewGigData, messageGigApi } = this.state;
        
        const secondExample = {
            classNames: 'custom_rating',
            a11y: false,
            isHalf: true,
            emptyIcon: <i className="star_empty" />,
            halfIcon: <i className="star_half" />,
            filledIcon: <i className="star_full" />,
        };

        const disabled = reviewType === 0 ? !formValid.comment || !ratingValid : !formValid.noReviews || !ratingValid;

        const columns = [
            { dataField: 'gig_images', text: 'Gig Images', sort: true, headerFormatter: this.headerSpan, formatter: this.gigImages },
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter: this.userName },
            { dataField: 'gigTitle', text: 'Gig Title', sort: true, headerFormatter: this.headerSpan, formatter: this.leftField },

            // { dataField: 'count_rating', text: 'Count Rating', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'total_contract', text: 'Days', sort: true, headerFormatter: this.headerSpan, formatter: this.daysField },

            // { dataField: 'generalRank', text: 'General Rank', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'categoryRank', text: 'Category Rank', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: '5D', text: '5D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: '15D', text: '15D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: '30D', text: '30D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: '180D', text: '180D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'total_contract', text: 'Total Contract', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'price', text: 'Price', sort: true, headerFormatter: this.headerSpan, formatter: this.priceField },

            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const dayOptions = [
            { value: 5, label: '5D' },
            { value: 15, label: '15D' },
            { value: 30, label: '30D' },
            { value: 180, label: '180D' }
        ]
        const typeOptions = [
            { value: 0, label: 'All Gigs' },
            { value: 1, label: 'Custom Gigs' },
            { value: 2, label: 'Standard Gigs' },
        ]

        const tableData = {
            columns: columns,
            data: this.state.gigList,
            keyId: 'gigID',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ]
        };
        const modalDa = {
            data: this.state.singleData,
            addMModal: this.state.isModal,
            submitRank: this.rankFormSubmit,
            handleClose: this.closeModal,
            issLoader: this.state.issLoader,
            messageAApi: this.state.messageAApi
        }
        const confirmModal = {
            title: this.state.title,
            text: this.state.text,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: () => this.deleteGig(this.state.gigId),
            isLoader: this.state.isLoader
        }
        const confirmDelReviewModal = {
            title: this.state.title,
            text: this.state.text,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: () => this.deleteGigReview(gigContract, review_id,reviewType),
            isLoader: this.state.isLoader
        }
        return (
            <section className="bg-balance best-seller">
                <div className="container-fluid">
                    <div className="balance_wrap grid-view-table">
                        <ul className="nesting_links">
                            <li><NavLink activeClassName="active" exact to='/best-gigs'>Best Gigs</NavLink></li>
                            <li><NavLink exact to='/best-sellers'>Best Sellers</NavLink></li>
                        </ul>
                        <div className="tester_view tester-record-right">
                            <p>Tester Record</p>
                            <button type="button" className={this.state.isTester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                                <span className="label_text"></span>
                            </button>
                        </div>

                        {viewBtn === 0 ? <button type="button" onClick={() => this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)} className="btn btn-primary top-rated">View Top Rated</button> : <button type="button" onClick={() => this.getBestGigList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy)} className="btn btn-primary top-rated">View Best Gigs</button>}

                        <div className="select_wrap main-service">
                            <div className="flex-box">
                                {/* <Select value={selectedOption} isClearable={isClearable} placeholder={"Select Category"} onChange={this.handleChange} options={mainCate} /> */}
                                <Select value={orderBy} isClearable={isClearable} placeholder={"Select Days"} options={dayOptions} onChange={this.handleDayChange} />
                                <Select value={gigType} isClearable={isClearable} placeholder={"Select Gig type"} options={typeOptions} onChange={this.handleTypeChange} />
                            </div>
                        </div>
                        {/* <DatePicker placeholderText="Select Start Date" selected={new Date()} minDate={new Date("2018-01-01")} maxDate={new Date()} selectsRange onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />                         */}
                        <DataTable TableData={tableData} />
                        <RankModal modalData={modalDa} />
                        <Modal show={editModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <div className="modal_view edit-gig-modal">
                                    <div className="modal_header">
                                        <h4 className="model_title">Edit Gig Detail<button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                                    </div>
                                    <div className="body_modals">
                                        <form method="POST" action="#" className="edit-detail" onSubmit={this.editGig}>
                                            <div className="form_group3">
                                                <label>Gig Title</label>
                                                <input type="text" name="gigTitle" className={formError.gigTitle !== '' ? "form_control2 error_border" : "form_control2"} value={gigTitle} onChange={this.inputHandler} placeholder="Gig Title" autoComplete="off" />
                                                {formError.gigTitle !== '' ? <p className="error_text">{formError.gigTitle}</p> : null}
                                            </div>
                                            <div className="form_group3">
                                                <label>Gig Description</label>
                                                <textarea name="gDescription" className={formError.gDescription !== '' ? "form_control2 error_border" : "form_control2"} value={gDescription} onChange={this.inputHandler} placeholder="Gig Description" autoComplete="off"></textarea>
                                                {formError.gDescription !== '' ? <p className="error_text">{formError.gDescription}</p> : null}
                                            </div>
                                            {/* {messageAApi !== '' ? <p className="error_text">{messageAApi}</p> : null} */}
                                            <div className="bg_submit2 mr-top">
                                                <button type="submit" className="btn-submit" >{issLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Update"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={hideConfirmation} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <div className="modal_view hide-confirmation-modal">
                                    <form method="POST" action="#" onSubmit={this.hideGig}>
                                        <div className="confirm-mod">
                                            <h3 className="conf_text">{hiddenField === "0" ? "Are you sure you want to hide gig?" : "Are you sure you want to show gig?"}</h3>
                                            {hiddenField === "0" ?
                                                <div className="form_group3">
                                                    <label>Give the reason to hide this gig</label>
                                                    <textarea name="reason" className={formError.reason !== '' ? "form_control2 error_border" : "form_control2"} value={reason} onChange={this.inputHandler} placeholder="Reason..." autoComplete="off"></textarea>
                                                    {formError.reason !== '' ? <p className="error_text">{formError.reason}</p> : null}
                                                </div>
                                                : null}
                                            <div className="bg_submit2 mr-top">
                                                {hiddenField === "0" ?
                                                    <button type="submit" className="btn-submit">{issLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Hide"}</button>
                                                    :
                                                    <button type="submit" className="btn-submit">{issLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Show"}</button>
                                                }
                                                <p>Or <button type="button" className="btn_cancel2" onClick={this.closeModal}>No</button></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal show={this.state.addRModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <div className="modal_view">
                                    <div className="modal_header">
                                        <h4 className="model_title">{isAdd ? "Add Reviews" : "Edit Review"} <button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                                    </div>
                                    <div className="body_modals">
                                        <div className="user_viewB gigId">
                                            <p><span>Gig ID: {gigId ? gigId : '--'}</span></p>
                                        </div>
                                        <form method="POST" action="#" onSubmit={isAdd ? this.addMReview : this.editMReview }>
                                            {isAdd ?
                                            <div className="form_group3">
                                                <label>Review Type</label>
                                                <div className="butn_g">
                                                    <button type="button" className={reviewType === 0 ? "radio_button active" : "radio_button"} onClick={() => this.reviewTypeHandler(0)}><i></i><span>Single Review</span></button>
                                                    <button type="button" className={reviewType === 1 ? "radio_button active" : "radio_button"} onClick={() => this.reviewTypeHandler(1)}><i></i><span>Multiple Reviews</span></button>
                                                </div>
                                            </div>
                                            :null
                                            }
                                            {reviewType === 0 ?
                                                <>
                                                    <div className="form_group3">
                                                        <label>Title</label>
                                                        <input type="text" name="title" className={formError.title !== '' ? "form_control2 error_border" : "form_control2"} value={title} onChange={this.inputHandler} placeholder="Enter title" autoComplete="off" />
                                                        {formError.title !== '' ? <p className="error_text">{formError.title}</p> : null}
                                                    </div>
                                                    <div className="form_group3">
                                                        <label>Comment</label>
                                                        <textarea name="comment" className={formError.comment !== '' ? "form_control2 textarea error_border" : "form_control2 textarea"} placeholder={'Enter comment'} value={comment} onChange={this.inputHandler}></textarea>
                                                        {formError.comment !== '' ? <p className="error_text">{formError.comment}</p> : null}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="form_group3">
                                                        <label>Comment</label>
                                                        <textarea name="comment" className={formError.comment !== '' ? "form_control2 textarea error_border" : "form_control2 textarea"} placeholder={'Enter comment'} value={comment} onChange={this.inputHandler}></textarea>
                                                        {formError.comment !== '' ? <p className="error_text">{formError.comment}</p> : null}
                                                    </div>
                                                    <div className="form_group3">
                                                        <label>No of Reviews (Maximun 150 Reviews)</label>
                                                        <input type="text" name="noReviews" className={formError.noReviews !== '' ? "form_control2 error_border" : "form_control2"} value={noReviews} onChange={this.inputHandler} placeholder="0" autoComplete="off" />
                                                        {formError.noReviews !== '' ? <p className="error_text">{formError.noReviews}</p> : null}
                                                    </div>
                                                </>
                                            }
                                            <div className="form_group3 flex mar_t20">
                                                <label>Review Date</label>
                                                <DateRangePicker initialSettings={{ singleDatePicker: true, locale: { format: 'DD MMM, YYYY' } }} startDate={moment(startDate).format('DD MMM, YYYY')} onApply={this.handleStartdate} ><input type="button" className="dateR_btn" /></DateRangePicker>
                                            </div>
                                            <div className="form_group3 flex mar_t20">
                                                <label>Rating</label>
                                                <ReactStars {...secondExample} value={clarity} onChange={this.ratinghandler} />
                                            </div>
                                            {messageAApi !== '' ? <p className="error_text">{messageAApi}</p> : null}
                                            <div className="bg_submit2 mr-top">
                                                <button type="submit" className="btn-submit" disabled={disabled || issLoader}>{issLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : isAdd ? "Add Review" : "Edit Review"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal show={this.state.rReviewModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <div className="review_wrap">
                                    <h6 className="subpage_title">Reviews <button type="button" className="btn-addRev" onClick={() => this.addReviewModal(gigId)}>Add</button></h6>
                                    <ul className="review_list" id="scrollableUl">
                                        { !isLoader ?
                                            reviewGigData.length > 0 ?
                                                <InfiniteScroll className="review_list width-forscrool" dataLength={reviewGigData.length} next={this.nextpageGigReviews} hasMore={isSGLoader} loader={<ScrollLoader />} scrollableTarget="scrollableUl">
                                                    {reviewGigData.map((info, i) => this.renderList(info, i))}
                                                </InfiniteScroll>
                                                :
                                                <li>
                                                    <div className="noRecord">
                                                        <p>{messageGigApi}</p>
                                                    </div>
                                                </li>
                                            
                                            :
                                            <LoaderComponent/>
                                        }
                                    </ul>
                                </div>
                            </Modal.Body>
                        </Modal>
                        
                        <ConfirmModal data={confirmModal} />
                        <ConfirmModal data={confirmDelReviewModal} />
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    clientCount: state.users.client,
    agentCount: state.users.agent,
    adminCount: state.users.admin
});

export default withRouter(connect(mapStateToProps)(BestSellers));
