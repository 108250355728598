import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import WalletTab from './WalletTab';
import HistoryTab from './HistoryTab';

class Wallet extends Component {
    render() {
        const { id } = this.props;
        return (
            <div className="tab_container">
                <div className="container-fluid">
                        <div className="balance_wrap">
                            <Tab.Container defaultActiveKey="wallet" className="tab_nav" id="balanceViews">
                                <Nav variant="pills" className="tab_nav">
                                    <Nav.Item>
                                        <Nav.Link eventKey="wallet">Wallet</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="history">History</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="wallet">
                                        <WalletTab uid={id} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="history">
                                        <HistoryTab uid={id} />
                                    </Tab.Pane>
                                </Tab.Content>                                
                        </Tab.Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(Wallet));
