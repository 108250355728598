import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';
import Modal from 'react-bootstrap/Modal';
import { jwtDecode } from '../../helpers/jwt_helper';
import { getCroppedImg } from '../../helpers/cropImg_helper';
import './index.css';

class AdminProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isImageView: false,
            isEdit: false,
            croppedAreaPixels: null,
            imgUrl: null,
            imgSrc: null,
            imgCropUrl: null,
            imgCropPath:null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 2 / 2,
            firstname: '',
            lastname: '',
            current_pass: '',
            email: '',
            new_pass: '',
            confirm_pass: '',
            formError: {
                firstname: '',
                lastname: '',
                current_pass: '',
                email: '',
                new_pass: '',
                confirm_pass: ''
            },
            formValid: {
                firstname: false,
                lastname: false,
                current_pass: true,
                email: false,
                new_pass: true,
                confirm_pass: true
            }
        }
    }

    componentDidMount() {
        const user = jwtDecode(localStorage.getItem('token'));
        this.setState({
            imgUrl: user.profile_pic,
            firstname:user.first_name,
            lastname:user.last_name,
            email:user.contact,
            formError:{
                firstname:user.first_name !== '' ?'':'First Name is Required',
                lastname:user.last_name !== '' ?'':'Last Name is Required',
                email:user.contact !== '' ?'':'Email is Required',
                current_pass:'',
                new_pass:'',
                confirm_pass:''
            },
            formValid:{
                firstname:user.first_name !== '' ?true:false,
                lastname:user.last_name !== '' ?true:false,
                email:user.contact !== '' ?true:false,
                current_pass:true,
                new_pass:true,
                confirm_pass:true
            }
        })
    }

    modalClose = () => {
        this.setState({
            isImageView: false,
            imgSrc: null,
            imgCropPath:null
            // imgCropUrl: null
        });
        // console.log(this.state.imgCropUrl);
    }

    fileHandler = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ imgSrc: reader.result, isImageView: true });
            };
            reader.readAsDataURL(file);
            // console.log(reader);
            // console.log();
        } else {
            console.log('else');
        }

    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log(croppedArea, croppedAreaPixels);
        this.setState({
            croppedAreaPixels
        })
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    showCropImg = async () => {
        const cropimg = await getCroppedImg(this.state.imgSrc, this.state.croppedAreaPixels);
        this.setState({ imgCropUrl: cropimg.dataUrl, imgCropPath:cropimg.path });
        this.modalClose();
    }


    inputHandler = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validationChecked(name, value));
    }

    validationChecked = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch (name) {
            case 'firstname':
                valid.firstname = value.length > 0;
                error.firstname = valid.firstname ? '' : 'Firstname must required!';
                break;
            case 'lastname':
                valid.lastname = value.length > 0;
                error.lastname = valid.lastname ? '' : 'Lastname must required!';
                break;
            case 'current_pass':
                valid.current_pass = value.length > 0;
                error.current_pass = valid.current_pass ? '' : 'current Password must required!';
                break;
            case 'email':
                if (value.length > 0) {
                    if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                        valid.email = true;
                        error.email = '';
                    } else {
                        valid.email = false;
                        error.email = 'Enter a valid email!';
                    }
                } else {
                    valid.email = false;
                    error.email = 'Email must required!';
                }
                break;
            case 'new_pass':
                if (value.length > 0) {
                    if (value.length >= 8) {
                        valid.new_pass = true;
                        error.new_pass = '';
                    } else {
                        valid.new_pass = false;
                        error.new_pass = 'New Password must be at least 8 characters long';
                    }
                } else {
                    valid.new_pass = false;
                    error.new_pass = 'New Password must required!';
                }
                break;
            case 'confirm_pass':
                if (value.length > 0) {
                    if (value === this.state.new_pass) {
                        valid.confirm_pass = true;
                        error.confirm_pass = '';
                    } else {
                        valid.confirm_pass = false;
                        error.confirm_pass = "Password doesn't match";
                    }
                } else {
                    valid.confirm_pass = false;
                    error.confirm_pass = 'Confirm Password must required!';
                }
                break;
            default:
                break;
        };

        this.setState({
            formError: error,
            formValid: valid
        });
    }

    updateUserD = (e) => {
        e.preventDefault();
        console.log('working...');
    }

    editHandle = () => {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }

    render() {

        const { isEdit, formError, formValid } = this.state;
        const disabled = !formValid.firstname || !formValid.lastname || !formValid.email;

        return (
            <div className="tab_container">
                <div className="form_wrap max-600">
                    <form method="POST" action="#" onSubmit={this.updateUserD}>
                        <div className="form_group">
                            <div className="img_profile">
                                <img src={this.state.imgCropUrl !== null?this.state.imgCropUrl:this.state.imgUrl} alt="profile" />
                                {isEdit?
                                    <label className="profile_img_label" htmlFor="adminProfile">
                                        <input type="file" id="adminProfile" onChange={this.fileHandler} />
                                        <span>Change</span>
                                    </label>:
                                    null
                                }
                            </div>
                        </div>
                        <div className="form_group divd_wrap">
                            <div className="wid_full">
                                <label className="label_form">First Name</label>
                                <input type="text" className={formError.firstname !== "" ? "form_control error_border" : "form_control"} name="firstname" placeholder="First Name" value={this.state.firstname} onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.firstname !== '' ? <p className="error_text">{formError.firstname}</p> : null}
                            </div>
                            <div className="wid_full">
                                <label className="label_form">Last Name</label>
                                <input type="text" className={formError.lastname !== "" ? "form_control error_border" : "form_control"} name="lastname" placeholder="Last Name" value={this.state.lastname} onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.lastname !== '' ? <p className="error_text">{formError.lastname}</p> : null}
                            </div>
                        </div>
                        <div className="form_group divd_wrap">
                            <div className="wid_full">
                                <label className="label_form">Email</label>
                                <input type="email" className={formError.email !== "" ? "form_control error_border" : "form_control"} name="email" placeholder="Email Address" value={this.state.email} onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.email !== '' ? <p className="error_text">{formError.email}</p> : null}
                            </div>
                            <div className="wid_full">
                                <label className="label_form">Current Password</label>
                                <input type="text" className={formError.current_pass !== "" ? "form_control error_border" : "form_control"} name="current_pass" placeholder="Current Password" onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.current_pass !== '' ? <p className="error_text">{formError.current_pass}</p> : null}
                            </div>

                        </div>
                        <div className="form_group divd_wrap">
                            <div className="wid_full">
                                <label className="label_form">New Password</label>
                                <input type="password" className={formError.new_pass !== "" ? "form_control error_border" : "form_control"} name="new_pass" placeholder="New Password" onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.new_pass !== '' ? <p className="error_text">{formError.new_pass}</p> : null}
                            </div>
                            <div className="wid_full">
                                <label className="label_form">Confirm Password</label>
                                <input type="password" className={formError.confirm_pass !== "" ? "form_control error_border" : "form_control"} name="confirm_pass" placeholder="Confirm Password" onChange={this.inputHandler} autoComplete="off" disabled={!isEdit} />
                                {formError.confirm_pass !== '' ? <p className="error_text">{formError.confirm_pass}</p> : null}
                            </div>
                        </div>
                        <div className="form_btngroup">
                            {
                                !isEdit ?
                                    <div><button type="button" className="btn_edit" onClick={this.editHandle}>Edit</button></div>
                                    :
                                    <div>
                                        <button type="submit" className="btn_save" disabled={disabled}>Save</button>
                                        <button type="button" className="btn_cancel" onClick={this.editHandle}>Cancel</button>
                                    </div>
                            }
                        </div>
                    </form>
                    <Modal show={this.state.isImageView} onHide={this.modalClose} className="modalImage_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <div className="image_view">
                                <Cropper classes={{containerClassName:'custom_cropImg'}} image={this.state.imgSrc} crop={this.state.crop} zoom={this.state.zoom} aspect={this.state.aspect} onCropChange={this.onCropChange} onCropComplete={this.onCropComplete} onZoomChange={this.onZoomChange} />
                            </div>
                            <div className="btton_group">
                                <button type="button" className="btn_close" onClick={this.modalClose}>Close</button>
                                <button className="btn_close" type="button" onClick={this.showCropImg}>Show Img</button>
                            </div>
                            
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(AdminProfile));
