import React, { Component, Fragment } from 'react';
import UserLoader from '../../assets/images/user-loader.gif';
import './Loader.css';

export default class LoaderComponent extends Component {
    render() {
        return (
            <Fragment>
                <div className="loader_block" id="loader">
                    <img src={ UserLoader } className="loader_img" alt="Loader" />
                </div>
            </Fragment>
        )
    }
}