import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './chathistory.css';

class ChatHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }

    }

    render() {
        return (
            <>
              <Container className='h-100'>
                <Row className='h-100'>
                    <Col>   
                      <Card className='h-100 card'>
                            <Card.Header className='text-center text-white'>Message</Card.Header>
                            <Card.Body className='d-grid'>
                                <Card.Text className='text-white sender'>
                                    Hello Sender
                                </Card.Text>
                                <Card.Text className='text-white reciver'>
                                    Hello Receiver
                                </Card.Text>
                                <InputGroup className="mb-3 mt-auto chatinputgroup">
                                    <Form.Control
                                        placeholder="typing......"
                                        aria-label="typing......"
                                    />
                                    <Button variant="outline-secondary">Sender</Button>
                                    <Button variant="outline-secondary">Receiver</Button>
                                </InputGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
              </Container>
            </>
        );
    }
}

export default ChatHistory;