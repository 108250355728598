import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import * as NUM_Helper from '../../helpers/NumberFormate';
import DataTable from '../../components/JqueryDataTable';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import NoDataIndication from '../TableLoader';
import DatePicker from 'react-datepicker'
import * as ACTIVE_TYPES from '../../redux/actions/types';
import moment from 'moment';
class CouponDetail extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            usedCoupList: [],
            isLoader: false,
            startDate: '',
            endDate: '',
            page: 1,
            search: '',
            totalRecord: 0,
            customTotal: 'adsad',
            sizePerPage: 10,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page, sizePerPage, startDate, endDate } = this.state
        this.getAllUsedCoupn(page, sizePerPage, startDate, endDate);
    }

    getAllUsedCoupn = async (page, sizePerPage, startDate, endDate) => {

        if (this._ismounted) {
            try {
                this.setState({
                    isLoader: true,
                    usedCoupList: []
                })
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //'Access-Control-Allow-Origin': '*'
                }

                const queryString = this.props.location.search;
                const urlParams = new URLSearchParams(queryString);
                const type = urlParams.get('id');

                let formData = new FormData();
                formData.append('couponID', type)
                formData.append('pageNo', page)
                formData.append('limit', sizePerPage)
                if (startDate === '' || startDate === null) {
                    formData.append('start_date', '')
                }
                else {
                    formData.append('start_date', moment(startDate).format('YYYY-MM-DD'))
                }
                if (endDate === '' || endDate === null) {
                    formData.append('end_date', '')
                }
                else {
                    formData.append('end_date', moment(endDate).format('YYYY-MM-DD'))
                }
                const response = await apiHalper('getCouponHistory', 'POST', formData, headers);
                const res = response.data;

                if (res.status) {
                    const data = jwtDecode(res.data);
                    console.log(data)
                    this.setState({
                        isLoader: false,
                        usedCoupList: data.data,
                        totalRecord: data.count,
                    })
                }
                else {
                    this.setState({
                        isLoader: false,
                        usedCoupList: []
                    });
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    messageApi: err.message
                })
            }
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'coupon_code':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'job_id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'order_date':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'coupon_for':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'discount_amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'fixed_price':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    orderNo = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    orderDate = (cell) => {
        return <span className="text-center margin_0auto">{cell ? moment(cell).format('MMM DD, YYYY') : "-"}</span>;
    }

    typeField = (cell, row) => {
        if (cell === 0) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Percentage"}</button>
        } else {
            return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Fixed"}</button>
        }
    }

    forField = (cell, row) => {
        switch (cell) {
            case 1:
                return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Bronze"}</button>
            case 2:
                return <button type="button" className={"btn-status silver margin_0auto"} disabled>{"Silver"}</button>
            case 3:
                return <button type="button" className={"btn-status gold margin_0auto"} disabled>{"Gold"}</button>
            case 4:
                return <button type="button" className={"btn-status vip margin_0auto"} disabled>{"VIP"}</button>
            case 5:
                return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"New User"}</button>
            case 6:
                return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Old User"}</button>
            case 7:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"Both User"}</button>
            case 8:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"AI User"}</button>
            default:
                return <button type="button" className={"btn-status gray margin_0auto"} disabled>--</button>
        }
    }

    limitCoupon = (cell, row) => {
        if (row.max_usage_per_user !== null) {
            return <span className="text-center margin_0auto">{cell}/{row.max_usage_per_user}</span>;
        } else {
            return <span className="text-center margin_0auto">{cell}/1</span>;
        }
    }

    discountField = (cell, row) => {
        if (row.type === 1) {
            return <span className="text-center margin_0auto">{cell + "(%)"}</span>;
        } else {
            return <span className="text-center margin_0auto">{cell + "($)"}</span>;
        }
    }

    amountField = (cell) => {
        return <span className="text-center margin_0auto">{cell ? "$" + cell : "-"}</span>;
    }

    oValueField = (cell) => {
        return <span className="text-center margin_0auto">{cell ? "$" + cell : "-"}</span>;
    }
    actionField = (cell, row) => {
        return <Link to={`/jobsDetails/${row.job_id}?type=offers`} className={row.job_id ? "btn-view margin_0auto" : "btn-view margin_0auto disable-link"} onClick={() => this.viewDetail(row)}>View</Link>;
    }

    viewDetail = (data) => {
        const { detailView } = this.props
        detailView(data)
    }

    statusField = (cell) => {
        if (cell === 1) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Active"}</button>
        } else {
            return <button type="button" className={"btn-status red margin_0auto"} disabled>{"Inactive"}</button>
        }
    }
    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }
    applyDates = () => {
        const { page, sizePerPage, startDate, endDate } = this.state
        this.getAllUsedCoupn(page, sizePerPage, startDate, endDate);
    }
    backHandler = () => {
        const { history } = this.props;
        history.push('/offers');
    }
    handleTableChange = (type, { page, sizePerPage, searchText }) => {

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            const { startDate, endDate } = this.state
            this.getAllUsedCoupn(page, sizePerPage, startDate, endDate);
        }
        if (type === 'search') {
            this.setState({
                search: searchText
            });
            const { page, sizePerPage, startDate, endDate } = this.state
            this.getAllUsedCoupn(page, sizePerPage, startDate, endDate);
        }
    }

    render() {

        const { startDate, endDate } = this.state;

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'coupon_code', text: 'Coupon Code', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'job_id', text: 'Order No.', sort: true, headerFormatter: this.headerSpan, formatter: this.orderNo },
            { dataField: 'order_date', text: 'Order Date', sort: true, headerFormatter: this.headerSpan, formatter: this.orderDate },
            { dataField: 'coupon_for', text: 'Coupon For', sort: true, headerFormatter: this.headerSpan, formatter: this.forField },            
            { dataField: 'discount_amount', text: 'Discount Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'fixed_price', text: 'Order Value', sort: true, headerFormatter: this.headerSpan, formatter: this.oValueField },            
            { dataField: 'action', text: "Action", sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // for: new user : 0, old user : 1, both : 2, silver : 3, gold : 4, vip : 5;
        // coupen type: percentage: 0, fixed:1;

        const tableData = {
            columns: columns,
            data: this.state.usedCoupList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            showTotal: true,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ]
        };

        return (
            <section className="bg-balance">
                {/* <div className="container-fluid"> */}
                <div className="balance_wrap">
                    <div className="btn_groupsB">
                        <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                        {/* <p className="subpage_title">Coupon History</p> */}
                    </div>
                    <div className="coupon-history">
                        <DatePicker placeholderText="Select Start Date" selected={startDate} minDate={new Date("2018-01-01")} maxDate={new Date()} onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />
                        <DatePicker placeholderText="Select End Date" selected={endDate} onChange={date => this.setEndDate(date)} minDate={startDate} maxDate={new Date()} endDate={endDate} dateFormat="yyyy-MM-dd" />
                        <button type="button" onClick={this.applyDates} className="btn btn-primary">Apply</button>
                    </div>
                    <DataTable TableData={tableData} />
                </div>
                {/* </div> */}
            </section>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(CouponDetail));
