import * as ACTION_TYPES from '../actions/types';
import * as TimeHelper from '../../helpers/TimeDiff';

const d = new Date();
const m = d.getMonth();
const month = TimeHelper.month_name(m);

const initialState = {
    isFilterModal:false,
    filterValue:null,
    filterSelect:null,
    isDdate:false,
    isMdate:false,
    isYdate:false,
    d_start_date:null,
    d_end_date:null,
    m_start_date:null,
    m_end_date:null,
    y_start_date:null,
    y_end_date:null,
    typeVal:null,
    filterD:'Today',
    dayValue:0,
    filterM:month,
    monthValue:(m + 1),
    filterY:d.getFullYear(),
    yearValue:d.getFullYear(),
    isApply:false
}

const reportReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.TOGGLE_FILTER_REPORT_MODAL:
            return{
                ...state,
                isFilterModal:!state.isFilterModal,
                filterSelect:action.payload,
            }
        case ACTION_TYPES.CLOSE_FILTER_REPORT_MODAL:
            return{
                ...state,
                isFilterModal:false
            }
        case ACTION_TYPES.TOGGLE_FILTER_REPORT_FIRST_VAL:
            return{
                ...state,
                filterD:action.payload.label,
                dayValue:action.payload.value,
                typeVal:action.payload.type,
                isDate:action.payload.isDate,
                d_start_date:action.payload.start,
                d_end_date:action.payload.end,
                isDdate:action.payload.isDate
            }
        case ACTION_TYPES.TOGGLE_FILTER_REPORT_SECOND_VAL:
            return{
                ...state,
                filterM:action.payload.label,
                monthValue:action.payload.value,
                typeVal:action.payload.type,
                isDate:action.payload.isDate,
                m_start_date:action.payload.start,
                m_end_date:action.payload.end,
                isMdate:action.payload.isDate
            }
        case ACTION_TYPES.TOGGLE_FILTER_REPORT_THIRD_VAL:
            return{
                ...state,
                filterY:action.payload.label,
                yearValue:action.payload.value,
                typeVal:action.payload.type,
                isDate:action.payload.isDate,
                y_start_date:action.payload.start,
                y_end_date:action.payload.end,
                isYdate:action.payload.isDate
            }
        case ACTION_TYPES.IS_APPLY_FILTER:
            return{
                ...state,
                isApply:action.payload
            }
        default:
            return state;
    }
}

export default reportReducers;