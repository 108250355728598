import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
// import * as NUM_Helper from '../../helpers/NumberFormate';
// import { numberWithCommas } from '../../helpers/NumberFormate';
import './index.css';

class CoupRepDetails extends Component {


    backHandler = () =>{
        const { history } = this.props;
        history.push('/offers/reportcoupon');
    }

    render() {
        // console.log(this.props);
        const { reportData } = this.props;
        // const { cid } = this.props.match.params;

        let couponFor = null;
        switch(reportData.coupon_for){
            case 1:
                couponFor = 'Bronze';
                break;
            case 2:
                couponFor = 'Silver';
                break;
            case 3:
                couponFor = 'Gold';
                break;
            case 4:
                couponFor = 'VIP';
                break;
            case 5:
                couponFor = 'New User';
                break;
            case 6:
                couponFor = 'Old User';
                break;
            case 7:
                couponFor = 'Both User';
                break;
            case 8:
                couponFor = 'AI User';
                break;
            default:
                couponFor = '--';
                break;
        }

        return (
            <section className="bg-balance">
                <div className="balance_wrap">
                    <div className="crDetails_wrap">
                        <div className="btn_groupsB pos_rel">
                            <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                            <p className="subpage_title">Coupon Report Detail</p>
                        </div>
                        <ul className="ul_cr_detail">
                            <li><p>Coupon Name: <span>{reportData.coupon_name}</span></p></li>
                            <li><p>Coupon Code: <span>{reportData.coupon_code}</span></p></li>
                            <li><p>Coupon Type: <span>{reportData.type === 1?"Percentage":"Fixed"}</span></p></li>
                            <li><p>Coupon For: <span>{couponFor}</span></p></li>
                            <li><p>Coupon Limit: <span>{reportData.num_of_users+'/'+reportData.max_usage_per_user}</span></p></li>
                            <li><p>Coupon Discount: <span>{reportData.type === 1?reportData.discount+'(%)':reportData.discount+'($)'}</span></p></li>
                            <li><p>Start Date: <span>{moment(reportData.start_date).format('MMM DD, YYYY')}</span></p></li>
                            <li><p>End Date: <span>{moment(reportData.end_date).format('MMM DD, YYYY')}</span></p></li>
                            <li><p>Coupon Status: <span>{reportData.status === 1?"Active":"Inactive"}</span></p></li>
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state =>({
    reportData:state.couponReport.detailsData
});


export default withRouter(connect(mapStateToProps)(CoupRepDetails));
