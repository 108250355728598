import * as ACTION_TYPES from '../actions/types';

const initialState = {
    editData:null,
    subServiceName:localStorage.getItem('subService'),
}

const pageContentReducers = (state= initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.EDIT_DATA_STORE:
            return{
                ...state,
                editData:action.payload,
            }
        case ACTION_TYPES.SERVICE_NAME:
            return{
                ...state,
                subServiceName:localStorage.getItem('subService')
            }
        default:
            return state;
    }
}

export default pageContentReducers;