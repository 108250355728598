import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Button from 'react-bootstrap/Button'

class ChatTapsM extends Component {
    
    render() {

        const {Show , handelCloseM, othermodel} = this.props;
        return (
            <div>
                <Modal show={Show} onHide={handelCloseM} aria-labelledby="contained-modal-title-vcenter" size="md" className="modal_design" centered>
                    <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Chat Taps<button type="button" onClick={handelCloseM}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                                    <div className="form_body2 list-class">
                                        <ul>
                                            <li>
                                                <label>keywords keywords</label>
                                                <div>
                                                    <Button variant="danger"> Delete</Button>
                                                    <Button variant="warning"> Edit</Button>
                                                    <Button>demo</Button>
                                                </div>
                                            </li>
                                            <li>
                                                <label>keywords</label>
                                                <div>
                                                    <Button variant="danger"> Delete</Button>
                                                    <Button variant="warning"> Edit</Button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="bg_submit2">
                                        <button className="btn-submit" onClick={othermodel}>Add New Tap</button>
                                        <p>Or <button type="button" className="btn_cancel2" onClick={handelCloseM}>Cancel</button></p>
                                    </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default  ChatTapsM