import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DataTable from '../../components/JqueryDataTable';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import NoDataIndication from '../TableLoader';
import DatePicker from 'react-datepicker'
import * as ACTIVE_TYPES from '../../redux/actions/types';
import ConfirmModal from '../SEO/ConfirmModal';
import moment from 'moment';
//import { selectFilter } from 'react-bootstrap-table2-filter';

class ManageNotificaiton extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            notificationList: [],
            appOptions: [],
            options: [],
            userType: '',
            appId: null,
            countryId: null,
            dormantDays: '',
            isLoader: false,
            page: 1,
            search: '',
            totalRecord: 0,
            customTotal: '',
            sizePerPage: 10,
            notiId: null,
            sendId: null,
            title: '',
            subtitle: '',
            startDate: '',
            showSendDate: "0",
            showPause: 0,
            formError: {
                title: '',
                subtitle: '',
            },
            formValid: {
                title: false,
                subtitle: false,
            },
            isSLoader: false,
            isConFModal: false,
            modalTitle: '',
            modalText: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page, sizePerPage } = this.state
        this.getNotificationList(sizePerPage, page);

        this.getAllApps();
        this.getAllCountry();
    }

    getAllApps = async () => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getAllApplication', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    appOptions: [],
                    isLoader: false
                })
            }
            const selectedApplist = jwtDecode(res.data)
            selectedApplist.unshift({ id: 0, name: "All Applications Selected" });

            this.setState({
                appOptions: selectedApplist.map(info => ({ value: info.id, label: info.name })),
                isLoader: false,
            });

        }
        catch (err) {
            console.error(err.message)
        }
    }

    getAllCountry = async () => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getCountries', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    options: [],
                    isLoader: false
                })
            }
            const selectedConList = jwtDecode(res.data)

            const sortList = selectedConList.sort((a, b) => {
                if (a.countryName > b.countryName) return 1
                if (a.countryName < b.countryName) return -1
                return 0;
            });
            sortList.unshift({ id: 0, countryName: "All Countries Selected" });
            this.setState({
                isLoader: false,
                options: sortList.map(info => ({ value: info.id, label: info.countryName })),
                // selectedOption: { value: sortList[0].id, label: sortList[0].countryName }
            })
        }
        catch (err) {
            console.error(err.message)
        }
    }

    getNotificationList = async (sizePerPage, page) => {

        if (this._ismounted) {
            try {
                this.setState({
                    isLoader: true,
                    notificationList: []
                })
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //'Access-Control-Allow-Origin': '*'
                }

                let formData = new FormData();
                formData.append('limit', sizePerPage)
                formData.append('pageNo', page)

                const response = await apiHalper('getNotificationList', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);

                    this.setState({
                        isLoader: false,
                        notificationList: data.data,
                        totalRecord: data.count,
                    })
                }
                else {
                    this.setState({
                        isLoader: false,
                        notificationList: []
                    });
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    messageApi: err.message
                })
            }
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'title':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'text':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span imgRight"}>{column.text}</span>;
            case 'sendDate':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'createdAt':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'sent':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    centerField = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    orderDate = (cell) => {
        return <span className="text-center margin_0auto">{cell ? moment(cell).format('MMM DD, YYYY') : "-"}</span>;
    }

    openModal = (data) => {
        // this.getAllApps()
        // this.getAllCountry()

        // const filters = JSON.parse(data.filterData)

        switch (data.userType) {
            case "0":
                this.setState({ userType: "All User" })
                break;
            case "1":
                this.setState({ userType: "Old User" })
                break;
            case "2":
                this.setState({ userType: "New User" })
                break;
            default:
                this.setState({ userType: "" })
                break;
        }

        var appNameArr = data.appName ? data.appName.split(',') : data.appName;
        var conNameArr = data.countryName ? data.countryName.split(',') : data.countryName;

        this.setState({
            isShowModal: true,
            appId: appNameArr ? appNameArr : ["All Applications Selected"],
            countryId: conNameArr ? conNameArr : ["All Countries Selected"],
            dormantDays: data.dormantDays,
        })

    }

    inputHandler = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validationChecked(name, value));
    }
    validationChecked = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const reg = /^[a-zA-Z_]*$/g;
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Title must required!';
                break;
            case 'subtitle':
                valid.subtitle = value.length > 0;
                error.subtitle = valid.subtitle ? '' : 'Subtitle must required!';
                break;
            case 'topicName':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.topicName = true
                        error.topicName = '';
                    } else {
                        valid.topicName = false
                        error.topicName = 'Special Character, Space and numbers are not allowed, You can only use Alphabates and Underscore';
                    }
                } else {
                    valid.topicName = false;
                    error.topicName = 'Topic Name must required';
                }
                break;
            default:
                break;
        };

        this.setState({
            formError: error,
            formValid: valid
        });
    }
    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }
    openEModal = (data) => {
        this.setState({
            isEditModal: true,
            notiId: data.id,
            title: data.title,
            subtitle: data.text,
            showSendDate: data.sendLater,
            formError: {
                title: '',
                subtitle: '',
            },
        })
    }

    openDuplicateModal = (data) => {

        switch (data.userType) {
            case "0":
                this.setState({ userType: "All User" })
                break;
            case "1":
                this.setState({ userType: "Old User" })
                break;
            case "2":
                this.setState({ userType: "New User" })
                break;
            default:
                this.setState({ userType: "" })
                break;
        }

        var appNameArr = data.appName ? data.appName.split(',') : data.appName;
        var conNameArr = data.countryName ? data.countryName.split(',') : data.countryName;

        this.setState({
            appId: appNameArr ? appNameArr : ["All Applications Selected"],
            countryId: conNameArr ? conNameArr : ["All Countries Selected"],
            dormantDays: data.dormantDays,

            isDupModal: true,
            notiId: data.id,
            title: data.title,
            subtitle: data.text,
            showSendDate: data.sendLater,
            formError: {
                title: '',
                subtitle: '',
            },
        })
    }

    sendChecked = (e) => {
        const { checked } = e.target
        let checkVal = "0"

        if (checked === true) {
            checkVal = "1"
        }
        else {
            checkVal = "0"
        }
        this.setState({
            showSendDate: checkVal
        })
    }
    sendPause = (e) => {
        const { checked } = e.target
        let checkVal = 0

        if (checked === true) {
            checkVal = 1
        }
        else {
            checkVal = 0
        }
        this.setState({
            showPause: checkVal
        })
    }
    editNotification = async (title, subtitle, startDate, showSendDate, notiId, showPause) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();

            formData.append('title', title)
            formData.append('text', subtitle)
            formData.append('sendDate', moment(startDate, ["YYYY-MM-DD h:mm A"]).format('YYYY-MM-DD HH:mm:ss'))
            formData.append('sendLater', showSendDate)
            formData.append('notificationID', notiId)
            formData.append('pause', showPause)

            const response = await apiHalper('editNotification', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_sucess(res.message);
                this.closeModal();
            }
            else {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_error(res.message);
                return true
            }
            const { page, sizePerPage } = this.state
            this.getNotificationList(sizePerPage, page);
        }
        catch (err) {
            console.log(err.message);
        }
    }

    duplicateNotification = async (isTester, selectedFilter, selectedOption, days, selectedUser, title, text, startDate, showSendDate, search) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            // App filter value
            let appFilterArr = [];
            for (let i = 0; i < selectedFilter.length; i++) {                
                let item = (this.state.appOptions).find(item => item.label === selectedFilter[i]);                
                appFilterArr.push(item.value)
            }

            // Country filter value
            let countryFilterArr = [];
            for (let i = 0; i < selectedOption.length; i++) {                
                let item = (this.state.options).find(item => item.label === selectedOption[i]);                
                countryFilterArr.push(item.value)
            }
            
            let formData = new FormData();
            formData.append('showTester', isTester ? 1 : 0)

            let commaSeparatedIds = appFilterArr.join(",");
            let commaSeparatedIdC = countryFilterArr.join(",")

            formData.append('appID', selectedFilter ? commaSeparatedIds : 0)
            formData.append('countryID', selectedOption ? commaSeparatedIdC : 0)
            formData.append('dormantDays', days ? days : 0)
            formData.append('userType', selectedUser.value ? selectedUser.value : 0)
            formData.append('title', title)
            formData.append('text', text)
            formData.append('topicName', this.state.notificationList[0].topicName)
            formData.append('sendDate', moment(startDate, ["YYYY-MM-DD h:mm A"]).format('YYYY-MM-DD HH:mm:ss'))
            formData.append('sendLater', showSendDate)
            formData.append('search_by', search)
            formData.append('duplicate_of', this.state.notificationList[0].id)

            const response = await apiHalper('sendOfferPush', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_error(res.message);
                return true
            }
            this.closeModal();
            const { page, sizePerPage } = this.state
            this.getNotificationList(sizePerPage, page);
        }
        catch (err) {
            console.log(err.message);
        }
    }
    sendConfirmation = (val) => {
        this.setState({
            updateType: 'status',
            sendId: val,
            modalTitle: 'Resend Notification',
            modalText: 'Are you sure you want to resend Notification?'
        });
        this.openConfirmModal();
    }

    openConfirmModal = () => {
        this.setState({
            isConFModal: true
        })
    }

    closeCofiClose = () => {
        this.setState({
            isConFModal: false,
            updateType: null,
            modalTitle: '',
            modalText: ''
        });
    }

    closeModal = () => {
        this.setState({
            isShowModal: false,
            isEditModal: false,
            isDupModal: false
        })
    }

    sendAgain = async () => {
        if (this._ismounted) {
            try {
                this.setState({
                    isLoader: true
                })
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                    //'Access-Control-Allow-Origin': '*'
                }

                let formData = new FormData();
                formData.append('notificationID', this.state.sendId)

                const response = await apiHalper('sendNotificationAgain', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    this.setState({
                        isLoader: false,
                    })
                    toster_sucess(res.message);
                    this.closeCofiClose();
                }
                else {
                    this.setState({
                        isLoader: false,
                    });
                    toster_error(res.message);
                    this.closeCofiClose();
                    return true
                }

                const { page, sizePerPage } = this.state
                this.getNotificationList(sizePerPage, page);

            } catch (err) {
                console.log(err);
                this.setState({
                    messageApi: err.message
                })
            }
        }
    }

    selectedList = (data, i) => {
        return <li key={i}>{data}</li>
    }

    actionField = (cell, row) => {
        return <div className="action_group text-center manage-icon">
            <button type="button" className="btn-view-icon" onClick={() => this.openModal(row)}></button>
            <button type="button" className="btn_edit2" onClick={() => this.openEModal(row)}></button>
            <button type="button" className="btn-duplicate" onClick={() => this.openDuplicateModal(row)}></button>
            {/* <button type="button" title="Resend" className="btn-resend" onClick={() => this.sendConfirmation(row.id)}></button> */}
        </div>
    }

    viewDetail = (data) => {
        const { detailView } = this.props
        detailView(data)
    }

    statusField = (cell) => {
        if (cell === "1") {
            return <button type="button" className={"btn-status green-text margin_0auto"} disabled>{"Send"}</button>
        } else if (cell === "2") {
            return <button type="button" className={"btn-status yellow-text margin_0auto"} disabled>{"Pause"}</button>
        } else {
            return <button type="button" className={"btn-status red margin_0auto"} disabled>{"Not Send"}</button>
        }
    }

    backHandler = () => {
        const { history } = this.props;
        history.push('/pushoffers');
    }
    handleTableChange = (type, { page, sizePerPage, searchText }) => {

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            this.getNotificationList(sizePerPage, page);
        }
        if (type === 'search') {
            this.setState({
                search: searchText
            });
            const { page, sizePerPage } = this.state
            this.getNotificationList(sizePerPage, page);
        }
    }

    render() {
        const { isTester, search, appId, countryId, dormantDays, userType, title, subtitle, notiId, formError, startDate, showSendDate, showPause } = this.state

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'username', text: 'Author ', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'title', text: 'Title', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'text', text: 'Text', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'sendDate', text: 'Send Date', sort: true, headerFormatter: this.headerSpan, formatter: this.orderDate },
            { dataField: 'createdAt', text: 'Created Date', sort: true, headerFormatter: this.headerSpan, formatter: this.orderDate },
            { dataField: 'sent', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: "Action", sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.notificationList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            showTotal: true,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ]
        };

        const confirmModal = {
            title: this.state.modalTitle,
            text: this.state.modalText,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: this.sendAgain,
            isLoader: this.state.isDLoader
        }

        return (
            <section className="bg-balance">
                <div className="balance_wrap">
                    <div className="btn_groupsB">
                        <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                    </div>
                    <DataTable TableData={tableData} />
                </div>
                <ConfirmModal data={confirmModal} />
                <Modal show={this.state.isShowModal} onHide={this.closeModal} className="modal_design" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">View Notification <button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals body_modals2 noti-detail">
                                <div className="input-group flex column-gap-15">
                                    <label>User : </label>
                                    <span>{userType}</span>
                                    {/* <span>{userType === "2" ? "Old User" ? userType === "1" : 'New User' : "All User"}</span> */}
                                    {/* <span>{userType === "2" ? "New User" : ''}</span>
                                    <span>{userType === "0" ? "All User" : ''}</span>*/}
                                </div>
                                <div className="input-group flex column-gap-15">
                                    <label>Application : </label>
                                    <ul>
                                        {appId && appId.length > 0 ? appId.map((data, i) => this.selectedList(data, i)) : "All Applications Selected"}
                                    </ul>
                                </div>
                                <div className="input-group flex column-gap-15">
                                    <label>Country : </label>
                                    <ul>
                                        {countryId && countryId.length > 0 ? countryId.map((data, i) => this.selectedList(data, i)) : "All Countries Selected"}
                                    </ul>
                                </div>
                                <div className="input-group flex column-gap-15">
                                    <label>Dormant Days : </label>
                                    <p>{dormantDays} Days</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isEditModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">Edit Notification <button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals body_modals2 edit-noti">
                                <form method="POST" action="#">
                                    <div className="form_body2">
                                        <div className="form_group">
                                            <label className="label_form">Title</label>
                                            <input type="text" name="title" className={formError.title !== '' ? "form_control3 error_border" : "form_control3"} value={title} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.title !== '' ? <p className="error_text">{formError.title}</p> : null}
                                        </div>
                                        <div className="form_group">
                                            <label className="label_form">Text</label>
                                            <input type="text" name="subtitle" className={formError.subtitle !== '' ? "form_control3 error_border" : "form_control3"} value={subtitle} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.subtitle !== '' ? <p className="error_text">{formError.subtitle}</p> : null}
                                        </div>

                                        <div className="form_group align-items-baseline column-gap-15 d-flex flex">
                                            <div className="form_group checkbox-group">
                                                <input type="checkbox" value={showSendDate} checked={showSendDate === "1"} id="sendLater" onChange={this.sendChecked} className="styled-checkbox" />
                                                <label htmlFor="sendLater">Send Later</label>
                                            </div>
                                            {showSendDate === "1" ?
                                                <div className="send-l-datepicker">
                                                    <DatePicker placeholderText="Select Start Date" minDate={new Date()} showTimeSelect selected={startDate} onChange={date => this.setStartDate(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                                                </div>
                                                : null}
                                        </div>
                                        <div className="form_group align-items-baseline column-gap-15 d-flex flex">
                                            <div className="form_group checkbox-group">
                                                <input type="checkbox" id="pause" value="1" onChange={this.sendPause} className="styled-checkbox" />
                                                <label htmlFor="pause">Pause</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg_submit2 mr-top">
                                        <button type="button" onClick={() => this.editNotification(title, subtitle, startDate, showSendDate, notiId, showPause)} className="btn-submit">Submit</button>
                                        <p>Or <button type="button" className="btn_cancel2" onClick={() => this.closeModal()}>Cancel</button></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isDupModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">Duplicate Notification <button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals body_modals2 edit-noti">
                                <form method="POST" action="#">
                                    <div className="form_body2">
                                        <div className="form_group">
                                            <label className="label_form">Title</label>
                                            <input type="text" name="title" className={formError.title !== '' ? "form_control3 error_border" : "form_control3"} value={title} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.title !== '' ? <p className="error_text">{formError.title}</p> : null}
                                        </div>
                                        <div className="form_group">
                                            <label className="label_form">Text</label>
                                            <input type="text" name="subtitle" className={formError.subtitle !== '' ? "form_control3 error_border" : "form_control3"} value={subtitle} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.subtitle !== '' ? <p className="error_text">{formError.subtitle}</p> : null}
                                        </div>
                                        <div className="noti-detail">
                                            <div className="input-group flex column-gap-15">
                                                <label>User : </label>
                                                <span>{userType}</span>
                                            </div>
                                            <div className="input-group flex column-gap-15">
                                                <label>Application : </label>
                                                <ul>
                                                    {appId && appId.length > 0 ? appId.map((data, i) => this.selectedList(data, i)) : "All Applications Selected"}
                                                </ul>
                                            </div>
                                            <div className="input-group flex column-gap-15">
                                                <label>Country : </label>
                                                <ul>
                                                    {countryId && countryId.length > 0 ? countryId.map((data, i) => this.selectedList(data, i)) : "All Countries Selected"}
                                                </ul>
                                            </div>
                                            <div className="input-group flex column-gap-15">
                                                <label>Dormant Days : </label>
                                                <p>{dormantDays} Days</p>
                                            </div>
                                        </div>
                                        <div className="form_group align-items-baseline column-gap-15 d-flex flex">
                                            <div className="form_group checkbox-group">
                                                <input type="checkbox" value={showSendDate} checked={showSendDate === "1"} id="sendLater" onChange={this.sendChecked} className="styled-checkbox" />
                                                <label htmlFor="sendLater">Send Later</label>
                                            </div>
                                            {showSendDate === "1" ?
                                                <div className="send-l-datepicker">
                                                    <DatePicker placeholderText="Select Start Date" minDate={new Date()} showTimeSelect selected={startDate} onChange={date => this.setStartDate(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="bg_submit2 mr-top">
                                        <button type="button" onClick={() => this.duplicateNotification(isTester, appId, countryId, dormantDays, userType, title, subtitle, startDate, showSendDate, search)} className="btn-submit">Submit</button>
                                        <p>Or <button type="button" className="btn_cancel2" onClick={() => this.closeModal()}>Cancel</button></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(ManageNotificaiton));
