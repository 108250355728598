import React, { Component } from 'react';
import { withRouter, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from '../../Routes/PrivateRoutes';
// import { Tab, Nav } from 'react-bootstrap';
import UnderReview from './UnderReview';
import ApprovedList from './ApprovedList';
import NoProfileList from './NoProfileList';
import RejectedProfile from './RejectedProfile';
// import { apiDeviceHelper } from '../../helpers/ApiHelper';
// import { toster_sucess } from '../../helpers/toster_helper';
import { ToastContainer } from 'react-toastify';
import './index.css';

class ProfilePicture extends Component {

    constructor(props){
        super(props);
        this.state={
            isUnderR:true
        }
    }

    checkTabs = (name) =>{
        if(name === 'approved' || name === 'noProfile'){
            this.setState({
                isUnderR:false
            });
        }else{
            this.setState({
                isUnderR:true
            });
        }
    }
    


    render() {

        // const { match } = this.props;
        // console.log(match);
        const routUrl = [
            { name:'Under Review', url:`/profile-picture`, params:'underReview'},
            { name:'Approved', url:`/profile-picture/approved`, params:'approved'},
            { name:'Rejected Profile', url:`/profile-picture/rejected`, params:'rejected'},
            { name:'No Profile', url:`/profile-picture/noProfile`, params:'noProfile'}
        ]

        return (
            <section className="bg-balance mr_b0">
                <div className="container-fluid">
                    <div className="balance_wrap">
                        <ul className="nesting_links">
                            {routUrl.map((data,i)=><li key={i}><NavLink activeClassName="active" onClick={()=>this.checkTabs(data.params)} exact to={data.url} >{data.name}</NavLink></li>)}
                        </ul>
                        <Switch>
                            <PrivateRoute exact path={`/profile-picture`} component={UnderReview} />
                            <PrivateRoute exact path={`/profile-picture/approved`} component={ApprovedList} />
                            <PrivateRoute exact path={`/profile-picture/rejected`} component={RejectedProfile} />
                            <PrivateRoute exact path={`/profile-picture/noProfile`} component={NoProfileList} />
                        </Switch>
                        {/* <Tab.Container defaultActiveKey="underReview" className="tab_nav tabs_profile" id="profilePicture">
                            <Nav variant="pills" className="tab_nav">
                                <Nav.Item>
                                    <Nav.Link eventKey="underReview" onClick={()=>this.checkTabs('underReview')}>Under Review</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="approved" onClick={()=>this.checkTabs('approved')}>Approved</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="noProfile" onClick={()=>this.checkTabs('noProfile')}>No Profile</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="underReview">
                                    <ListViews propsData={underR}   /> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="approved">
                                    <ListViews propsData={approv} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="noProfile">
                                    <ListViews propsData={noProfi} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container> */}
                    </div>
                </div>
                <ToastContainer />
            </section>
        )
    }
}

export default withRouter(connect(null)(ProfilePicture));
