import React, { Component } from 'react';
import Select from 'react-select';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
import { jwtDecode } from '../../helpers/jwt_helper';
import { apiHalper } from '../../helpers/ApiHelper';

class ContactList extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            contactUsList: [],
            recordCount: 0,
            isLoader: false,
            page: 1,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page } = this.state;
        this.getContactList(page);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getContactList = async (page) => {
        try {

            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            
            const response = await apiHalper(`getContact/${page}`, 'GET', null, headers);
            const res = response.data;
            console.log('res',res)
            if (res.status) {
                const ContactList = jwtDecode(res.data);
                console.log('ContactList',ContactList);
                this.setState({
                    isLoader: false,
                    contactUsList: ContactList.data,
                    recordCount: ContactList.count,
                    messageApi:''
                })
                
            }else {
                this.setState({
                    isLoader: false,
                    contactUsList: [],
                    recordCount: 0,
                    messageApi: res.message
                });
            }
  
        }
        catch (err) {
            console.log(err);
            this.setState({
                contactUsList: [],
                messageApi: err.message
            })
        }
    }
    headerSpan = (column) => {
        switch (column.dataField) {
            case 'id':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'name':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'email':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'message':
                return <span className={column.sort ? "header_span sort" : "header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    idFormatter = (cell,row) =>{
        return <div className="text-center name">{row.id}</div>;
    }
    nameFormatter = (cell, row) => {
        return <div className="text-center name">{row.name}</div>;
    }
    
    emailFormatter = (cell, row) => {
        return <div className="text-center email">{row.email}</div>;
    }
    
    messageFormatter = (cell, row) => {
        return <div className="text-center message">{row.message}</div>;
    }

    
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
        console.log('sortOrder:', sortOrder);
        console.log('sortField:', sortField);
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            console.log({ page })
            this.getContactList(page);
        }       
    }

    render() {
        console.log({ datatata: this.state.data })
        const options = [
            { value: 'join', label: 'Join' },
            { value: 'kw', label: 'Keyword' },
            { value: 'chat', label: 'Chat' },
            { value: 'flag', label: 'Flag' },
            { value: 'paid', label: 'Paid' },
            { value: 'jobs', label: 'Jobs' },
            { value: 'report', label: 'Report' }
        ];

        const { selectedOption, isClearable, contactUsList, isLoader, messageApi } = this.state;
        console.log('pavan',contactUsList)
        // const { addUser } = this.props; 

        const columns = [ 
            { dataField: 'id', text: 'ID', sort: true, headerFormatter: this.headerSpan, formatter: this.idFormatter },
            { dataField: 'name', text: 'Name', sort: true, headerFormatter: this.headerSpan, formatter: this.nameFormatter }, 
            { dataField: 'email', text: 'Email', sort: true, headerFormatter: this.headerSpan, formatter: this.emailFormatter },
            { dataField: 'message', text: 'Message', sort: true, headerFormatter: this.headerSpan, formatter: this.messageFormatter },
        ];
        
         
        const tableData = {
            columns:columns,
            data:contactUsList,
            keyId:'id',
            loading:isLoader,
            message:messageApi !== ''?messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.recordCount,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        return (
            <div className="tab_container">
                {/* <button type="button" className="btn_addUsers" onClick={()=>addUser()}>Add</button> */}
                {/* <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                </div> */}
                {/* <TableView TableData={tableData} />   */}
                {/* <DataTable TableData={tableData} />*/}
                <DataTable TableData={tableData} />
            </div>
        )
    }
}


export default ContactList;

