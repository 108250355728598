import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiHalper } from '../../helpers/ApiHelper';
import DataTable from '../JqueryDataTable';
import moment from 'moment';
import { jwtDecode } from '../../helpers/jwt_helper';
import NoDataIndication from '../TableLoader';
import DeleteModal from './DeleteModal/index';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
// import userImg from '../../assets/images/user_img.svg';
import * as ACTIVE_TYPES from '../../redux/actions/types';

class JobDetails extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isJProgress: 0,
            isLoader: false,
            page: 1,
            search: '',
            userData: [],
            message: '',
            count: 0,
            isConFModal: false,
            jobPostBidId: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.getJobsApiData(this.state.page, this.state.search, this.state.isJProgress);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getJobsApiData = async (page, search, isJProgress) => {
        try {
            if (this._ismounted) {
                this.setState({
                    isLoader: true
                })

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                const queryString = this.props.location.search;
                const urlParams = new URLSearchParams(queryString);
                const type = urlParams.get('type');
                const { id } = this.props.match.params;
                // const { id, type } = this.props;
                if (type === 'agents') {
                    // console.log('working');
                    let formData = new FormData();
                    formData.append('profile_id', id)
                    if (isJProgress === 0) {
                        formData.append('job_post_type', 'BIDDING')
                    } else if (isJProgress === 1) {
                        formData.append('job_post_type', 'IN-PROGRESS')
                    } else if (isJProgress === 2) {
                        formData.append('job_post_type', 'PAST-PROJECT')
                    }

                    formData.append('page_no', page)
                    formData.append('search_by', search)

                    const res = await apiHalper(`getAgentsJobs`, 'POST', formData, headers);

                    if (res.data.status) {
                        const data = jwtDecode(res.data.data);
                        console.log(data);

                        this.setState({
                            isLoader: false,
                            count: data.count,
                            userData: data.data,
                            // jobPostBidId: data.data[0].job_post_bid_id
                        })
                    } else {
                        // console.log('else');
                        this.setState({
                            isLoader: false,
                            userData: [],
                            message: res.data.message
                        })
                    }
                }

                if (type === 'clients') {
                    let formData = new FormData();
                    formData.append('profile_id', id)
                    if (isJProgress === 0) {
                        formData.append('job_post_type', 'WORK_IN_PROGRESS')
                    } else if (isJProgress === 1) {
                        formData.append('job_post_type', 'PAST_PROJECT')
                    }
                    formData.append('page_no', page)
                    formData.append('search_by', search)

                    const res = await apiHalper(`getJobPosts`, 'POST', formData, headers);
                    // console.log(res);
                    if(res.data.status === true){
                        const data = jwtDecode(res.data.data);

                        this.setState({
                            isLoader:false,
                            count:data.count,
                            userData:data.data
                            // data.data
                        })
                    }else{
                    this.setState({
                        isLoader: false,
                        userData: [],
                        message: 'Record not found'
                        // res.data.message
                    })
                    }
                }

            }
        } catch (err) {
            // console.log(err);
            this.setState({
                isLoader: false,
                userData: [],
                message: 'Network Error'
            })
        }
    }

    deleteConfirmation = (val, row) => {
        const {type} = this.props
        this.setState({
            updateType: 'status',
            status: val,
            editService: row,
            title: 'Delete Record',
            text: 'Are you sure you want to delete this record?',
            jobPostBidId: type === 'agents' ? row.id : row.jp_id
        });
        this.openDeleteModal();
    }

    openDeleteModal = () => {
        this.setState({
            isConFModal: true
        })
    }
    closeCofiClose = () => {
        this.setState({
            isConFModal: false,
            title: '',
            text: ''
        });
        this.getJobsApiData(this.state.page, this.state.search, this.state.isJProgress);
    }

    changeStatus = (val) => {
        this.setState({
            isJProgress: val
        });

        this.getJobsApiData(this.state.page, this.state.search, val);
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'bids_count':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'range_to':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'job_post_state_id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    bidField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    actionField = (cell, row) => {
        const { type } = this.props;
        console.log({ row })
        return <div className="text-center view-delete">
            <Link to={`/jobsDetails/${type === 'agents' ? row.id : row.jp_id}?type=${type}`} className="btn-view-icon"  onClick={()=>this.viewDetail(row)}></Link>
            {type === 'agents' && <button aria-label="Button Delete" type="button" className="btn_delete" onClick={() => this.deleteConfirmation(0, row)}></button>}
        </div>;
    }

    viewDetail= (data) =>{
        const {detailView} = this.props
        console.log({ data })
        detailView(data)
    }

    amountField = (cell, row) => {
        const { range_from, range_to } = row;
        return <span className="text-center margin_0auto">{(range_from !== null ? 'SAR ' + range_from : '-') + (range_to !== null ? ' - SAR ' + row.range_to : '-')}</span>;
    }

    statusField = (cell, row) => {
        const { type } = this.props
        const val = type === 'agents' ? row.job_post_state_name : row.name;
        switch (cell) {
            case 1:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 2:
                return <button type="button" className="btn-status btn-status2 minWd_220 blue margin_0auto" disabled>{val}</button>;
            case 3:
                return <button type="button" className="btn-status btn-status2 minWd_220 blue margin_0auto" disabled>{val}</button>;
            case 4:
                return <button type="button" className="btn-status btn-status2 minWd_220 green margin_0auto" disabled>{val}</button>;
            case 5:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 6:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{val}</button>;
            case 7:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{val}</button>;
            case 8:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{val}</button>;
            case 9:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 10:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 11:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 12:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 13:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{val}</button>;
            case 14:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{val}</button>;
            default:
                return <button type="button" className="btn-status btn-status2 minWd_220 margin_0auto" disabled>--</button>;
        }

        // switch(cell){
        //     case 0:
        //         return <button type="button" className="btn-status btn-statusmin red margin_0auto" disabled>Waiting for deposit</button>;
        //     case 1:
        //         return <button type="button" className="btn-status btn-statusmin blue margin_0auto" disabled>In Progress</button>;
        //     case 2:
        //         return <button type="button" className="btn-status btn-statusmin green margin_0auto" disabled>Completed</button>;
        //     case 3:
        //         return <button type="button" className="btn-status btn-statusmin red margin_0auto" disabled>Cancelled</button>;
        //     default:
        //         return <button type="button" className="btn-status btn-statusmin margin_0auto" disabled>Error</button>;
        // }
    }

    centerField = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            this.getJobsApiData(page, searchText, this.state.isJProgress);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getJobsApiData(page, searchText, this.state.isJProgress);
        }
    }

    isTester = (row) => {
        // console.log(row)
        let classs = null;
        if (row.is_tester === 1) {
            classs = 'tester_tr'
        } else {
            classs = null;
        }

        return classs;
    }

    deleteAll = async () => {
        try {

            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const { id } = this.props.match.params;

            let formData = new FormData();
            formData.append('profile_id', id)

            const response = await apiHalper('deleteAllBid', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false
                });
            }
            else {
                this.setState({
                    isLoader: false
                });
            }
            this.getJobsApiData(this.state.page, this.state.search, this.state.isJProgress);

        } catch (err) {
            console.log(err.message);
        }
    }

    deleteParticular = async (e) => {
        try {
            e.preventDefault();
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            // const { editService } = this.state

            const { id } = this.props.match.params;

            // return

            let formData = new FormData();
            formData.append('job_post_bid_id', this.state.jobPostBidId)
            formData.append('profile_id', id)


            const response = await apiHalper('deleteBid', 'POST', formData, headers)
            const res = response.data;

            if (!res.status) {
                this.setState({
                    apiErr: res.message
                })
                toster_error(res.message);
                return true
            }
            this.getJobsApiData(this.state.page, this.state.search, this.state.isJProgress);
            toster_sucess(res.message);

            this.setState({
                isConFModal: false,
                editData: null
            })
            this.getJobsApiData(this.state.page, this.state.search, this.state.isJProgress);
        }
        catch (err) {
            console.log(err.message);
        }
    }

    timeSet = (cell) => {
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>
    }

    render() {

        const { isJProgress } = this.state;
        const { type } = this.props;
        // console.log(this.props);
        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'id', text: 'Job ID', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'title', text: 'Title', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'timestamp', text: 'Date', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'bids_count', text: 'No Of Bid', sort: true, headerFormatter: this.headerSpan, formatter: this.bidField },
            { dataField: 'job_post_state_id', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'range_to', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];
        const columnsClients = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'jp_id', text: 'Job ID', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'jp_title', text: 'Title', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'timestamp', text: 'Date', sort: true, headerFormatter: this.headerSpan, formatter: this.timeSet },
            { dataField: 'bids_count', text: 'No Of Bid', sort: true, headerFormatter: this.headerSpan, formatter: this.bidField },
            { dataField: 'jps_id', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'range_to', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const DeleteModalData = {
            title: this.state.title,
            text: this.state.text,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: this.deleteParticular,
            isLoader: this.state.isDLoader
        }


        // let data = [];

        // if(isJProgress === 0){

        //     data = [
        //         { id:'1', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:0 },
        //         { id:'2', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'3', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:1 },
        //         { id:'4', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'5', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'6', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'7', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:1 },
        //         { id:'8', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'9', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:0 },
        //         { id:'10', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'11', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'12', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:1 },
        //         { id:'13', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'14', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'15', title:'Writer Of test Writer Of test', bid:5, jobId:44093, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'16', title:'Test 2550 Writer', bid:2, jobId:57874, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 }
        //     ]

        // }else if(isJProgress === 1){

        //     data = [
        //         { id:'1', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:2, amount:'$9 - $30 USD', flag:0 },
        //         { id:'2', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:3, amount:'$5 - $25 USD', flag:0 },
        //         { id:'3', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:3, amount:'$9 - $30 USD', flag:1 },
        //         { id:'4', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:2, amount:'$5 - $25 USD', flag:0 },
        //         { id:'5', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:3, amount:'$9 - $30 USD', flag:0 },
        //         { id:'6', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:3, amount:'$5 - $25 USD', flag:0 },
        //         { id:'7', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:2, amount:'$9 - $30 USD', flag:1 },
        //         { id:'8', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:2, amount:'$5 - $25 USD', flag:0 },
        //         { id:'9', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:2, amount:'$9 - $30 USD', flag:0 },
        //         { id:'10', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:3, amount:'$5 - $25 USD', flag:0 },
        //         { id:'11', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:3, amount:'$9 - $30 USD', flag:0 },
        //         { id:'12', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:2, amount:'$5 - $25 USD', flag:1 },
        //         { id:'13', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:3, amount:'$9 - $30 USD', flag:0 },
        //         { id:'14', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:2, amount:'$5 - $25 USD', flag:0 },
        //         { id:'15', title:'Writer Of test Writer Of test', bid:5, jobId:44093, date:'8 Months ago', status:3, amount:'$9 - $30 USD', flag:0 },
        //         { id:'16', title:'Test 2550 Writer', bid:2, jobId:57874, date:'2 Months ago', status:3, amount:'$5 - $25 USD', flag:0 }
        //     ]
        // }else{
        //     data = [
        //         { id:'1', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:0 },
        //         { id:'2', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'3', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:1 },
        //         { id:'4', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'5', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'6', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'7', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:1 },
        //         { id:'8', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'9', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:0, amount:'$9 - $30 USD', flag:0 },
        //         { id:'10', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 },
        //         { id:'11', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'12', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:1 },
        //         { id:'13', title:'Writer Of test Writer Of test', bid:5, jobId:57874, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'14', title:'Test 2550 Writer', bid:2, jobId:44093, date:'2 Months ago', status:0, amount:'$5 - $25 USD', flag:0 },
        //         { id:'15', title:'Writer Of test Writer Of test', bid:5, jobId:44093, date:'8 Months ago', status:1, amount:'$9 - $30 USD', flag:0 },
        //         { id:'16', title:'Test 2550 Writer', bid:2, jobId:57874, date:'2 Months ago', status:1, amount:'$5 - $25 USD', flag:0 }
        //     ]
        // }

        const tableData = {
            userData: [],
            editData: [],
            columns: type === 'agents' ? columns : columnsClients,
            data: this.state.userData,
            keyId: 'id',
            loading: this.state.isLoader,
            message: this.state.message,
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.count,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        return (
            <div className="tab_container job-status-div">
                { type === 'agents' ?
                    <div className={!isJProgress ? "wrap_btn_status wrap_btn_status2 active" : "wrap_btn_status wrap_btn_status2"} style={{ "--i": isJProgress }}>
                        <input type="radio" id="bidding" name="tab_status" checked={isJProgress === 0} onChange={() => this.changeStatus(0)} />
                        <label htmlFor="bidding" data-i="0">Bidding</label>
                        <input type="radio" id="openJobs" name="tab_status" checked={isJProgress === 1} onChange={() => this.changeStatus(1)} />
                        <label htmlFor="openJobs" data-i="1">Open Campaign</label>
                        <input type="radio" id="closedJobs" name="tab_status" checked={isJProgress === 2} onChange={() => this.changeStatus(2)} />
                        <label htmlFor="closedJobs" data-i="1">Closed Campaign</label>
                    </div>
                    :
                    <div className={!isJProgress ? "wrap_btn_status active" : "wrap_btn_status"} style={{ "--i": isJProgress }}>
                        <input type="radio" id="inprogress" name="tab_status" checked={isJProgress === 0} onChange={() => this.changeStatus(0)} />
                        <label htmlFor="inprogress" data-i="0">Campaign in progress</label>
                        <input type="radio" id="pastjobs" name="tab_status" checked={isJProgress === 1} onChange={() => this.changeStatus(1)} />
                        <label htmlFor="pastjobs" data-i="1">Past Campaign</label>
                    </div>
                }
                {isJProgress === 0 ? <button type="button" className="btn btn-danger delete-all" onClick={this.deleteAll}>Delete All Bid</button> : null}
                {/* <div className="select_wrap users_filters">
                    <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                </div> */}
                <DataTable TableData={tableData} />
                <DeleteModal data={DeleteModalData} />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView :(data) => dispatch({type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data})
})

export default withRouter(connect(null, mapDispatchToProps)(JobDetails));
