import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import ReviewModal from './ReviewModal';
import AddMoreModal from './AddMoreModal';
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollLoader from '../ScrollLoader';
import LoaderComponent from '../LoaderComponent';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment'
import { toster_sucess } from '../../helpers/toster_helper';

class ReviewDetails extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isModal: false,
            isAdd: false,
            reviewData: [],
            page: 1,
            isLoader: false,
            isSLoader: false,
            messageApi: '',
            singleData: null,
            dummyReview: 0,
            title: '',
            description: '',
            noReviews: '',
            clarity: 0,
            formError: {
                title: '',
                description: '',
                noReviews: ''
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            issLoader: false,
            startDate: new Date(),
            reviewType: 0,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.getReviewListApi(this.state.page)
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getReviewListApi = async (page) => {
        if (this._ismounted) {
            try {

                const { id } = this.props.match.params;

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('profile_id', id)
                formData.append('page_no', page)

                const res = await apiHalper('getAgentReview', 'POST', formData, headers);
                if (res.data.status === true) {
                    const data = jwtDecode(res.data.data);
                    if (data.length > 0) {
                        if(page > 1) {
                            this.setState({
                                isLoader: false,
                                isSLoader: true,
                                messageApi: '',
                                reviewData: [...this.state.reviewData, ...data]
                            })
                        } else {
                            this.setState({
                                isLoader: false,
                                isSLoader: true,
                                messageApi: '',
                                reviewData: data
                            })
                        }
                    } else {
                        this.setState({
                            isLoader: false,
                            isSLoader: false,
                            messageApi: ''
                        })
                    }
                } else {
                    this.setState({
                        isLoader: false,
                        isSLoader: false,
                        messageApi: res.data.message,
                        // reviewData: []
                    })
                }

            } catch (err) {
                this.setState({
                    isLoader: false,
                    isSLoader: false,
                    messageApi: 'Network Error'
                })
            }
        }
    }

    nextpageReviews = () => {
        const inc = this.state.page + 1;
        if (this.state.isSLoader) {
            this.setState({
                page: inc
            })
            this.getReviewListApi(inc)
        } else {
            this.setState({
                isSLoader: false
            })
        }

    }

    openModal = () => {
        this.setState({
            isAdd: true,
            isModal: true,
            clarity: 0,
            title: '',
            description: '',
            formError: {
                title: '',
                description: '',
                noReviews: '',
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            }
        });
    }

    editModal = (data) => {
        const titleValid = data.title !== '';
        const decriptionValid = data.comment !== '';
        this.setState({
            isAdd: false,
            isModal: true,
            singleData: data,
            title: data.title,
            description: data.comment,
            clarity: data.rate,
            dummyReview: data.dummy_review,
            startDate: new Date (data.timestamp),
            ratingValid: true,
            formError: {
                title: titleValid ? '' : 'Title is Required',
                description: decriptionValid ? '' : 'Description is Required',
            },
            formValid: {
                title: titleValid,
                description: decriptionValid
            }
        });
    }

    handlerClose = () => {
        this.setState({
            isModal: false,
            reviewType: 0,
        })
    }

    renderList = (data, i) => {
        // console.log(data);
        return <li key={i}>
            <div className="review_block">
                <div className="review_titleWrap">
                    <h6>{data.title}</h6>
                    <p>{this.checkRatingStar(data.rate)} {data.rate} <span className="date_text">{moment(data.timestamp).format('MMM YYYY')}</span></p>
                </div>
                <button type="button" className="btn-edit2" onClick={() => this.editModal(data)}>Edit</button>
            </div>
            <p className="details_text">{data.comment}</p>
        </li>
    }

    checkRatingStar = (rating) => {

        const ar = String(rating).split('.');
        if (ar.length > 1) {
            return <span className={`star_icon star-${ar[0]}-${ar[1]}`}></span>
        } else {
            // console.log(ar);
            return <span className={`star_icon star-${ar[0]}-0`}></span>
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const reg = /^([0-9]+[\\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Title must required'
                break;
            case 'description':
                valid.description = value.length > 0;
                error.description = valid.description ? '' : 'Description must required'
                break;
            case 'noReviews':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.noReviews = true
                        error.noReviews = '';
                    } else {
                        valid.noReviews = false
                        error.noReviews = 'Enter only numberic value';
                    }
                } else {
                    valid.noReviews = false;
                    error.noReviews = 'No of reviews must required';
                }
                break;
            default:
                break;
        }

        this.setState({
            formError: error,
            formValid: valid
        })
    }

    closeModal = () => {
        this.setState({
            isModal: false
        })
    }

    handleStartdate = (date) => {
        const startDate = date;
        this.setState({
            startDate: startDate
        });
        // console.log(picker);
    }

    reviewTypeHandler = (val) => {
        this.setState({
            reviewType: val
        })
    }

    ratinghandler = (rating) => {
        console.log(rating)
        this.setState({
            clarity: rating,
            ratingValid: true
        });
    }

    addMReview = async (e) => {
        e.preventDefault();

        // try{

        this.setState({
            issLoader: true
        })

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const { title, description, clarity, startDate, reviewType, noReviews } = this.state;

        let formData = new FormData();

        const { id } = this.props.match.params;

        formData.append('profile_id', id)

        // if (singleData !== null) {
        //     formData.append('profile_id', singleData.id)
        // }
        if (reviewType === 0) {
            formData.append('title', title)
            formData.append('comment', description)
        } else {
            formData.append('number_of_review', noReviews)
        }
        
        formData.append('review_type', reviewType)
        formData.append('rate', clarity)

        formData.append('timestamp', moment(startDate).format('YYYY-MM-DD'))

        const res = await apiHalper(`addAgentReviews`, 'POST', formData, headers);
        // console.log(res);
        if (res.data.status === true) {
            this.setState({
                issLoader: false,
                messageAApi: ''
            });
            this.closeModal();
            toster_sucess('Review Added Successfully...');
            this.getReviewListApi(this.state.page);
        } else {
            this.setState({
                issLoader: false,
                messageAApi: res.data.message
            });
        }

        // }catch(err){
        //     this.setState({
        //         issLoader:false,
        //         messageAApi:'Network Error'
        //     })
        // }
    }

    editReview = async (e) => {
        e.preventDefault();

        this.setState({
            issLoader: true
        })

        const headers = {
            'Authorization': localStorage.getItem('token')
        }

        const { singleData, title, description, clarity, startDate } = this.state;

        let formData = new FormData();
        const { id } = this.props.match.params;

        formData.append('profile_id', id)

        formData.append('title', title)
        formData.append('comment', description)

        if (singleData !== null) {
            formData.append('review_id', singleData.id)
        }

        formData.append('is_manual_review', singleData.dummy_review)
        formData.append('rate', clarity)

        formData.append('timestamp', moment(startDate).format('YYYY-MM-DD'))

        const res = await apiHalper(`editAgentReviews`, 'POST', formData, headers);
        if (res.data.status === true) {
            this.setState({
                issLoader: false,
                messageAApi: ''
            });
            this.closeModal();
            toster_sucess('Review Updated Successfully...');
            this.getReviewListApi(1);
        } else {
            this.setState({
                issLoader: false,
                messageAApi: res.data.message
            });
        }

    }


    render() {


        const { isLoader, reviewData, isSLoader, messageApi } = this.state;

        const modalDa = {
            addMModal: this.state.isModal,
            handleClose: this.handlerClose,
            singleData: this.state.singleData,
            isAdd: this.state.isAdd,
            title: this.state.title,
            description: this.state.description,
            clarity: this.state.clarity,
            formError: this.state.formError,
            formValid: this.state.formValid,
            ratingValid: this.state.ratingValid,
            messageAApi: this.state.messageAApi,
            issLoader: this.state.issLoader,
            startDate: this.state.startDate,
            inputHandler: this.inputHandler,
            handleStartdate: this.handleStartdate,
            addMReview: this.addMReview,
            editReview: this.editReview,
            ratinghandler: this.ratinghandler,
            reviewType: this.state.reviewType,
            reviewTypeHandler: this.reviewTypeHandler,
            noReviews: this.state.noReviews
        }

        return (
            <div className="tab_container">
                <div className="review_wrap">
                    <h6 className="subpage_title">Reviews <button type="button" className="btn-addRev" onClick={this.openModal}>Add</button></h6>
                    <ul className="review_list" id="scrollableUl">
                        {!isLoader ?
                            reviewData.length > 0 ?
                                <InfiniteScroll className="review_list width-forscrool" dataLength={reviewData.length} next={this.nextpageReviews} hasMore={isSLoader} loader={<ScrollLoader />} scrollableTarget="scrollableUl">
                                    {reviewData.map((info, i) => this.renderList(info, i))}
                                </InfiniteScroll>
                                :
                                <li>
                                    <div className="noRecord">
                                        <p>{messageApi}</p>
                                    </div>
                                </li>
                            :
                            <LoaderComponent />
                        }

                    </ul>
                </div>
                <AddMoreModal modalData={modalDa} />
                {/* <ReviewModal isModal={this.state.isModal} isAdd={this.state.isAdd} closeEvent = {this.handlerClose} /> */}
            </div>
        )
    }
}
export default withRouter(connect(null)(ReviewDetails));
