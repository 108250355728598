import React, { useState, useEffect } from "react";
import DataTable from "../JqueryDataTable";
import { jwtDecode } from "../../helpers/jwt_helper";
import DeleteCModal from "./DeleteCModal";
import CompanyModal from "./CompanyModal";
import { toast } from "react-toastify";
import { getAllCompany } from "./CompaniApiHelper";
import NoDataIndication from "../TableLoader";

const Companies = () => {
  const [companyList, setCompanyList] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [isCompanyModal, setIsCompanyModal] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [deleModal, setDeleModal] = useState({
    isDeleModal: false,
    deleteId: "",
  });

  const getList = async () => {
    const res = await getAllCompany("getAllCompany", "GET");
    if (res.data.status === true) {
      const data = jwtDecode(res.data.data);
      setTotalRecord(data.data.length);
    }
  };
  const getPage = async (page) => {
    setIsLoader(true);
    const res = await getAllCompany(`getCompany/${page}`, "GET");
    if (res.data.status === true) {
      setIsLoader(false);
      const data = jwtDecode(res.data.data);
      setCompanyList(data.data);
    }
  };

  useEffect(() => {
    getList();
    getPage(1);
  }, []);

  const statusField = (cell) => {
    if (cell === 1) {
      return (
        <button
          type="button"
          className={"btn-status blue margin_0auto"}
          disabled
        >
          {"Active"}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={"btn-status red btn_red margin_0auto"}
          disabled
        >
          {"Inactive"}
        </button>
      );
    }
  };

  const actionField = (cell, row) => {
    return (
      <div className="action_group ">
        {/* <button className="btn-view-icon" onClick={() => this.viewCouponCode(row)}></button> */}
        <button
          type="button"
          className="btn_edit2"
          onClick={() => {
            setUpdateData(row);
            setIsCompanyModal(!isCompanyModal);
            setIsAdd(false);
          }}
        ></button>
        <button
          type="button"
          className="btn_delete"
          onClick={() => openDeleModal(row.id)}
        ></button>
      </div>
    );
  };
  const imageField = (cell, row) => {
    console.log(cell, row, "cell");
    const url = jwtDecode(localStorage.getItem("filePath"));
    return (
      <div
        className="action_group text-center"
        style={{ height: "75px", width: "75px" }}
      >
        <img src={`${url.company}${cell}`} className="w-100 h-100" />
      </div>
    );
  };
  const openDeleModal = (id) => {
    setDeleModal({
      isDeleModal: true,
      deleteId: id,
    });
  };

  const cancelDelete = () => {
    setDeleModal({
      isDeleModal: false,
    });
  };
  const closeModal = () => {
    setIsCompanyModal(false);
  };
  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder, data }
  ) => {
    console.log(
      type,
      page,
      sizePerPage,
      searchText,
      sortField,
      sortOrder,
      data
    );
    let result;
    if (type === "search") {
      if (!searchText) {
        getList();
        getPage(1);
      } else {
        const getdata = async () => {
          const res = await getAllCompany(
            `getCompany/${page}/${searchText}`,
            "GET"
          );
          if (res.data.status === true) {
            setIsLoader(false);
            const data = jwtDecode(res.data.data);
            setTotalRecord(data.data.length);
            setCompanyList(data.data);
          }
        };
        getdata();
      }
      // this.setState(
      //   {
      //     search: searchText,
      //   },
      //   () => {
      //     getCoupList(page, searchText);
      //     this.getAllCoupon();
      //   }
      // );
    }
    if (sortOrder === "asc") {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setCompanyList(result);
    // this.setState(() => ({
    //     data: result
    // }))
    if (type === "pagination") {
      setPageNumber(page);
      getPage(page);
      // this.setState(
      //   {
      //     page,
      //     sizePerPage,
      //   },
      //   () => {
      //     getCoupList(page, searchText);
      //     this.getAllCoupon();
      //   }
      // );
    }
  };

  const headerSpan = (column, colIndex) => {
    switch (column.dataField) {
      default:
        return (
          <span className={column.sort ? "header_span sort" : "header_span"}>
            {column.text}
          </span>
        );
    }
  };
 
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerFormatter: headerSpan,
    },
    {
      dataField: "name_ar",
      text: "Name Ar",
      sort: true,
      headerFormatter: headerSpan,
    },
    {
      dataField: "filename",
      text: "Image",
      headerFormatter: headerSpan,
      formatter: imageField,
    },
    {
      dataField: "link",
      text: "Link",
      sort: true,
      headerFormatter: headerSpan,
      formatter: (cell) => {
        if (cell === "undefined"){
          return <div>-</div>
        }else{
          return <div>{cell}  </div>
        }
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerFormatter: headerSpan,
      formatter: statusField,
    },
    {
      dataField: "action",
      text: "Action",
      headerFormatter: headerSpan,  
      formatter: actionField,
    },
  ];

  const tableDatas = {
    columns: columns,
    data: companyList,
    keyId: "id",
    loading: isLoader,
    message: 'Table is Empty',
    noDataIndication: <NoDataIndication />,
    page: pageNumber,
    totalRecord: totalRecord,
    sizePerPage: 10,
    handleTableChange: handleTableChange,
    // overlay:overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } })
  };

  return (
    <>
      <section className="bg-balance">
        <div className="balance_wrap">
          <div className="btn_groupsB">
            <button
              type="button"
              className="btn_addUsers"
              onClick={() => {
                setIsCompanyModal(!isCompanyModal);
                setIsAdd(true);
              }}
            >
              Add Companies
            </button>
          </div>
        <DataTable TableData={tableDatas} />

          <DeleteCModal
            isModal={deleModal.isDeleModal}
            id={deleModal.deleteId}
            handleClose={cancelDelete}
            getList={getPage}
            pageNumber={pageNumber}
          />
          <CompanyModal
            updateData={updateData}
            isCompanyModal={isCompanyModal}
            closeModal={closeModal}
            isAdd={isAdd}
            getList={getPage}
            pageNumber={pageNumber}
            setIsAdd={setIsAdd}
          />
        </div>
      </section>
    </>
  );
};

export default Companies;
