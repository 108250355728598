import React, { Component } from 'react';
import { apiHalper } from '../../../helpers/ApiHelper'
import { jwtDecode } from '../../../helpers/jwt_helper'
import { Link } from 'react-router-dom';
import LoaderImg from '../../../assets/images/user-loader.gif';
import CkEditorComponent from '../../CkEditorComponent';

import './index.css';

export default class PageContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insertID: null,
            pageName: '',
            pageFilenm: '',
            pageHeading: '',
            pageContent: '',
            isLoader: false,
            formError: {
                pageName: '',
                pageFilenm: '',
                pageHeading: '',
                pageContent: ''
            },
            formValid: {
                pageName: false,
                pageFilenm: false,
                pageHeading: false,
                pageContent: false
            }
        }
    }

    componentDidMount() {
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            insertID: myParam
        })
        if(myParam === ''){
            this.props.history.push('/seo/add-main-services')
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;        
        this.setState({
            [name]:value
        },()=>this.checkValidation(name, value));    
        
    }
    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid =this.state.formValid;        
        switch(name){
            case 'pageName':
                valid.pageName = value.length > 0;
                error.pageName = valid.pageName?'':'Page Name is required.';
                break;            
            case 'pageHeading':
                valid.pageHeading = value.length > 0;
                error.pageHeading = valid.pageHeading?'':'Page Heading is required.';
                break;            
            default:
                break;
        }

        this.setState({
            formError:error,
            formValid:valid
        })
    }
    pageContenHandler = (event) => {        
        const { formError } = this.state;
        const data = event.editor.getData();
        this.setState({
            pageContent:data,
            formError: {
                ...formError,
                pageContent: ''
            }
        })        
    }
    addPageContent = async (e) => {
        try{
            e.preventDefault();
            const { pageName, pageHeading, formError } = this.state;        
            if(pageName==='' && pageHeading === '' ) {
                this.setState ({
                    formError: {
                        ...formError,
                        pageName: 'Page Name is required',
                        pageHeading: 'Page heading is required'
                    }
                })
            }
            else if (pageName==='') {
                this.setState ({
                    formError: {
                        ...formError,
                        pageName: 'Page Name is required'
                    }
                })
            }
            else if (pageHeading==='') {
                this.setState ({
                    formError: {
                        ...formError,
                        pageHeading: 'Page Heading is required',
                    }
                })
            }
            else {
                this.setState({
                    isLoader: true
                });
                const headers = {
                    'Authorization':localStorage.getItem('token')
                }
                const { insertID, pageName, pageHeading, pageContent } = this.state;

                const pageFilenm = pageName.split(" ").join("-").toLowerCase();
                const to = new URLSearchParams(this.props.location.search).get('to');
                const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
                let formData = new FormData()
                    formData.append('serviceCategoryID', insertID)
                    formData.append('pageName', pageName)
                    formData.append('pageFileName', pageFilenm)                
                    formData.append('pageContent', pageContent)
                    formData.append('pageHeading', pageHeading)
                    formData.append('pageShortDescription', "")
                    formData.append('parentPath', parentPath)
        
                const response = await apiHalper('addPages', 'POST', formData, headers)
                const res = response.data;
               
                if(res.status){
                    const { history } = this.props;
                    history.push(`/seo/add-faqs?id=${insertID}&parentPath=${parentPath}&to=${to}`)
                     
                    this.setState ({
                        formError: {
                            ...formError,
                            pageName: '',
                            pageFilenm: '',
                            pageHeading: '',
                            pagecontent: ''
                        }
                    })
                }else{                
                    this.setState({
                        apiErr: res.message,
                        isLoader: false
                    })
                    // return true
                }
                
            }
        }catch(err){
            console.log(err.message);
        }
    }
    render() {
        const { pageName, pageHeading, formError, isLoader } = this.state;
        const { location } = this.props;
        const prev = new URLSearchParams(location.search).get('prev');
        
        return (
            <div className="page-content">
                <h4 className="model_title">Page Content 
                    <Link to={`/seo/${prev}`} className="btn-back">Back</Link>         
                </h4>
                <form action="/" method="POST" onSubmit={(e) => this.addPageContent(e) }>
                    <div className="tabContent">
                        <div className="div_3">
                            {/* <div className="form_group">
                                <label>Parent ID</label>
                                <input type="text" className="form_control2" disabled placeholder="Default 0" />
                            </div> */}
                            <div className="form_group">
                                <label>Page Name</label>
                                {/* <input type="text" className="form_control2" placeholder="Page Name" /> */}
                                <input type="text" name="pageName" value={pageName} className={formError.pageName !== ''?"form_control2 error_border":"form_control2"} placeholder="Page Name" onChange={this.inputHandler} autoComplete="off" />
                                {formError.pageName !== ''?<p className="error_text pos_a">{formError.pageName}</p>:null}
                            </div>
                            <div className="form_group">
                                <label>Page File Name</label>                                
                                {/* <input type="text" name="pageFilenm" value={pageFilenm} className={formError.pageFilenm !== ''?"form_control2 error_border":"form_control2"} placeholder="File Name" onChange={this.inputHandler} autoComplete="off" /> 
                                    {formError.pageFilenm !== ''?<p className="error_text pos_a">{formError.pageFilenm}</p>:null} */}
                                <input type="text" name="pageFilenm" value={pageName.split(" ").join("-").toLowerCase()} className="form_control2" placeholder="File Name" disabled autoComplete="off" />
                            </div>
                        </div>
                        <div className="div_3">
                            <div className="form_group">
                                <label>Page Heading</label>
                                {/* <input type="text" className="form_control2" placeholder="Page Heading" /> */}
                                <input type="text" name="pageHeading" value={pageHeading} className={formError.pageHeading !== ''?"form_control2 error_border":"form_control2"} placeholder="Page Heading" onChange={this.inputHandler} autoComplete="off" />
                                {formError.pageHeading !== ''?<p className="error_text pos_a">{formError.pageHeading}</p>:null}
                            </div>
                        </div>
                        <div className="div_3">
                            <div className="form_group">
                                <label>Page Content</label>
                                <CkEditorComponent  data={this.state.pageContent} changeEvent={ this.pageContenHandler }/>
                                {formError.pageContent !== ''?<p className="error_text pos_a">{formError.pageContent}</p>:null}
                            </div>
                        </div>
                    </div>
                    <div className="bg_submit2 right-mx120">
                        <button type="submit" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save & Next"}</button>
                        {/* <Link to="/seo/add-faqs" className="btn-submit">Next</Link> */}
                    </div>
                </form>
            </div>
        )
    }
}