import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export  function toster_sucess(msg) {
    return toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

}

export  function toster_error(msg) {
        // autoClose: 5000,
    return toast.error(msg, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

}
