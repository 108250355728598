import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TableView from '../TableView';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';

class AccountDetails extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state = {
            isLoader:false,
            accountData:[],
            page:1,
            search:'',
            totalRecord:0,
            banksList: [],
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page, search } = this.state;
        this.getAccountApi(page, search);
        this.getAllBanksList(); 
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getAllBanksList = async (page) => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const { id } = this.props.match.params;
            console.log({ id4545: id })
            let formData = new FormData();
            formData.append('profile_id', id)
            formData.append('page_no', 1)
            formData.append('limit', 10)
            const response = await apiHalper('getAgentConnectedBank', 'POST', formData, headers);
            const res = response.data;
            
            if (!res.status) {
                this.setState({
                    banksList: [],
                    isLoader: false
                })
            }
            const selectedBanksList = jwtDecode(res.data)
            console.log({ selectedBanksList })
            const sortList = selectedBanksList.bank_data.sort((a, b) => {
                if (a.name > b.name) return 1
                if (a.name < b.name) return -1
                return 0;
            });
            this.setState({
                banksList: sortList,
                isLoader: false,
            })
        }
        catch (err) {
            console.error(err.message)
        }
    }
    getAccountApi = async (page, search) =>{

        if(this._ismounted){
            this.setState({
                isLoader:true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            const { uid, type } = this.props;

            let formData = new FormData();
            formData.append('profile_id', uid)
            formData.append('page_no', page)
            formData.append('search_by', search)
            // if(type === 'agent'){
                const response = await apiHalper('getAgentPaymentAccounts','POST',formData, headers);
                if(response.data.status === true){
                    const data = jwtDecode(response.data.data);
                    // const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].reject_amount;
                    if(Array.isArray(data) && data.length > 0){
                        this.setState({
                            isLoader:false,
                            accountData:data,
                        });
                    }else{
                        this.setState({
                            isLoader:false,
                            accountData:[],
                        });
                    }
                }else{
                    this.setState({
                        isLoader:false,
                        accountData:[],
                    });
                }
            // }
            if(type === 'client'){
                // const response = await apiHalper('getAgentPaymentAccounts','POST',formData, headers):;
                // // console.log(response);
                // if(response.data.status === true){
                //     const data = jwtDecode(response.data.data);
                //     console.log(data);
                //     // const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].reject_amount;
                //     if(Array.isArray(data) && data.length > 0){
                //         this.setState({
                //             isLoader:false,
                //             accountData:data,
                //         });
                //     }else{
                //         this.setState({
                //             isLoader:false,
                //             accountData:[],
                //         });
                //     }
                // }else{
                    this.setState({
                        isLoader:false,
                        accountData:[],
                    });
                // }
            }
        }
    }

    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        // console.log(type);
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }
        this.getAccountApi(page, searchText);
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'verified':
                return <span className={column.sort?"header_span sort text-center":"header_span text-center"}>{column.text}</span>;
            case 'provider':
                return <span className={column.sort?"header_span sort text-center":"header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
        // if(column.dataField === 'status'){
        //     return <span className={column.sort?"header_span sort text-center":"header_span text-center"}>{column.text}</span>;
        // }else{
        //     return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        // }
    }

    statusField = (cell) =>{
        // const { toggleModal } = this.props;
        
        switch(cell){
            case '0':
                return <button type="button" className="btn-status red margin_0auto" disabled>Unverified</button>;
            case '1':
                return <button type="button" className="btn-status green margin_0auto" disabled>Verified</button>;
            default:
                return <button type="button" className="btn-status margin_0auto" disabled>--</button>;
        }
        
    }

    pType = (cell, row) =>{
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    isTester = (row) =>{
        // console.log(row)
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    dateField = (cell, row) =>{
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }
    verificationFile = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        console.log({ decodePath })
        // const imgPath = `${decodePath.img_id}${row.verifications_file}`
        const mawthooqImgPath = `https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/agentApi/webroot/mawthooq_id/${row.mawthooq_id}`
        const crImgPath = `https://s3.me-central-1.amazonaws.com/cdn.influencebird.com/clientApi/webroot/commercial_registration/${row.cr_file}`
        return <div className="tb_user_wrap verification-prof">
             {row.bank_certificate && <p><a target='_blank' href={decodePath?.bank_certificate + row.bank_certificate}><button type="button" className="btn-show-icon"></button></a> 
            </p>}
            {/* <a href=''></a> */}
                
        </div>

    }

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'name', text: 'Bank name', sort: true, headerFormatter: this.headerSpan }, 
            { dataField: 'beneficiary_name', text: 'Beneficiary Name', sort: true, headerFormatter: this.headerSpan},
            { dataField: 'iban', text: 'IBAN number', sort: true, headerFormatter: this.headerSpan},
            { dataField: 'bank_certificate', text: 'Certificate', sort: true, headerFormatter: this.headerSpan, formatter: this.verificationFile },
            
        ];

        

        const tableData = {
            columns:columns,
            data:this?.state?.banksList,
            keyId:'id',
            loading:this.state.isLoader,
            message:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.totalRecord,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };



        return (
            <Fragment>
                <div className="tab_container">
                    <TableView TableData={tableData} />    
                </div>
            </Fragment>
        )
    }
}

export default withRouter(connect(null)(AccountDetails));
