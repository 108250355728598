import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as ACTION_TYPES from '../../redux/actions/types';
import './index.css';

const MySearch = (props) => {
    let input;
    const handleClick = (e) => {
        e.preventDefault();
        props.onSearch(input.value);
    };
    return (
        <form method="POST" action="#" onSubmit={handleClick}>
            <div className="search_bloc float_right">
                <input className="form-Scontrol" ref={n => input = n} type="text" placeholder="Search" />
                <button type="submit" className="btn-search">Search</button>
            </div>
        </form>
    );
};

class TableView extends Component {

    customTotal = (from, to, size) => {
        return <span className="custom_record react-bootstrap-table-pagination-total">
            {from} to {to} of Total {size}
        </span>
    }

    pageClick = ({ page, active, onPageChange }) => {
        const { handleClick } = this.props.TableData;
        return (
            <li className={active ? "page-item active" : "page-item"} key={page}>
                <Link to="#/javascript:void(0)" className={"page-link"} onClick={(e) => handleClick(e, page, onPageChange)} >{page}</Link>
            </li>
        );
    }

    render() {

        // const { SearchBar } = Search;
        const { columns, data, keyId, loading, message, noDataIndication, isUsers, searchFun, totalSize } = this.props.TableData;
        let options = null;
        let funSearch = '';
        if (isUsers) {
            options = {
                sizePerPage: 8,
                pageStartIndex: 1,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                pageButtonRenderer: this.pageClick
            };

            funSearch = searchFun;
        } else {
            options = {
                firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',
                showTotal: true,
                paginationTotalRenderer: this.customTotal,
            };

            funSearch = '';
        }
        return (
            <div className="table_wrap">
                <ToolkitProvider keyField={keyId} data={data} columns={columns} search={{ funSearch }} totalSize={isUsers && totalSize}  >
                    {
                        props => (
                            <div>
                                <MySearch {...props.searchProps} />
                                {/* <SearchBar { ...props.searchProps } className="search-table" placeholder="Search" /> */}
                                {/* <hr /> */}
                                <BootstrapTable
                                    {...props.baseProps} noDataIndication={!loading ? message : noDataIndication} classes="table_design" pagination={paginationFactory(options)}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addPage: (page) => dispatch({ type: ACTION_TYPES.PAGE_NUMBER_PAGINATION, payload: page })
})

export default withRouter(connect(null, mapDispatchToProps)(TableView));