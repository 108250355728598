import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoDataIndication from '../../TableLoader';
import SearchIcon from '../../../assets/images/search_icon.svg';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { apiHalper } from '../../../helpers/ApiHelper';
import { jwtDecode } from '../../../helpers/jwt_helper'
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';

class TopSkillCountries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            isSLoader: false,
            isMainLoader: false,
            pageID: null,
            countryArr: [],
            searchCountryArr: [],
            selectedCountryArray: [],
            selectedConID: [],
            searchVal: '',
            checked: false
        }
    }
    componentDidMount() {
        this.getAllCountry();
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            pageID: myParam
        })
        this.getSelectedCountry(myParam);
    }
    getAllCountry = async () => {
        try {
            this.setState({
                isLoader: true,
                isMainLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getCountries', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    countryArr: [],
                    searchCountryArr: [],
                    isLoader: false,
                    isMainLoader: false
                })
            }
            const selectedConList = jwtDecode(res.data)

            const sortList = selectedConList.sort((a, b) => {
                if (a.countryName > b.countryName) return 1
                if (a.countryName < b.countryName) return -1
                return 0;
            });
            // console.log(sortList);
            this.setState({
                searchCountryArr: sortList,
                countryArr: sortList,
                // selectedCountryArray: newArr,
                isLoader: false,
                isMainLoader: false
            })
        }
        catch (err) {
            console.error(err.message)
        }
    }

    countrySearchInput = (e) => {
        const { value } = e.target;
        const { countryArr } = this.state;

        if (value.length > 0) {
            const search = countryArr.filter(info => info.countryName.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                searchCountryArr: search,
                searchVal: value
            })
        } else {
            this.setState({
                searchCountryArr: countryArr,
                searchVal: value
            })
        }

    }

    searchCountry = async (e) => {
        e.preventDefault();
        const { searchCountryArr, searchVal } = this.state;

        const search = searchCountryArr.filter(info => info.countryName.toLowerCase() === searchVal.toLowerCase());
        this.setState({
            searchCountryArr: search
        })
    }
    addCountryBind = (data) => {
        let newArray = this.state.selectedCountryArray;
        let ConID = this.state.selectedConID;
        if (newArray.some(info => info.id === data.id)) {

            document.getElementById('countryID' + data.id).removeAttribute('checked', true);
            document.getElementById('countryID' + data.id).removeAttribute('class', 'active');

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }
        else {
            document.getElementById('countryID' + data.id).setAttribute('checked', true);
            document.getElementById('countryID' + data.id).setAttribute('class', 'active');
            newArray.push({ id: data.id, countryName: data.countryName });
            ConID.push(data.id);
        }

        if (newArray.length > 0) {
            this.setState({
                selectedCountryArray: newArray,
                selectedConID: ConID
            })
        }
    }

    CountryRender = (data, i) => {
        const { selectedConID } = this.state;
        let activeClass = '';
        let checked1 = false;

        if (selectedConID.includes(data.id)) {
            activeClass = "active";
            checked1 = true;
        }

        return <li key={i}>
            <label htmlFor={"countryID" + data.id}>
                <input type="checkbox" id={"countryID" + data.id} value={data.id} name="countryID" onClick={() => this.addCountryBind(data)} defaultChecked={checked1} className={activeClass} />
                <p><span>{data.countryName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }
    SelectedCountryRender = (data, i) => {
        return <li key={i}>
            <label htmlFor={"selectCon" + i}>
                <input type="checkbox" id={"selectCon" + i} value={data.id} name="selectedCon" defaultChecked onClick={() => this.removeSelectedCountry(data)} className="active" />
                <p><span>{data.countryName}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="add-icon" transform="translate(-13604 1017)">
                            <g id="Ellipse_20" className="rounded" data-name="Ellipse 20" transform="translate(13604 -1017)" fill="none" stroke="#fff" strokeWidth="1">
                                <circle cx="10" cy="10" r="10" stroke="none" />
                                <circle cx="10" cy="10" r="9.5" fill="none" />
                            </g>
                            <path id="plus" d="M9.5,4.5h-4V.5a.5.5,0,0,0-1,0v4H.5a.5.5,0,0,0,0,1h4v4a.5.5,0,1,0,1,0v-4h4a.5.5,0,1,0,0-1Zm0,0" transform="translate(13609 -1012)" fill="#fff" />
                        </g>
                    </svg>
                </p>
            </label>
        </li>
    }

    submitCountryList = async () => {
        const { selectedConID, pageID } = this.state
        try {
            if(selectedConID.length > 0) {
                this.setState({
                    isSLoader: true,
                    apiError: ''
                })
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData()

                formData.append('serviceCategoryID', pageID)
                formData.append('countryID', JSON.stringify(selectedConID))
                const response = await apiHalper('addTopCountriesSkills', 'POST', formData, headers)
                const res = response.data;

                if (!res.status) {
                    this.setState({
                        apiErr: res.message,
                        isSLoader: false
                    })
                    toster_error(res.message);
                    return true
                }
                toster_sucess(res.message);
                this.setState({
                    isSLoader: false
                })
            }
            else { 
                this.setState({
                    apiError: 'Please Select at least one Country',
                    isSLoader: false
                })
            }
        }
        catch (error) {
            console.log(error.message)
        }
    }

    getSelectedCountry = async (id) => {
        // try {
        //     this.setState({
        //         isLoader: true
        //     })
        //     const headers = {
        //         'Authorization': localStorage.getItem('token')
        //     }
        //     const response = await apiHalper(`getTopCountriesSkills/${id}`, 'GET', null, headers)
        //     const res = response.data;
        //     if (!res.status) {
        //         this.setState({
        //             apiErr: res.message,
        //             isLoader: false
        //         })
        //         return true
        //     }
        //     const decode = jwtDecode(res.data)
        //     let conId = []
        //     for (let cid = 0; cid < decode.length; cid++ ) {
        //         conId.push(decode[cid].id)
        //     }
        //     this.setState({
        //         selectedCountryArray: decode,
        //         selectedConID: conId
        //     })
        //     this.setState({
        //         isLoader: false
        //     })
        // }
        // catch (error) {
        //     console.log(error.message)
        // }
    }

    removeSelectedCountry = (data) => {
        let newArray = this.state.selectedCountryArray;
        let ConID = this.state.selectedConID;
        if (newArray.some(info => info.id === data.id)) {

            document.getElementById('countryID' + data.id).removeAttribute('checked', true);
            document.getElementById('countryID' + data.id).removeAttribute('class', 'active');

            const index = newArray.findIndex(info => info.id === data.id);
            const couIndex = ConID.findIndex(info => info === data.id);

            newArray.splice(index, 1);
            ConID.splice(couIndex, 1);
        }

        this.setState({
            selectedCountryArray: newArray,
            selectedConID: ConID
        })
    }

    render() {
        const { isSLoader, isMainLoader, apiError } = this.state;
        return (
            <>                
                <div className="top-list">
                    {isMainLoader ? <NoDataIndication /> : 
                        <div className={this.state.selectedCountryArray.length > 0 ? 'list country' : 'list country no-result'}>
                            <form action="/" method="POST" onSubmit={this.SelectedCountryList}>
                                <ul>
                                    {this.state.selectedCountryArray.length > 0 ?
                                        this.state.selectedCountryArray.map((data, i) => this.SelectedCountryRender(data, i)) : <li>No Country Available</li>}
                                </ul>
                            </form>
                        </div>
                    }
                    {isMainLoader ? <NoDataIndication /> : 
                        <div className={this.state.searchCountryArr.length > 0 ? 'list country' : 'list country no-result'}>
                            <form className="search-form" action="/" method="POST" onSubmit={this.searchCountry}>
                                <input autoComplete="off" type="text" value={this.state.searchVal} name="searchVal" placeholder="Search Country" onChange={this.countrySearchInput} />
                                <button type="submit"><img src={SearchIcon} alt="Search" /></button>
                            </form>
                            <form action="/" method="POST" onSubmit={this.CountryList}>
                                <ul>
                                    {this.state.searchCountryArr.length > 0 ? this.state.searchCountryArr.map((data, i) => this.CountryRender(data, i)) : <li>No Country Available</li>}
                                </ul>
                            </form>
                        </div>
                    }
                </div>
                <div className="bg_submit2 right-mx120">
                    {apiError !== '' ? <span className="skill-error">{apiError}</span> : null }
                    <button type="submit" className="btn-submit" onClick={this.submitCountryList}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                </div>                
            </>
        )
    }
}
export default withRouter(connect(null)(TopSkillCountries));