import * as ACTION_TYPES from '../actions/types';

const initialState={
    isCouponM:false,
    isAdd:true,
    editData:null,
    couponList:[]
}

const offersReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.OPEN_COUPON_MODAL:
            return{
                ...state,
                isCouponM:true,
                isAdd:action.payload.isAdd,
                editData:action.payload.data
            }
        case ACTION_TYPES.CLOSE_COUPON_MODAL:
            return{
                ...state,
                isCouponM:false,
                isAdd:action.payload.isAdd,
                editData:action.payload.data
            }
        case ACTION_TYPES.GET_COUPON_LIST:
            return{
                ...state,
                couponList:action.payload
            }
        default:
            return state;
    }
}
export default offersReducers;