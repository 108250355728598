
export function TimeRemaining(start, end){

    const difference = new Date(end) - new Date(start);
    const newdiff = new Date(end) - new Date();
    let timeLeft = {};

    if (difference > 0) {
        // console.log(difference);
        timeLeft = {
            totalDays: Math.floor(difference / (1000 * 3600 * 24)),
            days: Math.floor(newdiff / (1000 * 60 * 60 * 24)),
            hours: Math.floor((newdiff / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((newdiff / 1000 / 60) % 60),
            seconds: Math.floor((newdiff / 1000) % 60)
        };
    }else{
        timeLeft = {
            totalDays:0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }
    return timeLeft;
}

export function month_name(month){
    let mlist = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    return mlist[month];
}
