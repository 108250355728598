import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DummyProfile from "../../assets/images/dummy-profile.jpg";
import JobDetails from "./JobDetails";
import StatusDetails from "./StatusDetails";
import ProfileTab from "./ProfileTab";
import TrustDetails from "./TrustDetails";
import ReviewDetails from "./ReviewDetails";
import NoteDetails from "./NoteDetails";
import PayDetails from "./PayDetails";
import Wallet from "./Wallet";
import BidCharge from "./BidCharge";
import "./index.css";
import { apiHalper } from "../../helpers/ApiHelper";
import { jwtDecode } from "../../helpers/jwt_helper";
import * as ACTION_TYPES from "../../redux/actions/types";
import DeleteModal from "./DeleteModal/index";
import EditIcon from "../../assets/images/edit_icon.svg";
import SubmitIcon from "../../assets/images/submit.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import { toster_sucess, toster_error } from "../../helpers/toster_helper";
import { Button } from "react-bootstrap";
import WalletList from "./WalletTab";
class userDetails extends Component {
  _ismounted = false;

  constructor(props) {
    super(props);
    this.state = {
      type: null,
      id: null,
      isTester: false,
      activeTab: "",
      isConFModal: false,
      profilePic: "",
      isEditProfile: false,
    };
  }

  componentDidMount() {
    this._ismounted = true;
    const queryString = this.props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get("type");

    const { id } = this.props.match.params;
    this.setState({
      type: type,
      id: id,
    });
    this.viewProfile(type, id);
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  viewProfile = async (type, id) => {
    if (this._ismounted) {
      if (type === "agents") {
        const headers = {
          Authorization: localStorage.getItem("token"),
        };

        let formData = new FormData();
        formData.append("profile_id", id);

        const res = await apiHalper(
          "viewAgentProfile",
          "POST",
          formData,
          headers
        );
        if (res.data.status === true) {
          const data = jwtDecode(res.data.data);
          this.setState({
            isTester: data.is_tester === 1,
          });
          const { addDetails } = this.props;
          addDetails(data);
        }
      }

      if (type === "clients") {
        const headers = {
          Authorization: localStorage.getItem("token"),
        };

        let formData = new FormData();
        formData.append("profile_id", id);

        const res = await apiHalper(
          "viewClientProfile",
          "POST",
          formData,
          headers
        );
        if (res.data.status === true) {
          const data = jwtDecode(res.data.data);
          this.setState({
            isTester: data.is_tester === 1,
          });
          const { addDetails } = this.props;
          addDetails(data);
        }
      }
    }
  };

  removeTester = async () => {
    try {
      let testerCase = this.state.isTester;
      if (testerCase) {
        testerCase = false;
      } else {
        testerCase = true;
      }

      this.setState({
        isLoader: true,
      });

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      let formData = new FormData();
      formData.append("profile_id", this.state.id);
      formData.append("status", testerCase ? 1 : 0);

      const response = await apiHalper(
        "testerUserStatus",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (res.status) {
        this.setState({
          isLoader: false,
          isTester: testerCase,
        });
      } else {
        this.setState({
          isLoader: false,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  backTo = () => {
    const { history } = this.props;
    const queryString = this.props.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get("type");
    // if(this.state.type === )

    if (type === "jobs") {
      history.push("/allJobs");
    }
    if (type === "balance") {
      history.push("/balance");
    }
    if (type === "users") {
      history.push("/users/agent");
    }
    if (type === "clients") {
      history.push("/users");
    }
  };
  deleteConfirmation = (row) => {
    this.setState({
      updateType: "status",
      editService: row,
      title: "Delete Record",
      text: "Are you sure you want to delete this record?",
    });
    this.openDeleteModal();
  };
  openDeleteModal = () => {
    this.setState({
      isConFModal: true,
    });
  };
  closeCofiClose = () => {
    this.setState({
      isConFModal: false,
      title: "",
      text: "",
    });
  };
  deleteProfile = async (e) => {
    try {
      e.preventDefault();
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { id } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);

      const response = await apiHalper(
        "deleteAgentProfile",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (!res.status) {
        this.setState({
          apiErr: res.message,
        });
        toster_error(res.message);
        return true;
      }
      toster_sucess(res.message);
      setTimeout(() => {
        window.location.href = "/users/agents";
        this.setState({
          isConFModal: false,
          editData: null,
        });
      }, 500);
    } catch (err) {
      console.log(err.message);
    }
  };

  sendBidMail = async (username, email) => {
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      //const { username, email } = this.props.match.params;

      let formData = new FormData();
      formData.append("username", username);
      formData.append("email", email);

      console.log("formData", username, email);
      const response = await apiHalper(
        "sendBidMail",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (!res.status) {
        this.setState({
          apiErr: res.message,
        });
        toster_error(res.message);
        return true;
      }
      toster_sucess(res.message);
      setTimeout(() => {
        window.location.href = "/allJobs";
      }, 500);
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeProfilePic = async (e) => {
    try {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const { type, id } = this.state;

      //const { username, email } = this.props.match.params;

      let formData = new FormData();
      formData.append("profile_id", id);
      formData.append("profile_type_id", type === "agents" ? 1 : 2);
      formData.append("profile", e.target.files[0]);

      const response = await apiHalper(
        "addProfilePicture",
        "POST",
        formData,
        headers
      );
      const res = response.data;

      if (res.status) {
        const imgfilePath = localStorage.getItem("filePath");
        const decodePath = jwtDecode(imgfilePath);
        this.setState({
          profilePic:
            type === "clients"
              ? decodePath.client_img + res.data.profile_pic
              : res.data.path + res.data.profile_pic,
          isEditProfile: false,
        });
        this.viewProfile();
        toster_sucess(res.message);
      } else {
        this.setState({
          apiErr: res.message,
        });
        toster_error(res.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  handleDeleteProfilePic = () => {
    this.setState({ profilePic: "", isEditProfile: false });
  };
  render() {
    const { type, id, isTester, activeTab, profilePic, isEditProfile } =
      this.state;
    const { userDetails } = this.props;
    console.log({ userDetails, type });
    const imgfilePath = localStorage.getItem("filePath");
    const decodePath = jwtDecode(imgfilePath);
    console.log({ decodePath });
    const imgPath =
      userDetails?.img || userDetails?.profile_pic
        ? type === "agents"
          ? `${decodePath.img}${userDetails?.img}`
          : `${decodePath.client_img}${userDetails?.profile_pic}`
        : DummyProfile;
    const { isDeleModal, deleteId } = this.state;

    const DeleteModalData = {
      title: this.state.title,
      text: this.state.text,
      handleClose: this.closeCofiClose,
      isModal: this.state.isConFModal,
      submitFunc: this.deleteProfile,
      isLoader: this.state.isDLoader,
    };
    // console.log({ userDetails, profilePic: typeof profilePic });
    console.log(userDetails);
    return (
      <section className="bg_userDetails partner-detail">
        <div className="container-fluid">
          <div className="balance_wrap flex_wrap">
            <Tab.Container
              defaultActiveKey="jobs"
              className="tab_nav2"
              id="userDViews"
            >
              <div className="leftpart_1">
                <div>
                  <div className="user_wrrapper">
                    <div className="user-img-main">
                      <div className="img_dev">
                        <img
                          src={
                            !profilePic
                              ? imgPath
                              : typeof profilePic === "object"
                              ? URL.createObjectURL(profilePic)
                              : profilePic
                          }
                          alt="User"
                        />
                      </div>
                      <label className={`label-edit-btn`}>
                        <input
                          type="file"
                          name="profile"
                          onChange={this.handleChangeProfilePic}
                        />
                        <span>
                          <img src={EditIcon} />
                        </span>
                      </label>
                    </div>
                    <div className="user_content_wrap">
                      <h5>
                        {userDetails !== null ? userDetails.username : "--"}
                      </h5>
                      <p>
                        {userDetails !== null ? userDetails.email : "--"}{" "}
                        <span>
                          ID - {userDetails !== null ? userDetails.id : "--"}
                        </span>
                      </p>
                      <button
                        style={{ marginRight: "0" }}
                        className="btn-tester"
                        onClick={this.removeTester}
                      >
                        {isTester ? "Remove As Tester" : "Add as a tester"}
                      </button>
                      {type === "clients" && (
                        <button
                          style={{ marginLeft: "10px" }}
                          className="btn-bidEmail"
                          onClick={() =>
                            this.sendBidMail(
                              userDetails.username,
                              userDetails.email
                            )
                          }
                        >
                          Bid Email
                        </button>
                      )}
                    </div>
                  </div>

                  <Nav variant="pills" className="tab_nav2">
                    <Nav.Item>
                      <Nav.Link eventKey="jobs">Campaign</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="status">Status</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="trust">Trust</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="note"
                        onClick={() => {
                          this.setState({ activeTab: "note" });
                        }}
                      >
                        Note
                      </Nav.Link>
                    </Nav.Item>
                    {type === "agents" ? (
                      <Nav.Item>
                        <Nav.Link eventKey="pay">Pay</Nav.Link>
                      </Nav.Item>
                    ) : (
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="downloads">Downloads</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Wallet">Wallet</Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                    {type === "agents" ? (
                      <Nav.Item>
                        <Nav.Link eventKey="charge">Charge</Nav.Link>
                      </Nav.Item>
                    ) : null}

                    <Nav.Item>
                      <Nav.Link href="#" onSelect={this.backTo}>
                        Back
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="tabs_content rightpart_2">
                <Tab.Content>
                  <Tab.Pane eventKey="jobs">
                    <JobDetails id={id} type={type} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="status">
                    <StatusDetails id={id} type={type} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="profile">
                    <ProfileTab id={id} type={type} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="trust">
                    <TrustDetails id={id} type={type} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="reviews">
                    <ReviewDetails id={id} type={type} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="note">
                    {activeTab === "note" ? (
                      <NoteDetails id={id} type={type} />
                    ) : null}
                  </Tab.Pane>

                  {type === "agents" ? (
                    <Tab.Pane eventKey="pay">
                      <PayDetails id={id} type={type} />
                    </Tab.Pane>
                  ) : (
                    <>
                      <Tab.Pane eventKey="downloads">
                        {/* <PaymentDetails id={id} type={type} /> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="Wallet">
                        <Wallet />
                      </Tab.Pane>
                    </>
                  )}
                  {type === "agents" ? (
                    <Tab.Pane eventKey="charge">
                      <BidCharge id={id} type={type} />
                    </Tab.Pane>
                  ) : null}
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.users.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  addDetails: (data) =>
    dispatch({ type: ACTION_TYPES.USER_DETAILS_API, payload: data }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(userDetails)
);
