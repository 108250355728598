import React from 'react';
import { jwtDecode } from '../helpers/jwt_helper';
// import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const PrivateRoute = ({component, ...rest}) =>{
    const token = localStorage.getItem('token');
    const user = jwtDecode(token);
    // console.log(user);
    let ComponentToRender = component;
    // let location = useLocation();
    return(
        <Route
        {...rest} 
        render={(props) =>{
            return user !== null
            ? (
                <ComponentToRender {...props} />
                )
            : (
                <Redirect to="/" />
                )
            }
        }
        />
    );
}

// const mapStateToProps = (state) =>({
//     token:state.auth.token,
//     auth:state.auth.isAuth2
// })

export default withRouter(props=><PrivateRoute {...props} />);