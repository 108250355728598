import React, { Component, Fragment } from 'react';
import { withRouter, NavLink, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Income from './Income';
import Withdraw from './Withdraw';
import Profit from './Profit';
// import PrivateRoute from '../../Routes/PrivateRoutes';
import './index.css';

class Balance extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedOption: null,
            selectedOption2:null,
            selectedOption3:null,
            isClearable:true
        }
    }

    handleChange2 = selectedOption2 => {
        this.setState({ selectedOption2 });
        // console.log(`Option selected:`, selectedOption);
    };
    handleChange3 = selectedOption3 => {
        this.setState({ selectedOption3 });
        // console.log(`Option selected:`, selectedOption);
    };

    render() {
        
        const { match } = this.props;
        // console.log(this.props);
        const routUrl = [
            { name:'Income', url:`${match.url}`},
            { name:'Withdraw', url:`${match.url}/withdraw`},
            { name:'Profit', url:`${match.url}/profit`}
        ]

        return (
            <Fragment>
                <section className="bg-balance">
                    <div className="container-fluid">
                        <div className="balance_wrap">
                            <ul className="nesting_links">
                                {routUrl.map((data,i)=><li key={i}><NavLink activeClassName="active" exact to={data.url} >{data.name}</NavLink></li>)}
                            </ul>
                            <Switch>
                                <Route exact path={`${match.path}`} component={Income} />
                                <Route exact path={`${match.path}/withdraw`} component={Withdraw} />
                                <Route exact path={`${match.path}/profit`} component={Profit} />
                            </Switch>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default withRouter(connect(null)(Balance));
