import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as ACTION_TYPES from '../../redux/actions/types';
import DatePicker from 'react-datepicker'
// import Select from 'react-select';

class NotificationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            subtitle: '',
            topicName: '',
            startDate: '',
            showSendDate: 0,
            formError: {
                title: '',
                subtitle: '',
                topicName: ''
            },
            formValid: {
                title: false,
                subtitle: false,
                topicName: false
            },
            selectedUser: null,
            userSelError: ''
        }
    }

    handleChange = selectedOption => {
        this.setState({
            selectedUser: selectedOption,
            userSelError: null
        });
    };

    inputHandler = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validationChecked(name, value));
    }

    // topicInputHandler = e => {
    //     const { name, value } = e.target;
    //     if(value.length > 0) {
    //     let topic = value.replace("masspush_", "");
    //         this.setState({
    //             [name]: topic
    //         }, () => this.validationChecked(name, value));
    //     }
    //     else {
    //         this.setState({
    //             [name]: value
    //         })
    //     }
    // }

    validationChecked = (name, value) => {
        let error = this.state.formError;
        let valid = this.state.formValid;
        const reg = /^[a-zA-Z_]*$/g;
        switch (name) {
            case 'title':
                valid.title = value.length > 0;
                error.title = valid.title ? '' : 'Title must required!';
                break;
            case 'subtitle':
                valid.subtitle = value.length > 0;
                error.subtitle = valid.subtitle ? '' : 'Subtitle must required!';
                break;
            case 'topicName':
                if (value.length > 0) {
                    if (reg.test(value)) {
                        valid.topicName = true
                        error.topicName = '';
                    } else {
                        valid.topicName = false
                        error.topicName = 'Special Character, Space and numbers are not allowed, You can only use Alphabates and Underscore';
                    }
                } else {
                    valid.topicName = false;
                    error.topicName = 'Topic Name must required';
                }
                break;
            default:
                break;
        };

        this.setState({
            formError: error,
            formValid: valid
        });
    }

    componentDidUpdate(prevProps) {
        const { modalNoti } = this.props;
        if (prevProps.modalNoti !== modalNoti) {
            this.setState({
                title: '',
                subtitle: '',
                formError: {
                    title: '',
                    subtitle: ''
                },
                formValid: {
                    title: false,
                    subtitle: false
                },
                selectedUser: null,
                userSelError: '',
            })
        }
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    sendChecked = (e) => {
        const { checked } = e.target
        let checkVal = 0

        if (checked === true) {
            checkVal = 1
        }
        else {
            checkVal = 0
        }
        this.setState({
            showSendDate: checkVal
        })
    }
    sendNoti = async (e) => {
        e.preventDefault();
        const { handleClose, } = this.props;
        const { title, subtitle } = this.state
        const { sendSingleNotify, profileId } = this.props.modalData
        sendSingleNotify(profileId, title, subtitle)
        setTimeout(() => { handleClose() }, 1500);

    }

    sendAllNoti = async (e) => {
        e.preventDefault();
        const { handleClose } = this.props;
        const { title, subtitle, topicName, startDate, showSendDate } = this.state
        const { sendAllNotify, isTester, selectedFilter, selectedOption, days, selectedUser, search } = this.props.modalData
        sendAllNotify(isTester, selectedFilter, selectedOption, days, selectedUser, title, subtitle, topicName, startDate, showSendDate, search)
        setTimeout(() => { handleClose() }, 1500);
    }

    render() {

        const { isSendAll, modalNoti, handleClose } = this.props;
        const { userName } = this.props.modalData
        const { title, subtitle, topicName, formError, formValid, startDate, showSendDate } = this.state;

        const disabled = !isSendAll ? !formValid.title || !formValid.subtitle : !formValid.title || !formValid.subtitle || !formValid.topicName;

        return (
            <Modal show={modalNoti} onHide={() => handleClose()} className="modal_design" size={isSendAll ? "lg" : "sm"} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Notification <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals body_modals2">
                            <form method="POST" action="#" onSubmit={!isSendAll ? this.sendNoti : this.sendAllNoti}>
                                <div className="form_body2">
                                    {!isSendAll ? <p className="title_user">To: <span>{userName}</span></p> : null}
                                    <div className="form_group">
                                        <label className="label_form">Title</label>
                                        <input type="text" name="title" className={formError.title !== '' ? "form_control3 error_border" : "form_control3"} value={title} onChange={this.inputHandler} autoComplete="off" />
                                        {formError.title !== '' ? <p className="error_text">{formError.title}</p> : null}
                                    </div>
                                    <div className="form_group">
                                        <label className="label_form">Text</label>
                                        <input type="text" name="subtitle" className={formError.subtitle !== '' ? "form_control3 error_border" : "form_control3"} value={subtitle} onChange={this.inputHandler} autoComplete="off" />
                                        {formError.subtitle !== '' ? <p className="error_text">{formError.subtitle}</p> : null}
                                    </div>
                                    {isSendAll ? <>
                                        <div className="form_group">
                                            <label className="label_form">Topic Name</label>
                                            <input type="text" name="topicName" className="form_control3" value={topicName} onChange={this.inputHandler} autoComplete="off" />
                                            {formError.topicName !== '' ? <p className="error_text">{formError.topicName}</p> : null}
                                        </div>
                                        <div className="form_group align-items-baseline column-gap-15 d-flex flex">
                                            <div className="form_group checkbox-group">
                                                <input type="checkbox" value="1" id="sendLater" onChange={this.sendChecked} className="styled-checkbox" />
                                                <label htmlFor="sendLater">Send Later</label>
                                            </div>
                                            <div className={showSendDate ? "send-l-datepicker" : "send-l-datepicker d-none"}>
                                                {/* <DatePicker
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    showTimeSelect
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                /> */}

                                                <DatePicker placeholderText="Select Start Date" minDate={new Date()} showTimeSelect selected={startDate} onChange={date => this.setStartDate(date)} dateFormat="MMMM d, yyyy h:mm aa" />
                                            </div>
                                        </div>

                                    </>
                                        : null}
                                </div>
                                <div className="bg_submit2 mr-top">
                                    {!isSendAll ?
                                        <button type="submit" className="btn-submit" disabled={disabled}>Send</button>
                                        :
                                        <button type="submit" className="btn-submit" disabled={disabled}>Send all</button>
                                    }
                                    <p>Or <button type="button" className="btn_cancel2" onClick={() => handleClose()}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    modalNoti: state.notification.modalNoti,
    isSendAll: state.notification.isSendAll
});

const mapDispatchToProps = dispatch => ({
    handleClose: () => dispatch({ type: ACTION_TYPES.CLOSED_NOTIFICATION_MODAL })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationModal));
