import React from 'react';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/fonts.css';
import './assets/css/style.css';

function App() {
  return (
    <>
    <Routes />
    </>
  );
}

export default App;
