import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import NoDataIndication from '../TableLoader';
import DataTable from '../JqueryDataTable';
import Select from 'react-select';
import moment from 'moment';
import { toster_sucess } from '../../helpers/toster_helper';
import { toster_error } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import { Nav, Tab } from 'react-bootstrap';
import LoaderImg from '../../assets/images/user-loader.gif'
import './index.css'


class Location extends Component {
    _unmounted = false
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: { value: 1, label: 'Afghanistan', countryCode: 'AF', phoneCode: 93 },
            selectedState: {default:true, label:"Select State",},
            countries: [],
            statesOption: [],
            isHideToggle: 0,
            isLoader: false,
            stateList: [],
            isShowState: true,
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            selectedRow: [],
            isSelectAll: false,
            isModal: false,
            isEditModal: false,
            isClearable: false,
                cityName: '',
                stateName: '',
                stateCode: '',
                latitude: '',
                longitude: '',
            countryID: null,
            stateID: null,
            isSLoader: false,
            isEditCityTab: false,
            cityID: null,
            editData: [],
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { page, search } = this.state;
        this.getCountries();
        this.getStatelist(this.state.selectedOption.value, page, search);
    }

    componentWillUnmount() {
        this._unmounted = false
    }
    
    getCountries = async () => {
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                const response = await apiHalper('getCountries', 'GET', null, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    var option = Array.isArray(data) ? data.map(({ id, countryName }) => ({ value: id, label: countryName })) : [];
                    this.setState({
                        countries: option,
                    })
                } else {
                    this.setState({
                        isLoader: false,
                        countries: [],
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }

    }
    getState = async (country_id, page) => {
        const { sizePerPage } = this.state;
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                let formData = new FormData();
                formData.append("country_id", country_id)
                formData.append("search_by", '')
                formData.append("page_no", page)
                formData.append("limit", 1000)
                const response = await apiHalper('getState', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    console.log(data)
                    var option = Array.isArray(data.getState) ? data.getState.map(({ id, stateName }) => ({ value: id, label: stateName })) : [];
                    this.setState({
                        statesOption: option,
                        search: '',
                        // stateList: data.getState,
                        // totalRecord: data.total,
                        
                    })
                } else {
                    toster_error(res.message)
                    this.setState({
                        isLoader: false,
                        statesOption: [],
                        // countries: [],
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }
    getStatelist = async (country_id, page, search) => {
        const { sizePerPage } = this.state;
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                let formData = new FormData();
                formData.append("country_id", country_id)
                formData.append("search_by", search)
                formData.append("page_no", page)
                formData.append("limit", sizePerPage)
                const response = await apiHalper('getState', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    this.setState({
                        stateList: data.getState,
                        totalRecord: data.total,
                        search: '',
                    })
                } else {
                    toster_error(res.message)
                    this.setState({
                        isLoader: false,
                        stateList: [],
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }
    getCities = async (page, search, country_id, state_id) => {
        const { sizePerPage } = this.state;
        this.setState({
            isLoader: true,
        });
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                });

                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                let formData = new FormData();
                formData.append("country_id", country_id)
                formData.append("search_by", search)
                formData.append("page_no", page)
                formData.append("limit", sizePerPage)
                formData.append("state_id", state_id)
                const response = await apiHalper('getCities', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    this.setState({
                        stateList: data.getCity,
                        isLoader: false,
                        totalRecord: data.count,
                        search: '',
                    })
                } else {
                    this.setState({
                        isLoader: false,
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }

    deleteLocation = async () => {
        const { isShowState, selectedRow, page, search} = this.state
        try {
            if (this._unmounted) {
                this.setState({
                    isLoader: true,
                });
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }
                
                let formData = new FormData();
                formData.append("id", JSON.stringify(selectedRow))
                formData.append("type", isShowState?"1":"2")
                const response = await apiHalper('deleteLocation', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    this.setState({
                        isLoader: false,
                        isShowState: true,
                        isSelectAll: false,
                        selectedState: {default:true, label:"Select State",},
                        selectedRow: [],
                    })
                    toster_sucess(res.message)
                    this.getCountries();
                    this.getStatelist(this.state.selectedOption.value, page, search);
                    this.getState(this.state.selectedOption.value, page, search);
                } else {
                    toster_error(res.message)
                    this.setState({
                        isLoader: false,
                        selectedState: {default:true, label:"Select State",}
                    });
                }
            }
        } catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        const { istester } = this.state;
        if (type === 'pagination') {
            document.getElementById('checkAll').checked = false;
            this.setState({
                page,
                sizePerPage,
                selectedRow: [],
                isSelectAll: false,
            });
            var country_id = this.state.selectedOption.value
            if(this.state.isShowState){
                this.getStatelist(country_id, page, searchText)
            }else{
                console.log(this.state.selectedState)
                this.getCities(page, searchText, country_id, this.state.selectedState.value);
            }
        }

        if (type === 'search') {
            document.getElementById('checkAll').checked = false;
            this.setState({
                search: searchText,
                selectedRow: [],
                isSelectAll: false,
            });
            var country_id = this.state.selectedOption.value
            if(this.state.isShowState){
                this.getStatelist(country_id, page, searchText)
            }else{
                this.getCities(page, searchText, country_id, this.state.selectedState.value);
            }
        }        
    }
    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'check':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-left imgRight"}><label className="custom-checkbox table-header-checkbox"><input type="checkbox" id='checkAll' readOnly onChange={(e)=> this.handleCheckAll(e)} /><span className="checkmark"></span> ALL</label></span>;
            case 'stateName':
                return <span className={column.sort ? "header_span sort text-left imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'cityName':
                return <span className={column.sort ? "header_span sort text-left imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'countryCode':
                return <span className={column.sort ? "header_span sort text-left imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'phoneCode':
                return <span className={column.sort ? "header_span sort text-left imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-left imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    
    timeSet = (cell) => {
        return moment(cell).format('MMM DD, YYYY');
    }

    checkStateField = (cell, row) => {  
        return <label className="custom-checkbox"><input type="checkbox" id={row.id} key={row.id} onChange={(e)=> {this.handleCheck(e, row.id)}} /><span className="checkmark"></span></label>;
    }
    checkCityField = (cell, row) => {  
        return <label className="custom-checkbox"><input type="checkbox" id={row.cityID} key={row.cityID} onChange={(e)=> {this.handleCheck(e, row.cityID)}} /><span className="checkmark"></span></label>;
    }
    actionField = (cell, row) => {
        return <button type="button" onClick={()=>{this.showModal(row, false)}} className="btn-view max-90 margin_0auto" >Edit</button>
    }
    actionCityField = (cell, row) => {
        return <button type="button" onClick={()=>{this.showModal(row, true)}} className="btn-view max-90 margin_0auto" >Edit</button>
    }
    
    showModal = (data, isEditCity) => {
        if(!isEditCity){
            this.setState({
                stateID: data.id,
            })
        }
        this.setState({
            cityID: data.cityID,
            isEditCityTab: isEditCity,
            isModal: true,
            isEditModal: true,
            stateName: data.stateName,
            cityName: data.cityName,
            latitude: data.latitude,
            longitude: data.longitude,
            stateCode: data.stateCode,
            countryID: this.state.selectedOption.value
        })
    }

    editStateLocation = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                isSLoader: true
            })
            const { countryID, stateCode, stateName, stateID, longitude, latitude , page, search} = this.state;
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append("countryID", countryID)
            formData.append("state_id", stateID)
            formData.append("stateCode", stateCode)
            formData.append("stateName", stateName)
            formData.append("latitude", latitude)
            formData.append("longitude", longitude)
            const response = await apiHalper('editLocation', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                toster_sucess(res.message)
                this.setState({
                    isModal: false,
                    isSLoader: false,
                    cityName: '',
                    stateName: '',
                    stateCode: '',
                    latitude: '',
                    longitude: '',
                })
                this.getStatelist(countryID, page, search);
            } else {
                toster_error(res.message)
                this.setState({
                    isModal: false,
                });
            }
        }catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }
    
    editCityLocation = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                isSLoader: true
            })
            const { stateID, countryID, cityName, longitude, latitude, cityID } = this.state;
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append("countryID", countryID)
            formData.append("state_id", stateID)
            formData.append("city_id", cityID)
            formData.append("cityName", cityName)
            formData.append("latitude", latitude)
            formData.append("longitude", longitude)
            const response = await apiHalper('editLocation', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                toster_sucess(res.message)
                this.setState({
                    isModal: false,
                    search: '',
                    page: 1,
                    isSLoader: false,
                    cityName: '',
                    stateName: '',
                    stateCode: '',
                    latitude: '',
                    longitude: '',
                })
                const {page, search} = this.state;
                this.getCities(page, search, countryID, stateID);
            } else {
                toster_error(res.message)
                this.setState({
                    isModal: false,
                });
            }
        }catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }

    handleChange = (selectedOption) => {
        this.setState({
            countryID: selectedOption.value,
            selectedOption: selectedOption ,
            selectedState: {default:true, label:"Select State"},
            search: '',
            isLoader: true,
            isShowState: true,
            statesOption: [],
            page: 1,
            selectedRow: [],
            isSelectAll: false,
        })
        const {page, search} = this.state;
        this.getStatelist(selectedOption.value, page, '');
        this.getState(selectedOption.value, page);
    }   

    handleGetCountryID = (selectedOption) => {
        this.setState({
            countryID: selectedOption.value,
        })
        const { page } = this.state;
        this.getState(selectedOption.value, page);
    }
    handleGetStateID = (selectedOption) => {
        this.setState({
            stateID: selectedOption.value,
        })
    }

    handleChangeState = (selectedOption) => {
        this.setState({
            selectedState: selectedOption,
            search: '',
            page: 1,
            isShowState: false,
            stateID: selectedOption.value,
            selectedRow: [],
            isSelectAll: false,
        })
        var country_id = this.state.selectedOption.value
        const {page, search} = this.state;
        this.getCities(page, '', country_id, selectedOption.value);
    }

    handleCheckAll = (e) => {
        var selectedList;
        if(this.state.stateList[0].id == undefined){
            selectedList = Array.isArray(this.state.stateList) ? this.state.stateList.map(({ cityID }) => (cityID)) : [];
        }else{
            selectedList = Array.isArray(this.state.stateList) ? this.state.stateList.map(({ id }) => (id)) : [];
        }
        
        if(e.target.checked){
            selectedList.map((data) => {
                document.getElementById(data).checked = true;
            })
            this.setState({
                selectedRow: selectedList,
                isSelectAll: true,
            })
        }else{
            selectedList.map((data) => {
                document.getElementById(data).checked = false;
            })
            this.setState({
                selectedRow: [],
                isSelectAll: false,
            })
        }
    }

    handleCheck = (e, row_id) => {
        if(e.target.checked){
            var selected = this.state.selectedRow
            selected.push(row_id)
            this.setState({
                selectedRow: selected,
                isSelectAll: true,
            })
        }else{
            var selected = this.state.selectedRow
            var selectedArr = selected.filter(function(e) { return e !== row_id })
            this.setState({
                selectedRow: selectedArr,
            })
            if(selectedArr.length <= 0){
                this.setState({
                    isSelectAll: false,
                })
            }
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                isSLoader: true
            })
            let { countryID, stateName, stateCode, latitude, longitude, page, search } = this.state;
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append("country_id", countryID)
            formData.append("stateName", stateName)
            formData.append("stateCode", stateCode)
            formData.append("latitude", latitude)
            formData.append("longitude", longitude)
            const response = await apiHalper('addState', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                toster_sucess(res.message)
                this.setState({
                    isModal: false,
                    isShowState: true,
                    isSLoader: false,
                })
                this.getStatelist(countryID, page, search);
            } else {
                toster_error(res.message)
                this.setState({
                    isModal: false,
                });
            }
        }catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }
    handleSubmitCity = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                isSLoader: true
            })
            let { countryID, stateID, cityName, latitude, longitude, search, page } = this.state;
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append("country_id", countryID)
            formData.append("state_id", stateID)
            formData.append("cityName", cityName)
            formData.append("latitude", latitude)
            formData.append("longitude", longitude)
            const response = await apiHalper('addCities', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                toster_sucess(res.message)
                this.setState({
                    isModal: false,
                    page: 1,
                    search: '',
                    isSLoader: false,
                })
                this.getCities(page, search, countryID, stateID);
            } else {
                toster_error(res.message)
                this.setState({
                    isModal: false,
                });
            }
        }catch (err) {
            this.setState({
                apiMessage: err.message,
                isLoader: false,
            });
        }
    }
    showAdd = () => {
        this.setState({
            cityName: '',
            stateName: '',
            stateCode: '',
            latitude: '',
            longitude: '',
            isModal:true
        })
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    
    render() {
        const {isSLoader, isEditCityTab, countryID, selectedOption, isClearable, countries, statesOption, isSelectAll, isModal, isEditModal, isShowState, selectedState, stateName, formData, stateCode, latitude, longitude } = this.state
        let disabled = countryID === null
        let columns = []
        {isShowState?
            columns = [
                { dataField: 'check', text: '', sort: false, headerFormatter: this.headerSpan, formatter: this.checkStateField },
                { dataField: 'stateName', text: 'State Name', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'stateCode', text: 'State Code', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'phoneCode', text: 'Phone Code', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField },
            ]
        :
            columns = [
                { dataField: 'check', text: '', sort: false, headerFormatter: this.headerSpan, formatter: this.checkCityField },
                { dataField: 'cityName', text: 'City Name', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'countryCode', text: 'Country Code', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'phoneCode', text: 'Phone Code', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
                { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionCityField },
            ]
        }

        const tableData = {
            columns: columns,
            data: this.state.stateList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            sizePerPage: this.state.sizePerPage,
            totalRecord: this.state.totalRecord,
            handleTableChange: this.handleTableChange,
            rowClass: null
        };

        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap settings hide-column">
                        <div className="select_wrap location_select_wrap">
                            <Select className="select_ele" isClearable={isClearable} isSearchable={true} value={selectedOption} placeholder={"Select Country"} onChange={(option)=> this.handleChange(option)} options={countries} />
                            <Select className="select_ele" isClearable={isClearable} value={selectedState} placeholder={"Select State"} onChange={(option)=> this.handleChangeState(option)} options={statesOption} />
                            <button className='btn-add' onClick={()=>this.showAdd() }>ADD</button>
                            {isSelectAll?
                            <button className='btn-delete-all' onClick={()=>{this.deleteLocation()}}>DELETE</button>
                            :null}
                        </div>
                        <DataTable TableData={tableData} />
                    </div>
                </div>
                <Modal show={isModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">{isEditModal? "Edit": "Add"} State or City <button type="button" onClick={()=>{this.setState({isModal:false, isEditModal:false})}}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals">
                                    <Tab.Container id="" defaultActiveKey={isEditCityTab?"city":"state"}>
                                        <Nav className="tabs">
                                            <Nav.Link eventKey="state">{isEditModal? "Edit": "Add"} State</Nav.Link>
                                            <Nav.Link eventKey="city">{isEditModal? "Edit": "Add"} City</Nav.Link>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="state">
                                            <form method="POST" action="/"  onSubmit={isEditModal? this.editStateLocation :this.handleSubmit}>
                                                <div className="form_group">
                                                    {isEditModal?
                                                    <Select className="select_out" isSearchable={true} value={selectedOption} isClearable={isClearable} placeholder={"Select Country"} onChange={(option)=> this.handleGetCountryID(option)} options={countries} />
                                                    :
                                                    <Select className="select_out" isSearchable={true} isClearable={isClearable} placeholder={"Select Country"} onChange={(option)=> this.handleGetCountryID(option)} options={countries} />
                                                    }
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>State Name</label>
                                                        <input type="text" name="stateName" value={this.state.stateName} className= "form_control2" placeholder="State Name" onChange={this.inputHandler} autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>State Code</label>
                                                        <input type="text" name="stateCode" value={this.state.stateCode} className="form_control2" placeholder="State Code" onChange={this.inputHandler} autoComplete="off" required />
                                                        
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>Latitude</label>
                                                        <input type="text" name="latitude" value={this.state.latitude} className="form_control2" placeholder="Latitude" onChange={this.inputHandler} autoComplete="off" required />
                                                      
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>Longitude</label>
                                                        <input type="text" name="longitude" value={this.state.longitude} className="form_control2" placeholder="Longitude" onChange={this.inputHandler} autoComplete="off" required />
                                                        
                                                    </div>
                                                </div>
                                                <div className="bg_submit2 right-mx120">
                                                    <button type="submit" className="btn-submit" disabled={disabled}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                                
                                                </div>
                                            </form>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="city">
                                            <form method="POST" action="/"  onSubmit={isEditModal? this.editCityLocation :this.handleSubmitCity}>
                                                <div className="form_group">
                                                 {isEditModal?
                                                    <Select className="select_out" isSearchable={true} value={selectedOption} isClearable={isClearable} placeholder={"Select Country"} onChange={(option)=> this.handleGetCountryID(option)} options={countries} />
                                                    :
                                                    <Select className="select_out" isSearchable={true} isClearable={isClearable} placeholder={"Select Country"} onChange={(option)=> this.handleGetCountryID(option)} options={countries} />
                                                    }
                                                </div>
                                                <div className="form_group">
                                                {isEditModal?
                                                    <Select className="select_out" value={selectedState} isClearable={isClearable} placeholder={"Select State"} onChange={(option)=> this.handleGetStateID(option)} options={statesOption} />
                                                    :
                                                    <Select className="select_out" isClearable={isClearable} placeholder={"Select State"} onChange={(option)=> this.handleGetStateID(option)} options={statesOption} />
                                                    }
                                                    
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>City Name</label>
                                                        <input type="text" name="cityName" value={this.state.cityName} className="form_control2" placeholder="City Name" onChange={this.inputHandler} autoComplete="off" required />
                                                        
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>Latitude</label>
                                                        <input type="text" name="latitude" value={this.state.latitude} className="form_control2" placeholder="Latitude" onChange={this.inputHandler} autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="form_group">
                                                        <label>Longitude</label>
                                                        <input type="text" name="longitude" value={this.state.longitude} className="form_control2" placeholder="Longitude" onChange={this.inputHandler} autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="bg_submit2 right-mx120">
                                                    <button type="submit" className="btn-submit" disabled={disabled}>{isSLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                                </div>
                                            </form>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}

export default withRouter(connect(null)(Location))
