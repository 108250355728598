import * as ACTION_TYPES from '../actions/types';

const initialState={
    detailsData:null,
}

const couReportReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.GET_COUPON_REPORT_DETAILS:
            return{
                ...state,
                detailsData:action.payload
            }
        default:
            return state;
    }
}
export default couReportReducers;