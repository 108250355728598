import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Select from 'react-select';


class FilterModel extends Component {
    render() {
        const {Show , handelCloseFilter} = this.props;
        return (
            <Modal show={Show} onHide={handelCloseFilter} className="modal_design" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Filter <button type="button" onClick={handelCloseFilter}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#">
                                <div className="form_body">
                                    <div className="wrap_filterB flex_wrap">
                                        <h4>Sort By</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Date: New to Old</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Date: Old to New</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Waiting > Pending > Done</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Done > Pending > Waiting</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB flex_wrap">
                                        <h4>Date Range</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>All</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Select custom</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB flex_wrap">
                                        <h4>Filter</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Any Status</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Waiting</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Pending</span></label>
                                            </li>
                                            <li>
                                                <label><input type="radio"  name="checkBox" /><span>Done</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB">
                                        <h4>Client</h4>
                                        <Select placeholder="Serah for client username or email"  />
                                    </div>
                                    <div className="wrap_filterB">
                                        <h4>Agent</h4>
                                        <Select placeholder="Serah for agent username or email" />
                                    </div>    
                                </div>
                                <div className="bg_submit2 mr-top">
                                    <button type="submit" className="btn-submit">Apply</button>
                                    <p>Or <button type="button" className="btn_cancel2" onClick={handelCloseFilter}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default FilterModel