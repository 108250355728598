import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Details from './Details';
import * as ACTIVE_TYPES from '../../redux/actions/types';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import DataTable from '../../components/JqueryDataTable';
import NoDataIndication from '../../components/TableLoader';
import ConfirmModal from '../ConfirmModal';
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
// import SubmitDetails from './SubmitDetails';
// import Files from './Files';
// import PaymentDetails from './PaymentDetails';
import './index.css';
import { toster_error, toster_sucess } from '../../helpers/toster_helper';
import ReassignJobModal from './ReassignJobModal';

class IncrementDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cid: null,
            type: null,
            agentID: null,
            bidID: null,
            state: null,
            is_under_review: null,
            isViewProposal: false,
            submit_type: null,
            pageNo: 1,
            recordCount: 0,
            listData: [],
            isLoader: false,            
            isMainLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            modalState: false,
            modalMesage: '',
            reassign_modal: false
        }
    }

    componentDidMount() {        
        const { cid } = this.props.match.params;
        const queryString = this.props.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type');
        const agentID = urlParams.get('agentID')
        const bidID = urlParams.get('bidID')
        const ur = urlParams.get('ur')
        const state = urlParams.get('state')

        this.setState({
            cid: cid,
            type: type,
            is_under_review: ur,
            agentID: agentID,
            bidID: bidID,
            state:state
        })

        this.backTo(type, cid);
    }

    backBtn = () => {
        const { detailView, history } = this.props;
        detailView(null)
        let prevPath = localStorage.getItem('prevPath')
        if(prevPath){
            history.push(prevPath);
        }else{
            history.push('/allJobs');
        }
        localStorage.removeItem('prevPath')
    }

    backTo = (type, id) => {
        const { history } = this.props;
        if (id === null) {
            if (type === 'jobs') {
                history.push('/allJobs');
            }
            if (type === 'balance') {
                history.push('/balance');
            }
            if (type === 'agents') {
                history.push('/users/agents');
            }
            if (type === 'offers') {
                history.push('/offers/usedcoupon');
            }
            if (type === 'users') {
                history.push('/users/agent');
            }
        }
    }
    viewProposal = () => {
        const { isViewProposal, page, cid } = this.state
        this.setState({
            isViewProposal: !isViewProposal
        })
        if(!isViewProposal) {
            this.getProposalList(cid, page)
        }
    }
    headerSpan = (column) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'is_hired':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'message':
                return <span className={column.sort ? "header_span sort" : "header_span text-center"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort" : "header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    imgNotFound = (e)=>{
        e.target.src = DummyProfile;
    }
    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.img}`
        return <div className="tb_user_wrap p-0">
            <div className="useimg_block online_icon">
                <img src={row.img !== null? imgPath: DummyProfile} onError={(e)=>this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }
    amountField = (cell, row) => {
        return <div className="text-center amount">{row.amount} {row.currency}</div>
    }

    descriptionField = (row) => {
        return row
    }
    statusField = (cell) => {
        switch (cell) {
            case 0:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Not Hired'}</button>;
            case 1:
                return <button type="button" className="btn-status blue margin_0auto" disabled>{'Hired'}</button>;
            default: 
                break
        }
    }
    
    actionField = (row) => {
        // to={`/partner-detail?id=${row.id}`}
        return <Link className="btn-view margin_0auto">View Chat</Link>
    }
    
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if(type === 'pagination') {            
            this.setState({
                pageNo: page,
                sizePerPage,
            });
            this.getProposalList(this.state.cid, page);
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
            this.getProposalList(this.state.cid, page);
        }

    }
    getProposalList = async (id, pageNo) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('job_post_id', id)
            formData.append('page_no', pageNo )
            
            const response = await apiHalper('viewProposals', 'POST', formData, headers);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    listData: decode.bid_list,
                    recordCount: decode.count,
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    listData: [],
                    recordCount: 0,
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                listData: [],
                messageApi: err.message
            })
        }
    }

    modalCloseConfirm = () =>{
        this.setState({
            modalState: false,
            modalMesage: ""
        })
    }
    
    handleOnConfirm = (type) => {
        if(type==='approve'){
            this.setState({
                modalState: true,
                modalMesage:"approve this job?",
                submit_type:'approve'
            })
        }else{
            this.setState({
                modalState: true,
                modalMesage:"reject this job?",
                submit_type:'reject'
            })
        }
    }

    handleReassignModal = () => {
        this.setState({
            reassign_modal: !this.state.reassign_modal
        })
    }

    handleSubmitApprove = async () => {
        try{
            this.setState({
                isDLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('job_post_id', this.state.cid)

            const response = await apiHalper('approveJobPost', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isDLoader: false
                });
                toster_sucess("Approve Successfully...");
                this.modalCloseConfirm()
            } else {
                this.setState({
                    isDLoader: false
                });
                toster_error(res.message)
            }
        }catch (err){
            console.log(err)
        }
    }
    handleSubmitReject = async () => {
        try{
            this.setState({
                isDLoader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            formData.append('job_post_id', this.state.cid)

            const response = await apiHalper('rejectJobPost', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isDLoader: false
                });
                toster_sucess(res.message);
                this.modalCloseConfirm()
            } else {
                this.setState({
                    isDLoader: false
                });
                toster_error(res.message)
            }
        }catch (err){
            console.log(err)
        }
    }

    render() {
        // console.log(this.props.match.params);
        const { cid, agentID, state, bidID, type, isViewProposal, listData, isLoader, messageApi, pageNo, submit_type, modalState, is_under_review, reassign_modal } = this.state;

        const reassignJobModalData = {
            isOpen: this.state.reassign_modal,
            modalClose: this.handleReassignModal,
            job_id: cid,
            agentID: agentID,
            bidID: bidID
            // text: this.state.modalMesage,
            // confirmHandler: submit_type === 'approve' ? this.handleSubmitApprove: this.handleSubmitReject
        }

        const confirmModalData = {
            isOpen: this.state.modalState,
            modalClose: this.modalCloseConfirm,
            text: this.state.modalMesage,
            confirmHandler: submit_type === 'approve' ? this.handleSubmitApprove: this.handleSubmitReject
        }
        
        const columns = [
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter:this.userField },
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField },
            { dataField: 'is_hired', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'message', text: 'Description', sort: true, headerFormatter: this.headerSpan, formatter:this.descriptionField }, 
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: listData,
            keyId: 'username',
            loading: isLoader,
            message: messageApi !== '' ? messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: pageNo,
            totalRecord: this.state.recordCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange
        };
        
        return (
            <Fragment>
                <section className="bg-balance" >
                    <div className="container-fluid">
                        <div className={!isViewProposal ? "balance_wrap set-back" : "balance_wrap"}>
                        
                            {!isViewProposal ?
                                <div className={'btns'} style={{flexDirection:'row'}}>
                                    {is_under_review === '1'?
                                        <div className='btn-wrapper'>
                                            <button className='btn btn-primary' style={{backgroundColor:"green"}} onClick={()=> this.handleOnConfirm('approve')}>APPROVE JOB</button>
                                            <button className='btn btn-danger' onClick={()=> this.handleOnConfirm('reject')}>REJECT JOB</button>
                                        </div>
                                    :null}
                                    
                                    
                                    <div className='btn-wrapper'>
                                        {state === '2' ?<button className="btn btn-warning reassign-btn" onClick={this.handleReassignModal}>Reassign Job</button>:null}
                                        <button className="btn btn-primary" onClick={this.viewProposal}>View All Proposal</button>
                                        {type === 'jobs' ? <button onClick={this.backBtn} className="btn-back display_inlineBlock">Back</button> : null}
                                        {type === 'offers' ? <Link to="/offers/usedcoupon" onClick={this.backBtn} className="btn-back  display_inlineBlock">Back</Link> : null}
                                        {type === 'balance' ? <Link to="/balance" onClick={this.backBtn} className="btn-back  display_inlineBlock">Back</Link> : null}
                                        <div className="onoffswitch d-none">
                                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                                            <label className="onoffswitch-label" for="myonoffswitch">
                                                <span className="onoffswitch-inner"></span>
                                                <span className="onoffswitch-switch"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {!isViewProposal ? 
                                <Details id={cid} /> :
                                <div className="proposal-list">
                                    <button className="proposal-title btn-back right_btn display_inlineBlock" onClick={this.viewProposal}>Back to Job</button>                                    
                                    <DataTable TableData={tableData} />
                                </div>                                    
                            }
                        </div>
                    </div>
                </section>
                {modalState?
                    <ConfirmModal modalData={confirmModalData}/>
                :null}
                {reassign_modal?
                    <ReassignJobModal modalData={reassignJobModalData} />
                :null}
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(IncrementDetails));
