import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from '../JqueryDataTable';
import moment from 'moment';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import NotificationModal from './NotificationModal';
import * as ACTION_TYPES from '../../redux/actions/types';
import NoDataIndication from '../TableLoader';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
// import { selectFilter } from 'react-bootstrap-table2-filter';

class UsersLists extends Component {
    _unmounted = false
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            isFilterShow: false,
            profileId: null,
            selectedFilter: [{ value: 0, label: 'All Applications' }],
            selectedOption: [{ value: 0, label: 'All Countries' }],
            selectedUser: 0,
            days: '',
            issLoader: false,
            messageAApi: '',
            isTester: false,
            clientList: [],
            userName1: '',
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            userlist: []
        }
    }

    componentDidMount() {
        this._unmounted = true
        const { page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search } = this.state;
        this.getUserList(page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search);
        this.getAllApps();
        this.getAllCountry();
    }

    componentWillUnmount() {
        this._unmounted = false
    }

    getUserList = async (page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search) => {
        try {
            this.setState({
                isLoader: true,
                clientList: [],
                viewBtn: 0,
                isTopRate: false
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('pageNo', page)
            
            formData.append('limit', sizePerPage)
            formData.append('showTester', isTester ? 1 : 0)
            
            // formData.append('appID', selectedFilter ? selectedFilter.value : 0)
            // formData.append('countryID', selectedOption ? selectedOption.value : 0)
            let singleArray = selectedFilter.map((selectedFilter) => (selectedFilter.value))
            let commaSeparatedIds = singleArray.join(",")

            formData.append('appID', selectedFilter ? commaSeparatedIds : 0)
            
            let singleCArray = selectedOption.map((selectedOption) => (selectedOption.value))
            let commaSeparatedIdC = singleCArray.join(",")

            formData.append('countryID', selectedOption ? commaSeparatedIdC : 0)

            formData.append('dormantDays', days ? days : 0)
            formData.append('userType', selectedUser.value ? selectedUser.value : 0)
            formData.append('search_by', search)

            const response = await apiHalper('getAllClients', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    clientList: data.user_data,
                    totalRecord: data.user_count
                });
            }

            else {
                this.setState({
                    isLoader: false,
                    clientList: [],
                    totalRecord: 0
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    sendNoti = async (profileId, title, text) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('profile_id', profileId)
            formData.append('title', title)
            formData.append('text', text)

            const response = await apiHalper('sendSinglePush', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isLoader: false
                });
                toster_error(res.message);
                return true
            }
        }
        catch (err) {
            console.log(err.message);
        }
    }

    sendAllNoti = async (isTester, selectedFilter, selectedOption, days, selectedUser, title, text, topicName, startDate, showSendDate, search) => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            
            let formData = new FormData();
            formData.append('showTester', isTester ? 1 : 0)

            let singleArray = selectedFilter.map((selectedFilter) => (selectedFilter.value))
            let commaSeparatedIds = singleArray.join(",")

            formData.append('appID', selectedFilter ? commaSeparatedIds : 0)
            
            let singleCArray = selectedOption.map((selectedOption) => (selectedOption.value))
            let commaSeparatedIdC = singleCArray.join(",")

            formData.append('countryID', selectedOption ? commaSeparatedIdC : 0)

            // formData.append('appID', selectedFilter ? selectedFilter.value : 0)
            // formData.append('countryID', selectedOption ? selectedOption.value : 0)
            
            formData.append('dormantDays', days ? days : 0)
            formData.append('userType', selectedUser.value ? selectedUser.value : 0)
            formData.append('title', title)
            formData.append('text', text)
            formData.append('topicName', topicName)            
            formData.append('sendDate', moment(startDate, ["YYYY-MM-DD h:mm A"]).format('YYYY-MM-DD HH:mm:ss'))
            formData.append('sendLater', showSendDate)
            formData.append('search_by', search)

            const response = await apiHalper('sendOfferPush', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isLoader: false,
                    topicName: ''
                });
                toster_error(res.message);
                return true
            }
        }
        catch (err) {
            console.log(err.message);
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort pd_left" : "header_span pd_left"}>{column.text}</span>;
            case 'signup_date':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'contact_no':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'email':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'total_jobs':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'profile_status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    viewModal = (data) => {
        const { modalOpen } = this.props;

        this.setState({
            profileId: data.id,
            userName1: data.username,
        }, () => {
            modalOpen(false)
        })
    }

    actionField = (cell, row) => {
        return <button type="button" className="btn-view margin_0auto" onClick={() => this.viewModal(row)}>Send</button>;
    }

    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }
    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.photo}`
        console.log({imgPath, decodePath, row })
        return <div className="tb_user_wrap">
            <div className="useimg_block online_icon">
                <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username} {this.checkRatings(row.star)}</p>
        </div>
    }

    checkRatings = (count_rating) => {
        switch (count_rating) {
            case 1:
                return <span className="rating_wrap rating1"></span>;
            case 2:
                return <span className="rating_wrap rating2"></span>;
            case 3:
                return <span className="rating_wrap rating3"></span>;
            case 4:
                return <span className="rating_wrap rating4"></span>;
            case 5:
                return <span className="rating_wrap rating5"></span>;
            default:
                return <span className="rating_wrap rating0"></span>;
        }
    }

    dateField = (cell, row) => {
        return <span className="margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }
    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell ? cell : '-'}</span>;
    }
    leftField = (cell, row) => {
        return <span className=" margin_0auto">{cell ? cell : '-'}</span>;
    }

    statusField = (profile_status) => {
        switch (profile_status) {
            case 0:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Signed Up'}</button>;
            case 1:
                return <button type="button" className="btn-status btn-status2 minWd_220 green margin_0auto" disabled>{'Email Verification Sent'}</button>;
            case 2:
                return <button type="button" className="btn-status btn-status2 blue margin_0auto" disabled>{'Email Verification confirmed'}</button>;
            case 3:
                return <button type="button" className="btn-status btn-status2 minWd_220 green margin_0auto" disabled>{'Active'}</button>;
            case 4:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Inactive'}</button>;
            case 5:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'Dorment account'}</button>;
            case 6:
                return <button type="button" className="btn-status btn-status2 minWd_220 red margin_0auto" disabled>{'Deleted account'}</button>;
            case 7:
                return <button type="button" className="btn-status btn-status2 minWd_220 orange margin_0auto" disabled>{'On hold'}</button>;
            case 8:
                return <button type="button" className="btn-status btn-status2 orange minWd_220 margin_0auto" disabled>{'Req for delete Profile'}</button>;
            default:
                return <button type="button" className="btn-status btn-status2 minWd_220 margin_0auto" disabled>--</button>;
        }

    }

    getAllApps = async () => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getAllApplication', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    appOptions: [],
                    isLoader: false
                })
            }
            const selectedApplist = jwtDecode(res.data)
            selectedApplist.unshift({ id: 0, name: "All Applications" });

            this.setState({
                appOptions: selectedApplist.map(info => ({ value: info.id, label: info.name })),
                isLoader: false,
            });

        }
        catch (err) {
            console.error(err.message)
        }
    }

    getAllCountry = async () => {
        try {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getCountries', 'GET', null, headers);
            const res = response.data;
            if (!res.status) {
                this.setState({
                    options: [],
                    isLoader: false
                })
            }
            const selectedConList = jwtDecode(res.data)

            const sortList = selectedConList.sort((a, b) => {
                if (a.countryName > b.countryName) return 1
                if (a.countryName < b.countryName) return -1
                return 0;
            });
            sortList.unshift({ id: 0, countryName: "All Countries" });
            this.setState({
                options: sortList.map(info => ({ value: info.id, label: info.countryName })),
                isLoader: false,
                // selectedOption: { value: sortList[0].id, label: sortList[0].countryName }
            })
        }
        catch (err) {
            console.error(err.message)
        }
    }
    submitFilter = (e) => {
        e.preventDefault();
        this.closeModal();
        const { page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search } = this.state;
        this.getUserList(page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search);

    }
    showModal = () => {
        this.setState({
            isFilterShow: true,
        })
    }
    closeModal = () => {
        this.setState({
            isFilterShow: false,
        })
    }
    testerHandler = (e) => {
        let testerStatus = this.state.isTester
        if (testerStatus) {
            testerStatus = false
        }
        else {
            testerStatus = true
        }
        this.setState({
            isTester: testerStatus,
        });
        const { page, sizePerPage, selectedFilter, selectedOption, days, selectedUser, search } = this.state;
        this.getUserList(page, sizePerPage, testerStatus, selectedFilter, selectedOption, days, selectedUser, search);

    }
    inputHandler = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handleUserChange = selectedUser => {
        this.setState({ selectedUser });
        const { page, sizePerPage, isTester, selectedFilter, selectedOption, days, search } = this.state;
        this.getUserList(page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search);
    };

    handleAppChange = selectedFilter => {
        this.setState({ selectedFilter });
    };
    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });

            const { isTester, selectedFilter, selectedOption, days, selectedUser, search } = this.state;
            this.getUserList(page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, search);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            const { isTester, selectedFilter, selectedOption, days, selectedUser } = this.state;
            this.getUserList(page, sizePerPage, isTester, selectedFilter, selectedOption, days, selectedUser, searchText);
        }
    }

    render() {

        const { selectedOption, selectedFilter, appOptions, options, selectedUser, days, isFilterShow } = this.state
        const { modalOpen, } = this.props;

        const modalDa = {
            profileId: this.state.profileId,
            userName: this.state.userName1,
            search: this.state.search,
            isTester: this.state.isTester,
            selectedFilter: this.state.selectedFilter,
            selectedOption: this.state.selectedOption,
            days: this.state.days,
            selectedUser: this.state.selectedUser,
            sendSingleNotify: this.sendNoti,
            sendAllNotify: this.sendAllNoti,
            handleClose: this.closeModal,
            issLoader: this.state.issLoader,
        }

        const columns = [
            { dataField: 'username', text: 'Users', sort: true, headerFormatter: this.headerSpan, formatter: this.userField },
            { dataField: 'signup_date', text: 'Date', sort: true, headerFormatter: this.headerSpan, formatter: this.dateField },
            { dataField: 'contact_no', text: 'Contact No.', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'email', text: 'Email', sort: true, headerFormatter: this.headerSpan, formatter: this.leftField },
            { dataField: 'total_jobs', text: 'No of jobs', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'profile_status', text: 'Status', sort: false, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: 'Push Notification', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.clientList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '250', value: 250 },
                { text: '500', value: 500 }
            ]
        };
        const userOptions = [
            { value: 0, label: 'All User' },
            { value: 1, label: 'Old User' },
            { value: 2, label: 'New User' }
        ];

        return (
            <div className="tab_container push-offer-pg">
                <div className="push_noti_wrap">
                    <p><button type="button" onClick={() => modalOpen(true)}>New Notification</button></p>
                </div>
                <div className="tester_view tester-record-user">
                    <p>Tester Record</p>
                    <button type="button" className={this.state.isTester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                        <span className="label_text"></span>
                    </button>
                </div>
                <div className="filter_btnGroup flex-filter">
                    <Select value={selectedUser} onChange={this.handleUserChange} options={userOptions} placeholder="User Type" />
                    <button type="button" className="btn_filterM" onClick={this.showModal}></button>
                    <Link to="/manage-notification" className="manage-noti btn btn-primary">Manage Notification</Link>
                </div>
                <DataTable TableData={tableData} />
                <Modal show={isFilterShow} onHide={this.closeModal} className="modal_design" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <div className="modal_view">
                            <div className="modal_header">
                                <h4 className="model_title">Filter <button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                            </div>
                            <div className="body_modals body_modals2 notify-filter">
                                <form method="POST" action="#" onSubmit={this.submitFilter}>
                                    <div className="form_body2">
                                        <div className="wrap_filterB">
                                            <label className="label_form">Application</label>
                                            <Select isMulti={true} value={selectedFilter} placeholder="Select Applicaion" onChange={this.handleAppChange} options={appOptions} />
                                            {/* // options={appOptions} */}
                                        </div>
                                        <div className="wrap_filterB">
                                            <label className="label_form">Country</label>
                                            <Select isMulti={true} value={selectedOption} placeholder="Select Country" onChange={this.handleChange} options={options} />
                                        </div>
                                        <div className="wrap_filterB">
                                            <label className="label_form">Last App Engagement</label>
                                            <input type="number" name="days" placeholder="Enter Days" value={days} onChange={this.inputHandler} autoComplete="off" className="form_control3" />
                                        </div>
                                    </div>
                                    <div className="bg_submit2 mr-top">
                                        <button type="submit" className="btn-submit">Apply</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <NotificationModal modalData={modalDa} />

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    modalOpen: (val) => dispatch({ type: ACTION_TYPES.TOGGLE_NOTIFICATION_MODAL, payload: val })
})

export default withRouter(connect(null, mapDispatchToProps)(UsersLists))