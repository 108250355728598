import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../redux/actions/types';
import DataTable from '../JqueryDataTable';
import CouponModal from './CouponModal';
import DeleteModal from './DeleteModal';
import { getCoupList } from '../../redux/actions/offersActions';
// import overlayFactory from 'react-bootstrap-table2-overlay';
import moment from 'moment';
import NoDataIndication from '../TableLoader';
import { ToastContainer } from 'react-toastify';

class OffersLists extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            couponList: [],
            isDeleModal: false,
            editCoupData: null,
            deleteId: null,
            messageApi: '',
            page: 1,
            totalRecord: 0,
            search: '',
        }
    }

    componentDidMount() {

        this._ismounted = true;

        this.setState({
            isLoader: true
        });
        this.getAllCoupon();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getAllCoupon = async () => {
        this.setState({
            isLoader: true
        });
        if (this._ismounted) {
            try {
                const res = await getCoupList(this.state.page, this.state.search);
                // console.log(res);
                if (res.data.status === true) {
                    const data = res.data.data;
                    this.props.addCouponList(data.coupon_data);
                    this.setState({
                        isLoader: false,
                        totalRecord: data.count,
                        messageApi: ''
                    })
                }
            } catch (err) {
                this.setState({
                    isLoader: false,
                    totalRecord: 0,
                    messageApi: 'Network Error'
                })
            }
        }
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        console.log(page);
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            }, () => {
                getCoupList(page, searchText);
                this.getAllCoupon();
            });
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            }, () => {
                getCoupList(page, searchText);
                this.getAllCoupon();
            });
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'type':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'discount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'coupon_for':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'num_of_users':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'start_date':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'end_date':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    clickableField = (cell, row) => {
        return <button className="simple-link" onClick={() => this.viewCouponCode(row)}>{cell}</button>;
    }
    typeField = (cell, row) => {
        if (cell === 1) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Percentage"}</button>
        } else {
            return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Fixed"}</button>
        }
    }

    forField = (cell, row) => {
        switch (cell) {
            case 1:
                return <button type="button" className={"btn-status bronze margin_0auto"} disabled>{"Bronze"}</button>
            case 2:
                return <button type="button" className={"btn-status silver margin_0auto"} disabled>{"Silver"}</button>
            case 3:
                return <button type="button" className={"btn-status gold margin_0auto"} disabled>{"Gold"}</button>
            case 4:
                return <button type="button" className={"btn-status vip margin_0auto"} disabled>{"VIP"}</button>
            case 5:
                return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"New User"}</button>
            case 6:
                return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Old User"}</button>
            case 7:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"Both User"}</button>
            case 8:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"AI User"}</button>
            default:
                return <button type="button" className={"btn-status gray margin_0auto"} disabled>--</button>
        }
    }

    limitCoupon = (cell, row) => {
        if (row.max_usage_per_user !== null) {
            return <span className="text-center margin_0auto">{cell}/{row.max_usage_per_user}</span>;
        } else {
            return <span className="text-center margin_0auto">{cell}/1</span>;
        }
    }

    discountField = (cell, row) => {
        if (row.type === 1) {
            return <span className="text-center margin_0auto">{cell}%</span>;
        } else {
            return <span className="text-center margin_0auto">${cell}</span>;
        }
    }

    dateFormatter = (cell) => {
        //return <span className="text-center margin_0auto">{cell}</span>;
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY')}</span>;
    }

    statusField = (cell) => {
        if (cell === 1) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Active"}</button>
        } else {
            return <button type="button" className={"btn-status red btn_red margin_0auto"} disabled>{"Inactive"}</button>
        }
    }

    actionField = (cell, row) => {
        return <div className="action_group text-center">
            <button className="btn-view-icon" onClick={() => this.viewCouponCode(row)}></button>
            <button type="button" className="btn_edit2" onClick={() => this.openEModal(row)}></button>
            <button type="button" className="btn_delete" onClick={() => this.openDeleModal(row.id)}></button>
        </div>
    }
    viewCouponCode = (row) => {
        const { history } = this.props;
        history.push(`/offers/coupon-detail?id=${row.id}`);
    }
    openAModal = () => {
        const { ToggleModal } = this.props;
        ToggleModal(true, null);
    }

    openEModal = (data) => {
        const { ToggleModal } = this.props;
        setTimeout(() => ToggleModal(false, data), 2000);
    }

    handleClose = () => {
        this.setState({
            isModal: false
        })
    }

    openDeleModal = (id) => {
        this.setState({
            isDeleModal: true,
            deleteId: id
        })
    }

    cancelDelete = () => {
        this.setState({
            isDeleModal: false
        })
    }

    usedCoupon = () => {
        const { history } = this.props;
        history.push('/offers/usedcoupon');
    }

    reportCoupon = () => {
        const { history } = this.props;
        history.push('/offers/reportcoupon');
    }

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'coupon_code', text: 'Coupon Code', sort: true, headerFormatter: this.headerSpan, formatter: this.clickableField },
            { dataField: 'type', text: 'Coupon Type', sort: true, headerFormatter: this.headerSpan, formatter: this.typeField },
            { dataField: 'coupon_for', text: 'Coupon For', sort: true, headerFormatter: this.headerSpan, formatter: this.forField },
            { dataField: 'num_of_users', text: 'Coupon Limit', sort: true, headerFormatter: this.headerSpan, formatter: this.limitCoupon },
            { dataField: 'discount', text: 'Discount', sort: true, headerFormatter: this.headerSpan, formatter: this.discountField },
            { dataField: 'start_date', text: 'Start Date', sort: true, headerFormatter: this.headerSpan, formatter: this.dateFormatter },
            { dataField: 'end_date', text: 'End Date', sort: true, headerFormatter: this.headerSpan, formatter: this.dateFormatter },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // for: new user : 0, old user : 1, both : 2, silver : 3, gold : 4, vip : 5;
        // coupen type: percentage: 0, fixed:1;
        // status: active:1, inactive:0;
        const { messageApi } = this.state;
        const tableDatas = {
            columns: columns,
            data: this.props.couponList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: messageApi !== '' ? messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            // overlay:overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(255, 0, 0, 0.5)'}) } })
        };
        // console.log(this.state.couponList);

        const { isDeleModal, deleteId } = this.state;

        return (
            <section className="bg-balance">

                <div className="balance_wrap">
                    <div className="btn_groupsB">
                        <button type="button" className="btn_addUsers" onClick={() => this.openAModal()}>Add Coupon</button>
                        <button type="button" className="btn_coupon" onClick={this.usedCoupon}>Used Coupon</button>
                        <button type="button" className="btn_coupon" onClick={this.reportCoupon}>Coupon Report</button>
                    </div>
                    <DataTable TableData={tableDatas} />
                </div>
                <CouponModal />
                <DeleteModal isModal={isDeleModal} id={deleteId} handleClose={this.cancelDelete} />
                <ToastContainer />
            </section>
        )
    }
}

const mapStateToProps = state => ({
    couponList: state.offers.couponList
})

const mapDispatchToProps = dispatch => ({
    ToggleModal: (isadd, da) => dispatch({ type: ACTION_TYPES.OPEN_COUPON_MODAL, payload: { isAdd: isadd, data: da } }),
    addCouponList: (data) => dispatch({ type: ACTION_TYPES.GET_COUPON_LIST, payload: data })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OffersLists));
