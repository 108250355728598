import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../../assets/images/user-loader.gif';

class ConfirmModal extends Component {

    render() {

        const { isLoader, isModal, handleClose, submitFunc, title, text } = this.props.data;

        return (
            <Modal show={isModal} onHide={() => handleClose()} className={`modal_design ${this.props.customClass ? this.props.customClass : ""}`} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        {/* <div className="modal_header">
                            <h4 className="model_title">{isAdd?"Add":"Edit"} Coupon details <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div> */}
                        <div className="body_modals body_modals2">
                            <form method="POST" action="/">
                                <div className="form_body2">
                                    <p className="delete_text">{title}<span>{text}</span></p>
                                </div>
                                <div className="bg_submit2 mr-top">
                                    <button type="button" onClick={(e) => submitFunc(e)} className="btn-submit" disabled={isLoader} >{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Yes"}</button>
                                    <p>Or <button type="button" className="btn_cancel2" onClick={() => handleClose()}>No</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(ConfirmModal));
