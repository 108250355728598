import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import UsersLists from './UsersLists';

import './index.css';

class PushOffers extends Component {
    render() {

        const data = [
            { id:'1', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
            { id:'2', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'3', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:1 },
            { id:'4', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
            { id:'5', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'6', username:'john Doe', star:0, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'7', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
            { id:'8', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
            { id:'9', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
            { id:'10', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'11', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'12', username:'john Doe', star:1, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:1 },
            { id:'13', username:'john Doe', star:2, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'14', username:'john Doe', star:3, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:0, amount:'+$21.30', flag:0 },
            { id:'15', username:'john Doe', star:4, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 },
            { id:'16', username:'john Doe', star:5, time:'Oct 10, 2020', job:5, chat:5, kw:5, report:5, status:1, amount:'+$21.30', flag:0 }
        ];

        return (
            <section className="bg_userDetails">
                <div className="container-fluid">
                    <div className="balance_wrap flex_wrap" style={{marginBottom:'140px'}}>
                    {/* <Tab.Container defaultActiveKey="web1" className="tab_nav2" id="userDViews">
                            <div className="leftpart_1">
                                <Nav variant="pills" className="tab_nav2 tab_apps">
                                    <Nav.Item>
                                        <Nav.Link eventKey="web1">
                                            <div className="view_apps">
                                                <div className="logo_apps">
                                                    <img src={'https://redlinecoin.com/Favicon.ico'} alt="RedLine Coin" />
                                                </div>
                                                <div className="text_apps">
                                                    <h6>RedLine Coin</h6>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="web2">
                                            <div className="view_apps">
                                                <div className="logo_apps">
                                                    <img src={'https://www.cheapestessay.com/favicon.ico'} alt="cheapest essay" />
                                                </div>
                                                <div className="text_apps">
                                                    <h6>Cheapest Essay</h6>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="web3">
                                            <div className="view_apps">
                                                <div className="logo_apps">
                                                    <img src={'https://24task.com/assets/icon/favicon.ico'} alt="24Task" />
                                                </div>
                                                <div className="text_apps">
                                                    <h6>24Task</h6>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="tabs_content rightpart_2">
                                <Tab.Content>
                                    <Tab.Pane eventKey="web1">
                                        <UsersLists data={data} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="web2">
                                        <UsersLists data={data} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="web3">
                                        <UsersLists data={data} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container> */}
                        <UsersLists data={data} />
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(PushOffers));
