import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import DataTable from '../../components/JqueryDataTable';
import NoDataIndication from '../../components/TableLoader';
import './index.css'
import { connect } from 'react-redux';

class SocialSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editData: null,
            pageNo: 1,
            selectPartnerAs: 0,
            recordCount: 0,
            listData: [],
            isLoader: false,
            isSelectLoader: false,
            isMainLoader: false,
            messageApi: '',
            page: 1,
            search: '',
            selectedOption: [],
            isClearable: null
        }
    }

    componentDidMount() {
        // const { pageNo, selectPartnerAs, search } = this.state
        //  this.getSocialSurvey(pageNo, search, selectPartnerAs);
    }

    getSocialSurvey = async () => {
        try {
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('profile_id', 181)
            // formData.append('page_no', pageNo )
            // formData.append('search_by', search)     

            const response = await apiHalper('getSocialSurvey', 'POST', formData, headers);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);

                this.setState({
                    isLoader: false,
                    listData: decode.data,
                    recordCount: decode.count,
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    listData: [],
                    recordCount: 0,
                    messageApi: res.message
                });
            }

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }

    }

    centerField = (cell) => {        
        return <div className="text-center">{cell}</div>
    }

    // actionField = (cell, row) =>{
    //     const { addEditData } = this.props;
    //     return <Link to={`/partner-detail?id=${row.id}`} className="btn-view margin_0auto" onClick={()=>addEditData(row)}>View</Link>;
    // }

    headerSpan = (column) => {
        switch (column.dataField) {
            case 'id':
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
            case 'survey_status':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            case 'note':
                return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            // case 'action':
            //     return <span className={column.sort ? "header_span sort text-center" : "header_span text-center"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }
    userField = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.photo}`
        return <div className="tb_user_wrap">
            <div className="useimg_block online_icon">
                <img src={row.photo !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        const { selectPartnerAs } = this.state
        if (type === 'pagination') {
            this.setState({
                pageNo: page,
                sizePerPage,
            });
            this.getSocialSurvey(page, searchText, selectPartnerAs);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getSocialSurvey(page, searchText, selectPartnerAs);
        }
    }

    render() {
        const { listData, isLoader, pageNo, messageApi } = this.state;

        const columns = [
            { dataField: 'id', text: 'Id', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'survey_status', text: 'Survey Status', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'note', text: 'Note', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            // { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: listData,
            keyId: 'username',
            loading: isLoader,
            message: messageApi !== '' ? messageApi : 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: pageNo,
            totalRecord: this.state.recordCount,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange
        };
        return (
            <section className="bg-balance">
                <div className="container-fluid">
                    <div className="balance_wrap partner-list">
                        <DataTable TableData={tableData} />
                    </div>
                </div>
            </section>
        )
    }
}
export default withRouter(connect(null)(SocialSurvey));