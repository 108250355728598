import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import * as NUM_Helper from '../../helpers/NumberFormate';
import DataTable from '../JqueryDataTable';
import { apiHalper } from '../../helpers/ApiHelper';
import NoDataIndication from '../TableLoader';
import * as ACTIVE_TYPES from '../../redux/actions/types';
import moment from 'moment';
import './index.css';

class UsedCoupon extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            usedCoupList: [],
            isLoader: false,
            page: 1,
            totalRecord: 0,
            search: '',
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.getAllUsedCoupn();
    }

    getAllUsedCoupn = async () => {

        if (this._ismounted) {
            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': localStorage.getItem('token'),
                //'Access-Control-Allow-Origin': '*'
            }

            let formData = new FormData();
            formData.append('limit', 10);
            formData.append('page_no', this.state.page);
            formData.append('search_by', this.state.search);

            const res = await apiHalper('getUsedCoupon', 'POST', formData, headers);
            // console.log(res);
            if (res.data.status === true) {
                const data = res.data.data;
                // console.log(data);
                this.setState({
                    isLoader: false,
                    usedCoupList: data.coupon_data,
                    totalRecord: data.count
                })
            }
        }
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'discount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'coupon_for':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'discount_amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'job_id':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'order_date':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'fixed_price':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'num_of_users':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }

    orderNo = (cell) => {
        return <span className="text-center margin_0auto">{cell}</span>;
    }

    orderDate = (cell) => {
        return <span className="text-center margin_0auto">{cell ? moment(cell).format('MMM DD, YYYY') : "-"}</span>;
    }

    typeField = (cell, row) => {
        if (cell === 0) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Percentage"}</button>
        } else {
            return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Fixed"}</button>
        }
    }

    forField = (cell, row) => {
        switch (cell) {
            case 1:
                return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Bronze"}</button>
            case 2:
                return <button type="button" className={"btn-status silver margin_0auto"} disabled>{"Silver"}</button>
            case 3:
                return <button type="button" className={"btn-status gold margin_0auto"} disabled>{"Gold"}</button>
            case 4:
                return <button type="button" className={"btn-status vip margin_0auto"} disabled>{"VIP"}</button>
            case 5:
                return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"New User"}</button>
            case 6:
                return <button type="button" className={"btn-status orange margin_0auto"} disabled>{"Old User"}</button>
            case 7:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"Both User"}</button>
            case 8:
                return <button type="button" className={"btn-status green margin_0auto"} disabled>{"AI User"}</button>
            default:
                return <button type="button" className={"btn-status gray margin_0auto"} disabled>--</button>
        }
    }

    limitCoupon = (cell, row) => {
        if (row.max_usage_per_user !== null) {
            return <span className="text-center margin_0auto">{cell}/{row.max_usage_per_user}</span>;
        } else {
            return <span className="text-center margin_0auto">{cell}/1</span>;
        }
    }

    discountField = (cell, row) => {
        if (row.type === 1) {
            return <span className="text-center margin_0auto">{cell + "(%)"}</span>;
        } else {
            return <span className="text-center margin_0auto">{cell + "($)"}</span>;
        }
    }

    amountField = (cell) => {
        return <span className="text-center margin_0auto">{cell ? "$" + cell : "-"}</span>;
    }

    oValueField = (cell) => {
        return <span className="text-center margin_0auto">{cell ? "$" + cell : "-"}</span>;
    }
    actionField = (cell, row) => {
        return <Link to={`/jobsDetails/${row.job_id}?type=offers`} className={row.job_id ? "btn-view margin_0auto" : "btn-view margin_0auto disable-link"} onClick={() => this.viewDetail(row)}>View</Link>;
    }

    viewDetail = (data) => {
        const { detailView } = this.props
        detailView(data)
    }

    statusField = (cell) => {
        if (cell === 1) {
            return <button type="button" className={"btn-status blue margin_0auto"} disabled>{"Active"}</button>
        } else {
            return <button type="button" className={"btn-status red margin_0auto"} disabled>{"Inactive"}</button>
        }
    }

    backHandler = () => {
        const { history } = this.props;
        history.push('/offers');
    }
    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        console.log(page);
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))
        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            }, () => {
                this.getAllUsedCoupn();
            });
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            }, () => {
                this.getAllUsedCoupn();
            });
        }
    }

    render() {

        const columns = [
            // { dataField: 'id', text: '#', sort: false, headerFormatter: this.headerSpan }, 
            { dataField: 'coupon_code', text: 'Coupon Code', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan },
            { dataField: 'job_id', text: 'Order No.', sort: true, headerFormatter: this.headerSpan, formatter: this.orderNo },
            { dataField: 'order_date', text: 'Order Date', sort: true, headerFormatter: this.headerSpan, formatter: this.orderDate },
            { dataField: 'coupon_for', text: 'Coupon For', sort: true, headerFormatter: this.headerSpan, formatter: this.forField },
            { dataField: 'num_of_users', text: 'Coupon Limit', sort: true, headerFormatter: this.headerSpan, formatter: this.limitCoupon },
            { dataField: 'discount', text: 'Discount', sort: true, headerFormatter: this.headerSpan, formatter: this.discountField },
            { dataField: 'discount_amount', text: 'Discount Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
            { dataField: 'fixed_price', text: 'Order Value', sort: true, headerFormatter: this.headerSpan, formatter: this.oValueField },
            { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
            { dataField: 'action', text: "Action", sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        // for: new user : 0, old user : 1, both : 2, silver : 3, gold : 4, vip : 5;
        // coupen type: percentage: 0, fixed:1;

        const tableDatas = {
            columns: columns,
            data: this.state.usedCoupList,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
        };

        return (
            <section className="bg-balance">
                {/* <div className="container-fluid"> */}
                <div className="balance_wrap">
                    <div className="btn_groupsB">
                        <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                        <p className="subpage_title">Used Coupon</p>
                    </div>
                    <DataTable TableData={tableDatas} />
                </div>
                {/* </div> */}
            </section>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(UsedCoupon));
