import React, { Component, Fragment } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import DataTable from '../JqueryDataTable';
import LoaderImg from '../../assets/images/user-loader.gif';
import Modal from 'react-bootstrap/Modal';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import * as ACTIVE_TYPES from '../../redux/actions/types';
import moment from 'moment';
import { jwtDecode } from '../../helpers/jwt_helper';
import ConfirmModal from '../SEO/ConfirmModal';
import { toster_sucess, toster_error } from '../../helpers/toster_helper';

class Income extends Component {

    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            selectedRefundType: { value: 1, label: 'Full Refund' },
            // selectedRefundAmt: { value: 0, label: '$' },           
            isClearable: true,
            isLoader: false,
            incomeData: [],
            jobId: null,
            gigID: null,
            projectAmt: null,
            depositCharge: null,
            paybleAmount: null,
            inputAmount: null,
            inputAmountErr: '',
            transactionId: '',
            transactIdErr: '',
            refundNote: '',
            startDate: '',
            endDate: '',
            page: 1,
            search: '',
            aBalance: null,
            pBalance: null,
            rBalance: null,
            cBalance: null,
            tBalance: null,
            totalRecord: 0,
            sizePerPage: 10,
            isShowModal: false,
            project_type: 1,  //  1 = gig | 2 = job
            isJobActive: true,
        }
    }

    componentDidMount() {
        this._ismounted = true;
        const { page, search, selectedOption, startDate, endDate, sizePerPage } = this.state;
        this.getIncomeApi(page, sizePerPage, search, selectedOption, startDate, endDate, this.state.project_type);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }
    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    applyDates = () => {
        this.getIncomeApi(this.state.page, this.state.sizePerPage, this.state.search, this.state.selectedOption, this.state.startDate, this.state.endDate, this.state.project_type);
    }

    getIncomeApi = async (page, sizePerPage, search, selected, startDate, endDate, project_type) => {

        if (this._ismounted) {
            this.setState({
                isLoader: true,
                incomeData: []
            });

            // const { selectedOption } = this.state;

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('sort_by', selected !== null ? selected.value : '')
            formData.append('page_no', page)
            formData.append('search_by', search)
            formData.append('limit', sizePerPage)
            if (startDate === '' || startDate === null) {
                formData.append('start_date', '')
            }
            else {
                formData.append('start_date', moment(startDate).format('YYYY-MM-DD'))
            }
            if (endDate === '' || endDate === null) {
                formData.append('end_date', '')
            }
            else {
                formData.append('end_date', moment(endDate).format('YYYY-MM-DD'))
            }
            formData.append('project_type', project_type)
            formData.append('influencer_bird', 1)
            const response = await apiHalper('getIncome', 'POST', formData, headers);
            if (response.data.status === true) {
                const data = jwtDecode(response.data.data);
                console.log(data);
                if (response.data.data) {
                    const total_balance = (data.total[0].done_amount + data.total[0].pending_amount) - data.total[0].refunded_amount;
                    this.setState({
                        isLoader: false,
                        incomeData: data.income,
                        aBalance: data.total[0].done_amount,
                        pBalance: data.total[0].pending_amount,
                        rBalance: data.total[0].refunded_amount,
                        cBalance: data.total[0].cancel_amount,
                        tBalance: total_balance.toFixed(2),
                        totalRecord: data.record_count,
                    });
                    // if (startDate !== '' || startDate !== null) {
                    //     this.setState({page: 1});
                    // }
                }
                else {
                    this.setState({
                        isLoader: false,
                        incomeData: [],
                        totalRecord: 0
                    });
                }
            } else {
                this.setState({
                    isLoader: false,
                    incomeData: [],
                    aBalance: 0,
                    pBalance: 0,
                    tBalance: 0
                });
            }
        }
    }

    handleChange = selectedOption => {
        console.log(selectedOption);
        this.setState({ selectedOption });
        this.getIncomeApi(this.state.page, this.state.sizePerPage, this.state.search, selectedOption, this.state.startDate, this.state.endDate, this.state.project_type);
    };

    handleTypeChange = selectedRefundType => {
        this.setState({ selectedRefundType });
        const { projectAmt } = this.state;

        if (selectedRefundType.value === 2) {
            this.setState({
                paybleAmount: '',
                inputAmount: '',
                inputAmountErr: '',
                transactIdErr: ''
            });
        }
        else {
            this.setState({
                paybleAmount: projectAmt,
                inputAmount: projectAmt,
            });
        }
    };

    // handleAmtChange = selectedRefundAmt => {
    //     this.setState({ selectedRefundAmt });
    //     const { projectAmt, inputAmount } = this.state;

    //     if (selectedRefundAmt.value === 1) {
    //         if (inputAmount <= 100) {
    //             var amt = 0;
    //             amt = (projectAmt * inputAmount) / 100;
    //             this.setState({
    //                 paybleAmount: amt.toFixed(2),
    //                 inputAmount: inputAmount,
    //                 inputAmountErr: ''
    //             });
    //         }
    //         else {
    //             this.setState({
    //                 inputAmountErr: 'Not allowed to enter more than 100%'
    //             });
    //         }
    //     }
    //     else {
    //         this.setState({
    //             paybleAmount: projectAmt,
    //             inputAmount: projectAmt,
    //         });
    //     }
    // };

    inputHandler = (e) => {
        const { name, value } = e.target;
        const { projectAmt } = this.state;

        var inputErr = '';
        const isnum = /^[0-9]*$/.test(value);

        if (isnum) {
            inputErr = ''
        }
        else {
            inputErr = "Percentage must in number"
        }

        if (e.target.value.length <= 0) {
            inputErr = 'Please Enter Some Percentage'
        }

        // if (e.target.value <= projectAmt) {
        //     this.setState({
        //         [name]: value,
        //         inputAmountErr: ''
        //     });
        // }
        // else {
        //     this.setState({
        //         inputAmountErr: 'Not allowed to enter larger amount than project amount'
        //     });
        // }

        // if (selectedRefundAmt.value === 1) {
        if (e.target.value > 100) {
            inputErr = 'Not allowed to enter more than 100%'
        }
        else {
            var amt = 0;
            amt = (projectAmt * e.target.value) / 100;
            this.setState({
                paybleAmount: amt.toFixed(2),
                [name]: value,
            });
        }
        this.setState({
            inputAmountErr: inputErr
        })
        // }
        // else {
        //     if (e.target.value <= projectAmt) {
        //         this.setState({
        //             paybleAmount: e.target.value
        //         });
        //     }
        // }
    }

    transactionIdHandler = e => {
        var TIDErr = ''
        if (e.target.value.length <= 0) {
            TIDErr = 'Transaction ID is required'
        }
        else {
            TIDErr = ''
        }
        this.setState({
            transactionId: e.target.value,
            transactIdErr: TIDErr
        });
    }

    noteHandler = e => {
        this.setState({
            refundNote: e.target.value
        });
    }

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable }));
        this.getIncomeApi(this.state.page, this.state.sizePerPage, this.state.search, this.state.selectedOption, this.state.startDate, this.state.endDate, this.state.project_type);
    }

    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'status':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'transactionid':
                return <span className={column.sort ? "header_span sort custom-width imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'refundable_amt':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'refund':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'timestamp':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
        // return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
    }

    statusField = (cell) => {

        switch (cell) {
            case 0:
                return <button type="button" className="btn-status blue margin_0auto" disabled>Pending</button>;
            case 1:
                return <button type="button" className="btn-status green margin_0auto" disabled>Paid</button>;
            case 2:
                return <button type="button" className="btn-status red margin_0auto" disabled>Cancel</button>;
            case 3:
                return <button type="button" className="btn-status orange margin_0auto" disabled>Refunded</button>;
            default:
                return <button type="button" className="btn-status margin_0auto" disabled>--</button>;
        }

    }

    dateField = (cell, row) => {
        return <span className="text-center margin_0auto">{moment(cell).format('MMM DD, YYYY hh:mm:ss')}</span>;
    }

    actionField = (cell, row) => {
        return <Link to={`/jobsDetails/${row.job_post_id}?type=balance`} className="btn-view margin_0auto" onClick={() => this.viewDetail(row)}>View</Link>;
    }
    actionGigField = (cell, row) => {
        return <Link to={`/gigDetail/${row.gigContractID}?type=balance_agents`} className="btn-view margin_0auto" onClick={() => this.viewDetail(row)}>View</Link>
    }
    viewDetail = (data) => {
        const { detailView } = this.props
        detailView(data);
    }

    amountField = (cell, row) => {
        return <span className="text-center margin_0auto">{`$${cell}`}</span>;
    }

    refundField = (cell, row) => {
        return row.isRefundable === 1 ? <button type="button" className="btn-status orange margin_0auto refund" onClick={() => this.openModal(row)}>Refund</button> : <span className="d-block text-center">---</span>;
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data }) => {
        let result;
        if (sortOrder === 'asc') {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            result = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState(() => ({
            data: result
        }))

        const { selectedOption } = this.state;
        if (type === 'pagination') {

            this.setState({
                page,
                sizePerPage
            });
            this.getIncomeApi(page, sizePerPage, this.state.search, selectedOption, this.state.startDate, this.state.endDate, this.state.project_type);
        }

        if (type === 'search') {
            this.setState({
                search: searchText
            });
            this.getIncomeApi(this.state.page, this.state.sizePerPage, searchText, selectedOption, this.state.startDate, this.state.endDate, this.state.project_type);
        }
    }

    isTester = (row) => {
        let classs = null;
        if (row.is_tester === 1) {
            classs = 'tester_tr';
        } else {
            classs = null;
        }

        return classs;
    }

    jobId = (cell, row) => {
        // :{row.title}
        return <span className="job-title" title={`Paid for job ID ${row.job_post_id}`}>Paid for job ID {row.job_post_id} </span>;
    }
    gigId = (cell, row) => {
        // :{row.title}
        return <span className="job-title" title={`Paid for job ID ${row.gigContractID}`}>Paid for gig ID {row.gigContractID} </span>;
    }
    agentName = (cell) => {
        return cell;
    }
    openConfirmModal = () => {
        this.setState({
            isConFModal: true
        })
    }
    refundSubmit = async () => {
        if (this._ismounted) {
            const { inputAmount, paybleAmount, transactionId, selectedRefundType, projectAmt } = this.state;

            if (inputAmount.length <= 0) {
                this.setState({
                    inputAmountErr: 'Please Enter Some Percentage'
                });
            }
            if (transactionId.length <= 0) {
                this.setState({
                    transactIdErr: 'Transaction ID is required'
                });
            }
            else {
                if (parseFloat(projectAmt) === parseFloat(paybleAmount) && selectedRefundType.value === 2) {
                    this.setState({
                        modalTitle: 'Refund Notification',
                        modalText: 'Are you sure? This should be full refund, because you entered 100% amount.'
                    }, () => { this.openConfirmModal(); });
                }
                else {
                    this.apiCall();
                }
            }
        }
    }

    apiCall = async () => {
        const { jobId, gigID, paybleAmount, inputAmount, refundNote, transactionId, selectedRefundType, projectAmt, depositCharge, isJobActive } = this.state;
        try {
            this.setState({
                isLoader: true,
                transactIdErr: ''
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            let formData = new FormData();
            if(isJobActive){
                formData.append('jobID', jobId);
            }else{
                formData.append('gigContractID', gigID);
            }
            formData.append('refundAmount', (parseFloat(projectAmt) === parseFloat(paybleAmount) || selectedRefundType.value === 1) ? (projectAmt + depositCharge) : paybleAmount);
            formData.append('note', refundNote);
            formData.append('transactionID', transactionId);
            formData.append('refundType', parseFloat(projectAmt) === parseFloat(paybleAmount) ? 1 : selectedRefundType.value);
            formData.append('refundedPr', selectedRefundType.value === 2 ? inputAmount : 100);
            let response ;
            if(isJobActive){
                response = await apiHalper('acceptJobRefundRequest', 'POST', formData, headers)
            }else{
                response = await apiHalper('acceptGigrefundRequest', 'POST', formData, headers)
            }
            const res = response.data;
            if (res.status) {
                this.setState({
                    isLoader: false,
                    isConFModal: false,
                    isShowModal: false
                });
                toster_sucess(res.message);
            }
            else {
                this.setState({
                    isLoader: false,
                    isConFModal: false,
                    isShowModal: false
                });
                toster_error(res.message);
            }
            const { page, search, selectedOption, startDate, endDate, sizePerPage, project_type } = this.state;
            this.getIncomeApi(page, sizePerPage, search, selectedOption, startDate, endDate, project_type);

        } catch (err) {
            console.log(err.message);
        }
    }

    openModal = (data) => {
        if(this.state.isJobActive){
            this.setState({
                jobId: data.job_post_id,
            })
        }else{
            this.setState({
                gigID: data.gigContractID,
            })
        }
        
        this.setState({
            isShowModal: true,
            projectAmt: data.refundable_amt,
            depositCharge: data.depositCharges,
            paybleAmount: data.refundable_amt,
            inputAmount: data.refundable_amt,
            selectedRefundType: { value: 1, label: 'Full Refund' },
            transactIdErr: ''
            // selectedRefundAmt: { value: 0, label: '$' },
        })
    }

    closeModal = () => {
        this.setState({
            isShowModal: false,
            inputAmountErr: '',
            transactIdErr: ''
        })
    }

    closeCofiClose = () => {
        this.setState({
            isConFModal: false,
            modalTitle: '',
            modalText: ''
        });
    }

    handleJobGigChange = () => {
        const { isJobActive } = this.state;
        const { page, search, selectedOption, startDate, endDate, sizePerPage } = this.state;
        this.setState({ isJobActive: !isJobActive });
        document.getElementById('tableSearch').value = '';
        if(isJobActive){
            this.setState({
                project_type: 1,
                search: ''
            })
            this.getIncomeApi(page, sizePerPage, '', selectedOption, startDate, endDate, 1);
        }else{
            this.setState({
                project_type: 2,
                search: ''
            })
            this.getIncomeApi(page, sizePerPage, '', selectedOption, startDate, endDate, 2);
        }
    }

    render() {

        const confirmModal = {
            title: this.state.modalTitle,
            text: this.state.modalText,
            handleClose: this.closeCofiClose,
            isModal: this.state.isConFModal,
            submitFunc: this.apiCall
        }
        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Paid' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Refund' }
        ];

        // const refundAmt = [
        //     { value: 0, label: '$' },
        //     { value: 1, label: '%' }
        // ];

        const refundType = [
            { value: 1, label: 'Full Refund' },
            { value: 2, label: 'Partial Refund' }
        ];

        // const customStyles = {
        //     dropdownIndicator: (provided) => ({
        //         ...provided,
        //         display: 'none'
        //     })
        // }
        // Are you sure? This should be full refund, because you entered 100% amount.

        const { isJobActive } = this.state;
        var columns = [];
        {isJobActive ? 
            columns = [
                { dataField: 'job_post_id', text: 'Job Id', sort: true, headerFormatter: this.headerSpan, formatter: this.jobId },
                { dataField: 'transactionid', text: 'Transaction ID', sort: true, headerFormatter: this.headerSpan, formatter: this.agentName },
                { dataField: 'username', text: 'Agent Name', sort: true, headerFormatter: this.headerSpan, formatter: this.agentName },
                { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.dateField },
                { dataField: 'amount', text: 'Bid Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
                { dataField: 'refundable_amt', text: 'Project Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
                { dataField: 'refund', text: 'Refund', sort: true, headerFormatter: this.headerSpan, formatter: this.refundField },
                { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
                { dataField: 'action', text: "Action", sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
            ]
        :
            columns = [
                { dataField: 'gigContractID', text: 'Gig Id', sort: true, headerFormatter: this.headerSpan, formatter: this.gigId },
                { dataField: 'transactionid', text: 'Transaction ID', sort: true, headerFormatter: this.headerSpan, formatter: this.agentName },
                { dataField: 'username', text: 'Agent Name', sort: true, headerFormatter: this.headerSpan, formatter: this.agentName },
                { dataField: 'timestamp', text: 'Time', sort: true, headerFormatter: this.headerSpan, formatter: this.dateField },
                { dataField: 'amount', text: 'Bid Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
                { dataField: 'refundable_amt', text: 'Project Amount', sort: true, headerFormatter: this.headerSpan, formatter: this.amountField },
                { dataField: 'refund', text: 'Refund', sort: true, headerFormatter: this.headerSpan, formatter: this.refundField },
                { dataField: 'status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter: this.statusField },
                { dataField: 'action', text: "Action", sort: false, headerFormatter: this.headerSpan, formatter: this.actionGigField }
            ]
        }
            
        const tableData = {
            columns: columns,
            data: this.state.incomeData,
            keyId: 'id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: 10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '250', value: 250 },
                { text: '500', value: 500 }
            ]
        };

        const { isLoader, transactionId, refundNote, inputAmountErr, transactIdErr, selectedOption, selectedRefundType, isClearable, jobId, gigID, inputAmount, paybleAmount, projectAmt, depositCharge, aBalance, pBalance, rBalance, cBalance, tBalance, startDate, endDate } = this.state;
        const disabled = transactIdErr || inputAmountErr;
        
        return (
            <Fragment>
                {/* <ul className="nesting_links">
                    <li><a href='#' className={isJobActive ? "": "active"} onClick={e => {this.handleJobGigChange(); e.preventDefault()}}>Gigs</a></li>
                    <li><a href='#' className={isJobActive ? "active": ""} onClick={e => {this.handleJobGigChange(); e.preventDefault()}}>Jobs</a></li>
                </ul> */}
            
                <div className="tab_container">
                    <ul className="wiget_wrap wiget_wrap5">
                        <li>
                            <div>
                                <h5>{aBalance} USD</h5>
                                <p>Available Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{pBalance} USD</h5>
                                <p>Pending Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{rBalance} USD</h5>
                                <p>Refunded Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{cBalance} USD</h5>
                                <p>Cancel Balance</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h5>{tBalance} USD</h5>
                                <p>Total Balance</p>
                            </div>
                        </li>
                    </ul>
                    {/* <div className="total_balance">${tBalance}</div> */}
                    <div className="export-data-modal">
                        <DatePicker placeholderText="Select Start Date" selected={startDate} minDate={new Date("2018-01-01")} maxDate={new Date()} onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />
                        <DatePicker placeholderText="Select End Date" selected={endDate} onChange={date => this.setEndDate(date)} minDate={startDate} maxDate={new Date()} endDate={endDate} dateFormat="yyyy-MM-dd" />
                        <button type="button" onClick={this.applyDates} className="btn btn-primary">Apply</button>
                    </div>
                    <div className="select_wrap">
                        <Select value={selectedOption} isClearable={isClearable} placeholder={"Select"} onChange={this.handleChange} options={options} />
                    </div>
                    <DataTable TableData={tableData} />
                    <ConfirmModal data={confirmModal} customClass="refund-confirm" />
                    <Modal show={this.state.isShowModal} onHide={this.closeModal} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <div className="modal_view refund-modal">
                                <div className="modal_header">
                                    <h4 className="model_title">Refund Option<button type="button" onClick={this.closeModal}><span className="close_icon"></span></button></h4>
                                </div>
                                <div className="body_modals body_modals2 noti-detail">
                                    <div className="input-group flex column-gap-15">
                                        {isJobActive ?
                                        <>
                                            <label>JobID : </label>
                                            <span>{jobId ? jobId : '-'}</span>
                                        </>
                                        :
                                        <>
                                            <label>GigID : </label>
                                            <span>{gigID ? gigID : '-'}</span>
                                        </>
                                        }
                                        
                                    </div>
                                    <div className="input-group flex column-gap-15">
                                        <label>Project Amount : </label>
                                        <span>{projectAmt ? `$${projectAmt}` : '0'}</span>
                                    </div>
                                    <div className="input-group flex column-gap-15">
                                        <label>Deposit Charge : </label>
                                        <span>{depositCharge ? depositCharge : '0'}</span>
                                    </div>
                                    <div className="input-group flex column-gap-15">
                                        <label>Refund Type : </label>
                                        <span><Select value={selectedRefundType} placeholder={"Select Type"} onChange={this.handleTypeChange} options={refundType} /></span>
                                    </div>
                                    {selectedRefundType.value === 2 ?
                                        <div className="input-group flex column-gap-15">
                                            <label>Refund Percentage : </label>
                                            <span className="i-group">
                                                <input type="text" name="inputAmount" value={inputAmount} onChange={this.inputHandler} className="black-area" placeholder="Enter Percentage" autoComplete="off" />
                                                {/* <Select styles={customStyles} value={selectedRefundAmt} isSearchable={false} onChange={this.handleAmtChange} options={refundAmt} /> */}
                                                <div className="percentage">%</div>
                                                {inputAmountErr ? <p className="error_msg mt-1">{inputAmountErr}</p> : null}
                                            </span>
                                        </div>
                                        : null
                                    }
                                    <div className="input-group flex column-gap-15">
                                        <label>Payble Amount : </label>
                                        {selectedRefundType.value === 1 || parseFloat(projectAmt) === parseFloat(paybleAmount) ?
                                            <span>{`$${parseFloat(paybleAmount) + depositCharge}`} <em>(Including Deposit Charge)</em></span>
                                            :
                                            <span>{paybleAmount ? `$${paybleAmount}` : '0'}</span>
                                        }
                                    </div>
                                    <div className="input-group flex column-gap-15">
                                        <label>Transaction Id : </label>
                                        <span>
                                            <input type="text" name="transactionId" value={transactionId} onChange={this.transactionIdHandler} className="black-area" placeholder="Transaction Id" autoComplete="off" />
                                            {transactIdErr ? <p className="error_msg">{transactIdErr}</p> : null}
                                        </span>
                                    </div>
                                    <div className="input-group flex column-gap-15">
                                        <label>Note : </label>
                                        <span>
                                            <textarea name="refundNote" value={refundNote} onChange={this.noteHandler} placeholder="Enter Note..."></textarea>
                                        </span>
                                    </div>
                                    <div className="bg_submit2 mr-top">
                                        <button type="button" onClick={() => this.refundSubmit()} className="btn-submit" disabled={disabled || isLoader}>{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit"}</button>
                                        <p>Or <button type="button" className="btn_cancel2" onClick={() => this.closeModal()}>Cancel</button></p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    detailView: (data) => dispatch({ type: ACTIVE_TYPES.JOB_VIEW_DETAIL, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(Income));
