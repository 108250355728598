import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiHalper } from '../../helpers/ApiHelper';
import { toster_error, toster_sucess } from '../../helpers/toster_helper';

class BidCharge extends Component {

    constructor(props){
        super(props);
        this.state={
            percentage:'',
            amount:'',
            formError:{
                percentage:'',
                amount:''
            },
            formValid:{
                percentage:false,
                amount:false,
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { userDetails, type } = this.props;

        console.log({ BID54: userDetails, prevProps })
        if(prevProps.userDetails !== userDetails) {
            console.log({ BID: userDetails })
            this.setState({
                percentage: userDetails?.percent_charge,
                amount: userDetails?.amount_charge,
                formValid:{
                    percentage: userDetails?.percent_charge !== 0 ? true : false,
                    amount: userDetails?.amount_charge !== 0 ? true : false,
                }
            })
        }
    }

    inputHandler = (e) =>{
        const { name, value } = e.target;
        this.setState({
            [name]:value
        },()=>this.checkValidation(name, value));
    }

    checkValidation = (name, value) =>{
        let error = this.state.formError;
        let valid = this.state.formValid;

        const flotReg = new RegExp(/(\d+(\.\d+)?)/);

        switch(name){
            case 'percentage':
                if(value.length > 0){
                    if(flotReg.test(value)){
                        valid.percentage = true;
                        error.percentage = ''
                    }else{
                        valid.percentage = false;
                        error.percentage = 'Enter a valid number!'
                    }
                }else{
                    valid.percentage = false;
                    error.percentage = 'Field is required!'
                }
                break;
            case 'amount':
                if(value.length > 0){
                    if(flotReg.test(value)){
                        valid.amount = true;
                        error.amount = ''
                    }else{
                        valid.amount = false;
                        error.amount = 'Enter a valid number!'
                    }
                }else{
                    valid.amount = false;
                    error.amount = 'Field is required!'
                }
                break;
            default:
                break;
        }

        this.setState({
            formError:error,
            formValid:valid
        });
    }

    updateBidCharge = async (e) =>{
        e.preventDefault();
        const { id } = this.props.match.params;
        const headers = {
            'Authorization': localStorage.getItem('token')
        }
        const {percentage, amount} = this.state;
        let formData = new FormData();
        formData.append('profile_id', id)
        formData.append('percent_charge', percentage)
        formData.append('amount_charge', amount)
        const response = await apiHalper('updateUserBidCharge', 'POST', formData, headers);
        const res = response.data;
        if (res.status) {
            toster_sucess(res.message);             
        }
        else {
            toster_error(res.message);
        }
    }

    render() {

        const { percentage, amount, formError, formValid } = this.state;
        const disabled = !formValid.percentage || !formValid.amount || (this.props.userDetails.percent_charge === Number(percentage) && this.props.userDetails.amount_charge === Number(amount));

        return (
            <div className="tab_container">
                <div className="display-table">
                    <div className="table-cell">
                        <div className="form_wrap cus_formG">
                            <form method="POST" action="#" onSubmit={(e)=>this.updateBidCharge(e)}>
                                <h6>Bid Charges</h6>
                                {console.log({ userDetails123: this.props.userDetails })}
                                <div className="form_group">
                                    <div className="wid_full">
                                        <input type="text" className={formError.percentage !== ""?"form_control error_border" : "form_control"} value={percentage} name="percentage" placeholder="Percentage" onChange={this.inputHandler} autoComplete="off" />
                                        <span className="icon_perc">%</span>
                                    </div>
                                    {formError.percentage !== ''?<p className="error_text">{formError.percentage}</p>:null}
                                </div>
                                <div className="form_group">
                                    <div className="wid_full">
                                        <input type="text" className={formError.amount !== ""?"form_control error_border" : "form_control"} value={amount} name="amount" placeholder="Amount" onChange={this.inputHandler} autoComplete="off" />
                                        <span className="icon_doller">$</span>
                                        
                                    </div>
                                    {formError.amount !== ''?<p className="error_text">{formError.amount}</p>:null}
                                </div>
                                <div className="form_btngroup">
                                    <div>
                                        <button type="submit" className="btn_save" disabled={disabled}>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDetails:state.users.userDetails
})

export default withRouter(connect(mapStateToProps)(BidCharge));