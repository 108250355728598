import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { apiHalper } from '../../helpers/ApiHelper';
import LoaderImg from '../../assets/images/user-loader.gif';
import { getCoupList } from '../../redux/actions/offersActions';
import { jwtDecode } from '../../helpers/jwt_helper';
import * as ACTION_TYPES from '../../redux/actions/types';
import { ToastContainer } from 'react-toastify';
import {toster_sucess, toster_error} from '../../helpers/toster_helper';

class DeleteModal extends Component {

    constructor(props){
        super(props);
        this.state={
            isLoader:false
        }
    }

    deleteCoupon = async (e) =>{
        e.preventDefault();
        // console.log('Delete coupon...');
        this.setState({isLoader:true})
        const { id } = this.props;
        let formData = new FormData();
        formData.append('coupon_id', id);

        const headers = {
            'Authorization':localStorage.getItem('token')
        }

        const res = await apiHalper('deleteCoupon','POST', formData, headers);
        if(res.data.status === true){
            this.setState({
                apiError:'',
                isLoader:false
            });
            toster_sucess("Delete Coupon Successfully..");
            this.props.handleClose();
            const res1 = await getCoupList();
            if(res1.data.status === true){
                const data = jwtDecode(res1.data.data);
                this.props.addCouponList(data)
            }
        }else{
            this.setState({
                apiError:res.data.message,
                isLoader:false
            });
            toster_error("Delete Coupon Failed");
        }

    }

    render() {

        const { isModal, handleClose } = this.props;
        const {isLoader} = this.state;

        return (
            <Modal show={isModal} onHide={()=>handleClose()} className="modal_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        {/* <div className="modal_header">
                            <h4 className="model_title">{isAdd?"Add":"Edit"} Coupon details <button type="button" onClick={handleClose}><span className="close_icon"></span></button></h4>
                        </div> */}
                        <div className="body_modals body_modals2">
                            <form method="POST" action="#" onSubmit={this.deleteCoupon}>
                                <div className="form_body2">
                                    <p className="delete_text">Delete Record<span>Are you sure you want to delete this record?</span></p>
                                </div>
                                <div className="bg_submit2 mr-top">
                                    <button type="submit" className="btn-submit" disabled={isLoader} >{isLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Yes"}</button>
                                    <p>Or <button type="button" className="btn_cancel2" onClick={()=>handleClose()}>No</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <ToastContainer />
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch =>({
    addCouponList:(data)=>dispatch({type:ACTION_TYPES.GET_COUPON_LIST, payload:data})
})

export default withRouter(connect(null, mapDispatchToProps)(DeleteModal));
