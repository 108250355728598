import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../assets/images/user-loader.gif';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import { toster_error, toster_sucess } from '../../helpers/toster_helper';

class NoteModal extends Component {

    constructor(props){
        super(props);
        this.state={
            note:'',
            isLoader: false,
            formError:{
                note:''
            },
            formValid:{
                note:false
            }
        }
    }

    inputHandler = e =>{
        const { name, value } = e.target;
        this.setState({
            [name]:value
        }, ()=>this.validationChecked(name, value));
    }

    validationChecked = (name, value) =>{
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch(name){
            case 'note':
                valid.note = value.length > 0;
                error.note = valid.note?'':'Note must required!';
                break;
            default:
                break;
        };

        this.setState({
            formError:error,
            formValid:valid
        });
    }

    sendNote = async (e) =>{
        e.preventDefault();
        console.log('send Note...');
        try{
            this.setState({
                isLoader: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('profile_id', this.props.id)
            formData.append('message', this.state.note)

            const response = await apiHalper('addUserNotes', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                this.setState({
                    isLoader: false,
                });
                
                this.props.handlerClose();
                toster_sucess(response.message)
            }
            else {
                this.setState({
                    isLoader: false
                });
                toster_error("Something wrong")
            }
        }catch(err){
            toster_error(err.message)
        }

    }

    updateNote = (e) =>{
        e.preventDefault();
        console.log('update Note...');
    }

    render() {

        const { isModal, isAdd, handlerClose } = this.props;

        const { note, formError, formValid, isLoader } = this.state;

        const disabled = !formValid.note;

        return (
            <Modal show={isModal} onHide={()=>handlerClose()} className="modal_design" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">{isAdd?"ADD":"Edit"} Note <button type="button" onClick={()=>handlerClose()}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals body_modals2">
                            <form method="POST" action="#" onSubmit={isAdd?this.sendNote:this.updateNote}>
                                <div className="form_body2">
                                    <div className="form_group"> 
                                        <label className="label_form">Note</label>
                                        <textarea name="note" rows="5" className={formError.note !== ''?"form_control2 error_border textarea":"form_control2 textarea"} value={note} onChange={this.inputHandler} autoComplete="off"/>
                                        {formError.note !== ''?<p className="error_text">{formError.note}</p>:null}
                                    </div>
                                    
                                </div>
                                <div className="bg_submit2 mr-top">
                                    {!isAdd?
                                        <button type="submit" className="btn-submit" disabled={disabled}>Update</button>
                                        :
                                        <button type="submit" className="btn-submit" disabled={disabled}>{isLoader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Submit"}</button>
                                    }   
                                    <p>Or <button type="button" className="btn_cancel2" onClick={()=>handlerClose()}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default withRouter(connect(null)(NoteModal));
