import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import IncomeDetails from './IncomeDetails';
import WithdrawDetails from './WithdrawDetails';
import AccountDetails from './AccountDetails';

class PayDetails extends Component {
    render() {
        const { id } = this.props;
        return (
            <div className="tab_container">
                <div className="container-fluid">
                        <div className="balance_wrap">
                            <Tab.Container defaultActiveKey="income" className="tab_nav" id="balanceViews">
                                <Nav variant="pills" className="tab_nav">
                                    <Nav.Item>
                                        <Nav.Link eventKey="income">Income</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="account">Account</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                
                                {/* <Link to="/reports" className="btn-report">Report</Link> */}

                                <Tab.Content>
                                    <Tab.Pane eventKey="income">
                                        <IncomeDetails uid={id} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="withdraw">
                                        <WithdrawDetails uid={id} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="account">
                                        <AccountDetails uid={id} />
                                    </Tab.Pane>
                                </Tab.Content>
                                
                        </Tab.Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(PayDetails));
