import React, { Component } from 'react'
import { Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
 
class ChatViews extends Component {
    backHandler= () => {
        const {history} = this.props;
        history.push('/chats');
    }
    render() {
        return (
            <section className="bg-balance mr_b0">
                <div className="container">
                    <div className="balance_wrap bg_rap">
                        <div className="chat_bloc">
                            <button type="button" className="btn-back" onClick={this.backHandler}>Back</button>
                            <div className="flex_box">
                                <div className="img_ve">
                                    <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="chats" />
                                    <img src="https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg" alt="chats" />
                                </div>
                                <h6 className="text_chatHead">John Doe <span>Active Now</span></h6>
                            </div>
                            <Link to="/chats/1/details" className="view_btn">view</Link>
                        </div>
                        <div className="chat">
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-left">
                                <p className="message_text">message list item onMeetingMoreSelect event, gets 3 parameters: message item, index of item, event</p>
                            </div>
                            <div className="chat-message-right">
                                <p className='message_text'>message list item onMeetingMoreSelect event, gets 3 parameters:</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(connect(null)(ChatViews))
