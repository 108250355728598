import React, { Component } from 'react';
import { NavLink, Switch, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import AllList from './AllList';
import PostList from './PostList';
// import BidList from './BidList';
import HiredList from './HiredList';
import DepositList from './DepositList';
import RefundReqList from './RefundReqList';
import UReviewList from './UReviewList';
import ReportList from './ReportList';
// import { ToastContainer } from 'react-toastify';
import PrivateRoute from '../../Routes/PrivateRoutes';

import './index.css';


class AllGigs extends Component {
    handlePrevUrl = (path) => {
        localStorage.setItem('prevGigPath', path)
    }

    render() {
        const { match } = this.props;
        // console.log(this.props);
        const routUrl = [
            { name:'All', url:`${match.url}`},
            { name:'Post', url:`${match.url}/post`},
            // { name:'Bid', url:`${match.url}/bid`},
            { name:'Hired', url:`${match.url}/hired`},
            { name:'Deposit', url:`${match.url}/deposit`},
            { name:'Refund Requests', url:`${match.url}/refundReq`},
            { name:'Under Review', url:`${match.url}/underRev`},
            { name:'Reported', url:`${match.url}/reported`}
        ]

        return (
            <section className="bg-balance">
                    <div className="container-fluid">
                        <div className="balance_wrap">
                            <ul className="nesting_links">
                                {routUrl.map((data,i)=><li key={i}><NavLink activeClassName="active" onClick={() => this.handlePrevUrl(data.url)} exact to={data.url} >{data.name}</NavLink></li>)}
                            </ul>
                            <Switch>
                                <PrivateRoute exact path={`${match.path}`} component={AllList} />
                                <PrivateRoute exact path={`${match.path}/post`} component={PostList} />
                                {/* <PrivateRoute exact path={`${match.path}/bid`} component={BidList} /> */}
                                <PrivateRoute exact path={`${match.path}/hired`} component={HiredList} />
                                <PrivateRoute exact path={`${match.path}/deposit`} component={DepositList} />
                                <PrivateRoute exact path={`${match.path}/refundReq`} component={RefundReqList} />
                                <PrivateRoute exact path={`${match.path}/underRev`} component={UReviewList} />
                                <PrivateRoute exact path={`${match.path}/reported`} component={ReportList} />
                            </Switch>
                        </div>
                    </div>
                    {/* <ToastContainer /> */}
                </section>
        )
    }
}

export default withRouter(connect(null)(AllGigs));
