import React, { Component } from 'react'
import { apiHalper } from '../../../helpers/ApiHelper';
//import { jwtDecode } from '../../../helpers/jwt_helper'
//import uploadIcon  from '../../../assets/images/upload-icon.svg';
import LoaderImg from '../../../assets/images/user-loader.gif';
import { toster_sucess, toster_error } from '../../../helpers/toster_helper';
import { Link } from 'react-router-dom';

export default class metaTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            insertID: null,
            metaTitle: '',
            metaKeyword:'',
            metaDescription:'',
            isFormVisible:false,
            formError: {
                metaTitle: '',
                metaKeyword:'',
                metaDescription:'',
            },
            formValid: {
                metaTitle: false,
                metaKeyword:false,
                metaDescription:false,
            }
        }
    }
    componentDidMount() {
        const search = this.props.location.search
        const urlParams = new URLSearchParams(search);
        const myParam = urlParams.get('id');
        this.setState({
            insertID: myParam
        })
        if (myParam === '') {
            this.props.history.push('/seo/add-faqs')
        }
    }
    handleFormVisible = () => {
        this.setState({
            isFormVisible: !this.state.isFormVisible
        })
        console.log(this.state.isFormVisible)
    }
    skipSubmit = () => {
        console.log("Skip...")
    }
    submitContent = async (e) => {
        e.preventDefault();
        const { metaTitle, metaKeyword, formError, metaDescription } = this.state;        
        if(metaTitle==='' && metaKeyword==='' && metaDescription==='' ) {
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: 'Meta Title is required',
                    metaKeyword: 'Meta Keyword is required',
                    metaDescription: 'Meta Description is required',
                }
            })
        }
        else if (metaTitle==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: 'Meta Title is required'
                }
            })
        }
        else if (metaKeyword==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaKeyword: 'Meta Keyword is required',
                }
            })
        }
        else if (metaDescription==='') {
            this.setState ({
                formError: {
                    ...formError,
                    metaDescription: 'Meta Description is required',
                }
            })
        }
        else {
            this.setState({              
                isLoader: true
            })
            const headers = { 'Authorization': localStorage.getItem('token') }
            const { insertID, metaTitle, metaKeyword, metaDescription } = this.state;
            const to = new URLSearchParams(this.props.location.search).get('to');
            const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
            let formData = new FormData()
                formData.append('serviceCategoryID', insertID)
                formData.append('pageTitle', metaTitle)
                formData.append('pageMetaKeywords', metaKeyword)
                formData.append('pageMetaDescription', metaDescription)
                formData.append('parentPath', parentPath)
            const response = await apiHalper('addMetaTags', 'POST', formData, headers)
            const res = response.data;
            if(!res.status){
                this.setState({
                    apiErr: res.message,
                    isLoader: false
                })
                toster_error(res.message)
                return true
            }

            const { history } = this.props;
            toster_sucess(res.message)
            history.push(`/seo/${to}`)
           
            this.setState ({
                formError: {
                    ...formError,
                    metaTitle: '',
                    metaKeyword: '',
                    metaDescription: '',
                }
            })
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]:value
        },()=>this.checkValidation(name, value));
    }
    textareaHandler = (e) => {
        const { formError } = this.state;
        const value = e.target.value;
        this.setState({
            metaDescription:value,
            formError: {
                ...formError,
                metaDescription: '',
            }
        },()=>this.checkValidation(value));
    }
    checkValidation = (name, value) => {
        let error = this.state.formError;
        let valid =this.state.formValid;        
        switch(name){
            case 'metaTitle':
                valid.metaTitle = value.length > 0;
                error.metaTitle = valid.metaTitle?'':'Meta Title is required.';
                break;
            case 'metaKeyword':
                valid.metaKeyword = value.length > 0;
                error.metaKeyword = valid.metaKeyword?'':'Metakeyword is required.';
                break;
            case 'metaDescription':
                valid.metaDescription = value.length > 0;
                error.metaDescription = valid.metaDescription?'':'Metadescription is required.';
                break;
            default:
                break;
        }

        this.setState({
            formError:error,
            formValid:valid
        })
    }
    render() {
        const { metaTitle, metaKeyword, formError, isLoader, insertID, isFormVisible } = this.state
        const parentPath = new URLSearchParams(this.props.location.search).get('parentPath');
        const to = new URLSearchParams(this.props.location.search).get('to');
        return (
            <div className="page-content">                
                <h4 className="model_title">Meta Tag
                <Link to={`/seo/add-faqs?id=${insertID}&parentPath=${parentPath}`} className="btn-back">Back</Link>
                </h4>
                <form action="/" method="POST" onSubmit={e => this.submitContent(e)}>
                    <div className="tabContent">
                        <div className="checkbox-group mb-3">
                            <input type="checkbox" value={isFormVisible} name="showMenu" id="mainmenu" defaultChecked={isFormVisible} onChange={this.handleFormVisible} className="styled-checkbox" />
                            <label htmlFor="mainmenu">Disabled Form</label>
                        </div>
                        <div className="div_3">
                            <div className="form_group">
                                <label>Meta Title</label>                                
                                <input type="text" name="metaTitle" value={metaTitle} disabled={isFormVisible} className={formError.metaTitle !== ''?"form_control2 error_border":"form_control2"} placeholder="Meta title" onChange={this.inputHandler} autoComplete="off" />
                                {formError.metaTitle !== ''?<p className="error_text pos_a">{formError.metaTitle}</p>:null}
                            </div>
                            <div className="form_group">
                                <label>Meta keyword</label>
                                <input type="text" name="metaKeyword" value={metaKeyword} disabled={isFormVisible} className={formError.metaKeyword !== ''?"form_control2 error_border":"form_control2"} placeholder="Meta keyword" onChange={this.inputHandler} autoComplete="off" />
                                {formError.metaKeyword !== ''?<p className="error_text pos_a">{formError.metaKeyword}</p>:null}
                            </div>
                        </div>
                        <div className="div_3">
                            <div className="form_group">
                                <label>Meta Description</label>
                                <textarea onChange={this.textareaHandler} disabled={isFormVisible} className={formError.metaDescription !== ''?"form_control2 textarea error_border":"form_control2 textarea" } placeholder="Meta Description"></textarea>
                                {formError.metaDescription !== ''?<p className="error_text pos_a">{formError.metaDescription}</p>:null}
                            </div>
                        </div>
                        {/* <div className="div_3">
                            <div className="form_group">
                                <label>H1 Tag</label>
                                <input type="text" className="form_control3" placeholder="H1 Tag" />                                
                            </div>                                             
                            <div className="form_group">
                                <label>Schema code</label>
                                <input type="text" className="form_control3" placeholder="Schema Code" />                                
                            </div>
                        </div> */}
                        
                        {/* <div className="div_3 drag-file">
                            <label>Video embed</label>
                            <div className="form_group files">
                                <span><img src={uploadIcon} alt="Upload File" /><p>Drag &amp; Drop Video here <br/>or <b>Browse Files</b></p></span>
                                <input type="file" id="video" name="video" accept="video/mp4,video/x-m4v,video/*" />
                            </div>
                        </div> */}
                    </div>
                    <div className="bg_submit2 right-mx120">
                        {isFormVisible ? 
                        <Link to={`/seo/${to}`} onClick={this.skipSubmit} className="btn-submit">Skip</Link> 
                        :
                        <button type="submit" className="btn-submit">{isLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Save"}</button>                                                                 
                        }
                        
                    </div>
                </form>
            </div>
        )
    }
}
