import React from 'react';
import './index.css';

const NoDataIndication = () => (
    <section className="loader_wrap">
        <div className="loader2 loader-7">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
        </div>
    </section>
);

export default NoDataIndication;