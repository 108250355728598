import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ListViews from './ListViews';
import LoaderImg from '../../assets/images/user-loader.gif';
// import { apiDeviceHelper } from '../../helpers/ApiHelper';
// import { toster_sucess } from '../../helpers/toster_helper';

class NoProfileList extends Component {
    _ismounted = false;

    constructor(props) {
        super(props);
        this.state = {
            noProSelect: [],
            isLoader: false,
            issLoader: false,
            isDisabled: false,
            isBLoader: false,
            messageApi: ''
        }
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    noProfileHander = (e) => {
        const { value } = e.target;
        let selected = this.state.noProSelect;
        if (!selected.includes(value)) {
            selected.push(value);
        } else {
            selected.splice(selected.indexOf(value), 1);
        }

        this.setState({
            noProSelect: selected
        });
    }

    aRejectApi = async () => {
        console.log('working...');
        // const { appSelect } = this.state;
        // if(Array.isArray(appSelect) && appSelect.length){

        //     this.setState({
        //         isARSLoader:true
        //     });

        //     let formData = new FormData();
        //     formData.append('photo_id', appSelect.join())
        //     const response = await apiHalper('rejectProfilePicture','POST', formData, null);
        //     if(response.data.flag === 1){
        //         const { appSelect, approvData } = this.state;
        //         let newArray = approvData;
        //         for(let i = appSelect.length -1; i >= 0; i--){
        //             newArray.splice(appSelect[i], i);
        //         }

        //         this.setState({
        //             isARSLoader:false,
        //             approvData:newArray
        //         });

        //         toster_sucess("Profile Rejected Successfully...");
        //     }
        // }
    }

    render() {

        const noProfData = [
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' },
            { img: 'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name: 'Doe', first_name: 'John', email: 'john1@gmail.com', date: '3 months ago' }
        ]

        const { isDisabled, isBLoader } = this.state;

        const noProfi = {
            name: 'noProfile',
            data: noProfData,
            inputHandler: this.noProfileHander,
            // nextData:this.nextpageApprovedprofile,
            // isSLoader:this.state.issLoader,
            // isLoader:this.state.isLoader,
            // messageApi:this.state.messageApi,
        }

        return <Fragment>
            <ListViews propsData={noProfi} />
            <div className="group_btns">
                <button type="button" className="btn_same btn_reject" onClick={this.aRejectApi} disabled={isDisabled || isBLoader}>{isBLoader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Reject"}</button>
            </div>
        </Fragment>
    }
}

export default withRouter(connect(null)(NoProfileList))
