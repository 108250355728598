import * as ACTIVE_TYPES from '../actions/types';

const initialState = {
    isSidebarActive:false
}

const headerReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTIVE_TYPES.TOGGLE_SIDEBAR_ACTIVE:
            return{
                ...state,
                isSidebarActive:!state.isSidebarActive
            }
        default:
            return state;
    }
}

export default headerReducers;