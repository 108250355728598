import React, { Component } from 'react';

class ProgressCircleBar extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {

        const { title, classes, sqSize, strokeWidth, percentage, isdays, totalDays  } = this.props.data;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (sqSize - strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // console.log(this.props);
    
        // Scale 100% coverage overlay with the actual percent
        let dashOffset = null;
        if(isdays && totalDays > 0){
          dashOffset = dashArray - dashArray * percentage / totalDays;
        }else{
          dashOffset = dashArray - dashArray * percentage / 60;
        }
        
      return (
        <svg className={classes}
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}>
            <circle
              className="circle-background"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth={`${strokeWidth}px`} />
            <circle
              className="circle-progress"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth={`${strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset
              }} />
            <text
              className="circle-text"
              x="50%"
              y="38%"
              dy=".3em"
              textAnchor="middle">
              {percentage} <tspan x="30" dx="10" dy="22">{title}</tspan>
            </text>
        </svg>
      );
    }
}

export default ProgressCircleBar;