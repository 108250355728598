import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as ACTION_TYPES from '../../redux/actions/types';
import './index.css';

class ImageViewer extends Component {
    constructor(props){
        super(props);
        this.state={
            img_url:''
        }
    }

    componentDidUpdate(prevProps){
        const { img_data, isImageView } = this.props;

        if(prevProps.isImageView !== isImageView){
            if(img_data !== null){
                this.imgUrlCrator(img_data);
            }
        }
    }

    imgUrlCrator = (file) =>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            let url = e.target.result;
            this.setState({
                img_url:url
            })
        }
    }

    render() {

        const { isImageView, handleClose } = this.props;
        const { img_url } = this.state;

        return (
            <Modal show={isImageView} onHide={handleClose} className="modalImage_design" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <button type="button" className="btn_close" onClick={handleClose}>Close</button>
                    <div className="image_view">
                        <img src={img_url} alt="viewer" />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = state =>({
    isImageView:state.balance.isImageView,
    img_data:state.balance.image_data
});

const mapDispatchToProps = dispatch =>({
    handleClose:()=>dispatch({type:ACTION_TYPES.TOGGLE_IMAGE_VIEWER, payload:null})
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageViewer));
