import axios from "axios";
import axiosInstance from "../../axiosConfig";


export const getAllCompany = (api, method)=>{
    const apiUrl = api;
    return new Promise((resolve, reject) => {
        axiosInstance({
            method: method,
            url: apiUrl,

        })
            .then(res => resolve(res))
            .catch(error => reject(error));
    });

} 


