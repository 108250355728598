import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import LoaderImg from "../../assets/images/user-loader.gif";
import { jwtDecode } from "../../helpers/jwt_helper";
import { apiHalper } from "../../helpers/ApiHelper";

const CompanyModal = ({
  isCompanyModal,
  closeModal,
  isAdd,
  getList,
  setIsAdd,
  updateData,
  pageNumber,
}) => {
  const [isSLoader, setIsSLoader] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: "",
    name_ar: "",
    link: "",
    profileFile: "",
    profilePic: "",
  });

  useEffect(() => {
    const url = jwtDecode(localStorage.getItem("filePath"));

    setCompanyData({
      name: updateData?.name,
      name_ar: updateData?.name_ar,
      link: updateData?.link === "undefined" ? "" : updateData?.link,
      profileFile: updateData?.filename,
      profilePic: `${url.company}${updateData?.filename}`,
    });
  }, [updateData]);

  const handleChangeProfile = (e) => {
    setCompanyData({
      ...companyData,
      profileFile: e.target.files[0],
      profilePic: URL.createObjectURL(e.target.files[0]),
    });
  };
  const handleSubmit = async () => {
    setIsSLoader(true);
    const formData = new FormData();
    formData.append("name", companyData.name);
    formData.append("name_ar", companyData.name_ar);
    formData.append("link", companyData.link);
    formData.append("image", companyData.profileFile);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    try {
      const res1 = await apiHalper("addCompany", "POST", formData, headers);
      if (res1.data.status === true) {
        setIsSLoader(false);
        setIsAdd(!isAdd);
        setCompanyData({
          name: "",
          name_ar: "",
          link: "",
          profileFile: "",
          profilePic: "",
        });
        toast.success("Company Add Successfully!");
        getList(pageNumber);
        closeModal();
      } else {
        toast.error(res1.data.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const handleUpdate = async () => {
    setIsSLoader(true);
    const formData = new FormData();
    formData.append("name", companyData.name);
    formData.append("name_ar", companyData.name_ar);
    formData.append("link", companyData.link);
    formData.append("image", companyData.profileFile);
    formData.append("id", updateData.id);
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    const res1 = await apiHalper("editCompany", "POST", formData, headers);
    if (res1.data.status === true) {
      setIsSLoader(false);
      toast.success("Company Edit Successfully!");
      setCompanyData({
        name: "",
        name_ar: "",
        link: "",
        profileFile: "",
        profilePic: "",
      });
      getList(pageNumber);
      closeModal();
    } else {
      toast.error("Edit Company  Failed");
    }
  };

  return (
    <>
      <Modal
        show={isCompanyModal}
        className="modal_design"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal_view">
            <div className="modal_header">
              <h4 className="model_title">
                Company details{" "}
                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </h4>
            </div>
            <div className="body_modals body_modals2">
              <form>
                <div className="form_body">
                  <div className="form_group2">
                    <label>Company Name</label>
                    <input
                      type="text"
                      name="name"
                      className={"form_control2"}
                      value={companyData.name}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          name: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2">
                    <label>Company Name Ar</label>
                    <input
                      type="text"
                      name="name_ar"
                      className={"form_control2"}
                      value={companyData.name_ar}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          name_ar: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2">
                    <label>Link(optional)</label>
                    <input
                      type="text"
                      name="link"
                      className={"form_control2"}
                      value={companyData.link}
                      onChange={(e) => {
                        setCompanyData({
                          ...companyData,
                          link: e.target.value,
                        });
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form_group2 " style={{ display: "grid" }}>
                    <label>Image</label>
                    <input
                      type="file"
                      name="profilePic"
                      autoComplete="off"
                      onChange={(e) => {
                        handleChangeProfile(e);
                      }}
                    />
                    <img
                      src={companyData?.profilePic}
                      style={{ width: "50px" }}
                    />
                  </div>
                </div>
                <div className="bg_submit2 mr-top">
                  {isAdd ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={
                        companyData.name &&
                          companyData.name_ar &&
                          companyData.profileFile
                          ? false
                          : true
                      }
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handleUpdate();
                      }}
                      type="submit"
                      className="btn-submit"
                      disabled={isSLoader}
                    >
                      {isSLoader ? (
                        <img
                          src={LoaderImg}
                          className="small-loader"
                          alt="loader"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                  <p>
                    Or{" "}
                    <button
                      type="button"
                      className="btn_cancel2"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompanyModal;
