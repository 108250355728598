import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
// import TableView from '../TableView';
import DataTable from '../JqueryDataTable';
import NoDataIndication from '../TableLoader';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import moment from 'moment';


class HistoryList extends Component {

    _ismounted = false;

    constructor(props){
        super(props);
        this.state={
            status:1,
            selectedOption:null,
            isClearable:true,
            isLoader:false,
            historyData:[],
            page:1,
            totalRecord: 0,
            search:'',
            istester: false,
            messageApi:''
        }
    }

    componentDidMount(){
        this._ismounted = true;
        const { page,istester, search } = this.state;
        this.getHistory(page,istester, search);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    getHistory = async (page,istester, search) =>{

        if(this._ismounted){
            try{
                this.setState({
                    isLoader:true
                });

                const headers = {
                    'Authorization': localStorage.getItem("token")
                    
             
                }

                const { id } = this.props.match.params;

                let formData = new FormData();
                formData.append('profile_id', id)
                formData.append('page_no', page)
            
                    const response = await apiHalper('getClientBalanceHistory','POST',formData, headers);
                    if(response.data.status === true){
                        const data = jwtDecode(response.data.data);
                            this.setState({
                                isLoader:false,
                                totalRecord: data.histroy_count,
                                historyData:data.histroy_data,                           
                            });               
                        
                    }else{
                        this.setState({
                            isLoader:false,
                            historyData:[],          
                        });
                    }
            }catch(err){
                this.setState({
                    isLoader:false,  
                    historyData:[],          
                    messageApi:'Network Error'
                })
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        const { page, search } = this.state;
        this.getHistory(page, search, selectedOption);
    };

    toggleClearable = () =>{
        this.setState(state => ({ isClearable: !state.isClearable }));
        const { page, search, selectedOption } = this.state;
        this.getHistory(page, search, selectedOption);
    }

    headerSpan = ( column, colIndex )=>{
        switch(column.dataField){
            case 'status':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'amount':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'id':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            case 'datetime':
                return <span className={column.sort?"header_span sort text-center imgRight":"header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort?"header_span sort":"header_span"}>{column.text}</span>;
        }
    }

    statusField = (cell) =>{
        console.log({ cell })
        
        switch(cell){
            case 0:
                return <button type="button" className="btn-status blue margin_0auto" disabled>{'Pending'}</button>;
            case 1:
                return <button type="button" className="btn-status green margin_0auto" disabled>{'Completed'}</button>;
            case 2:
                return <button type="button" className="btn-status red margin_0auto" disabled>{'Cancel'}</button>;
            case 3:
                return <button type="button" className="btn-status orange margin_0auto" disabled>{'Refunded'}</button>;
            default:
                return <button type="button" className="btn-status  margin_0auto" disabled>--</button>;
        }
        
    }
    id = (cell, row) =>{
        return <span className="btn-view margin_0auto">{row.job_post_id}</span>;
    }
    amountField = (cell, row) =>{
        return <span className="text-center margin_0auto">{row.amount}</span>;
    }
    dateField = (cell, row) => {
        const formattedDate = moment(row.date_paid).format('MMM DD, YYYY, hh:mm:ss a');
        return <span className="text-center margin_0auto">{formattedDate}</span>;
    }    

    handleTableChange = ( type, { page, sizePerPage, searchText } ) => {
        // console.log(type);
        if(type === 'pagination'){
            
            this.setState({
                page,
                sizePerPage
            });
        }

        if(type === 'search'){
            this.setState({
                search:searchText
            });
        }
        const { selectedOption } = this.state;
        this.getHistory(page, searchText, selectedOption);
    }

    jobId = (cell)=>{
        return `Paid for job ID ${cell}`
    }

    isTester = (row) =>{
        let classs = null;
        if(row.is_tester === 1){
            classs = 'tester_tr'
        }else{
            classs = null;
        }

        return classs;
    }

    render() {

        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Paid' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Refunded' }
        ];

        const columns = [
            { dataField: 'id', text:"id", sort:true, headerFormatter:this.headerSpan, formatter:this.id},
            { dataField: 'amount', text: 'Amount', sort: true, headerFormatter: this.headerSpan, formatter:this.amountField }, 
            { dataField: 'datetime', text: 'Date', sort: true, headerFormatter: this.headerSpan, formatter:this.dateField },
            { dataField: 'income_status', text: 'Status', sort: true, headerFormatter: this.headerSpan, formatter:this.statusField },
           
        ];

        const tableData = {
            columns:columns,
            data:this.state.historyData,
            keyId:'id',
            loading:this.state.isLoader,
            message:this.state.messageApi !== ''?this.state.messageApi:'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page:this.state.page,
            totalRecord:this.state.totalRecord,
            sizePerPage:10,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester
        };

        // const { selectedOption, isClearable } = this.state;
        
        return (
            <Fragment>
                <div className="tab_container">
                     {/* <div className="select_wrap">
                        <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={options} />
                    </div>  */}
                    <DataTable TableData={tableData} />           
                </div>
            </Fragment>
        )
    }
}
export default withRouter(connect(null)(HistoryList));
