import * as ACTION_TYPES from '../actions/types';

const initialState = {
    isAJFModal:false,
    isPJFModal:false,
    isBJFModal:false,
    isHJFModal:false,
    isDJFModal:false,
    isRJFModal:false,
    isUJFModal:false,
    isREJFModal:false,
    jobID:localStorage.getItem('jobID'),
    profileID:localStorage.getItem('profileID')
}

const allJobsReducers = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.TOGGLE_JOBS_ALLFILTER_MODAL:
            return{
                ...state,
                isAJFModal:!state.isAJFModal,
            }
        case ACTION_TYPES.TOGGLE_JOBS_POSTFILTER_MODAL:
            return{
                ...state,
                isPJFModal:!state.isPJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_BIDFILTER_MODAL:
            return{
                ...state,
                isBJFModal:!state.isBJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_HIREDFILTER_MODAL:
            return{
                ...state,
                isHJFModal:!state.isHJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_DEPOFILTER_MODAL:
            return{
                ...state,
                isDJFModal:!state.isDJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_RRQUESTFILTER_MODAL:
            return{
                ...state,
                isRJFModal:!state.isRJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_UREVIEWFILTER_MODAL:
            return{
                ...state,
                isUJFModal:!state.isUJFModal
            }
        case ACTION_TYPES.TOGGLE_JOBS_REPORTFILTER_MODAL:
            return{
                ...state,
                isREJFModal:!state.isREJFModal
            }
        default:
            return state;
    }
}

export default allJobsReducers;