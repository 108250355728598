import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as TimeHelper from '../../helpers/TimeDiff';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Select from 'react-select';

class JobsFilterM extends Component {
    constructor(props){
        super(props);
        this.state={
            monthArray:[],
            selectedOption:null,
            isClearable:true,
        }
    }

    componentDidMount(){
        this.getMonth();
    }

    getMonth = () =>{

        const { dateRange } = this.props.field;

        // const d = new Date();
        const current = new Date();
        const n = 2;
        let newArray = [];
        let d;
        for (let i = n; i >= 1; i--) {
            d = new Date(current.getFullYear(), current.getMonth() - i, 1);
            // month = TimeHelper.month_name(d.getMonth());
            newArray.push({
                label: TimeHelper.month_name(d.getMonth()),
                val: (d.getMonth() + 1),
                name:dateRange.custom_name, 
                id:`${dateRange.month}Month${i}`
            });
        }

        newArray.push({
            label: TimeHelper.month_name(current.getMonth()),
            val: current.getMonth() + 1,
            name:dateRange.custom_name, 
            id:`${dateRange.month}Month3`
        });
        // let monthArray = [];
        // const n = 2;
        // for(let i = n; i >= 1; i--){
        //     monthArray.push({label:TimeHelper.month_name(d.getMonth() - i),val:((d.getMonth() + 1) - i),name:dateRange.custom_name, id:`${dateRange.month}Month${i}`});
        // }
        // monthArray.push({label:TimeHelper.month_name(d.getMonth()),val:(d.getMonth() + 1),name:dateRange.custom_name,id:`${dateRange.month}Month3`})
        this.setState({
            monthArray:newArray
        });
    }

    renderClientStatus = (info, i, inputName)=>{
        const { cJobStatus, jobStatusHandler } = this.props.data;
        return <li key={i}>
            <label htmlFor={info.inputID}>
                <input type="radio" id={info.inputID} name={inputName} checked={cJobStatus === info.statusID} onChange={()=>jobStatusHandler(info.statusID)} />
                <span>{info.status}</span>
            </label>
        </li>
    }

    renderServiceList = (info, i, inputName)=>{
        const { typeServices, serviceHandler } = this.props.data;
        return <li key={i}>
            <label htmlFor={info.inputID}>
                <input type="radio" name={inputName} id={info.inputID} checked={typeServices === info.id} onChange={()=>serviceHandler(info.id)} />
                <span>{info.name}</span>
            </label>
        </li>
    }


    render() {

        const options = [
            { value: 0, label: 'Pending' },
            { value: 1, label: 'Paid' },
            { value: 2, label: 'Cancel' },
            { value: 3, label: 'Refund' }
        ];

        const { isModal, closeEvent, field, data } = this.props;
        const { sortBy, dateRange, cjs, iba, toservices } = field;
        const { sortByVal, rangeVal, manuDate, startDate, endDate, iAdmin, typeServices, selectedCountry, isClearable, sortByHandler, rangeHandler, manuDateHandler, handleStartdate, handleEnddate, 
            adminHandler, serviceHandler, applyHandler, handleCountry } = data;

        const d = new Date();
        const { monthArray } = this.state;

        return (
            <Modal show={isModal} onHide={()=>closeEvent()} className="modal_design" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Filter <button type="button" onClick={closeEvent}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#" onSubmit={applyHandler}>
                                <div className="form_body">
                                    <div className="wrap_filterB flex_wrap">
                                        <h4>Sort By</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label htmlFor={sortBy.newOld}><input type="radio" id={sortBy.newOld} name={sortBy.name} checked={sortByVal === 1} onChange={()=>sortByHandler(0)} /><span>Date: New to Old</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={sortBy.oldNew}><input type="radio" name={sortBy.name} id={sortBy.oldNew} checked={sortByVal === 2} onChange={()=>sortByHandler(1)} /><span>Date: Old to New</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB flex_wrap">
                                        <h4>Date Range</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label htmlFor={dateRange.date_range}><input type="radio" id={dateRange.date_range} name={dateRange.name} checked={rangeVal === 0} onChange={()=>rangeHandler(0)} /><span>All</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={dateRange.custom_range}><input type="radio" name={dateRange.name} id={dateRange.custom_range} checked={rangeVal === 1} onChange={()=>rangeHandler(1)} /><span>Select Custom</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    {rangeVal === 1?
                                    <div className="wrap_filterB">
                                        <ul className="radio_ul">
                                            {monthArray.length > 0?
                                                monthArray.map((data1, i)=><li key={i}>
                                                        <label htmlFor={data1.id}><input type="radio" id={data1.id} name={data1.custom_name} checked={data1.val === manuDate} onChange={()=>manuDateHandler(data1.val)}  /><span>{data1.label}</span></label>
                                                    </li>
                                                )
                                                :
                                                null
                                            }
                                            
                                            <li>
                                                <label htmlFor={dateRange.year}><input type="radio" name={dateRange.custom_name} id={dateRange.year} checked={manuDate === d.getFullYear()} onChange={()=>manuDateHandler(d.getFullYear())} /><span>{d.getFullYear()}</span></label>
                                            </li>
                                        </ul>
                                        <div className="date_range_wrap max_wdt">
                                            <DateRangePicker initialSettings={{singleDatePicker:true, maxDate:new Date(), startDate}}  onApply={handleStartdate}><input type="button" /></DateRangePicker>
                                            <span>To</span>
                                            <DateRangePicker initialSettings={{singleDatePicker:true, maxDate:new Date(), endDate}} onApply={handleEnddate}><input type="button" /></DateRangePicker>
                                        </div>
                                    </div>
                                    :
                                    null
                                    }
                                    <div className="wrap_filterB">
                                        <h4>Client Job Status</h4>
                                        <ul className="radio_ul radio_float mar5_l0">
                                            {Array.isArray(cjs.cateArray) && cjs.cateArray.length > 0?
                                                cjs.cateArray.map((data, i)=>this.renderClientStatus(data, i, cjs.name))
                                                :
                                                null
                                            }
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB">
                                        <h4>Involved By Admin</h4>
                                        <ul className="radio_ul">
                                            <li>
                                                <label htmlFor={iba.iAdmin1}><input type="radio" id={iba.iAdmin1} name={iba.name} checked={iAdmin === 0} onChange={()=>adminHandler(0)} /><span>Any Status</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={iba.iAdmin2}><input type="radio" name={iba.name} id={iba.iAdmin2} checked={iAdmin === 1} onChange={()=>adminHandler(1)} /><span>Edit Job Details by Admin</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={iba.iAdmin3}><input type="radio" name={iba.name} id={iba.iAdmin3} checked={iAdmin === 2} onChange={()=>adminHandler(2)} /><span>Deleted Job by Admin</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={iba.iAdmin4}><input type="radio" name={iba.name} id={iba.iAdmin4} checked={iAdmin === 3} onChange={()=>adminHandler(3)} /><span>Red Flag Job</span></label>
                                            </li>
                                            <li>
                                                <label htmlFor={iba.iAdmin5}><input type="radio" name={iba.name} id={iba.iAdmin5} checked={iAdmin === 4} onChange={()=>adminHandler(4)} /><span>Deleted Chat by Admin</span></label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="wrap_filterB">
                                        <h4>Client Country</h4>
                                        <Select value={selectedCountry} isClearable={isClearable} onChange={()=>handleCountry()} options={options} />
                                    </div>
                                    <div className="wrap_filterB">
                                        <h4>Type Of Services</h4>
                                        <ul className="radio_ul radio_float mar7_l0">
                                            <li>
                                                <label htmlFor={'allServices'}>
                                                    <input type="radio" id={'allServices'} name={toservices.name} checked={typeServices === 0} onChange={()=>serviceHandler(0)} />
                                                    <span>All Services</span>
                                                </label>
                                            </li>
                                            {Array.isArray(toservices.serviceArray)?
                                                toservices.serviceArray.map((info, i)=>this.renderServiceList(info, i, toservices.name))
                                                :
                                                null
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className="bg_submit2 mr-top">
                                    <button type="submit" className="btn-submit">Apply</button>
                                    <p>Or <button type="button" className="btn_cancel2" onClick={()=>closeEvent()}>Cancel</button></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(connect(null)(JobsFilterM));
