import React from 'react';

const HeaderSpan = ({ column }) => {
    return (
        <span className={column.sort ? "header_span sort" : "header_span"}>
            {column.text}
        </span>
    );
};

export default HeaderSpan;
