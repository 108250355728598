import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiHalper } from '../../helpers/ApiHelper';
import { toster_error, toster_sucess } from '../../helpers/toster_helper';

// import Progresstrust from '../../assets/images/trust_pro.png'

class TrustDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            emailTrust:0,
            phoneTrust:0,
            mawthooqIDTrust: 0,
            crTrust: 0,
            vatTrust: 0,
            fbTrust:0,
            ldTrust:0,
            payTrust:0,
            vIDTrust:2,
            trusTotal:0,
            trusProg:0,
            clasA1:'',
            clasA2:'',
            clasA3:'',
            clasA4:'',
            type: '',
            profileScoreActive1:'',
            profileScoreActive2:'',
            profileScoreActive3:'',
            profileScoreActive4:'',
            profileProgress: 0,
            profileTotal: 0,
            profile_status: null,
        }
    }

    componentDidUpdate(prevProps){
        const { userDetails, type } = this.props;
        if(prevProps.type !== type) {
            this.setState({
                type: type
            })
        }
        
        if(prevProps.userDetails !== userDetails){
            
            const { trust_rate_status, trust_rate, profile_status, cr_verified, mawthooq_verified, id, vat_status, cr_status } = userDetails;
            
            this.setState({ profile_id: id})
            
            if(profile_status !== null){
                this.setState({
                    profile_status:profile_status
                })
                this.setState({
                    profileTotal: profile_status.total_percentage
                })
                
                if(profile_status.total_percentage <= 25){
                    this.setState({
                        profileScoreActive1:'active',
                        profileScoreActive2:'',
                        profileScoreActive3:'',
                        profileScoreActive4:'',
                    });
                }else if (profile_status.total_percentage > 25 && profile_status.total_percentage <= 50){
                    this.setState({
                        profileScoreActive1:'',
                        profileScoreActive2:'active',
                        profileScoreActive3:'',
                        profileScoreActive4:'',
                    });
                }else if (profile_status.total_percentage > 50 && profile_status.total_percentage <= 75){
                    this.setState({
                        profileScoreActive1:'',
                        profileScoreActive2:'',
                        profileScoreActive3:'active',
                        profileScoreActive4:'',
                    });
                }else if (profile_status.total_percentage > 75 && profile_status.total_percentage <= 100){
                    this.setState({
                        profileScoreActive1:'',
                        profileScoreActive2:'',
                        profileScoreActive3:'',
                        profileScoreActive4:'active',
                    });
                }
            }
            
            if(trust_rate !== null){
                
                const trusToatl = trust_rate.total_points + trust_rate.points_needed;
                
                if(trust_rate.total_points <= 27.5){
                    this.setState({
                        clasA1:'active',
                        clasA2:'',
                        clasA3:'',
                        clasA4:''

                    });
                }else if(trust_rate.total_points > 27.5 && trust_rate.total_points <= 55 ){
                    this.setState({
                        clasA1:'',
                        clasA2:'active',
                        clasA3:'',
                        clasA4:''
                    })
                }else if(trust_rate.total_points > 55 && trust_rate.total_points <= 82.5){
                    this.setState({
                        clasA1:'',
                        clasA2:'',
                        clasA3:'active',
                        clasA4:''
                    })
                }else if(trust_rate.total_points > 82.5 && trust_rate.total_points <= trusToatl){
                    this.setState({
                        clasA1:'',
                        clasA2:'',
                        clasA3:'',
                        clasA4:'active'
                    })
                }

                this.setState({
                    trusTotal:trusToatl,
                    trusProg: trust_rate.total_points
                })
            }else{
                this.setState({
                    trusTotal:0,
                    trusProg: 0
                })
            }

            if(trust_rate !== null && trust_rate !== ""){
                this.setState({
                    emailTrust: Number(trust_rate?.email) || 0,
                    phoneTrust: Number(trust_rate?.phone_number) || 0,
                    fbTrust:trust_rate?.facebook,
                    payTrust:trust_rate?.payment,
                    mawthooqIDTrust: Number(trust_rate?.mawthooq_id) || 0,
                    crTrust: Number(cr_status) || 0,
                    vatTrust: Number(vat_status) || 0,
                })
            } else {
                this.setState({
                    emailTrust:0,
                    phoneTrust:0,
                    fbTrust:0,
                    payTrust:0,
                    vIDTrust:0,
                    mawthooqIDTrust: 0,
                    crTrust: 0,
                    vatTrust: 0,
                })
            }
        }
    }

    onChangeVerification = async (verifyField, val) => {
        const headers = {
            'Authorization': localStorage.getItem('token')
        }
        const {profile_id, emailTrust, phoneTrust} = this.state
        let formData = new FormData();
        formData.append('profile_id', profile_id)
        if(verifyField === 'verifyPhone') {
            formData.append('is_phone_verified', val)
        } else if(verifyField === 'verifyEmail') {
            formData.append('is_email_verified', val)
        } else {
            formData.append('verify_status', val)
        }

        const response = await apiHalper(verifyField, 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                if(verifyField === 'verifyEmail') {
                    this.setState({emailTrust:val});
                } else if(verifyField === 'verifyPhone') {
                    this.setState({phoneTrust:val});
                } else if(verifyField === 'verifyMawthooq') {
                    this.setState({mawthooqIDTrust:val});
                } else if(verifyField === 'verifyCR') {
                    this.setState({crTrust:val});
                } else if(verifyField === 'verifyVat') {
                    this.setState({vatTrust:val});
                }
                toster_sucess("Status Updated Successfully...");             
            } else {
                toster_error(res.message);
            }
    }
    // onChangeVerifyMawthooq = async (val) => {
    //     const headers = {
    //         'Authorization': localStorage.getItem('token')
    //     }
    //     const {profile_id, emailTrust, phoneTrust} = this.state
    //     let formData = new FormData();
    //     formData.append('profile_id', profile_id)
    //     formData.append('verify_status', val)

    //     const response = await apiHalper('verifyMawthooq', 'POST', formData, headers);
    //         const res = response.data;
    //         if (res.status) {
    //             this.setState({mawthooqIDTrust:val});
    //             toster_sucess("Status Updated Successfully...");             
    //         } else {
    //             toster_error(res.message);
    //         }
    // }
    buttonsHandler = (val, e) =>{
        const { name } = e.target;
        switch(name){
            case 'email_trust':
                this.onChangeVerification('verifyEmail', val)
                break
            case 'phone_trust':
                this.onChangeVerification('verifyPhone', val)
                break
            case 'mawthooq':
                this.onChangeVerification('verifyMawthooq', val)
                break
            case 'crTrust':
                // this.setState({phoneTrust:val});
                this.onChangeVerification('verifyCR', val)
                break
            case 'vatTrust':
                // this.setState({phoneTrust:val});
                this.onChangeVerification('verifyVat', val)
                break
            default:
                return null;
        } 
    }
    render() {
        // ldTrust
        const { emailTrust, phoneTrust, fbTrust, payTrust, vIDTrust, trusProg, trusTotal, profile_status, profileTotal, profileScoreActive1, profileScoreActive2, profileScoreActive3, profileScoreActive4, clasA1, clasA2, clasA3, clasA4, type, mawthooqIDTrust, crTrust, vatTrust } = this.state;
        
        return (
            <div className="tab_container">
                <div className='trust_wrapper_div'>
                    <h1>TRUST STATUS:</h1>
                    <div className="trus_wrap">
                        <div className="prog_wrap">
                            <div className="table_cellDiv">
                            <div className='progress_div'>
                                <p>Current Trust Score: {trusProg}/{trusTotal}</p>
                                    <div className="prog_bar">
                                        <span className={clasA1}></span>
                                        <span className={clasA2}></span>
                                        <span className={clasA3}></span>
                                        <span className={clasA4}></span>
                                    </div>
                            </div>
                            </div>
                        </div>
                        <div className="trust_blocks">
                            <div className="trust_info">
                                <h6>Email</h6>
                                <div className="div_trustValid">
                                    <p className={`tpoint_wrap ${emailTrust === 1 ? 'green' : 'red'}`}>30 Points</p>
                                    <ul className="trus_ul">
                                        <li><label htmlFor="emailREG"><input type="radio" name="email_trust" id="emailREG"  checked={emailTrust === 2} onChange={(e)=>this.buttonsHandler(2, e)} /><span className="bgColor_gray">Reg</span></label></li>
                                        <li><label htmlFor="emailNO"><input type="radio" name="email_trust" id="emailNO" checked={emailTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="emailYES"><input type="radio" name="email_trust" id="emailYES" checked={emailTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="trust_info">
                                <h6>Phone</h6>
                                <div className="div_trustValid">
                                    <p className={`tpoint_wrap ${phoneTrust === 1 ? 'green' : 'red'}`}>30 Points</p>
                                    <ul className="trus_ul">
                                        <li><label htmlFor="phoneREG"><input type="radio" name="phone_trust" id="phoneREG" checked={phoneTrust === 2} onChange={(e)=>this.buttonsHandler(2, e)} /><span className="bgColor_gray">Reg</span></label></li>
                                        <li><label htmlFor="phoneNO"><input type="radio" name="phone_trust" id="phoneNO" checked={phoneTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="phoneYES"><input type="radio" name="phone_trust" id="phoneYES" checked={phoneTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="trust_info">
                                <h6>Facebook</h6>
                                <div className="div_trustValid">
                                    <p className="tpoint_wrap green">20 Points</p>
                                    {console.log({ fbTrust })}
                                    <ul className="trus_ul">
                                        <li><label htmlFor="fbREG"><input type="radio" name="fb_trust" id="fbREG" disabled checked={fbTrust === 2} onChange={(e)=>this.buttonsHandler(2, e)} /><span className="bgColor_gray">Reg</span></label></li>
                                        <li><label htmlFor="fbNO"><input type="radio" name="fb_trust" id="fbNO" disabled checked={fbTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="fbYES"><input type="radio" name="fb_trust" id="fbYES" disabled checked={fbTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="trust_info">
                                <h6>Payment</h6>
                                <div className="div_trustValid">
                                    <p className="tpoint_wrap red">30 Points</p>
                                    {console.log({ payTrust })}
                                    <ul className="trus_ul">
                                        <li><label htmlFor="payREG"><input type="radio" name="pay_trust" id="payREG" disabled checked={payTrust === 2} onChange={(e)=>this.buttonsHandler(2, e)} /><span className="bgColor_gray">Reg</span></label></li>
                                        <li><label htmlFor="payNO"><input type="radio" name="pay_trust" id="payNO" disabled checked={payTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="payYES"><input type="radio" name="pay_trust" id="payYES" disabled checked={payTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div> */}
                            {type === 'agents' && <div className="trust_info">
                                <h6>Mawthooq ID</h6>
                                <div className="div_trustValid">
                                    <p className={`tpoint_wrap ${mawthooqIDTrust === 1 ? 'green' : 'red'}`}>40 Points</p>
                                    <ul className="trus_ul">
                                        <li><label htmlFor="vIDNO"><input type="radio" name="mawthooq" id="vIDNO" checked={mawthooqIDTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="vIDYES"><input type="radio" name="mawthooq" id="vIDYES" checked={mawthooqIDTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>}
                            {type === 'clients' && <div className="trust_info">
                                <h6>Commercial Registration ID</h6>
                                <div className="div_trustValid">
                                    <p className={`tpoint_wrap ${crTrust === 1 ? 'green' : 'red'}`}>40 Points</p>
                                    <ul className="trus_ul">
                                        <li><label htmlFor="crTrustNo"><input type="radio" name="crTrust" id="crTrustNo" checked={crTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="crTrustYes"><input type="radio" name="crTrust" id="crTrustYes" checked={crTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>}
                            {type === 'clients' && <div className="trust_info">
                                <h6>VAT</h6>
                                <div className="div_trustValid">
                                    {/* <p className="tpoint_wrap green">40 Points</p> */}
                                    <ul className="trus_ul">
                                        <li><label htmlFor="vatNo"><input type="radio" name="vatTrust" id="vatNo" checked={vatTrust === 0} onChange={(e)=>this.buttonsHandler(0, e)} /><span className="bgColor_red">No</span></label></li>
                                        <li><label htmlFor="vatYES"><input type="radio" name="vatTrust" id="vatYES" checked={vatTrust === 1} onChange={(e)=>this.buttonsHandler(1, e)} /><span className="bgColor_blue">Yes</span></label></li>
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div> 

                <div className='trust_wrapper_div'>
                    <h1>PROFILE STATUS:</h1>
                    <div className="trus_wrap">
                        <div className="prog_wrap">
                            <div className="table_cellDiv">
                            <div className='progress_div'>
                                <p>Current Profile Score: {profileTotal}%</p>
                                    <div className="prog_bar">
                                        <span className={profileScoreActive1}></span>
                                        <span className={profileScoreActive2}></span>
                                        <span className={profileScoreActive3}></span>
                                        <span className={profileScoreActive4}></span>
                                    </div>
                            </div>
                            </div>
                        </div>
                        <div className="trust_blocks">
                            <div className="trust_info">
                                <div className="div_trustValid">
                                    <h6>Private Information</h6>
                                    <p className="tpoint_wrap green">{profile_status&&profile_status.private_info}%</p>
                                </div>
                            </div>
                            <div className="trust_info">
                                <div className="div_trustValid">
                                    <h6>Professional Information</h6>
                                    <p className="tpoint_wrap green">{profile_status&&profile_status.professional_info}%</p>
                                </div>
                            </div>
                            <div className="trust_info">
                                <div className="div_trustValid">
                                    <h6>Skill</h6>
                                    <p className="tpoint_wrap green">{profile_status&&profile_status.skill}%</p>
                                </div>
                            </div>
                            <div className="trust_info">
                                
                                <div className="div_trustValid">
                                    <h6>Verification</h6>
                                    <p className="tpoint_wrap green">{profile_status&&profile_status.verification}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        )
    }
}

const mapStateToProps = state =>({
    userDetails:state.users.userDetails
})

export default withRouter(connect(mapStateToProps)(TrustDetails));
