import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import NoDataIndication from '../TableLoader';
import DummyProfile from '../../assets/images/dummy-profile.jpg';
import RankModal from './RankModal';
import DataTable from '../JqueryDataTable';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
// import DatePicker from 'react-datepicker'
import { toster_sucess } from '../../helpers/toster_helper';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';

class Agent extends Component {
    _unmounted = false
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            issLoader: false,
            messageAApi: '',
            isTester: false,
            isTopRate: false,
            isClearable: true,
            viewBtn: 0,
            orderBy: '',
            sortBy: 'DESC',
            selectedOption: null,
            mainCate: [],
            sellerList: [],
            totalSeleRecord: 0,
            imageVerify: '',
            isModal: false,
            editData: null,
            page: 1,
            search: '',
            totalRecord: 0,
            sizePerPage: 10,
            isSelectedLoader: false,
            startDate: '',
            endDate: '',
        }
    }

    componentDidMount() {
        this._unmounted = true
        this.getMainCate();
        const { page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate } = this.state;
        this.getSellerList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)
    }

    componentWillUnmount() {
        this._unmounted = false
    }

    // toggleClearable = () => {
    //     this.setState({
    //         isClearable: !this.state.isClearable
    //     });
    //     const { page, search, sizePerPage, selectedOption, isTester } = this.state;
    //     this.getSellerList(page, search, sizePerPage, selectedOption, isTester)
    // }

    getMainCate = async () => {
        try {
            if (this._unmounted) {
                const headers = {
                    'Authorization': localStorage.getItem('token'),
                   // "Access-Control-Allow-Origin": '*'
                }

                const response = await apiHalper('getMainServices', 'GET', null, headers);
                const res = response.data;
                if (res.status) {
                    const data = jwtDecode(res.data);
                    const option = Array.isArray(data) ? data.map(({ id, name }) => ({ value: id, label: name })) : [];
                    this.setState({
                        mainCate: option,
                    })
                } else {
                    this.setState({
                        mainCate: []
                    })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    getSellerList = async (page, search, sizePerPage, selectedOption, isTester, type, sort, startDate, endDate) => {
        try {
            this.setState({
                isLoader: true,
                sellerList: [],
                viewBtn: 0,
                isTopRate: false
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('gigType', 'ALL')
            formData.append('pageNo', page)
            formData.append('searchBy', search)
            // formData.append('dateFrom', startDate)
            // formData.append('dateTo', endDate)
            if (startDate === '' || startDate === null) {
                formData.append('dateFrom', '')
            }
            else {
                formData.append('dateFrom', moment(startDate).format('YYYY-MM-DD'))
            }
            if (endDate === '' || endDate === null) {
                formData.append('dateTo', '')
            }
            else {
                formData.append('dateTo', moment(endDate).format('YYYY-MM-DD'))
            }
            // formData.append('serviceCategoryID', selectedOption ? selectedOption.value : 0)
            formData.append('serviceCategoryID', 4352)
            formData.append('showTester', isTester ? 1 : 0)
            formData.append('limit', sizePerPage)
            formData.append('orderBy', type)
            formData.append('sortBy', sort)

            const response = await apiHalper('getSellerLists', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    sellerList: data.data,
                    totalRecord: data.count,
                    viewBtn: 0,
                    isTopRate: false
                });
            }
            else {
                this.setState({
                    isLoader: false,
                    sellerList: [],
                    viewBtn: 0,
                    isTopRate: false
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }


    viewTopRated = async (page, search, sizePerPage, selectedOption, isTester, type, sort, startDate, endDate) => {
        try {
            this.setState({
                isLoader: true,
                sellerList: [],
                viewBtn: 1,
                isTopRate: true
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('pageNo', page)
            formData.append('searchBy', search)
            formData.append('limit', sizePerPage)
            if (startDate === '' || startDate === null) {
                formData.append('dateFrom', '')
            }
            else {
                formData.append('dateFrom', moment(startDate).format('YYYY-MM-DD'))
            }
            if (endDate === '' || endDate === null) {
                formData.append('dateTo', '')
            }
            else {
                formData.append('dateTo', moment(endDate).format('YYYY-MM-DD'))
            }
            // formData.append('serviceCategoryID', selectedOption ? selectedOption.value : 0)
            formData.append('serviceCategoryID', 4352)
            formData.append('showTester', isTester ? 1 : 0)
            formData.append('orderBy', type)
            formData.append('sortBy', sort)

            const response = await apiHalper('getTopSellerLists', 'POST', formData, headers)
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    isLoader: false,
                    sellerList: data.data,
                    totalRecord: data.count,
                    viewBtn: 1,
                    isTopRate: true
                });
            }
            else {
                this.setState({
                    isLoader: false,
                    sellerList: [],
                    viewBtn: 1,
                    isTopRate: true
                });
            }
        } catch (err) {
            console.log(err.message);
        }
    }


    headerSpan = (column, colIndex) => {
        switch (column.dataField) {
            case 'username':
                return <span className={column.sort ? "header_span sort imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'count_rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'rating':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            case 'generalRank':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'categoryRank':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case '5D':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case '15D':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case '30D':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case '180D':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'total_contract':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            case 'price':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;

            case 'action':
                return <span className={column.sort ? "header_span sort text-center imgRight" : "header_span text-center imgRight"}>{column.text}</span>;
            default:
                return <span className={column.sort ? "header_span sort" : "header_span"}>{column.text}</span>;
        }
    }
    handleChange = (selectedOption) => {
        this.setState({
            selectedOption,
            search: '',
            rowSelected: [],
            showAll: true
        })
        document.getElementById('tableSearch').value = '';
        const { page, search, sizePerPage, isTester, orderBy, sortBy, startDate, endDate } = this.state;
        this.getSellerList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)
    }

    imgNotFound = (e) => {
        e.target.src = DummyProfile;
    }

    userName = (cell, row) => {
        const imgfilePath = localStorage.getItem('filePath')
        const decodePath = jwtDecode(imgfilePath);
        const imgPath = `${decodePath.img}${row.img}`
        return <div className="tb_user_wrap user-prof">
            <div className="useimg_block online_icon">
                <img src={row.img !== null ? imgPath : DummyProfile} onError={(e) => this.imgNotFound(e)} alt="User" />
            </div>
            <p>{row.username}</p>
        </div>
    }

    commonField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell === null ? "-" : cell}</span>;
    }
    centerField = (cell, row) => {
        return <span className="text-center margin_0auto">{cell === null ? "-" : cell}</span>;
    }

    actionField = (cell, row) => {
        return <button type="button" className="btn-view margin_0auto" onClick={() => this.openModal(row)}>Set Rank</button>
    }

    handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder }) => {
        const { selectedOption, isTester, isTopRate, sortBy, startDate, endDate } = this.state

        if (sortOrder === 'asc') {
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'ASC', startDate, endDate)
            }
            else {
                this.getSellerList(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'ASC', startDate, endDate);
            }
        } else {
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'DESC', startDate, endDate)
            }
            else {
                this.getSellerList(page, searchText, sizePerPage, selectedOption, isTester, sortField, 'DESC', startDate, endDate);
            }
        }

        if (type === 'pagination') {
            this.setState({
                page,
                sizePerPage
            });
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy, startDate, endDate)
            }
            else {
                this.getSellerList(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy, startDate, endDate);
            }
        }
        if (type === 'search') {
            this.setState({
                search: searchText
            });
            if (isTopRate) {
                this.viewTopRated(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy, startDate, endDate)
            }
            else {
                this.getSellerList(page, searchText, sizePerPage, selectedOption, isTester, sortField, sortBy, startDate, endDate);
            }
        }
    }

    openModal = (data) => {
        this.setState({
            isModal: true,
            singleData: data,
            title: '',
            description: '',
            noReviews: '',
            clarity: 0,
            formError: {
                title: '',
                description: '',
                noReviews: '',
            },
            formValid: {
                title: false,
                description: false,
                noReviews: false
            },
            ratingValid: false,
            messageAApi: '',
            reviewType: 0
        })
    }

    closeModal = () => {
        this.setState({
            isModal: false
        })
    }

    rankFormSubmit = async (general, category) => {
        const { page, search, sizePerPage, selectedOption, isTester, isTopRate, orderBy, sortBy, startDate, endDate } = this.state;
        try {
            this.setState({
                issLoader: true,
                messageAApi: ''
            })
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();

            formData.append('profile_id', this.state.singleData.profile_id)
            formData.append('generalRank', general)
            formData.append('categoryRank', category)

            const res = await apiHalper(`setTopSeller`, 'POST', formData, headers);
            if (res.data.status === true) {
                this.setState({
                    issLoader: false,
                    messageAApi: ''
                });
                this.closeModal();
                if (isTopRate) {
                    this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)
                }
                else {
                    this.getSellerList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)
                }
                toster_sucess('Rank Added Successfully...');

            } else {
                this.setState({
                    issLoader: false,
                    messageAApi: res.data.message
                });
            }

        } catch (err) {
            this.setState({
                issLoader: false,
                messageAApi: 'Network Error'
            })
        }
    }

    testerHandler = (e) => {

        let testerStatus = this.state.isTester
        if (testerStatus) {
            testerStatus = false
        }
        else {
            testerStatus = true
        }
        this.setState({
            isTester: testerStatus,
        });

        const { page, search, sizePerPage, selectedOption, isTopRate, orderBy, sortBy, startDate, endDate } = this.state;

        if (isTopRate) {
            this.viewTopRated(page, search, sizePerPage, selectedOption, testerStatus, orderBy, sortBy, startDate, endDate)
        }
        else {
            this.getSellerList(page, search, sizePerPage, selectedOption, testerStatus, orderBy, sortBy, startDate, endDate);
        }
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date
        })
    }

    setEndDate = (date) => {
        this.setState({
            endDate: date
        })
    }

    applyDates = () => {
        const { isTopRate, page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate } = this.state;
        if (isTopRate) {
            this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate);
        }
        else {
            this.getSellerList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate);
        }
    }

    render() {
        const { page, search, sizePerPage, selectedOption, isTester, isClearable, mainCate, startDate, endDate, viewBtn, orderBy, sortBy } = this.state

        const columns = [
            { dataField: 'username', text: 'User Name', sort: true, headerFormatter: this.headerSpan, formatter: this.userName },
            { dataField: 'count_rating', text: 'Count Rating', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'starpoints', text: 'Rating', sort: true, headerFormatter: this.headerSpan, formatter: this.commonField },
            { dataField: 'generalRank', text: 'General Rank', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'categoryRank', text: 'Category Rank', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: '5D', text: '5D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: '15D', text: '15D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: '30D', text: '30D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: '180D', text: '180D', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'total_contract', text: 'Total Contract', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },
            { dataField: 'price', text: 'Price', sort: true, headerFormatter: this.headerSpan, formatter: this.centerField },

            { dataField: 'action', text: 'Action', sort: false, headerFormatter: this.headerSpan, formatter: this.actionField }
        ];

        const tableData = {
            columns: columns,
            data: this.state.sellerList,
            keyId: 'profile_id',
            loading: this.state.isLoader,
            message: 'Table is Empty',
            noDataIndication: <NoDataIndication />,
            page: this.state.page,
            totalRecord: this.state.totalRecord,
            sizePerPage: this.state.sizePerPage,
            handleTableChange: this.handleTableChange,
            rowClass: this.isTester,
            isCsv: false,
            isSelectRow: false,
            dataLimit: [
                { text: '10', value: 10 },
                { text: '20', value: 20 },
                { text: '30', value: 30 },
                { text: '50', value: 50 },
                { text: '100', value: 100 }
            ]
        };

        const modalDa = {
            data: this.state.singleData,
            addMModal: this.state.isModal,
            submitRank: this.rankFormSubmit,
            handleClose: this.closeModal,
            issLoader: this.state.issLoader,
            messageAApi: this.state.messageAApi
        }
        return (
            <section className="bg-balance best-seller">
                <div className="container-fluid">
                    <div className="balance_wrap">
                        <ul className="nesting_links">
                            <li><NavLink exact to='/best-gigs'>Best Gigs</NavLink></li>
                            <li><NavLink activeClassName="active" exact to='/best-sellers'>Best Sellers</NavLink></li>
                        </ul>
                        <div className="tester_view tester-record-right">
                            <p>Tester Record</p>
                            <button type="button" className={this.state.isTester ? "button_checkbox active" : "button_checkbox"} onClick={this.testerHandler}>
                                <span className="label_text"></span>
                            </button>
                        </div>
                        {viewBtn === 0 ? <button type="button" onClick={() => this.viewTopRated(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)} className="btn btn-primary top-rated">View Top Rated</button> : <button type="button" onClick={() => this.getSellerList(page, search, sizePerPage, selectedOption, isTester, orderBy, sortBy, startDate, endDate)} className="btn btn-primary top-rated">View Best Sellers</button>}

                        {/* <div className="select_wrap main-service">
                            <Select value={selectedOption} isClearable={isClearable} placeholder={"Select Category"} onChange={this.handleChange} options={mainCate} />
                        </div> */}
                        <div className='date-range'>
                            <DatePicker placeholderText="Select Start Date" selected={startDate} minDate={new Date("2018-01-01")} maxDate={new Date()} onChange={date => this.setStartDate(date)} dateFormat="yyyy-MM-dd" />
                            <DatePicker placeholderText="Select End Date" selected={endDate} onChange={date => this.setEndDate(date)} minDate={startDate} maxDate={new Date()} endDate={endDate} dateFormat="yyyy-MM-dd" />
                            <button type="button" onClick={this.applyDates} className="btn btn-primary">Apply</button>
                        </div>
                        <DataTable TableData={tableData} />
                        <RankModal modalData={modalDa} />
                    </div>
                </div>
            </section>
        )
    }
}
export default withRouter(connect(null)(Agent));
