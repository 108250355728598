import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ListViews from './ListViews';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper';
import { toster_sucess } from '../../helpers/toster_helper';
import LoaderImg from '../../assets/images/user-loader.gif';

class RejectedProfile extends Component {
    _ismounted = false;

    constructor(props){
        super(props);
        this.state = {
            rejProData:[],
            rejProSelect:[],
            isLoader:false,
            isRsLoader:false,
            page:1,
            messageApi:'',
            isDisabled:true,
            isBLeader:false,
            search:''
        }
    }

    componentDidMount() { 
        this._ismounted = true;
        const { page, search } = this.state;
        this.setState({
            isLoader:true
        })
        this.getRejectedProfileData(page, search);
    }
      
    componentWillUnmount() {
        this._ismounted = false;
    }

    getRejectedProfileData = async (page, search) => {
        if(this._ismounted){
            try{
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('page_no', page)
                formData.append('search_by', search)

                const res = await apiHalper(`getRejectedProfilePicture`, 'POST', formData, headers);
                // console.log(res);
                if(res.data.status === true){
                    let data = jwtDecode(res.data.data);
                    // console.log(data);
                    if(data.length > 0){
                        if(search !== ''){
                            this.setState({
                                isRsLoader:true,
                                isLoader:false,
                                rejProData:data[0].data,
                                messageApi:''
                            })
                        }else{
                            this.setState({
                                isRsLoader:true,
                                isLoader:false,
                                rejProData:[...this.state.rejProData, ...data[0].data],
                                messageApi:''
                            })
                        }
                    }else{
                        this.setState({
                            isLoader:false,
                            isRsLoader:false,
                            messageApi:'',
                        })
                    }
                }else{
                    this.setState({
                        isLoader:false,
                        isRsLoader:false,
                        rejProData:[],
                        messageApi:'No Record Found'
                    })
                }
            }catch(err){
                this.setState({
                    isLoader:false,
                    isRsLoader:false,
                    rejProData:[],
                    messageApi:'Network Error'
                })
            }
        }
    }

    nextpageApprovedprofile = () => {
        const inc = this.state.page + 1;
        if(this.state.isRsLoader){
            this.setState({
                page : inc
            })
            this.getRejectedProfileData(inc, this.state.search)
        }else{
            this.setState({
                isRsLoader:false
            })
        }
        
    }

    rejProfileHander = (e) =>{
        const { value } = e.target;
        let selected = this.state.rejProSelect;
        if(!selected.includes(value)){
            selected.push(value);
        }else{
            selected.splice(selected.indexOf(value), 1);
        }

        if(Array.isArray(selected) && selected.length){
            this.setState({
                rejProSelect:selected,
                isDisabled:false
            })
        }else{
            this.setState({
                rejProSelect:selected,
                isDisabled:true
            })
        }
    }

    uRApproveApi = async () =>{

        const { rejProSelect } = this.state;
        if(Array.isArray(rejProSelect) && rejProSelect.length){

            this.setState({
                isBLeader:true,
            });

            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('photo_ids', rejProSelect.join())
            const response = await apiHalper('approveProfilePicture','POST', formData, headers);
            if(response.data.status === true){
                // const { rejProSelect, rejProData } = this.state;
                // let newArray = rejProData;
                // for(let i = rejProSelect.length -1; i >= 0; i--){
                //     newArray.splice(rejProSelect[i], i);
                // }
                
                this.setState({
                    isBLeader:false,
                    rejProData:[],
                    rejProSelect:[],
                    page:1
                });
                this.getRejectedProfileData(1, this.state.search);
                toster_sucess("Profile Approved Successfully...");
            }else{
                this.setState({
                    isBLeader:false,
                });
            }
        }
    }

    searchHandler = (e) =>{
        const { value } = e.target;
        this.setState({
            search:value
        })
    }

    searchSubmit = (e)=>{
        e.preventDefault();
        const { search } = this.state;
        this.setState({
            rejProData:[]
        })
        // if(search !== ""){
            this.getRejectedProfileData(1, search);
        // }
    }

    render() {

        // const noProfData = [
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'},
        //     { img:'https://mlrjx6kefml3.i.optimole.com/6AH3zQ-wlC6a5CH/w:300/h:300/q:auto/dpr:2.6/rt:fill/g:ce/https://stratefix.com/wp-content/uploads/2016/04/dummy-profile-pic-male1.jpg', last_name:'Doe', first_name:'John', email:'john1@gmail.com', date:'3 months ago'}
        // ]

        const { isDisabled, isBLeader, search } = this.state;

        const RProfi = {
            name:'rejectedProfile',
            data:this.state.rejProData,
            inputHandler:this.rejProfileHander,
            nextData:this.nextpageApprovedprofile,
            isLoader:this.state.isLoader,
            isSLoader:this.state.isRsLoader,
            messageApi:this.state.messageApi
        }

        return <Fragment>
                <div className="search_bar">
                    <form action="/" method="POST" onSubmit={this.searchSubmit}>
                        <input type="text" name="search" value={search} onChange={this.searchHandler} autoComplete="off" placeholder="Search" />
                        <button type="submit" className="btn_searchIc"></button>
                    </form>
                </div>
            <ListViews propsData={RProfi} />
            <div className="group_btns">
                <button type="button" className="btn_same btn_apply" onClick={this.uRApproveApi} disabled={isDisabled || isBLeader}>{isBLeader?<img src={LoaderImg} className="small-loader" alt="loader" />:"Approve"}</button>
            </div>
        </Fragment>
    }
}

export default withRouter(connect(null)(RejectedProfile))
