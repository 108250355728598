import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import './index.css';

class AddModel extends Component {
    constructor(props){
        super(props);
        this.state={
            radioStatus:0,
            adminstatus:0,
            tapName:'',
            keywords:'',
            formError:{
                tapName:'',
                keywords:''
            },
            formValid:{
                tapName:false,
                keywords:false
            }
        }
    }

    radioChange = (val) =>{
        this.setState({
            radioStatus:val
        });
    }

    adminRadioChange = (val) =>{
        this.setState({
            adminstatus:val
        });
    }

    inputHandler = e =>{
        const { name, value } = e.target;
        this.setState({
            [name]:value
        }, ()=>this.validationChecked(name, value));
    }

    validationChecked = (name, value) =>{
        let error = this.state.formError;
        let valid = this.state.formValid;
        switch(name){
            case 'tapName':
                valid.tapName = value.length > 0;
                error.tapName = valid.tapName?'':'tapName must required!';
                break;
            case 'keywords':
                valid.keywords = value.length > 0;
                error.keywords = valid.keywords?'':'keywords must required!';
                break;
            default:
                break;
        };

        this.setState({
            formError:error,
            formValid:valid
        });
    }
    render() {

        const {lgShow , handelClose} = this.props;
        const { tapName, keywords,  formError, formValid } = this.state;
        const disabled = !formValid.tapName || !formValid.keywords;
        return (
            <div>
                <Modal show={lgShow} onHide={handelClose} aria-labelledby="contained-modal-title-vcenter" size="sm" className="modal_design" centered>
                    <Modal.Body>
                    <div className="modal_view">
                        <div className="modal_header">
                            <h4 className="model_title">Tap Details<button type="button" onClick={handelClose}><span className="close_icon"></span></button></h4>
                        </div>
                        <div className="body_modals">
                            <form method="POST" action="#" onSubmit={this.submitUser}>
                                    <div className="form_body2">
                                        <div className="form_group">
                                                <label className="label_form">1- Tap Name</label>
                                                <input type="text" name="tapName" className={formError.tapName !== ''?"form_control2 error_border":"form_control2"} value={tapName} onChange={this.inputHandler} placeholder="Enter tap Name" autoComplete="off"/>
                                                {formError.tapName !== ''?<p className="error_text">{formError.tapName}</p>:null}
                                        </div>
                                        <div className="form_group">
                                                <label className="label_form">2- Add keywords</label>
                                                <input type="text" name="keywords" className={formError.keywords !== ''?"form_control2 error_border":"form_control2"} value={keywords} onChange={this.inputHandler} placeholder="Enter keywords" autoComplete="off"/>
                                                {formError.keywords !== ''?<p className="error_text">{formError.keywords}</p>:null}
                                        </div>
                                        <div className="form_group">
                                            <label className="model_title"><span className="label_form lable-width">Phone Number</span><button type="button" ><span className="close_icon close-icon-forTap-model"></span></button></label>
                                        </div>
                                    </div>
                                    <div className="bg_submit2">
                                        <button type="submit" className="btn-submit" disabled={disabled}>Save</button>
                                        <p>Or <button type="button" className="btn_cancel2" onClick={handelClose}>Cancel</button></p>
                                    </div>
                            </form>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default  AddModel