import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { apiHalper } from '../../helpers/ApiHelper';
import { jwtDecode } from '../../helpers/jwt_helper'
import LoaderImg from '../../assets/images/user-loader.gif';
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import { toster_sucess, toster_error } from '../../helpers/toster_helper';
// import AddAccountIcon from '../../assets/images/add_icon.svg';
import DummyImage from '../../assets/images/dummy_img.svg';
import * as ACTION_TYPES from '../../redux/actions/types';

const mainFileArr = [];

class WithdrawModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSloader: false,
            images: [],
            mainFileObj: [],
            imgUrls: [],
            note: '',
            grantNote: '',
            grantNoteErr: '',
            transactionID: '',
            transactionErr: '',
            paymentOptions: [],
            filesize: 0,
            statusValue: 1,
            selectedOption: null,
            selectOpterr: '',
            isClearable: true,
            selectedOption2: null,
            isClearable2: true
        }
    }

    // componentDidMount() {
    //     this.getPaymentAccount()
    // }

    componentDidUpdate(prevProps) {
        const { show } = this.props;

        if (prevProps.show !== show) {
            if (show) {
                this.setState({
                    isSloader: false,
                    images: [],
                    imgUrls: [],
                    note: '',
                    grantNote: '',
                    grantNoteErr: '',
                    transactionID: '',
                    transactionErr: '',
                    selectOpterr: '',
                    paymentOptions: [],
                    filesize: 0,
                    statusValue: 1,
                    selectedOption: null,
                    isClearable: true,
                    selectedOption2: null,
                    isClearable2: true
                })
                this.getPaymentAccount();
            }            
        }
    }

    getPaymentAccount = async () => {
        try {
            const headers = {
                'Authorization': localStorage.getItem('token')
            }
            const response = await apiHalper('getAdminPaymentAccount', 'GET', null, headers);
            const res = response.data;
            const decode = jwtDecode(res.data)
            if (res.status) {
                this.setState({
                    paymentOptions: decode.map(info => ({ label: info.account, value: info.id })),
                    messageApi: '',
                });
            } else {
                this.setState({
                    paymentOptions: [],
                    messageApi: res.message
                });
            }

        } catch (err) {
            this.setState({
                messageApi: err.message
            })
        }
    }

    handleChange = selectedOption => {
        this.setState({ 
            selectedOption,
            selectOpterr: ''
        });
    };

    toggleClearable = () => {
        this.setState(state => ({ isClearable: !state.isClearable }));
    }

    handleChange2 = selectedOption => {
        this.setState({ selectedOption2: selectedOption });
    };

    toggleClearable2 = () => {
        this.setState(state => ({ isClearable2: !state.isClearable2 }));
    }

    pendingNote = (e) => {
        e.preventDefault();
    }

    fileHandler = async (e) => {        
        const files = e.target.files;
        if(files.length > 0) {
            let totalSize = this.state.images;
            for (var i = 0; i < files.length; i++) {
                // img  = await this.createFileReader(files[i]);
                totalSize.push(files[i])
            }
            this.setState({
                images: totalSize,
                mainFileObj: totalSize,
                imgUrls: mainFileArr
            })
        }
    }

    removeFile = (data) => {
        var array = this.state.images; // make a separate copy of the array
        var index = array.indexOf(data)
        console.log(data, index)
        if (index > -1) {
            array.splice(index, 1);
        }
        this.setState({
            images: array
            
        });
        
    }

    inputHandler = (e) => {
        this.setState({
            transactionID: e.target.value
        })
        if (this.state.transactionID.length >= 0) {
            this.setState({
                transactionErr: ''
            })
        }
    }

    noteHandler = (e) => {
        this.setState({
            grantNote: e.target.value
        })
        if (this.state.grantNote.length >= 0) {
            this.setState({
                grantNoteErr: ''
            })
        }
    }

    radioHandler = (value) => {
        this.setState({ statusValue: value });
    }

    textareaHandler = (e) => {
        this.setState({
            note: e.target.value
        })
    }
    grantWithdrawal = async () => {
        const { handleClose, getWithdraw } = this.props;
        const { grantNote, transactionID, images, mainFileObj, selectedOption } = this.state;
        if (selectedOption === null){
            this.setState({
                selectOpterr: 'Please Select Payment Option'
            })
        }
        else if (transactionID.length === 0 ) {
            this.setState({
                transactionErr: 'Please Enter transaction ID'
            })
        }
        else if (grantNote.length === 0) {
            this.setState({
                grantNoteErr: 'Please Enter Note'
            })
        }
        else {        
            try {
                this.setState({
                    isSloader: true,
                    transactionErr: '',
                    grantNoteErr: '',
                    selectOpterr: ''
                });
                const headers = {
                    'Authorization': localStorage.getItem('token')
                }

                let formData = new FormData();
                formData.append('withdrawalID', getWithdraw.id)
                formData.append('note', grantNote)
                formData.append('transactionID', transactionID)
                if(images.length > 0){            
                    for (let index = 0; index < images.length; index++) {
                        formData.append('paymentScreenshots', mainFileObj[index])
                    }
                }
                else {
                    formData.append('paymentScreenshots', '')
                }
                
                if (this.state.selectedOption) {
                    formData.append('adminPaymentAccountID', this.state.selectedOption.value)
                }
                const response = await apiHalper('grantWithdrawal', 'POST', formData, headers);
                const res = response.data;
                if (res.status) {
                    this.setState({
                        isSloader: false,
                        messageApi: '',
                    });
                    handleClose()
                    toster_sucess(res.message);
                } else {
                    this.setState({
                        isSloader: false,
                        messageApi: res.message
                    });
                    toster_error(res.message);
                }

            } catch (err) {
                console.log(err);
                this.setState({
                    messageApi: err.message
                })
            }
        }
    }

    rejectWithdrawal = async (id, note) => {
        const { handleClose } = this.props
        try {
            this.setState({
                isSloader: true
            });
            const headers = {
                'Authorization': localStorage.getItem('token')
            }

            let formData = new FormData();
            formData.append('withdrawalID', id)
            formData.append('note', note)

            const response = await apiHalper('rejectWithdrawal', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isSloader: false,
                    messageApi: '',
                });
                handleClose()
                toster_sucess(res.message);
            } else {
                this.setState({
                    isSloader: false,
                    messageApi: res.message
                });
                toster_error(res.message);
            }

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }
    }

    render() {
        // console.log(this.state.images);
        
        const { handleClose, show, toggleViewImage, getWithdraw } = this.props;
        const { statusValue, isClearable, selectedOption, selectOpterr, note, isSloader, transactionID, grantNote, paymentOptions, grantNoteErr, transactionErr, images } = this.state;
        

        return (
            <Modal show={show} onHide={() => handleClose()} className="modal_design" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="modal_view">
                        <div className="withdraw_pay">
                            <div className="select_blocks">
                                {/* <input type="radio" name="status" defaultValue={0} checked={statusValue === 0} id="pendding" onClick={()=>this.radioHandler(0)} />
                                <label className="label_radio blue" htmlFor="pendding">Pending</label> */}
                                <input type="radio" name="status" defaultChecked={statusValue === 1} defaultValue={1} id="paid" onClick={() => this.radioHandler(1)} />
                                <label className="label_radio green" htmlFor="paid">Paid</label>
                                {/* <input type="radio" name="status" defaultValue={2} id="holdBtn" checked={statusValue === 2} onClick={()=>this.radioHandler(2)}/>
                                <label className="label_radio gray" htmlFor="holdBtn">Hold</label> */}
                                <input type="radio" name="status" defaultValue={3} id="rejected" defaultChecked={statusValue === 3} onClick={() => this.radioHandler(3)} />
                                <label className="label_radio red" htmlFor="rejected">Rejected</label>
                            </div>
                            <div className="view_tabContent">
                                <h4 className="model_title"> {statusValue === 1 ? "Paid Details" : "Rejected Details" } <button type="button" onClick={() => handleClose()}><span className="close_icon"></span></button></h4>
                                <form action="#" method="POST" onSubmit={this.pendingNote} encType="multipart/form-data">
                                    {statusValue === 1 ?
                                        <div className="paid_field">
                                            {/* <div className="input_wrap">
                                                <div className="bg_black bg_black2">
                                                    <input type="text" name="available_balance" defaultValue={"1500000 USD"} disabled />
                                                    <label>Available Balance</label>
                                                </div>
                                                <div className="bg_black bg_black2">
                                                    <input type="text" name="withdraw_balance" defaultValue={"15000.00 USD"} />
                                                    <label>Withdraw Balance</label>
                                                </div>
                                            </div> */}
                                            <div className="input_wrap">
                                                <div className="flex_group">
                                                    <div className="bg_black">
                                                        <label>From <span>Paypal</span></label>
                                                        <span className="select_input">
                                                            <Select value={selectedOption} isClearable={isClearable} onChange={this.handleChange} options={paymentOptions} />
                                                            {selectOpterr !== '' ? <p className="error_text pos_a selectedErr">{selectOpterr}</p> : null}
                                                        </span>
                                                        {/* <select id="from_paypal">
                                                            <option value="1">test@gmail.com</option>
                                                            <option value="2">pay@gmail.com</option>
                                                        </select> */}
                                                    </div>
                                                    {/* <button type="button"className="btn_account" onClick={()=>addAcountOpen()}><img src={AddAccountIcon} alt="Add Account" /> <span>Add Account</span></button> */}
                                                </div>
                                                <div className="flex_group">
                                                    <div className="bg_black">
                                                        <label>To <span>Paypal</span></label>
                                                        <span className="select_input">
                                                            {getWithdraw && getWithdraw.account}
                                                            {/* <Select value={selectedOption2} isClearable={isClearable2} onChange={this.handleChange2} options={options} /> */}
                                                        </span>
                                                        {/* <select id="to_paypal">
                                                            <option value="1">test@gmail.com</option>
                                                            <option value="2">pay@gmail.com</option>
                                                        </select> */}
                                                    </div>
                                                    {/* <button type="button"className="btn_account" onClick={()=>addAcountOpen()}><img src={AddAccountIcon} alt="Add Account" /> <span>Add Account</span></button> */}
                                                </div>
                                            </div>
                                            <div className="field_bg">
                                                <div className="form_group2">
                                                    <label>Transaction Id</label>
                                                    <input type="text" name="transaction_id" onChange={this.inputHandler} autoComplete="off" placeholder="Enter Transaction ID" />
                                                    {transactionErr !== '' ? <p className="error_text pos_a">{transactionErr}</p> : null}
                                                </div>
                                                <div className="form_group2">
                                                    <label>Note</label>
                                                    <textarea name="note" onChange={this.noteHandler} placeholder="Enter Note..."></textarea>
                                                    {grantNoteErr !== '' ? <p className="error_text pos_a">{grantNoteErr}</p> : null}
                                                </div>
                                                <div className="form_group2 mb-0">
                                                    <input type="file" name="files[]" id="file_input" onChange={this.fileHandler} multiple={true} />
                                                    {/* <input type="file" name="files[]" className="form-control" onChange={this.multiFiles}  /> */}
                                                    <label htmlFor="file_input" className="file_label">Payment screenshot <span>(Only allow PNG, JPG, JPEG, GIF format and Max size is 2MB)</span></label>
                                                </div>
                                            </div>
                                            <div>
                                                <ul className="flie_list">
                                                    {images.length > 0 ?
                                                        images.map((info, i) =>
                                                            <li key={i}>
                                                                <div className="flie_block">
                                                                    <div className="content_wrap">
                                                                        <div className="img_url"><img src={DummyImage} alt="File" /></div>
                                                                        <div className="img_content">
                                                                            <h6>{info.name}</h6>
                                                                            <p>{(info.size / 1048576).toFixed(2)}MB</p>
                                                                        </div>
                                                                    </div>
                                                                    <button type="button" className="btn-viewImg" onClick={() => toggleViewImage(info)}>
                                                                        <img src="" alt="" />
                                                                    </button>
                                                                    <button aria-label="Button Delete" type="button" className="btn-close" onClick={() => this.removeFile(info)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.856" height="10.856" viewBox="0 0 10.856 10.856"><g transform="translate(0.4 0.4)"><g transform="translate(0 0)"><path className="a" d="M5.744,5,9.846.9A.526.526,0,0,0,9.1.155L5,4.257.9.155A.526.526,0,0,0,.154.9L4.256,5,.154,9.1A.526.526,0,0,0,.9,9.847L5,5.745l4.1,4.1A.526.526,0,0,0,9.846,9.1Z" transform="translate(0 -0.001)" /></g></g></svg>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ) : null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="bg_submit">
                                                <button type="button" className="btn-submit" onClick={() => this.grantWithdrawal(getWithdraw.id, transactionID, grantNote)}>{isSloader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit Payment"}</button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {statusValue !== 1 ?
                                        <>
                                            <div className="field_bg">
                                                <div className="form_group2">
                                                    <label>Note</label>
                                                    <textarea name="note" onChange={this.textareaHandler} placeholder="Enter Note..."></textarea>
                                                </div>
                                            </div>
                                            <div className="bg_submit reject">
                                                <button type="button" className="btn-submit" onClick={() => this.rejectWithdrawal(getWithdraw.id, note)}>{isSloader ? <img src={LoaderImg} className="small-loader" alt="loader" /> : "Submit Payment"}</button>
                                            </div>
                                        </>
                                        : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
const mapStateToProps = state => ({
    show: state.balance.withdrawModal,
    getWithdraw: state.balance.withdrawData
})
const mapDispatchToProps = dispatch => ({
    toggleViewImage: (data) => dispatch({ type: ACTION_TYPES.TOGGLE_IMAGE_VIEWER, payload: data }),
    addAcountOpen: () => dispatch({ type: ACTION_TYPES.TOGGLE_ADD_ACCOUNT_MODAL }),
    handleClose: () => dispatch({ type: ACTION_TYPES.TOGGLE_WITHDRAW_MODAL })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawModal));